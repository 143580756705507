import { FieldErrors } from 'react-hook-form';

import { UserRole } from '../../types/user';

import { ProfileFormData } from './types';

export const fagomradeIsNotSelected = (
  errors: FieldErrors<ProfileFormData>
) => {
  // All checkboxes have error if none are selected
  return (
    !!errors.energiradgiver &&
    !!errors.bygningsekspert &&
    !!errors.kjelOgVarmeanlegg &&
    !!errors.ventilasjonsOgKjoleanlegg
  );
};

export const isFagomradeRequired = (formData: ProfileFormData) => {
  // Require fagområde if user is professional and no fagområde is selected
  return (
    formData.rolle === UserRole.PROFESJONELL &&
    !formData.energiradgiver &&
    !formData.bygningsekspert &&
    !formData.kjelOgVarmeanlegg &&
    !formData.ventilasjonsOgKjoleanlegg
  );
};
