import React, { FC } from 'react';

import StatisticsFilterProvider from '../../../../hooks/useStatisticsFilter/provider';
import { FilterCategory } from '../../../../hooks/useStatisticsFilter/context';

import StatisticsBuildingTypeView from './statisticsBuildingTypeView';

const StatisticsBuildingType: FC = () => {
  return (
    <StatisticsFilterProvider
      categories={[FilterCategory.BUILDING_CATEGORY, FilterCategory.FYLKER]}
    >
      <StatisticsBuildingTypeView />
    </StatisticsFilterProvider>
  );
};

export default StatisticsBuildingType;
