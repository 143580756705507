import React, { FC } from 'react';
import { Button, Dialog, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { isBuildingGarageOrAnnex } from '../utils/matrikkelBygningsTyper';

const getTranslationContext = (
  isGarasjeOrAnnex: boolean,
  isHovedBygg: boolean
) => {
  if (isGarasjeOrAnnex) {
    return {
      contextKey: 'garage'
    };
  }

  if (!isHovedBygg) {
    return {
      contextKey: 'annex'
    };
  }

  return null;
};

interface StartRegistrationDialogProps {
  bygningsTypeNummer: string;
  isHovedBygg: boolean;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const StartRegistrationDialog: FC<StartRegistrationDialogProps> = ({
  bygningsTypeNummer,
  isHovedBygg,
  isOpen,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation();

  const isGarasjeOrAnnex = isBuildingGarageOrAnnex(bygningsTypeNummer);
  const translation = getTranslationContext(isGarasjeOrAnnex, isHovedBygg);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Title>
        {t('register.dialog.title', { context: translation?.contextKey })}
      </Dialog.Title>

      <Dialog.Content>
        <Typography>
          {t('register.dialog.content', { context: translation?.contextKey })}
        </Typography>
      </Dialog.Content>

      <Dialog.Actions>
        <Button variant="secondary" onClick={onClose}>
          {t('previous')}
        </Button>

        <Button onClick={onConfirm}>
          {t('energyRateBuilding', { context: translation?.contextKey })}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
