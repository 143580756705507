import React, { FC } from 'react';
import { Button, Dialog } from 'enova-frontend-components';
import { Trans, useTranslation } from 'react-i18next';

interface DeleteRegistrationDialogProps {
  bruksenhetsIdentId: string | number | undefined;
  deleting: boolean;
  isOpen: boolean;
  onClose: (() => void) | undefined;
  onConfirm: () => void;
}

export const DeleteRegistrationDialog: FC<DeleteRegistrationDialogProps> = ({
  bruksenhetsIdentId,
  deleting,
  isOpen,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Title>
        {t('expertToolsXMLPortfolio.worklist.removeFile.title')}
      </Dialog.Title>

      <Dialog.Content>
        <Trans
          i18nKey={t(
            'expertToolsXMLPortfolio.worklist.removeFile.description',
            {
              BruksenhetsIdentId: bruksenhetsIdentId
            }
          )}
        />
      </Dialog.Content>

      <Dialog.Actions>
        <Button variant="secondary" disabled={deleting} onClick={onClose}>
          {t('cancel')}
        </Button>

        <Button loading={deleting} onClick={onConfirm}>
          {t('confirm')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
