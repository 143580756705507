import { Grid } from 'enova-frontend-components';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BuildingListItemRegistrationDateWidth,
  SmallTypography
} from '../../../../utils';
import useFormatDate from '../../../../../../../hooks/useFormatDate';

interface Props {
  date?: Date;
}

export const PublishedDate: FC<Props> = ({ date }) => {
  const { formatDate } = useFormatDate();
  const { t } = useTranslation();

  if (!date) {
    return null;
  }

  return (
    <Grid
      item
      minWidth={BuildingListItemRegistrationDateWidth}
      width={BuildingListItemRegistrationDateWidth}
    >
      <Fragment>
        <SmallTypography>
          {t('registeredDate.energyCertificate')}
        </SmallTypography>

        <SmallTypography>{formatDate(new Date(date))}</SmallTypography>
      </Fragment>
    </Grid>
  );
};
