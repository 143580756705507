import { useParams } from 'react-router-dom';

import { FlerboligRegistrationXMLParams } from '../types';
import { GrupperegistreringTiltakSection } from '../../../../../../components/tiltakSection/grupperegistrering/grupperegistreringTiltakSection';
import Measures from '../../common/steps/measures';

export const FlerboligMeasures = () => {
  const { flerboligId } = useParams<FlerboligRegistrationXMLParams>();

  return (
    <Measures registrationType="flerbolig">
      <GrupperegistreringTiltakSection grupperegistreringId={flerboligId} />
    </Measures>
  );
};
