import { FC } from 'react';

import { BuildingDetails } from '../../../../../../../../types/building';

import { CompactBuildingInfo } from './compactBuildingInfo';
import { BuildingInfo } from './buildingInfo';

interface Props {
  minimizedView?: boolean;
  building: BuildingDetails;
}

export const BuildingInfoWrapper: FC<Props> = ({ minimizedView, building }) => {
  if (minimizedView) {
    return <CompactBuildingInfo building={building} />;
  }

  return <BuildingInfo building={building} />;
};
