import { TextField } from 'enova-frontend-components';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateBuildingFormContext } from '../utils';

interface BruksenhetsNummerProps {
  disabled: boolean;
}

export const BruksenhetsNummer: FC<BruksenhetsNummerProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const { control } = useCreateBuildingFormContext();

  return (
    <Controller
      name="bruksenhetsnummer"
      control={control}
      rules={{
        pattern: {
          value: /[HLUK][0-9]{4}$/,
          message: t('invalid') as string
        }
      }}
      render={({ field: { onChange, ...rest }, formState: { errors } }) => (
        <TextField
          {...rest}
          onChange={(e) => onChange(e.currentTarget.value.toUpperCase())}
          disabled={disabled}
          HelperBoxProps={{
            'aria-label': t('readFieldInformation'),
            children: t('building.create.bruksenhetsnummer.helperText')
          }}
          label={t('building.create.bruksenhetsnummer')}
          fullWidth
          error={!!errors?.bruksenhetsnummer}
          helperText={errors?.bruksenhetsnummer?.message}
        />
      )}
    />
  );
};
