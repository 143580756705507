import { Button, useSnack } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SoknadSteg } from '../../registering/utils/registerEnums';
import { queryClient, queryKeys } from '../../../utils/react-query';
import { createErrorMessage } from '../../../utils/utils';

import { useArchiveAndCloneRegistration } from './useArchiveAndCloneRegistration';

interface Props {
  registrationId: string;
  energiplanId?: string;
  energiobjektId: string;
  soknadSteg: SoknadSteg;
}

export const ArchiveAndCloneRegistration: FC<Props> = ({
  registrationId,
  energiplanId,
  energiobjektId,
  soknadSteg
}) => {
  const { t } = useTranslation();
  const { addSnack } = useSnack();
  const { archiveRegistration, isLoading } = useArchiveAndCloneRegistration();

  if (!registrationId || !energiplanId) {
    return null;
  }
  const onArchiveRegistration = () => {
    archiveRegistration(
      { registrationId, energiplanId },
      {
        onSuccess: () =>
          queryClient.invalidateQueries([
            queryKeys.energyAnalysisList,
            energiobjektId
          ]),
        onError: () => {
          addSnack(
            createErrorMessage(t('registration.archiveAndClone.error')),
            { variant: 'error' }
          );
        }
      }
    );
  };

  return (
    <Button
      loading={isLoading}
      variant="secondary"
      onClick={onArchiveRegistration}
    >
      {t('documentsForEnovaApplication.edit', { context: soknadSteg })}
    </Button>
  );
};
