import {
  BygningsForm,
  Skjerming,
  VeggId
} from '../../components/registering/utils/registerEnums';
import {
  Bygningsdetaljer,
  Detaljeringssteg,
  Energibruk,
  Etasje,
  Gulvsone,
  Konstruksjonstetthet,
  Tak,
  Terrengskjerming,
  UserInput,
  Vegg,
  Veggsone,
  Vindu,
  YtterDor
} from '../../types/registration/userInput';
import { Tiltak } from '../../types/registration/tiltak';
import { EnergyPlan } from '../../types/building';
import { User } from '../../types/user';
import { UpdateCalculationParameterMap } from '../../types/calculationParameters';

export type RegistrationStore = {
  allFloorsSameShape: boolean;
  allFloorsSameWallConstruction: boolean;
  allWallsAgainstTheOpen: boolean;
  // CalculatedArea is a map with a floorId as key and calculated area as value.
  // Value might be undefined if we are not able to calculate area for the given floor:
  // { "first_floor_id" : 44, "second_floor_id" : undefined }
  calculatedArea?: Record<string, number | undefined>;
  energiplan?: Partial<EnergyPlan>;
  userInput: UserInput;
  user?: User;
};

export enum RegistrationAction {
  UPDATE_REGISTRATION = 'UPDATE_REGISTRATION',
  INIT_REGISTRATION = 'INIT_REGISTRATION',
  INIT_SOKNAD = 'INIT_SOKNAD',
  // DOOR
  DOR_ADD = 'DOR_ADD',
  DOR_REMOVE = 'DOR_REMOVE',
  // VINDU
  VINDU_ADD = 'VINDU_ADD',
  VINDU_EDIT = 'VINDU_EDIT',
  VINDU_REMOVE = 'VINDU_REMOVE',
  // GULV
  GULV_SET_HAS_MULTIPLE_ZONES = 'GULVSONE_SET_HAS_MULTIPLE_ZONES',
  GULVSONE_ADD = 'GULVSONE_ADD',
  GULVSONE_INIT = 'GULVSONE_INIT',
  GULVSONE_REMOVE = 'GULVSONE_REMOVE',
  GULVSONE_UPDATE = 'GULVSONE_UPDATE',
  // SINGLE FLOOR EDITS
  ETASJE_ROTATE = 'ETASJE_ROTATE',
  ETASJE_UPDATE = 'ETASJE_UPDATE',
  ETASJE_UPDATE_SHAPE = 'ETASJE_UPDATE_SHAPE',
  ETASJE_UPDATE_WALL = 'ETASJE_UPDATE_WALL',
  ETASJE_UPDATE_WALLS = 'ETASJE_UPDATE_WALLS',
  ETASJE_VEGG_SET_HAS_MULTIPLE_ZONES = 'ETASJE_VEGG_SET_HAS_MULTIPLE_ZONES',
  ETASJE_VEGGSONE_ADD = 'ETASJE_VEGGSONE_ADD',
  ETASJE_VEGGSONE_REMOVE = 'ETASJE_VEGGSONE_REMOVE',
  ETASJE_VEGGSONE_UPDATE = 'ETASJE_VEGGSONE_UPDATE',
  // ALL FLOORS
  ETASJER_ROTATE = 'ETASJER_ROTATE',
  ETASJER_UPDATE = 'ETASJER_UPDATE',
  ETASJER_UPDATE_SHAPE = 'ETASJER_UPDATE_SHAPE',
  ETASJER_UPDATE_WALL = 'ETASJER_UPDATE_WALL',
  ETASJER_UPDATE_WALLS = 'ETASJER_UPDATE_WALLS',
  ETASJER_VEGG_SET_HAS_MULTIPLE_ZONES = 'ETASJER_VEGG_SET_HAS_MULTIPLE_ZONES',
  ETASJER_VEGGSONE_ADD = 'ETASJER_VEGGSONE_ADD',
  ETASJER_VEGGSONE_REMOVE = 'ETASJER_VEGGSONE_REMOVE',
  ETASJER_VEGGSONE_UPDATE = 'ETASJER_VEGGSONE_UPDATE',
  // TILTAK
  GJENSTAENDE_TILTAK_ADD = 'GJENSTAENDE_TILTAK_ADD',
  GJENSTAENDE_TILTAK_REMOVE = 'GJENSTAENDE_TILTAK_REMOVE',
  // TAK
  TAK_UPDATE = 'TAK_UPDATE',
  TAK_VINDU_ADD = 'TAK_VINDU_ADD',
  TAK_VINDU_EDIT = 'TAK_VINDU_EDIT',
  TAK_VINDU_REMOVE = 'TAK_VINDU_REMOVE',
  // TOGGLES
  TOGGLE_ALL_FLOORS_SAME_SHAPE = 'TOGGLE_ALL_FLOORS_SAME_SHAPE',
  TOGGLE_ALL_FLOORS_SAME_WALL_CONSTR = 'TOGGLE_ALL_FLOORS_SAME_WALL_CONSTR',
  TOGGLE_ALL_WALLS_AGAINST_THE_OPEN = 'TOGGLE_ALL_WALLS_AGAINST_THE_OPEN',
  // GENERAL
  UPDATE_BYGNINGSDETALJERING = 'UPDATE_BYGNINGSDETALJERING',
  UPDATE_DETAILING_STEPS = 'UPDATE_DETAILING_STEPS',
  UPDATE_ENERGIBRUK = 'UPDATE_ENERGIBRUK',
  UPDATE_ENERGIBRUK_UNIT = 'UPDATE_ENERGIBRUK_UNIT',
  UPDATE_KONSTRUKSJONSTETTHET = 'UPDATE_KONSTRUKSJONSTETTHET',
  UPDATE_PARAMETERS = 'UPDATE_PARAMETERS',
  UPDATE_TERRENGSKJERMING = 'UPDATE_TERRENGSKJERMING',
  // GENERIC
  UPDATE_REGISTRATION_FIELD = 'UPDATE_REGISTRATION_FIELD',
  // User
  UPDATE_USER = 'UPDATE_USER',
  // AREA
  CLEAR_CALCULATED_AREA = 'CLEAR_CALCULATED_AREA',
  UPDATE_CALCULATED_AREA = 'UPDATE_CALCULATED_AREA',

  // Veggsone
  VEGGKONSTRUKSJON_COPY = 'VEGGKONSTRUKSJON_COPY',

  WALL_HAS_NO_WINDOWS = 'WALL_HAS_NO_WINDOWS'
}

export type UpdateParametersPayload = {
  data: UpdateCalculationParameterMap;
};

export type UpdateRegistrationPayload = {
  energiplan?: Partial<EnergyPlan>;
  userInput?: UserInput;
};

export type UpdateRegistrationAction = {
  payload: UpdateRegistrationPayload;
  type: RegistrationAction.UPDATE_REGISTRATION;
};

// INIT
export type InitRegistrationAction = {
  type: RegistrationAction.INIT_REGISTRATION;
};

// INIT SØKNAD
export type InitSoknadPayload = {
  energiplan?: Partial<EnergyPlan>;
  userInput?: UserInput;
};

export type InitSoknadAction = {
  payload: InitSoknadPayload;
  type: RegistrationAction.INIT_SOKNAD;
};

// DOOR

// Add door
export type DorAddPayload = {
  dor: YtterDor;
};

export type DorAddAction = {
  payload: DorAddPayload;
  type: RegistrationAction.DOR_ADD;
};

// Remove door
export type DorRemovePayload = {
  dorId: string;
};

export type DorRemoveAction = {
  payload: DorRemovePayload;
  type: RegistrationAction.DOR_REMOVE;
};

// WINDOWS

// Add window
export type VinduAddPayload = {
  etasjeId: string;
  veggId: VeggId;
  veggsoneId: string;
  windows: Vindu[];
};

export type VinduAddAction = {
  payload: VinduAddPayload;
  type: RegistrationAction.VINDU_ADD;
};

// Remove window
export type VinduRemovePayload = {
  etasjeId: string;
  veggId: VeggId;
  veggsoneId: string;
  vinduIds: string[];
};

export type VinduRemoveAction = {
  payload: VinduRemovePayload;
  type: RegistrationAction.VINDU_REMOVE;
};

// Edit window
export type VinduEditPayload = {
  etasjeId: string;
  veggId: VeggId;
  veggsoneId: string;
  windows: Vindu[];
  vinduIds: string[];
};

export type VinduEditAction = {
  payload: VinduEditPayload;
  type: RegistrationAction.VINDU_EDIT;
};

// GULVSONE

// Init floor zones
export type GulvsoneInitAction = {
  type: RegistrationAction.GULVSONE_INIT;
};

// Add floor zone
export type GulvsoneAddPayload = {
  etasjeId: string;
};

export type GulvsoneAddAction = {
  payload: GulvsoneAddPayload;
  type: RegistrationAction.GULVSONE_ADD;
};

// Disable multiple floor zones
export type GulvSetHasMultipleZonesPayload = {
  hasMultipleZones: boolean;
  etasjeId: string;
};

export type GulvSetHasMultipleZonesAction = {
  payload: GulvSetHasMultipleZonesPayload;
  type: RegistrationAction.GULV_SET_HAS_MULTIPLE_ZONES;
};

// Remove floor zone
export type GulvsoneRemovePayload = {
  etasjeId: string;
};

export type GulvsoneRemoveAction = {
  payload: GulvsoneRemovePayload;
  type: RegistrationAction.GULVSONE_REMOVE;
};

// Update floor zone
export type GulvsoneUpdatePayload = {
  etasjeId: string;
  field: keyof Gulvsone;
  gulvsoneId: string;
  value: unknown;
};

export type GulvsoneUpdateAction = {
  payload: GulvsoneUpdatePayload;
  type: RegistrationAction.GULVSONE_UPDATE;
};

// ETASJE

// Rotate etasje
export type EtasjeRotatePayload = Pick<Etasje, 'id'>;

export type EtasjeRotateAction = {
  payload: EtasjeRotatePayload;
  type: RegistrationAction.ETASJE_ROTATE;
};

// Update etasje
export type EtasjeUpdatePayload = Etasje;

export type EtasjeUpdateAction = {
  payload: EtasjeUpdatePayload;
  type: RegistrationAction.ETASJE_UPDATE;
};

// Update etasje buildingShape
export type EtasjeUpdateShapePayload = Pick<Etasje, 'id'> & {
  shape: BygningsForm;
};

export type EtasjeUpdateShapeAction = {
  payload: EtasjeUpdateShapePayload;
  type: RegistrationAction.ETASJE_UPDATE_SHAPE;
};

// Update wall
export type EtasjeUpdateWallPayload = {
  etasjeId: string;
  field: keyof Vegg;
  value: unknown;
  veggId: VeggId;
};

export type EtasjeUpdateWallsPayload = {
  fields: Pick<EtasjeUpdateWallPayload, 'field' | 'value' | 'veggId'>[];
  etasjeId: string;
};

export type EtasjeUpdateWallAction = {
  payload: EtasjeUpdateWallPayload;
  type: RegistrationAction.ETASJE_UPDATE_WALL;
};

export type EtasjeUpdateWallsAction = {
  payload: EtasjeUpdateWallsPayload;
  type: RegistrationAction.ETASJE_UPDATE_WALLS;
};

export type WallHasNoWindowsPayload = {
  value: boolean;
  etasjeId: string;
  veggId: string;
};

export type WallHasNoWindowsAction = {
  payload: WallHasNoWindowsPayload;
  type: RegistrationAction.WALL_HAS_NO_WINDOWS;
};

// Add wall zone
export type EtasjeVeggsoneAddPayload = {
  etasjeId: string;
  veggId: VeggId;
  veggsone?: Omit<Veggsone, 'id'>;
};

export type EtasjeVeggsoneAddAction = {
  payload: EtasjeVeggsoneAddPayload;
  type: RegistrationAction.ETASJE_VEGGSONE_ADD;
};

// Disable multiple wall zones
export type EtasjeVeggSetHasMultipleZonesPayload = {
  etasjeId: string;
  hasMultipleZones: boolean;
  veggId: VeggId;
};

export type EtasjeVeggSetHasMultipleZonesAction = {
  payload: EtasjeVeggSetHasMultipleZonesPayload;
  type: RegistrationAction.ETASJE_VEGG_SET_HAS_MULTIPLE_ZONES;
};

// Remove wall zone
export type EtasjeVeggsoneRemovePayload = {
  veggsoneId: string;
};

export type EtasjeVeggsoneRemoveAction = {
  payload: EtasjeVeggsoneRemovePayload;
  type: RegistrationAction.ETASJE_VEGGSONE_REMOVE;
};

// Update wall zone
export type EtasjeVeggsoneUpdatePayload = {
  veggsone: Veggsone;
};

export type VeggsoneCopyPayload = {
  etasjeId: string;
  fraVeggsoneId: string;
  tilVeggsoneId: string;
};

export type EtasjeVeggsoneUpdateAction = {
  payload: EtasjeVeggsoneUpdatePayload;
  type: RegistrationAction.ETASJE_VEGGSONE_UPDATE;
};

// ETASJER (ALL)

// Rotate etasjer (all)
export type EtasjerRotateAction = {
  type: RegistrationAction.ETASJER_ROTATE;
};

// Update etasjer (all) field
export type EtasjerUpdatePayload = {
  etasje: Etasje;
};

export type EtasjerUpdateAction = {
  payload: EtasjerUpdatePayload;
  type: RegistrationAction.ETASJER_UPDATE;
};

// Update etasjer (all) buildingShape
export type EtasjerUpdateShapePayload = {
  shape: BygningsForm;
};

export type EtasjerUpdateShapeAction = {
  payload: EtasjerUpdateShapePayload;
  type: RegistrationAction.ETASJER_UPDATE_SHAPE;
};

// Update wall on all floors
export type EtasjerUpdateWallPayload = {
  field: keyof Vegg;
  value: unknown;
  veggId: VeggId;
};

export type EtasjerUpdateWallAction = {
  payload: EtasjerUpdateWallPayload;
  type: RegistrationAction.ETASJER_UPDATE_WALL;
};

// Update multiple walls for same floor
export type EtasjerUpdateWallsPayload = {
  fields: EtasjerUpdateWallPayload[];
};

export type EtasjerUpdateWallsAction = {
  payload: EtasjerUpdateWallsPayload;
  type: RegistrationAction.ETASJER_UPDATE_WALLS;
};

// Add wall zone on all floors
export type EtasjerVeggsoneAddPayload = {
  veggId: VeggId;
  veggsone: Omit<Veggsone, 'id'>;
};

export type EtasjerVeggsoneAddAction = {
  payload: EtasjerVeggsoneAddPayload;
  type: RegistrationAction.ETASJER_VEGGSONE_ADD;
};

// Disable multiple wall zones on all floors
export type EtasjerVeggSetHasMultipleZonesPayload = {
  hasMultipleZones: boolean;
  veggId: VeggId;
};

export type EtasjerVeggSetHasMultipleZonesAction = {
  payload: EtasjerVeggSetHasMultipleZonesPayload;
  type: RegistrationAction.ETASJER_VEGG_SET_HAS_MULTIPLE_ZONES;
};

// Remove wall zone on all floors
export type EtasjerVeggsoneRemovePayload = {
  veggId: VeggId;
};

export type EtasjerVeggsoneRemoveAction = {
  payload: EtasjerVeggsoneRemovePayload;
  type: RegistrationAction.ETASJER_VEGGSONE_REMOVE;
};

// Update wall zone on all floors
export type EtasjerVeggsoneUpdatePayload = {
  veggsone: Veggsone;
  veggId: VeggId;
  // Index, not id, because the same veggsone have different ids on each floor
  veggsoneIndex: number;
};

export type EtasjerVeggsoneUpdateAction = {
  payload: EtasjerVeggsoneUpdatePayload;
  type: RegistrationAction.ETASJER_VEGGSONE_UPDATE;
};

// TILTAK

// Add gjenstaende tiltak
export type GjenstaendeTiltakAddPayload = {
  tiltak: Tiltak;
};

export type GjenstaendeTiltakAddAction = {
  payload: GjenstaendeTiltakAddPayload;
  type: RegistrationAction.GJENSTAENDE_TILTAK_ADD;
};

// Remove gjenstaende tiltak
export type GjenstaendeTiltakRemovePayload = {
  tiltakId: string;
};

export type GjenstaendeTiltakRemoveAction = {
  payload: GjenstaendeTiltakRemovePayload;
  type: RegistrationAction.GJENSTAENDE_TILTAK_REMOVE;
};

// ROOF

// Update roof
export type TakUpdatePayload = {
  field: keyof Tak;
  value: unknown;
};

export type TakUpdateAction = {
  payload: TakUpdatePayload;
  type: RegistrationAction.TAK_UPDATE;
};

// Add roof window
export type TakVinduAddPayload = {
  vindu: Vindu;
};

export type TakVinduAddAction = {
  payload: TakVinduAddPayload;
  type: RegistrationAction.TAK_VINDU_ADD;
};

// Remove roof window
export type TakVinduRemovePayload = {
  vinduIds: string[];
};

export type TakVinduRemoveAction = {
  payload: TakVinduRemovePayload;
  type: RegistrationAction.TAK_VINDU_REMOVE;
};

// Edit roof window
export type TakVinduEditPayload = {
  vindu: Vindu;
};

export type TakVinduEditAction = {
  payload: TakVinduEditPayload;
  type: RegistrationAction.TAK_VINDU_EDIT;
};

// BYGNINGSDETALJERING

// Update bygningsdetaljer
export type UpdateBygningsdetaljerPayload = {
  field: keyof Bygningsdetaljer;
  value: unknown;
};

export type UpdateBygningsdetaljerAction = {
  payload: UpdateBygningsdetaljerPayload;
  type: RegistrationAction.UPDATE_BYGNINGSDETALJERING;
};

// KONSTRUKSJONSTETTHET

// Update konstruksjonstetthet
export type UpdateKonstruksjonstetthetPayload = {
  data: Konstruksjonstetthet;
};

export type UpdateKonstruksjonstetthetAction = {
  payload: UpdateKonstruksjonstetthetPayload;
  type: RegistrationAction.UPDATE_KONSTRUKSJONSTETTHET;
};

// ENERGIBRUK

// Update energibruk
export type UpdateEnergibrukPayload = {
  energibruk: Energibruk;
};

export type UpdateEnergibrukAction = {
  payload: UpdateEnergibrukPayload;
  type: RegistrationAction.UPDATE_ENERGIBRUK;
};

// Update energibruk unit
export type UpdateEnergibrukUnitPayload = Pick<
  Energibruk,
  'energienhet' | 'energikilde'
>;

export type UpdateEnergibrukUnitAction = {
  payload: UpdateEnergibrukUnitPayload;
  type: RegistrationAction.UPDATE_ENERGIBRUK_UNIT;
};

// TERRENGSKJERMING

// Update terrengskjerming
export type UpdateTerrengskjermingPayload = {
  field: keyof Terrengskjerming;
  value: Skjerming;
};

export type UpdateTerrengskjermingAction = {
  payload: UpdateTerrengskjermingPayload;
  type: RegistrationAction.UPDATE_TERRENGSKJERMING;
};

// DETAILING STEPS

// Update detailing steps
export type UpdateDetailingStepsPayload = {
  step: keyof Detaljeringssteg;
  value: boolean;
};

export type UpdateDetailingStepsAction = {
  payload: UpdateDetailingStepsPayload;
  type: RegistrationAction.UPDATE_DETAILING_STEPS;
};

// TOGGLERS

// Toggle all floors same shape
export type ToggleAllFloorsSameShapePayload = {
  value: boolean;
};

export type ToggleAllFloorsSameShapeAction = {
  payload: ToggleAllFloorsSameShapePayload;
  type: RegistrationAction.TOGGLE_ALL_FLOORS_SAME_SHAPE;
};

// Toggle all floors same walls
export type ToggleAllFloorsSameWallConstrPayload = {
  value: boolean;
};

export type ToggleAllFloorsSameWallConstrAction = {
  payload: ToggleAllFloorsSameWallConstrPayload;
  type: RegistrationAction.TOGGLE_ALL_FLOORS_SAME_WALL_CONSTR;
};

// Toggle all walls towards the open
export type ToggleAllWallsAgainstTheOpenPayload = {
  value: boolean;
};

export type ToggleAllWallsAgainstTheOpenAction = {
  payload: ToggleAllWallsAgainstTheOpenPayload;
  type: RegistrationAction.TOGGLE_ALL_WALLS_AGAINST_THE_OPEN;
};

// GENERAL
export type UpdateRegistrationFieldPayload = {
  value: unknown;
  /**
   * Oject path of nested attribute. Must be in inwards order.
   */
  objectPath: string[];
};

export type UpdateRegistrationFieldAction = {
  payload: UpdateRegistrationFieldPayload;
  type: RegistrationAction.UPDATE_REGISTRATION_FIELD;
};

// USER
export type UpdateUserPayload = {
  value?: User;
};

export type UpdateUserAction = {
  payload: UpdateUserPayload;
  type: RegistrationAction.UPDATE_USER;
};

// CALCULATED AREA
export type UpdateCalculatedAreaPayload = {
  area: number | undefined;
  etasjeId: string;
};

export type UpdateCalculatedAreaAction = {
  payload: UpdateCalculatedAreaPayload;
  type: RegistrationAction.UPDATE_CALCULATED_AREA;
};

export type VeggsoneCopyAction = {
  payload: VeggsoneCopyPayload;
  type: RegistrationAction.VEGGKONSTRUKSJON_COPY;
};

// UPDATE_PARAMETERS
export type UpdateParametersAction = {
  payload: UpdateParametersPayload;
  type: RegistrationAction.UPDATE_PARAMETERS;
};

export type RegistrationActionTypes =
  | UpdateParametersAction
  | InitRegistrationAction
  | InitSoknadAction
  | DorAddAction
  | DorRemoveAction
  | EtasjeRotateAction
  | EtasjerRotateAction
  | EtasjerUpdateAction
  | EtasjerUpdateShapeAction
  | EtasjerUpdateWallAction
  | EtasjerUpdateWallsAction
  | EtasjerVeggSetHasMultipleZonesAction
  | EtasjerVeggsoneAddAction
  | EtasjerVeggsoneRemoveAction
  | EtasjerVeggsoneUpdateAction
  | EtasjeUpdateAction
  | EtasjeUpdateShapeAction
  | EtasjeUpdateWallAction
  | EtasjeUpdateWallsAction
  | EtasjeVeggSetHasMultipleZonesAction
  | EtasjeVeggsoneAddAction
  | EtasjeVeggsoneRemoveAction
  | EtasjeVeggsoneUpdateAction
  | GjenstaendeTiltakAddAction
  | GjenstaendeTiltakRemoveAction
  | GulvSetHasMultipleZonesAction
  | GulvsoneAddAction
  | GulvsoneInitAction
  | GulvsoneRemoveAction
  | GulvsoneUpdateAction
  | WallHasNoWindowsAction
  | TakUpdateAction
  | TakVinduAddAction
  | TakVinduEditAction
  | TakVinduRemoveAction
  | ToggleAllFloorsSameShapeAction
  | ToggleAllFloorsSameWallConstrAction
  | ToggleAllWallsAgainstTheOpenAction
  | UpdateBygningsdetaljerAction
  | UpdateDetailingStepsAction
  | UpdateEnergibrukAction
  | UpdateEnergibrukUnitAction
  | UpdateKonstruksjonstetthetAction
  | UpdateRegistrationAction
  | UpdateRegistrationFieldAction
  | UpdateTerrengskjermingAction
  | UpdateUserAction
  | UpdateCalculatedAreaAction
  | VinduAddAction
  | VinduEditAction
  | VinduRemoveAction
  | VeggsoneCopyAction;
