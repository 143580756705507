import { Button } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  attestUri?: string;
}

export const DownloadButton: FC<Props> = ({ attestUri }) => {
  const { t } = useTranslation();

  return (
    <Button
      disabled={!attestUri}
      fullWidth
      leftIcon="download"
      variant="secondary"
      href={attestUri}
    >
      {t('downloadEnergyCertificate')}
    </Button>
  );
};
