import React, { FC } from 'react';
import { NumberField } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { ConstructionSpecType } from '../../../../../utils/constructionSpecType';
import {
  camelCasify,
  kebabCasify
} from '../../../../../../../utils/navigation';
import { useUser } from '../../../../../../../hooks/useUser';
import { getYearError, WallZoneFormFieldProps } from '../utils';
import { useBuildingConstructionData } from '../useBuildingConstructionData';

export const YearOfRenovation: FC<WallZoneFormFieldProps> = ({ control }) => {
  const { t } = useTranslation();
  const { constructionYear } = useBuildingConstructionData();
  const { user } = useUser();

  return (
    <Controller
      control={control}
      name="wallZoneData.wallZone.veggKonstruksjon.rehabiliteringEllerPabyggingsAr"
      rules={{
        validate: {
          required: (value, { wallZoneData }) => {
            const yearError = getYearError(
              value ?? 0,
              user?.rolle,
              constructionYear
            );

            // Only required if detailing is set to 'yearOfRehabilitation'
            if (
              wallZoneData.detailing === 'yearOfRehabilitation' &&
              !!yearError
            ) {
              return t('register.bygningsdetaljer.byggear.error', {
                context: yearError
              });
            }
            return true;
          }
        }
      }}
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error }
      }) => {
        return (
          <NumberField
            {...rest}
            autoComplete
            helperText={error?.message}
            error={!!error}
            name={kebabCasify(ConstructionSpecType.YEAR_OF_REHABILITATION)}
            format="####"
            onValueChange={({ floatValue }) => onChange(floatValue)}
            value={value ?? null}
            label={t(
              `register.wallZone.${camelCasify(
                ConstructionSpecType.YEAR_OF_REHABILITATION
              )}.label`
            )}
          />
        );
      }}
    />
  );
};
