/* eslint-disable max-len */

export enum TiltakKategori {
  Automatikkanlegg = 'automatikkanlegg',
  Brukertiltak = 'brukertiltak',
  Bygningsmessig = 'bygningsmessig',
  Egendefinert = 'egendefinert',
  ElektriskAnlegg = 'elektriskAnlegg',
  Generelle = 'generelle',
  Luftbehandlingsanlegg = 'luftbehandlingsanlegg',
  Ovrige = 'ovrige',
  Sanitaeranlegg = 'sanitaeranlegg',
  Utendors = 'utendors',
  Varmeanlegg = 'varmeanlegg'
}

export type Tiltakskategori = {
  kategori: TiltakKategori;
  tiltaksliste: TiltakType[];
};

// Boligbygg
export enum BygningsmessigeTiltak {
  MontereTetningslister = 'montereTetningslister',
  TettingAvLuftlekasjoner = 'tettingAvLuftlekasjoner',
  EtterisoleringAvKaldtLoft = 'etterisoleringAvKaldtLoft',
  EtterisoleringAvYttertakLoft = 'etterisoleringAvYttertakLoft',
  IsoleringAvGulvMotKaldKjellerKryprom = 'isoleringAvGulvMotKaldKjellerKryprom',
  IsoleringAvGulvMotGrunn = 'isoleringAvGulvMotGrunn',
  IsoleringAvGulvMotDetFri = 'isoleringAvGulvMotDetFri',
  RandsoneIsoleringAvEtasjeSkillere = 'randsoneIsoleringAvEtasjeSkillere',
  EtterisoleringAvYttervegg = 'etterisoleringAvYttervegg',
  EtterisoleringAvKjellervegg = 'etterisoleringAvKjellervegg',
  IsoleringAvInnerveggMotUoppvarmetRom = 'isoleringAvInnerveggMotUoppvarmetRom',
  IsolereLoftsluke = 'isolereLoftsluke',
  UtskiftingAvVindu = 'utskiftingAvVindu',
  UtskiftingAvYtterdor = 'utskiftingAvYtterdor',
  TermograferingOgTetthetsProving = 'termograferingOgTetthetsProving',

  // Yrkesbygg
  IsoleringAvYttervegg = 'isoleringAvYttervegg',
  IsoleringAvInnervegg = 'isoleringAvInnervegg',
  IsoleringAvGulv = 'isoleringAvGulv',
  IsoleringAvRandsoneGulv = 'isoleringAvRandsoneGulv',
  IsoleringAvYttertak = 'isoleringAvYttertak',
  TettingOvergangGulvVegg = 'tettingOvergangGulvVegg',
  ElimineringKuldebro = 'elimineringKuldebro',
  TettelistVinduer = 'tettelistVinduer',
  TettelistYtterdorer = 'tettelistYtterdorer',
  TettingOvergangKarmVegg = 'tettingOvergangKarmVegg',
  UtskiftingDorVinduPort = 'utskiftingDorVinduPort',
  UtebdringPort = 'utbedringPort',
  AutomatiskStengingPort = 'automatiskStengingPort',
  Termografering = 'termografering'
}

export enum SanitaeranleggTiltak {
  SkifteTilSparedusj = 'skifteTilSparedusj',
  IsolereVarmtvannsror = 'isolereVarmtvannsror',

  // Yrkesbygg
  UtskiftingVarmtvannsbereder = 'utskiftingVarmtvannsbereder',
  TermostatiskBlandeventil = 'termostatiskBlandeventil',
  VannbesparendeArmatur = 'vannbesparendeArmatur',
  IsoleringavVarmtvannsror = 'isoleringavVarmtvannsror',
  VarmepumpeSanitaeranlegg = 'varmepumpeSanitaeranlegg'
}

export enum LuftbehandlingsanleggTiltak {
  MontereBlafreSpjeldPaKjokkenVentilator = 'montereBlafreSpjeldPaKjokkenVentilator',
  SkifteAvtrekksViftePaBadTilNyMedFuktStyring = 'skifteAvtrekksViftePaBadTilNyMedFuktStyring',
  MontereUrStyringPaAvtrekksVifterVentilasjonsAnlegg = 'montereUrStyringPaAvtrekksVifterVentilasjonsAnlegg',
  InstallereVentilasjonsAnleggMedVarmeGjenvinnerTilErstatningForNaturligVentilasjon = 'installereVentilasjonsAnleggMedVarmeGjenvinnerTilErstatningForNaturligVentilasjon',
  InstallereVentilasjonsAnleggMedVarmeGjenvinnerTilErstatningForMekaniskVentilasjon = 'installereVentilasjonsAnleggMedVarmeGjenvinnerTilErstatningForMekaniskVentilasjon',
  InstallereVarmeGjenvinnerIVentilasjonsAnlegg = 'installereVarmeGjenvinnerIVentilasjonsAnlegg',
  UtforeServicePaVentilasjonsAnlegg = 'utforeServicePaVentilasjonsAnlegg',

  // Yrkesbygg
  VarmegjenvinningVentilasjonsanlegg = 'varmegjenvinningVentilasjonsanlegg',
  InstillingDriftstiderVentilasjonsanlegg = 'instillingDriftstiderVentilasjonsanlegg',
  BehovsstyringVentilasjon = 'behovsstyringVentilasjon',
  VarmepumpeVarmeFraVentilasjonsluft = 'varmepumpeVarmeFraVentilasjonsluft'
}

export enum ElektriskeAnleggTiltak {
  SkifteTilLavEnergiParer = 'skifteTilLavEnergiParer',
  TemperaturOgTidsStyringAvPanelOvner = 'temperaturOgTidsStyringAvPanelOvner',
  TemperaturOgTidsStyringAvElektriskGulvVarmeTakVarme = 'temperaturOgTidsStyringAvElektriskGulvVarmeTakVarme',
  TemperaturOgTidsStyringAvElektriskLuftVarme = 'temperaturOgTidsStyringAvElektriskLuftVarme',
  TidsstyringAvPanelOvner = 'tidsstyringAvPanelOvner',
  TidsstyringAvElektriskGulvVarmeTakVarme = 'tidsstyringAvElektriskGulvVarmeTakVarme',
  TidsstyringAvElektriskLuftVarme = 'tidsstyringAvElektriskLuftVarme',

  // Yrkesbygg
  AutomatikkLysstyring = 'automatikkLysstyring',
  Lavenergiarmaturer = 'lavenergiarmaturer',
  TermostatOgTidsstyringAvElVarme = 'termostatOgTidsstyringAvElVarme',
  TidsstyringAvElVarme = 'tidsstyringAvElVarme'
}

export enum VarmeanleggTiltak {
  UtskiftingTilTermostatStyrteRadiatorVentiler = 'utskiftingTilTermostatStyrteRadiatorVentiler',
  MonteringAvTermostatStyringPaGulvVarme = 'monteringAvTermostatStyringPaGulvVarme',
  AutomatikkForShuntUteTemperaturReguleringOgNattSenkning = 'automatikkForShuntUteTemperaturReguleringOgNattSenkning',
  NattSenkningPaAutomatikkForShuntUteTemperaturRegulering = 'nattSenkningPaAutomatikkForShuntUteTemperaturRegulering',
  IsoleringAvVarmeRorVentilerPumper = 'isoleringAvVarmeRorVentilerPumper',
  InstallereLuftLuftVarmePumpe = 'installereLuftLuftVarmePumpe',
  MonteringAvPeisInnsatsIApenPeis = 'monteringAvPeisInnsatsIApenPeis',
  InstallereNyRentBrennendeVedOvnPeisInnsatsAlternativtPelletsKamin = 'installereNyRentBrennendeVedOvnPeisInnsatsAlternativtPelletsKamin',
  InstallereNyOljeParafinKaminAlternativtPelletsKaminAlternativtGassPeisGassOvn = 'installereNyOljeParafinKaminAlternativtPelletsKaminAlternativtGassPeisGassOvn',
  InstallereVarmePumpeFraBergJordVannEllerUteLuftTilVannbarentSystem = 'installereVarmePumpeFraBergJordVannEllerUteLuftTilVannbarentSystem',
  ServicePaKjelAnleggSamtEvtMonteringAvRoykGassTermometer = 'servicePaKjelAnleggSamtEvtMonteringAvRoykGassTermometer',
  UtskiftingAvBrennerPaKjelanlegg = 'utskiftingAvBrennerPaKjelanlegg',
  UtskiftingAvOljekjelTilBiokjel = 'utskiftingAvOljekjelTilBiokjel',
  InstallereSolVarmeAnlegg = 'installereSolVarmeAnlegg',
  IndividuellVarmeMalingIFlerBoligHus = 'individuellVarmeMalingIFlerBoligHus',

  // Yrkesbygg
  InnreguleringVarmeanlegg = 'innreguleringVarmeanlegg',
  ShuntreguleringNattsenking = 'shuntreguleringNattsenking',
  OmbygningTilMengderegulertSystem = 'ombygningTilMengderegulertSystem',
  UtskiftingPumper = 'utskiftingPumper',
  MontereTermostatstyrteRadiatorventiler = 'montereTermostatstyrteRadiatorventiler',
  IsoleringAvRor = 'isoleringAvRor',
  UtskiftingBrennerOljekjel = 'utskiftingBrennerOljekjel',
  UtskiftingOljefyrtKjel = 'utskiftingOljefyrtKjel',
  InstallereAutomatiskKjelvelgersentral = 'installereAutomatiskKjelvelgersentral',
  Varmepumpe = 'varmepumpe',
  OvergangTilFjernvarme = 'overgangTilFjernvarme',
  VarmeanleggBiobrensel = 'varmeanleggBiobrensel',
  InstallereSolvarmeanlegg = 'installereSolvarmeanlegg'
}

export enum UtendorsTiltak {
  MontereUrbryterPaMotorVarmer = 'montereUrbryterPaMotorVarmer',
  SkifteTilSpareParerPaUtebelysning = 'skifteTilSpareParerPaUtebelysning',
  MontereAutomatikkPaUtebelysning = 'montereAutomatikkPaUtebelysning',
  TermostatOgNedborsStyringAvSnoSmelteAnlegg = 'termostatOgNedborsStyringAvSnoSmelteAnlegg',

  // Yrkesbygg
  NedborsstyringSnosmelteanlegg = 'nedborsstyringSnosmelteanlegg',
  MotereAutomatikkUtebelysning = 'motereAutomatikkUtebelysning'
}

export enum Brukertiltak {
  FolgMedPaEnergiBrukenIBoligen = 'folgMedPaEnergiBrukenIBoligen',
  LuftKortOgEffektivt = 'luftKortOgEffektivt',
  ReduserInneTemperaturen = 'reduserInneTemperaturen',
  BrukVarmtVannFornuftig = 'brukVarmtVannFornuftig',
  SlaAvLysetOgBrukSpareParer = 'slaAvLysetOgBrukSpareParer',
  VaskMedFulleMaskiner = 'vaskMedFulleMaskiner',
  SlaElApparaterHeltAv = 'slaElApparaterHeltAv',
  SparStromPaKjokkenet = 'sparStromPaKjokkenet',
  VelgHvitevarerMedLavtForbruk = 'velgHvitevarerMedLavtForbruk',
  FyrRiktigMedVed = 'fyrRiktigMedVed',
  TiltakUtendors = 'tiltakUtendors'
}

export enum GenerelleTiltak {
  Energioppfolgingssystem = 'energioppfolgingssystem',
  DVInstruks = 'dvInstruks',
  IndividuellEnergimaling = 'individuellEnergimaling'
}

export enum AutomatikkanleggTiltak {
  InstalleringSDAnlegg = 'installeringSdAnlegg'
}

export enum OvrigeTiltak {
  Solavskjerming = 'solavskjerming',
  FrikjolingViaVentilasjon = 'frikjolingViaVentilasjon',
  FrikjolingViaIsvannsystem = 'frikjolingViaIsvannsystem',
  InnreguleringKjoleanlegg = 'innreguleringKjoleanlegg',
  OmbyggingTilMengderegulertKjolesystem = 'ombyggingTilMengderegulertKjolesystem',
  UtskiftingPumperKjolesystem = 'utskiftingPumperKjolesystem',
  GjenvinningKondensatorvarme = 'gjenvinningKondensatorvarme',
  Solcelleanlegg = 'solcelleanlegg'
}

export enum EgendefinertTiltak {
  Egendefinert = 'egendefinert'
}

export type TiltakType =
  | Brukertiltak
  | BygningsmessigeTiltak
  | ElektriskeAnleggTiltak
  | LuftbehandlingsanleggTiltak
  | SanitaeranleggTiltak
  | UtendorsTiltak
  | VarmeanleggTiltak
  | GenerelleTiltak
  | AutomatikkanleggTiltak
  | OvrigeTiltak
  | EgendefinertTiltak;

export enum TiltakStatus {
  Utfort = 0,
  Gjenstaende = 1
}

export type Tiltak = {
  beskrivelseBokmal?: string;
  beskrivelseNynorsk?: string;
  energiplanId?: string;
  navnBokmal?: string;
  navnNynorsk?: string;
  rehabilitering: boolean;
  status: TiltakStatus;
  tiltakId?: string;
  tiltaksKategori: TiltakKategori;
  portefoljeTiltakId?: string;
  grupperegistreringId?: string;
};

export type TiltakMap = Partial<Record<TiltakKategori, Tiltak[]>>;
