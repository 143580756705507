import { FC } from 'react';
import { Button, Dialog, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  errorInformation?: string[];
}

export const ErrorDialog: FC<Props> = ({
  open,
  onCancel,
  onConfirm,
  errorInformation
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} size="md" onClose={onCancel}>
      <Dialog.Title>
        {t('register.backendValidation.dialog.title')}
      </Dialog.Title>

      <Dialog.Content>
        <ul>
          {errorInformation?.map((error) => (
            <li key={error}>
              <Typography>{error}</Typography>
            </li>
          ))}
        </ul>
      </Dialog.Content>

      <Dialog.Actions>
        <Button variant="secondary" onClick={onCancel}>
          {t('register.backendValidation.dialog.cancel')}
        </Button>

        <Button onClick={onConfirm}>
          {t('register.backendValidation.dialog.confirm')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
