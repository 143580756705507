import {
  Box,
  Button,
  Dialog,
  Link,
  Typography
} from 'enova-frontend-components';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CertificateVisibility, SimpleEnergyPlan } from '../../types/building';

import { useGrantCertificateConsent } from './useGrantCertificateConsent';
import { useRevokeCertificateConsent } from './useRevokeCertificateConsent';
import { ReadMoreSection } from './readMoreSection';
import { CertificateVisibilityRadio } from './certificateVisibilityRadio';

interface Props {
  energiplan: SimpleEnergyPlan;
}

export const CertificateVisibilityConsent: FC<Props> = ({ energiplan }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const [certificateVisibility, setCertificateVisibility] =
    useState<CertificateVisibility>(energiplan.synlighet);

  const { grantCertificateConsent, isLoading: isGrantLoading } =
    useGrantCertificateConsent(energiplan.registreringId);
  const { revokeCertificateConsent, isLoading: isRevokeLoading } =
    useRevokeCertificateConsent(energiplan.registreringId);

  const isLoading = isGrantLoading || isRevokeLoading;

  const onSave = () => {
    if (certificateVisibility === 'offentlig') {
      grantCertificateConsent(energiplan.energiplanId, {
        onSuccess: () => setOpen(false)
      });
    }

    if (certificateVisibility === 'reservert') {
      revokeCertificateConsent(energiplan.energiplanId, {
        onSuccess: () => setOpen(false)
      });
    }
  };

  return (
    <Fragment>
      <Link transparentBackground onClick={() => setOpen(true)} button>
        {t('energyCertificateVisibilityConsent.link')}
      </Link>

      <Dialog open={open}>
        <Dialog.Title
          title={t('energyCertificateVisibilityConsent.dialog.title')}
        >
          {t('energyCertificateVisibilityConsent.dialog.title')}
        </Dialog.Title>

        <Dialog.Content>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography>
              {t('energyCertificateVisibilityConsent.dialog.info_short')}
              <sup>1</sup>
            </Typography>

            <Typography>
              {t('energyCertificateVisibilityConsent.dialog.info.reserve')}
            </Typography>
            <Typography>
              {t('energyCertificateVisibilityConsent.dialog.info.revoke')}
            </Typography>

            <CertificateVisibilityRadio
              onChange={setCertificateVisibility}
              value={certificateVisibility}
              isLoading={isLoading}
            />
          </Box>
          <ReadMoreSection />
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            disabled={isLoading}
            variant="secondary"
            onClick={() => setOpen(false)}
          >
            {t('energyCertificateVisibilityConsent.dialog.button.cancel')}
          </Button>

          <Button
            loading={isLoading}
            onClick={onSave}
            disabled={energiplan.synlighet === certificateVisibility}
          >
            {t('energyCertificateVisibilityConsent.dialog.button.save')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Fragment>
  );
};
