import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, FactBox, styled } from 'enova-frontend-components';

import DismissableInfoBox from '../../../components/dismissableInfoBox';
import { BuildingScreenTab, camelCasify } from '../../../utils/navigation';
import ReadonlyField from '../../../components/readonlyField';
import ReadonlyParagraphField from '../../../components/readonlyParagraphField';
import { DetailedBuildingDetails } from '../../../types/building';
import {
  GetEnergiObjektTypeFromMatrikkel,
  GetKategoriFromMatrikkel,
  GetTypeFromMatrikkel,
  isBuildingTemporary
} from '../../../utils/matrikkelBygningsTyper';
import { User, UserType } from '../../../types/user';

import { CreateTempBuildingLink } from './createTempBuildingLink';
import { useBuildingExpertReports } from './useExpertReports';

const Wrapper = styled(Box)`
  display: grid;
  gap: ${({ theme }) => theme.spacing(1)};
  grid-template-columns: repeat(auto-fit, 200px);

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.sm}px) {
    grid-auto-flow: row dense;
    gap: ${({ theme }) => theme.spacing(3)};

    div:last-child {
      grid-column: span 2;
    }
  }
`;

interface AboutProps {
  building: DetailedBuildingDetails;
  user?: User;
}

const About: FC<AboutProps> = ({ building, user }) => {
  const { t } = useTranslation();
  const { expertReports } = useBuildingExpertReports(building.energiObjektId);

  const { elkjel, kjoleanlegg, varmeanlegg, ventilasjon } = expertReports;

  const {
    bruksAreal,
    bruksEnhetsNummer,
    bruksNummer,
    byggAr,
    bygningsNummer,
    gardsNummer,
    bygningsTypeNummer,
    bruksenhetsIdentId,
    kanEnergimerkesAvPrivatPerson,
    andelNummer,
    seksjonsNummer
  } = building;

  const energiObjektType = GetEnergiObjektTypeFromMatrikkel(
    kanEnergimerkesAvPrivatPerson
  );

  return (
    <Box mt={4}>
      <DismissableInfoBox
        className="mb-4"
        inline={false}
        localStorageKey={user?.userId}
      >
        {t(`buildingScreen.tab.${camelCasify(BuildingScreenTab.ABOUT)}.info`, {
          context: energiObjektType
        })}
      </DismissableInfoBox>

      <FactBox
        title={t('buildingScreen.tab.omBoligen.boxTitle', {
          context: energiObjektType
        })}
        TitleProps={{ component: 'h2' }}
      >
        <Wrapper>
          <ReadonlyField
            label={t('buildingScreen.tab.omBoligen.category')}
            value={
              bygningsTypeNummer
                ? GetKategoriFromMatrikkel(bygningsTypeNummer)
                : '-'
            }
          />

          <ReadonlyField
            label={t('buildingScreen.tab.omBoligen.type')}
            value={
              bygningsTypeNummer
                ? GetTypeFromMatrikkel(bygningsTypeNummer)
                : '-'
            }
          />

          <ReadonlyField
            label={t('buildingScreen.tab.omBoligen.area')}
            value={bruksAreal ? t('squareMeters', { area: bruksAreal }) : '-'}
          />

          <ReadonlyField
            label={t('buildingScreen.tab.omBoligen.year')}
            value={byggAr || '-'}
          />

          <ReadonlyField
            label={t('buildingScreen.tab.omBoligen.gardsnummer')}
            value={gardsNummer || '-'}
          />

          <ReadonlyField
            label={t('buildingScreen.tab.omBoligen.bruksnummer')}
            value={bruksNummer || '-'}
          />

          <ReadonlyField
            label={t('buildingScreen.tab.omBoligen.seksjonsnummer')}
            value={seksjonsNummer || '-'}
          />

          <ReadonlyField
            label={t('buildingScreen.tab.omBoligen.bygningsnummer')}
            value={bygningsNummer || '-'}
          />

          <ReadonlyField
            label={t('buildingScreen.tab.omBoligen.andelsnummer')}
            value={andelNummer || '-'}
          />

          <ReadonlyField
            label={t('buildingScreen.tab.omBoligen.bolignummer')}
            value={bruksEnhetsNummer || '-'}
          />

          {user?.type === UserType.EXPERT && (
            <ReadonlyField
              label={t('buildingScreen.tab.omBoligen.bruksenhetsIdentId')}
              value={
                isBuildingTemporary(bruksenhetsIdentId)
                  ? t('buildingScreen.tab.omBoligen.isTemporary')
                  : bruksenhetsIdentId || '-'
              }
            />
          )}
        </Wrapper>

        {isBuildingTemporary(bruksenhetsIdentId) && (
          <Box mt={4}>
            <Divider />
            <Box mt={4}>
              <CreateTempBuildingLink building={building} />
            </Box>
          </Box>
        )}
      </FactBox>

      {varmeanlegg && (
        <FactBox
          className="mt-4"
          title={t(`buildingScreen.tab.omBoligen.varmeanlegg`)}
        >
          <ReadonlyParagraphField
            label={varmeanlegg.tittel}
            value={varmeanlegg.rapport}
          />
        </FactBox>
      )}

      {elkjel && (
        <FactBox
          className="mt-4"
          title={t(`buildingScreen.tab.omBoligen.elkjel`)}
        >
          <ReadonlyParagraphField
            label={elkjel.tittel}
            value={elkjel.rapport}
          />
        </FactBox>
      )}

      {kjoleanlegg && (
        <FactBox
          className="mt-4"
          title={t(`buildingScreen.tab.omBoligen.kjoleanlegg`)}
        >
          <ReadonlyParagraphField
            label={kjoleanlegg.tittel}
            value={kjoleanlegg.rapport}
          />
        </FactBox>
      )}

      {ventilasjon && (
        <FactBox
          className="mt-4"
          title={t(`buildingScreen.tab.omBoligen.ventilasjon`)}
        >
          <ReadonlyParagraphField
            label={ventilasjon.tittel}
            value={ventilasjon.rapport}
          />
        </FactBox>
      )}
    </Box>
  );
};

export default About;
