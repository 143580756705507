import React, { FC, Fragment, HTMLProps, ReactNode, useMemo } from 'react';
import {
  Box,
  Button,
  CONTENT_PADDING,
  CONTENT_WIDTH,
  Hidden,
  Layout as LayoutBase,
  MainProps,
  styled
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import RouterLink from '../routerLink';
import { useUser } from '../../hooks/useUser';
import { camelCasify, DefaultMainScreenTab } from '../../utils/navigation';
import { ActionBarHeight } from '../../utils/layout';

import UserMenu from './components/userMenu';
import MainMenu from './components/mainMenu';
import Footer from './components/footer';
import EMSLogo from './components/logoWrapper';
import LanguageMenu from './languageMenu';

const ActionBar = styled((props) => <Box {...props} />)`
  background: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  height: ${ActionBarHeight}px;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.appBar};
`;

const StyledAppBar = styled(LayoutBase.AppBar)`
  top: ${ActionBarHeight}px;
  box-shadow: none;
  min-height: 64px;

  .user-select--button {
    ${({ theme }) => theme.breakpoints.up('md')} {
      max-width: 19vw;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
      max-width: 21vw;
    }

    ${({ theme }) => theme.breakpoints.up('xl')} {
      max-width: 30vw;
    }
  }

  a {
    ${({ theme }) => theme.breakpoints.up('sm')} {
      width: auto;
      max-width: 60vw;
    }
  }
` as typeof LayoutBase.AppBar;

const StyledLayoutBaseMain = styled(LayoutBase.Main)`
  margin-top: ${ActionBarHeight}px;
`;

export type LayoutProps = {
  fullWidth?: boolean;
  MainProps?: MainProps;
  children: ReactNode;
};

const HiddenLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  transform: translateY(-100%);
  transition: transform 0.3s;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.appBar + 1};
  transition: ${({ theme }) => theme.transitions.duration.shortest}ms;

  &:focus-within {
    transform: translateY(calc(0% + 4px));
  }
`;

const HiddenLink = styled(Button)`
  height: 40px;
`;

const Layout: FC<LayoutProps> = ({
  children,
  fullWidth,
  MainProps: mainProps
}) => {
  const { t } = useTranslation();

  const mainId = useMemo(() => mainProps?.id ?? 'main', []);
  const { user } = useUser();

  const title = t('navigation.goToScreen', {
    screen: t(`mainScreen.tab.${camelCasify(DefaultMainScreenTab)}`, {
      context: user?.type
    }).toLowerCase()
  });

  return (
    <Fragment>
      <HiddenLinkWrapper>
        <HiddenLink variant="secondary" href={`#${mainId}`}>
          {t('skipToContent')}
        </HiddenLink>
      </HiddenLinkWrapper>

      <ActionBar>
        <LanguageMenu />
      </ActionBar>

      <LayoutBase>
        <StyledAppBar
          leftContent={<MainMenu />}
          logoProps={{
            ...({
              component: RouterLink,
              to: '/'
            } as HTMLProps<HTMLAnchorElement>),
            'aria-label': title,
            title,
            children: <EMSLogo />
          }}
          rightContent={
            <Hidden mdDown>
              <UserMenu />
            </Hidden>
          }
        />

        <StyledLayoutBaseMain {...mainProps} id={mainId}>
          {fullWidth ? (
            children
          ) : (
            <Box margin="0 auto" maxWidth={CONTENT_WIDTH} p={CONTENT_PADDING}>
              {children}
            </Box>
          )}
        </StyledLayoutBaseMain>

        <Footer />
      </LayoutBase>
    </Fragment>
  );
};

export default Layout;
