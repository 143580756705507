import React, { FC } from 'react';
import { Grid, Loader, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

interface LoadingIndicatorProps {
  text?: string;
}

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({ text }) => {
  const { t } = useTranslation();

  return (
    <Grid
      alignItems="center"
      container
      direction="column"
      marginTop={8}
      spacing={4}
    >
      <Grid item>
        <Loader size="large" />
      </Grid>

      <Grid item>
        <Typography>
          {text ? t(text) : t('register.parameters.loading')}
        </Typography>
      </Grid>
    </Grid>
  );
};
