import { SelectOption, SelectValue } from 'enova-frontend-components';

import {
  DetailingStep,
  Energienhet,
  Energikilde
} from '../components/registering/utils/registerEnums';

export const detailingStepList: DetailingStep[] = [
  DetailingStep.Bygningsform,
  DetailingStep.Vegger,
  DetailingStep.Vinduer,
  DetailingStep.Takkonstruksjon,
  DetailingStep.Gulv,
  DetailingStep.Ytterdorer,
  DetailingStep.Energibruk,
  DetailingStep.Lekkasjetall
];

export const TekniskUtstyrKeys = [
  'oppvarmingBioenergi',
  'oppvarmingElektrisk',
  'oppvarmingFjernvarme',
  'oppvarmingGass',
  'oppvarmingOlje',
  'oppvarmingSol',
  'oppvarmingVarmepumpe',
  'oppvarmingVed'
] as const;

export type TekniskUtstyrKey = typeof TekniskUtstyrKeys[number];

export const energySourceUnitMap: Partial<
  Record<
    TekniskUtstyrKey,
    { energySource: Energikilde; unit: Energienhet | Energienhet[] }
  >
> = {
  oppvarmingElektrisk: {
    unit: Energienhet.kWh,
    energySource: Energikilde.Elektrisk
  },
  oppvarmingOlje: {
    unit: Energienhet.liter,
    energySource: Energikilde.OljeParafin
  },
  oppvarmingBioenergi: {
    unit: Energienhet.kg,
    energySource: Energikilde.Bioenergi
  },
  oppvarmingFjernvarme: {
    unit: Energienhet.kWh,
    energySource: Energikilde.Fjernvarme
  },
  oppvarmingGass: {
    unit: [Energienhet.kg, Energienhet.standardKubikkmeter],
    energySource: Energikilde.Gass
  },
  oppvarmingVed: {
    unit: [Energienhet.liter, Energienhet.favner],
    energySource: Energikilde.Ved
  }
};

export const getKeysWithValue = (obj?: Record<string, unknown>): string[] =>
  Object.keys(obj ?? {});

export const getSelectOptionsFromEnum = (
  e: Record<string, string>,
  generateLabel?: (label: string) => string
): SelectOption[] => {
  const entries = Object.entries(e);

  return entries.map(([, value]) => ({
    label: generateLabel ? generateLabel(value) : value,
    value
  }));
};

export const getRadioOptionsFromEnum = (
  e: Record<string, string>,
  width?: 'auto' | 'fullWidth',
  generateLabel?: (label: string) => string
): SelectOption[] => {
  const entries = Object.entries(e);

  return entries.map(([, value]) => ({
    label: generateLabel ? generateLabel(value) : value,
    value,
    width
  }));
};

export const getRadioOptionsWithTLabelKey = (
  e: Record<string, string>,
  width?: 'auto' | 'fullWidth',
  generateLabel?: (label: string) => string
): {
  tLabelKey: SelectValue;
  label: SelectValue;
  value: SelectValue;
  disabled?: boolean | undefined;
}[] =>
  getRadioOptionsFromEnum(e, width, (label) =>
    generateLabel ? generateLabel(label) : `register.${label}`
  ).map((option) => {
    const label = option.label;
    delete (option as Partial<SelectOption>).label;
    return { ...option, tLabelKey: label };
  });

export const getEtasjerTOptions = (
  etasjeCount: number,
  allFloorsSameShape: boolean,
  erKjeller: boolean,
  etasjeNummer: number
): { context?: string; count?: number } | undefined => {
  if (etasjeCount === 1) return { context: 'singleFloor' };
  if (allFloorsSameShape) return undefined;
  if (erKjeller) return { context: 'basement' };
  return { context: 'floorNo', count: etasjeNummer };
};

export const NullishValues = [null, undefined, '', [], {}, 0];
