import { FC } from 'react';
import { Link as EnovaLink, Typography } from 'enova-frontend-components';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  BuildingScreenTab,
  getBuildingScreenPath
} from '../../../utils/navigation';

interface PublishedErrorLinkProps {
  buildingId: string;
  tab: BuildingScreenTab;
  i18nKey: string;
}

export const PublishedErrorLink: FC<PublishedErrorLinkProps> = ({
  buildingId,
  tab,
  i18nKey
}) => {
  const { push } = useHistory();

  return (
    <Typography>
      <Trans
        i18nKey={i18nKey}
        components={[
          <EnovaLink
            button
            key={0}
            variant="inherit"
            onClick={() =>
              push(
                getBuildingScreenPath({
                  buildingId,
                  tab
                })
              )
            }
          />
        ]}
      />
    </Typography>
  );
};
