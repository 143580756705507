import React, { FC } from 'react';
import { Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { CalculationParameterCategory } from '../../../../../types/calculationParameters';
import {
  StyledTable,
  unitWidth,
  useAdjustParametersFormContext
} from '../utils';

import { CalculatedValue, ExpertValue, Title } from './components';

interface LargeScreenItemProps {
  category: CalculationParameterCategory;
}

export const LargeScreenItem: FC<LargeScreenItemProps> = ({ category }) => {
  const { t } = useTranslation();

  const { control, trigger } = useAdjustParametersFormContext();
  const valueCanBeNegative = (parameterKey: string) => {
    return parameterKey === 'frostsikringstemperatur';
  };

  return (
    <StyledTable unitWidt={unitWidth}>
      <thead>
        <tr>
          <td>{t('register.parameters.name')}</td>
          <td>{t('register.parameters.calculated')}</td>
          <td>{t('register.parameters.new')}</td>
          <td>
            <Typography variant="srOnly">{t('unit')}</Typography>
          </td>
        </tr>
      </thead>

      <tbody>
        {Object.entries(category).map(
          ([parameterKey, { calculatedValue, unit }]) => {
            return (
              <tr key={parameterKey}>
                <td>
                  <Title parameterKey={parameterKey} />
                </td>

                <td>
                  <CalculatedValue
                    calculatedValue={calculatedValue}
                    parameterKey={parameterKey}
                    allowNegative={valueCanBeNegative(parameterKey)}
                  />
                </td>

                <td>
                  <ExpertValue
                    control={control}
                    trigger={trigger}
                    parameterKey={parameterKey}
                    allowNegative={valueCanBeNegative(parameterKey)}
                  />
                </td>

                <td>
                  <Typography>{unit}</Typography>
                </td>
              </tr>
            );
          }
        )}
      </tbody>
    </StyledTable>
  );
};
