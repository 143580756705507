import { Select, SelectOption } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { useWallFormContext } from '../../../utils';
import { WallDetailingOption } from '../../../types';
import { WallZoneFormFieldProps } from '../utils';

export const DetailingSelect: FC<WallZoneFormFieldProps> = ({ control }) => {
  const { t } = useTranslation();
  const { setValue } = useWallFormContext();

  const detailingOptions: SelectOption[] = [
    {
      label: t('select'),
      value: -1,
      disabled: true
    },
    {
      label: t('register.wallZone.detailing.option.construction'),
      value: 'construction',
      disabled: false
    },
    {
      label: t('register.wallZone.detailing.option.yearOfRehabilitation'),
      value: 'yearOfRehabilitation',
      disabled: false
    },
    {
      label: t('register.wallZone.detailing.option.unchanged'),
      value: 'unchanged',
      disabled: false
    },
    {
      label: t('register.wallZone.detailing.option.unknown'),
      value: 'unknown',
      disabled: false
    }
  ];

  const handleValueChange = (detailingOption: WallDetailingOption) => {
    // Reset construction data on change
    setValue('wallZoneData.wallZone.veggKonstruksjon', {});

    // Set window data programmatically if 'unknown' or 'unchanged' is selected
    if (detailingOption === 'unchanged') {
      setValue('wallZoneData.wallZone.veggKonstruksjon', {
        uendretSidenOppforing: true
      });
    }

    if (detailingOption === 'unknown') {
      setValue('wallZoneData.wallZone.veggKonstruksjon', {
        brukStandardVerdier: true
      });
    }
  };

  return (
    <Controller
      control={control}
      rules={{ validate: (value) => value !== -1 || t('required') }}
      name="wallZoneData.detailing"
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error }
      }) => {
        return (
          <Select
            {...rest}
            error={!!error}
            helperText={error?.message}
            fullWidth
            label={t('register.wallZone.information')}
            aria-label={t('register.wallZone.information')}
            options={detailingOptions}
            value={value ?? -1}
            HelperBoxProps={{
              'aria-label': t('readFieldInformation'),
              children: t('register.wallType.information')
            }}
            onChange={(e) => {
              onChange(e.currentTarget.value);
              handleValueChange(e.currentTarget.value as WallDetailingOption);
            }}
          />
        );
      }}
    />
  );
};
