import { FC, Fragment } from 'react';
import { FullScreenLoader, Grid } from 'enova-frontend-components';

import useSelector from '../../../../hooks/useSelector';
import { getHasErrors } from '../../../../components/registering/utils/validation';
import useRegistrationForm from '../../useRegistrationForm';
import ErrorMessage from '../errorMessage';

import { NextStepButton, PreviousStepButton } from './buttons';

export const RegistrationActionButtons: FC = () => {
  const {
    displayErrors,
    startedObjectErrors,
    goToNextStep,
    goToPrevStep,
    loadingStep,
    nextStep,
    prevStep,
    step
  } = useRegistrationForm();

  const hasErrors = useSelector(
    (state) =>
      getHasErrors(state, step) || Object.keys(startedObjectErrors).length > 0
  );

  const disableSubmit = displayErrors && hasErrors;

  return (
    <Fragment>
      <FullScreenLoader open={!!loadingStep} />

      {disableSubmit && (
        <Grid item xs={12}>
          <ErrorMessage />
        </Grid>
      )}

      <Grid
        item
        xs={12}
        container
        spacing={4}
        mt={10}
        direction={{ xs: 'column', sm: 'row' }}
      >
        {prevStep && (
          <Grid flex={1} item sm="auto" xs={12}>
            <PreviousStepButton disabled={loadingStep} onClick={goToPrevStep} />
          </Grid>
        )}

        {nextStep && (
          <Grid flex={1} item sm="auto" ml={{ sm: 'auto' }} xs={12}>
            <NextStepButton
              onClick={goToNextStep}
              disabled={disableSubmit || loadingStep}
            />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};
