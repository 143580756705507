import { Box, styled } from 'enova-frontend-components';
import React, { FC } from 'react';

import { BuildingShape } from '../../shapeAndArea/components/buildingShape';
import { Vegg, Veggsone } from '../../../../../types/registration/userInput';
import { BygningsForm, Himmelretning } from '../../../utils/registerEnums';
import { CustomAccordionItem } from '../../../../customAccordionItem';
import { HighlightedShapeWrapper } from '../../../../highlightedShapeWrapper';

import { HasWindowsRadio } from './hasWindowsRadio';
import { WindowList } from './windowList';
import { AddWindow } from './addWindow';
import { WindowsEmptyState } from './windowsEmptyState';
import { WindowWallTitle } from './windowWallTitle';

const hasNoWindows = (veggsone: Veggsone) =>
  veggsone?.vinduer?.length || veggsone?.vinduer?.length === 0;

const WallWrapper = styled(Box)`
  background-color: #eef9f5;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

interface Props {
  wall: Vegg;
  etasjeId: string;
  shape?: BygningsForm;
}

export const Wall: FC<Props> = ({ wall, etasjeId, shape }) => {
  const hasWallZones = wall.veggSoner.length > 0;

  if (!hasWallZones) {
    return (
      <CustomAccordionItem title={<WindowWallTitle wall={wall} />}>
        <WallWrapper>
          <WindowsEmptyState />
        </WallWrapper>
      </CustomAccordionItem>
    );
  }

  // Display window form by default if wall has no windows
  const displayWindowForm = wall.veggSoner.every(hasNoWindows);

  return (
    <CustomAccordionItem title={<WindowWallTitle wall={wall} />}>
      <WallWrapper>
        <HighlightedShapeWrapper highlightedWallId={wall.veggId}>
          <BuildingShape shape={shape} direction={Himmelretning.Nord} />
        </HighlightedShapeWrapper>
        <Box display="flex" flexDirection="row" justifyContent="center">
          <HasWindowsRadio
            value={!!wall.hasWindows}
            veggId={wall.veggId}
            etasjeId={etasjeId}
          />
        </Box>
        {wall.hasWindows && (
          <>
            {wall.veggSoner.map((zone, veggsoneIndex) => (
              <WindowList
                key={veggsoneIndex}
                etasjeId={etasjeId}
                wallId={wall.veggId}
                veggsoneId={zone.id}
              />
            ))}
            <AddWindow
              etasjeId={etasjeId}
              wallId={wall.veggId}
              formVisible={displayWindowForm}
            />
          </>
        )}
      </WallWrapper>
    </CustomAccordionItem>
  );
};
