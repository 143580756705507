import React, { FC } from 'react';
import { Box, FactBox } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import useSelector from '../../../../hooks/useSelector';
import { getHasVentilationData } from '../../../../store/registration/selectors';
import useRegistrationForm from '../../../../screens/registration/useRegistrationForm';
import DynamicField from '../../dynamicField';
import { VentilationFields } from '../../utils/registerConfig';

const Ventilation: FC = () => {
  const { t } = useTranslation();
  const { displayErrors } = useRegistrationForm();

  const hasValue = useSelector(getHasVentilationData);

  return (
    <FactBox
      error={displayErrors && !hasValue}
      id="ventilation-factbox-title"
      title={t('register.ventilation.title')}
    >
      <Box display="flex" flexDirection="column">
        {VentilationFields.map((defs) => (
          <DynamicField
            {...defs}
            aria-labelledby="ventilation-factbox-title"
            key={defs.key ?? defs.attrKey}
          />
        ))}
      </Box>
    </FactBox>
  );
};

export default Ventilation;
