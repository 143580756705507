import { useMutation, useQueryClient } from 'react-query';

import { XMLReport } from '../../../../../../types/expertToolsXML';
import { queryKeys } from '../../../../../../utils/react-query';
import * as api from '../../../../../../services/api';

export const useCreateFlerboligRegistration = (buildingId: string) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    ({
      report,
      bruksenhetsIds,
      groupName
    }: {
      report: XMLReport;
      bruksenhetsIds: string[];
      groupName: string;
    }) => api.createFlerboligRegistrations(report, bruksenhetsIds, groupName),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          queryKeys.energyAnalysisList,
          buildingId
        ]);
      }
    }
  );

  return { createFlerboligRegistration: mutate, isLoading };
};
