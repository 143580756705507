import React, { FC, Fragment, useState } from 'react';
import { Grid, Typography, useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import useExpertToolsXMLPortfolio from '../../useExpertToolsXMLPortfolio';
import { SimpleEnergyAnalysis } from '../../../../../types/building';
import { createErrorMessage } from '../../../../../utils/utils';

import { ParsedContent } from './models';
import { FileList } from './fileList';
import { DeleteRegistrationDialog } from './deleteRegistrationDialog';
import { useDeleteRegistration } from './useDeleteRegistration';

const parseData = (
  simpleEnergyAnalyses: SimpleEnergyAnalysis[]
): ParsedContent[] => {
  return simpleEnergyAnalyses.reduce(
    (
      acc: ParsedContent[],
      {
        registreringId,
        registrering: { energiObjektId },
        energiplan: { energimerke: { ekspertInndata = undefined } = {} } = {}
      }
    ) => {
      let inputJson;

      try {
        if (ekspertInndata) inputJson = JSON.parse(ekspertInndata);
        else return acc;
      } catch (err: unknown) {
        console.error(err);
        return acc;
      }

      return [...acc, { registreringId, energiObjektId, inputJson }];
    },
    []
  );
};

const RegistrationList: FC = () => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();
  const [registrationToDelete, setRegistrationToDelete] = useState<{
    registreringId: string;
    BruksenhetsIdentId?: number | string;
  }>();

  const { simpleEnergyAnalyses, tiltakList, refreshPortefoljeData } =
    useExpertToolsXMLPortfolio();
  const { deleteRegistration, isLoading } = useDeleteRegistration(
    refreshPortefoljeData
  );

  const parsedData = parseData(simpleEnergyAnalyses);

  const confirmDeleteRegistration = () => {
    if (!registrationToDelete) {
      return;
    }

    deleteRegistration(registrationToDelete.registreringId, {
      onSuccess: () => {
        setRegistrationToDelete(undefined);
        addSnack(t('registration.delete.success'), {
          variant: 'success'
        });
      },
      onError: () => {
        addSnack(createErrorMessage(t('registration.delete.error')), {
          variant: 'error'
        });
      }
    });
  };

  const tableCaptionId = 'expertToolsXMLPortfolio-worklist-heading';

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h2" id={tableCaptionId}>
          {t('expertToolsXMLPortfolio.worklist.heading')}
        </Typography>

        <FileList
          data={parsedData}
          tableCaptionId={tableCaptionId}
          setRegistrationToDelete={(data) => setRegistrationToDelete(data)}
          tiltakList={tiltakList}
        />
      </Grid>

      <DeleteRegistrationDialog
        bruksenhetsIdentId={registrationToDelete?.BruksenhetsIdentId}
        deleting={isLoading}
        isOpen={!!registrationToDelete}
        onClose={
          isLoading ? undefined : () => setRegistrationToDelete(undefined)
        }
        onConfirm={confirmDeleteRegistration}
      />
    </Fragment>
  );
};

export default RegistrationList;
