import { Checkbox } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BuildingDetails } from '../../../../../../../../../types/building';
import { useCategoryFilter } from '../useCategoryFilter';
import { useXmlRegistrationSearchParams } from '../../../../hooks/useXmlRegistrationSearchParams';

interface Props {
  searchResult: BuildingDetails[];
}

export const SelectAllCheckbox: FC<Props> = ({ searchResult }) => {
  const { setBuildingSelection, selectedBuildingIds } =
    useXmlRegistrationSearchParams();
  const { t } = useTranslation();

  const { selectedCategory } = useCategoryFilter();

  const isAllBuildingsSelected = searchResult?.every((buildingId) =>
    selectedBuildingIds.map((b) => b).includes(buildingId.bruksenhetsIdentId)
  );

  const selectedCategoryFilter = (building: BuildingDetails) =>
    !selectedCategory || building.bygningsTypeNummer === selectedCategory;

  const handleSelectAll = (checked?: boolean) => {
    const allBuildingIds = searchResult
      ?.filter(selectedCategoryFilter)
      .map((building) => building.bruksenhetsIdentId);

    setBuildingSelection(checked ? allBuildingIds : []);
  };

  return (
    <Checkbox
      label={t('expertToolsXML.selectMultiple.selectAll')}
      aria-label={t('expertToolsXML.selectMultiple.selectAll')}
      onChange={(_, checked) => handleSelectAll(checked)}
      checked={isAllBuildingsSelected}
    />
  );
};
