import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BuildingScreenTab,
  camelCasify
} from '../../../../../../../utils/navigation';
import DismissableInfoBox from '../../../../../../../components/dismissableInfoBox';
import { useUser } from '../../../../../../../hooks/useUser';

export const EnergyCertificateInfoBox = () => {
  const { user } = useUser();
  const { t } = useTranslation();

  if (!user) {
    return null;
  }

  const infoText = t(
    `buildingScreen.tab.${camelCasify(
      BuildingScreenTab.ENERGY_CERTIFICATE
    )}.info`,
    {
      context: user.type
    }
  );

  return (
    <DismissableInfoBox
      className="mb-4"
      inline={false}
      localStorageKey={user.userId}
    >
      {infoText}
    </DismissableInfoBox>
  );
};
