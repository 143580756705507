import { createContext } from 'react';

import { BuildingDetails } from '../../types/building';
import {
  BuildingCategoryFilters,
  EnergiCertificateFilters,
  StatusFilters
} from '../../utils/filters';
import { fylkerNames } from '../../utils/kommuner';

export enum FilterCategory {
  BUILDING_CATEGORY = 'buildingCategory',
  ENERGY_CERTIFICATE = 'energyCertificate',
  GEOGRAPHY = 'geography',
  STATUS = 'status'
}

export const CategoryOptions: Record<FilterCategory, string[]> = {
  // TODO: Translate
  [FilterCategory.BUILDING_CATEGORY]: Object.values(BuildingCategoryFilters),
  [FilterCategory.GEOGRAPHY]: fylkerNames,
  [FilterCategory.ENERGY_CERTIFICATE]: Object.values(EnergiCertificateFilters),
  [FilterCategory.STATUS]: Object.values(StatusFilters)
};

export enum SortAttribute {
  ADDRESS = 'address',
  LABEL = 'energyRating',
  REGISTERED = 'registeredDate'
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export type Order = [SortAttribute, SortOrder];

export const ORDER_BY = 'orderBy';

export type FilterValues = Record<FilterCategory, string[]>;

export type Mode = FilterCategory | typeof ORDER_BY;

export type BuildingListValues = {
  buildings?: BuildingDetails[];
  buildingsError: boolean;
  buildingsLoading: boolean;
  refreshBuildings: () => void;
};

export type BuildingFilterContextValues = {
  allowFilter?: boolean;
  allowSearch?: boolean;
  buildingList?: BuildingListValues;
  categories?: FilterCategory[];
  clearAll: () => void;
  clearFilterCategory: (category: FilterCategory) => void;
  clearSortOrder: () => void;
  filterValues: FilterValues;
  handleSearch: () => void;
  mode?: Mode;
  order: Order;
  searchString?: string;
  setMode: (mode?: Mode) => void;
  setSearchString: (searchString: string) => void;
  updateFilterValues: (
    category: FilterCategory,
    option: string,
    checked: boolean
  ) => void;
  updateOrder: (newAttr: SortAttribute) => void;
  body: string;
};

const BuildingFilterContext = createContext<BuildingFilterContextValues>({
  clearAll: () => true,
  clearFilterCategory: () => true,
  clearSortOrder: () => true,
  filterValues: {
    [FilterCategory.BUILDING_CATEGORY]: [],
    [FilterCategory.ENERGY_CERTIFICATE]: [],
    [FilterCategory.GEOGRAPHY]: [],
    [FilterCategory.STATUS]: []
  },
  handleSearch: () => true,
  order: [SortAttribute.ADDRESS, SortOrder.ASC],
  setMode: () => true,
  setSearchString: () => true,
  updateFilterValues: () => true,
  updateOrder: () => true,
  body: ''
});

export default BuildingFilterContext;
