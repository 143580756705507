import React, { FC, Fragment } from 'react';
import {
  Box,
  Divider,
  FormMessage,
  MainContentBox,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import RouterLink from '../../components/routerLink';
import {
  AllRegistrationScreenParams,
  BuildingScreenTab,
  getBuildingScreenPath
} from '../../utils/navigation';
import BuildingHeaderInfo from '../../components/buildingHeaderInfo';
import { LoadingIndicator } from '../../components/loadingIndicator';
import { SoknadStegMap } from '../../types/soknad';

import useRegistrationForm from './useRegistrationForm';
import { useGetUserInput } from './useGetUserInput';
import { RegistrationFormWrapper } from './registration';

interface RegistrationScreenContentProps {
  soknadStegMap: SoknadStegMap | undefined;
}

const RegistrationScreenContent: FC<RegistrationScreenContentProps> = ({
  soknadStegMap
}) => {
  const { buildingId } = useParams<AllRegistrationScreenParams>();
  const { t } = useTranslation();

  const { updateSteps, soknadSteg, regId, registrationIsPublished, step } =
    useRegistrationForm();
  const { isLoading, userInputError } = useGetUserInput(regId, updateSteps);

  return (
    <Fragment>
      <MainContentBox>
        <RouterLink
          className="mb-3 as-start"
          to={getBuildingScreenPath({
            buildingId,
            tab: soknadSteg
              ? BuildingScreenTab.DOCUMENTS
              : BuildingScreenTab.ENERGY_RATING
          })}
        >
          {t('navigation.registerScreen.back')}
        </RouterLink>

        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <Typography variant="h1" mb={0}>
            {t('registerScreen.pageTitle', { context: soknadSteg })}
          </Typography>

          <BuildingHeaderInfo
            energiobjektId={buildingId}
            AddressProps={{ variant: 'h2', component: 'p' }}
          />
        </Box>
      </MainContentBox>

      <Divider />

      <MainContentBox>
        {isLoading ? (
          <LoadingIndicator text="loadingContent" />
        ) : userInputError ? (
          <FormMessage
            fullWidth
            role="alert"
            title={t('registerScreen.loadRegistrationFailed.title')}
          >
            {t('registerScreen.loadRegistrationFailed.description')}
          </FormMessage>
        ) : (
          <RegistrationFormWrapper
            soknadStegMap={soknadStegMap}
            isPublished={registrationIsPublished}
            step={step}
          />
        )}
      </MainContentBox>
    </Fragment>
  );
};

export default RegistrationScreenContent;
