import { Box, styled, Typography } from 'enova-frontend-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Vindu } from '../../../../types/registration/userInput';
import { camelCasify } from '../../../../utils/navigation';
import { WindowSpecType } from '../../utils/window';

import { useWindowInfo } from './useWindowInfo';

const InfoWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 35px;
`;

const ItemWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1 0 150px;
  gap: 5px;
`;

interface WindowInfoProps {
  numberOfEqualWindows: number;
  window: Vindu;
}

export const WindowInfo: FC<WindowInfoProps> = ({
  numberOfEqualWindows,
  window
}) => {
  const { t } = useTranslation();
  const { windowSpecType, windowValue } = useWindowInfo(window);

  return (
    <Box display="flex" flexDirection="column">
      <Box marginBottom={2}>
        <Typography bold>{t('register.window.windowNo')}</Typography>
      </Box>

      <InfoWrapper>
        <ItemWrapper>
          <Typography variant="body2">{t('area')}</Typography>

          {window?.arealIKvm != null && (
            <Typography variant="body2" bold>
              {t('squareMeters', { area: window?.arealIKvm })}
            </Typography>
          )}
        </ItemWrapper>

        <ItemWrapper>
          <Typography variant="body2">
            {t('register.window.specType')}
          </Typography>

          <Typography variant="body2" bold>
            {t('register.window.specType', {
              context: camelCasify(windowSpecType)
            })}
          </Typography>
        </ItemWrapper>

        <ItemWrapper>
          <Typography variant="body2">
            {t('register.window.numberOfEqualWindows')}
          </Typography>

          <Typography variant="body2" bold>
            {t('pieces', { pieces: numberOfEqualWindows })}
          </Typography>
        </ItemWrapper>

        {windowSpecType !== WindowSpecType.UNKNOWN && (
          <ItemWrapper>
            <Typography variant="body2">{t('value')}</Typography>

            <Typography variant="body2" bold>
              {windowValue}
            </Typography>
          </ItemWrapper>
        )}
      </InfoWrapper>
    </Box>
  );
};
