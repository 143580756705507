import { Accordion } from 'enova-frontend-components';
import React from 'react';
import { AccordionItemProps } from 'enova-frontend-components/dist/components/surfaces/accordion/accordionItem';

export const CustomAccordionItem = (props: AccordionItemProps) => (
  <Accordion.Item
    {...props}
    initExpanded={false}
    ExpandableAreaProps={{
      style: { padding: 0 }
    }}
  >
    {props.children}
  </Accordion.Item>
);
