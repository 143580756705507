import { Vindu } from '../../../../../types/registration/userInput';
import { WindowData, WindowDetailingOption, WindowFormData } from '../types';

const getDetailingValue = (vindu?: Vindu): WindowDetailingOption | -1 => {
  if (vindu?.uverdi) {
    return 'uValue';
  }
  if (vindu?.fabrikasjonsAr) {
    return 'yearOfProduction';
  }

  if (vindu?.karmType) {
    return 'windowType';
  }

  if (vindu?.brukStandardVerdier) {
    return 'unknown';
  }

  // -1 represents no value
  return -1;
};

export const useWindowDefaultValues = (
  windowData: WindowData
): WindowFormData | undefined => {
  if (!windowData) {
    return;
  }

  return {
    windowData,
    detailing: getDetailingValue(windowData.window)
  };
};
