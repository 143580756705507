import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CollapsibleFactBox, Grid } from 'enova-frontend-components';

import useSelector from '../../../../../../hooks/useSelector';
import ReadonlyField from '../../../../../../components/readonlyField';
import useFormatDate from '../../../../../../hooks/useFormatDate';

export const ConstructionDensitySummarySection: FC = () => {
  const { t } = useTranslation();
  const { formatDate } = useFormatDate();

  const data = useSelector(
    (state) => state.register.userInput.bygningsdetaljer.konstruksjonstetthet
  );

  return (
    <Grid item>
      <CollapsibleFactBox title={t('register.constructionDensity.title')}>
        {data?.lekkasjetall && (
          <ReadonlyField
            label={t('register.lekkasjetall.label')}
            value={data.lekkasjetall}
          />
        )}
        {data?.dato && (
          <ReadonlyField
            label={t('register.lekkasjetall.dato.label')}
            value={formatDate(data.dato)}
          />
        )}
      </CollapsibleFactBox>
    </Grid>
  );
};
