import React, { FC } from 'react';
import { Grid, styled, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { Vindu } from '../../../../../../../types/registration/userInput';
import { VeggId } from '../../../../../../../components/registering/utils/registerEnums';
import { Window } from '../../../../../../../components/registering/window-section/window';
import { groupWindows } from '../../../../../../../components/registering/window-section/utils';

type WindowsSummarySectionContentProps = {
  veggId: VeggId;
  veggsoneIndex?: number;
  vinduer?: Vindu[];
};

// Need some magic margins to handle all the padding and margin that are being used
// everywhere. Need to simplify all these styles.
const Wrapper = styled(Grid)`
  margin-bottom: 25px;
  margin-left: 5px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const WindowsSummaryContent: FC<WindowsSummarySectionContentProps> = ({
  veggId,
  veggsoneIndex,
  vinduer
}) => {
  const { t } = useTranslation();
  const windowRecord = groupWindows(vinduer ?? []);

  return (
    <Grid container>
      {vinduer && vinduer.length > 0 ? (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        Object.entries(windowRecord)?.map(([_, windowGroup]) => {
          const vindu = windowGroup?.[0];

          return (
            <Wrapper xs={12} key={vindu.id}>
              <Window
                window={vindu}
                numberOfEqualWindows={windowGroup.length}
              />
            </Wrapper>
          );
        })
      ) : (
        <Grid item xs={12}>
          <Typography>
            {t('register.window.empty', {
              context: veggsoneIndex == null ? 'wall' : 'wallZone',
              wallZoneId:
                veggsoneIndex != null &&
                `${veggId.toUpperCase()}${veggsoneIndex + 1}`,
              veggId: veggId.toUpperCase()
            })}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default WindowsSummaryContent;
