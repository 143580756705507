import { TFunction } from 'i18next';
import { SelectOption } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { useGetInsulationData } from '../../../screens/registration/useGetInsulationData';
import { GulvType } from '../../../types/misc';

import { TakKonstruksjonsType } from './registerEnums';

const AllInsulationValueOptions = [
  0, 50, 75, 100, 125, 150, 200, 250, 300, 350, 400, 450, 500
];

// Hardcoded insulation options used in wallZone and floorZone component.
export const getInsulationOptions = (
  t: TFunction,
  insulationOptions?: number[]
): SelectOption[] => [
  {
    label: String(
      t('register.isolation', {
        context: 'default'
      })
    ),
    value: -1,
    disabled: true
  },
  ...(insulationOptions ?? AllInsulationValueOptions).map((option) => ({
    label: String(
      t('register.isolation', {
        count: option,
        context: 'count'
      })
    ),
    value: option
  }))
];

// Returns roof insulation based on roof type
export const useRoofInsulationOptions = (
  roofType: TakKonstruksjonsType | undefined
): SelectOption[] => {
  const { insulationData } = useGetInsulationData();
  const { t } = useTranslation();

  const disabledOption = {
    label: t('register.isolation', {
      context: 'default'
    }),
    value: -1,
    disabled: true
  };

  if (!insulationData?.takkonstruksjoner || !roofType) {
    return [disabledOption];
  }

  const options: SelectOption[] = insulationData.takkonstruksjoner[
    roofType
  ].map((option) => {
    const label = t('register.isolation', {
      count: option,
      context: 'count'
    });

    return {
      label,
      value: option
    };
  });

  return [disabledOption, ...options];
};

const disabledOption = {
  value: -1,
  disabled: true
};

// Returns floor insulation based on floor type
export const useFloorInsulationOptions = (
  gulvType: GulvType | undefined
): Record<'floorOptions' | 'insulationOptions', SelectOption[]> => {
  const { insulationData } = useGetInsulationData();
  const { t } = useTranslation();

  const disabledFloorOption = {
    ...disabledOption,
    label: t('register.floors.constructionType.option.default')
  };
  const disabledInsulationOption = {
    ...disabledOption,
    label: t('register.isolation_default')
  };

  if (!insulationData?.gulvkonstruksjoner) {
    return {
      floorOptions: [disabledFloorOption],
      insulationOptions: [disabledInsulationOption]
    };
  }

  const gulvTyper = Object.entries(insulationData?.gulvkonstruksjoner).map(
    ([key]) => {
      const label = t(`register.floors.constructionType.option.${key}`);

      return {
        label,
        value: key as GulvType
      };
    }
  );

  // Since users has been able to enter invalid floor values in the past we need to check
  // if the floor type is valid before we map over the insulation values.
  const isFloorTypeValid =
    !!gulvType && insulationData.gulvkonstruksjoner?.[gulvType]?.length > 0;

  const insulationValues = isFloorTypeValid
    ? insulationData.gulvkonstruksjoner?.[gulvType]?.map((option) => ({
        label: option,
        value: option
      }))
    : [];

  return {
    floorOptions: [disabledFloorOption, ...gulvTyper],
    insulationOptions: [disabledInsulationOption, ...insulationValues]
  };
};
