import React, { FC } from 'react';
import { Box, FormMessage, Typography } from 'enova-frontend-components';
import { useFormState } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import Joi from 'joi';

import { State } from './utils';
import {
  reduceErrors,
  SUM_OPPVARMING_ERROR,
  SUM_TAPPEVANN_ERROR
} from './validation';

export const ErrorMessage: FC = () => {
  const { t } = useTranslation();
  const { errors } = useFormState<State>();

  if (Object.keys(errors).length === 0) {
    return null;
  }

  const reducedErrors = reduceErrors(
    // Need to to a cast here because react-hook-form cant infer correct error types
    // when used with a custom joi resolver.
    errors as Record<string, Joi.ValidationErrorItem>
  );

  return (
    <Box mt={10}>
      <FormMessage fullWidth title={t('register.errorMessage.title')}>
        <Typography>{t('register.errorMessage.description')}</Typography>

        <ul aria-label={t('register.errorMessage.list')}>
          {Object.entries(reducedErrors).map(([field, error]) => {
            if (
              field === SUM_OPPVARMING_ERROR ||
              field === SUM_TAPPEVANN_ERROR
            ) {
              const customErrorMessageLabel = t(
                `register.parameters.error.${field}`,
                { limit: error?.limit }
              );

              // If its a custom error we want to create a custom error message
              return (
                <li key={field} aria-label={customErrorMessageLabel}>
                  <Trans
                    i18nKey={`register.parameters.error.${field}`}
                    values={{ limit: error?.limit }}
                    components={[<i key={0} />]}
                  />
                </li>
              );
            }

            const translatedField = t(`register.parameters.type.${field}`);
            const errorMessage = t(`register.parameters.error.${error.type}`, {
              field: translatedField,
              limit: error.limit
            });

            return (
              <li key={field} aria-label={errorMessage}>
                {errorMessage}
              </li>
            );
          })}
        </ul>
      </FormMessage>
    </Box>
  );
};
