import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { BygningsForm } from '../../../utils/registerEnums';
import { MinifiedWall } from '../../../../../store/registration/selectors';
import { queryKeys } from '../../../../../utils/react-query';
import { calculateArea } from '../../../../../services/api';
import { updateCalculatedArea } from '../../../../../store/registration/actions';

export const useCalculateFloorArea = (
  bygningsform: BygningsForm,
  vegger: MinifiedWall[],
  etasjeId: string
) => {
  const dispatch = useDispatch();
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  // Enable the query when every wall have a length and building shape is selected
  const enabled =
    vegger.length > 0 &&
    vegger.every((v) => v.lengdeIMeter != null && v.lengdeIMeter > 0) &&
    bygningsform !== BygningsForm.Unknown;

  const { data, error, isLoading } = useQuery(
    [queryKeys.area, JSON.stringify(vegger), etasjeId],
    () => {
      const veggLengder = vegger.map((vegg) => ({
        lengde: vegg.lengdeIMeter ?? 0,
        id: vegg.veggId
      }));

      return calculateArea(bygningsform, veggLengder);
    },
    {
      enabled,
      onSuccess: (response) => {
        if (response?.areal == null) {
          return;
        }

        // The result of the calculation need to be stored inside redux
        // to be able to validate and make it appear at page bottom with
        // the buttons that handles navigation and error messages.
        dispatch(updateCalculatedArea({ area: response.areal, etasjeId }));
      },
      onError: () => {
        // If an error happens we update the calculated area state with an undefined value for the given floor
        dispatch(updateCalculatedArea({ area: undefined, etasjeId }));
        addSnack(t('areaError'), { variant: 'warning' });
      }
    }
  );

  return { area: data?.areal ?? 0, error, isLoading };
};
