import { Box, Collapse, Link, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import React, { FC, useState } from 'react';

import { useProfileFormContext } from '../types';
import { UserRole } from '../../../types/user';

import { MoreInfoDialog } from './moreInfoDialog';
import { FagomradeCheckbox } from './fagomradeCheckbox';

interface FagomraderProps {
  loading: boolean;
}

export const Fagomrader: FC<FagomraderProps> = ({ loading }) => {
  const { watch } = useProfileFormContext();
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  const userIsProfessional = watch('rolle') === UserRole.PROFESJONELL;

  return (
    <Collapse in={userIsProfessional} timeout="auto">
      <Box display="flex" flexDirection="column" gap={5} my={5}>
        <Box>
          <Typography variant="h4">
            {t('profileScreen.professional.areas.requirements.title')}
          </Typography>
          <Typography variant="p">
            {t('profileScreen.professional.areas.requirements.description')}
          </Typography>
        </Box>

        <Box>
          <Typography variant="h4">
            {t('profileScreen.professional.areas.title')}
          </Typography>
          <Typography variant="p">
            {t('profileScreen.professional.areas.description')}
          </Typography>
        </Box>

        <Box>
          <Link button type="button" onClick={() => setDialogOpen(true)}>
            {t('profileScreen.professional.areas.more')}
          </Link>
        </Box>

        <FagomradeCheckbox loading={loading} fieldKey="energiradgiver" />

        <FagomradeCheckbox loading={loading} fieldKey="bygningsekspert" />

        <FagomradeCheckbox loading={loading} fieldKey="kjelOgVarmeanlegg" />

        <FagomradeCheckbox
          loading={loading}
          fieldKey="ventilasjonsOgKjoleanlegg"
        />
      </Box>
      <MoreInfoDialog
        open={isDialogOpen}
        onCancel={() => setDialogOpen(false)}
      />
    </Collapse>
  );
};
