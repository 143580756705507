import { FC } from 'react';

import { ReactComponent as XShapeNorth } from '../../../../../../../assets/icons/shapes/x-shape/x-shape-1.svg';
import { ReactComponent as XShapeEast } from '../../../../../../../assets/icons/shapes/x-shape/x-shape-2.svg';
import { ReactComponent as XShapeSouth } from '../../../../../../../assets/icons/shapes/x-shape/x-shape-3.svg';
import { ReactComponent as XShapeWest } from '../../../../../../../assets/icons/shapes/x-shape/x-shape-4.svg';
import { Himmelretning } from '../../../../../utils/registerEnums';

import { ShapeProps } from './types';

export const XShape: FC<ShapeProps> = ({ direction }) => {
  switch (direction) {
    case Himmelretning.Nord:
      return <XShapeNorth />;
    case Himmelretning.Ost:
      return <XShapeEast />;
    case Himmelretning.Sor:
      return <XShapeSouth />;
    case Himmelretning.Vest:
      return <XShapeWest />;

    default:
      return <XShapeNorth />;
  }
};
