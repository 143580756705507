import { Box, Button, FullScreenLoader } from 'enova-frontend-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PreviousStepButton } from '../../../../screens/registration/components/registrationActionButtons/buttons';
import useRegistrationForm from '../../../../screens/registration/useRegistrationForm';

import { useUpdateReduxStore } from './useUpdateReduxStore';

interface SubmitButtonsProps {
  isLoading: boolean;
  goBack: () => void;
}

export const SubmitButtons: FC<SubmitButtonsProps> = ({
  isLoading,
  goBack
}) => {
  const { t } = useTranslation();
  const { nextStep, prevStep, loadingStep } = useRegistrationForm();

  useUpdateReduxStore();

  const isPending = isLoading || loadingStep;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      gap={4}
      mt={10}
      flexDirection={{ xs: 'column', sm: 'row' }}
    >
      {isPending && <FullScreenLoader open />}

      {prevStep && (
        <Box>
          <PreviousStepButton disabled={isPending} onClick={goBack} />
        </Box>
      )}

      {nextStep && (
        <Box>
          <Button disabled={isPending} fullWidth type="submit">
            {t('register.actions.next')}
          </Button>
        </Box>
      )}
    </Box>
  );
};
