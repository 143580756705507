import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { WallZoneFormData } from '../types';
import { TilliggendeRom } from '../../../utils/registerEnums';
import { Vegg } from '../../../../../types/registration/userInput';

import { Form } from './form';
import { useWallZoneDefaultValues } from './useWallZoneDefaultValues';
import { useWallZoneOperations } from './form/useWallZoneOperations';
import { useBuildingConstructionData } from './form/useBuildingConstructionData';

interface Props {
  isUniformWall: boolean;
  onSave: () => void;
  onCancel: () => void;
  wall: Vegg;
  etasjeId: string;
}

export const AddWallZoneForm: FC<Props> = ({
  onSave,
  onCancel,
  isUniformWall,
  wall,
  etasjeId
}) => {
  const { wallZoneData } = useWallZoneDefaultValues(
    isUniformWall ? { lengdeIMeter: wall.lengdeIMeter } : undefined
  );
  const { addVeggsone } = useWallZoneOperations(etasjeId, wall.veggId);
  const { allWallsAgainstTheOpen } = useBuildingConstructionData();
  const methods = useForm<WallZoneFormData>({
    defaultValues: {
      wallZoneData: {
        ...wallZoneData,
        floorId: etasjeId,
        wallId: wall.veggId
      }
    }
  });

  const onSubmit = ({ wallZoneData }: WallZoneFormData) => {
    const tilliggendeRom = allWallsAgainstTheOpen
      ? TilliggendeRom.MotDetFri
      : wallZoneData.wallZone.tilliggendeRom;

    const veggsone = {
      ...wallZoneData.wallZone,
      tilliggendeRom
    };

    addVeggsone(veggsone);
    onSave();
  };

  return (
    <FormProvider {...methods}>
      <Form
        allWallsAgainstTheOpen={allWallsAgainstTheOpen}
        onSubmit={onSubmit}
        onDelete={() => onCancel()}
      />
    </FormProvider>
  );
};
