import { useMutation } from 'react-query';

import { createBuilding } from '../../services/api';

import { CreateBuildingData } from './types';

export const useCreateBuilding = () => {
  // Explicitly type out everything that needs to be sent to the backend versus spreading
  const { mutate, isLoading } = useMutation((data: CreateBuildingData) =>
    createBuilding({
      bygningsnummer: data.bygningsnummer || null,
      kommunenavn: data.kommunenavn,
      kommunenummer: data.kommunenummer,
      postnummer: data.postnummer,
      poststed: data.poststed,
      andelsnummer: data.andelsnummer ? +data.andelsnummer : null,
      bruksenhetsnummer: data.bruksenhetsnummer || null,
      bruksnummer: data.bruksnummer ? +data.bruksnummer : null,
      festenummer: data.festenummer || null,
      gardsnummer: data.gardsnummer ? +data.gardsnummer : null,
      gatebokstav: data.gateBokstav || null,
      gatenavn: data.gatenavn || null,
      gatenummer: data.gatenummer || null,
      organisasjonsnummer: data.organisasjonsnummer?.value || null,
      seksjonsnummer: data.seksjonsnummer ? +data.seksjonsnummer : null,
      bygningstype: data.bygningstype ? +data.bygningstype : null
    })
  );

  return { mutate, isLoading };
};
