import { Typography } from 'enova-frontend-components';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GetEnergiObjektTypeFromMatrikkel } from '../../../../../../utils/matrikkelBygningsTyper';
import { DetailedBuildingDetails } from '../../../../../../types/building';

import { EnergyPlanListItem } from './energyPlanListItem';

interface Props {
  building: DetailedBuildingDetails;
}

export const PreviousEnergyPlanList: FC<Props> = ({ building }) => {
  const { t } = useTranslation();

  const { ikkeGyldigePubliserteEnergiplaner, kanEnergimerkesAvPrivatPerson } =
    building;

  const energiObjektType = GetEnergiObjektTypeFromMatrikkel(
    kanEnergimerkesAvPrivatPerson
  );

  if (!ikkeGyldigePubliserteEnergiplaner) {
    return null;
  }

  return (
    <Fragment>
      <Typography gutterBottom mt={5} variant="h2">
        {t('buldingScreen.tab.energiattest.previousCertificates', {
          context: energiObjektType
        })}
      </Typography>

      {ikkeGyldigePubliserteEnergiplaner?.map((energyplan) => (
        <EnergyPlanListItem
          key={energyplan.registreringId}
          energyPlan={energyplan}
        />
      ))}
    </Fragment>
  );
};
