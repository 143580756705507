import { useQuery } from 'react-query';

import { queryKeys } from '../../../../../../../../utils/react-query';
import { getSearchBuilding } from '../../../../../../../../services/api';

import { BuildingSearchFormData } from './types';

export const useSearchBuilding = (
  defaultValues: BuildingSearchFormData,
  isSearchEnabled: boolean
) => {
  const {
    error,
    isFetching: loading,
    data: searchResults,
    refetch
  } = useQuery(
    [queryKeys.advancedSearch, defaultValues],
    () => getSearchBuilding({ ...defaultValues, bareHovedbygg: true }),
    {
      enabled: isSearchEnabled,
      keepPreviousData: true
    }
  );

  return {
    error,
    isLoading: loading,
    searchResults,
    refetch
  };
};
