import { getYear } from 'date-fns';
import { Control } from 'react-hook-form';

import { UserRole } from '../../../../../../types/user';
import {
  FieldError,
  MAX_NUMBER_OF_YEARS_IN_THE_FUTURE
} from '../../../../utils/validation';
import { WallZoneFormData } from '../../types';
import { VeggType } from '../../../../utils/registerEnums';

export const getYearError = (
  yearValue?: number,
  userRole?: UserRole,
  yearOfConstruction?: number
) => {
  const isProfessional = userRole === UserRole.PROFESJONELL;

  if (!yearValue) {
    return FieldError.Missing;
  }

  if (String(yearValue).length !== 4) {
    return FieldError.Invalid;
  }

  const currentYear = getYear(Date.now());

  if (!isProfessional && yearValue > currentYear) {
    return FieldError.Max;
  }

  if (yearValue > currentYear + MAX_NUMBER_OF_YEARS_IN_THE_FUTURE) {
    return FieldError.MaxProfessional;
  }

  if (yearOfConstruction && yearValue < yearOfConstruction) {
    return FieldError.Min;
  }

  return null;
};

export interface WallZoneFormFieldProps {
  control: Control<WallZoneFormData>;
}

export const VeggTypeIsolasjonsTykkelseMap: Record<VeggType, number[]> = {
  [VeggType.BindingsKonstruksjonMedGjennomGaendeStendere]: [
    0, 50, 75, 100, 125, 150, 200, 250, 300, 350, 400, 450, 500
  ],
  //According to BDO 50, 70, 100, 125, 150, 200, 250 are the right values even if one of the names is BindingsverkMed75mmIsolasjon
  [VeggType.BindingsverkMed50mmIsolasjonOgKontinuerligUtvendigIsolasjon]: [
    50, 70, 100, 125, 150, 200, 250
  ],
  [VeggType.BindingsverkMed75mmIsolasjonOgKontinuerligUtvendigIsolasjon]: [
    50, 70, 100, 125, 150, 200, 250
  ],
  [VeggType.BindingsverkMed100mmIsolasjonOgKontinuerligUtvendigIsolasjon]: [
    50, 70, 100, 125, 150, 200, 250
  ],
  [VeggType.BindingsverkMed150mmIsolasjonOgKontinuerligUtvendigIsolasjon]: [
    50, 70, 100, 125, 150, 200, 250
  ],
  [VeggType.BindingsverkMed200mmIsolasjonOgKontinuerligUtvendigIsolasjon]: [
    50, 70, 100, 125, 150, 200, 250
  ],
  [VeggType.BindingsverkMed250mmIsolasjonOgKontinuerligUtvendigIsolasjon]: [
    50, 70, 100, 125, 150, 200, 250
  ],
  [VeggType.BindingsverkMed300mmIsolasjonOgKontinuerligUtvendigIsolasjon]: [
    50, 70, 100, 125, 150, 200, 250
  ],
  [VeggType.BindingsverkMedDobbeltveggkonstruksjon]: [300, 350, 400, 450, 500],
  [VeggType.BindingsverkMed50mmMurisolasjonOgTeglforblending]: [
    75, 100, 125, 150, 175, 200, 225, 250
  ],
  [VeggType.BetongMedIsolertPaforing]: [
    0, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325, 350
  ],
  [VeggType.BetongMed50mmKontinuerligUtvendigIsolasjonOgInnvendigIsolertPaforing]:
    [0, 50, 75, 100, 125, 150],
  [VeggType.BetongMed100mmKontinuerligUtvendigIsolasjonOgInnvendigIsolertPaforing]:
    [0, 50, 75, 100, 125, 150],
  [VeggType.BetongMed150mmKontinuerligUtvendigIsolasjonOgInnvendigIsolertPaforing]:
    [0, 50, 75, 100, 125, 150],
  [VeggType.Porebetong150mmMedIsolertPaforing]: [
    0, 50, 75, 100, 125, 150, 175, 200
  ],
  [VeggType.Porebetong240mmMedIsolertPaforing]: [
    0, 50, 75, 100, 125, 150, 175, 200
  ],
  [VeggType.Lettklinkerbetong150mmMedIsolertPaforing]: [
    0, 50, 75, 100, 125, 150, 175, 200
  ],
  [VeggType.Lettklinkerbetong250mmMedIsolertPaforing]: [
    0, 50, 75, 100, 125, 150, 175, 200
  ],
  [VeggType.SkallmurAvTegl]: [0, 50, 75, 100, 125, 150, 175, 200, 225, 250],
  [VeggType.LaftetYttervegg]: [0, 50, 75, 100, 125, 150, 175, 200],
  [VeggType.LecaIsoblokk250mmMedIsolertPaforing]: [0, 50, 100],
  [VeggType.LecaIsoblokk300mmMedIsolertPaforing]: [0, 50, 100],
  [VeggType.LecaIsoblokk350mmMedIsolertPaforing]: [0, 50, 100],
  [VeggType.Veggelementer]: [50, 75, 100, 120, 150, 200, 250, 300]
};
