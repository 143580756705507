import { Resolver } from 'react-hook-form';
import i18n from 'i18next';

import { BuildingSearchFormData } from './types';

const genericFieldError = { type: 'required', message: '' };

const validBruksenhetsnummer = (bruksenhetsnummer?: string) => {
  if (!bruksenhetsnummer || bruksenhetsnummer.trim() === '') {
    return true;
  }

  return bruksenhetsnummer?.match(/[HLUK][0-9]{4}$/);
};

// No field specific errors. Resolver only adds generic errors to highlight required fields in form.
// Custom error messages are handled in FormErrors component
export const searchFormResolver: Resolver<BuildingSearchFormData> = (
  formValues
) => {
  const {
    gatenavn,
    bygningsnummer,
    gardsnummer,
    kommunenummer,
    bruksenhetsnummer
  } = formValues;

  // Kommune is always required
  const kommuneError = !kommunenummer && {
    kommunenummer: genericFieldError
  };

  // Require either gatenavn, bygningsnummer or gardsnummer
  const missingRequiredField = !gatenavn && !bygningsnummer && !gardsnummer;
  const requiredFieldsErrors = missingRequiredField && {
    gatenavn: genericFieldError,
    gardsnummer: genericFieldError,
    bygningsnummer: genericFieldError
  };

  // Validate the format of bruksenhetsnummer (H0101)
  const bruksenhetsnummerError = !validBruksenhetsnummer(bruksenhetsnummer) && {
    bruksenhetsnummer: {
      type: 'invalid',
      message: i18n.t('search.advanced.error.bruksenhetsnummer')
    }
  };

  return {
    values: formValues,
    errors: {
      ...kommuneError,
      ...requiredFieldsErrors,
      ...bruksenhetsnummerError
    }
  };
};
