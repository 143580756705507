import { FC } from 'react';

import { ReactComponent as YShapeNorth } from '../../../../../../../assets/icons/shapes/y-shape/y-shape-1.svg';
import { ReactComponent as YShapeEast } from '../../../../../../../assets/icons/shapes/y-shape/y-shape-2.svg';
import { ReactComponent as YShapeSouth } from '../../../../../../../assets/icons/shapes/y-shape/y-shape-3.svg';
import { ReactComponent as YShapeWest } from '../../../../../../../assets/icons/shapes/y-shape/y-shape-4.svg';
import { Himmelretning } from '../../../../../utils/registerEnums';

import { ShapeProps } from './types';

export const YShape: FC<ShapeProps> = ({ direction }) => {
  switch (direction) {
    case Himmelretning.Nord:
      return <YShapeNorth />;
    case Himmelretning.Ost:
      return <YShapeEast />;
    case Himmelretning.Sor:
      return <YShapeSouth />;
    case Himmelretning.Vest:
      return <YShapeWest />;

    default:
      return <YShapeNorth />;
  }
};
