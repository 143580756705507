import React, { FC } from 'react';
import { CollapsibleFactBox, Grid } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { CalculationParameterMap } from '../../../../../types/calculationParameters';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';

import { LargeScreenItem } from './largeScreenItem';
import { SmallScreenItem } from './smallScreenItem';

interface CategoriesProps {
  beregningskjerneInput: CalculationParameterMap;
}

export const Categories: FC<CategoriesProps> = ({ beregningskjerneInput }) => {
  const { t } = useTranslation();
  const { xsScreen } = useMediaQuery();

  return (
    <Grid container spacing={4} direction="column">
      {Object.entries(beregningskjerneInput).map(([categoryKey, category]) => {
        return (
          <Grid item key={categoryKey}>
            <CollapsibleFactBox
              unmountOnExit={true}
              title={t(`register.parameters.category.${categoryKey}`)}
            >
              {xsScreen ? (
                <SmallScreenItem category={category} />
              ) : (
                <LargeScreenItem category={category} />
              )}
            </CollapsibleFactBox>
          </Grid>
        );
      })}
    </Grid>
  );
};
