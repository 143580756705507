import { useQuery } from 'react-query';

import { getPortefoljeData } from '../../../../services/api';
import { queryKeys } from '../../../../utils/react-query';

export const usePortefoljeData = (portefoljeId: string) => {
  const {
    error,
    isLoading,
    data,
    refetch: refreshPortefoljeData
  } = useQuery([queryKeys.portfolio, portefoljeId], () =>
    getPortefoljeData(portefoljeId)
  );

  const energiAnalysePrivatBoligList = data?.energiAnalysePrivatBoligList || [];
  const energiAnalyseYrkesByggList = data?.energiAnalyseYrkesByggList || [];

  return {
    simpleEnergyAnalyses: [
      ...energiAnalysePrivatBoligList,
      ...energiAnalyseYrkesByggList
    ],
    error,
    isLoading,
    refreshPortefoljeData
  };
};
