import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'enova-frontend-components';

import { SimpleEnergyAnalysis } from '../../../../../types/building';

import { EnhetRegistrationUnpublished } from './enhetRegistrationUnpublished';

export const UnpublishedEnhetRegistrationList: FC<{
  energianalyser?: SimpleEnergyAnalysis[];
}> = ({ energianalyser }) => {
  const { t } = useTranslation();

  if (!energianalyser || energianalyser.length === 0) {
    return <Typography>{t('xmlOverview.enheter.noRegistrations')}</Typography>;
  }

  return (
    <Fragment>
      {energianalyser.map(({ registrering }) => (
        <EnhetRegistrationUnpublished
          key={registrering.registreringId}
          registrering={registrering}
        />
      ))}
    </Fragment>
  );
};
