import React, { FC } from 'react';
import { Box, SearchField, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

interface NormalScreenSearchProps {
  onSearch: () => void;
  searchString: string | undefined;
  setSearchString: (searchString: string) => void;
}

export const NormalScreenSearch: FC<NormalScreenSearchProps> = ({
  onSearch,
  searchString,
  setSearchString
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex">
      <Box display="flex" flexDirection="column">
        <Typography
          {...{ htmlFor: 'searchFieldId' }}
          component="label"
          gutterBottom
          variant="h3"
        >
          {t('search.title')}
        </Typography>

        <Box width={430} maxWidth={1}>
          <SearchField
            fullWidth
            id="searchFieldId"
            onChange={({ currentTarget: { value } }) => setSearchString(value)}
            onSearch={onSearch}
            value={searchString}
            placeholder={t('search.barContent')}
          />
        </Box>
      </Box>
    </Box>
  );
};
