import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { camelCasify } from '../../../../../utils/navigation';
import { KarmType } from '../../../utils/registerEnums';
import i18n from '../../../../../i18n';
import { VinduKarmTypeGlassTypeMap } from '../utils';
import { getSelectOptionsFromEnum } from '../../../../../utils/register';

export const useKarmAndGlassTypeOptions = (karmType: KarmType | undefined) => {
  const { t } = useTranslation();

  const glassTypeOptions = useMemo(
    () => [
      {
        label: String(t('register.window.glassType.option.default')),
        value: -1,
        disabled: true
      },
      ...(karmType ? VinduKarmTypeGlassTypeMap[karmType] : [])
        .map((glassTypeOption) => ({
          label: t(`register.window.glassType.option.${glassTypeOption}`),
          value: glassTypeOption
        }))
        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))
    ],
    [karmType, i18n.language]
  );

  const karmTypeOptions = [
    {
      label: String(
        t('register.window.karmType.option.default', {
          context: 'default'
        })
      ),
      value: -1,
      disabled: true
    },
    ...getSelectOptionsFromEnum(KarmType, (label) =>
      t(`register.window.karmType.option.${camelCasify(label)}`)
    ).sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))
  ];

  return { glassTypeOptions, karmTypeOptions };
};
