import { FC } from 'react';
import { format } from 'date-fns';
import { Button} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { TraceLogItem, formatTraceLogId } from '../../services/tracelog';

interface Props {
  logentry: TraceLogItem;
}

export const TraceLogRow: FC<Props> = ({ logentry }) => {
  const { t } = useTranslation();
  return (
    <tr>
      <td>
        {format(new Date(logentry.time.toString()), 'dd.MM.yyyy HH:mm:ss')}
      </td>
      <td style={{ overflowWrap:"break-word", maxWidth:"300px" }}>{formatTraceLogId(logentry)}</td>
      <td className="actions">
        <Button
          variant="secondary"
          size="small"
          onClick={() => {
            navigator.clipboard.writeText(JSON.stringify(formatTraceLogId(logentry)));
          }}
        >
          {t('traceLog.actions.copy')}
        </Button>
      </td>
    </tr>
  );
};
