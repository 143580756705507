import { useFormContext } from 'react-hook-form';
import { styled } from 'enova-frontend-components';

import { CalculationParameterMap } from '../../../../types/calculationParameters';

export type State = Record<string, BeregningskjerneInput>;

export const useAdjustParametersFormContext = () => {
  return useFormContext<State>();
};

export interface BeregningskjerneInput {
  expertValue: number | null;
  calculatedValue: number | null;
}

// The calculation parameters are mapped to a simplified state that can be used with react-hook-form.
// Each key in the object represents a field in the form.
export const mapBeregningskjerneinput = (
  beregningskjerneInput: CalculationParameterMap
): State => {
  const result: State = {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  for (const [_, categoryValue] of Object.entries(beregningskjerneInput)) {
    for (const [key, value] of Object.entries(categoryValue)) {
      result[key] = {
        expertValue: value.expertValue != null ? value.expertValue : null,
        calculatedValue: value?.calculatedValue
      };
    }
  }

  return result;
};

const getCalculationParameterMapAdvisor = (
  beregningskjerneInput: CalculationParameterMap
): CalculationParameterMap => {
  // Remember to deep clone the parameters or the original object will be mutated by the delete statements
  // Thats why we use JSON.parse(JSON.stringify())
  const parameters: CalculationParameterMap =
    JSON.parse(JSON.stringify({ ...beregningskjerneInput })) || {};

  // Delete all the fields the user should not be able to modify
  delete parameters?.generell?.areal_oppv;
  delete parameters?.settpunktTemperatur;
  delete parameters?.varmetapMotGrunn?.faseforskjell_utetemp_varmetap;
  delete parameters?.varmetapMotGrunn?.aarsmiddeltemp_inne;
  delete parameters?.varmetapMotGrunn?.aarsmiddeltemp_ute;
  delete parameters?.varmetransportVentilasjon
    ?.luftmengde_spesifikk_i_driftstid;
  delete parameters?.varmetransportVentilasjon
    ?.luftmengde_spesifikk_utenfor_driftstid;
  delete parameters?.varmertransportInfiltrasjon?.terrengskjermingskoeff_e;
  delete parameters?.varmertransportInfiltrasjon?.terrengskjermingskoeff_f;
  delete parameters?.varmertransportInfiltrasjon?.luftmengde_spesifikk_tilluft;
  delete parameters?.varmertransportInfiltrasjon
    ?.luftmengde_spesifikk_avtrekksluft;
  delete parameters?.solskjermingTidsvariabler;
  delete parameters?.interntVarmetilskud;
  delete parameters?.energibehov?.energibehov_tappevann;
  delete parameters?.energibehov?.energibehov_belysning;
  delete parameters?.energibehov?.energibehov_utstyr;
  delete parameters?.energibehov?.areal_avkjoelt_andel;
  delete parameters?.energibehov?.temp_settpunkt_kjoeling;
  delete parameters?.energibehov?.tid_drift_pumpe_oppv;
  delete parameters?.energibehov?.pumpeeffekt_spesifikk_kjoling;
  delete parameters?.energibehov?.tid_drift_pumpe_kjoling;
  delete parameters?.energibehov?.temp_differanse_veskekrets_kjoling;
  delete parameters?.behovLevertElektrisitet?.systemvirkningsgrad_solcelle;
  delete parameters?.behovLevertElektrisitet?.effektfaktor_kjoeleanlegg;
  delete parameters?.beregningCo2;
  delete parameters?.primaerenergifaktorer;
  delete parameters?.energipris;
  delete parameters?.energipolitiskVektetEnergibehov;
  delete parameters?.driftstider;
  delete parameters?.utetemperatur;
  delete parameters?.varmetilskuddSol;
  delete parameters?.fasteVerdier;

  return parameters;
};

export const transformBeregningskjerneInput = (
  beregningskjerneInput: CalculationParameterMap
) => {
  // Defaultvalues are the form state
  const defaultValues = mapBeregningskjerneinput(beregningskjerneInput);
  // Parameters are used to map over the data and create the form.
  // It consists of a subset of the data from beregningskjerneInput.
  const parameters = getCalculationParameterMapAdvisor(beregningskjerneInput);

  return { defaultValues, parameters };
};

export const unitWidth = 54;

export const StyledTable = styled.table<{ unitWidt: number }>`
  margin: ${({ theme }) => theme.spacing(-1)};
  width: ${({ theme }) => `calc(100% + ${theme.spacing(2)})`};

  thead td {
    font-weight: ${({ theme }) => theme.typography.h4.fontWeight};
    font-size: ${({ theme }) => theme.typography.h4.fontSize};
  }

  tbody td {
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
  }

  td {
    padding: ${({ theme }) => theme.spacing(1)};

    &.numberfield {
      width: 150px;
    }

    &.unit {
      width: ${({ unitWidt }) => unitWidt}px;
    }
  }
`;
