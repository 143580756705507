import { FC, useEffect } from 'react';
import { Button, Dialog } from 'enova-frontend-components';
import { Trans, useTranslation } from 'react-i18next';

import { REFERRER_KEY, useAuthentication } from '../../hooks/useAuthentication';

import { AutoSaveParams, useSaveBeforeLogout } from './useSaveBeforeLogout';

interface SaveDialogProps {
  isOpen: boolean;
  onClose: () => void;
  params?: AutoSaveParams;
}

export const SaveDialog: FC<SaveDialogProps> = ({
  params,
  isOpen,
  onClose
}) => {
  const { t } = useTranslation();
  const { saveData, isLoading } = useSaveBeforeLogout(params);
  const { signIn } = useAuthentication();

  const onClick = async () => {
    // Set the current url in userManager state so we can redirect the user
    // back to this url after re-authentication
    signIn({ state: { [REFERRER_KEY]: window.location.href } });
  };

  useEffect(() => saveData(), []);

  return (
    <Dialog open={isOpen}>
      <Dialog.Title>{t('sessionExpires.dialog.title')}</Dialog.Title>
      <Dialog.Content>
        <Trans
          i18nKey="sessionExpires.dialog.content"
          components={[<br key={0} />]}
        />
      </Dialog.Content>

      <Dialog.Actions>
        <Button onClick={onClick} variant="primary" disabled={isLoading}>
          {t('logInAgain')}
        </Button>
        <Button onClick={onClose} variant="secondary">
          {t('cancel')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
