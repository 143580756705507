import React, { FC, useState } from 'react';
import { Box } from 'enova-frontend-components';

import { StyledPaper } from '../styledComponents';
import { Etasje } from '../../../../../../types/registration/userInput';
import { ShapeAndAreaForm } from '../../shapeAndAreaForm';
import { useFloorTitle } from '../../../../utils/useFloorTitle';

import { FloorEmptyState } from './floorEmptyState';
import { FloorDetails } from './floorDetails';

interface Props {
  floor: Etasje;
  allFloorsSameShape?: boolean;
}

export const FloorDetailing: FC<Props> = ({ floor, allFloorsSameShape }) => {
  const [isFormVisible, setFormVisible] = useState<boolean>(false);

  const { getFloorTitle } = useFloorTitle();

  const floorTitle = getFloorTitle(
    floor.etasjeNummer,
    floor.erKjeller,
    allFloorsSameShape
  );

  if (isFormVisible) {
    return (
      <StyledPaper>
        <Box p={4}>
          <ShapeAndAreaForm
            floor={floor}
            onSave={() => setFormVisible(false)}
            onCancel={() => setFormVisible(false)}
          />
        </Box>
      </StyledPaper>
    );
  }

  // Floor empty state
  if (floor?.vegger?.length === 0) {
    return (
      <FloorEmptyState title={floorTitle} onEdit={() => setFormVisible(true)} />
    );
  }

  return (
    <FloorDetails
      floor={floor}
      title={floorTitle}
      onEdit={() => setFormVisible(true)}
    />
  );
};
