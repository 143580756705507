import React, { FC, Fragment } from 'react';
import { Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { BuildingDetails } from '../../../../../../../../types/building';

import { SearchResultItem } from './searchResultItem';
import { BuildingSearchEmptyState } from './components/buildingSearchEmptyState';
import { BuildingCategorySelect } from './components/buildingCategorySelect';
import { SearchError } from './components/searchError';
import { useCategoryFilter } from './useCategoryFilter';
import { SelectAllCheckbox } from './components/selectAllCheckbox';

interface AdvancedSearchResultsProps {
  error: unknown;
  searchResult: BuildingDetails[] | undefined;
  refetch: () => Promise<unknown>;
  onResultItemSelect: (building: BuildingDetails) => void;
  isMultiSelect: boolean;
}

export const SearchResults: FC<AdvancedSearchResultsProps> = ({
  error,
  refetch,
  searchResult,
  onResultItemSelect,
  isMultiSelect
}) => {
  const { t } = useTranslation();
  const { selectedCategory } = useCategoryFilter();

  if (error) {
    return <SearchError refetch={refetch} />;
  }

  if (!searchResult) {
    return null;
  }

  if (searchResult.length === 0) {
    return <BuildingSearchEmptyState />;
  }

  const selectedCategoryFilter = (building: BuildingDetails) =>
    !selectedCategory ||
    selectedCategory === '-1' ||
    building.bygningsTypeNummer === selectedCategory;

  return (
    <Fragment>
      <Grid item display="flex" justifyContent="space-between" xs={12}>
        {isMultiSelect && <SelectAllCheckbox searchResult={searchResult} />}
        <BuildingCategorySelect buildings={searchResult} />
      </Grid>
      <Grid item xs={12} display="flex" flexDirection="column">
        <Typography variant="h4" gutterBottom>
          {t('buildingSections')}
        </Typography>
      </Grid>
      {searchResult?.filter(selectedCategoryFilter).map((building) => (
        <SearchResultItem
          key={building.bruksenhetsIdentId}
          building={building}
          onSelect={onResultItemSelect}
        />
      ))}
    </Fragment>
  );
};
