/* eslint-disable camelcase */
import { getYear } from 'date-fns';

import { RootState } from '../../../store/rootReducer';
import {
  RegistrationCommonStep,
  RegistrationStep,
} from '../../../utils/navigation';
import {
  KjelKaminVannbarenVarme,
  OppvarmingVarmepumpe,
  Punktoppvarming,
  SolfangerMedVannbarenVarme,
  TekniskUtstyr,
  VarmepumpeVentilasjon,
} from '../../../types/registration/userInput';
import { UserRole } from '../../../types/user';

import { DetailingStep, OppvarmingBruk, Varmekilde } from './registerEnums';

// VALIDATION SELECTORS
export const getRequiredFields = (
  state: RootState,
  step?: RegistrationStep
): Record<string, unknown> | undefined => {
  if (!step) {
    return undefined;
  }

  if (step === RegistrationCommonStep.ABOUT) {
    const {
      register: {
        userInput: {
          bygningsdetaljer: {
            antallEtasjerUnntattKjeller,
            byggAr,
            byggType,
            harBoligenKjeller,
            konstruksjonsmateriale,
            totalBRA,
            totalOppvarmetBRA
          } = {},
          tekniskUtstyr,
          tekniskUtstyr: {
            // Elektrisk
            oppvarmingElektrisk,
            oppvarmingElektrisk: {
              kjel: elKjel = {} as KjelKaminVannbarenVarme
            } = {},
            oppvarmingElektrisk: {
              kjel: {
                installasjonsAr: elKjelInstallasjonsAr
              } = {} as KjelKaminVannbarenVarme
            } = {},
            // Fjernvarm
            oppvarmingFjernvarme,
            // Gass
            oppvarmingGass,
            oppvarmingGass: {
              kjel: gassKjel = {} as KjelKaminVannbarenVarme
            } = {},
            oppvarmingGass: {
              kjel: {
                installasjonsAr: gassKjelInstallasjonsAr
              } = {} as KjelKaminVannbarenVarme
            } = {},
            oppvarmingBioenergi,
            oppvarmingBioenergi: {
              kjel: bioKjel = {} as KjelKaminVannbarenVarme,
              kaminMedVannbarenVarme: bioKamin = {} as KjelKaminVannbarenVarme
            } = {},
            // Bioenergi
            oppvarmingBioenergi: {
              kjel: {
                installasjonsAr: bioKjelInstallasjonsAr
              } = {} as KjelKaminVannbarenVarme,
              kaminMedVannbarenVarme: {
                installasjonsAr: bioKaminInstallasjonsAr
              } = {} as KjelKaminVannbarenVarme
            } = {},
            // Olje
            oppvarmingOlje,
            oppvarmingOlje: {
              kjel: oljeKjel = {} as KjelKaminVannbarenVarme
            } = {},
            oppvarmingOlje: {
              kjel: {
                installasjonsAr: oljeKjelInstallasjonsAr
              } = {} as KjelKaminVannbarenVarme
            } = {},
            // Varmepumpe
            oppvarmingVarmepumpe,
            oppvarmingVarmepumpe: {
              varmeKilde,
              vannbarenVarme: vannbarenVarmeVarmepumpe = {} as Omit<
                KjelKaminVannbarenVarme,
                'varmesentral'
              >
            } = {} as OppvarmingVarmepumpe,
            oppvarmingVarmepumpe: {
              vannbarenVarme: {
                installasjonsAr: varmepumpeVannbarenInstallasjonsAr
              } = {} as Omit<KjelKaminVannbarenVarme, 'varmesentral'>,
              punktoppvarming: {
                installasjonsAr: varmepumpePunktInstallasjonsAr
              } = {} as Punktoppvarming,
              ventilasjon: {
                installasjonsAr: varmepumpeVentilasjonInstallasjonsAr
              } = {} as VarmepumpeVentilasjon
            } = {} as OppvarmingVarmepumpe,
            oppvarmingSol,
            oppvarmingSol: {
              solfangerMedVannbarenVarme = {} as SolfangerMedVannbarenVarme
            } = {},
            oppvarmingSol: {
              solfangerMedVannbarenVarme: {
                installasjonsAr: solfangerInstallasjonsAr
              } = {} as KjelKaminVannbarenVarme
            } = {}
          } = {},
          ventilasjon
        } = {}
      }
    } = state;

    const baseResult = {
      byggType,
      byggAr,
      antallEtasjerUnntattKjeller,
      totalBRA,
      totalOppvarmetBRA,
      harBoligenKjeller,
      konstruksjonsmateriale,
      tekniskUtstyr,
      ventilasjon
    };

    let result = {};

    if (tekniskUtstyr && 'oppvarmingElektrisk' in tekniskUtstyr)
      result = { ...result, oppvarmingElektrisk };
    if (tekniskUtstyr && 'oppvarmingFjernvarme' in tekniskUtstyr)
      result = { ...result, oppvarmingFjernvarme };
    if (tekniskUtstyr && 'oppvarmingBioenergi' in tekniskUtstyr)
      result = { ...result, oppvarmingBioenergi };
    if (tekniskUtstyr && 'oppvarmingOlje' in tekniskUtstyr)
      result = { ...result, oppvarmingOlje };
    if (tekniskUtstyr && 'oppvarmingGass' in tekniskUtstyr)
      result = { ...result, oppvarmingGass };
    if (tekniskUtstyr && 'oppvarmingSol' in tekniskUtstyr)
      result = { ...result, oppvarmingSol };

    if (
      tekniskUtstyr &&
      'oppvarmingVarmepumpe' in tekniskUtstyr &&
      oppvarmingVarmepumpe &&
      'varmeKilde' in oppvarmingVarmepumpe &&
      varmeKilde &&
      ![Varmekilde.Ukjent].includes(varmeKilde)
    )
      result = { ...result, oppvarmingVarmepumpe };

    if (
      tekniskUtstyr &&
      'oppvarmingElektrisk' in tekniskUtstyr &&
      oppvarmingElektrisk &&
      'kjel' in oppvarmingElektrisk &&
      elKjel &&
      'bruk' in elKjel &&
      ![OppvarmingBruk.Tappevann].includes(elKjel.bruk) &&
      !('gulvvarme' in elKjel) &&
      !('radiator' in elKjel)
    )
      result = { ...result, elKjel };

    if (
      tekniskUtstyr &&
      'oppvarmingGass' in tekniskUtstyr &&
      oppvarmingGass &&
      'kjel' in oppvarmingGass &&
      gassKjel &&
      'bruk' in gassKjel &&
      ![OppvarmingBruk.Tappevann].includes(gassKjel.bruk) &&
      !('gulvvarme' in gassKjel) &&
      !('radiator' in gassKjel)
    )
      result = { ...result, gassKjel };

    if (
      tekniskUtstyr &&
      'oppvarmingBioenergi' in tekniskUtstyr &&
      oppvarmingBioenergi &&
      'kjel' in oppvarmingBioenergi &&
      bioKjel &&
      'bruk' in bioKjel &&
      ![OppvarmingBruk.Tappevann].includes(bioKjel.bruk) &&
      !('gulvvarme' in bioKjel) &&
      !('radiator' in bioKjel)
    )
      result = { ...result, bioKjel };

    if (
      tekniskUtstyr &&
      'oppvarmingBioenergi' in tekniskUtstyr &&
      oppvarmingBioenergi &&
      'kaminMedVannbarenVarme' in oppvarmingBioenergi &&
      bioKamin &&
      'bruk' in bioKamin &&
      ![OppvarmingBruk.Tappevann].includes(bioKamin.bruk) &&
      !('gulvvarme' in bioKamin) &&
      !('radiator' in bioKamin)
    )
      result = { ...result, bioKamin };

    if (
      tekniskUtstyr &&
      'oppvarmingOlje' in tekniskUtstyr &&
      oppvarmingOlje &&
      'kjel' in oppvarmingOlje &&
      oljeKjel &&
      'bruk' in oljeKjel &&
      ![OppvarmingBruk.Tappevann].includes(oljeKjel.bruk) &&
      !('gulvvarme' in oljeKjel) &&
      !('radiator' in oljeKjel)
    )
      result = { ...result, oljeKjel };

    if (
      tekniskUtstyr &&
      'oppvarmingVarmepumpe' in tekniskUtstyr &&
      oppvarmingVarmepumpe &&
      'vannbarenVarme' in oppvarmingVarmepumpe &&
      vannbarenVarmeVarmepumpe &&
      'bruk' in vannbarenVarmeVarmepumpe &&
      ![OppvarmingBruk.Tappevann].includes(vannbarenVarmeVarmepumpe.bruk) &&
      !('gulvvarme' in vannbarenVarmeVarmepumpe) &&
      !('radiator' in vannbarenVarmeVarmepumpe)
    )
      result = { ...result, vannbarenVarmeVarmepumpe };

    if (
      tekniskUtstyr &&
      'oppvarmingSol' in tekniskUtstyr &&
      oppvarmingSol &&
      'solfangerMedVannbarenVarme' in oppvarmingSol &&
      solfangerMedVannbarenVarme &&
      'bruk' in solfangerMedVannbarenVarme &&
      ![OppvarmingBruk.Tappevann].includes(solfangerMedVannbarenVarme.bruk) &&
      !('gulvvarme' in solfangerMedVannbarenVarme) &&
      !('radiator' in solfangerMedVannbarenVarme)
    )
      result = { ...result, solfangerMedVannbarenVarme };

    if (gassKjelInstallasjonsAr)
      result = { ...result, gassKjelInstallasjonsAr };
    if (bioKjelInstallasjonsAr) result = { ...result, bioKjelInstallasjonsAr };
    if (bioKaminInstallasjonsAr)
      result = { ...result, bioKaminInstallasjonsAr };
    if (elKjelInstallasjonsAr) result = { ...result, elKjelInstallasjonsAr };
    if (oljeKjelInstallasjonsAr)
      result = { ...result, oljeKjelInstallasjonsAr };
    if (solfangerInstallasjonsAr)
      result = { ...result, solfangerInstallasjonsAr };
    if (varmepumpeVannbarenInstallasjonsAr)
      result = { ...result, varmepumpeVannbarenInstallasjonsAr };
    if (varmepumpePunktInstallasjonsAr)
      result = { ...result, varmepumpePunktInstallasjonsAr };
    if (varmepumpeVentilasjonInstallasjonsAr)
      result = { ...result, varmepumpeVentilasjonInstallasjonsAr };

    return { ...baseResult, ...result };
  }

  if (step === DetailingStep.Bygningsform) {
    const {
      register: { userInput: { bygningsdetaljer: { etasjer } = {} } = {} }
    } = state;

    let result: Record<string, unknown> = {};

    if (etasjer && etasjer.length > 0) {
      for (const etasje of etasjer) {
        if (!etasje.bygningsForm && !('bygningsForm' in result))
          result = { ...result, bygningsForm: etasje.bygningsForm };
        if (
          (!etasje.gjennomsnittligEtasjehoyde ||
            etasje.gjennomsnittligEtasjehoyde < 1.7 ||
            etasje.gjennomsnittligEtasjehoyde > 50) &&
          !('gjennomsnittligEtasjehoyde' in result)
        )
          result = {
            ...result,
            gjennomsnittligEtasjehoyde: etasje.gjennomsnittligEtasjehoyde
          };
        const vegger = etasje.vegger;
        if (vegger && vegger.length > 0) {
          for (const vegg of vegger) {
            if (
              !vegg.lengdeIMeter ||
              (vegg.lengdeIMeter <= 0 && !('lengdeIMeter' in result))
            )
              result = { ...result, lengdeIMeter: vegg.lengdeIMeter };
          }
        }
      }
    }
    return result;
  }

  if (step === DetailingStep.Takkonstruksjon) {
    return {
      takKonstruksjonsType: undefined,
      isolasjonstykkelseIMillimeter: undefined
    };
  }

  if (step === DetailingStep.Gulv) {
    const {
      register: {
        userInput: { bygningsdetaljer: { byggAr, etasjer } = {} } = {}
      }
    } = state;

    if (etasjer && etasjer.length > 0) {
      for (const etasje of etasjer) {
        const gulvsoner = etasje.gulvSoner;
        if (gulvsoner && gulvsoner.length > 0) {
          for (const gulv of gulvsoner) {
            const gulvRehabiliteringAr =
              gulv.gulvKonstruksjon?.rehabiliteringEllerPabyggingsAr;
            const isProfessional =
              state.register.user?.rolle === UserRole.PROFESJONELL;

            if (
              gulvRehabiliteringAr &&
              (gulvRehabiliteringAr < (byggAr || 0) ||
                (isProfessional &&
                  gulvRehabiliteringAr > getYear(Date.now()) + 5) ||
                (!isProfessional && gulvRehabiliteringAr > getYear(Date.now())))
            ) {
              return { gulvRehabiliteringAr };
            }
          }
        }
      }
    }
  }

  if (
    step === RegistrationCommonStep.PARAMETERS &&
    state.register.energiplan?.energimerke?.inndata?.beregningskjerneInput
  ) {
    const {
      register: {
        energiplan: {
          energimerke: {
            inndata: {
              beregningskjerneInput: {
                varmetapMotUoppvarmedeRom: {
                  varmetapsfaktor_uoppv: { expertValue: varmetapsfaktor_uoppv }
                },
                varmetransportVentilasjon: {
                  tempvirkningsgrad_varmegjenvinner: {
                    expertValue: tempvirkningsgrad_varmegjenvinner
                  }
                },
                solskjermingData: {
                  solskjermingsfaktor_horisont_oest: {
                    expertValue: solskjermingsfaktor_horisont_oest
                  },
                  solskjermingsfaktor_horisont_vest: {
                    expertValue: solskjermingsfaktor_horisont_vest
                  },
                  solskjermingsfaktor_horisont_soer: {
                    expertValue: solskjermingsfaktor_horisont_soer
                  },
                  solskjermingsfaktor_horisont_nord: {
                    expertValue: solskjermingsfaktor_horisont_nord
                  },
                  solskjermingsfaktor_horisont_tak: {
                    expertValue: solskjermingsfaktor_horisont_tak
                  },
                  solskjermingsfaktor_overheng_oest: {
                    expertValue: solskjermingsfaktor_overheng_oest
                  },
                  solskjermingsfaktor_overheng_vest: {
                    expertValue: solskjermingsfaktor_overheng_vest
                  },
                  solskjermingsfaktor_overheng_soer: {
                    expertValue: solskjermingsfaktor_overheng_soer
                  },
                  solskjermingsfaktor_overheng_nord: {
                    expertValue: solskjermingsfaktor_overheng_nord
                  },
                  solskjermingsfaktor_overheng_tak: {
                    expertValue: solskjermingsfaktor_overheng_tak
                  },
                  solskjermingsfaktor_finner_oest: {
                    expertValue: solskjermingsfaktor_finner_oest
                  },
                  solskjermingsfaktor_finner_vest: {
                    expertValue: solskjermingsfaktor_finner_vest
                  },
                  solskjermingsfaktor_finner_soer: {
                    expertValue: solskjermingsfaktor_finner_soer
                  },
                  solskjermingsfaktor_finner_nord: {
                    expertValue: solskjermingsfaktor_finner_nord
                  },
                  solskjermingsfaktor_finner_tak: {
                    expertValue: solskjermingsfaktor_finner_tak
                  },
                  arealfraksjon_karm_oest: {
                    expertValue: arealfraksjon_karm_oest
                  },
                  arealfraksjon_karm_vest: {
                    expertValue: arealfraksjon_karm_vest
                  },
                  arealfraksjon_karm_soer: {
                    expertValue: arealfraksjon_karm_soer
                  },
                  arealfraksjon_karm_nord: {
                    expertValue: arealfraksjon_karm_nord
                  },
                  arealfraksjon_karm_tak: {
                    expertValue: arealfraksjon_karm_tak
                  },
                  solfaktor_vindu_oest: { expertValue: solfaktor_vindu_oest },
                  solfaktor_vindu_vest: { expertValue: solfaktor_vindu_vest },
                  solfaktor_vindu_soer: { expertValue: solfaktor_vindu_soer },
                  solfaktor_vindu_nord: { expertValue: solfaktor_vindu_nord },
                  solfaktor_vindu_tak: { expertValue: solfaktor_vindu_tak },
                  solfaktor_total_glass_skjerming_oest: {
                    expertValue: solfaktor_total_glass_skjerming_oest
                  },
                  solfaktor_total_glass_skjerming_vest: {
                    expertValue: solfaktor_total_glass_skjerming_vest
                  },
                  solfaktor_total_glass_skjerming_soer: {
                    expertValue: solfaktor_total_glass_skjerming_soer
                  },
                  solfaktor_total_glass_skjerming_nord: {
                    expertValue: solfaktor_total_glass_skjerming_nord
                  },
                  solfaktor_total_glass_skjerming_tak: {
                    expertValue: solfaktor_total_glass_skjerming_tak
                  }
                },
                behovLevertElektrisitet: {
                  el_solcelle_andel_el_spesifikt_forbruk: {
                    expertValue: el_solcelle_andel_el_spesifikt_forbruk
                  },
                  el_er_andel_energi_oppv_ventilasjon: {
                    expertValue: el_er_andel_energi_oppv_ventilasjon
                  },
                  el_hp_andel_energi_oppv_ventilasjon: {
                    expertValue: el_hp_andel_energi_oppv_ventilasjon
                  },
                  el_Tsol_andel_energi_oppv_ventilasjon: {
                    expertValue: el_Tsol_andel_energi_oppv_ventilasjon
                  },
                  el_er_andel_energi_tappevann_varme: {
                    expertValue: el_er_andel_energi_tappevann_varme
                  },
                  el_hp_andel_energi_tappevann_varme: {
                    expertValue: el_hp_andel_energi_tappevann_varme
                  },
                  el_Tsol_andel_energi_tappevann_varme: {
                    expertValue: el_Tsol_andel_energi_tappevann_varme
                  },
                  systemvirkningsgrad_elektrisk_oppv_ventilasjon: {
                    expertValue: systemvirkningsgrad_elektrisk_oppv_ventilasjon
                  },
                  systemvirkningsgrad_elektrisk_tappevann_varme: {
                    expertValue: systemvirkningsgrad_elektrisk_tappevann_varme
                  },
                  systemvirkningsgrad_varmepumpeanlegg_oppv_ventilasjon: {
                    expertValue:
                      systemvirkningsgrad_varmepumpeanlegg_oppv_ventilasjon
                  },
                  systemvirkningsgrad_varmepumpeanlegg_tappevann_varme: {
                    expertValue:
                      systemvirkningsgrad_varmepumpeanlegg_tappevann_varme
                  },
                  systemvirkningsgrad_solfanger_termisk_oppv_ventilasjon: {
                    expertValue:
                      systemvirkningsgrad_solfanger_termisk_oppv_ventilasjon
                  },
                  systemvirkningsgrad_solfanger_termisk_tappevann_varme: {
                    expertValue:
                      systemvirkningsgrad_solfanger_termisk_tappevann_varme
                  }
                },
                behovLevertOljeGassFjernvarmeBioenergiAndre: {
                  olje_andel_energi_oppv_ventilasjon: {
                    expertValue: olje_andel_energi_oppv_ventilasjon
                  },
                  olje_andel_energi_tappevann_varme: {
                    expertValue: olje_andel_energi_tappevann_varme
                  },
                  systemvirkningsgrad_olje_oppv_ventilasjon: {
                    expertValue: systemvirkningsgrad_olje_oppv_ventilasjon
                  },
                  systemvirkningsgrad_olje_tappevann_varme: {
                    expertValue: systemvirkningsgrad_olje_tappevann_varme
                  },
                  gass_andel_energi_oppv_ventilasjon: {
                    expertValue: gass_andel_energi_oppv_ventilasjon
                  },
                  gass_andel_energi_tappevann_varme: {
                    expertValue: gass_andel_energi_tappevann_varme
                  },
                  systemvirkningsgrad_gass_oppv_ventilasjon: {
                    expertValue: systemvirkningsgrad_gass_oppv_ventilasjon
                  },
                  systemvirkningsgrad_gass_tappevann_varme: {
                    expertValue: systemvirkningsgrad_gass_tappevann_varme
                  },
                  fjernvarme_andel_energi_oppv_ventilasjon: {
                    expertValue: fjernvarme_andel_energi_oppv_ventilasjon
                  },
                  fjernvarme_andel_energi_tappevann_varme: {
                    expertValue: fjernvarme_andel_energi_tappevann_varme
                  },
                  systemvirkningsgrad_fjernvarme_oppv_ventilasjon: {
                    expertValue: systemvirkningsgrad_fjernvarme_oppv_ventilasjon
                  },
                  systemvirkningsgrad_fjernvarme_tappevann: {
                    expertValue: systemvirkningsgrad_fjernvarme_tappevann
                  },
                  bio_andel_energi_oppv_ventilasjon: {
                    expertValue: bio_andel_energi_oppv_ventilasjon
                  },
                  bio_andel_energi_tappevann_varme: {
                    expertValue: bio_andel_energi_tappevann_varme
                  },
                  systemvirkningsgrad_bio_oppv_ventilasjon: {
                    expertValue: systemvirkningsgrad_bio_oppv_ventilasjon
                  },
                  systemvirkningsgrad_bio_tappevann: {
                    expertValue: systemvirkningsgrad_bio_tappevann
                  },
                  annet_andel_energi_oppv_ventilasjon: {
                    expertValue: annet_andel_energi_oppv_ventilasjon
                  },
                  annet_andel_energi_tappevann_varme: {
                    expertValue: annet_andel_energi_tappevann_varme
                  },
                  systemvirkningsgrad_annet_oppv_ventilasjon: {
                    expertValue: systemvirkningsgrad_annet_oppv_ventilasjon
                  },
                  systemvirkningsgrad_annet_tappevann: {
                    expertValue: systemvirkningsgrad_annet_tappevann
                  }
                }
              }
            }
          }
        }
      }
    } = state;

    return {
      varmetapsfaktor_uoppv,
      tempvirkningsgrad_varmegjenvinner,
      solskjermingsfaktor_horisont_oest,
      solskjermingsfaktor_horisont_vest,
      solskjermingsfaktor_horisont_soer,
      solskjermingsfaktor_horisont_nord,
      solskjermingsfaktor_horisont_tak,
      solskjermingsfaktor_overheng_oest,
      solskjermingsfaktor_overheng_vest,
      solskjermingsfaktor_overheng_soer,
      solskjermingsfaktor_overheng_nord,
      solskjermingsfaktor_overheng_tak,
      solskjermingsfaktor_finner_oest,
      solskjermingsfaktor_finner_vest,
      solskjermingsfaktor_finner_soer,
      solskjermingsfaktor_finner_nord,
      solskjermingsfaktor_finner_tak,
      arealfraksjon_karm_oest,
      arealfraksjon_karm_vest,
      arealfraksjon_karm_soer,
      arealfraksjon_karm_nord,
      arealfraksjon_karm_tak,
      solfaktor_vindu_oest,
      solfaktor_vindu_vest,
      solfaktor_vindu_soer,
      solfaktor_vindu_nord,
      solfaktor_vindu_tak,
      solfaktor_total_glass_skjerming_oest,
      solfaktor_total_glass_skjerming_vest,
      solfaktor_total_glass_skjerming_soer,
      solfaktor_total_glass_skjerming_nord,
      solfaktor_total_glass_skjerming_tak,
      el_solcelle_andel_el_spesifikt_forbruk,
      el_er_andel_energi_oppv_ventilasjon,
      el_hp_andel_energi_oppv_ventilasjon,
      el_Tsol_andel_energi_oppv_ventilasjon,
      el_er_andel_energi_tappevann_varme,
      el_hp_andel_energi_tappevann_varme,
      el_Tsol_andel_energi_tappevann_varme,
      systemvirkningsgrad_elektrisk_oppv_ventilasjon,
      systemvirkningsgrad_elektrisk_tappevann_varme,
      systemvirkningsgrad_varmepumpeanlegg_oppv_ventilasjon,
      systemvirkningsgrad_varmepumpeanlegg_tappevann_varme,
      systemvirkningsgrad_solfanger_termisk_oppv_ventilasjon,
      systemvirkningsgrad_solfanger_termisk_tappevann_varme,
      olje_andel_energi_oppv_ventilasjon,
      olje_andel_energi_tappevann_varme,
      systemvirkningsgrad_olje_oppv_ventilasjon,
      systemvirkningsgrad_olje_tappevann_varme,
      gass_andel_energi_oppv_ventilasjon,
      gass_andel_energi_tappevann_varme,
      systemvirkningsgrad_gass_oppv_ventilasjon,
      systemvirkningsgrad_gass_tappevann_varme,
      fjernvarme_andel_energi_oppv_ventilasjon,
      fjernvarme_andel_energi_tappevann_varme,
      systemvirkningsgrad_fjernvarme_oppv_ventilasjon,
      systemvirkningsgrad_fjernvarme_tappevann,
      bio_andel_energi_oppv_ventilasjon,
      bio_andel_energi_tappevann_varme,
      systemvirkningsgrad_bio_oppv_ventilasjon,
      systemvirkningsgrad_bio_tappevann,
      annet_andel_energi_oppv_ventilasjon,
      annet_andel_energi_tappevann_varme,
      systemvirkningsgrad_annet_oppv_ventilasjon,
      systemvirkningsgrad_annet_tappevann
    };
  }
  return undefined;
};

export const modifyTeksniskUtstyrForRomOppvarmingElesktrisk = (
  modifiedUtstyr: TekniskUtstyr
): TekniskUtstyr => {
  if (
    modifiedUtstyr.oppvarmingElektrisk &&
    modifiedUtstyr.oppvarmingElektrisk.kjel &&
    modifiedUtstyr.oppvarmingElektrisk.kjel.bruk &&
    [OppvarmingBruk.Tappevann].includes(
      modifiedUtstyr.oppvarmingElektrisk.kjel.bruk
    )
  )
    delete modifiedUtstyr.oppvarmingElektrisk.kjel;

  if (
    modifiedUtstyr.oppvarmingElektrisk &&
    Object.keys(modifiedUtstyr.oppvarmingElektrisk).length === 0
  )
    delete modifiedUtstyr.oppvarmingElektrisk;

  return modifiedUtstyr;
};

export const modifyTeksniskUtstyrForRomOppvarmingBioenergi = (
  modifiedUtstyr: TekniskUtstyr
): TekniskUtstyr => {
  if (
    modifiedUtstyr.oppvarmingBioenergi &&
    modifiedUtstyr.oppvarmingBioenergi.kjel &&
    modifiedUtstyr.oppvarmingBioenergi.kjel.bruk &&
    [OppvarmingBruk.Tappevann].includes(
      modifiedUtstyr.oppvarmingBioenergi.kjel.bruk
    )
  )
    delete modifiedUtstyr.oppvarmingBioenergi.kjel;

  if (
    modifiedUtstyr.oppvarmingBioenergi &&
    modifiedUtstyr.oppvarmingBioenergi.kaminMedVannbarenVarme &&
    modifiedUtstyr.oppvarmingBioenergi.kaminMedVannbarenVarme.bruk &&
    [OppvarmingBruk.Tappevann].includes(
      modifiedUtstyr.oppvarmingBioenergi.kaminMedVannbarenVarme.bruk
    )
  )
    delete modifiedUtstyr.oppvarmingBioenergi.kaminMedVannbarenVarme;

  if (
    modifiedUtstyr.oppvarmingBioenergi &&
    Object.keys(modifiedUtstyr.oppvarmingBioenergi).length === 0
  )
    delete modifiedUtstyr.oppvarmingBioenergi;

  return modifiedUtstyr;
};

export const modifyTeksniskUtstyrForRomOppvarmingVarmepumpe = (
  modifiedUtstyr: TekniskUtstyr
): TekniskUtstyr => {
  if (
    modifiedUtstyr.oppvarmingVarmepumpe &&
    modifiedUtstyr.oppvarmingVarmepumpe.varmeKilde &&
    ![Varmekilde.Ukjent].includes(
      modifiedUtstyr.oppvarmingVarmepumpe.varmeKilde
    ) &&
    ('punktoppvarming' in modifiedUtstyr.oppvarmingVarmepumpe ||
      'vannbarenVarme' in modifiedUtstyr.oppvarmingVarmepumpe ||
      'ventilasjon' in modifiedUtstyr.oppvarmingVarmepumpe)
  )
    delete modifiedUtstyr.oppvarmingVarmepumpe.varmeKilde;

  if (
    modifiedUtstyr.oppvarmingVarmepumpe &&
    'brukStandardVerdier' in modifiedUtstyr.oppvarmingVarmepumpe
  )
    delete modifiedUtstyr.oppvarmingVarmepumpe.brukStandardVerdier;

  if (
    modifiedUtstyr.oppvarmingVarmepumpe &&
    modifiedUtstyr.oppvarmingVarmepumpe.vannbarenVarme &&
    modifiedUtstyr.oppvarmingVarmepumpe.vannbarenVarme.bruk &&
    [OppvarmingBruk.Tappevann].includes(
      modifiedUtstyr.oppvarmingVarmepumpe.vannbarenVarme.bruk
    )
  )
    delete modifiedUtstyr.oppvarmingVarmepumpe.vannbarenVarme;

  if (
    modifiedUtstyr.oppvarmingVarmepumpe &&
    Object.keys(modifiedUtstyr.oppvarmingVarmepumpe).length === 0
  )
    delete modifiedUtstyr.oppvarmingVarmepumpe;

  return modifiedUtstyr;
};

export const modifyTeksniskUtstyrForRomOppvarmingSol = (
  modifiedUtstyr: TekniskUtstyr
): TekniskUtstyr => {
  if (
    modifiedUtstyr.oppvarmingSol &&
    modifiedUtstyr.oppvarmingSol.solfangerMedVannbarenVarme &&
    modifiedUtstyr.oppvarmingSol.solfangerMedVannbarenVarme.bruk &&
    [OppvarmingBruk.Tappevann].includes(
      modifiedUtstyr.oppvarmingSol.solfangerMedVannbarenVarme.bruk
    )
  )
    delete modifiedUtstyr.oppvarmingSol.solfangerMedVannbarenVarme;

  if (
    modifiedUtstyr.oppvarmingSol &&
    'solceller' in modifiedUtstyr.oppvarmingSol
  )
    delete modifiedUtstyr.oppvarmingSol.solceller;

  if (
    modifiedUtstyr.oppvarmingSol &&
    Object.keys(modifiedUtstyr.oppvarmingSol).length === 0
  )
    delete modifiedUtstyr.oppvarmingSol;

  return modifiedUtstyr;
};

export const modifyTeksniskUtstyrForRomOppvarmingOlje = (
  modifiedUtstyr: TekniskUtstyr
): TekniskUtstyr => {
  if (
    modifiedUtstyr.oppvarmingOlje &&
    modifiedUtstyr.oppvarmingOlje.kjel &&
    modifiedUtstyr.oppvarmingOlje.kjel.bruk &&
    [OppvarmingBruk.Tappevann].includes(modifiedUtstyr.oppvarmingOlje.kjel.bruk)
  )
    delete modifiedUtstyr.oppvarmingOlje.kjel;

  if (
    modifiedUtstyr.oppvarmingOlje &&
    Object.keys(modifiedUtstyr.oppvarmingOlje).length === 0
  )
    delete modifiedUtstyr.oppvarmingOlje;

  return modifiedUtstyr;
};

export const modifyTeksniskUtstyrForRomOppvarmingGass = (
  modifiedUtstyr: TekniskUtstyr
): TekniskUtstyr => {
  if (
    modifiedUtstyr.oppvarmingGass &&
    modifiedUtstyr.oppvarmingGass.kjel &&
    modifiedUtstyr.oppvarmingGass.kjel.bruk &&
    [OppvarmingBruk.Tappevann].includes(modifiedUtstyr.oppvarmingGass.kjel.bruk)
  )
    delete modifiedUtstyr.oppvarmingGass.kjel;

  if (
    modifiedUtstyr.oppvarmingGass &&
    Object.keys(modifiedUtstyr.oppvarmingGass).length === 0
  )
    delete modifiedUtstyr.oppvarmingGass;

  return modifiedUtstyr;
};

export const checkTeksniskUtstyrHasRomoppvarming = (
  utstyr: TekniskUtstyr
): boolean => {
  // Make deep copy
  if (!utstyr) return true;
  let modifiedUtstyr = JSON.parse(JSON.stringify(utstyr));
  if (!modifiedUtstyr) return true;

  // Remove fields from OppvarmingElektrisk
  modifiedUtstyr =
    modifyTeksniskUtstyrForRomOppvarmingElesktrisk(modifiedUtstyr);

  // Remove fields from OppvarmingBioenergi
  modifiedUtstyr =
    modifyTeksniskUtstyrForRomOppvarmingBioenergi(modifiedUtstyr);

  // Remove fields from OppvarmingVarmepumpe
  modifiedUtstyr =
    modifyTeksniskUtstyrForRomOppvarmingVarmepumpe(modifiedUtstyr);

  // Remove fields from OppvarmingSol
  modifiedUtstyr = modifyTeksniskUtstyrForRomOppvarmingSol(modifiedUtstyr);

  // Remove fields from OppvarmingOlje
  modifiedUtstyr = modifyTeksniskUtstyrForRomOppvarmingOlje(modifiedUtstyr);

  // Remove fields from OppvarmingGass
  modifiedUtstyr = modifyTeksniskUtstyrForRomOppvarmingGass(modifiedUtstyr);

  // Final assessment
  if (
    Object.keys(modifiedUtstyr).length === 0 &&
    ((utstyr.oppvarmingElektrisk &&
      Object.keys(utstyr.oppvarmingElektrisk).length > 0) ||
      (utstyr.oppvarmingBioenergi &&
        Object.keys(utstyr.oppvarmingBioenergi).length > 0) ||
      (utstyr.oppvarmingVarmepumpe &&
        Object.keys(utstyr.oppvarmingVarmepumpe).length > 0) ||
      (utstyr.oppvarmingSol && Object.keys(utstyr.oppvarmingSol).length > 0) ||
      (utstyr.oppvarmingOlje &&
        Object.keys(utstyr.oppvarmingOlje).length > 0) ||
      (utstyr.oppvarmingGass && Object.keys(utstyr.oppvarmingGass).length > 0))
  )
    return false;

  return true;
};
