import type { SigninRedirectArgs, User } from 'oidc-client-ts';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';

import { authManager } from '../../auth';

import { ActionType, reducer } from './reducer';
import { AuthenticationState, initialAuthState } from './types';
import { hasAuthParams, hasReferrer, REFERRER_KEY } from './utils';

interface AuthenticationContext extends AuthenticationState {
  closeSessionExpiresDialog: () => void;
  signIn: (args?: SigninRedirectArgs) => Promise<void>;
  signOut: () => Promise<void>;
}

export const AuthenticationContext =
  createContext<AuthenticationContext | null>(null);

export const AuthenticationProvider: FC<{ children: ReactNode }> = ({
  children
}) => {
  const [userManager] = useState(() => authManager);
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const isInitialized = useRef(false);
  const restartSilentRenewAttempts = useRef<number>(0);

  useEffect(() => {
    if (state.sessionExpiry.isNotificationDismissed) {
      return;
    }

    const userExpiresIn = state.user?.expires_in ?? 0;

    if (state.user && userExpiresIn < 55) {
      dispatch({ type: ActionType.SESSION_EXPIRES });
    }
  }, [state]);

  useEffect(() => {
    const expiresIn = state.user?.expires_in ?? 0;

    // Whenever an authenticated user is loaded in state we reset the silent renew attempts variable.
    if (expiresIn > 0 && restartSilentRenewAttempts.current > 0) {
      restartSilentRenewAttempts.current = 0;
    }
  }, [state]);

  useEffect(() => {
    if (!userManager || isInitialized.current) {
      return;
    }

    isInitialized.current = true;

    const getUser = async (): Promise<void> => {
      let user: User | null | void = null;

      try {
        // Check if we are returning back from authority server with auth params in the URL.
        if (hasAuthParams()) {
          user = await userManager.signinCallback();
        }

        user = !user ? await userManager.getUser() : user;

        dispatch({ type: ActionType.INITIALISED, user });

        // Check if the user has a redirect url passed in state. If so redirect.
        if (user?.state && hasReferrer(user.state)) {
          window.location.replace(user.state?.[REFERRER_KEY]);
        }

        return;
      } catch (error) {
        dispatch({ type: ActionType.ERROR, error });
      }
    };

    getUser();
  }, [userManager]);

  useEffect(() => {
    if (!userManager) {
      return;
    }

    const handleUserLoaded = (user: User) =>
      dispatch({ type: ActionType.USER_LOADED, user });
    const handleUserUnloaded = () =>
      dispatch({ type: ActionType.USER_UNLOADED });
    const handleSilentRenewError = (error: Error) =>
      console.warn('Silent renew error', error);

    // Function called when the accessTokenExpired event is raised. The oidc-client-ts
    // package also dispatches the accessTokenExpired event when the application wakes up
    // from sleep or inactivity. In this scenario the oidc-client-ts fails to silently
    // refresh the acccess token. We should first attempt to restart the silent renew process, and
    // if that attempt is unsuccessful, we will proceed to sign in the user again.
    const handleAccessTokenExpired = async () => {
      const localTimeString = new Date().toLocaleTimeString();

      console.warn('Access token expired', localTimeString);

      const user = await userManager.getUser();
      const validUser = user && (!user.expired || user.refresh_token);

      // Try to restart the silent renew process if we haven't already tried to do so.
      if (restartSilentRenewAttempts.current === 0 && validUser) {
        console.warn('Silent renew process restart', localTimeString);

        try {
          userManager.stopSilentRenew();
          userManager.startSilentRenew();
        } catch (error) {
          console.error('Failed to restart the silent renew process', error);
        } finally {
          // Once we have attempted to restart the silent renew process, we need to increment
          // our reference variable to prevent an infinite loop of silent renew restart attempts.
          restartSilentRenewAttempts.current += 1;
        }

        return;
      }

      // We have already tried to restart the silent renew process without success and
      // the user will need to sign in again.
      console.warn('Sign out. Redirecting to login', localTimeString);

      await userManager.signoutRedirect();
      await userManager.removeUser();
    };

    userManager.events.addUserLoaded(handleUserLoaded);
    userManager.events.addUserUnloaded(handleUserUnloaded);
    userManager.events.addSilentRenewError(handleSilentRenewError);
    userManager.events.addAccessTokenExpired(handleAccessTokenExpired);

    return () => {
      userManager.events.removeUserLoaded(handleUserLoaded);
      userManager.events.removeUserUnloaded(handleUserUnloaded);
      userManager.events.removeSilentRenewError(handleSilentRenewError);
      userManager.events.removeAccessTokenExpired(handleAccessTokenExpired);
    };
  }, [userManager]);

  const signIn = useCallback(
    async (args?: SigninRedirectArgs) => await userManager.signinRedirect(args),
    [userManager]
  );

  const signOut = useCallback(async () => {
    await userManager.signoutRedirect();
  }, [userManager]);

  const closeSessionExpiresDialog = () =>
    dispatch({ type: ActionType.SESSION_EXPIRES_CONFIRMED });

  return (
    <AuthenticationContext.Provider
      value={{ ...state, signIn, signOut, closeSessionExpiresDialog }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
