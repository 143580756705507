import React, { FC } from 'react';
import { Box, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { useXmlRegistrationSearchParams } from '../common/hooks/useXmlRegistrationSearchParams';
import { XmlRegistrationHeader } from '../common/components/xmlRegistrationHeader';
import { FlerboligRegistrationResponse } from '../../../../../services/types';

interface Props {
  flerboligRegistrationData?: FlerboligRegistrationResponse;
}

export const FlerboligHeader: FC<Props> = ({ flerboligRegistrationData }) => {
  const { t } = useTranslation();
  const { groupName, selectedBuildingIds } = useXmlRegistrationSearchParams();

  const buildingCount =
    flerboligRegistrationData?.energianalyser?.length ||
    selectedBuildingIds.length;

  const name = flerboligRegistrationData?.flerboligbygg?.navn || groupName;

  return (
    <XmlRegistrationHeader
      headerInfo={
        <Box pb={5}>
          <Typography variant="h3">{name}</Typography>
          <Typography>
            {t('expertToolsXML.flerbolig.buildingsSelected', {
              count: buildingCount
            })}
          </Typography>
        </Box>
      }
    />
  );
};
