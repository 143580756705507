import { createContext } from 'react';

import { ExpertToolsXMLPortfolioStep } from '../../../../utils/expertToolsXML/enums';
import { SimpleEnergyAnalysis } from '../../../../types/building';
import { Tiltak } from '../../../../types/registration/tiltak';

export type ExpertToolsXMLPortfolioContextValues = {
  goToNextStep?: () => void;
  goToPrevStep?: () => void;
  nextStep?: ExpertToolsXMLPortfolioStep;
  portefoljeId?: string;
  prevStep?: ExpertToolsXMLPortfolioStep;
  refreshPortefoljeData: () => void;
  simpleEnergyAnalyses: SimpleEnergyAnalysis[];
  step: ExpertToolsXMLPortfolioStep;
  steps: ExpertToolsXMLPortfolioStep[];
  tiltakList?: Tiltak[];
  loadingStep: boolean;
};

const ExpertToolsXMLPortfolioContext =
  createContext<ExpertToolsXMLPortfolioContextValues>({
    refreshPortefoljeData: () => undefined,
    simpleEnergyAnalyses: [],
    step: ExpertToolsXMLPortfolioStep.UPLOAD,
    steps: [],
    loadingStep: false
  });

export default ExpertToolsXMLPortfolioContext;
