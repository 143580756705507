import React, { FC, Fragment } from 'react';

import useBuildingDetails from '../../hooks/useBuildingDetails';

import EnovaApplicationListItem from './enovaApplicationListItem';

type EnovaApplicationProps = {
  displayActionButtons?: boolean;
};

const EnovaApplicationList: FC<EnovaApplicationProps> = ({
  displayActionButtons
}) => {
  const { building, soknadStegMap } = useBuildingDetails();
  const gyldigSoknadId = building?.gyldigSoknad?.soknadId;

  if (!gyldigSoknadId || !soknadStegMap) {
    return null;
  }

  const applicationIsCompleted = !!soknadStegMap?.steg3?.done;

  return (
    <Fragment>
      {Object.values(soknadStegMap).map((soknadstegInfo) => {
        if (!soknadstegInfo) {
          return null;
        }

        const isPreview = soknadstegInfo.soknadSteg === 'steg2';

        return (
          <EnovaApplicationListItem
            {...soknadstegInfo}
            isPreview={isPreview}
            displayActionButtons={displayActionButtons}
            key={soknadstegInfo.id}
            soknadId={gyldigSoknadId}
            applicationIsCompleted={applicationIsCompleted}
            isSteg1Done={!!soknadStegMap.steg1?.done}
          />
        );
      })}
    </Fragment>
  );
};

export default EnovaApplicationList;
