import {
  getKeysWithValue,
  getRadioOptionsWithTLabelKey
} from '../../../../utils/register';
import { CheckboxType, Field } from '../../../../types/registration/fieldTypes';
import {
  OppvarmingBruk,
  Termostatstyring,
  TermostatstyringGulv,
  Varmekilde
} from '../registerEnums';

import {
  getInstallasjonsArField,
  getKjelOrKaminFields,
  getNestedTermostatstyringField,
  getServiceGjennomfortSiste2ArField,
  getTermostatstyringField,
  getTermostatstyringGulvField,
  getVarmepumpeVentilationFields,
  kjelOrKaminDefaultValueOnCheck
} from './common';

export const TechnicalEquipmentFields: CheckboxType[] = [
  {
    attrKey: 'tekniskUtstyr',
    path: ['tekniskUtstyr'],
    options: [
      {
        tLabelKey: 'register.tekniskUtstyr.oppvarmingElektrisk.label',
        value: 'oppvarmingElektrisk',
        tHelperTextKey:
          'register.tekniskUtstyr.oppvarmingElektrisitet.helperText',
        fields: [
          {
            attrKey: 'oppvarmingElektrisk',
            path: ['tekniskUtstyr', 'oppvarmingElektrisk'],
            selector: (state) =>
              getKeysWithValue(
                state.register.userInput?.tekniskUtstyr?.oppvarmingElektrisk
              ),
            tLabelKey: '',
            type: Field.CHECK,
            options: [
              {
                defaultValueOnCheck: {
                  termostatstyring: Termostatstyring.Ukjent
                },
                fields: [
                  getNestedTermostatstyringField(
                    (state) =>
                      state.register.userInput?.tekniskUtstyr
                        ?.oppvarmingElektrisk?.panelovn,
                    ['tekniskUtstyr', 'oppvarmingElektrisk', 'panelovn']
                  )
                ],
                tLabelKey: 'register.tekniskUtstyr.panelovn.label',
                value: 'panelovn'
              },
              {
                defaultValueOnCheck: {
                  termostatstyring: TermostatstyringGulv.Ukjent
                },
                fields: [
                  getTermostatstyringGulvField(
                    (state) =>
                      state.register.userInput?.tekniskUtstyr
                        ?.oppvarmingElektrisk?.gulvvarme,
                    ['tekniskUtstyr', 'oppvarmingElektrisk', 'gulvvarme']
                  )
                ],
                tLabelKey: 'register.tekniskUtstyr.gulvvarme.label',
                value: 'gulvvarme'
              },
              {
                defaultValueOnCheck: kjelOrKaminDefaultValueOnCheck,
                tLabelKey: 'register.tekniskUtstyr.kjel.label',
                tHelperTextKey: 'register.tekniskUtstyr.kjel.helperText',
                value: 'kjel',
                fields: getKjelOrKaminFields(
                  (state) =>
                    state.register.userInput?.tekniskUtstyr?.oppvarmingElektrisk
                      ?.kjel,
                  'kjel',
                  ['tekniskUtstyr', 'oppvarmingElektrisk', 'kjel']
                ).map((field) =>
                  field.attrKey === 'tekniskUtstyr.fordeling'
                    ? {
                        ...field,
                        condition: (state) =>
                          !!state.register.userInput?.tekniskUtstyr
                            ?.oppvarmingElektrisk?.kjel?.bruk &&
                          [
                            OppvarmingBruk.TappevannOgOppvarming,
                            OppvarmingBruk.Oppvarming,
                            OppvarmingBruk.Ukjent
                          ].includes(
                            state.register.userInput?.tekniskUtstyr
                              ?.oppvarmingElektrisk?.kjel?.bruk
                          )
                      }
                    : field
                )
              },
              {
                defaultValueOnCheck: {
                  termostatstyring: Termostatstyring.Ukjent
                },
                fields: [
                  getNestedTermostatstyringField(
                    (state) =>
                      state.register.userInput?.tekniskUtstyr
                        ?.oppvarmingElektrisk?.takvarme,
                    ['tekniskUtstyr', 'oppvarmingElektrisk', 'takvarme']
                  )
                ],
                tLabelKey: 'register.tekniskUtstyr.takvarme.label',
                tHelperTextKey: 'register.tekniskUtstyr.takvarme.helperText',
                value: 'takvarme'
              },
              {
                defaultValueOnCheck: {
                  termostatstyring: Termostatstyring.Ukjent
                },
                fields: [
                  getNestedTermostatstyringField(
                    (state) =>
                      state.register.userInput?.tekniskUtstyr
                        ?.oppvarmingElektrisk?.luftvarme,
                    ['tekniskUtstyr', 'oppvarmingElektrisk', 'luftvarme']
                  )
                ],
                tLabelKey: 'register.tekniskUtstyr.luftvarme.label',
                tHelperTextKey: 'register.tekniskUtstyr.luftvarme.helperText',
                value: 'luftvarme'
              }
            ]
          }
        ]
      },
      {
        tHelperTextKey:
          'register.tekniskUtstyr.oppvarmingFjernvarme.helperText',
        tLabelKey: 'register.tekniskUtstyr.oppvarmingFjernvarme.label',
        value: 'oppvarmingFjernvarme',
        fields: [
          {
            attrKey: 'oppvarmingFjernvarme',
            selector: (state) =>
              getKeysWithValue(
                state.register.userInput?.tekniskUtstyr?.oppvarmingFjernvarme
              ),
            path: ['tekniskUtstyr', 'oppvarmingFjernvarme'],
            tLabelKey: '',
            type: Field.CHECK,
            options: [
              {
                defaultValueOnCheck: Termostatstyring.Ukjent,
                fields: [
                  getTermostatstyringField(
                    (state) =>
                      state.register.userInput?.tekniskUtstyr
                        ?.oppvarmingFjernvarme?.gulvvarme,
                    ['tekniskUtstyr', 'oppvarmingFjernvarme', 'gulvvarme']
                  )
                ],
                tLabelKey: 'register.tekniskUtstyr.vannbarenGulvvarme.label',
                value: 'gulvvarme'
              },
              {
                defaultValueOnCheck: Termostatstyring.Ukjent,
                fields: [
                  getTermostatstyringField(
                    (state) =>
                      state.register.userInput?.tekniskUtstyr
                        ?.oppvarmingFjernvarme?.radiator,
                    ['tekniskUtstyr', 'oppvarmingFjernvarme', 'radiator']
                  )
                ],
                tLabelKey: 'register.tekniskUtstyr.radiator.label',
                value: 'radiator'
              }
            ]
          }
        ]
      },
      {
        tHelperTextKey: 'register.tekniskUtstyr.oppvarmingBioenergi.helperText',
        tLabelKey: 'register.tekniskUtstyr.oppvarmingBioenergi.label',
        value: 'oppvarmingBioenergi',
        fields: [
          {
            attrKey: 'oppvarmingBioenergi',
            options: [
              {
                defaultValueOnCheck: {
                  termostatstyring: Termostatstyring.Ukjent
                },
                fields: [
                  getNestedTermostatstyringField(
                    (state) =>
                      state.register.userInput?.tekniskUtstyr
                        ?.oppvarmingBioenergi?.kamin,
                    ['tekniskUtstyr', 'oppvarmingBioenergi', 'kamin']
                  )
                ],
                tLabelKey: 'register.tekniskUtstyr.bioKamin.label',
                value: 'kamin'
              },
              {
                defaultValueOnCheck: kjelOrKaminDefaultValueOnCheck,
                tLabelKey:
                  'register.tekniskUtstyr.bioKaminMedVannbarenVarme.label',
                value: 'kaminMedVannbarenVarme',
                fields: getKjelOrKaminFields(
                  (state) =>
                    state.register.userInput?.tekniskUtstyr?.oppvarmingBioenergi
                      ?.kaminMedVannbarenVarme,
                  'kamin',
                  [
                    'tekniskUtstyr',
                    'oppvarmingBioenergi',
                    'kaminMedVannbarenVarme'
                  ]
                ).map((field) =>
                  field.attrKey === 'tekniskUtstyr.fordeling'
                    ? {
                        ...field,
                        condition: (state) =>
                          !!state.register.userInput?.tekniskUtstyr
                            ?.oppvarmingBioenergi?.kaminMedVannbarenVarme
                            ?.bruk &&
                          [
                            OppvarmingBruk.TappevannOgOppvarming,
                            OppvarmingBruk.Oppvarming,
                            OppvarmingBruk.Ukjent
                          ].includes(
                            state.register.userInput?.tekniskUtstyr
                              ?.oppvarmingBioenergi?.kaminMedVannbarenVarme
                              ?.bruk
                          )
                      }
                    : field
                )
              },
              {
                defaultValueOnCheck: kjelOrKaminDefaultValueOnCheck,
                tLabelKey: 'register.tekniskUtstyr.bioKjel.label',
                value: 'kjel',
                fields: getKjelOrKaminFields(
                  (state) =>
                    state.register.userInput?.tekniskUtstyr?.oppvarmingBioenergi
                      ?.kjel,
                  'kjel',
                  ['tekniskUtstyr', 'oppvarmingBioenergi', 'kjel']
                ).map((field) =>
                  field.attrKey === 'tekniskUtstyr.fordeling'
                    ? {
                        ...field,
                        condition: (state) =>
                          !!state.register.userInput?.tekniskUtstyr
                            ?.oppvarmingBioenergi?.kjel?.bruk &&
                          [
                            OppvarmingBruk.TappevannOgOppvarming,
                            OppvarmingBruk.Oppvarming,
                            OppvarmingBruk.Ukjent
                          ].includes(
                            state.register.userInput?.tekniskUtstyr
                              ?.oppvarmingBioenergi?.kjel?.bruk
                          )
                      }
                    : field
                )
              }
            ],
            path: ['tekniskUtstyr', 'oppvarmingBioenergi'],
            selector: (state) =>
              getKeysWithValue(
                state.register.userInput?.tekniskUtstyr?.oppvarmingBioenergi
              ),
            type: Field.CHECK,
            tLabelKey: ''
          }
        ]
      },
      {
        defaultValueOnCheck: { varmeKilde: Varmekilde.Ukjent },
        fields: [
          {
            attrKey: 'varmeKilde',
            path: ['tekniskUtstyr', 'oppvarmingVarmepumpe', 'varmeKilde'],
            options: getRadioOptionsWithTLabelKey(
              Varmekilde,
              undefined,
              (label) =>
                `register.tekniskUtstyr.oppvarmingVarmepumpe.varmeKilde.option.${label}`
            ).map((item) =>
              item.value === Varmekilde.Ukjent
                ? item
                : {
                    ...item,
                    fields: [
                      {
                        attrKey: '',
                        type: Field.RADIO,
                        path: [
                          'tekniskUtstyr',
                          'oppvarmingVarmepumpe',
                          'oppvarmingVarmepumpe.varmeKilde.type'
                        ],
                        selector: (state) =>
                          Object.keys(
                            state.register.userInput?.tekniskUtstyr
                              ?.oppvarmingVarmepumpe ?? {}
                          )?.filter((key) =>
                            [
                              'punktoppvarming',
                              'vannbarenVarme',
                              'ventilasjon'
                            ].includes(key)
                          )?.[0],
                        tLabelKey:
                          'register.tekniskUtstyr.oppvarmingVarmepumpe.fordelingSystem.label',
                        tInformationKey:
                          'register.tekniskUtstyr.oppvarmingVarmepumpe.fordelingSystem.information',
                        options: [
                          {
                            tLabelKey:
                              'register.tekniskUtstyr.punktoppvarming.label',
                            tHelperTextKey:
                              'register.tekniskUtstyr.punktoppvarming.helperText',
                            value: 'punktoppvarming',
                            fields: [
                              getInstallasjonsArField(
                                (state) =>
                                  state.register.userInput?.tekniskUtstyr
                                    ?.oppvarmingVarmepumpe?.punktoppvarming
                                    ?.installasjonsAr,
                                [
                                  'tekniskUtstyr',
                                  'oppvarmingVarmepumpe',
                                  'punktoppvarming'
                                ]
                              ),
                              getServiceGjennomfortSiste2ArField(
                                (state) =>
                                  state.register.userInput?.tekniskUtstyr
                                    ?.oppvarmingVarmepumpe?.punktoppvarming
                                    ?.serviceGjennomfortSiste2Ar,
                                'punktoppvarming',
                                [
                                  'tekniskUtstyr',
                                  'oppvarmingVarmepumpe',
                                  'punktoppvarming'
                                ]
                              )
                            ]
                          },
                          {
                            tLabelKey:
                              'register.tekniskUtstyr.vannbarenVarme.label varme',
                            tHelperTextKey:
                              'register.tekniskUtstyr.vannbarenVarme.helperText',
                            value: 'vannbarenVarme',
                            fields: getKjelOrKaminFields(
                              (state) =>
                                state.register.userInput?.tekniskUtstyr
                                  ?.oppvarmingVarmepumpe?.vannbarenVarme,
                              'vannbarenVarme',
                              [
                                'tekniskUtstyr',
                                'oppvarmingVarmepumpe',
                                'vannbarenVarme'
                              ]
                            )
                              .filter(
                                ({ attrKey }) => attrKey !== 'varmesentral'
                              )
                              .map((field) =>
                                field.attrKey === 'tekniskUtstyr.fordeling'
                                  ? {
                                      ...field,
                                      condition: (state) =>
                                        !!state.register.userInput
                                          ?.tekniskUtstyr?.oppvarmingVarmepumpe
                                          ?.vannbarenVarme?.bruk &&
                                        [
                                          OppvarmingBruk.TappevannOgOppvarming,
                                          OppvarmingBruk.Oppvarming,
                                          OppvarmingBruk.Ukjent
                                        ].includes(
                                          state.register.userInput
                                            ?.tekniskUtstyr
                                            ?.oppvarmingVarmepumpe
                                            ?.vannbarenVarme?.bruk
                                        )
                                    }
                                  : field
                              )
                          },
                          {
                            tLabelKey:
                              'register.tekniskUtstyr.ventilasjon.label',
                            tHelperTextKey:
                              'register.tekniskUtstyr.ventilasjon.helperText',
                            value: 'ventilasjon',
                            fields: getVarmepumpeVentilationFields(
                              (state) =>
                                state.register.userInput?.tekniskUtstyr
                                  ?.oppvarmingVarmepumpe?.ventilasjon,
                              'ventilasjon',
                              [
                                'tekniskUtstyr',
                                'oppvarmingVarmepumpe',
                                'ventilasjon'
                              ]
                            )
                          }
                        ]
                      }
                    ]
                  }
            ),
            selector: (state) =>
              state.register.userInput?.tekniskUtstyr?.oppvarmingVarmepumpe
                ?.varmeKilde,
            tLabelKey: `register.tekniskUtstyr.oppvarmingVarmepumpe.varmeKilde.label`,
            tInformationKey: `register.tekniskutstyr.oppvarmingVarmepumpe.varmeKilde.information`,
            type: Field.RADIO
          }
        ],
        tLabelKey: 'register.tekniskUtstyr.oppvarmingVarmepumpe.label',
        tHelperTextKey:
          'register.tekniskUtstyr.oppvarmingVarmepumpe.helperText',
        value: 'oppvarmingVarmepumpe'
      },
      {
        defaultValueOnCheck: { solceller: false },
        tLabelKey: 'register.tekniskUtstyr.oppvarmingSol.label',
        tHelperTextKey: 'register.tekniskUtstyr.oppvarmingSol.helperText',
        value: 'oppvarmingSol',
        fields: [
          {
            attrKey: 'solceller',
            row: true,
            path: ['tekniskUtstyr', 'oppvarmingSol', 'solceller'],
            selector: (state) =>
              state.register.userInput?.tekniskUtstyr?.oppvarmingSol?.solceller,
            tInformationKey:
              'register.tekniskUtstyr.oppvarmingSol.solceller.information',
            tLabelKey: 'register.tekniskUtstyr.oppvarmingSol.solceller.label',
            type: Field.RADIO,
            valueType: 'boolean',
            options: [
              {
                tLabelKey: 'yes',
                value: true,
                width: 'auto'
              },
              {
                tLabelKey: 'no',
                value: false,
                width: 'auto'
              }
            ]
          },
          {
            attrKey: 'solfangerMedVannbarenVarme',
            row: true,
            path: [
              'tekniskUtstyr',
              'oppvarmingSol',
              'solfangerMedVannbarenVarme'
            ],
            selector: (state) =>
              !!state.register.userInput?.tekniskUtstyr?.oppvarmingSol
                ?.solfangerMedVannbarenVarme,
            tInformationKey:
              'register.tekniskUtstyr.oppvarmingSol.solfangerMedVannbarenVarme.information',
            tLabelKey:
              'register.tekniskUtstyr.oppvarmingSol.solfangerMedVannbarenVarme.label',
            type: Field.RADIO,
            valueType: 'object',
            options: [
              {
                tLabelKey: 'yes',
                value: true,
                width: 'auto'
              },
              {
                tLabelKey: 'no',
                value: false,
                width: 'auto'
              }
            ],
            commonFields: {
              true: [
                {
                  attrKey: 'bruk',
                  path: [
                    'tekniskUtstyr',
                    'oppvarmingSol',
                    'solfangerMedVannbarenVarme',
                    'bruk'
                  ],
                  row: true,
                  options: getRadioOptionsWithTLabelKey(
                    OppvarmingBruk,
                    'auto',
                    (label) =>
                      `register.tekniskUtstyr.oppvarmingSol.solfangerMedVannbarenVarme.bruk.option.${label}`
                  ),
                  selector: (state) =>
                    state.register.userInput?.tekniskUtstyr?.oppvarmingSol
                      ?.solfangerMedVannbarenVarme?.bruk,
                  tLabelKey:
                    'register.tekniskUtstyr.oppvarmingSol.solfangerMedVannbarenVarme.bruk.label',
                  type: Field.RADIO
                },
                getInstallasjonsArField(
                  (state) =>
                    state.register.userInput?.tekniskUtstyr?.oppvarmingSol
                      ?.solfangerMedVannbarenVarme?.installasjonsAr,
                  [
                    'tekniskUtstyr',
                    'oppvarmingSol',
                    'solfangerMedVannbarenVarme'
                  ]
                ),
                {
                  ...getServiceGjennomfortSiste2ArField(
                    (state) =>
                      state.register.userInput?.tekniskUtstyr?.oppvarmingSol
                        ?.solfangerMedVannbarenVarme
                        ?.serviceGjennomfortSiste2Ar,
                    'punktoppvarming',
                    [
                      'tekniskUtstyr',
                      'oppvarmingSol',
                      'solfangerMedVannbarenVarme'
                    ]
                  ),
                  tLabelKey:
                    'register.tekniskUtstyr.oppvarmingSol.solfangerMedVannbarenVarme.serviceGjennomfortSiste2Ar.label'
                },
                {
                  attrKey: 'tekniskUtstyr.fordeling',
                  tDescriptionKey:
                    'register.tekniskUtstyr.fordeling.description',
                  path: [
                    'tekniskUtstyr',
                    'oppvarmingSol',
                    'solfangerMedVannbarenVarme'
                  ],
                  tLabelKey: `register.tekniskUtstyr.fordeling.label`,
                  condition: (state) =>
                    state.register?.userInput?.tekniskUtstyr?.oppvarmingSol
                      ?.solfangerMedVannbarenVarme?.bruk !==
                    OppvarmingBruk.Tappevann,
                  selector: (state) =>
                    getKeysWithValue(
                      state.register.userInput?.tekniskUtstyr?.oppvarmingSol
                        ?.solfangerMedVannbarenVarme
                    ),
                  type: Field.CHECK,
                  options: [
                    {
                      defaultValueOnCheck: Termostatstyring.Ukjent,
                      fields: [
                        getTermostatstyringField(
                          (state) =>
                            state.register.userInput?.tekniskUtstyr
                              ?.oppvarmingSol?.solfangerMedVannbarenVarme
                              ?.gulvvarme,
                          [
                            'tekniskUtstyr',
                            'oppvarmingSol',
                            'solfangerMedVannbarenVarme',
                            'gulvvarme'
                          ]
                        )
                      ],
                      tLabelKey: 'register.tekniskUtstyr.gulvvarme.label',
                      value: 'gulvvarme'
                    },
                    {
                      defaultValueOnCheck: Termostatstyring.Ukjent,
                      fields: [
                        getTermostatstyringField(
                          (state) =>
                            state.register.userInput?.tekniskUtstyr
                              ?.oppvarmingSol?.solfangerMedVannbarenVarme
                              ?.radiator,
                          [
                            'tekniskUtstyr',
                            'oppvarmingSol',
                            'solfangerMedVannbarenVarme',
                            'radiator'
                          ]
                        )
                      ],
                      tLabelKey: 'register.tekniskUtstyr.radiator.label',
                      value: 'radiator'
                    }
                  ]
                }
              ]
            }
          }
        ]
      },
      {
        tLabelKey: 'register.tekniskUtstyr.oppvarmingOlje.label',
        tHelperTextKey: 'register.tekniskUtstyr.oppvarmingOlje.helperText',
        value: 'oppvarmingOlje',
        fields: [
          {
            attrKey: '',
            selector: (state) =>
              getKeysWithValue(
                state.register.userInput?.tekniskUtstyr?.oppvarmingOlje
              ),
            path: ['tekniskUtstyr', 'oppvarmingOlje'],
            type: Field.CHECK,
            tLabelKey: '',
            options: [
              {
                defaultValueOnCheck: {
                  termostatstyring: Termostatstyring.Ukjent
                },
                tLabelKey: 'register.tekniskUtstyr.oljeKamin.label',
                value: 'kamin',
                fields: [
                  getNestedTermostatstyringField(
                    (state) =>
                      state.register.userInput?.tekniskUtstyr?.oppvarmingOlje
                        ?.kamin,
                    ['tekniskUtstyr', 'oppvarmingOlje', 'kamin']
                  )
                ]
              },
              {
                defaultValueOnCheck: kjelOrKaminDefaultValueOnCheck,
                tLabelKey: 'register.tekniskUtstyr.oljeKjel.label',
                value: 'kjel',
                fields: getKjelOrKaminFields(
                  (state) =>
                    state.register.userInput?.tekniskUtstyr?.oppvarmingOlje
                      ?.kjel,
                  'kjel',
                  ['tekniskUtstyr', 'oppvarmingOlje', 'kjel']
                ).map((field) =>
                  field.attrKey === 'tekniskUtstyr.fordeling'
                    ? {
                        ...field,
                        condition: (state) =>
                          !!state.register.userInput?.tekniskUtstyr
                            ?.oppvarmingOlje?.kjel?.bruk &&
                          [
                            OppvarmingBruk.TappevannOgOppvarming,
                            OppvarmingBruk.Oppvarming,
                            OppvarmingBruk.Ukjent
                          ].includes(
                            state.register.userInput?.tekniskUtstyr
                              ?.oppvarmingOlje?.kjel?.bruk
                          )
                      }
                    : field
                )
              }
            ]
          }
        ]
      },
      {
        tLabelKey: 'register.tekniskUtstyr.oppvarmingVed.label',
        tHelperTextKey: 'register.tekniskUtstyr.oppvarmingVed.helperText',
        value: 'oppvarmingVed',
        fields: [
          {
            attrKey: 'installasjonsArApenPeis',
            selector: (state) =>
              state.register.userInput?.tekniskUtstyr?.oppvarmingVed
                ?.installasjonsArApenPeis,
            path: ['tekniskUtstyr', 'oppvarmingVed', 'installasjonsArApenPeis'],
            tLabelKey: 'register.tekniskUtstyr.installasjonsArApenPeis.label',
            tInformationKey:
              'register.tekniskUtstyr.installasjonsArApenPeis.information',
            type: Field.YEAR
          },
          {
            attrKey: 'installasjonsArPeisMedDor',
            selector: (state) =>
              state.register.userInput?.tekniskUtstyr?.oppvarmingVed
                ?.installasjonsArPeisMedDor,
            path: [
              'tekniskUtstyr',
              'oppvarmingVed',
              'installasjonsArPeisMedDor'
            ],
            tLabelKey: 'register.tekniskUtstyr.installasjonsArPeisMedDor.label',
            tInformationKey:
              'register.tekniskUtstyr.installasjonsArPeisMedDor.information',
            type: Field.YEAR
          },
          {
            attrKey: 'installasjonsArLukketOvn',
            selector: (state) =>
              state.register.userInput?.tekniskUtstyr?.oppvarmingVed
                ?.installasjonsArLukketOvn,
            path: [
              'tekniskUtstyr',
              'oppvarmingVed',
              'installasjonsArLukketOvn'
            ],
            tLabelKey: 'register.tekniskUtstyr.installasjonsArLukketOvn.label',
            tInformationKey:
              'register.tekniskUtstyr.installasjonsArLukketOvn.information',
            type: Field.YEAR
          }
        ]
      },
      {
        tLabelKey: 'register.tekniskUtstyr.oppvarmingGass.label',
        tHelperTextKey: 'register.tekniskUtstyr.oppvarmingGass.helperText',
        value: 'oppvarmingGass',
        fields: [
          {
            attrKey: '',
            selector: (state) =>
              getKeysWithValue(
                state.register.userInput?.tekniskUtstyr?.oppvarmingGass
              ),
            path: ['tekniskUtstyr', 'oppvarmingGass'],
            type: Field.CHECK,
            tLabelKey: '',
            options: [
              {
                defaultValueOnCheck: {
                  termostatstyring: Termostatstyring.Ukjent
                },
                fields: [
                  getNestedTermostatstyringField(
                    (state) =>
                      state.register.userInput?.tekniskUtstyr?.oppvarmingGass
                        ?.kamin,
                    ['tekniskUtstyr', 'oppvarmingGass', 'kamin']
                  )
                ],
                tLabelKey: 'register.tekniskUtstyr.gassKamin.label',
                value: 'kamin'
              },
              {
                defaultValueOnCheck: kjelOrKaminDefaultValueOnCheck,
                tLabelKey: 'register.tekniskUtstyr.gassKjel.label',
                value: 'kjel',
                fields: getKjelOrKaminFields(
                  (state) =>
                    state.register.userInput?.tekniskUtstyr?.oppvarmingGass
                      ?.kjel,
                  'kjel',
                  ['tekniskUtstyr', 'oppvarmingGass', 'kjel']
                ).map((field) =>
                  field.attrKey === 'tekniskUtstyr.fordeling'
                    ? {
                        ...field,
                        condition: (state) =>
                          !!state.register.userInput?.tekniskUtstyr
                            ?.oppvarmingGass?.kjel?.bruk &&
                          [
                            OppvarmingBruk.TappevannOgOppvarming,
                            OppvarmingBruk.Oppvarming,
                            OppvarmingBruk.Ukjent
                          ].includes(
                            state.register.userInput?.tekniskUtstyr
                              ?.oppvarmingGass?.kjel?.bruk
                          )
                      }
                    : field
                )
              }
            ]
          }
        ]
      }
    ],
    required: true,
    selector: (state) =>
      getKeysWithValue(state.register.userInput?.tekniskUtstyr),
    tDescriptionKey:
      // eslint-disable-next-line max-len
      'register.tekniskUtstyr.oppvarming.description',
    tLabelKey: '',
    type: Field.CHECK
  }
];
