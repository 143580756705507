import React, { FC, ReactNode } from 'react';
import { useMutation } from 'react-query';

import { updateBaseUser } from '../../services/api';
import { UserBase } from '../../types/user';
import { useAuthentication } from '../useAuthentication';

import { UserContext, UserContextValues } from './context';
import { useUserProfile } from './useUserProfile';

export const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuthentication();
  const { isLoading, isFetchedAfterMount, refetchUser, user } =
    useUserProfile(isAuthenticated);

  const { mutate, isLoading: isUpdateUserLoading } = useMutation(
    (updatedUser: UserBase) => updateBaseUser(updatedUser),
    {
      // Queries, such as useUserProfile, that have the 'enabled' flag wont refetch data when the query gets
      // invalidated. Therefore we need to manually refetch the user after updating for now.
      onSuccess: async () => await refetchUser()
    }
  );

  const values: UserContextValues = {
    user,
    isLoadingUserProfile: isLoading,
    isFetchedAfterMount,
    // Check that the useUserProfile hook has tried to fetch a user profile
    // and that we have successfully retrieved a user with a userId
    hasUserProfile:
      isFetchedAfterMount && !isLoading && isAuthenticated && !!user?.userId,
    updateUser: mutate,
    isUpdateUserLoading
  };

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};
