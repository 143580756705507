import React, { ReactNode } from 'react';
import { Paper, styled } from 'enova-frontend-components';

export type TableContent = { content?: ReactNode; key: string };

export type TableRow = { data: TableContent[]; key: string };

export type TableType = {
  head: string[];
  rows: TableRow[];
};

const StyledPaper = styled(Paper)`
  overflow-x: auto;
`;

export const StyledTable = styled((props) => (
  <StyledPaper>
    <table {...props} />
  </StyledPaper>
))`
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    width: 25% !important;
    padding: ${({ theme }) => theme.spacing(2)};
  }

  thead {
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) =>
      theme.palette.getContrastText(theme.palette.primary.main)};
    font-weight: ${({ theme }) => theme.typography.h4.fontWeight};
  }

  tbody tr {
    th {
      font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
      text-align: left;
    }

    th,
    td:not(:last-child) {
      border-right: thin solid ${({ theme }) => theme.palette.divider};
    }

    &:not(:last-child) td,
    &:not(:last-child) th {
      border-bottom: thin solid ${({ theme }) => theme.palette.divider};
    }
  }
`;
