import Joi from 'joi';

import { SUM_OPPVARMING_ERROR, SUM_TAPPEVANN_ERROR } from './schema';

interface StateError {
  type: string | undefined;
  message: string | undefined;
  limit?: number | undefined;
}

// The purpose of this function is to simplify and restructure the original errors object from Joi into a more
// specific format, allowing for easier handling and processing of the error information in the errorMessage component.
export const reduceErrors = (
  errors: Record<string, Joi.ValidationErrorItem>
) => {
  // The result object will be a Record<string, StateError> where the key is the name of the input field.
  // You can also add customErrorCodes if you want error messages that are not directly related to a
  // specific input field.
  const result: Record<string, StateError> = {};

  for (const [key, error] of Object.entries(errors)) {
    const type = error.type;
    const message = error.message;
    const customErrorCode = error.context?.error?.message;

    // Check if this is not a custom error. If so just add it to the result object
    // and continue to the next error.
    if (!customErrorCode) {
      result[key] = {
        type,
        message,
        limit: error.context?.limit
      };

      continue;
    }

    if (
      customErrorCode.includes(SUM_OPPVARMING_ERROR) ||
      customErrorCode.includes(SUM_TAPPEVANN_ERROR)
    ) {
      if (result[customErrorCode]) {
        continue;
      }

      // Custom errors are formatted as "code:limit" to be able to add limit value to the error message.
      // See function sumAndelOppvarming and sumAndelTappevann in schema.ts for more information.
      const [code, limit] = customErrorCode.split(':');

      result[code] = {
        type,
        message,
        limit
      };
    }
  }

  return result;
};
