import React, { Fragment } from 'react';
import { Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

export const CertificateWithReservation = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Typography gutterBottom variant="h2">
        {t('buildingScreen.tab.energiattest.certificateWithReservation.title')}
      </Typography>

      <Typography>
        {t(
          'buildingScreen.tab.energiattest.certificateWithReservation.content'
        )}
      </Typography>
    </Fragment>
  );
};
