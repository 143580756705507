import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import { fylkerDictionary, kommuneDictionary } from '../../utils/kommuner';

import StatisticsFilterContext, {
  CategoryOptions,
  FilterCategory,
  FilterSubcategory,
  FilterValues,
  StatisticsFilterContextValues,
  SubcategoryFilterValues,
  SubcategoryOptions
} from './context';

type FilterProviderProps = {
  categories?: FilterCategory[];
  subcategories?: FilterSubcategory[];
  children: ReactNode;
};

type SearchValues = Partial<FilterValues> & Partial<SubcategoryFilterValues>;

const StatisticsFilterProvider: FC<FilterProviderProps> = ({
  children,
  categories,
  subcategories
}) => {
  const [body, setBody] = useState('');

  const searchValues = useMemo<SearchValues>(() => {
    let sv: SearchValues = JSON.parse(body || '{}');

    if (body) {
      Object.values(FilterCategory).forEach((fc) => {
        const val = sv[fc];

        if (val)
          if (Array.isArray(val))
            sv = {
              ...sv,
              [fc]: val?.filter((fv) => CategoryOptions[fc].includes(fv))
            };
          else if (typeof val === 'string' && CategoryOptions[fc].includes(val))
            sv = {
              ...sv,
              [fc]: [val]
            };
          else delete sv[fc];
      });
    }
    return sv;
  }, [body]);

  const [filterValues, setFilterValues] = useState<FilterValues>({
    [FilterCategory.CONSTRUCTION_CODE]:
      searchValues[FilterCategory.CONSTRUCTION_CODE] || [],
    [FilterCategory.BUILDING_CATEGORY]:
      searchValues[FilterCategory.BUILDING_CATEGORY] || [],
    [FilterCategory.FYLKER]: searchValues[FilterCategory.FYLKER] || []
  });

  const [subcategoryFilterValues, setSubcategoryFilterValues] =
    useState<SubcategoryFilterValues>({
      [FilterSubcategory.KOMMUNER]:
        searchValues[FilterSubcategory.KOMMUNER] || []
    });

  const updateFilterValues = (
    category: FilterCategory,
    option: string,
    checked: boolean,
    subcategory?: FilterSubcategory
  ) => {
    setFilterValues((prev) => ({
      ...prev,
      [category]: checked
        ? [...prev[category], option]
        : prev[category].filter((val) => val !== option)
    }));

    // remove kommune filters when fylke unchecked
    if (
      !checked &&
      category === FilterCategory.FYLKER &&
      subcategory &&
      subcategoryFilterValues[subcategory]?.length > 0
    ) {
      const selectedKommuner = subcategoryFilterValues[subcategory];
      const kommuner = fylkerDictionary[option];

      if (kommuner) {
        const kommuneValuesAfter = selectedKommuner.filter(
          (kommune) => !kommuner.includes(kommune)
        );
        setSubcategoryFilterValues((prev) => ({
          ...prev,
          [subcategory]: kommuneValuesAfter
        }));
      }
    }
  };

  const updateSubcategoryFilterValues = (
    subcategory: FilterSubcategory,
    subcategoryOption: string,
    checked: boolean
  ) => {
    setSubcategoryFilterValues((prev) => ({
      ...prev,
      [subcategory]: checked
        ? [...prev[subcategory], subcategoryOption]
        : prev[subcategory].filter(
            (val) => !kommuneDictionary[subcategoryOption].includes(val)
          )
    }));
  };

  const [mode, setMode] = useState<FilterCategory>();

  const generateRequestBody = () => {
    const tmpFilterValues = Object.entries(filterValues).reduce(
      (acc, [key, value]) =>
        value.length === 0 ? acc : { ...acc, [key]: value },
      {}
    );

    const additionalKommuner = expandKommuner();

    const tmpSubcategoryFilterValues = Object.entries(
      subcategoryFilterValues
    ).reduce((acc, [key, value]) => {
      return value.length === 0 && !additionalKommuner
        ? acc
        : {
            ...acc,
            [key]: additionalKommuner
              ? [...new Set([...value, ...additionalKommuner])]
              : value
          };
    }, {});

    const queryObj = { ...tmpFilterValues, ...tmpSubcategoryFilterValues };

    return JSON.stringify(queryObj);
  };

  /**
   * If subcategoryFilter contains kommuner use kommuneDictionary to include historical.
   * If only fylke and no kommuner in subcategoryFilter, include all kommuner (with historical)
   */
  const expandKommuner = (): string[] => {
    if (filterValues.geography?.length > 0) {
      return filterValues.geography.flatMap((fylke) => {
        const containsFylkeKommuner = subcategoryFilterValues.kommune
          ? subcategoryFilterValues.kommune.filter((k) =>
              fylkerDictionary[fylke].includes(k)
            )
          : [];
        return containsFylkeKommuner.length > 0
          ? containsFylkeKommuner.flatMap(
              (kommunenummer) => kommuneDictionary[kommunenummer]
            )
          : fylkerDictionary[fylke];
      });
    }
    return [];
  };

  useEffect(() => {
    setBody(generateRequestBody());
  }, [filterValues, subcategoryFilterValues]);

  const clearAll = () => {
    setFilterValues({
      [FilterCategory.CONSTRUCTION_CODE]: [],
      [FilterCategory.BUILDING_CATEGORY]: [],
      [FilterCategory.FYLKER]: []
    });
    setSubcategoryFilterValues({
      [FilterSubcategory.KOMMUNER]: []
    });
  };

  const clearFilterCategory = (category: FilterCategory) => {
    setFilterValues((prev) => ({
      ...prev,
      [category]: []
    }));

    const subcategory = SubcategoryOptions[category];

    if (subcategory)
      setSubcategoryFilterValues((prev) => ({
        ...prev,
        [subcategory]: []
      }));
  };

  const values: StatisticsFilterContextValues = {
    mode,
    categories,
    subcategories,
    setMode,
    clearAll,
    clearFilterCategory,
    filterValues,
    subcategoryFilterValues,
    updateFilterValues,
    updateSubcategoryFilterValues,
    body
  };

  return (
    <StatisticsFilterContext.Provider value={values}>
      {children}
    </StatisticsFilterContext.Provider>
  );
};

export default StatisticsFilterProvider;
