import React, { FC, Fragment } from 'react';
import { Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { SoknadSteg } from '../../../../../../../components/registering/utils/registerEnums';
import useBuildingDetails from '../../../../../../../hooks/useBuildingDetails';
import { LoadingIndicator } from '../../../../../../../components/loadingIndicator';
import { ErrorBox } from '../../../../../../../components/errorBox';

import { SupportCriteriaTable } from './supportCriteriaTable';
import { useGetEnovaSupportLevels } from './useGetEnovaSupportLevels';
import SoknadTable from './soknadTable';
import { QualificationSummary } from './qualificationSummary';

export interface ApplicationSummaryProps {
  soknadSteg: SoknadSteg;
  soknadId: string;
}

const EnovaApplicationQualifications: FC<ApplicationSummaryProps> = ({
  soknadSteg,
  soknadId
}) => {
  const { t } = useTranslation();
  const { building, address, soknadStegMap } = useBuildingDetails();
  const { enovaSupportLevels, isLoading, isError, refetchSupportLevels } =
    useGetEnovaSupportLevels(soknadSteg, soknadId);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <ErrorBox onRetry={refetchSupportLevels} />;
  }

  if (!soknadStegMap || !soknadSteg) {
    return null;
  }

  const { bygningsNummer, gardsNummer } = building;
  const godkjentNiva = enovaSupportLevels?.godkjentNiva;
  const enovaStotteKriteria = enovaSupportLevels?.enovaStotteKriteria;

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="h4" component="h3" gutterBottom>
          {t('register.summary.tilstandsrapport.heading', {
            address,
            bygningsNummer,
            gardsNummer
          })}
        </Typography>

        <Typography bold>
          {t('register.summary.tilstandsrapport.subheading')}
        </Typography>

        <Typography>
          {t('register.summary.tilstandsrapport.description')}
        </Typography>
      </Grid>

      <Grid item xs={12} maxWidth={1} mb={4}>
        <SoknadTable soknadStegMap={soknadStegMap} />
      </Grid>

      <QualificationSummary
        soknadSteg={soknadSteg}
        godkjentNiva={godkjentNiva}
      />

      <Grid item mt={2} xs={12}>
        <Typography variant="h4">
          {t('register.summary.tilstandsrapport.criteria')}
        </Typography>
      </Grid>

      <Grid item maxWidth={1} xs={12}>
        <SupportCriteriaTable criteriaLevels={enovaStotteKriteria} />
      </Grid>
    </Fragment>
  );
};

export default EnovaApplicationQualifications;
