import { CancelToken } from 'apisauce';
import { useQuery } from 'react-query';

import { getBuilding, getSimpleEnergyAnalyses } from '../../services/api';
import { queryKeys } from '../../utils/react-query';

export const useBuildingData = (energiobjektId: string) => {
  const { error, isLoading, data } = useQuery(
    [queryKeys.building, energiobjektId],
    ({ signal }) => {
      const source = CancelToken.source();

      const request = getBuilding(energiobjektId, source.token);

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by React Query');
      });

      return request;
    }
  );

  return {
    buildingError: error,
    buildingLoading: isLoading,
    building: data
  };
};

export const useBuildingEnergyAnalyses = (buildingId: string) => {
  const { data, error, isLoading, refetch } = useQuery(
    [queryKeys.energyAnalysisList, buildingId],
    () => getSimpleEnergyAnalyses(buildingId)
  );

  return {
    simpleEnergyAnalyses: data ?? [],
    simpleEnergyAnalysesError: error,
    simpleEnergyAnalysesLoading: isLoading,
    refreshSimpleEnergyAnalyses: refetch
  };
};
