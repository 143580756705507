import React, { FC, HTMLAttributes } from 'react';
import { Box } from 'enova-frontend-components';

import RegFieldCollapse from './regFieldCollapse';
import CollapsibleProvider from './useCollapsible/provider';

type InnerCollapsibleRegFieldProps = HTMLAttributes<HTMLDivElement> & {
  open: boolean;
};

type InnerCollapsibleRegFieldSubTypes = {
  Collapse: typeof RegFieldCollapse;
};

const InnerCollapsibleRegField: FC<InnerCollapsibleRegFieldProps> &
  InnerCollapsibleRegFieldSubTypes = ({ children, open, ...rest }) => (
  <CollapsibleProvider open={open}>
    <Box maxWidth={1} {...rest}>
      {children}
    </Box>
  </CollapsibleProvider>
);

InnerCollapsibleRegField.Collapse = RegFieldCollapse;

export default InnerCollapsibleRegField;
