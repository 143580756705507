import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'enova-frontend-components';

interface CheckBoxLabelProps {
  fieldKey: string;
}

export const CheckBoxLabel: FC<CheckBoxLabelProps> = ({ fieldKey }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography style={{ fontWeight: 600 }}>
        {t(`profileScreen.professional.areas.label.${fieldKey}`)}
      </Typography>
      <Typography variant="ingress">
        {t(`profileScreen.professional.areas.description.${fieldKey}`)}
      </Typography>
    </Box>
  );
};
