import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getTiltaksKategorier } from '../../../services/api';
import { EnergiObjektType } from '../../../utils/matrikkelBygningsTyper';
import { queryKeys } from '../../../utils/react-query';
import { createErrorMessage } from '../../../utils/utils';

export const useGetTiltakskategorier = (energiobjekttype: EnergiObjektType) => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(
    [queryKeys.measureCategories, energiobjekttype],
    () => getTiltaksKategorier(energiobjekttype),
    {
      staleTime: 24 * 3600 * 1000,
      cacheTime: Infinity,
      onError: () => {
        addSnack(createErrorMessage(t('tiltak.categories.error')), {
          variant: 'error'
        });
      }
    }
  );

  return {
    tiltakskategorier: data,
    isLoading
  };
};
