import { Button } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Address } from '../../../../../../../types/building';
import { useGetBuildingAddress } from '../../../../../../../hooks/useGetBuildingAddress';
import { mailto } from '../../../../../../../utils/utils';

interface Props {
  address: Address;
}

export const ShareButton: FC<Props> = ({ address }) => {
  const { getBuildingAddress } = useGetBuildingAddress();
  const { t } = useTranslation();

  const addressString = getBuildingAddress(address);

  return (
    <Button
      fullWidth
      leftIcon="share"
      variant="secondary"
      onClick={() => mailto(addressString)}
    >
      {t('shareEnergyCertificate')}
    </Button>
  );
};
