import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'enova-frontend-components';

import { Etasje } from '../../../../../../../types/registration/userInput';
import { useCalculateFloorArea } from '../../../hooks/useCalculateFloorArea';
import { BygningsForm } from '../../../../../utils/registerEnums';
import { useFloorTitle } from '../../../../../utils/useFloorTitle';

import { FloorAreaWrapper } from './floorAreaWrapper';

interface FloorAreaProps {
  floor: Etasje;
  isCurrentFloor: boolean;
  isFirstElement: boolean;
}

export const FloorArea: FC<FloorAreaProps> = ({
  floor,
  isCurrentFloor,
  isFirstElement
}) => {
  const { t } = useTranslation();
  const { area } = useCalculateFloorArea(
    floor?.bygningsForm ?? BygningsForm.Unknown,
    floor?.vegger ?? [],
    floor.id
  );
  const { getFloorTitle } = useFloorTitle();
  const floorTitle = getFloorTitle(floor.etasjeNummer, floor.erKjeller);

  return (
    <FloorAreaWrapper
      isCurrentFloor={isCurrentFloor}
      isFirstElement={isFirstElement}
    >
      <Typography>
        <strong>{floorTitle}</strong>
      </Typography>
      <Typography>
        {area} {t('suffix.m2')}
      </Typography>
    </FloorAreaWrapper>
  );
};
