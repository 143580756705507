import { Malform } from '../../components/registering/utils/registerEnums';

import {
  AutomatikkanleggTiltak,
  Brukertiltak,
  BygningsmessigeTiltak,
  EgendefinertTiltak,
  ElektriskeAnleggTiltak,
  GenerelleTiltak,
  LuftbehandlingsanleggTiltak,
  OvrigeTiltak,
  SanitaeranleggTiltak,
  TiltakType,
  UtendorsTiltak,
  VarmeanleggTiltak
} from './tiltak';

export const TiltakNameMap: Record<TiltakType, Record<Malform, string>> = {
  // Brukertiltak
  [Brukertiltak.FolgMedPaEnergiBrukenIBoligen]: {
    [Malform.Bokmal]: 'Følg med på energibruken i boligen',
    [Malform.Nynorsk]: 'Følg med på energibruken i bustaden'
  },
  [Brukertiltak.LuftKortOgEffektivt]: {
    [Malform.Bokmal]: 'Luft kort og effektivt',
    [Malform.Nynorsk]: 'Luft kort og effektivt'
  },
  [Brukertiltak.ReduserInneTemperaturen]: {
    [Malform.Bokmal]: 'Redusér innetemperaturen',
    [Malform.Nynorsk]: 'Reduser innetemperaturen'
  },
  [Brukertiltak.BrukVarmtVannFornuftig]: {
    [Malform.Bokmal]: 'Bruk varmtvann fornuftig',
    [Malform.Nynorsk]: 'Bruk varmvatn fornuftig'
  },
  [Brukertiltak.SlaAvLysetOgBrukSpareParer]: {
    [Malform.Bokmal]: 'Slå av lyset og bruk sparepærer',
    [Malform.Nynorsk]: 'Slå av lyset og bruk sparepærer'
  },
  [Brukertiltak.VaskMedFulleMaskiner]: {
    [Malform.Bokmal]: 'Vask med fulle maskiner',
    [Malform.Nynorsk]: 'Vask med fulle maskiner'
  },
  [Brukertiltak.SlaElApparaterHeltAv]: {
    [Malform.Bokmal]: 'Slå elektriske apparater helt av',
    [Malform.Nynorsk]: 'Slå elektriske apparat heilt av'
  },
  [Brukertiltak.SparStromPaKjokkenet]: {
    [Malform.Bokmal]: 'Spar strøm på kjøkkenet',
    [Malform.Nynorsk]: 'Spar straum på kjøkkenet'
  },
  [Brukertiltak.VelgHvitevarerMedLavtForbruk]: {
    [Malform.Bokmal]: 'Velg hvitevarer med lavt forbruk',
    [Malform.Nynorsk]: 'Vel kvitevarer med lågt forbruk'
  },
  [Brukertiltak.FyrRiktigMedVed]: {
    [Malform.Bokmal]: 'Fyr riktig med ved',
    [Malform.Nynorsk]: 'Fyr riktig med ved'
  },
  [Brukertiltak.TiltakUtendors]: {
    [Malform.Bokmal]: 'Tiltak utendørs',
    [Malform.Nynorsk]: 'Tiltak utandørs'
  },

  // BygningsmessigeTiltak
  [BygningsmessigeTiltak.MontereTetningslister]: {
    [Malform.Bokmal]: 'Montering av tetningslister',
    [Malform.Nynorsk]: 'Montering av tettingslister'
  },
  [BygningsmessigeTiltak.TettingAvLuftlekasjoner]: {
    [Malform.Bokmal]: 'Tetting av luftlekkasjer',
    [Malform.Nynorsk]: 'Tetting av luftlekkasjar'
  },
  [BygningsmessigeTiltak.EtterisoleringAvKaldtLoft]: {
    [Malform.Bokmal]: 'Etterisolering av kaldt loft',
    [Malform.Nynorsk]: 'Etterisolering av kaldt loft'
  },
  [BygningsmessigeTiltak.EtterisoleringAvYttertakLoft]: {
    [Malform.Bokmal]: 'Etterisolering av yttertak/loft',
    [Malform.Nynorsk]: 'Etterisolering av yttertak/loft'
  },
  [BygningsmessigeTiltak.IsoleringAvGulvMotKaldKjellerKryprom]: {
    [Malform.Bokmal]: 'Isolering av gulv mot kald kjeller/kryprom',
    [Malform.Nynorsk]: 'Isolering av golv mot kald kjellar/kryprom'
  },
  [BygningsmessigeTiltak.IsoleringAvGulvMotGrunn]: {
    [Malform.Bokmal]: 'Isolering av gulv mot grunn',
    [Malform.Nynorsk]: 'Isolering av golv mot grunn'
  },
  [BygningsmessigeTiltak.IsoleringAvGulvMotDetFri]: {
    [Malform.Bokmal]: 'Isolering av gulv mot det fri',
    [Malform.Nynorsk]: 'Isolering av golv mot friluft'
  },
  [BygningsmessigeTiltak.RandsoneIsoleringAvEtasjeSkillere]: {
    [Malform.Bokmal]: 'Randsoneisolering av etasjeskillere',
    [Malform.Nynorsk]: 'Randsoneisolering av etasjeskiljarar'
  },
  [BygningsmessigeTiltak.EtterisoleringAvYttervegg]: {
    [Malform.Bokmal]: 'Etterisolering av yttervegg',
    [Malform.Nynorsk]: 'Etterisolering av yttervegg'
  },
  [BygningsmessigeTiltak.EtterisoleringAvKjellervegg]: {
    [Malform.Bokmal]: 'Etterisolering av kjellervegg',
    [Malform.Nynorsk]: 'Etterisolering av kjellarvegg'
  },
  [BygningsmessigeTiltak.IsoleringAvInnerveggMotUoppvarmetRom]: {
    [Malform.Bokmal]: 'Isolering av innervegg mot uoppvarmet rom',
    [Malform.Nynorsk]: 'Isolering av innervegg mot uoppvarma rom'
  },
  [BygningsmessigeTiltak.IsolereLoftsluke]: {
    [Malform.Bokmal]: 'Isolere loftsluke',
    [Malform.Nynorsk]: 'Isolering av loftsluke'
  },
  [BygningsmessigeTiltak.UtskiftingAvVindu]: {
    [Malform.Bokmal]: 'Utskifting av vindu',
    [Malform.Nynorsk]: 'Utskifting av vindauge'
  },
  [BygningsmessigeTiltak.UtskiftingAvYtterdor]: {
    [Malform.Bokmal]: 'Utskifting av ytterdør',
    [Malform.Nynorsk]: 'Utskifting av ytterdør'
  },
  [BygningsmessigeTiltak.TermograferingOgTetthetsProving]: {
    [Malform.Bokmal]: 'Termografering og tetthetsprøving',
    [Malform.Nynorsk]: 'Termografering og tettleiksprøving'
  },

  // ElektriskeAnleggTiltak
  [ElektriskeAnleggTiltak.SkifteTilLavEnergiParer]: {
    [Malform.Bokmal]: 'Skifte til lavenergipærer',
    [Malform.Nynorsk]: 'Skifte til lågenergipærer'
  },
  [ElektriskeAnleggTiltak.TemperaturOgTidsStyringAvElektriskGulvVarmeTakVarme]:
    {
      [Malform.Bokmal]:
        'Temperatur- og tidsstyring av elektrisk gulvvarme/takvarme',
      [Malform.Nynorsk]:
        'Temperatur- og tidsstyring av elektrisk golvvarme/takvarme'
    },
  [ElektriskeAnleggTiltak.TemperaturOgTidsStyringAvElektriskLuftVarme]: {
    [Malform.Bokmal]: 'Temperatur- og tidsstyring av elektrisk luftvarme',
    [Malform.Nynorsk]: 'Temperatur- og tidsstyring av elektrisk luftvarme'
  },
  [ElektriskeAnleggTiltak.TemperaturOgTidsStyringAvPanelOvner]: {
    [Malform.Bokmal]: 'Temperatur- og tidsstyring av panelovner',
    [Malform.Nynorsk]: 'Temperatur- og tidsstyring av panelomnar'
  },
  [ElektriskeAnleggTiltak.TidsstyringAvElektriskGulvVarmeTakVarme]: {
    [Malform.Bokmal]: 'Tidsstyring av elektrisk gulvvarme/takvarme',
    [Malform.Nynorsk]: 'Tidsstyring av elektrisk golvvarme/takvarme'
  },
  [ElektriskeAnleggTiltak.TidsstyringAvElektriskLuftVarme]: {
    [Malform.Bokmal]: 'Tidsstyring av elektrisk luftvarme',
    [Malform.Nynorsk]: 'Tidsstyring av elektrisk luftvarme'
  },
  [ElektriskeAnleggTiltak.TidsstyringAvPanelOvner]: {
    [Malform.Bokmal]: 'Tidsstyring av panelovner',
    [Malform.Nynorsk]: 'Tidsstyring av panelomnar'
  },

  // LuftbehandlingsanleggTiltak
  [LuftbehandlingsanleggTiltak.InstallereVarmeGjenvinnerIVentilasjonsAnlegg]: {
    [Malform.Bokmal]: 'Installere varmegjenvinner i ventilasjonsanlegg',
    [Malform.Nynorsk]: 'Installering av varmeattvinnar i ventilasjonsanlegg'
  },
  [LuftbehandlingsanleggTiltak.InstallereVentilasjonsAnleggMedVarmeGjenvinnerTilErstatningForMekaniskVentilasjon]:
    {
      [Malform.Bokmal]:
        'Installere ventilasjonsanlegg med varmegjenvinner til erstatning for mekanisk ventilasjon',
      [Malform.Nynorsk]:
        'Installering av ventilasjonsanlegg med varmeattvinnar til erstatning for mekanisk ventilasjon'
    },
  [LuftbehandlingsanleggTiltak.InstallereVentilasjonsAnleggMedVarmeGjenvinnerTilErstatningForNaturligVentilasjon]:
    {
      [Malform.Bokmal]:
        'Installere ventilasjonsanlegg med varmegjenvinner til erstatning for naturlig ventilasjon',
      [Malform.Nynorsk]:
        'Installering av ventilasjonsanlegg med varmeattvinnar til erstatning for naturleg ventilasjon'
    },
  [LuftbehandlingsanleggTiltak.MontereBlafreSpjeldPaKjokkenVentilator]: {
    [Malform.Bokmal]: 'Montere blafrespjeld på kjøkkenventilator',
    [Malform.Nynorsk]: 'Montering av blafrespjeld på kjøkkenventilator'
  },
  [LuftbehandlingsanleggTiltak.MontereUrStyringPaAvtrekksVifterVentilasjonsAnlegg]:
    {
      [Malform.Bokmal]:
        'Montere urstyring på avtrekksvifter/ventilasjonsanlegg',
      [Malform.Nynorsk]:
        'Montering av urstyring på avtrekksvifter/ventilasjonsanlegg'
    },
  [LuftbehandlingsanleggTiltak.SkifteAvtrekksViftePaBadTilNyMedFuktStyring]: {
    [Malform.Bokmal]: 'Skifte avtrekksvifte på bad til ny med fuktstyring',
    [Malform.Nynorsk]:
      'Utskifting av avtrekksvifte på bad med ny vifte med fuktstyring'
  },
  [LuftbehandlingsanleggTiltak.UtforeServicePaVentilasjonsAnlegg]: {
    [Malform.Bokmal]: 'Utføre service på ventilasjonsanlegg',
    [Malform.Nynorsk]: 'Utføring av service på ventilasjonsanlegg'
  },

  // SanitaeranleggTiltak
  [SanitaeranleggTiltak.IsolereVarmtvannsror]: {
    [Malform.Bokmal]: 'Isolere varmtvannsrør',
    [Malform.Nynorsk]: 'Isolering av varmtvassrør'
  },
  [SanitaeranleggTiltak.SkifteTilSparedusj]: {
    [Malform.Bokmal]: 'Skifte til sparedusj',
    [Malform.Nynorsk]: 'Skifte til sparedusj'
  },

  // UtendorsTiltak
  [UtendorsTiltak.MontereAutomatikkPaUtebelysning]: {
    [Malform.Bokmal]: 'Montere automatikk på utebelysning',
    [Malform.Nynorsk]: 'Montering av automatikk på utelys'
  },
  [UtendorsTiltak.MontereUrbryterPaMotorVarmer]: {
    [Malform.Bokmal]: 'Montere urbryter på motorvarmer',
    [Malform.Nynorsk]: 'Montering av urbrytar på motorvarmar'
  },
  [UtendorsTiltak.SkifteTilSpareParerPaUtebelysning]: {
    [Malform.Bokmal]: 'Skifte til sparepærer på utebelysning',
    [Malform.Nynorsk]: 'Skifte til sparepærer på utelys'
  },
  [UtendorsTiltak.TermostatOgNedborsStyringAvSnoSmelteAnlegg]: {
    [Malform.Bokmal]: 'Termostat- og nedbørsstyring av snøsmelteanlegg',
    [Malform.Nynorsk]: 'Termostat- og nedbørsstyring av snøsmelteanlegg'
  },

  // VarmeanleggTiltak
  [VarmeanleggTiltak.UtskiftingTilTermostatStyrteRadiatorVentiler]: {
    [Malform.Bokmal]: 'Utskifting til termostatstyrte radiatorventiler',
    [Malform.Nynorsk]: 'Utskifting til termostatstyrte radiatorventilar'
  },
  [VarmeanleggTiltak.AutomatikkForShuntUteTemperaturReguleringOgNattSenkning]: {
    [Malform.Bokmal]:
      'Automatikk for shunt- eller utetemperaturregulering og nattsenkning',
    [Malform.Nynorsk]:
      'Automatikk for shunt- eller utetemperaturregulering og nattesenking'
  },
  [VarmeanleggTiltak.IndividuellVarmeMalingIFlerBoligHus]: {
    [Malform.Bokmal]: 'Individuell varmemåling i flerbolighus',
    [Malform.Nynorsk]: 'Individuell varmemåling i fleirbustadhus'
  },
  [VarmeanleggTiltak.InstallereLuftLuftVarmePumpe]: {
    [Malform.Bokmal]: 'Installere luft/luft-varmepumpe',
    [Malform.Nynorsk]: 'Installering av luft/luft-varmepumpe'
  },
  [VarmeanleggTiltak.InstallereNyOljeParafinKaminAlternativtPelletsKaminAlternativtGassPeisGassOvn]:
    {
      [Malform.Bokmal]:
        'Installere ny olje/parafinkamin alternativt pelletskamin alternativt gasspeis/gassovn',
      [Malform.Nynorsk]:
        'Installering av ny olje- eller parafinkamin, alternativt pelletkamin, alternativt gasspeis/gassomn'
    },
  [VarmeanleggTiltak.InstallereNyRentBrennendeVedOvnPeisInnsatsAlternativtPelletsKamin]:
    {
      [Malform.Bokmal]:
        'Installere ny rentbrennende vedovn / peisinnsats, alternativt pelletskamin',
      [Malform.Nynorsk]:
        'Installering av ny, reintbrennande vedomn/peisinnsats, alternativt pelletkamin'
    },
  [VarmeanleggTiltak.InstallereSolVarmeAnlegg]: {
    [Malform.Bokmal]: 'Installere solvarmeanlegg',
    [Malform.Nynorsk]: 'Installering av solvarmeanlegg'
  },
  [VarmeanleggTiltak.InstallereVarmePumpeFraBergJordVannEllerUteLuftTilVannbarentSystem]:
    {
      [Malform.Bokmal]:
        'Installere varmepumpe fra berg, jord, vann eller uteluft til vannbårent system',
      [Malform.Nynorsk]:
        'Installera varmepumpe frå berg, jord, vatn eller uteluft til vassbore system'
    },
  [VarmeanleggTiltak.IsoleringAvVarmeRorVentilerPumper]: {
    [Malform.Bokmal]: 'Isolering av varmerør, ventiler, pumper',
    [Malform.Nynorsk]: 'Isolering av varmerør, ventilar, pumper'
  },
  [VarmeanleggTiltak.MonteringAvPeisInnsatsIApenPeis]: {
    [Malform.Bokmal]: 'Montering av peisinnsats i åpen peis',
    [Malform.Nynorsk]: 'Montering av peisinnsats i open peis'
  },
  [VarmeanleggTiltak.MonteringAvTermostatStyringPaGulvVarme]: {
    [Malform.Bokmal]: 'Montering av termostatstyring på gulvvarme',
    [Malform.Nynorsk]: 'Montering av termostatstyring på golvvarme'
  },
  [VarmeanleggTiltak.NattSenkningPaAutomatikkForShuntUteTemperaturRegulering]: {
    [Malform.Bokmal]:
      'Nattsenkning på automatikk for shunt- eller utetemperaturregulering',
    [Malform.Nynorsk]:
      'Nattesenking på automatikk for shunt- eller utetemperaturregulering'
  },
  [VarmeanleggTiltak.ServicePaKjelAnleggSamtEvtMonteringAvRoykGassTermometer]: {
    [Malform.Bokmal]:
      'Service på kjelanlegg samt evt. montering av røykgasstermometer',
    [Malform.Nynorsk]:
      'Service på kjelanlegg og eventuell montering av røykgasstermometer'
  },
  [VarmeanleggTiltak.UtskiftingAvBrennerPaKjelanlegg]: {
    [Malform.Bokmal]: 'Utskifting av brenner på kjelanlegg',
    [Malform.Nynorsk]: 'Utskifting av brennar på kjelanlegg'
  },
  [VarmeanleggTiltak.UtskiftingAvOljekjelTilBiokjel]: {
    [Malform.Bokmal]: 'Utskifting av oljekjel til biokjel',
    [Malform.Nynorsk]: 'Utskifting av oljekjel med biokjel'
  },

  //

  [GenerelleTiltak.Energioppfolgingssystem]: {
    [Malform.Bokmal]: 'Energioppfølgingssystem (EOS)',
    [Malform.Nynorsk]: 'Energioppfølgingssystem (EOS)'
  },
  [GenerelleTiltak.DVInstruks]: {
    [Malform.Bokmal]: 'DV-instruks',
    [Malform.Nynorsk]: 'DV- instruks'
  },
  [GenerelleTiltak.IndividuellEnergimaling]: {
    [Malform.Bokmal]: 'Individuell energimåling',
    [Malform.Nynorsk]: 'Individuell energimåling'
  },

  [BygningsmessigeTiltak.IsoleringAvYttervegg]: {
    [Malform.Bokmal]: 'Isolering av yttervegg',
    [Malform.Nynorsk]: 'Isolering av yttervegg'
  },
  [BygningsmessigeTiltak.IsoleringAvInnervegg]: {
    [Malform.Bokmal]: 'Isolering av innervegg mot kalde rom',
    [Malform.Nynorsk]: 'Isolering av innervegg mot kalde rom'
  },
  [BygningsmessigeTiltak.IsoleringAvGulv]: {
    [Malform.Bokmal]: 'Isolering av gulv',
    [Malform.Nynorsk]: 'Isolering av golv'
  },
  [BygningsmessigeTiltak.IsoleringAvRandsoneGulv]: {
    [Malform.Bokmal]: 'Isolering av randsone gulv',
    [Malform.Nynorsk]: 'Isolering av randsone golv'
  },
  [BygningsmessigeTiltak.IsoleringAvYttertak]: {
    [Malform.Bokmal]: 'Isolering av yttertak/mot kaldt loft',
    [Malform.Nynorsk]: 'Isolering av yttertak eller mot kaldt loft'
  },
  [BygningsmessigeTiltak.TettingOvergangGulvVegg]: {
    [Malform.Bokmal]: 'Tetting overgang gulv/vegg eller tak/vegg',
    [Malform.Nynorsk]: 'Tetting av overgang golv−vegg eller tak−vegg'
  },
  [BygningsmessigeTiltak.ElimineringKuldebro]: {
    [Malform.Bokmal]: 'Eliminering av kuldebro',
    [Malform.Nynorsk]: 'Eliminering av kuldebru'
  },
  [BygningsmessigeTiltak.TettelistVinduer]: {
    [Malform.Bokmal]: 'Nye tettelister rundt vinduer',
    [Malform.Nynorsk]: 'Nye tettingslister rundt vindauge'
  },
  [BygningsmessigeTiltak.TettelistYtterdorer]: {
    [Malform.Bokmal]: 'Nye tettelister rundt ytterdører / balkongdører',
    [Malform.Nynorsk]: 'Nye tettingslister rundt ytterdører/balkongdører'
  },
  [BygningsmessigeTiltak.TettingOvergangKarmVegg]: {
    [Malform.Bokmal]: 'Tetting overgang karm/vegg for vinduer/dører',
    [Malform.Nynorsk]: 'Tetting av overgang karm−vegg for vindauge/dører'
  },
  [BygningsmessigeTiltak.UtskiftingDorVinduPort]: {
    [Malform.Bokmal]: 'Utskifting av vinduer/dører/porter',
    [Malform.Nynorsk]: 'Utskifting av vindauge/dører/portar'
  },
  [BygningsmessigeTiltak.UtebdringPort]: {
    [Malform.Bokmal]: 'Utbedring av porter',
    [Malform.Nynorsk]: 'Utbetring av portar'
  },
  [BygningsmessigeTiltak.AutomatiskStengingPort]: {
    [Malform.Bokmal]: 'Automatisk stenging av porter',
    [Malform.Nynorsk]: 'Automatisk stenging av portar'
  },
  [BygningsmessigeTiltak.Termografering]: {
    [Malform.Bokmal]: 'Termografering og tetthetsprøving',
    [Malform.Nynorsk]: 'Termografering og tettleiksprøving'
  },

  [SanitaeranleggTiltak.UtskiftingVarmtvannsbereder]: {
    [Malform.Bokmal]: 'Utskifting av varmtvannsbereder',
    [Malform.Nynorsk]: 'Utskifting av vassvarmar'
  },
  [SanitaeranleggTiltak.TermostatiskBlandeventil]: {
    [Malform.Bokmal]: 'Termostatisk blandeventil',
    [Malform.Nynorsk]: 'Termostatisk blandeventil'
  },
  [SanitaeranleggTiltak.VannbesparendeArmatur]: {
    [Malform.Bokmal]: 'Vannbesparende armatur',
    [Malform.Nynorsk]: ' Vassparande armatur'
  },
  [SanitaeranleggTiltak.IsoleringavVarmtvannsror]: {
    [Malform.Bokmal]: 'Isolering av varmtvannsrør',
    [Malform.Nynorsk]: ' Isolering av varmtvassrør'
  },
  [SanitaeranleggTiltak.VarmepumpeSanitaeranlegg]: {
    [Malform.Bokmal]: 'Varmepumpe sanitæranlegg',
    [Malform.Nynorsk]: ' Varmepumpe sanitæranlegg'
  },
  [LuftbehandlingsanleggTiltak.VarmegjenvinningVentilasjonsanlegg]: {
    [Malform.Bokmal]: 'Varmegjenvinning i ventilasjonsanlegg',
    [Malform.Nynorsk]: 'Varmeattvinning i ventilasjonsanlegg'
  },
  [LuftbehandlingsanleggTiltak.InstillingDriftstiderVentilasjonsanlegg]: {
    [Malform.Bokmal]: 'Instilling av driftstider i ventilasjonsanlegg',
    [Malform.Nynorsk]: 'Instilling av driftstider i ventilasjonsanlegg'
  },
  [LuftbehandlingsanleggTiltak.BehovsstyringVentilasjon]: {
    [Malform.Bokmal]: 'Behovstyring av ventilasjon',
    [Malform.Nynorsk]: 'Styring av ventilasjon etter behov'
  },
  [LuftbehandlingsanleggTiltak.VarmepumpeVarmeFraVentilasjonsluft]: {
    [Malform.Bokmal]: 'Varmepumpe som henter varme fra ventilasjonsluft',
    [Malform.Nynorsk]: 'Varmepumpe som hentar varme frå ventilasjonslufta'
  },

  [ElektriskeAnleggTiltak.AutomatikkLysstyring]: {
    [Malform.Bokmal]: 'Automatikk for styring av lys',
    [Malform.Nynorsk]: 'Automatikk for styring av lys'
  },
  [ElektriskeAnleggTiltak.Lavenergiarmaturer]: {
    [Malform.Bokmal]: 'Lavenergiarmaturer',
    [Malform.Nynorsk]: 'Lågenergiarmaturar'
  },
  [ElektriskeAnleggTiltak.TermostatOgTidsstyringAvElVarme]: {
    [Malform.Bokmal]: 'Termostat- og tidsstyring av el.varme',
    [Malform.Nynorsk]: 'Termostat- og tidsstyring av el. varme'
  },
  [ElektriskeAnleggTiltak.TidsstyringAvElVarme]: {
    [Malform.Bokmal]: 'Tidsstyring av el.varme',
    [Malform.Nynorsk]: 'Tidsstyring av el. varme'
  },
  [AutomatikkanleggTiltak.InstalleringSDAnlegg]: {
    [Malform.Bokmal]: 'Installering av SD-anlegg',
    [Malform.Nynorsk]: 'Installering av SD-anlegg'
  },

  [VarmeanleggTiltak.InnreguleringVarmeanlegg]: {
    [Malform.Bokmal]: 'Innregulering av varmeanlegg',
    [Malform.Nynorsk]: ' Innregulering av varmeanlegg'
  },
  [VarmeanleggTiltak.ShuntreguleringNattsenking]: {
    [Malform.Bokmal]:
      'Shuntregulering med utetemperaturkompensering og nattsenking',
    [Malform.Nynorsk]:
      ' Shuntregulering med utetemperaturkompensering og nattesenking'
  },
  [VarmeanleggTiltak.OmbygningTilMengderegulertSystem]: {
    [Malform.Bokmal]: 'Ombygging til mengderegulert system',
    [Malform.Nynorsk]: 'Ombygging til mengderegulert system'
  },
  [VarmeanleggTiltak.UtskiftingPumper]: {
    [Malform.Bokmal]: 'Utskifting av pumper',
    [Malform.Nynorsk]: ' Utskifting av pumper'
  },
  [VarmeanleggTiltak.MontereTermostatstyrteRadiatorventiler]: {
    [Malform.Bokmal]: 'Montere termostatstyrte radiatorventiler',
    [Malform.Nynorsk]: ' Montering av termostatstyrte radiatorventilar'
  },
  [VarmeanleggTiltak.IsoleringAvRor]: {
    [Malform.Bokmal]: 'Isolering av rør, ventiler, pumper etc.',
    [Malform.Nynorsk]: 'Isolering av rør, ventilar, pumper osv.'
  },
  [VarmeanleggTiltak.UtskiftingOljefyrtKjel]: {
    [Malform.Bokmal]: 'Utskifting av oljefyrt kjel',
    [Malform.Nynorsk]: 'Utskifting av oljefyrt kjel'
  },
  [VarmeanleggTiltak.UtskiftingBrennerOljekjel]: {
    [Malform.Bokmal]: 'Utskifting av brenner til oljekjel',
    [Malform.Nynorsk]: 'Utskifting av brennar til oljekjel'
  },
  [VarmeanleggTiltak.InstallereAutomatiskKjelvelgersentral]: {
    [Malform.Bokmal]: 'Installere automatisk kjelvelgersentral',
    [Malform.Nynorsk]: 'Installering av automatisk kjelveljarsentral'
  },
  [VarmeanleggTiltak.Varmepumpe]: {
    [Malform.Bokmal]: 'Varmepumpe',
    [Malform.Nynorsk]: 'Varmepumpe'
  },
  [VarmeanleggTiltak.OvergangTilFjernvarme]: {
    [Malform.Bokmal]: 'Overgang til fjernvarme',
    [Malform.Nynorsk]: 'Overgang til fjernvarme'
  },
  [VarmeanleggTiltak.VarmeanleggBiobrensel]: {
    [Malform.Bokmal]: 'Varmeanlegg basert på biobrensel',
    [Malform.Nynorsk]: ' Varmeanlegg basert på biobrensel'
  },
  [VarmeanleggTiltak.InstallereSolvarmeanlegg]: {
    [Malform.Bokmal]: 'Installere solvarmeanlegg',
    [Malform.Nynorsk]: 'Installering av solvarmeanlegg'
  },

  [OvrigeTiltak.Solavskjerming]: {
    [Malform.Bokmal]: 'Solavskjerming',
    [Malform.Nynorsk]: ' Solavskjerming'
  },
  [OvrigeTiltak.FrikjolingViaVentilasjon]: {
    [Malform.Bokmal]: 'Frikjøling via ventilasjon',
    [Malform.Nynorsk]: ' Frikjøling via ventilasjon'
  },
  [OvrigeTiltak.FrikjolingViaIsvannsystem]: {
    [Malform.Bokmal]: 'Frikjøling av isvannssystem',
    [Malform.Nynorsk]: 'Frikjøling av isvassystem'
  },
  [OvrigeTiltak.InnreguleringKjoleanlegg]: {
    [Malform.Bokmal]: 'Innregulering av kjøleanlegg',
    [Malform.Nynorsk]: ' Innregulering av kjøleanlegg'
  },
  [OvrigeTiltak.OmbyggingTilMengderegulertKjolesystem]: {
    [Malform.Bokmal]: 'Ombygging til mengderegulert kjølesystem',
    [Malform.Nynorsk]: ' Ombygging til mengderegulert kjølesystem'
  },
  [OvrigeTiltak.UtskiftingPumperKjolesystem]: {
    [Malform.Bokmal]: 'Utskifting av pumper i kjølesystem',
    [Malform.Nynorsk]: ' Utskifting av pumper i kjølesystem'
  },
  [OvrigeTiltak.GjenvinningKondensatorvarme]: {
    [Malform.Bokmal]: 'Gjenvinning av kondensatorvarme',
    [Malform.Nynorsk]: 'Attvinning av kondensatorvarme'
  },
  [OvrigeTiltak.Solcelleanlegg]: {
    [Malform.Bokmal]: 'Solcelleanlegg',
    [Malform.Nynorsk]: 'Solcelleanlegg'
  },

  [UtendorsTiltak.NedborsstyringSnosmelteanlegg]: {
    [Malform.Bokmal]: 'Nedbørsstyring av snøsmelteanlegg',
    [Malform.Nynorsk]: 'Nedbørsstyring av snøsmelteanlegg'
  },
  [UtendorsTiltak.MotereAutomatikkUtebelysning]: {
    [Malform.Bokmal]: 'Montere automatikk på utebelysning',
    [Malform.Nynorsk]: 'Montering av automatikk på utelys'
  },

  [EgendefinertTiltak.Egendefinert]: {
    [Malform.Bokmal]: 'Egendefinert',
    [Malform.Nynorsk]: 'Egendefinert'
  }
};
