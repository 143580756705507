import { useParams } from 'react-router-dom';
import { FC } from 'react';

import { usePublishRegistration } from '../../../../../../../hooks/usePublishRegistration';
import { XMLParams } from '../../../types';
import Summary from '../../../common/steps/summary';
import { XmlRegistrationStepLoader } from '../../../common/components/xmlRegistrationStepLoader';
import { SimpleEnergyAnalysis } from '../../../../../../../types/building';

import { EnhetSummaryTabs } from './enhetSummaryTabs';

interface Props {
  energianalyse?: SimpleEnergyAnalysis;
}

export const EnhetSummary: FC<Props> = ({ energianalyse }) => {
  const { registrationId } = useParams<XMLParams>();

  const buildingId = energianalyse?.registrering.energiObjektId ?? '';

  const { publishRegistration, publishIsLoading } = usePublishRegistration(
    registrationId,
    buildingId
  );

  if (publishIsLoading) {
    return <XmlRegistrationStepLoader />;
  }

  if (!energianalyse) {
    return null;
  }

  return (
    <Summary
      onPublishRegistration={publishRegistration}
      summaryTabs={<EnhetSummaryTabs energianalyse={energianalyse} />}
      registrationType="enhet"
    />
  );
};
