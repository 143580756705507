import React, { FC, useMemo } from 'react';
import {
  CollapsibleFactBox,
  Grid,
  Hidden,
  styled,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  Energienhet,
  Energikilde
} from '../../../../../../components/registering/utils/registerEnums';
import {
  Energibruk,
  TekniskUtstyr
} from '../../../../../../types/registration/userInput';
import { getEnergibruk } from '../../../../../../store/registration/selectors';
import { energySourceUnitMap } from '../../../../../../utils/register';
import useSelector from '../../../../../../hooks/useSelector';
import { TechnicalEquipmentFields } from '../../../../../../components/registering/utils/registerConfig';

const StyledTable = styled.table`
  width: 100%;

  caption {
    font-size: ${({ theme }) => theme.typography.h3.fontSize};
    text-align: left;
  }

  td,
  th {
    padding: ${({ theme }) => theme.spacing(1, 4)};
    text-align: center;

    &.dense {
      padding: 0;
    }

    &.left {
      text-align: left;

      &.dense {
        padding: ${({ theme }) => theme.spacing(0.5, 2, 0.5, 0)};
      }
    }

    &.right {
      text-align: right;

      &.dense {
        padding: ${({ theme }) => theme.spacing(0.5, 0, 0.5, 2)};
      }
    }
  }

  .energysource {
    padding: ${({ theme }) => theme.spacing(1, 4, 1, 0)};
  }
`;

const EnergyUsageSummarySection: FC = () => {
  const { t } = useTranslation();

  const energibruk = useSelector(
    (state) => getEnergibruk(state)?.filter((item) => item.forbruk != null),
    shallowEqual
  );

  const groupedBySource = useMemo(
    () =>
      energibruk?.reduce(
        (
          acc: Partial<
            Record<
              Energikilde,
              Pick<Energibruk, 'energienhet' | 'energikilde'> &
                Record<number, number>
            >
          >,
          cur
        ) => ({
          ...acc,
          [cur.energikilde]: {
            ...(acc[cur.energikilde] || {}),
            [cur.ar]: cur.forbruk,
            energienhet: cur.energienhet,
            energikilde: cur.energikilde
          }
        }),
        {}
      ) || {},
    [energibruk]
  );

  const years = energibruk
    ?.reduce(
      (acc: number[], { ar, forbruk }) =>
        forbruk != null && !acc.includes(ar) ? [...acc, ar] : acc,
      []
    )
    .sort();

  const yearCount =
    years && years.length > 0 ? Math.max(...years) - Math.min(...years) + 1 : 0;

  const filledYears = new Array(yearCount)
    .fill(0)
    .map((_, i) => i + Math.min(...(years || [])));

  const titleId = useMemo(() => uuidv4(), []);

  return (
    <Grid item xs={12}>
      <CollapsibleFactBox
        initExpanded
        title={t('register.energyUsage.title')}
        TitleProps={{ id: titleId }}
      >
        <Hidden smUp>
          <Grid container spacing={4} direction="column">
            {filledYears.map((year) => (
              <Grid item key={year}>
                <StyledTable>
                  <caption>
                    {t('register.energyUsage.usageForYear', { year })}
                  </caption>

                  <tbody>
                    <tr>
                      <td aria-hidden className="dense left">
                        <Typography component="span" bold>
                          {t('energySource')}
                        </Typography>
                      </td>

                      <th className="dense right" scope="col">
                        <Typography component="span" bold>
                          {t('register.energyUsage.usage')}
                        </Typography>
                      </th>
                    </tr>

                    {Object.entries(groupedBySource).map(([key, value]) => {
                      const { energienhet } = value;

                      const test1 = Object.entries(energySourceUnitMap).find(
                        ([, val]) => val.energySource === key
                      )?.[0];

                      const tLabelKey =
                        TechnicalEquipmentFields[0].options.find(
                          (option) => option.value === test1
                        )?.tLabelKey;

                      return (
                        <tr key={key}>
                          <th className="dense left" scope="row">
                            <Typography component="span">
                              {t(tLabelKey as string)}
                            </Typography>
                          </th>

                          <td className="dense right" key={year}>
                            <Typography variant="srOnly">{`${value[year]} ${t(
                              `register.energienhet.${Energienhet[energienhet]}`,
                              { context: 'sr' }
                            )}`}</Typography>

                            <Typography aria-hidden component="span">{`${
                              value[year]
                            } ${t(
                              `register.energienhet.${Energienhet[energienhet]}`
                            )}`}</Typography>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </StyledTable>
              </Grid>
            ))}
          </Grid>
        </Hidden>

        <Hidden smDown>
          <StyledTable aria-labelledby={titleId}>
            <tbody>
              <tr>
                <td className="dense left" aria-hidden>
                  <Typography component="span" bold>
                    {t('energySource')}
                  </Typography>
                </td>

                {filledYears?.map((year) => (
                  <th scope="col" key={year} id={year.toString()}>
                    <Typography bold>{year}</Typography>
                  </th>
                ))}
              </tr>

              {Object.entries(energySourceUnitMap)
                .filter(
                  ([key, { energySource }]) =>
                    (key as keyof TekniskUtstyr) === 'oppvarmingElektrisk' ||
                    Object.keys(groupedBySource).includes(energySource)
                )
                .map(([key, { energySource }]) => {
                  const tLabelKey = TechnicalEquipmentFields[0].options.find(
                    (option) => option.value === key
                  )?.tLabelKey;

                  const { [energySource]: { energienhet = undefined } = {} } =
                    groupedBySource || {};

                  return tLabelKey ? (
                    <tr key={energySource}>
                      <th scope="row" className="dense left" id={energySource}>
                        <Typography component="span" bold>
                          {t(tLabelKey as string)}
                        </Typography>
                      </th>

                      {filledYears?.map((year) => {
                        const value = groupedBySource?.[energySource]?.[year];

                        return (
                          <td
                            key={year}
                            headers={`${year} ${energySource} ${energySource}_unit`}
                            title={
                              value == null
                                ? t('register.missingValue')
                                : `${value} ${t(
                                    `register.energienhet.${
                                      energienhet
                                        ? Energienhet[energienhet]
                                        : ''
                                    }`
                                  )}`
                            }
                          >
                            <Typography component="span">
                              {value ?? '-'}
                            </Typography>
                          </td>
                        );
                      })}

                      {energienhet && (
                        <th
                          scope="row"
                          id={`${energySource}_unit`}
                          className="dense left"
                        >
                          <Typography variant="srOnly">
                            {t(
                              `register.energienhet.${
                                Energienhet[energienhet!]
                              }`,
                              { context: 'sr' }
                            )}
                          </Typography>

                          <Typography
                            aria-hidden
                            component="span"
                            title={t(
                              `register.energienhet.${
                                Energienhet[energienhet!]
                              }`,
                              { context: 'sr' }
                            )}
                          >
                            {t(
                              `register.energienhet.${
                                Energienhet[energienhet!]
                              }`
                            )}
                          </Typography>
                        </th>
                      )}
                    </tr>
                  ) : null;
                })}
            </tbody>
          </StyledTable>
        </Hidden>
      </CollapsibleFactBox>
    </Grid>
  );
};

export default EnergyUsageSummarySection;
