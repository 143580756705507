import { useTranslation } from 'react-i18next';
import { useSnack } from 'enova-frontend-components';
import { useQuery } from 'react-query';

import { useBuildingSearchFormContext } from '../types';
import {
  fetchKommuneByNavn,
  fetcKommuneByNummer
} from '../../../../../../../../../services/api';
import { KommuneSearchOptions } from '../../../../../../../buildings/components/searchView/advancedSearch/types';
import { queryKeys } from '../../../../../../../../../utils/react-query';

const isStringNumber = (str: string) => {
  // Use a regular expression to check for a number
  const regex = /^\d+$/;
  return regex.test(str);
};

const fetchKommuner = async (input: string) => {
  if (isStringNumber(input)) {
    return await fetcKommuneByNummer(input);
  }
  return await fetchKommuneByNavn(input);
};
const isSearchEnabled = (input: string) => {
  if (isStringNumber(input)) {
    return input.length === 4;
  }
  return input.length > 1;
};

export const useKommuneSearch = (kommuneInput: string) => {
  const { t } = useTranslation();
  const { addSnack } = useSnack();
  const { setValue } = useBuildingSearchFormContext();

  const { isLoading } = useQuery(
    [queryKeys.kommune, kommuneInput],
    () => fetchKommuner(kommuneInput),
    {
      enabled: isSearchEnabled(kommuneInput),
      onSuccess(data) {
        if (!data) {
          return;
        }

        const options: KommuneSearchOptions =
          data?.kommuner.map((kommune) => ({
            label:
              `${kommune.kommunenavnNorsk} (${kommune.kommunenummer})` ?? '',
            value: kommune.kommunenummer ?? ''
          })) ?? [];

        setValue('kommuneOptions', options);
      },
      onError() {
        setValue('kommunenummer', '');
        addSnack(
          t('search.advanced.error.kommuneSearch', {
            input: kommuneInput
          }),
          {
            variant: 'error'
          }
        );
      }
    }
  );

  return { isLoading };
};
