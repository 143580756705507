import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'enova-frontend-components';

import OpenRegistrationListItem, {
  OpenRegistrationListItemProps
} from './openRegistrationListItem';
import { RegistrationType } from './models';

type OpenRegistrationListProps = {
  registrationIds?: string[];
  translationContext?: string;
  registrationType: RegistrationType;
} & Pick<OpenRegistrationListItemProps, 'getPath'>;

const OpenRegistrationList: FC<OpenRegistrationListProps> = ({
  getPath,
  registrationIds,
  translationContext: context,
  registrationType
}) => {
  const { t } = useTranslation();

  return registrationIds && registrationIds.length > 0 ? (
    <Fragment>
      <Grid item xs={12}>
        <Typography mt={5} gutterBottom variant="h2">
          {t('energyrating.continue.existing.title', { context })}
        </Typography>

        <Typography>
          {t('energyrating.continue.existing.description', { context })}
        </Typography>
      </Grid>

      {registrationIds.map((id) => (
        <OpenRegistrationListItem
          getPath={getPath}
          key={id}
          registrationId={id}
          registrationType={registrationType}
        />
      ))}
    </Fragment>
  ) : null;
};

export default OpenRegistrationList;
