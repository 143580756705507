import {
  getRadioOptionsWithTLabelKey,
  getSelectOptionsFromEnum
} from '../../../../utils/register';
import { Field, FieldType } from '../../../../types/registration/fieldTypes';
import {
  JaNeiVetIkke,
  Varmekilde,
  VentilasjonsType,
  VentilasjonVarmegjenvinningsType
} from '../registerEnums';

import {
  getInstallasjonsArField,
  getServiceGjennomfortSiste2ArField
} from './common';

export const VentilationFields: FieldType[] = [
  // Display only if varmeKilde for varmepumpe is _NOT_ Varmekilde.LuftFraVentilasjonsanlegg
  {
    attrKey: 'ventilasjon',
    condition: (state) =>
      state.register?.userInput?.tekniskUtstyr?.oppvarmingVarmepumpe
        ?.varmeKilde !== Varmekilde.LuftFraVentilasjonsanlegg,
    key: 'ventilasjon-without-Varmekilde.LuftFraVentilasjonsanlegg',
    options: [
      {
        key: VentilasjonsType.Naturlig,
        tLabelKey: `register.ventilation.option.${VentilasjonsType.Naturlig}`,
        value: VentilasjonsType.Naturlig,
        width: 'auto',
        tHelperTextKey: 'register.ventilation.option.naturlig.helperText'
      },
      {
        key: VentilasjonsType.PeriodiskAvtrekk,
        tLabelKey: `register.ventilation.option.${VentilasjonsType.PeriodiskAvtrekk}`,
        value: VentilasjonsType.PeriodiskAvtrekk,
        width: 'auto',
        tHelperTextKey:
          'register.ventilation.option.periodiskAvtrekk.helperText'
      },
      {
        key: VentilasjonsType.MekaniskAvtrekk,
        tLabelKey: `register.ventilation.option.${VentilasjonsType.MekaniskAvtrekk}`,
        value: VentilasjonsType.MekaniskAvtrekk,
        width: 'auto',
        tHelperTextKey: 'register.ventilation.option.mekaniskAvtrekk.helperText'
      },
      {
        key: VentilasjonsType.Balansert,
        tLabelKey: `register.ventilation.option.${VentilasjonsType.Balansert}`,
        value: VentilasjonsType.Balansert,
        width: 'auto',
        tHelperTextKey: 'register.ventilation.option.balansert.helperText',
        fields: [
          getInstallasjonsArField(
            (state) => state.register.userInput?.ventilasjon?.installasjonsAr,
            ['ventilasjon']
          ),
          {
            ...getServiceGjennomfortSiste2ArField(
              (state) =>
                state.register.userInput?.ventilasjon
                  ?.serviceGjennomfortSiste2Ar,
              'ventilasjon',
              ['ventilasjon']
            ),
            tLabelKey: 'register.ventilation.serviceGjennomfortSiste2Ar.label'
          },
          {
            attrKey: 'gjenvinningAvVarme',
            row: true,
            options: getRadioOptionsWithTLabelKey(JaNeiVetIkke, 'auto'),
            path: ['ventilasjon', 'gjenvinningAvVarme'],
            selector: (state) =>
              state.register.userInput?.ventilasjon?.gjenvinningAvVarme,
            tLabelKey: 'register.ventilation.gjenvinningAvVarme.label',
            tInformationKey:
              'register.ventilation.gjenvinningAvVarme.information',
            type: Field.RADIO,
            commonFields: {
              [JaNeiVetIkke.Ja]: [
                {
                  attrKey: 'gjenvinningsType',
                  path: ['ventilasjon', 'gjenvinningsType'],
                  options: [
                    {
                      label:
                        'register.ventilation.gjenvinningsType.option.default',
                      value: -1,
                      disabled: true
                    },
                    ...getSelectOptionsFromEnum(
                      VentilasjonVarmegjenvinningsType,
                      (label) =>
                        `register.ventilation.gjenvinningsType.option.${label}`
                    )
                  ],
                  selector: (state) =>
                    state.register?.userInput?.ventilasjon?.gjenvinningsType,
                  tLabelKey: 'register.ventilation.gjenvinningsType.label',
                  type: Field.SELECT
                }
              ]
            }
          }
        ]
      },
      {
        key: VentilasjonsType.Ukjent,
        tLabelKey: `register.ventilation.option.${VentilasjonsType.Ukjent}`,
        value: VentilasjonsType.Ukjent,
        width: 'auto',
        tHelperTextKey: 'register.ventilation.option.ukjent.helperText'
      }
    ],
    path: ['ventilasjon', 'ventilasjonType'],
    required: true,
    selector: (state) => state.register.userInput?.ventilasjon?.ventilasjonType,
    tDescriptionKey: 'register.ventilation.description',
    tLabelKey: '',
    type: Field.RADIO,
    valueType: 'string'
  },
  // Display only if varmeKilde for varmepumpe _IS_ Varmekilde.LuftFraVentilasjonsanlegg
  {
    attrKey: 'ventilasjon',
    condition: (state) =>
      state.register?.userInput?.tekniskUtstyr?.oppvarmingVarmepumpe
        ?.varmeKilde === Varmekilde.LuftFraVentilasjonsanlegg,
    key: 'ventilasjon-with-Varmekilde.LuftFraVentilasjonsanlegg',
    options: [
      {
        key: VentilasjonsType.MekaniskAvtrekk,
        tLabelKey: `register.ventilation.option.${VentilasjonsType.MekaniskAvtrekk}`,
        value: VentilasjonsType.MekaniskAvtrekk,
        width: 'auto',
        tHelperTextKey: 'register.ventilation.option.mekaniskAvtrekk.helperText'
      },
      {
        key: VentilasjonsType.Balansert,
        tLabelKey: `register.ventilation.option.${VentilasjonsType.Balansert}`,
        value: VentilasjonsType.Balansert,
        width: 'auto',
        tHelperTextKey: 'register.ventilation.option.balansert.helperText',
        fields: [
          getInstallasjonsArField(
            (state) => state.register.userInput?.ventilasjon?.installasjonsAr,
            ['ventilasjon']
          ),
          {
            ...getServiceGjennomfortSiste2ArField(
              (state) =>
                state.register.userInput?.ventilasjon
                  ?.serviceGjennomfortSiste2Ar,
              'ventilasjon',
              ['ventilasjon']
            ),
            tLabelKey: 'register.ventilation.serviceGjennomfortSiste2Ar.label'
          },
          {
            attrKey: 'gjenvinningAvVarme',
            row: true,
            options: getRadioOptionsWithTLabelKey(JaNeiVetIkke, 'auto'),
            path: ['ventilasjon', 'gjenvinningAvVarme'],
            selector: (state) =>
              state.register.userInput?.ventilasjon?.gjenvinningAvVarme,
            tLabelKey: 'register.ventilation.gjenvinningAvVarme.label',
            tInformationKey:
              'register.ventilation.gjenvinningAvVarme.information',
            type: Field.RADIO,
            commonFields: {
              [JaNeiVetIkke.Ja]: [
                {
                  attrKey: 'gjenvinningsType',
                  path: ['ventilasjon', 'gjenvinningsType'],
                  options: [
                    {
                      label:
                        'register.ventilation.gjenvinningsType.option.default',
                      value: -1,
                      disabled: true
                    },
                    ...getSelectOptionsFromEnum(
                      VentilasjonVarmegjenvinningsType,
                      (label) =>
                        `register.ventilation.gjenvinningsType.option.${label}`
                    )
                  ],
                  selector: (state) =>
                    state.register?.userInput?.ventilasjon?.gjenvinningsType,
                  tLabelKey: 'register.ventilation.gjenvinningsType.label',
                  type: Field.SELECT
                }
              ]
            }
          }
        ]
      }
    ],
    path: ['ventilasjon', 'ventilasjonType'],
    selector: (state) => state.register.userInput?.ventilasjon?.ventilasjonType,
    tDescriptionKey: 'register.ventilation.description',
    tLabelKey: '',
    type: Field.RADIO,
    valueType: 'string'
  }
];
