import { BuildingSearchFormData } from '../types';

export const createSearchParams = (
  data: BuildingSearchFormData,
  searchString: string
) => {
  // Use existing search params to preserve values outside search form
  const searchParams = new URLSearchParams(searchString);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const entries = Object.entries(data) as [keyof BuildingSearchFormData, any][];

  for (const [key, value] of entries) {
    // Skip creating search params for kommuneOptions and post information as it's only used to find kommune number.
    if (key === 'kommuneOptions') {
      continue;
    }

    if (value) {
      // Update search params with search form data
      searchParams.set(key, value);
    } else {
      // Delete empty values to reset previous search
      searchParams.delete(key);
    }
  }

  return searchParams;
};
