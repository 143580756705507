import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'enova-frontend-components';
import React from 'react';

import { getWindowSubHeader } from '../utils';
import { Vegg } from '../../../../../types/registration/userInput';

export const WindowWallTitle = ({ wall }: { wall: Vegg }) => {
  const { t } = useTranslation();

  const hasWallZones = wall.veggSoner.length > 0;
  const windowAmount = wall.veggSoner.reduce(
    (totalWindowAmount, currentValue) =>
      totalWindowAmount + (currentValue?.vinduer?.length || 0),
    0
  );

  const subHeader = getWindowSubHeader(
    windowAmount,
    !!wall.hasWindows,
    hasWallZones
  );

  return (
    <Box display="flex" flexDirection="row" gap={4} alignItems="center">
      <Typography variant="h4">
        {t('wall', { veggId: wall.veggId.toUpperCase() })}
      </Typography>
      <Typography>( {subHeader} )</Typography>
    </Box>
  );
};
