import {
  Box,
  Menu,
  Paper,
  styled,
  Typography
} from 'enova-frontend-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WallZoneData } from '../types';
import { CustomMenuButton } from '../../../../customMenuButton';

import { EditWallZoneForm } from './editWallZoneForm';
import { WallZoneInformation } from './wallZoneInformation';
import { useWallZoneOperations } from './form/useWallZoneOperations';

const WallZoneDataWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
`;

const TitleWrapper = styled(Box)`
  padding: 15px;
  border-bottom: 1px solid #d0d2d2;
`;

interface Props {
  wallZoneData: WallZoneData;
}

export const WallZone: FC<Props> = ({ wallZoneData }) => {
  const { t } = useTranslation();
  const { removeVeggsone } = useWallZoneOperations(
    wallZoneData.floorId,
    wallZoneData.wallId
  );
  const [isEditMode, setEditMode] = useState<boolean>(false);

  const wallZoneTitle =
    wallZoneData.wallZoneIndex === undefined
      ? t('register.wallZone.uniformWall')
      : t('register.wallZone.zoneId', {
          index: wallZoneData.wallZoneIndex + 1
        });

  return (
    <Paper
      key={wallZoneData.wallZoneIndex}
      shadow="none"
      style={{ border: 'solid 1px #d0d2d2' }}
    >
      <TitleWrapper>
        <Typography variant="h4">{wallZoneTitle}</Typography>
      </TitleWrapper>

      {isEditMode && (
        <Box m={5} p={3}>
          <EditWallZoneForm
            onSave={() => setEditMode(false)}
            data={wallZoneData}
          />
        </Box>
      )}

      {!isEditMode && (
        <WallZoneDataWrapper>
          <Box m={5} p={3}>
            <WallZoneInformation wallZone={wallZoneData.wallZone} />
          </Box>

          {/*Action buttons*/}
          <Box display="flex" alignItems="stretch" ml={2}>
            <Menu>
              <CustomMenuButton />
              <Menu.Content>
                <Menu.Item
                  onClick={() =>
                    wallZoneData?.wallZone?.id &&
                    removeVeggsone(wallZoneData.wallZone.id)
                  }
                >
                  {t('register.wallZone.form.delete')}
                </Menu.Item>
                <Menu.Item onClick={() => setEditMode(true)}>
                  {t('register.wallZone.form.edit')}
                </Menu.Item>
              </Menu.Content>
            </Menu>
          </Box>
        </WallZoneDataWrapper>
      )}
    </Paper>
  );
};
