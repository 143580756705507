import type { User } from 'oidc-client-ts';

export interface AuthenticationState {
  /**
   * See [User](https://authts.github.io/oidc-client-ts/classes/User.html) for more details.
   */
  user?: User | null;

  /**
   * True when the library has been initialized and no navigator request is in progress.
   */
  isLoading: boolean;
  /**
   * True while the user has a valid access token.
   */
  isAuthenticated: boolean;
  /**
   * Was there a signin or silent renew error?
   */
  error?: Error;
  /**
   * When a user's session is about to expire, the boolean value 'isDialogOpen' is set to true,
   * which triggers the display of a dialog box. The user can then choose to reauthenticate in
   * order to extend their session.
   *
   * The boolean value 'isNotificationDismissed' indicates whether the user has dismissed the
   * dialog box. This information is used to prevent the user from being spammed with repeated notifications.
   */
  sessionExpiry: {
    isDialogOpen: boolean;
    isNotificationDismissed: boolean;
  };
}

export const initialAuthState: AuthenticationState = {
  isLoading: true,
  isAuthenticated: false,
  sessionExpiry: {
    isDialogOpen: false,
    isNotificationDismissed: false
  }
};
