import {
  DorAddAction,
  DorAddPayload,
  DorRemoveAction,
  DorRemovePayload,
  EtasjeRotateAction,
  EtasjeRotatePayload,
  EtasjerRotateAction,
  EtasjerUpdateAction,
  EtasjerUpdatePayload,
  EtasjerUpdateShapeAction,
  EtasjerUpdateShapePayload,
  EtasjerUpdateWallAction,
  EtasjerUpdateWallPayload,
  EtasjerUpdateWallsAction,
  EtasjerUpdateWallsPayload,
  EtasjerVeggSetHasMultipleZonesAction,
  EtasjerVeggSetHasMultipleZonesPayload,
  EtasjerVeggsoneAddAction,
  EtasjerVeggsoneAddPayload,
  EtasjerVeggsoneRemoveAction,
  EtasjerVeggsoneRemovePayload,
  EtasjerVeggsoneUpdateAction,
  EtasjerVeggsoneUpdatePayload,
  EtasjeUpdateAction,
  EtasjeUpdatePayload,
  EtasjeUpdateShapeAction,
  EtasjeUpdateShapePayload,
  EtasjeUpdateWallAction,
  EtasjeUpdateWallPayload,
  EtasjeUpdateWallsAction,
  EtasjeUpdateWallsPayload,
  EtasjeVeggSetHasMultipleZonesAction,
  EtasjeVeggSetHasMultipleZonesPayload,
  EtasjeVeggsoneAddAction,
  EtasjeVeggsoneAddPayload,
  EtasjeVeggsoneRemoveAction,
  EtasjeVeggsoneRemovePayload,
  EtasjeVeggsoneUpdateAction,
  EtasjeVeggsoneUpdatePayload,
  GjenstaendeTiltakAddAction,
  GjenstaendeTiltakAddPayload,
  GjenstaendeTiltakRemoveAction,
  GjenstaendeTiltakRemovePayload,
  GulvSetHasMultipleZonesAction,
  GulvSetHasMultipleZonesPayload,
  GulvsoneAddAction,
  GulvsoneAddPayload,
  GulvsoneInitAction,
  GulvsoneRemoveAction,
  GulvsoneRemovePayload,
  GulvsoneUpdateAction,
  GulvsoneUpdatePayload,
  InitRegistrationAction,
  InitSoknadAction,
  InitSoknadPayload,
  RegistrationAction,
  TakUpdateAction,
  TakUpdatePayload,
  TakVinduAddAction,
  TakVinduAddPayload,
  TakVinduEditAction,
  TakVinduEditPayload,
  TakVinduRemoveAction,
  TakVinduRemovePayload,
  ToggleAllFloorsSameShapeAction,
  ToggleAllFloorsSameShapePayload,
  ToggleAllFloorsSameWallConstrAction,
  ToggleAllFloorsSameWallConstrPayload,
  ToggleAllWallsAgainstTheOpenAction,
  ToggleAllWallsAgainstTheOpenPayload,
  UpdateBygningsdetaljerAction,
  UpdateBygningsdetaljerPayload,
  UpdateCalculatedAreaAction,
  UpdateCalculatedAreaPayload,
  UpdateDetailingStepsAction,
  UpdateDetailingStepsPayload,
  UpdateEnergibrukAction,
  UpdateEnergibrukPayload,
  UpdateEnergibrukUnitAction,
  UpdateEnergibrukUnitPayload,
  UpdateKonstruksjonstetthetAction,
  UpdateKonstruksjonstetthetPayload,
  UpdateParametersAction,
  UpdateParametersPayload,
  UpdateRegistrationAction,
  UpdateRegistrationFieldAction,
  UpdateRegistrationFieldPayload,
  UpdateRegistrationPayload,
  UpdateTerrengskjermingAction,
  UpdateTerrengskjermingPayload,
  UpdateUserAction,
  UpdateUserPayload,
  VeggsoneCopyAction,
  VeggsoneCopyPayload,
  VinduAddAction,
  VinduAddPayload,
  VinduEditAction,
  VinduEditPayload,
  VinduRemoveAction,
  VinduRemovePayload,
  WallHasNoWindowsAction,
  WallHasNoWindowsPayload
} from './types';

export const updateRegistration = (
  payload: UpdateRegistrationPayload
): UpdateRegistrationAction => ({
  payload,
  type: RegistrationAction.UPDATE_REGISTRATION
});

// INIT ACTION
export const initRegistration = (): InitRegistrationAction => ({
  type: RegistrationAction.INIT_REGISTRATION
});

// INIT SØKNAD ACTION
export const initSoknad = (payload: InitSoknadPayload): InitSoknadAction => ({
  payload,
  type: RegistrationAction.INIT_SOKNAD
});

// DOR ACTIONS
export const addDor = (payload: DorAddPayload): DorAddAction => ({
  payload,
  type: RegistrationAction.DOR_ADD
});

export const removeDor = (payload: DorRemovePayload): DorRemoveAction => ({
  payload,
  type: RegistrationAction.DOR_REMOVE
});

// VINDU ACTIONS
export const addVindu = (payload: VinduAddPayload): VinduAddAction => ({
  payload,
  type: RegistrationAction.VINDU_ADD
});

export const removeVindu = (
  payload: VinduRemovePayload
): VinduRemoveAction => ({
  payload,
  type: RegistrationAction.VINDU_REMOVE
});

export const editVindu = (payload: VinduEditPayload): VinduEditAction => ({
  payload,
  type: RegistrationAction.VINDU_EDIT
});

// GULVSONE ACTIONS
export const initGulvsoner = (): GulvsoneInitAction => ({
  type: RegistrationAction.GULVSONE_INIT
});

export const addGulvsone = (
  payload: GulvsoneAddPayload
): GulvsoneAddAction => ({
  payload,
  type: RegistrationAction.GULVSONE_ADD
});

export const setHasMultipleZonesGulvsoner = (
  payload: GulvSetHasMultipleZonesPayload
): GulvSetHasMultipleZonesAction => ({
  payload,
  type: RegistrationAction.GULV_SET_HAS_MULTIPLE_ZONES
});

export const removeGulvsone = (
  payload: GulvsoneRemovePayload
): GulvsoneRemoveAction => ({
  payload,
  type: RegistrationAction.GULVSONE_REMOVE
});

export const updateGulvsone = (
  payload: GulvsoneUpdatePayload
): GulvsoneUpdateAction => ({
  payload,
  type: RegistrationAction.GULVSONE_UPDATE
});

// ETASJE ACTIONS
export const updateEtasje = (
  payload: EtasjeUpdatePayload
): EtasjeUpdateAction => ({
  payload,
  type: RegistrationAction.ETASJE_UPDATE
});

export const updateEtasjeShape = (
  payload: EtasjeUpdateShapePayload
): EtasjeUpdateShapeAction => ({
  payload,
  type: RegistrationAction.ETASJE_UPDATE_SHAPE
});

export const rotateEtasje = (
  payload: EtasjeRotatePayload
): EtasjeRotateAction => ({
  payload,
  type: RegistrationAction.ETASJE_ROTATE
});

export const setWallHasNoWindows = (
  payload: WallHasNoWindowsPayload
): WallHasNoWindowsAction => ({
  payload,
  type: RegistrationAction.WALL_HAS_NO_WINDOWS
});

export const updateEtasjeWall = (
  payload: EtasjeUpdateWallPayload
): EtasjeUpdateWallAction => ({
  payload,
  type: RegistrationAction.ETASJE_UPDATE_WALL
});

export const updateEtasjeWalls = (
  payload: EtasjeUpdateWallsPayload
): EtasjeUpdateWallsAction => ({
  payload,
  type: RegistrationAction.ETASJE_UPDATE_WALLS
});

export const addEtasjeVeggsone = (
  payload: EtasjeVeggsoneAddPayload
): EtasjeVeggsoneAddAction => ({
  payload,
  type: RegistrationAction.ETASJE_VEGGSONE_ADD
});

export const setHasMultipleZonesEtasjeVeggsone = (
  payload: EtasjeVeggSetHasMultipleZonesPayload
): EtasjeVeggSetHasMultipleZonesAction => ({
  payload,
  type: RegistrationAction.ETASJE_VEGG_SET_HAS_MULTIPLE_ZONES
});

export const removeEtasjeVeggsone = (
  payload: EtasjeVeggsoneRemovePayload
): EtasjeVeggsoneRemoveAction => ({
  payload,
  type: RegistrationAction.ETASJE_VEGGSONE_REMOVE
});

export const copyVeggkonstruksjon = (
  payload: VeggsoneCopyPayload
): VeggsoneCopyAction => ({
  payload,
  type: RegistrationAction.VEGGKONSTRUKSJON_COPY
});
export const updateEtasjeVeggsone = (
  payload: EtasjeVeggsoneUpdatePayload
): EtasjeVeggsoneUpdateAction => ({
  payload,
  type: RegistrationAction.ETASJE_VEGGSONE_UPDATE
});

// ETASJER ACTIONS
export const updateEtasjer = (
  payload: EtasjerUpdatePayload
): EtasjerUpdateAction => ({
  payload,
  type: RegistrationAction.ETASJER_UPDATE
});

export const updateEtasjerShape = (
  payload: EtasjerUpdateShapePayload
): EtasjerUpdateShapeAction => ({
  payload,
  type: RegistrationAction.ETASJER_UPDATE_SHAPE
});

export const rotateEtasjer = (): EtasjerRotateAction => ({
  type: RegistrationAction.ETASJER_ROTATE
});

export const updateEtasjerWall = (
  payload: EtasjerUpdateWallPayload
): EtasjerUpdateWallAction => ({
  payload,
  type: RegistrationAction.ETASJER_UPDATE_WALL
});

export const updateEtasjerWalls = (
  payload: EtasjerUpdateWallsPayload
): EtasjerUpdateWallsAction => ({
  payload,
  type: RegistrationAction.ETASJER_UPDATE_WALLS
});

export const addEtasjerVeggsone = (
  payload: EtasjerVeggsoneAddPayload
): EtasjerVeggsoneAddAction => ({
  payload,
  type: RegistrationAction.ETASJER_VEGGSONE_ADD
});

export const setHarMultipleZonesEtasjerVeggsone = (
  payload: EtasjerVeggSetHasMultipleZonesPayload
): EtasjerVeggSetHasMultipleZonesAction => ({
  payload,
  type: RegistrationAction.ETASJER_VEGG_SET_HAS_MULTIPLE_ZONES
});

export const removeEtasjerVeggsone = (
  payload: EtasjerVeggsoneRemovePayload
): EtasjerVeggsoneRemoveAction => ({
  payload,
  type: RegistrationAction.ETASJER_VEGGSONE_REMOVE
});

export const updateEtasjerVeggsone = (
  payload: EtasjerVeggsoneUpdatePayload
): EtasjerVeggsoneUpdateAction => ({
  payload,
  type: RegistrationAction.ETASJER_VEGGSONE_UPDATE
});

// MEASURE ACTIONS
export const addTiltak = (
  payload: GjenstaendeTiltakAddPayload
): GjenstaendeTiltakAddAction => ({
  payload,
  type: RegistrationAction.GJENSTAENDE_TILTAK_ADD
});

export const removeTiltak = (
  payload: GjenstaendeTiltakRemovePayload
): GjenstaendeTiltakRemoveAction => ({
  payload,
  type: RegistrationAction.GJENSTAENDE_TILTAK_REMOVE
});

// TAK ACTIONS
export const updateTak = (payload: TakUpdatePayload): TakUpdateAction => ({
  payload,
  type: RegistrationAction.TAK_UPDATE
});

export const addTakVindu = (
  payload: TakVinduAddPayload
): TakVinduAddAction => ({
  payload,
  type: RegistrationAction.TAK_VINDU_ADD
});

export const editTakVindu = (
  payload: TakVinduEditPayload
): TakVinduEditAction => ({
  payload,
  type: RegistrationAction.TAK_VINDU_EDIT
});

export const removeTakVindu = (
  payload: TakVinduRemovePayload
): TakVinduRemoveAction => ({
  payload,
  type: RegistrationAction.TAK_VINDU_REMOVE
});

// BYGNINGSDETALJERING ACTIONS
export const updateBygningsdetaljer = (
  payload: UpdateBygningsdetaljerPayload
): UpdateBygningsdetaljerAction => ({
  payload,
  type: RegistrationAction.UPDATE_BYGNINGSDETALJERING
});

// KONSTRUKSJONSTETTHET ACTIONS
export const updateKonstruksjonstetthet = (
  payload: UpdateKonstruksjonstetthetPayload
): UpdateKonstruksjonstetthetAction => ({
  payload,
  type: RegistrationAction.UPDATE_KONSTRUKSJONSTETTHET
});

// ENERGIBRUK ACTIONS
export const updateEnergibruk = (
  payload: UpdateEnergibrukPayload
): UpdateEnergibrukAction => ({
  payload,
  type: RegistrationAction.UPDATE_ENERGIBRUK
});

export const updateEnergibrukUnit = (
  payload: UpdateEnergibrukUnitPayload
): UpdateEnergibrukUnitAction => ({
  payload,
  type: RegistrationAction.UPDATE_ENERGIBRUK_UNIT
});

// TERRENGSKJERMING ACTIONS
export const updateTerrengskjerming = (
  payload: UpdateTerrengskjermingPayload
): UpdateTerrengskjermingAction => ({
  payload,
  type: RegistrationAction.UPDATE_TERRENGSKJERMING
});

// DETAILING STEPS ACTIONS
export const updateDetailingSteps = (
  payload: UpdateDetailingStepsPayload
): UpdateDetailingStepsAction => ({
  payload,
  type: RegistrationAction.UPDATE_DETAILING_STEPS
});

// TOGGLE ALL FLOORS HAVE THE SAME SHAPE
export const toggleAllFloorsHaveSameShape = (
  payload: ToggleAllFloorsSameShapePayload
): ToggleAllFloorsSameShapeAction => ({
  payload,
  type: RegistrationAction.TOGGLE_ALL_FLOORS_SAME_SHAPE
});

// TOGGLE ALL FLOORS HAVE THE SAME WALL CONSTRUCTION
export const toggleAllFloorsHaveSameWallConstr = (
  payload: ToggleAllFloorsSameWallConstrPayload
): ToggleAllFloorsSameWallConstrAction => ({
  payload,
  type: RegistrationAction.TOGGLE_ALL_FLOORS_SAME_WALL_CONSTR
});

// TOGGLE ALL WALLS AGAINST THE OPEN
export const toggleAllWallsAgainstTheOpen = (
  payload: ToggleAllWallsAgainstTheOpenPayload
): ToggleAllWallsAgainstTheOpenAction => ({
  payload,
  type: RegistrationAction.TOGGLE_ALL_WALLS_AGAINST_THE_OPEN
});

// GENERIC ACTION FOR UPDATING NESTED VALUE
export const updateRegistrationField = (
  payload: UpdateRegistrationFieldPayload
): UpdateRegistrationFieldAction => ({
  payload,
  type: RegistrationAction.UPDATE_REGISTRATION_FIELD
});

// GENERIC ACTION FOR UPDATING USER IN STORE
export const updateUser = (payload: UpdateUserPayload): UpdateUserAction => ({
  payload,
  type: RegistrationAction.UPDATE_USER
});

// UPDATE TOTAL CALCULATED AREA FOR A FLOOR
export const updateCalculatedArea = (
  payload: UpdateCalculatedAreaPayload
): UpdateCalculatedAreaAction => ({
  payload,
  type: RegistrationAction.UPDATE_CALCULATED_AREA
});

// UPDATE PARAMETERS
export const updateParameters = (
  payload: UpdateParametersPayload
): UpdateParametersAction => ({
  payload,
  type: RegistrationAction.UPDATE_PARAMETERS
});
