import React, { FC, Fragment } from 'react';
import { FullScreenLoader, Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import useExpertToolsXMLPortfolio from '../useExpertToolsXMLPortfolio';
import { PortefoljeTiltakSection } from '../../../../components/tiltakSection';

const Measures: FC = () => {
  const { t } = useTranslation();

  const { tiltakList, portefoljeId, loadingStep } =
    useExpertToolsXMLPortfolio();

  if (!portefoljeId) {
    return null;
  }

  return (
    <Fragment>
      <FullScreenLoader open={loadingStep} />
      <Grid item xs={12} container spacing={4} direction="column">
        <Grid item>
          <Typography variant="h2" gutterBottom>
            {t('expertToolsXMLPortfolio.worklist.measures.heading')}
          </Typography>

          <Typography>
            {t('expertToolsXMLPortfolio.worklist.measures.description')}
          </Typography>
        </Grid>

        <PortefoljeTiltakSection
          portefoljeId={portefoljeId}
          tiltakList={tiltakList}
        />
      </Grid>
    </Fragment>
  );
};

export default Measures;
