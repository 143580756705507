import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Radio, RadioGroup } from 'enova-frontend-components';

import {
  EgendefinertTiltak,
  TiltakKategori
} from '../../../types/registration/tiltak';
import { camelCasify } from '../../../utils/navigation';

import { useTiltakFormContext } from './utils';

interface CategoryRadioProps {
  isLoading: boolean;
  categories: TiltakKategori[];
}

export const CategoryRadioGroup: FC<CategoryRadioProps> = ({
  categories,
  isLoading
}) => {
  const { t } = useTranslation();

  const { control, setValue } = useTiltakFormContext();

  return (
    <Controller
      control={control}
      name="category"
      render={({ field: { onChange, ...rest } }) => (
        <RadioGroup
          {...rest}
          onChange={(e) => {
            onChange(e);

            // Selection of category 'Egendefinert' sets name and disables type selection
            if (e.currentTarget.value === TiltakKategori.Egendefinert) {
              setValue('name', EgendefinertTiltak.Egendefinert);
              return;
            }
            // Reset name value in all other cases
            setValue('name', -1);
          }}
          label={t('expertToolsXML.measures.newMeasure.category.label')}
          required
          row
          disabled={isLoading}
        >
          {categories.map((category) => (
            <Radio
              key={category}
              label={t(`tiltak.category.${camelCasify(category)}`)}
              width="auto"
              value={category}
            />
          ))}
        </RadioGroup>
      )}
    />
  );
};
