import { Link } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getCreateBuildingPath } from '../../createBuilding/utils';
import {
  EnergiObjektType,
  GetEnergiObjektTypeFromMatrikkel
} from '../../../utils/matrikkelBygningsTyper';
import { Bygningstype } from '../../createBuilding/types';
import { DetailedBuildingDetails } from '../../../types/building';

interface Props {
  building: DetailedBuildingDetails;
}

export const CreateTempBuildingLink: FC<Props> = ({ building }) => {
  const { t } = useTranslation();

  const bygningstype =
    GetEnergiObjektTypeFromMatrikkel(building.kanEnergimerkesAvPrivatPerson) ===
    EnergiObjektType.PrivatBolig
      ? Bygningstype.BoligBygg
      : Bygningstype.YrkesBygg;

  const organisasjonsnummer = {
    label: building.borettslagNavn,
    value: building.borettslagNummer
  };

  const url = getCreateBuildingPath({
    gatenavn: building.adresse.gateNavn,
    gardsnummer: building.gardsNummer,
    bruksnummer: building.bruksNummer,
    gatenummer: building.adresse.nummer,
    postnummer: building.adresse.postNummer,
    seksjonsnummer: building.seksjonsNummer,
    bygningsnummer: building.bygningsNummer,
    festenummer: building.festeNummer,
    organisasjonsnummer,
    bygningstype
  });

  return (
    <Link href={url}>
      {t('buildingScreen.tab.omBoligen.createTempBuilding')}
    </Link>
  );
};
