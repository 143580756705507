import { Trans } from 'react-i18next';
import { Box, Link } from 'enova-frontend-components';
import React from 'react';

import { DetailingStep } from '../../../utils/registerEnums';
import useRegistrationForm from '../../../../../screens/registration/useRegistrationForm';

export const WallsEmptyState = () => {
  const { goToStep } = useRegistrationForm();

  return (
    <Box m={3}>
      <Trans
        i18nKey={'register.wallZOne.emptyState.description'}
        components={[
          <Link
            key="walls-empty-state-link"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              goToStep(DetailingStep.Bygningsform);
            }}
          />
        ]}
      />
    </Box>
  );
};
