import { ApiResponse } from 'apisauce';

const LocalStorageKey = 'EMS_TRACE_LOG';
const maxLogItems = 100;
export const logError = (functionName: string, response: ApiResponse<any>) => {
  try {
    if (response && !response.ok) {
      var items = readFromLocalStorage();
      if (items.length >= maxLogItems) {
        items = items.slice(-maxLogItems);
      }
      var traceId = '';
      if (response.headers) {
        traceId = response.headers['x-correlation-id'];
      }
      if (traceId) {
        items.push({
          time: new Date(),
          method: functionName,
          traceInformation:
            traceId +
            ". Url: '" +
            (response.config?.baseURL + '/' + response.config?.url ??
              'Ukjent') +
            "'",
          traceId:traceId
        });
      } else {
        items.push({
          time: new Date(),
          method: functionName,
          traceInformation:
            "Klient feil. Url: '" +
            (response.config?.baseURL + '/' + response.config?.url ??
              'Ukjent') +
            "'",
        });
      }
      localStorage.setItem(LocalStorageKey, JSON.stringify(items));
    }
  } catch (error) {
    console.error('Error while logging error', error);
  }
};

export const logNetworkError = (
  functionName: string,
  response: ApiResponse<any>
) => {
  try {
    var items = readFromLocalStorage();
    if (items.length >= maxLogItems) {
      items = items.slice(-maxLogItems);
    }
    items.push({
      time: new Date(),
      method: functionName,
      traceInformation:
        "Klient feil. Url: '" +
        (response.config?.baseURL + '/' + response.config?.url ?? 'Ukjent') +
        "'",
    });
    localStorage.setItem(LocalStorageKey, JSON.stringify(items));
  } catch (error) {
    console.error('Error while logging error', error);
  }
};

export const readFromLocalStorage = (): TraceLogItem[] => {
  const storedData = localStorage.getItem(LocalStorageKey);
  if (storedData) {
    try {
      const parsedData: TraceLogItem[] = JSON.parse(storedData);
      return parsedData;
    } catch (error) {
      //Clear storrage to try to self heal if  data is corrupted
      localStorage.setItem(LocalStorageKey, '');
      console.error('Error parsing data from localStorage:', error);
    }
  }
  return [];
};
export const formatTraceLogId = (item:TraceLogItem) => {
  try
  {
    if(item.traceId){
      return "EMS-" + item.traceId.substring(28, 32);
    }else{
      return "EMS-Nettverksfeil" 
    }
  } catch (error) {
    //Should not happen, but use this value to avoid issues.
    return "EMS-Ukjent";
  }
}
export interface TraceLogItem {
  time: Date;
  method: string;
  traceInformation: string;
  traceId?: string;
}
