import { Box, Grid, Link, Paper, Typography } from 'enova-frontend-components';
import { Trans, useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import { AddBuilding } from './addBuilding';

export const NoSearchResult = () => {
  const { t } = useTranslation();
  const [isOpen, setDialogOpen] = useState(false);
  const searchString = new URLSearchParams(location.search).get('q');
  const kartverketUrl = `https://seeiendom.kartverket.no/?search=${searchString}`;

  return (
    <Grid xs={12} item display="flex">
      <Paper outlined={true}>
        <Box margin={3}>
          <Typography variant="h4">{t('search.no_result')}</Typography>
          <Trans
            i18nKey="search.no_result.content"
            components={{
              li: <li />,
              ul: <ul />,
              KartverketLink: <Link targetBlank={true} href={kartverketUrl} />,
              AddBuildingLink: (
                <Link button onClick={() => setDialogOpen(true)} />
              )
            }}
          />
        </Box>
      </Paper>
      <AddBuilding isOpen={isOpen} onCancel={() => setDialogOpen(false)} />
    </Grid>
  );
};
