import { Select } from 'enova-frontend-components';
import React, { FC } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useKarmAndGlassTypeOptions } from '../useKarmAndGlassTypeOptions';
import { WindowFormFieldProps } from '../utils';

export const WindowGlassSelect: FC<WindowFormFieldProps> = ({ control }) => {
  const { t } = useTranslation();

  const karmType = useWatch({
    control,
    name: `windowData.window.karmType`
  });

  const { glassTypeOptions } = useKarmAndGlassTypeOptions(karmType);

  return (
    <Controller
      control={control}
      rules={{
        validate: (value, formValues) => {
          if (formValues.detailing !== 'windowType') {
            return;
          }

          return !!value || t('required');
        }
      }}
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error }
      }) => (
        <Select
          {...rest}
          error={!!error}
          helperText={error?.message}
          disabled={!karmType}
          label={t('register.window.glassType.label')}
          onChange={(e) => onChange(e?.currentTarget?.value)}
          value={value ?? -1}
          options={glassTypeOptions}
          required
        />
      )}
      name="windowData.window.glassType"
    />
  );
};
