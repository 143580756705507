import {
  Box,
  Grid,
  styled,
  TextField,
  useSnack
} from 'enova-frontend-components';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { useMediaQuery } from '../../hooks/useMediaQuery';
import { createErrorMessage } from '../../utils/utils';

import { useCreateBuilding } from './useCreateBuildling';
import {
  getFormSubmitRedirectUrl,
  useCreateBuildingDefaultValues,
  useCreateBuildingFormContext,
  validateStringAsNumber
} from './utils';
import {
  Andelsnummer,
  BuildingType,
  ErrorMessage,
  GardsAndBruksNummer,
  HousingAssociationOrCompany,
  PostalAddress,
  Terms
} from './components';
import { SubmitButtons } from './components/submitButtons';
import { AddNewBuilding } from './components/addNewBuilding';
import { BruksenhetsNummer } from './components/bruksenhetsnumer';
import { GateBokstav } from './components/gateBokstav';
import {
  ExistingBuildingDialog,
  ExistingBuildingDialogProps
} from './existingBuildingDialog';
import { CreateBuildingData } from './types';

const FormWrapper = styled(Box)`
  background-color: white;
  border-radius: 5px;
  border: 1px solid #c1c1c1;
  padding: ${({ theme }) => theme.spacing(4)};
`;

export const CreateBuildingForm: FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useCreateBuildingFormContext();

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { xsScreen } = useMediaQuery();
  const { addSnack } = useSnack();
  const createBuildingDefaultValues = useCreateBuildingDefaultValues();

  const [existingBuildingDialogProps, setExistingBuildingDialogProps] =
    useState<Partial<ExistingBuildingDialogProps> | null>();

  const { mutate, isLoading } = useCreateBuilding();

  useEffect(() => {
    reset(createBuildingDefaultValues);
    window.scroll(0, 0);
  }, [location]);

  const navigateToBuilding = (
    buildingData?: CreateBuildingData,
    responseData?: string
  ) => {
    if (!buildingData || !responseData) {
      return;
    }
    history.push(getFormSubmitRedirectUrl(buildingData, responseData));
  };

  const onSubmit = handleSubmit((data) => {
    mutate(data, {
      onSuccess: (response) => {
        // Show dialog if building already exists (Status = 200)
        if (response.status == 200) {
          setExistingBuildingDialogProps({
            buildingData: data,
            responseData: response.data
          });
          return;
        }

        addSnack(t('building.create.success'), { variant: 'success' });
        navigateToBuilding(data, response.data);
      },
      onError: () => {
        addSnack(createErrorMessage(t('building.create.error.submit')), {
          variant: 'error'
        });
      }
    });
  });

  const helperBoxLabel = t('readFieldInformation');

  return (
    <FormWrapper>
      <form onSubmit={onSubmit} noValidate>
        <Grid container rowGap={3} maxWidth={500}>
          <BuildingType disabled={isLoading} />

          <TextField
            disabled={isLoading}
            label={t('building.create.streetName')}
            fullWidth
            error={!!errors?.gatenavn}
            helperText={errors?.gatenavn?.message}
            {...register('gatenavn')}
          />

          <Grid container item columnSpacing={3}>
            <Grid item xs={12} sm={5}>
              <TextField
                disabled={isLoading}
                label={t('building.create.streetNumber')}
                fullWidth={xsScreen}
                error={!!errors?.gatenummer}
                helperText={errors?.gatenummer?.message}
                {...register('gatenummer', {
                  validate: {
                    invalid: (val) =>
                      validateStringAsNumber(val) || (t('invalid') as string)
                  }
                })}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <GateBokstav disabled={isLoading} />
            </Grid>
          </Grid>

          <PostalAddress disabled={isLoading} />

          <TextField
            disabled
            label={t('building.create.municipality')}
            fullWidth
            required
            error={!!errors?.kommunenavn}
            helperText={errors?.kommunenavn?.message}
            {...register('kommunenavn', { required: t('required') as string })}
          />

          <GardsAndBruksNummer disabled={isLoading} />

          <TextField
            disabled={isLoading}
            HelperBoxProps={{
              'aria-label': helperBoxLabel,
              children: t('building.create.buildingNumber.helperText')
            }}
            label={t('building.create.buildingNumber')}
            error={!!errors?.bygningsnummer}
            helperText={errors?.bygningsnummer?.message}
            {...register('bygningsnummer', {
              validate: {
                invalid: (val) =>
                  validateStringAsNumber(val) || (t('invalid') as string)
              }
            })}
            fullWidth
          />

          <BruksenhetsNummer disabled={isLoading} />

          <TextField
            disabled={isLoading}
            HelperBoxProps={{
              'aria-label': helperBoxLabel,
              children: t('building.create.sectionNumber.helperText')
            }}
            label={t('building.create.sectionNumber')}
            fullWidth
            error={!!errors?.seksjonsnummer}
            helperText={errors?.seksjonsnummer?.message}
            {...register('seksjonsnummer', {
              validate: {
                invalid: (val) =>
                  validateStringAsNumber(val) || (t('invalid') as string)
              }
            })}
          />

          <TextField
            disabled={isLoading}
            HelperBoxProps={{
              'aria-label': helperBoxLabel,
              children: t('building.create.festenummer.helperText')
            }}
            label={t('building.create.festenummer')}
            fullWidth
            error={!!errors?.festenummer}
            helperText={errors?.festenummer?.message}
            {...register('festenummer', {
              validate: {
                invalid: (val) =>
                  validateStringAsNumber(val) || (t('invalid') as string)
              }
            })}
          />

          <HousingAssociationOrCompany disabled={isLoading} />
          <Andelsnummer disabled={isLoading} />
          <Terms disabled={isLoading} />
          <AddNewBuilding disabled={isLoading} />
          <SubmitButtons
            errors={errors}
            isLoading={isLoading}
            onCancel={() => history.goBack()}
          />
        </Grid>
        <ErrorMessage errors={errors} />
      </form>
      <ExistingBuildingDialog
        {...existingBuildingDialogProps}
        onCancel={() => setExistingBuildingDialogProps(null)}
        onConfirm={navigateToBuilding}
      />
    </FormWrapper>
  );
};
