import { useLocation } from 'react-router-dom';

import { BuildingSearchFormData } from '../types';

export const useBuildingSearchDefaultValues = () => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const defaultValues: Omit<BuildingSearchFormData, 'kommunenavn'> = {
    bruksnummer: search.get('bruksnummer') ?? '',
    gardsnummer: search.get('gardsnummer') ?? '',
    kommunenummer: search.get('kommunenummer') ?? '',
    seksjonsnummer: search.get('seksjonsnummer') ?? '',
    kommuneOptions: [],
    bruksenhetsnummer: search.get('bruksenhetsnummer') ?? '',
    gatenavn: search.get('gatenavn') ?? '',
    bokstav: search.get('bokstav') ?? '',
    nummer: search.get('nummer') ?? '',
    bygningsnummer: search.get('bygningsnummer') ?? '',
    festenummer: search.get('festenummer') ?? ''
  };

  return { defaultValues };
};
