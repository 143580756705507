import React, { FC } from 'react';
import {
  CollapsibleFactBox,
  Grid,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import ReadonlyField from '../../../../../../components/readonlyField';
import { getTak } from '../../../../../../store/registration/selectors';
import useSelector from '../../../../../../hooks/useSelector';
import { Window } from '../../../../../../components/registering/window-section/window';

const RoofSummarySection: FC = () => {
  const { t } = useTranslation();

  const roof = useSelector(getTak, shallowEqual);

  const { isolasjonstykkelseIMillimeter, takKonstruksjonsType, vinduer } =
    roof || {};

  if (
    !roof ||
    (!isolasjonstykkelseIMillimeter &&
      !takKonstruksjonsType &&
      (!vinduer || vinduer.length === 0))
  ) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <CollapsibleFactBox title={t('register.roof.title')}>
        <Grid container spacing={4}>
          {takKonstruksjonsType && (
            <Grid item xs={12}>
              <ReadonlyField
                label={t('register.roof.constructionType.label')}
                value={t(
                  `register.roof.constructionType.option.${takKonstruksjonsType}`
                )}
              />
            </Grid>
          )}

          {isolasjonstykkelseIMillimeter != null && (
            <Grid item xs={12}>
              <ReadonlyField
                label={t('register.isolation.label')}
                value={`${isolasjonstykkelseIMillimeter} ${t('suffix.mm')}`}
              />
            </Grid>
          )}

          {vinduer && vinduer.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="h3" component="h4" mb={2}>
                {t('register.roof.windows.title')}
              </Typography>

              <Grid container spacing={4}>
                {vinduer.map((vindu) => (
                  <Grid item xs={12} key={vindu.id}>
                    <Window window={vindu} type="roof" />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </CollapsibleFactBox>
    </Grid>
  );
};

export default RoofSummarySection;
