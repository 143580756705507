import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, RadioGroup } from 'enova-frontend-components';

interface Props {
  onChange: (value: boolean) => void;
  value: boolean;
}
export const SameConstructionAllFloorsRadio: FC<Props> = ({
  value,
  onChange
}) => {
  const { t } = useTranslation();

  return (
    <RadioGroup
      row
      marginBottom
      value={value}
      onChange={(_, val) => onChange(val === 'true')}
      label={t(
        'register.wallsAndIsolation.allFloorsHaveSameWallConstruction.label'
      )}
    >
      <Radio label={t('yes')} value={true} width="auto" />
      <Radio label={t('no')} value={false} width="auto" />
    </RadioGroup>
  );
};
