import React, { FC, Fragment } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import {
  MainContentBox,
  useEnovaContext,
  useMediaQuery
} from 'enova-frontend-components';

import {
  camelCasify,
  getStatisticsScreenPath,
  StatisticsScreenParams
} from '../../utils/navigation';

import { StatisticsScreenViews } from './utils';
import StatisticsOverview from './overview';
import StatisticsOverTimeView from './views/statisticsOverTime';
import StatisticsBuildingTypeView from './views/statisticsBuildingType';
import StatisticsEnergikarakterView from './views/statisticsEnergikarakter';

const StatisticsScreen: FC = () => {
  const { t } = useTranslation();
  const { view } = useParams<StatisticsScreenParams>();
  const { theme } = useEnovaContext();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const helmetTitle = [
    ...(view ? [t(`statisticsScreen.${camelCasify(view)}.title`)] : []),
    t('statistics')
  ].join(' | ');

  return (
    <Fragment>
      <Helmet title={helmetTitle} />

      <MainContentBox maxWidth={lgUp ? '90vw' : 'default'}>
        <Switch>
          <Route
            component={StatisticsOverTimeView}
            exact
            path={getStatisticsScreenPath({
              view: StatisticsScreenViews.OVER_TIME
            })}
          />

          <Route
            component={StatisticsBuildingTypeView}
            exact
            path={getStatisticsScreenPath({
              view: StatisticsScreenViews.BUILDING_TYPE
            })}
          />

          <Route
            component={StatisticsEnergikarakterView}
            exact
            path={getStatisticsScreenPath({
              view: StatisticsScreenViews.ENERGY_GRADE
            })}
          />

          <StatisticsOverview />
        </Switch>
      </MainContentBox>
    </Fragment>
  );
};

export default StatisticsScreen;
