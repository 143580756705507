import {
  useEnovaContext,
  useMediaQuery as useEFCMediaQuery
} from 'enova-frontend-components';

type ScreenSizes = {
  xsScreen: boolean;
  smScreen: boolean;
  mdScreen: boolean;
  lgScreen: boolean;
  xlScreen: boolean;
};

export const useMediaQuery = (): ScreenSizes => {
  const { theme } = useEnovaContext();

  return {
    xsScreen: useEFCMediaQuery(theme.breakpoints.only('xs')),
    smScreen: useEFCMediaQuery(theme.breakpoints.only('sm')),
    mdScreen: useEFCMediaQuery(theme.breakpoints.only('md')),
    lgScreen: useEFCMediaQuery(theme.breakpoints.only('lg')),
    xlScreen: useEFCMediaQuery(theme.breakpoints.only('xl'))
  };
};
