/* eslint-disable max-len */
import React, { FC } from 'react';
import { Grid, RadioButton, RadioGroup } from 'enova-frontend-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  ReactComponent as FlattTak,
} from '../../../../assets/icons/roof/flatt-tak.svg';
import {
  ReactComponent as FlattTakInverted,
} from '../../../../assets/icons/roof/flatt-tak-inverted.svg';
import {
  ReactComponent as SkraatakOppvarmet,
} from '../../../../assets/icons/roof/skraatak-oppvarmet.svg';
import {
  ReactComponent as SkraatakOppvarmetInverted,
} from '../../../../assets/icons/roof/skraatak-oppvarmet-inverted.svg';
import {
  ReactComponent as SkraatakUoppvarmet,
} from '../../../../assets/icons/roof/skraatak-uoppvarmet.svg';
import {
  ReactComponent as SkraatakUoppvarmetInverted,
} from '../../../../assets/icons/roof/skraatak-uoppvarmet-inverted.svg';
import {
  ReactComponent as SkraatakUtenLoft,
} from '../../../../assets/icons/roof/skraatak-uten-loft.svg';
import {
  ReactComponent as SkraatakUtenLoftInverted,
} from '../../../../assets/icons/roof/skraatak-uten-loft-inverted.svg';
import { updateTak } from '../../../../store/registration/actions';
import { TakKonstruksjonsType } from '../../utils/registerEnums';

const roofConstr = [
  {
    icon: SkraatakOppvarmet,
    invertedIcon: SkraatakOppvarmetInverted,
    takKonstruksjonsType: TakKonstruksjonsType.SkratakMedOppvarmetLoft
  },
  {
    icon: SkraatakUoppvarmet,
    invertedIcon: SkraatakUoppvarmetInverted,
    takKonstruksjonsType: TakKonstruksjonsType.SkratakMedUoppvarmetLoft
  },
  {
    icon: SkraatakUtenLoft,
    invertedIcon: SkraatakUtenLoftInverted,
    takKonstruksjonsType: TakKonstruksjonsType.SkratakUtenLoft
  },
  {
    icon: FlattTak,
    invertedIcon: FlattTakInverted,
    takKonstruksjonsType: TakKonstruksjonsType.FlattTak
  }
];

interface RoofConstructionProps {
  roofType: TakKonstruksjonsType | undefined;
  error: boolean;
}

export const RoofConstruction: FC<RoofConstructionProps> = ({
  error,
  roofType
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Grid item>
      <RadioGroup
        aria-label={t('register.roof.constructionType.label')}
        row
        value={roofType ?? -1}
        onChange={(_, value) =>
          dispatch(
            updateTak({
              field: 'takKonstruksjonsType',
              value: value
            })
          )
        }
      >
        <Grid container spacing={2}>
          {roofConstr.map(
            ({
              icon: Icon,
              invertedIcon: InvertedIcon,
              takKonstruksjonsType
            }) => (
              <Grid item xs={12} sm={6} lg={4} key={takKonstruksjonsType}>
                <RadioButton
                  icon={<Icon />}
                  invertedIcon={<InvertedIcon />}
                  label={t(
                    `register.roof.constructionType.option.${takKonstruksjonsType}`
                  )}
                  value={takKonstruksjonsType}
                  error={error}
                  width="fullWidth"
                />
              </Grid>
            )
          )}
        </Grid>
      </RadioGroup>
    </Grid>
  );
};
