import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { createErrorMessage } from '../../utils/utils';
import { useUser } from '../../hooks/useUser';
import { AreaOfExpertise } from '../../types/user';

import { ProfileFormData } from './types';

export const useUpdateUser = () => {
  const { updateUser, user, isUpdateUserLoading } = useUser();
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const update = (formData: ProfileFormData) => {
    if (!user) {
      return;
    }
    const fagomrader: AreaOfExpertise[] = [];

    if (formData.bygningsekspert) {
      fagomrader.push(AreaOfExpertise.BYGNINGSEKSPERT);
    }

    if (formData.energiradgiver) {
      fagomrader.push(AreaOfExpertise.ENERGIRADGIVER);
    }

    if (formData.kjelOgVarmeanlegg) {
      fagomrader.push(AreaOfExpertise.KJELOGVARMEANLEGG);
    }

    if (formData.ventilasjonsOgKjoleanlegg) {
      fagomrader.push(AreaOfExpertise.VENTILASJONOGKJOLEANLEGG);
    }

    const updatedUser = {
      ...user,
      rolle: formData.rolle,
      fagomrader
    };

    updateUser(updatedUser, {
      onSuccess: () => {
        addSnack(t('profileScreen.saveSuccess'), {
          variant: 'success'
        });
      },
      onError: () => {
        addSnack(createErrorMessage(t('profileScreen.saveError')), {
          variant: 'error'
        });
      }
    });
  };

  return {
    updateUser: update,
    isLoading: isUpdateUserLoading
  };
};
