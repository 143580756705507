import React, { FC, Fragment } from 'react';
import { FactBox, Grid } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { camelCasify } from '../../utils/navigation';
import { Tiltak, TiltakMap } from '../../types/registration/tiltak';

import ExistingTiltak from './tiltak';
import { TiltakMutation } from './types';

type ExistingTiltakListProps = {
  deleteTiltakMutation: TiltakMutation;
  updateTiltakMutation: TiltakMutation;
  tiltakList: Tiltak[];
};

const TiltakList: FC<ExistingTiltakListProps> = ({
  tiltakList,
  updateTiltakMutation,
  deleteTiltakMutation
}) => {
  const { t } = useTranslation();

  if (!tiltakList || tiltakList.length === 0) {
    return null;
  }

  const tiltakMap = tiltakList?.reduce(
    (acc: TiltakMap, tiltak) => ({
      ...acc,
      [tiltak.tiltaksKategori]: [...(acc[tiltak.tiltaksKategori] || []), tiltak]
    }),
    {}
  );

  return (
    <Fragment>
      {Object.entries(tiltakMap).map(([kategori, tiltakIKategori]) => (
        <Grid item key={kategori}>
          <FactBox
            title={t(`tiltak.category.${camelCasify(kategori)}`)}
            TitleProps={{ component: 'h4' }}
          >
            <Grid container spacing={6} direction="column">
              {tiltakIKategori.map((tiltak, index) => (
                <ExistingTiltak
                  key={tiltak.tiltakId}
                  tiltak={tiltak}
                  tiltakIndex={index}
                  updateTiltakMutation={updateTiltakMutation}
                  deleteTiltakMutation={deleteTiltakMutation}
                />
              ))}
            </Grid>
          </FactBox>
        </Grid>
      ))}
    </Fragment>
  );
};

export default TiltakList;
