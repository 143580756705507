import React, { FC, Fragment } from 'react';
import { Box, Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  AppRoute,
  getSoknadScreenPath,
  hasPermission,
  SoknadScreenParams
} from '../../../../utils/navigation';
import { useUser } from '../../../../hooks/useUser';
import useBuildingDetails from '../../../../hooks/useBuildingDetails';
import RouterButtonLink from '../../../../components/routerButtonLink';
import EnovaApplicationList from '../../../../components/enovaApplicationList';
// eslint-disable-next-line max-len
import EnovaApplicationQualifications from '../../../registration/components/summary/tabs/calculatedEnergyRating/enovaApplicationQualifications';
import { LoadingIndicator } from '../../../../components/loadingIndicator';
import { SoknadSteg } from '../../../../components/registering/utils/registerEnums';
import { DetailedBuildingDetails } from '../../../../types/building';
import { SoknadStegMap } from '../../../../types/soknad';

import { CreateApplicationLink, EnergyAnalysisError } from './components';
import { DeleteApplication } from './deleteApplicationDialog';

const getCurrentSoknadSteg = (building: DetailedBuildingDetails) => {
  if (building.gyldigSoknad?.registreringForSteg3) {
    return SoknadSteg.Steg3;
  }

  if (building.gyldigSoknad?.registreringForSteg2) {
    return SoknadSteg.Steg2;
  }

  if (building.gyldigSoknad?.registreringForSteg1) {
    return SoknadSteg.Steg1;
  }

  return undefined;
};

interface Props {
  building: DetailedBuildingDetails;
  nextStage?: SoknadSteg;
  soknadStegMap?: SoknadStegMap;
}

export const DocumentsForEnovaApplication: FC<Props> = ({
  building,
  nextStage,
  soknadStegMap
}) => {
  const { buildingId } = useParams<SoknadScreenParams>();
  const { t } = useTranslation();
  const { user } = useUser();

  const {
    gyldigSoknad,
    bygningsTypeNummer,
    isHovedbygg,
    kanEnergimerkesAvPrivatPerson
  } = building;

  const isOwner = !!gyldigSoknad?.isOwner;
  const soknadId = gyldigSoknad?.soknadId;

  const currentSoknadSteg = getCurrentSoknadSteg(building);
  const isEnergiradgiver = hasPermission(AppRoute.SOKNAD, user);

  const hasDocumentAccess =
    kanEnergimerkesAvPrivatPerson &&
    (hasPermission(AppRoute.SOKNAD, user) ||
      gyldigSoknad?.registreringForSteg1);

  const isFinalStepPublished =
    soknadStegMap && soknadStegMap[SoknadSteg.Steg3]?.done;

  return (
    <Fragment>
      <Box mt={4}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h2">
              {t('documentsForEnovaApplication.title')}
            </Typography>

            <Typography>
              {t('documentsForEnovaApplication.description', {
                context:
                  !soknadId && (hasDocumentAccess ? 'expert_empty' : 'empty')
              })}
            </Typography>
          </Grid>

          {!soknadId && hasDocumentAccess && (
            <CreateApplicationLink
              bygningsTypeNummer={bygningsTypeNummer}
              isHovedbygg={isHovedbygg}
              soknadSteg={nextStage}
            />
          )}

          {soknadId && (
            <Fragment>
              <EnovaApplicationList
                displayActionButtons={isEnergiradgiver && isOwner}
              />

              <Grid
                container
                spacing={4}
                pt={isEnergiradgiver ? 8 : 2}
                pb={4}
                pl={4}
              >
                {currentSoknadSteg && soknadId && (
                  <EnovaApplicationQualifications
                    soknadSteg={currentSoknadSteg}
                    soknadId={soknadId}
                  />
                )}
              </Grid>

              {isEnergiradgiver && isOwner && !isFinalStepPublished && (
                <Grid item xs={12} sm="auto" mr={{ sm: '100%' }}>
                  <DeleteApplication soknadId={soknadId} />
                </Grid>
              )}

              {nextStage && isEnergiradgiver && isOwner && (
                <Grid item xs={12} sm="auto">
                  <RouterButtonLink
                    fullWidth
                    to={getSoknadScreenPath({
                      buildingId,
                      soknadId: soknadId,
                      soknadSteg: nextStage
                    })}
                  >
                    {t('documentsForEnovaApplication.create', {
                      context: nextStage
                    })}
                  </RouterButtonLink>
                </Grid>
              )}
            </Fragment>
          )}
        </Grid>
      </Box>
    </Fragment>
  );
};

const DocumentsForEnovaApplicationWrapper: FC = () => {
  const {
    building,
    simpleEnergyAnalysesLoading,
    simpleEnergyAnalysesError,
    getNextSoknadSteg,
    refreshEnergyAnalysis,
    soknadStegMap
  } = useBuildingDetails();

  if (simpleEnergyAnalysesLoading) {
    return <LoadingIndicator />;
  }

  if (simpleEnergyAnalysesError) {
    return <EnergyAnalysisError onClick={refreshEnergyAnalysis} />;
  }

  return (
    <DocumentsForEnovaApplication
      building={building}
      soknadStegMap={soknadStegMap}
      nextStage={getNextSoknadSteg()}
    />
  );
};

export default DocumentsForEnovaApplicationWrapper;
