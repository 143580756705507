import { Box, styled } from 'enova-frontend-components';
import React, { FC, useState } from 'react';

import { BuildingShape } from '../../shapeAndArea/components/buildingShape';
import { BygningsForm, Himmelretning } from '../../../utils/registerEnums';
import { Vegg } from '../../../../../types/registration/userInput';
import { HighlightedShapeWrapper } from '../../../../highlightedShapeWrapper';

import { UniformWallRadio } from './uniformWallRadio';
import { WallZones } from './wallZones';
import { AddWallZone } from './addWallZone';

const WallWrapper = styled(Box)`
  background-color: #eef9f5;
  margin-bottom: 0;
  padding-bottom: 20px;
`;

interface WallProps {
  shape?: BygningsForm;
  wall: Vegg;
  etasjeId: string;
}

export const Wall: FC<WallProps> = ({ shape, wall, etasjeId }) => {
  const [isUniformWall, setUniformWall] = useState<boolean>(
    wall.veggSoner.length < 2
  );

  const showAddNewZoneButton = wall.veggSoner.length === 0 || !isUniformWall;

  // Wall without zones should display add wall zone form by default
  const displayAddForm = wall.veggSoner.length === 0 && isUniformWall;

  return (
    <WallWrapper mb={5}>
      <HighlightedShapeWrapper highlightedWallId={wall.veggId}>
        <BuildingShape shape={shape} direction={Himmelretning.Nord} />
      </HighlightedShapeWrapper>
      <Box display="flex" flexDirection="row" justifyContent="center">
        <UniformWallRadio value={isUniformWall} onSelect={setUniformWall} />
      </Box>
      <WallZones
        etasjeId={etasjeId}
        isUniformWall={isUniformWall}
        wall={wall}
      />
      {showAddNewZoneButton && (
        <AddWallZone
          etasjeId={etasjeId}
          wall={wall}
          isUniformWall={isUniformWall}
          isFormVisible={displayAddForm}
        />
      )}
    </WallWrapper>
  );
};
