import { createContext } from 'react';

import { fylkerNames } from '../../utils/kommuner';
import {
  BuildingCategoryFilters,
  ConstructionCodeFilters
} from '../../utils/filters';

// All category filters
export enum FilterCategory {
  CONSTRUCTION_CODE = 'constructionCode',
  BUILDING_CATEGORY = 'buildingCategory',
  FYLKER = 'geography'
}

export const CategoryOptions: Record<FilterCategory, string[]> = {
  [FilterCategory.CONSTRUCTION_CODE]: Object.values(ConstructionCodeFilters),
  [FilterCategory.BUILDING_CATEGORY]: Object.values(
    BuildingCategoryFilters
  ).filter((item) => item.toLowerCase() != 'annenbygning'),
  [FilterCategory.FYLKER]: fylkerNames
};

export type FilterValues = Record<FilterCategory, string[]>;

// All subcategory filters
export enum FilterSubcategory {
  KOMMUNER = 'kommune'
}

export const SubcategoryOptions: Record<
  FilterCategory,
  FilterSubcategory | undefined
> = {
  [FilterCategory.CONSTRUCTION_CODE]: undefined,
  [FilterCategory.BUILDING_CATEGORY]: undefined,
  [FilterCategory.FYLKER]: FilterSubcategory.KOMMUNER
};

export type SubcategoryFilterValues = Record<FilterSubcategory, string[]>;

export type StatisticsFilterContextValues = {
  categories?: FilterCategory[];
  subcategories?: FilterSubcategory[];
  mode?: FilterCategory;
  setMode: (mode?: FilterCategory) => void;
  clearAll: () => void;
  clearFilterCategory: (category: FilterCategory) => void;
  filterValues: FilterValues;
  subcategoryFilterValues: SubcategoryFilterValues;
  updateFilterValues: (
    category: FilterCategory,
    option: string,
    checked: boolean,
    subcategory?: FilterSubcategory
  ) => void;
  updateSubcategoryFilterValues: (
    subcategory: FilterSubcategory,
    subcategoryOption: string,
    checked: boolean
  ) => void;
  body: string;
};

const StatisticsFilterContext = createContext<StatisticsFilterContextValues>({
  setMode: () => true,
  clearAll: () => true,
  clearFilterCategory: () => true,
  filterValues: {
    [FilterCategory.CONSTRUCTION_CODE]: [],
    [FilterCategory.BUILDING_CATEGORY]: [],
    [FilterCategory.FYLKER]: []
  },
  subcategoryFilterValues: {
    [FilterSubcategory.KOMMUNER]: []
  },
  updateFilterValues: () => true,
  updateSubcategoryFilterValues: () => true,
  body: ''
});

export default StatisticsFilterContext;
