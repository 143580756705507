import { useTranslation } from 'react-i18next';
import { Box, EnergyRatingIndicator } from 'enova-frontend-components';
import React, { FC } from 'react';

import { SimpleEnergyMarking } from '../../../../../../../../types/building';

interface EnergyGradeCellProps {
  energimerke?: SimpleEnergyMarking;
}

export const EnergyGradeCell: FC<EnergyGradeCellProps> = ({ energimerke }) => {
  const { t } = useTranslation();

  const energikarakter = energimerke?.energiKarakter;
  const oppvarmingskarakter = energimerke?.properties?.oppvarmingKarakter;

  if (!energikarakter || !oppvarmingskarakter) {
    return (
      <Box display="flex" justifyContent="center">
        <EnergyRatingIndicator />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      title={`${t('energyLabel.heatingGrade', {
        context: oppvarmingskarakter
      })} ${energikarakter.toUpperCase()}`}
    >
      <EnergyRatingIndicator
        energyGrade={energikarakter}
        heatingGrade={oppvarmingskarakter}
      />
    </Box>
  );
};
