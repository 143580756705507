import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useDebounce } from 'rooks';
import { Autocomplete } from 'enova-frontend-components';

import { useKommuneSearch } from '../useKommuneSearch';
import { useBuildingSearchFormContext } from '../../types';

export const KommuneField = () => {
  const { t } = useTranslation();
  const [kommunenavn, setKommunenavn] = useState();

  const { control, getValues } = useBuildingSearchFormContext();

  const { isLoading } = useKommuneSearch(
    kommunenavn || getValues().kommunenummer?.toString() || ''
  );
  const options = useWatch({ control, name: 'kommuneOptions' });
  const setKommunenavnDebounced = useDebounce(setKommunenavn, 500);

  return (
    <Controller
      control={control}
      name="kommunenummer"
      rules={{
        validate: {
          required: (value) => {
            if (!value) {
              return t('search.advanced.error.kommune');
            }

            return true;
          }
        }
      }}
      render={({ field: { onChange, value, ...rest }, fieldState }) => {
        return (
          <Autocomplete
            value={value}
            label={t('search.advanced.field_kommunenavn')}
            onChange={(_, option) => {
              onChange(option?.value ?? '');
            }}
            onInputChange={(_, val, reason) => {
              if (reason === 'input') {
                setKommunenavnDebounced(val);
              }
            }}
            fullWidth
            error={!!fieldState.error}
            loading={isLoading}
            options={options}
            loadingText={t('search.advanced.kommune.loading')}
            noOptionsText={t('search.advanced.kommune.noOptions')}
            {...rest}
          />
        );
      }}
    />
  );
};
