import { useFormContext } from 'react-hook-form';

import { TiltakType } from '../../../types/registration/tiltak';
import { TiltakNameMap } from '../../../types/registration/tiltakNameMap';

import { TiltakFormData } from './types';

export const useTiltakFormContext = () => {
  return useFormContext<TiltakFormData>();
};

// Converts string value from database to key value
export const getTiltakTypeFromStringValue = (
  value?: string
): TiltakType | undefined => {
  if (!value) {
    return undefined;
  }

  for (const key in TiltakNameMap) {
    const nestedRecord = TiltakNameMap[key as TiltakType];
    const matchingEntry = Object.entries(nestedRecord).find(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      ([_, val]) => val === value
    );
    if (matchingEntry) {
      return key as TiltakType;
    }
  }
  return undefined;
};
