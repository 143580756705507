import React, { FC } from 'react';

import { FilterCategory } from '../../../../hooks/useStatisticsFilter/context';
import StatisticsFilterProvider from '../../../../hooks/useStatisticsFilter/provider';

import StatisticsOverTimeView from './statisticsEnergikarakterView';

const StatisticsEnergikarakter: FC = () => (
  <StatisticsFilterProvider
    categories={[FilterCategory.BUILDING_CATEGORY, FilterCategory.FYLKER]}
  >
    <StatisticsOverTimeView />
  </StatisticsFilterProvider>
);

export default StatisticsEnergikarakter;
