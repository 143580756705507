import { FC } from 'react';
import { FieldErrors } from 'react-hook-form';
import { Box, FormMessage, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { State } from './types';

interface ErrorMessageProps {
  errors: FieldErrors<State>;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ errors }) => {
  const { t } = useTranslation();

  if (Object.keys(errors).length === 0) {
    return null;
  }

  return (
    <Box mt={10}>
      <FormMessage fullWidth title={t('register.errorMessage.title')}>
        <Typography>{t('register.errorMessage.description')}</Typography>

        <ul aria-label={t('register.errorMessage.list')}>
          {Object.entries(errors).map(([field, error]) => {
            return (
              <li key={field} aria-label={error.message}>
                {t(error.message as string)}
              </li>
            );
          })}
        </ul>
      </FormMessage>
    </Box>
  );
};
