import React, { FC } from 'react';

import StatisticsFilterProvider from '../../../../hooks/useStatisticsFilter/provider';
import { FilterCategory } from '../../../../hooks/useStatisticsFilter/context';

import StatisticsOverTimeView from './statisticsOverTimeView';

const StatisticsOverTime: FC = () => (
  <StatisticsFilterProvider categories={[FilterCategory.FYLKER]}>
    <StatisticsOverTimeView />
  </StatisticsFilterProvider>
);

export default StatisticsOverTime;
