import { useMutation, useQueryClient } from 'react-query';

import { createTiltakEnergiplan } from '../../../services/api';
import { Tiltak } from '../../../types/registration/tiltak';
import { queryKeys } from '../../../utils/react-query';

export const useAddEnergiplanTiltak = (energiplanId: string | undefined) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (tiltak: Tiltak) => createTiltakEnergiplan({ ...tiltak, energiplanId }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          queryKeys.energyplanMeasures,
          energiplanId
        ])
    }
  );

  return { mutate, isLoading };
};
