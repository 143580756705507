import React, { FC } from 'react';
import { Box, Grid, RadioGroup } from 'enova-frontend-components';
import { Control, Controller } from 'react-hook-form';

import { FloorFormData } from '../../../types';
import { BygningsForm } from '../../../../../utils/registerEnums';
import { useFloorFormContext } from '../../../utils';
import { getWallsByShape } from '../../../hooks/utils';

import { ShapeRadioButton } from './shapeRadioButton';

interface Props {
  control: Control<FloorFormData>;
}

export const BuildingShapeSelector: FC<Props> = ({ control }) => {
  const { setValue } = useFloorFormContext();

  return (
    <Box my={8}>
      <Controller
        control={control}
        name="floor.bygningsForm"
        render={({ field: { value, onChange, ...rest } }) => (
          <RadioGroup
            {...rest}
            value={value ?? null}
            onChange={(_, value) => {
              const shape = value as BygningsForm;
              onChange(shape);
              // Selected shape decides which wall input fields to show
              setValue('floor.vegger', getWallsByShape(shape));
            }}
          >
            <Grid container gap={2} justifyContent="center">
              <ShapeRadioButton shape={BygningsForm.Rektangulaert} />
              <ShapeRadioButton shape={BygningsForm.TForm} />
              <ShapeRadioButton shape={BygningsForm.Atriumshus} />
              <ShapeRadioButton shape={BygningsForm.YForm} />
              <ShapeRadioButton shape={BygningsForm.LForm} />
              <ShapeRadioButton shape={BygningsForm.UForm} />
              <ShapeRadioButton shape={BygningsForm.XForm} />
              <ShapeRadioButton shape={BygningsForm.ZForm} />
              <ShapeRadioButton shape={BygningsForm.ZFormSpeilvendt} />
              <ShapeRadioButton shape={BygningsForm.LFormSpeilvendt} />
            </Grid>
          </RadioGroup>
        )}
      />
    </Box>
  );
};
