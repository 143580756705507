import React, { FC } from 'react';
import { Button, Grid, styled, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../hooks/useUser';
import { getName } from '../../../types/user';
import { useAuthentication } from '../../../hooks/useAuthentication';

const Name = styled(Typography)`
  text-transform: uppercase;
`;

const UserMenuCollapsed: FC = () => {
  const { user } = useUser();
  const { signIn, signOut, isAuthenticated } = useAuthentication();

  const { t } = useTranslation();

  if (!isAuthenticated) {
    return (
      <Button fullWidth onClick={() => signIn()} variant="secondary">
        {t('logIn')}
      </Button>
    );
  }

  return (
    <Grid container spacing={2} direction="column" mt={0}>
      {!!user && (
        <Grid item>
          <Name component="p" variant="h4" align="center">
            {getName(user)}
          </Name>
        </Grid>
      )}

      <Grid item>
        <Button fullWidth onClick={signOut} variant="secondary">
          {t('logOut')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserMenuCollapsed;
