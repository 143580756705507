import React, { FC, Fragment } from 'react';
import { Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { Tiltak } from '../../../types/registration/tiltak';
import useBuildingDetails from '../../../hooks/useBuildingDetails';
import { GetEnergiObjektTypeFromMatrikkel } from '../../../utils/matrikkelBygningsTyper';
import { AddTiltak } from '../addTiltak';
import TiltakList from '../tiltakList';

import { useAddTiltakPortefolje } from './useAddTiltakPortefolje';
import { useDeleteTiltakPortefolje } from './useDeleteTiltakPortefolje';
import { useUpdatePortefoljeTiltak } from './useUpdatePortefoljeTiltak';

type TiltakSectionProps = {
  portefoljeId: string;
  tiltakList?: Tiltak[];
};

const PortefoljeTiltakSection: FC<TiltakSectionProps> = ({
  portefoljeId,
  tiltakList
}) => {
  const { t } = useTranslation();
  const { building } = useBuildingDetails();
  const energiobjektType = GetEnergiObjektTypeFromMatrikkel(
    building.kanEnergimerkesAvPrivatPerson
  );

  const updateTiltakMutation = useUpdatePortefoljeTiltak(portefoljeId);
  const deleteTiltakMutation = useDeleteTiltakPortefolje(portefoljeId);

  const addTiltakPortefoljeMutation = useAddTiltakPortefolje(portefoljeId);
  const hasTiltak = tiltakList && tiltakList.length > 0;

  return (
    <Fragment>
      <Grid item>
        <AddTiltak
          addTiltakMutation={addTiltakPortefoljeMutation}
          energiobjektType={energiobjektType}
          allowEnergiobjektTypeOverride
          hasTiltak={hasTiltak}
        />
      </Grid>

      {hasTiltak && (
        <Fragment>
          <Grid item mt={4}>
            <Typography variant="h3">
              {t('tiltak.existingMeasures.heading', {
                context: energiobjektType
              })}
            </Typography>
          </Grid>

          <TiltakList
            tiltakList={tiltakList}
            updateTiltakMutation={updateTiltakMutation}
            deleteTiltakMutation={deleteTiltakMutation}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default PortefoljeTiltakSection;
