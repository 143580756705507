import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { CalculationParameterMap } from '../../../../types/calculationParameters';
import useRegistrationForm from '../../../../screens/registration/useRegistrationForm';

import { useUpdateParameters } from './useUpdateParameters';
import { State } from './utils';
import { Form } from './form';

interface AdjustParametersProps {
  beregningskjerneInput: CalculationParameterMap;
  parameters: CalculationParameterMap;
  defaultValues: State;
  registrationId: string | undefined;
}

export const AdjustParametersPage: FC<AdjustParametersProps> = ({
  registrationId,
  beregningskjerneInput,
  defaultValues,
  parameters
}) => {
  const { push } = useHistory();

  const { showErrorMessage, nextStep, generateRoute, prevStep } =
    useRegistrationForm();
  const { updateParameters, updateParametersIsLoading } =
    useUpdateParameters(registrationId);

  const onSubmit = (data: State) => {
    updateParameters(
      { inputData: beregningskjerneInput, state: data },
      {
        onSuccess: () => {
          if (nextStep) {
            push(generateRoute(nextStep));
          }
        },
        onError: () => showErrorMessage?.()
      }
    );
  };

  const goBack = (state: State) => {
    updateParameters(
      { inputData: beregningskjerneInput, state },
      {
        onSettled: () => {
          if (prevStep) {
            push(generateRoute(prevStep));
          }
        }
      }
    );
  };

  return (
    <Form
      parameters={parameters}
      defaultValues={defaultValues}
      isLoading={updateParametersIsLoading}
      onSubmit={onSubmit}
      goBack={goBack}
    />
  );
};
