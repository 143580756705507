import {
  Box,
  EnergyRatingIndicator,
  FactBox,
  Typography
} from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DownloadButton } from '../energyCertificate/components';
import useFormatDate from '../../../../../../hooks/useFormatDate';
import { EnergyPlan } from '../../../../../../types/building';

interface Props {
  energyPlan: EnergyPlan;
}

export const EnergyPlanListItem: FC<Props> = ({ energyPlan }) => {
  const { formatDate } = useFormatDate();
  const { t } = useTranslation();
  const energyGrade = energyPlan.energimerke?.energiKarakter;
  const heatingGrade = energyPlan.energimerke?.properties?.oppvarmingKarakter;

  if (!energyGrade || !heatingGrade) {
    return null;
  }

  const uri = energyPlan.attestUri;
  const date = formatDate(energyPlan.publisertDato);
  const title = t('buildingScreen.tab.energiattest.signedOn', {
    date
  });

  return (
    <FactBox title={title} sx={{ marginBottom: 4 }}>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Box p={2} pt={0}>
          <Typography pb={3} variant="h4">
            {t('energyRating')}
          </Typography>

          <EnergyRatingIndicator
            energyGrade={energyGrade}
            heatingGrade={heatingGrade}
          />
        </Box>

        <Box p={2} pr={4}>
          <DownloadButton attestUri={uri} />
        </Box>
      </Box>
    </FactBox>
  );
};
