import React, { FC, forwardRef } from 'react';
import { Link as RrdLink, LinkProps } from 'react-router-dom';
import {
  Button as EfcButton,
  ButtonProps as EfcButtonProps
} from 'enova-frontend-components';

export type RouterLinkProps = Omit<LinkProps, 'instance'> & EfcButtonProps;

// eslint-disable-next-line
const Link: FC<any> = forwardRef(({ navigate, ...rest }, ref) => (
  <RrdLink {...rest} ref={ref} />
));

const RouterButtonLink: FC<Omit<RouterLinkProps, 'component'>> = forwardRef<
  HTMLButtonElement,
  RouterLinkProps
>(({ onClick, ...rest }, ref) => (
  <EfcButton
    {...rest}
    {...{ component: Link }}
    onClick={(e) => {
      !e.ctrlKey && window.scrollTo(0, 0);
      onClick && onClick(e);
    }}
    ref={ref as React.RefObject<HTMLButtonElement>}
  />
));

RouterButtonLink.displayName = 'RouterButtonLink';

export default RouterButtonLink;
