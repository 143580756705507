import { useMutation, useQueryClient } from 'react-query';

import { deleteTiltakPortefolje } from '../../../services/api';
import { queryKeys } from '../../../utils/react-query';
import { Tiltak } from '../../../types/registration/tiltak';

export const useDeleteTiltakPortefolje = (portefoljeId: string | undefined) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    ({ portefoljeTiltakId }: Tiltak) =>
      deleteTiltakPortefolje(portefoljeTiltakId, portefoljeId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          queryKeys.portfolioMeasures,
          portefoljeId
        ])
    }
  );

  return {
    mutate,
    isLoading
  };
};
