import { useMutation } from 'react-query';

import { deleteGrupperegistrering } from '../../../../../services/api';
import { queryClient, queryKeys } from '../../../../../utils/react-query';

export const useDeleteFlerboligRegistration = () => {
  const { mutate, isLoading } = useMutation(
    (grupperegistreringId: string) =>
      deleteGrupperegistrering(grupperegistreringId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([queryKeys.userExpertRegistrations])
    }
  );
  return {
    isLoading: isLoading,
    deleteFlerboligRegistration: mutate
  };
};
