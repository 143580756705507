import { useMutation, useQueryClient } from 'react-query';

import * as api from '../../../services/api';
import { queryKeys } from '../../../utils/react-query';

export const useDeleteSoknad = (buildingId: string) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (soknadId: string | undefined) => api.deleteSoknad(soknadId),
    {
      onSuccess: () =>
        Promise.all([
          queryClient.invalidateQueries([queryKeys.building, buildingId]),
          queryClient.invalidateQueries([
            queryKeys.energyAnalysisList,
            buildingId
          ])
        ])
    }
  );

  return { deleteSoknad: mutate, isLoading };
};
