import { useLocation } from 'react-router-dom';

import { AdvancedSearchFormData } from '../types';

export const useAdvancedSearchDefaultValues = () => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const defaultValues: AdvancedSearchFormData = {
    bruksnummer: search.get('bruksnummer') ?? '',
    festenummer: search.get('festenummer') ?? '',
    gardsnummer: search.get('gardsnummer') ?? '',
    kommunenummer: search.get('kommunenummer') ?? '',
    seksjonsnummer: search.get('seksjonsnummer') ?? '',
    bygningsnummer: search.get('bygningsnummer') ?? '',
    postnummer: search.get('postnummer') ?? '',
    poststed: search.get('poststed') ?? '',
    kommuneOptions: [],
    bruksenhetsnummer: search.get('bruksenhetsnummer') ?? '',
  };

  return { defaultValues };
};
