import {
  Button,
  Form,
  Grid,
  Link,
  TextField,
  Typography,
  useSnack
} from 'enova-frontend-components';
import { Trans, useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { createPortefolje } from '../../../services/api';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { queryKeys } from '../../../utils/react-query';
import { createErrorMessage } from '../../../utils/utils';

export const CreateWorkListForm = () => {
  const { xsScreen } = useMediaQuery();
  const { t } = useTranslation();
  const { addSnack } = useSnack();
  const [name, setName] = useState('');
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (navn: string) => createPortefolje({ navn }),
    {
      onSuccess: () => queryClient.invalidateQueries([queryKeys.portfolioList])
    }
  );

  const addWorklistMutation = () => {
    if (!name) {
      return;
    }

    mutate(name, {
      onSuccess: () => {
        setName('');
      },
      onError: () => {
        addSnack(
          createErrorMessage(
            t('expertToolsXMLPortfolio.worklists.createWorklist.error')
          ),
          { variant: 'error' }
        );
      }
    });
  };

  return (
    <React.Fragment>
      <Typography variant="h2" gutterBottom>
        {t('expertToolsXMLPortfolio.create.title')}
      </Typography>

      <Typography paragraph>
        <Trans
          i18nKey="expertToolsXMLPortfolio.create.description"
          components={[
            <Link
              key={0}
              targetBlank={true}
              href="https://www.enova.no/energimerking/eksperter/"
              variant="inherit"
            />
          ]}
        />
      </Typography>

      <Form onSubmit={addWorklistMutation} className="mb-4">
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <TextField
              name="title"
              fullWidth={xsScreen}
              label={t('expertToolsXMLPortfolio.create.label')}
              placeholder={t('expertToolsXMLPortfolio.create.placeholder')}
              onChange={(event) => setName(event.currentTarget.value || '')}
              value={name}
              disabled={isLoading}
            />
          </Grid>

          <Grid item>
            <Button
              fullWidth={xsScreen}
              type="submit"
              variant="primary"
              loading={isLoading}
              disabled={!name || isLoading}
            >
              {t('expertToolsXMLPortfolio.create.button')}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </React.Fragment>
  );
};
