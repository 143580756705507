import React, { FC } from 'react';
import { NumberField } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { WallZoneFormFieldProps } from '../utils';

export const WallZoneLength: FC<WallZoneFormFieldProps> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      rules={{ validate: (value) => (value && value > 0) || t('required') }}
      name="wallZoneData.wallZone.lengdeIMeter"
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error }
      }) => (
        <NumberField
          {...rest}
          error={!!error}
          helperText={error?.message}
          autoComplete
          label={t('register.wallZone.length')}
          value={value ?? null}
          marginBottom
          name="lengde-i-meter"
          onValueChange={({ floatValue }) => onChange(floatValue)}
          suffix={t('suffix.m')}
        />
      )}
    />
  );
};
