import React, { FC, ReactNode } from 'react';
import { Box, BoxProps, lighten, styled } from 'enova-frontend-components';
import classNames from 'classnames';

import RegFieldCollapse from './regFieldCollapse';
import CollapsibleProvider from './useCollapsible/provider';
import InnerCollapsibleRegField from './innerCollapsibleRegField';

export type CollapsibleRegFieldProps = BoxProps & {
  divider?: boolean;
  end?: boolean;
  open: boolean;
  start?: boolean;
  /**
   * If `true`, the expandable area does *not* recieve a colored background
   */
  transparent?: boolean;
  children: ReactNode;
};

type CollapsibleRegFieldSubTypes = {
  Collapse: typeof RegFieldCollapse;
  InnerCollapsible: typeof InnerCollapsibleRegField;
};

const Container = styled((props) => <Box {...props} />)`
  border-color: ${({ theme }) => theme.palette.divider};

  &:not(.transparent).open {
    background: ${({ theme }) => lighten(theme.palette.primary.main, 0.93)};
  }

  &:not(.transparent).open + :not(.transparent).open,
  &.divider {
    margin-top: ${({ theme }) => theme.spacing(-4)};
    border-top: ${({ theme }) => `2px solid ${theme.palette.divider}`};
  }

  &:not(.open) + :not(.transparent).open {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
` as typeof Box;

const CollapsibleRegField: FC<CollapsibleRegFieldProps> &
  CollapsibleRegFieldSubTypes = ({
  children,
  divider,
  end,
  open,
  start,
  transparent,
  ...rest
}) => (
  <CollapsibleProvider open={open}>
    <Container
      className={classNames({ open, end, start, transparent, divider })}
      maxWidth={(theme) => `calc(100% + ${theme.spacing(8)})`}
      mb={open && !transparent ? (end ? -5 : 2) : 0}
      mt={open && !transparent && start ? -5 : 0}
      mx={-4}
      pb={open ? (end ? 5 : 4) : undefined}
      pt={open && !transparent ? (start ? 5 : 4) : undefined}
      px={4}
      sx={{ transition: '200ms ease-in-out' }}
      {...rest}
    >
      {children}
    </Container>
  </CollapsibleProvider>
);

CollapsibleRegField.Collapse = RegFieldCollapse;
CollapsibleRegField.InnerCollapsible = InnerCollapsibleRegField;

export default CollapsibleRegField;
