import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Tiltak, TiltakType } from '../../types/registration/tiltak';
import { EnergiObjektType } from '../../utils/matrikkelBygningsTyper';
import { TiltakNameMap } from '../../types/registration/tiltakNameMap';
import { Malform } from '../registering/utils/registerEnums';

import { TiltakFormData } from './tiltakForm/types';
import { getTiltakTypeFromStringValue } from './tiltakForm/utils';
import { TiltakForm } from './tiltakForm';

export interface UpdateTiltakFormProps {
  tiltak: Tiltak;
  onUpdateTiltak: (tiltak: Tiltak) => void;
  isLoading: boolean;
  onCancel: () => void;
  energiobjektType: EnergiObjektType;
}

export const UpdateTiltakForm: FC<UpdateTiltakFormProps> = ({
  tiltak,
  onCancel,
  energiobjektType,
  onUpdateTiltak,
  isLoading
}) => {
  const methods = useForm<TiltakFormData>({
    defaultValues: {
      category: tiltak.tiltaksKategori ?? -1,
      name: getTiltakTypeFromStringValue(tiltak.navnBokmal) ?? -1,
      description: tiltak.beskrivelseBokmal ?? ''
    }
  });

  const onSubmit = (data: TiltakFormData) => {
    if (data.category === -1) {
      return;
    }

    const updatedTiltak = {
      ...tiltak,
      beskrivelseBokmal: data.description,
      beskrivelseNynorsk: data.description,
      navnBokmal: TiltakNameMap[data.name as TiltakType][Malform.Bokmal],
      navnNynorsk: TiltakNameMap[data.name as TiltakType][Malform.Nynorsk],
      tiltaksKategori: data.category
    };

    onUpdateTiltak(updatedTiltak);
  };

  return (
    <FormProvider {...methods}>
      <TiltakForm
        tiltak={tiltak}
        onSubmit={onSubmit}
        onCancel={onCancel}
        isLoading={isLoading}
        energiobjektType={energiobjektType}
      />
    </FormProvider>
  );
};
