import React, { FC, Fragment, useEffect } from 'react';
import { Box, Button, Grid, Link, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';

import { useUser } from '../../hooks/useUser';
import { getMainScreenPath, MainScreenTab } from '../../utils/navigation';
import { REFERRER_KEY, useAuthentication } from '../../hooks/useAuthentication';

interface LocationState {
  from?: string;
}

const HomeScreen: FC = () => {
  const { t } = useTranslation();
  const location = useLocation<LocationState>();

  const { hasUserProfile } = useUser();
  const { signIn, isAuthenticated } = useAuthentication();

  const mainScreenPath = getMainScreenPath({ tab: MainScreenTab.BUILDINGS });

  useEffect(() => {
    // To determine if a user has been redirected from a protected route, check the 'from' property,
    // which indicates the route that the user attempted to access while unauthenticated.
    const referrer = location.state?.from;
    const isLoggedIn = isAuthenticated && hasUserProfile;

    // Pass the route URL as state to userManager and proceed to redirect them to the ID Porten
    // if the user is not authenticated.
    if (referrer && !isLoggedIn) {
      signIn({
        state: {
          [REFERRER_KEY]: referrer
        }
      });
    }
  }, [location?.state, isAuthenticated, hasUserProfile]);

  if (isAuthenticated && hasUserProfile) {
    return <Redirect to={mainScreenPath} />;
  }

  return (
    <Fragment>
      <Box>
        <Box maxWidth={800} margin="auto" mt={5} mb={8}>
          <Typography align="center" gutterBottom variant="h1">
            {t('startScreen.pageTitle')}
          </Typography>

          <Typography className="text-preline" align="center" paragraph>
            {t('startScreen.description')}
          </Typography>

          <Typography align="center" paragraph>
            <Link
              href="https://www.enova.no/energimerking/om-energimerkeordningen/"
              variant="inherit"
            >
              {t('readMore')}
            </Link>
          </Typography>
        </Box>
        <Box className="bg-primary" mt={4}>
          <Box maxWidth={800} margin="auto" p={{ xs: 4, sm: 10 }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography paragraph align="center">
                  {t('startScreen.login')}
                </Typography>
              </Grid>

              <Grid item xs={12} sm="auto" className="m-auto">
                <Button variant="secondary" fullWidth onClick={() => signIn()}>
                  <Typography component="span" variant="h4" bold>
                    {t('logIn')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default HomeScreen;
