import { Typography } from 'enova-frontend-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const FileListTableHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <td>
          <Typography bold>
            {t('expertToolsXMLPortfolio.worklist.table.heading', {
              context: 'bruksenhetsIdentId'
            })}
          </Typography>
        </td>

        <td>
          <Typography bold>
            {t('expertToolsXMLPortfolio.worklist.table.heading', {
              context: 'identifier'
            })}
          </Typography>
        </td>

        <td>
          <Typography bold>
            {t('expertToolsXMLPortfolio.worklist.table.heading', {
              context: 'address'
            })}
          </Typography>
        </td>

        <td className="actions">
          <Typography variant="srOnly">
            {t('expertToolsXMLPortfolio.worklist.table.heading', {
              context: 'actions'
            })}
          </Typography>
        </td>
      </tr>
    </thead>
  );
};
