import { Box, Typography } from 'enova-frontend-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import useRegistrationForm from '../../../../screens/registration/useRegistrationForm';

import { Form } from './form';
import { SubmitButtons } from './submitButtons';
import { State } from './types';
import { ErrorMessage } from './errorMessage';
import { useConstructionDensityData } from './utils';

export const ConstructionDensity: FC = () => {
  const { t } = useTranslation();
  const { user, byggAr, konstruksjonstetthet } = useConstructionDensityData();
  const { goToNextStep } = useRegistrationForm();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm<State>({
    defaultValues: {
      user,
      byggAr,
      lekkasjetall: konstruksjonstetthet?.lekkasjetall,
      lekkasjetallDato: konstruksjonstetthet?.dato
        ? new Date(konstruksjonstetthet.dato)
        : undefined
    }
  });

  const onSubmit = () => goToNextStep();

  return (
    <Box>
      <Typography variant="h2" gutterBottom>
        {t('register.constructionDensity.title')}
      </Typography>

      <Typography paragraph gutterBottom>
        {t('register.constructionDensity.description1')}
      </Typography>

      <Typography paragraph gutterBottom>
        {t('register.constructionDensity.description2')}
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Form control={control} user={user} />
        <ErrorMessage errors={errors} />
        <SubmitButtons watch={watch} />
      </form>
    </Box>
  );
};
