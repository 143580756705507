import { Box, styled } from 'enova-frontend-components';
import { FC } from 'react';

import { Vindu } from '../../../../types/registration/userInput';
import { WindowType } from '../utils';

import { EditWindowDialog } from './editWindowDialog';
import { RemoveWindowDialog } from './removeWindowDialog';
import { WindowInfo } from './windowInfo';

const ButtonWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(4)};
  margin-top: ${({ theme }) => theme.spacing(4)};

  :empty {
    margin: 0;
  }
`;

interface WindowProps {
  editWindow?: (window: Vindu, numberOfEqualWindows: number) => void;
  numberOfEqualWindows?: number;
  removeWindow?: () => void;
  type?: WindowType;
  window: Vindu;
}

export const Window: FC<WindowProps> = ({
  editWindow,
  numberOfEqualWindows,
  removeWindow,
  type,
  window
}) => {
  return (
    <Box>
      <WindowInfo
        numberOfEqualWindows={numberOfEqualWindows || 0}
        window={window}
      />

      <ButtonWrapper>
        {removeWindow && <RemoveWindowDialog removeWindow={removeWindow} />}

        {editWindow && (
          <EditWindowDialog
            onSubmit={editWindow}
            window={window}
            numberOfEqualWindows={numberOfEqualWindows}
            type={type}
          />
        )}
      </ButtonWrapper>
    </Box>
  );
};
