import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Link,
  styled,
  Typography
} from 'enova-frontend-components';

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

interface MoreInfoDialogProps {
  open: boolean;
  onCancel: () => void;
}

export const MoreInfoDialog: FC<MoreInfoDialogProps> = ({ open, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onCancel}>
      <HeaderWrapper px={5} py={4}>
        <Typography variant="h3" display="flex" alignItems="center">
          {t('profileScreen.professional.areas.dialog.title')}
        </Typography>
        <Button variant="secondary" onClick={onCancel}>
          {t('close')}
        </Button>
      </HeaderWrapper>

      <Divider />
      <Dialog.Content>
        <Box display="flex" flexDirection="column" gap={5} my={3}>
          <Box>
            <Typography variant="h4">
              {t(`profileScreen.professional.areas.label.energiradgiver`)}
            </Typography>
            <Typography variant="body2">
              <Trans
                i18nKey="profileScreen.professional.areas.more.energiradgiver"
                components={[
                  <Link
                    targetBlank
                    button
                    key={0}
                    href="https://www.enova.no/privat/alle-energitiltak/energiradgivning/"
                  />
                ]}
              />
            </Typography>
          </Box>

          <Box>
            <Typography variant="h4">
              {t(`profileScreen.professional.areas.label.bygningsekspert`)}
            </Typography>
            <Typography variant="body2">
              {t(`profileScreen.professional.areas.more.bygningsekspert`)}
            </Typography>
          </Box>

          <Box>
            <Typography variant="h4">
              {t(`profileScreen.professional.areas.label.kjelOgVarmeanlegg`)}
            </Typography>
            <Typography variant="body2">
              {t(`profileScreen.professional.areas.more.kjelOgVarmeanlegg`)}
            </Typography>
          </Box>

          <Box>
            <Typography variant="h4">
              {t(
                `profileScreen.professional.areas.label.ventilasjonsOgKjoleanlegg`
              )}
            </Typography>
            <Typography variant="body2">
              {t(
                `profileScreen.professional.areas.more.ventilasjonsOgKjoleanlegg`
              )}
            </Typography>
          </Box>
        </Box>
      </Dialog.Content>
    </Dialog>
  );
};
