import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'enova-frontend-components';

import useBuildingDetails from '../../../hooks/useBuildingDetails';
import { GetEnergiObjektTypeFromMatrikkel } from '../../../utils/matrikkelBygningsTyper';
import TiltakListReadOnly from '../../../components/tiltakSection/tiltakListReadOnly';

const TiltakTab: FC = () => {
  const { t } = useTranslation();
  const {
    building: { gjenstaendeTiltak, kanEnergimerkesAvPrivatPerson }
  } = useBuildingDetails();
  const energiObjektType = GetEnergiObjektTypeFromMatrikkel(
    kanEnergimerkesAvPrivatPerson
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={11} xl={10}>
        <Typography mt={4} variant="h2" gutterBottom>
          {t('energySavingMeasures.title', {
            context: energiObjektType
          })}
        </Typography>

        {gjenstaendeTiltak && gjenstaendeTiltak.length > 0 ? (
          <Fragment>
            <Typography paragraph className="text-preline">
              {t('energySavingMeasures.description', {
                context: energiObjektType
              })}
            </Typography>

            <Grid container spacing={4} direction="column">
              <TiltakListReadOnly tiltakList={gjenstaendeTiltak} />
            </Grid>
          </Fragment>
        ) : (
          <Typography>{t('energySavingMeasures.emptyState')}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default TiltakTab;
