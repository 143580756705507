import { useFormContext } from 'react-hook-form';

import { SearchValues } from '../../../../../../types/building';

export type AdvancedSearchFormData = Pick<
  SearchValues,
  | 'postnummer'
  | 'poststed'
  | 'kommunenummer'
  | 'kommunenavn'
  | 'gardsnummer'
  | 'bruksnummer'
  | 'festenummer'
  | 'seksjonsnummer'
  | 'bygningsnummer'
  | 'bruksenhetsnummer'
> & {
  kommuneOptions: KommuneSearchOptions;
};

export const useAdvancedSearchFormContext = () => {
  return useFormContext<AdvancedSearchFormData>();
};

export type KommuneSearchOptions = { label: string; value: string }[];
