import React, { FC } from 'react';
import {
  Box,
  CONTENT_PADDING,
  CONTENT_WIDTH,
  Footer as FooterBase,
  Grid,
  styled,
  Typography
} from 'enova-frontend-components';
import { Trans, useTranslation } from 'react-i18next';

import RouterLink from '../../routerLink';
import { externalLinks } from '../../../config';

import EMSLogo from './logoWrapper';

const FooterLinkWrapper = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const LogoLinkWrapper = styled(Grid)`
  a {
    text-decoration: none;
  }
`;

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <FooterBase>
      <Box
        role="navigation"
        maxWidth={CONTENT_WIDTH}
        p={CONTENT_PADDING}
        mx="auto"
      >
        <Grid
          container
          spacing={4}
          justifyContent="space-between"
          direction={{ xs: 'column', md: 'row' }}
        >
          {Object.entries(externalLinks.footer).map(([category, linkList]) => (
            <Grid item key={category}>
              <FooterBase.CategoryHeader>
                {t(`footer.${category}.heading`)}
              </FooterBase.CategoryHeader>

              {Object.entries(linkList).map(([name, href]) => (
                <FooterLinkWrapper key={name}>
                  <Trans
                    i18nKey={t(`footer.${category}.${name}`)}
                    components={[
                      <FooterBase.Link
                        {...(category !== 'contact'
                          ? {
                              rel: 'noopener noreferrer',
                              target: '_blank'
                            }
                          : {})}
                        href={href || undefined}
                        key={name}
                      />
                    ]}
                  />
                </FooterLinkWrapper>
              ))}
            </Grid>
          ))}

          <LogoLinkWrapper
            item
            xs={12}
            justifyContent="center"
            display="flex"
            mt={8}
          >
            <RouterLink aria-label={t('home')} className="fvbsr" to="/">
              <EMSLogo />
            </RouterLink>
          </LogoLinkWrapper>
        </Grid>
      </Box>
    </FooterBase>
  );
};

export default Footer;
