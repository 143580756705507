import _ from 'underscore';

import useSelector from '../../../../../../hooks/useSelector';
import { getEtasjer } from '../../../../../../store/registration/selectors';

export const useBuildingConstructionData = () => {
  const allFloorsSameWallConstruction = useSelector(
    (state) => state.register.allFloorsSameWallConstruction
  );

  const constructionYear = useSelector(
    (state) => state.register.userInput?.bygningsdetaljer?.byggAr
  );

  const allWallsAgainstTheOpen = useSelector(
    (state) => state.register?.allWallsAgainstTheOpen
  );

  const allFloorsSameShape = useSelector(
    (state) => state.register.allFloorsSameShape
  );

  const floors = useSelector((state) => getEtasjer(state), _.isEqual);

  return {
    allFloorsSameWallConstruction,
    allFloorsSameShape,
    allWallsAgainstTheOpen,
    constructionYear,
    floors
  };
};
