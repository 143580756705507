import { Box, styled } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BygningsForm, Himmelretning } from './registering/utils/registerEnums';
import { BuildingShape } from './registering/steps/shapeAndArea/components/buildingShape';

const ShapeWrapper = styled(Box)`
  border: solid 1px #c1c1c1;
  border-radius: 50%;
  height: 290px;
  width: 290px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;

  .selected {
    fill: ${({ theme }) => theme.palette.fjordGreen};
    stroke: ${({ theme }) => theme.palette.fjordGreen};
    stroke-width: 4px;
  }

  rect.selected {
    stroke-width: 1px;
  }
`;

interface Props {
  shape: BygningsForm;
  direction: Himmelretning;
}

export const BuildingShapeWithCompass: FC<Props> = ({ shape, direction }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" flexGrow={1}>
      <Box display="flex" justifyContent="center">
        {t('direction.nord')}
      </Box>
      <Box display="flex">
        <Box alignSelf="center"> {t('direction.vest')}</Box>
        <ShapeWrapper>
          <BuildingShape shape={shape} direction={direction} />
        </ShapeWrapper>
        <Box alignSelf="center"> {t('direction.ost')}</Box>
      </Box>
      <Box display="flex" justifyContent="center">
        {t('direction.sor')}
      </Box>
    </Box>
  );
};
