import React, { useState } from 'react';
import { Select, SelectOption } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { useWallFormContext } from '../../../utils';
import {
  Vegg,
  Veggsone
} from '../../../../../../../types/registration/userInput';
import useSelector from '../../../../../../../hooks/useSelector';
import { getEtasjer } from '../../../../../../../store/registration/selectors';
import { getDetailingValue } from '../../useWallZoneDefaultValues';
import { useFloorTitle } from '../../../../../utils/useFloorTitle';

const getZoneLabelContext = (wall: Vegg, soneIndex: number) => {
  const hasMultipleZones = wall.veggSoner.length > 1;
  return {
    veggId: wall.veggId.toUpperCase() + (hasMultipleZones ? soneIndex + 1 : '')
  };
};

export const WallZoneSelect = () => {
  const etasjer = useSelector(getEtasjer, shallowEqual);

  const { t } = useTranslation();
  const { setValue } = useWallFormContext();
  const [selectedValue, setSelectedValue] = useState<string | -1>(-1);
  const { getFloorTitle } = useFloorTitle();
  const detailingOptions: SelectOption[] = [
    {
      label: t('select'),
      value: -1,
      disabled: true
    }
  ];

  const wallZoneMap: { [id: string]: Veggsone } = {};

  etasjer?.map((etasjeData) =>
    etasjeData.vegger?.map((wallData) => {
      const floorTitle = getFloorTitle(
        etasjeData.etasjeNummer,
        etasjeData.erKjeller
      );

      wallData.veggSoner.map((zone, zoneIndex) => {
        const wallZoneLabel = `${t(
          'wall',
          getZoneLabelContext(wallData, zoneIndex)
        )} - ${floorTitle}`;

        // Add select options
        detailingOptions.push({
          label: wallZoneLabel,
          value: zone.id
        });

        // Add wall zone to access object on select
        wallZoneMap[zone.id] = zone;
      });
    })
  );

  return (
    <Select
      label={t('register.wallZone.useValuesFrom')}
      key="useValuesFrom"
      value={selectedValue}
      options={detailingOptions}
      HelperBoxProps={{
        'aria-label': t('readFieldInformation'),
        children: t('register.wallZone.selectWallZone.information')
      }}
      onChange={(e) => {
        const selectedWallZoneId = e.currentTarget.value;
        setSelectedValue(selectedWallZoneId);

        const { veggKonstruksjon, tilliggendeRom } =
          wallZoneMap[selectedWallZoneId];

        setValue('wallZoneData.detailing', getDetailingValue(veggKonstruksjon));
        setValue('wallZoneData.wallZone.veggKonstruksjon', veggKonstruksjon);
        setValue('wallZoneData.wallZone.tilliggendeRom', tilliggendeRom);
      }}
    />
  );
};
