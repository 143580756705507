import { useTranslation } from 'react-i18next';
import { Typography } from 'enova-frontend-components';
import React from 'react';

export const WorklistTableHeader = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <td>
          <Typography bold>{t('name')}</Typography>
        </td>
        <td>
          <Typography bold>{t('createdDate')}</Typography>
        </td>
        <td>
          <Typography bold>{t('modifiedDate')}</Typography>
        </td>
        <td>
          <Typography bold>{t('status')}</Typography>
        </td>
        <td className="actions">
          <Typography variant="srOnly">{t('actions')}</Typography>
        </td>
      </tr>
    </thead>
  );
};
