import { useQuery } from 'react-query';

import { getTiltakGrupperegistrering } from '../../../services/api';
import { queryKeys } from '../../../utils/react-query';

export const useGetTiltakGrupperegistrering = (
  grupperegistreringId: string | undefined
) => {
  const { isLoading, data: tiltakList } = useQuery(
    [queryKeys.grupperegistreringMeasures, grupperegistreringId],
    () => getTiltakGrupperegistrering(grupperegistreringId),
    {
      enabled: !!grupperegistreringId
    }
  );

  return {
    grupperegistreringId,
    tiltakList,
    isLoading
  };
};
