import { Tabs } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleEnergyAnalysis } from '../../../../../../../types/building';
import { SummaryTab } from '../../../common/steps/summary/summaryTabs/tabs/types';
import { FileSummaryTab } from '../../../common/steps/summary/summaryTabs/tabs/fileSummaryTab';
import CalculatedEnergyRating from '../../../common/steps/summary/summaryTabs/tabs/calculatedEnergyRatingTab/calculatedEnergyRating';
import { camelCasify } from '../../../../../../../utils/navigation';
import { MeasuresTab } from '../../../common/steps/summary/summaryTabs/tabs/measuresTab';
import { SummaryTabs } from '../../../common/steps/summary/summaryTabs';
import { useTiltakEnergiPlan } from '../../../../../../../hooks/useTiltakEnergiplan';
import { useGetUnofficialEnergyCertificate } from '../../../../../../../hooks/useGetUnofficialEnergyCertificate';

interface Props {
  energianalyse: SimpleEnergyAnalysis;
}

export const EnhetSummaryTabs: FC<Props> = ({ energianalyse }) => {
  const tiltakQuery = useTiltakEnergiPlan(
    energianalyse?.energiplan?.energiplanId
  );

  const { getCertificate, isLoading } = useGetUnofficialEnergyCertificate(
    energianalyse.registreringId
  );

  const { t } = useTranslation();
  const generateTabTitle = (tab: SummaryTab) =>
    t(`expertToolsXML.summary.tab.${camelCasify(tab)}.tabTitle`);

  return (
    <SummaryTabs>
      <Tabs.Tab
        identifier={SummaryTab.RATING}
        tabTitle={generateTabTitle(SummaryTab.RATING)}
      >
        <CalculatedEnergyRating
          onDownloadCertificate={getCertificate}
          energyAnalysis={energianalyse}
          isLoading={isLoading}
        />
      </Tabs.Tab>

      <Tabs.Tab
        identifier={SummaryTab.REVIEW}
        tabTitle={generateTabTitle(SummaryTab.REVIEW)}
      >
        <FileSummaryTab energyAnalysis={energianalyse} />
      </Tabs.Tab>

      <Tabs.Tab
        identifier={SummaryTab.MEASURES}
        tabTitle={generateTabTitle(SummaryTab.MEASURES)}
      >
        <MeasuresTab tiltakQuery={tiltakQuery} />
      </Tabs.Tab>
    </SummaryTabs>
  );
};
