import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { initSoknad } from '../../store/registration/actions';
import useBuildingDetails from '../../hooks/useBuildingDetails';
import RegistrationScreenContent from '../registration/content';
import {
  getSoknadScreenPath,
  getSoknadSummaryScreenPath,
  SoknadScreenParams
} from '../../utils/navigation';
import { SoknadSteg } from '../../components/registering/utils/registerEnums';
import RegistrationFormProvider from '../registration/useRegistrationForm/provider';
import PageNotFound from '../pageNotFound';

const SoknadScreen: FC = () => {
  const {
    address,
    building: { gyldigSoknad },
    soknadStegMap
  } = useBuildingDetails();

  const registreringSoknadSteg1 = gyldigSoknad?.registreringForSteg1;
  const registreringSoknadSteg2 = gyldigSoknad?.registreringForSteg2;
  const registreringSoknadSteg3 = gyldigSoknad?.registreringForSteg3;

  const { t } = useTranslation();
  const { soknadId, soknadSteg = SoknadSteg.Steg1 } =
    useParams<SoknadScreenParams>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initSoknad({}));
  }, []);

  const helmetTitle = [
    t('registerScreen.pageTitle', {
      context: soknadSteg
    }),
    address
  ].join(' | ');

  const invalid = useMemo(
    () =>
      gyldigSoknad?.soknadId != soknadId ||
      (soknadSteg === SoknadSteg.Steg2 && !registreringSoknadSteg1) ||
      (soknadSteg === SoknadSteg.Steg3 &&
        !(registreringSoknadSteg1 && registreringSoknadSteg2)),
    []
  );

  if (invalid) return <PageNotFound />;

  const getRegId = () => {
    switch (soknadSteg) {
      case SoknadSteg.Steg1:
        return registreringSoknadSteg1;
      case SoknadSteg.Steg2:
        return registreringSoknadSteg2 ?? registreringSoknadSteg1;
      case SoknadSteg.Steg3:
        return registreringSoknadSteg3 ?? registreringSoknadSteg2;
      default:
        return undefined;
    }
  };

  const getRegistrationId = () => {
    switch (soknadSteg) {
      case SoknadSteg.Steg1:
        return registreringSoknadSteg1;
      case SoknadSteg.Steg2:
        return registreringSoknadSteg2;
      case SoknadSteg.Steg3:
        return registreringSoknadSteg3;
      default:
        return undefined;
    }
  };

  return (
    <Fragment>
      <Helmet title={helmetTitle} />
      <RegistrationFormProvider
        regId={getRegId()}
        registrationId={getRegistrationId()}
        soknad={{
          soknadId: gyldigSoknad?.soknadId,
          soknadSteg
        }}
        generatePath={getSoknadScreenPath}
        generateSummaryPath={getSoknadSummaryScreenPath}
      >
        <RegistrationScreenContent soknadStegMap={soknadStegMap} />
      </RegistrationFormProvider>
    </Fragment>
  );
};

export default SoknadScreen;
