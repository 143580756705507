import { Box, Checkbox } from 'enova-frontend-components';
import { Controller } from 'react-hook-form';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateBuildingFormContext } from '../utils';

interface Props {
  disabled?: boolean;
}

export const AddNewBuilding: FC<Props> = ({ disabled }) => {
  const { control } = useCreateBuildingFormContext();
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="flex-start" flexDirection="column" mt={4}>
      <Controller
        control={control}
        name="leggTilNyBygning"
        render={({ field }) => (
          <Checkbox
            {...field}
            disabled={disabled}
            checked={!!field.value}
            width="fullWidth"
            label={t('building.create.addUnitsOnSameAddress')}
          />
        )}
      />
    </Box>
  );
};
