import React, { FC, Fragment } from 'react';
import {
  Box,
  Divider,
  FullScreenLoader,
  MainContentBox,
  Typography
} from 'enova-frontend-components';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import {
  AppRoute,
  ExpertToolsXMLPortfolioWorklistParams
} from '../../../utils/navigation';
import { WorklistError } from '../worklistOverview/worklistTable/components';
import { queryKeys } from '../../../utils/react-query';
import { getPortefoljeData } from '../../../services/api';

import { EditWorklist } from './editWorklist';
import { DisplayWorklist } from './displayWorklist';

const Worklist: FC = () => {
  const { t } = useTranslation();
  const { portefoljeId } = useParams<ExpertToolsXMLPortfolioWorklistParams>();

  const { error, isLoading, data, refetch } = useQuery(
    [queryKeys.portfolio, portefoljeId],
    () => getPortefoljeData(portefoljeId)
  );

  const portefolje = data?.portefolje;

  if (error && !portefolje?.portefoljeId) {
    return <Redirect to={`/${AppRoute.EXPERT_TOOLS_XML_PORTFOLIO}`} />;
  }

  if (isLoading) {
    return <FullScreenLoader open />;
  }

  if (!portefolje || error) {
    return (
      <MainContentBox>
        <WorklistError refetch={refetch} />
      </MainContentBox>
    );
  }

  const isPublished = !!portefolje.publisertDato;

  return (
    <Fragment>
      <MainContentBox>
        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <Typography variant="h1" mb={0}>
            {t('expertToolsXMLPortfolio.title')}
          </Typography>

          <Typography variant="h2" component="span">
            {portefolje.navn}
          </Typography>
        </Box>
      </MainContentBox>

      <Divider />

      <MainContentBox>
        {isPublished ? <DisplayWorklist data={data} /> : <EditWorklist />}
      </MainContentBox>
    </Fragment>
  );
};

export default Worklist;
