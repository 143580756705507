import { Checkbox } from 'enova-frontend-components';
import { Controller } from 'react-hook-form';
import React, { FC } from 'react';

import { fagomradeIsNotSelected, isFagomradeRequired } from '../utils';
import { ProfileFormData, useProfileFormContext } from '../types';

import { CheckBoxLabel } from './checkBoxLabel';

interface FagomradeCheckboxProps {
  fieldKey: keyof ProfileFormData;
  loading: boolean;
}

export const FagomradeCheckbox: FC<FagomradeCheckboxProps> = ({
  fieldKey,
  loading
}) => {
  const { formState, control, trigger } = useProfileFormContext();

  return (
    <Controller
      control={control}
      name={fieldKey}
      rules={{
        validate: {
          required: (_value, formValues) => !isFagomradeRequired(formValues)
        }
      }}
      render={({ field }) => (
        <Checkbox
          {...field}
          checked={!!field.value}
          width="fullWidth"
          disabled={loading}
          error={fagomradeIsNotSelected(formState.errors)}
          label={<CheckBoxLabel fieldKey={fieldKey} />}
          onChange={async (e) => {
            field.onChange(e);
            if (formState.isSubmitted) {
              await trigger();
            }
          }}
        />
      )}
    />
  );
};
