import { combineReducers } from 'redux';

import registerReducer from './registration/reducer';

/* ------------- Assemble The Reducers ------------- */
const rootReducer = combineReducers({
  register: registerReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
