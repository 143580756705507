import { getYear } from 'date-fns';
import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';

import useSelector from '../../../hooks/useSelector';
import { Vindu } from '../../../types/registration/userInput';
import { UserRole } from '../../../types/user';
import { GlassType, KarmType, VeggId } from '../utils/registerEnums';
import {
  FieldError,
  MAX_NUMBER_OF_YEARS_IN_THE_FUTURE
} from '../utils/validation';
import { WindowSpecType } from '../utils/window';
import {
  getTakVinduer,
  getVinduer
} from '../../../store/registration/selectors';

export type NormalWindowType = {
  etasjeId: string;
  unified?: boolean;
  hasWindows?: boolean;
  veggId: VeggId;
  veggsoneId: string;
  veggsoneIndex: number;
};

export type WindowType = 'roof' | NormalWindowType;

export const getTranslationOptions = (
  type: WindowType
): Record<string, string> => {
  if (type === 'roof') return { context: 'roof' };

  if (type.unified)
    return {
      context: 'wall',
      veggId: type.veggId.toUpperCase()
    };

  return {
    context: 'wallZone',
    wallZoneId: `${type.veggId.toUpperCase()}${type.veggsoneIndex + 1}`
  };
};

export const VinduKarmTypeGlassTypeMap: Record<KarmType, GlassType[]> = {
  [KarmType.Trekarm]: [
    GlassType.EttLagVanligGlass,
    GlassType.KobletRammeMed1LagGlassIHverRamme,
    GlassType.EttLagGlassMedVarevinduAv1LagGlass,
    GlassType.EttLagGlassMedVarevinduAv2LagIsolerruteLuftfylt,
    GlassType.ToLagIsolerruteLuftfylt,
    GlassType.ToLagIsolerrute1EnergibeleggLuftfylt,
    GlassType.ToLagIsolerruteGassfylt,
    GlassType.ToLagIsolerrute1EnergibeleggGassfylt,
    GlassType.TreLagIsolerruteLuftfylt,
    GlassType.TreLagIsolerrute1EnergibeleggLuftfylt,
    GlassType.TreLagIsolerruteGassfylt,
    GlassType.TreLagIsolerrute1EnergibeleggGassfylt,
    GlassType.TreLagIsolerrute2EnergibeleggGassfylt,
    GlassType.ToLagIsolerruteSolreflekterendeLuftfylt,
    GlassType.ToLagIsolerrute1EnergibeleggSolreflekterendeLuftfylt,
    GlassType.ToLagIsolerrute1EnergibeleggSolreflekterendeGassfylt,
    GlassType.ToLagIsolerrute1EnergibeleggGassfyltMedSuperspacer,
    GlassType.TreLagIsolerrute2EnergibeleggGassfyltMedSuperspacer,
    GlassType.ToLagIsolerrute1EnergibeleggSolreflekterendeGassfyltMedSuperspacer
  ],
  [KarmType.IsolertTrekarm]: [
    GlassType.ToLagIsolerrute1EnergibeleggGassfylt,
    GlassType.TreLagIsolerrute1EnergibeleggGassfylt,
    GlassType.TreLagIsolerrute2EnergibeleggGassfylt,
    GlassType.ToLagIsolerrute1EnergibeleggSolreflekterendeGassfylt,
    GlassType.ToLagIsolerrute1EnergibeleggGassfyltMedSuperspacer,
    GlassType.TreLagIsolerrute2EnergibeleggGassfyltMedSuperspacer,
    GlassType.ToLagIsolerrute1EnergibeleggSolreflekterendeGassfyltMedSuperspacer
  ],
  [KarmType.SuperisolertMetallkarmMedKuldebrobryter]: [
    GlassType.ToLagIsolerrute1EnergibeleggGassfylt,
    GlassType.TreLagIsolerrute1EnergibeleggGassfylt,
    GlassType.TreLagIsolerrute2EnergibeleggGassfylt,
    GlassType.ToLagIsolerrute1EnergibeleggSolreflekterendeGassfylt,
    GlassType.ToLagIsolerrute1EnergibeleggGassfyltMedSuperspacer,
    GlassType.TreLagIsolerrute2EnergibeleggGassfyltMedSuperspacer,
    GlassType.ToLagIsolerrute1EnergibeleggSolreflekterendeGassfyltMedSuperspacer
  ],
  [KarmType.MetallkarmUtenKuldebrobryter]: [
    GlassType.EttLagVanligGlass,
    GlassType.KobletRammeMed1LagGlassIHverRamme,
    GlassType.EttLagGlassMedVarevinduAv1LagGlass,
    GlassType.EttLagGlassMedVarevinduAv2LagIsolerruteLuftfylt,
    GlassType.ToLagIsolerruteLuftfylt,
    GlassType.ToLagIsolerrute1EnergibeleggLuftfylt,
    GlassType.ToLagIsolerruteGassfylt,
    GlassType.TreLagIsolerruteLuftfylt,
    GlassType.TreLagIsolerrute1EnergibeleggLuftfylt,
    GlassType.ToLagIsolerruteSolreflekterendeLuftfylt,
    GlassType.ToLagIsolerrute1EnergibeleggSolreflekterendeLuftfylt,
    GlassType.ToLagIsolerrute1EnergibeleggSolreflekterendeGassfylt
  ],
  [KarmType.MetallkarmMedKuldebrobryter]: [
    GlassType.ToLagIsolerruteLuftfylt,
    GlassType.ToLagIsolerrute1EnergibeleggLuftfylt,
    GlassType.ToLagIsolerruteGassfylt,
    GlassType.ToLagIsolerrute1EnergibeleggGassfylt,
    GlassType.TreLagIsolerruteLuftfylt,
    GlassType.TreLagIsolerrute1EnergibeleggLuftfylt,
    GlassType.TreLagIsolerruteGassfylt,
    GlassType.TreLagIsolerrute1EnergibeleggGassfylt,
    GlassType.TreLagIsolerrute2EnergibeleggGassfylt,
    GlassType.ToLagIsolerruteSolreflekterendeLuftfylt,
    GlassType.ToLagIsolerrute1EnergibeleggSolreflekterendeLuftfylt,
    GlassType.ToLagIsolerrute1EnergibeleggSolreflekterendeGassfylt,
    GlassType.ToLagIsolerrute1EnergibeleggGassfyltMedSuperspacer,
    GlassType.TreLagIsolerrute2EnergibeleggGassfyltMedSuperspacer,
    GlassType.ToLagIsolerrute1EnergibeleggSolreflekterendeGassfyltMedSuperspacer
  ],
  [KarmType.Plastkarm]: [
    GlassType.ToLagIsolerruteLuftfylt,
    GlassType.ToLagIsolerrute1EnergibeleggLuftfylt,
    GlassType.ToLagIsolerruteGassfylt,
    GlassType.ToLagIsolerrute1EnergibeleggGassfylt,
    GlassType.TreLagIsolerruteLuftfylt,
    GlassType.TreLagIsolerrute1EnergibeleggLuftfylt,
    GlassType.TreLagIsolerruteGassfylt,
    GlassType.TreLagIsolerrute1EnergibeleggGassfylt,
    GlassType.TreLagIsolerrute2EnergibeleggGassfylt,
    GlassType.ToLagIsolerruteSolreflekterendeLuftfylt,
    GlassType.ToLagIsolerrute1EnergibeleggSolreflekterendeLuftfylt,
    GlassType.ToLagIsolerrute1EnergibeleggSolreflekterendeGassfylt,
    GlassType.ToLagIsolerrute1EnergibeleggGassfyltMedSuperspacer,
    GlassType.TreLagIsolerrute2EnergibeleggGassfyltMedSuperspacer,
    GlassType.ToLagIsolerrute1EnergibeleggSolreflekterendeGassfyltMedSuperspacer
  ]
};

interface Params {
  byggAr: number | undefined;
  fabrikasjonsAr: number | undefined;
  userRole: UserRole | undefined;
}

export const getYearOfManufactureError = (params: Params) => {
  const { byggAr, fabrikasjonsAr, userRole } = params;
  const isProfessional = userRole === UserRole.PROFESJONELL;

  if (!fabrikasjonsAr) {
    return {};
  }

  if (fabrikasjonsAr < 1000) {
    return { fabrikasjonsar: FieldError.Invalid };
  }

  const currentYear = getYear(Date.now());

  if (!isProfessional && fabrikasjonsAr > currentYear) {
    return { fabrikasjonsar: FieldError.Max };
  }

  if (fabrikasjonsAr > currentYear + MAX_NUMBER_OF_YEARS_IN_THE_FUTURE) {
    return { fabrikasjonsar: FieldError.MaxProfessional };
  }

  if (byggAr && fabrikasjonsAr < byggAr) {
    return { fabrikasjonsar: FieldError.Min };
  }

  return {};
};

export type WindowErrors = {
  fabrikasjonsar?: string;
};

export const getWindowSpecType = (window: Vindu): WindowSpecType => {
  const { karmType, glassType, uverdi, fabrikasjonsAr } = window;

  if (karmType != null || glassType != null) {
    return WindowSpecType.WINDOW_TYPE;
  }

  if (uverdi != null) {
    return WindowSpecType.U_VALUE;
  }

  if (fabrikasjonsAr != null) {
    return WindowSpecType.YEAR_OF_CHANGE;
  }

  return WindowSpecType.UNKNOWN;
};

export const isWindowValid = (
  vindu: Vindu,
  vinduType: WindowSpecType | null
) => {
  if (!vindu.arealIKvm || !vinduType) {
    return false;
  }

  if (vinduType === WindowSpecType.U_VALUE && !vindu.uverdi) {
    return false;
  }

  if (vinduType === WindowSpecType.YEAR_OF_CHANGE && !vindu.fabrikasjonsAr) {
    return false;
  }

  if (
    vinduType === WindowSpecType.WINDOW_TYPE &&
    !(vindu.glassType && vindu.karmType)
  ) {
    return false;
  }

  return true;
};

export const useWindowErrors = (fabrikasjonsAr: number | undefined) => {
  const [errors, setErrors] = useState<WindowErrors>({});

  const { byggAr, userRole } = useSelector((state) => ({
    byggAr: state.register.userInput?.bygningsdetaljer?.byggAr,
    userRole: state.register.user?.rolle
  }));

  useEffect(() => {
    const possibleError = getYearOfManufactureError({
      fabrikasjonsAr,
      byggAr,
      userRole
    });

    setErrors(possibleError);
  }, [fabrikasjonsAr]);

  return { errors };
};

// Create an array of equal windows.
export const createWindows = (window: Vindu, numberOfEqualWindows: number) =>
  Array(numberOfEqualWindows)
    .fill(null)
    .map(() => ({ ...window, id: uuidv4() }));

export const groupWindows = (windows: Vindu[]): Record<string, Vindu[]> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  return _.groupBy(windows, ({ id, ...rest }) => JSON.stringify(rest));
};

// Hook used to return a record of every type of window registered. Windows are grouped together
// based on the data they contain: E.g.:
/**
{
  "{\"arealIKvm\":3}": [
    {
      "id": "efb0262b-d0d5-4ac1-986a-f6859e441b44",
      "arealIKvm": 3
    },
    {
      "id": "7008a441-37bd-4aba-95cf-c8880d0b5ace",
      "arealIKvm": 3
    }
  ],
  "{\"arealIKvm\":2,\"uverdi\":23}": [
    {
      "id": "623cf5bc-daa3-4ddd-8bc6-342bd893bf1d",
      "arealIKvm": 2,
      "uverdi": 23
    },
    {
      "id": "7751c1bc-7797-46c5-95ba-23da3b9485cf",
      "arealIKvm": 2,
      "uverdi": 23
    },
  ]
}
 */
export const useWindows = (type: WindowType) => {
  const isRoof = type === 'roof';

  const vinduer = useSelector(
    (state) =>
      isRoof
        ? getTakVinduer(state)
        : getVinduer(state, type.etasjeId, type.veggId, type.veggsoneId),
    shallowEqual
  );

  return { windowRecord: groupWindows(vinduer) };
};
