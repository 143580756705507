import React from 'react';

import { BuildingSelect } from '../../common/steps/buildingSelect';
import { useBuildingSelectFlerbolig } from '../hooks/useBuildingSelectFlerbolig';

export const FlerboligBuildingSelect = () => {
  const { onSelect, toggleMultiSelect } = useBuildingSelectFlerbolig();

  return (
    <BuildingSelect
      onSelect={onSelect}
      toggleMultiSelect={toggleMultiSelect}
      registrationType="flerbolig"
    />
  );
};
