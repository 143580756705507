import { Button, Dialog } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailingStep } from '../../../../utils/registerEnums';

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  categoryToDelete?: DetailingStep;
}

export const DeleteExistingDataDialog: FC<Props> = ({
  isOpen,
  onConfirm,
  onCancel,
  categoryToDelete
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <Dialog.Title>
        {t('register.step.delete.title', {
          stegtittel: t(`register.step.${categoryToDelete}`)
        })}
      </Dialog.Title>

      <Dialog.Content className="text-preline">
        {t('register.step.delete.description', {
          stegtittel: t(`register.step.${categoryToDelete}`)
        })}
      </Dialog.Content>

      <Dialog.Actions>
        <Button variant="secondary" onClick={onCancel}>
          {t('cancel')}
        </Button>

        <Button onClick={onConfirm}>{t('confirm')}</Button>
      </Dialog.Actions>
    </Dialog>
  );
};
