interface Organisasjonsnummer {
  label: string;
  value: string;
}

export enum Bygningstype {
  BoligBygg = 100,
  YrkesBygg = 200
}

export interface CreateBuildingData {
  gatenavn: string;
  gatenummer: string;
  gateBokstav: string;
  postnummer: string;
  poststed: string;
  kommunenummer: string;
  kommunenavn: string;
  gardsnummer: string;
  bruksnummer: string;
  bygningsnummer: string;
  bruksenhetsnummer: string;
  seksjonsnummer: string;
  festenummer: string;
  organisasjonsnummer: Organisasjonsnummer | undefined;
  andelsnummer: string;
  vilkar: boolean;
  leggTilNyBygning: boolean;
  bygningstype: Bygningstype;
}

export type CreateBuildingUrlParameters = Pick<
  CreateBuildingData,
  | 'gatenavn'
  | 'gatenummer'
  | 'bruksnummer'
  | 'gardsnummer'
  | 'postnummer'
  | 'bygningstype'
  | 'organisasjonsnummer'
  | 'bygningsnummer'
  | 'seksjonsnummer'
  | 'festenummer'
>;
