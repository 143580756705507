import { useContext } from 'react';

import StatisticsFilterContext, {
  StatisticsFilterContextValues
} from './context';

const useStatisticsFilter = (): StatisticsFilterContextValues =>
  useContext(StatisticsFilterContext);

export default useStatisticsFilter;
