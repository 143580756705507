import { useMutation } from 'react-query';

import useStore from '../../../hooks/useStore';
import * as api from '../../../services/api';

// Only used in registration form provider to save the calculation parameters
// when a user navigates with the breadcrumb menu on the left side of the registration page.
export const useUpdateParameters = (registrationId: string | undefined) => {
  const store = useStore();

  const { mutate, isLoading } = useMutation(() => {
    const inputData =
      store.getState().register.energiplan?.energimerke?.inndata
        ?.beregningskjerneInput;

    // If no input data is available, just return from this function.
    if (!inputData) {
      return Promise.resolve(null);
    }

    return api.updateInputParameters(registrationId, inputData);
  });

  return {
    updateParameters: mutate,
    updateParametersIsLoading: isLoading
  };
};
