import { Box, Paper, styled } from 'enova-frontend-components';

const IconWrapper = styled(Box)`
  height: 100px;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 50px;
    height: 50px;
  }
`;

const FloorDataWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const FloorWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-right: 1px solid #d0d2d2;
`;

const StyledPaper = styled(Paper)`
  box-shadow: none;
  border: solid 1px #d0d2d2;
  margin: 20px;
`;

const WallsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
`;

export {
  IconWrapper,
  FloorWrapper,
  FloorDataWrapper,
  StyledPaper,
  WallsWrapper
};
