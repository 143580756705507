import { useMutation } from 'react-query';

import store from '../../store';
import { updateRegistration } from '../../services/api';

export const useAutoSave = () => {
  const { mutate } = useMutation((registrationId: string) => {
    const userInput = store.getState().register.userInput;
    return updateRegistration(registrationId, userInput);
  });

  return { mutateAutoSave: mutate };
};
