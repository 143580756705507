import { Box, Button, styled } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { WindowFormData } from '../types';

import { useWindowFormContext } from './utils';
import { FormFields } from './formFields';

const WindowWrapper = styled(Box)`
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  padding: 15px;
`;

interface Props {
  onSubmit: (data: WindowFormData) => void;
  onRemove: () => void;
}

export const Form: FC<Props> = ({ onSubmit, onRemove }) => {
  const { control, handleSubmit } = useWindowFormContext();
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <WindowWrapper>
        <FormFields control={control} />

        <Box display="flex" flexDirection="row" gap={1}>
          <Button size="medium" onClick={onRemove}>
            {t('register.window.remove')}
          </Button>
          <Button size="medium" variant="secondary" type="submit">
            {t('save')}
          </Button>
        </Box>
      </WindowWrapper>
    </form>
  );
};
