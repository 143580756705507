import React, { FC } from 'react';
import { NumberField } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { WindowFormFieldProps } from '../utils';
import { getYearOfManufactureError } from '../../utils';
import { useUser } from '../../../../../../hooks/useUser';
import { useBuildingConstructionData } from '../../../walls/wallZone/form/useBuildingConstructionData';

export const YearOfProduction: FC<WindowFormFieldProps> = ({ control }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { constructionYear } = useBuildingConstructionData();

  return (
    <Controller
      control={control}
      name="windowData.window.fabrikasjonsAr"
      rules={{
        validate: {
          required: (value, values) => {
            // Only required if detailing selection is year of production
            if (values.detailing !== 'yearOfProduction') {
              return;
            }

            const errorType = getYearOfManufactureError({
              constructionYear,
              yearOfProduction: value,
              userRole: user?.rolle
            });

            return (
              !errorType ||
              t('register.parameters.error.year', { context: errorType })
            );
          }
        }
      }}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <NumberField
            autoComplete
            helperText={error?.message}
            error={!!error}
            label={t('register.window.yearOfProduction')}
            name="produksjonsar"
            format="####"
            onValueChange={({ floatValue }) => onChange(floatValue)}
            value={value ?? null}
          />
        );
      }}
    />
  );
};
