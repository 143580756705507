import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Grid, Typography } from 'enova-frontend-components';
import { shallowEqual, useDispatch } from 'react-redux';
import React, { FC, useState } from 'react';

import { DetailingStep } from '../../../../utils/registerEnums';
import { camelCasify } from '../../../../../../utils/navigation';
import { useMediaQuery } from '../../../../../../hooks/useMediaQuery';
import useSelector from '../../../../../../hooks/useSelector';
import { getDetailingSteps } from '../../../../../../store/registration/selectors';
import { updateDetailingSteps } from '../../../../../../store/registration/actions';
import { hasToDeleteExistingData } from '../utils';

import { DeleteExistingDataDialog } from './deleteExistingDataDialog';

const CheckBoxLabel = ({ detailingStep }: { detailingStep: DetailingStep }) => {
  const { t } = useTranslation();

  const category = camelCasify(detailingStep);

  return (
    <Box>
      <Typography style={{ fontWeight: 600 }}>
        {t(`register.extraDetails.${category}.title`)}
      </Typography>
      <Typography>
        {t(`register.extraDetails.${category}.description`)}
      </Typography>
    </Box>
  );
};

interface StepCheckBoxProps {
  step: DetailingStep;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
}

export const StepCheckBox: FC<StepCheckBoxProps> = ({
  step,
  disabled,
  onChange
}) => {
  const { t } = useTranslation();
  const { xsScreen } = useMediaQuery();
  const detailingSteps = useSelector(getDetailingSteps, shallowEqual);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const userInput = useSelector((s) => s.register.userInput);
  const dispatch = useDispatch();

  const handleChange = (checked: boolean) => {
    dispatch(updateDetailingSteps({ step, value: checked }));

    // Vinduer, Vegger and Bygningsform checkboxes need more logic on value change
    onChange && onChange(checked);
  };

  const onDetailingStepChange = (checked: boolean) => {
    if (!checked && hasToDeleteExistingData(userInput, step)) {
      setDeleteDialogOpen(true);
      return;
    }

    handleChange(checked);
  };

  return (
    <Grid item lg={5} m={2}>
      <Checkbox
        key={step}
        disabled={disabled}
        checked={!!detailingSteps[step]}
        aria-label={t(`register.extraDetails.${step}.title`)}
        label={<CheckBoxLabel detailingStep={step} />}
        onChange={(_, checked) => onDetailingStepChange(checked)}
        width={xsScreen ? 'fullWidth' : 'auto'}
      />

      <DeleteExistingDataDialog
        isOpen={deleteDialogOpen}
        onCancel={() => setDeleteDialogOpen(false)}
        categoryToDelete={step}
        onConfirm={() => {
          setDeleteDialogOpen(false);
          handleChange(false);
        }}
      />
    </Grid>
  );
};
