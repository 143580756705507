import React, { FC } from 'react';
import {
  EnergyGrade,
  EnergyRating2D,
  HeatingGrade
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

type TranslatedEnergyRating2DProps = {
  energyGrade: EnergyGrade;
  heatingGrade: HeatingGrade;
};

const TranslatedEnergyRating2D: FC<TranslatedEnergyRating2DProps> = ({
  energyGrade,
  heatingGrade
}) => {
  const { t } = useTranslation();

  return (
    <EnergyRating2D
      energyAxisLabel={t('energyLabel.energyGrade')}
      heatingAxisLabel={t('energyLabel.heatingGrade')}
      energyGrade={energyGrade}
      heatingGrade={heatingGrade}
      screenReaderCaption={t('energyLabel2D.srCaption', {
        heatingGrade: t('energyLabel.heatingGrade', {
          context: heatingGrade
        }),
        energyGrade: energyGrade
      })}
    />
  );
};

export default TranslatedEnergyRating2D;
