import React, { FC, ReactNode } from 'react';
import { Box, Divider, Typography } from 'enova-frontend-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { XMLParams } from '../../types';

import { ReturnToXmlOverviewButton } from './returnToXmlOverviewButton';

interface Props {
  headerInfo: ReactNode;
}

export const XmlRegistrationHeader: FC<Props> = ({ headerInfo }) => {
  const { step } = useParams<XMLParams>();
  const { t } = useTranslation();

  const isSelectBuildingStep = step === 'velg-bygg';

  return (
    <Box display="flex" flexDirection="column" gap={5} mt={5}>
      <Box mb={2}>
        <ReturnToXmlOverviewButton />
      </Box>

      {!isSelectBuildingStep && headerInfo}

      {isSelectBuildingStep && (
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="h2">
            {t('expertToolsXML.step.velgBygg')}
          </Typography>
          <Typography>
            {t('expertToolsXML.step.velgBygg.description')}
          </Typography>
        </Box>
      )}

      <Divider />
    </Box>
  );
};
