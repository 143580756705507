import { useParams } from 'react-router-dom';
import React from 'react';

import Measures from '../../common/steps/measures';
import { XMLParams } from '../../types';
import { useEnergyAnalysis } from '../../../../../../hooks/useEnergyAnalysis';
import { XmlRegistrationStepLoader } from '../../common/components/xmlRegistrationStepLoader';
import { EnergiplanTiltakSection } from '../../../../../../components/tiltakSection';
import { useTiltakEnergiPlan } from '../../../../../../hooks/useTiltakEnergiplan';

export const EnhetMeasures = () => {
  const { registrationId } = useParams<XMLParams>();

  const { energyAnalysis, energyAnalysisLoading } =
    useEnergyAnalysis(registrationId);
  const energiplanId = energyAnalysis?.energiplan?.energiplanId;
  const { tiltakList } = useTiltakEnergiPlan(energiplanId);

  if (energyAnalysisLoading) {
    return <XmlRegistrationStepLoader />;
  }

  if (!energyAnalysis) {
    return null;
  }

  if (!energiplanId) {
    return null;
  }

  return (
    <Measures registrationType="enhet">
      <EnergiplanTiltakSection
        energiplanId={energiplanId}
        tiltakList={tiltakList}
        allowEnergiobjektTypeOverride
      />
    </Measures>
  );
};
