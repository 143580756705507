import React, { FC, Fragment } from 'react';
import { Tabs } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useUser } from '../../hooks/useUser';
import {
  AppRoute,
  camelCasify,
  DefaultMainScreenTab,
  getMainScreenPath,
  hasPermission,
  MainScreenParams,
  MainScreenTab
} from '../../utils/navigation';
import { SessionExpiresDialog } from '../../components/sessionExpiresDialog';
import { useMediaQuery } from '../../hooks/useMediaQuery';

import TabTitle from './tabs/tabTitle';
import { BuildingsTabContent } from './tabs/buildingsTab';
import { OtherBuildingsTabContent } from './tabs/otherBuildingsTab';
import { SearchTabContent } from './tabs/searchTab';
import { XmlTab } from './tabs/xmlTab';
import { NewBadge } from './xmlRegistrationView/xmlRegistration/common/components/styledComponents';

const MainScreen: FC = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { tab = DefaultMainScreenTab } = useParams<MainScreenParams>();
  const { user } = useUser();
  const { xsScreen } = useMediaQuery();
  if (!user) {
    return null;
  }

  const userType = user.type;

  const handleTabChange = (newTab: MainScreenTab) =>
    push(getMainScreenPath({ tab: newTab }));

  return (
    <Fragment>
      <Helmet
        title={t(`mainScreen.tab.${camelCasify(tab)}`, { context: userType })}
      />

      <Tabs
        onChange={(_, newTab) => handleTabChange(newTab as MainScreenTab)}
        selectedTab={tab}
      >
        <Tabs.Tab
          identifier={MainScreenTab.BUILDINGS}
          tabTitle={
            <TabTitle
              tabIdentifier={MainScreenTab.BUILDINGS}
              userType={userType}
            />
          }
        >
          <BuildingsTabContent />
        </Tabs.Tab>

        <Tabs.Tab
          identifier={MainScreenTab.OTHERS_BUILDINGS}
          tabTitle={
            <TabTitle
              tabIdentifier={MainScreenTab.OTHERS_BUILDINGS}
              userType={userType}
            />
          }
        >
          <OtherBuildingsTabContent />
        </Tabs.Tab>

        <Tabs.Tab
          identifier={MainScreenTab.SEARCH}
          tabTitle={
            <TabTitle
              tabIdentifier={MainScreenTab.SEARCH}
              userType={userType}
            />
          }
        >
          <SearchTabContent />
        </Tabs.Tab>

        {hasPermission(AppRoute.EXPERT_TOOLS_XML, user) && (
          <Tabs.Tab
            identifier={MainScreenTab.XML_OVERVIEW}
            tabTitle={
              <Fragment>
                <TabTitle
                  tabIdentifier={MainScreenTab.XML_OVERVIEW}
                  userType={userType}
                />

                {!xsScreen && <NewBadge />}
              </Fragment>
            }
          >
            <XmlTab />
          </Tabs.Tab>
        )}
      </Tabs>
      <SessionExpiresDialog />
    </Fragment>
  );
};

export default MainScreen;
