import { FormMessage, Grid, Link, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  refetch: () => Promise<unknown>;
}

export const SearchError: FC<Props> = ({ refetch }) => {
  const { t } = useTranslation();

  return (
    <Grid xs={12} item>
      <FormMessage fullWidth title={t('search.error.title')}>
        <Typography>{t('search.error.description')}</Typography>
        <Link button color="inherit" onClick={async () => await refetch()}>
          {t('search.error.retry')}
        </Link>
      </FormMessage>
    </Grid>
  );
};
