import { useMutation } from 'react-query';

import { grantCertificateConsent } from '../../services/api';
import { queryClient, queryKeys } from '../../utils/react-query';

export const useGrantCertificateConsent = (registrationId: string) => {
  const { mutate, isLoading } = useMutation(
    (energiplanId: string) => grantCertificateConsent(energiplanId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          queryKeys.energyAnalysis,
          registrationId
        ])
    }
  );

  return { grantCertificateConsent: mutate, isLoading };
};
