import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberField } from 'enova-frontend-components';

interface WindowAreaProps {
  onChange: (floatValue: number | undefined) => void;
  value: number | undefined;
}

export const WindowArea: FC<WindowAreaProps> = ({ onChange, value }) => {
  const { t } = useTranslation();

  return (
    <NumberField
      autoComplete
      label={t('register.window.vindusarealIKvm')}
      name="vindusareal-i-kvm"
      required
      onValueChange={({ floatValue }) => onChange(floatValue)}
      value={value}
      suffix={t('squareMeters')}
    />
  );
};
