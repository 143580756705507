import React, { FC, ReactNode } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import {
  Box,
  FormMessage,
  FullScreenLoader,
  Link,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { BuildingId } from '../../utils/navigation';
import { SoknadSteg } from '../../components/registering/utils/registerEnums';
import { useGetBuildingAddress } from '../useGetBuildingAddress';

import BuildingDetailsContext, { BuildingDetailsValues } from './context';
import {
  useBuildingData,
  useBuildingEnergyAnalyses
} from './buildingDetailsHooks';
import { createSoknadStegMap } from './utils';

const BuildingDetailsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { buildingId } = useParams<BuildingId>();
  const { getBuildingAddress } = useGetBuildingAddress();
  const { t } = useTranslation();
  const { go } = useHistory();

  const { buildingError, buildingLoading, building } =
    useBuildingData(buildingId);

  const {
    simpleEnergyAnalyses,
    simpleEnergyAnalysesError,
    simpleEnergyAnalysesLoading,
    refreshSimpleEnergyAnalyses
  } = useBuildingEnergyAnalyses(buildingId);

  if (buildingLoading) {
    return <FullScreenLoader open />;
  }

  if (buildingError)
    return (
      <Box>
        <FormMessage fullWidth title={t('generalError.title')}>
          <Typography>{t('building.error')}</Typography>
          <Link
            button
            transparentBackground
            color="inherit"
            onClick={() => go(0)}
          >
            {t('generalError.retry')}
          </Link>
        </FormMessage>
      </Box>
    );

  if (!building) {
    return <Redirect to="/" />;
  }

  const { adresse, gyldigSoknad, gyldigeEnergiattester } = building;

  const address = getBuildingAddress(adresse);

  const currentEnergyAnalysis =
    gyldigeEnergiattester.length > 0
      ? simpleEnergyAnalyses?.find(
          ({ registreringId }) =>
            registreringId === gyldigeEnergiattester[0].registreringId
        )
      : undefined;

  const soknadStegMap = createSoknadStegMap(building, simpleEnergyAnalyses);

  const getNextSoknadSteg = (): SoknadSteg | undefined => {
    if (
      !gyldigSoknad?.soknadId &&
      !gyldigSoknad?.registreringForSteg1 &&
      !gyldigSoknad?.registreringForSteg2 &&
      !gyldigSoknad?.registreringForSteg3
    )
      return SoknadSteg.Steg1;

    if (
      gyldigSoknad?.soknadId &&
      soknadStegMap?.[SoknadSteg.Steg1]?.done &&
      !gyldigSoknad?.registreringForSteg3
    ) {
      if (!gyldigSoknad?.registreringForSteg2) return SoknadSteg.Steg2;
      if (soknadStegMap[SoknadSteg.Steg2]?.done) return SoknadSteg.Steg3;
    }
    return undefined;
  };

  const values: BuildingDetailsValues = {
    address,
    building,
    currentEnergyAnalysis,
    getNextSoknadSteg,
    refreshEnergyAnalysis: () => refreshSimpleEnergyAnalyses(),
    simpleEnergyAnalyses: simpleEnergyAnalyses,
    simpleEnergyAnalysesError: !!simpleEnergyAnalysesError,
    simpleEnergyAnalysesLoading,
    soknadStegMap
  };

  return (
    <BuildingDetailsContext.Provider value={values}>
      {children}
    </BuildingDetailsContext.Provider>
  );
};

export default BuildingDetailsProvider;
