import { createContext } from 'react';

import { SoknadSteg } from '../../components/registering/utils/registerEnums';
import {
  BuildingDetails,
  DetailedBuildingDetails,
  SimpleEnergyAnalysis
} from '../../types/building';
import { SoknadStegMap } from '../../types/soknad';

export type BuildingDetailsValues = {
  address: string;
  building: DetailedBuildingDetails;
  currentEnergyAnalysis?: SimpleEnergyAnalysis;
  getNextSoknadSteg: () => SoknadSteg | undefined;
  refreshEnergyAnalysis: () => void;
  simpleEnergyAnalyses?: SimpleEnergyAnalysis[];
  simpleEnergyAnalysesError: boolean;
  simpleEnergyAnalysesLoading: boolean;
  soknadStegMap?: SoknadStegMap;
};

const BuildingDetailsContext = createContext<BuildingDetailsValues>({
  address: '',
  building: {} as BuildingDetails,
  getNextSoknadSteg: () => undefined,
  refreshEnergyAnalysis: () => undefined,
  simpleEnergyAnalysesError: false,
  simpleEnergyAnalysesLoading: false
});

export default BuildingDetailsContext;
