import { useDispatch } from 'react-redux';

import { DetailingStep } from '../../../../utils/registerEnums';
import { updateDetailingSteps } from '../../../../../../store/registration/actions';

import { StepCheckBox } from './stepCheckBox';

export const BygningsformCheckBox = () => {
  const dispatch = useDispatch();

  const onBygningsformChange = (checked: boolean) => {
    // Bygningsform is required for Vinduer and Vegger steps to be active
    // If Bygningsform is unchecked we programmatically uncheck Vinduer and Vegger
    if (!checked) {
      dispatch(
        updateDetailingSteps({ step: DetailingStep.Vinduer, value: false })
      );

      dispatch(
        updateDetailingSteps({ step: DetailingStep.Vegger, value: false })
      );
    }
  };

  return (
    <StepCheckBox
      step={DetailingStep.Bygningsform}
      onChange={onBygningsformChange}
    />
  );
};
