import { Stepper } from 'enova-frontend-components';
import { FC } from 'react';

import { XmlRegistrationStep } from '../../types';

import { XmlStep } from './xmlStep';

interface Props {
  checkStepCompletion: (step: XmlRegistrationStep) => boolean;
}

export const XmlRegistrationStepper: FC<Props> = ({ checkStepCompletion }) => (
  <Stepper>
    <XmlStep step="velg-bygg" done={checkStepCompletion('velg-bygg')} />
    <XmlStep step="last-opp-fil" done={checkStepCompletion('last-opp-fil')} />
    <XmlStep
      step="oppsummering-av-fil"
      done={checkStepCompletion('oppsummering-av-fil')}
    />
    <XmlStep step="tiltak" done={checkStepCompletion('tiltak')} />
    <XmlStep step="oppsummering" done={checkStepCompletion('oppsummering')} />
    <XmlStep step="kvittering" done={checkStepCompletion('kvittering')} />
  </Stepper>
);
