import { useMutation, useQueryClient } from 'react-query';

import * as api from '../../services/api';
import { ExpertReport } from '../../types/building';
import { queryKeys } from '../../utils/react-query';

export const useCreateExpertReport = (buildingId: string) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (report: ExpertReport) => api.createExpertReport(report),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([queryKeys.expertReports, buildingId])
    }
  );

  return { createExpertReport: mutate, isLoading };
};
