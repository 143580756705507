import { Autocomplete, useSnack } from 'enova-frontend-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useDebounce } from 'rooks';

import { queryKeys } from '../../../utils/react-query';
import { searchCompany } from '../../../services/api';
import { containsOnlyDigits, useCreateBuildingFormContext } from '../utils';
import { createErrorMessage } from '../../../utils/utils';

const getNoOptionText = (str: string): string => {
  if (!str) {
    return 'building.create.housingAssociationOrCompany.search';
  }

  if (containsOnlyDigits(str) && str.length !== 9) {
    return 'building.create.housingAssociationOrCompany.search.orgNumber';
  }

  return 'building.create.housingAssociationOrCompany.search.noResults';
};

const useCompanySearch = (companyName: string) => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(
    [queryKeys.brreg, companyName],
    () => searchCompany(companyName),
    {
      enabled: companyName?.length > 0,
      onError() {
        addSnack(
          createErrorMessage(
            t('building.create.housingAssociationOrCompany.search.error')
          ),
          { variant: 'error' }
        );
      }
    }
  );

  const options =
    data?.map((enhet) => ({
      label: `${enhet?.navn} ${enhet?.organisasjonsnummer}`,
      value: enhet?.organisasjonsnummer
    })) ?? [];

  return { options, isLoading };
};

interface HousingAssociationOrCompanyProps {
  disabled: boolean;
}

export const HousingAssociationOrCompany: FC<
  HousingAssociationOrCompanyProps
> = ({ disabled }) => {
  const {
    control,
    formState: { defaultValues }
  } = useCreateBuildingFormContext();
  const { t } = useTranslation();

  const [companyName, setCompanyName] = useState(
    defaultValues?.organisasjonsnummer?.label ?? ''
  );
  const setCompanyNameDebounced = useDebounce(setCompanyName, 500);

  const { options, isLoading } = useCompanySearch(companyName);

  const noOptionsText = getNoOptionText(companyName);

  return (
    <Controller
      name="organisasjonsnummer"
      control={control}
      render={({ field: { onChange, value, ...rest } }) => {
        return (
          <Autocomplete
            fullWidth
            disabled={disabled}
            value={value?.value}
            onChange={(_, option) => onChange(option)}
            onInputChange={(_, val, reason) => {
              if (reason === 'input') {
                setCompanyNameDebounced(val);
              }
            }}
            HelperBoxProps={{
              'aria-label': t('readFieldInformation'),
              children: t(
                'building.create.housingAssociationOrCompany.helperText'
              )
            }}
            options={options}
            loading={isLoading}
            loadingText={t('building.create.loading.companySearch')}
            noOptionsText={t(noOptionsText)}
            label={t('building.create.housingAssociationOrCompany')}
            placeholder={t(
              'building.create.housingAssociationOrCompany.placeholder'
            )}
            {...rest}
          />
        );
      }}
    />
  );
};
