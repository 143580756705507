import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  Grid,
  Link,
  Typography,
  TypographyProps
} from 'enova-frontend-components';

import { YtterDor } from '../../../../types/registration/userInput';

type ExistingDoorProps = {
  HeadingProps?: TypographyProps;
  index: number;
  removeDoor?: () => void;
  door: YtterDor;
};

const ExistingDoor: FC<ExistingDoorProps> = ({
  HeadingProps,
  door: { dorType },
  removeDoor,
  index
}) => {
  const { t } = useTranslation();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography bold {...HeadingProps}>
            {t('register.door.doorNo', { count: index + 1 })}
          </Typography>
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">
              {t('register.door.dorType.label')}
            </Typography>

            <Typography variant="body2" bold>
              {t(`register.door.dorType.option.${dorType}`)}
            </Typography>
          </Grid>

          {removeDoor && (
            <Grid item xs={12} sm="auto">
              <Button
                fullWidth
                variant="secondary"
                onClick={() => setDeleteDialogOpen(true)}
              >
                {t('register.door.remove.button', { count: index + 1 })}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      {removeDoor && (
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <Dialog.Title>
            {t('register.door.remove.title', { count: index + 1 })}
          </Dialog.Title>

          <Dialog.Content>
            {t('register.door.remove.description', { count: index + 1 })}
          </Dialog.Content>

          <Dialog.Actions>
            <Link button onClick={() => setDeleteDialogOpen(false)}>
              {t('cancel')}
            </Link>

            <Button onClick={removeDoor}>{t('confirm')}</Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </Fragment>
  );
};

export default ExistingDoor;
