import { FC, Fragment } from 'react';
import { SessionExpiresDialog } from '../../components/sessionExpiresDialog';
import { DisplayWorklist } from './logview';
import { readFromLocalStorage } from '../../services/tracelog';

const TracelogScreen: FC = () => {
  const rows = readFromLocalStorage();

  return (
    <Fragment>
      <DisplayWorklist rows={rows} />
      <SessionExpiresDialog />
    </Fragment>
  );
};

export default TracelogScreen;
