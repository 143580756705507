import React, { FC, forwardRef } from 'react';
import { Link as RrdLink, LinkProps } from 'react-router-dom';
import { StylizedButton, StylizedButtonProps } from 'enova-frontend-components';

export type StylizedRouterLinkProps = Omit<LinkProps, 'instance'> &
  StylizedButtonProps;

// eslint-disable-next-line
const Link: FC<any> = forwardRef(({ navigate, ...rest }, ref) => (
  <RrdLink {...rest} ref={ref} />
));

const StylizedRouterButtonLink: FC<Omit<StylizedRouterLinkProps, 'component'>> =
  forwardRef<HTMLButtonElement, StylizedRouterLinkProps>(
    ({ onClick, ...rest }, ref) => (
      <StylizedButton
        {...rest}
        {...{ component: Link }}
        onClick={(e) => {
          !e.ctrlKey && window.scrollTo(0, 0);
          onClick && onClick(e);
        }}
        ref={ref as React.RefObject<HTMLButtonElement>}
      />
    )
  );

StylizedRouterButtonLink.displayName = 'StylizedRouterButtonLink';

export default StylizedRouterButtonLink;
