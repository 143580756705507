import React, { FC, Fragment } from 'react';
import {
  CollapsibleFactBox,
  Grid,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import useSelector from '../../../../../../hooks/useSelector';
import ReadonlyField from '../../../../../../components/readonlyField';
import RecursiveReadonlyField from '../../../../../../components/recursiveReadonlyField';
import { Himmelretning } from '../../../../../../components/registering/utils/registerEnums';
import {
  getHasConstructionDensityData,
  getHasDoors,
  getHasEnergyUsageData,
  getHasFloorData,
  getHasRoofData,
  getHasShapeAndDirectionData,
  getHasTechnicalEquipmentData,
  getHasVentilationData,
  getHasWallData,
  getHasWindows,
  getTerrainShielding
} from '../../../../../../store/registration/selectors';
import {
  BasicInformation,
  TechnicalEquipmentFields,
  VentilationFields
} from '../../../../../../components/registering/utils/registerConfig';

import DoorsSummarySection from './doorsSummarySection';
import EnergyUsageSummarySection from './energyUsageSummarySection';
import FloorsSummarySection from './floorsSummarySection';
import RoofSummarySection from './roofSummarySection';
import ShapeAndDirectionSummarySection from './shapeAndDirectionSummarySection';
import WallsSummarySection from './wallsSummarySection';
import WindowsSummarySection from './windowsSummarySection';
import { ConstructionDensitySummarySection } from './constructionDensitySummarySection';

const SummaryReview: FC = () => {
  const { t } = useTranslation();

  const hasConstructionDensityData = useSelector(getHasConstructionDensityData);
  const hasDoors = useSelector(getHasDoors);
  const hasEnergyUsageData = useSelector(getHasEnergyUsageData);
  const hasFloorData = useSelector(getHasFloorData);
  const hasRoofData = useSelector(getHasRoofData);
  const hasShapeAndDirectionData = useSelector(getHasShapeAndDirectionData);
  const hasTechnicalEquipmentData = useSelector(getHasTechnicalEquipmentData);
  const hasVentilationData = useSelector(getHasVentilationData);
  const hasWallData = useSelector(getHasWallData);
  const hasWindows = useSelector(getHasWindows);
  const terrainShielding = useSelector(getTerrainShielding, shallowEqual);

  return (
    <Fragment>
      <Typography paragraph>
        {t('register.summary.information.description', { context: 1 })}
      </Typography>

      <Typography paragraph>
        {t('register.summary.information.description', { context: 2 })}
      </Typography>

      <Grid container spacing={4} direction="column">
        <Grid item>
          <CollapsibleFactBox
            initExpanded
            title={t('register.general.basicInformation.title')}
          >
            {BasicInformation.map((defs) => (
              <RecursiveReadonlyField
                key={defs.key ?? defs.attrKey}
                {...defs}
              />
            ))}
          </CollapsibleFactBox>
        </Grid>

        {hasTechnicalEquipmentData && (
          <Grid item>
            <CollapsibleFactBox
              title={t('register.tekniskUtstyr.oppvarming.title')}
            >
              {TechnicalEquipmentFields.map((defs) => (
                <RecursiveReadonlyField
                  groupFields
                  hideLabel
                  key={defs.key ?? defs.attrKey}
                  ValueProps={{ variant: 'h3', component: 'h4' }}
                  {...defs}
                />
              ))}
            </CollapsibleFactBox>
          </Grid>
        )}

        {hasVentilationData && (
          <Grid item>
            <CollapsibleFactBox title={t('register.ventilation.title')}>
              {VentilationFields.map((defs) => (
                <RecursiveReadonlyField
                  key={defs.key ?? defs.attrKey}
                  {...defs}
                />
              ))}
            </CollapsibleFactBox>
          </Grid>
        )}

        {hasShapeAndDirectionData && <ShapeAndDirectionSummarySection />}
        {hasWallData && <WallsSummarySection />}
        {hasWindows && <WindowsSummarySection />}

        {terrainShielding && (
          <Grid item>
            <CollapsibleFactBox title={t('register.terrainShielding.title')}>
              {Object.values(Himmelretning).map((himmelretning) => (
                <ReadonlyField
                  key={himmelretning}
                  label={t(`direction.${himmelretning}`)}
                  value={
                    terrainShielding[himmelretning]
                      ? t(
                          `register.terrainShielding.option.${terrainShielding[himmelretning]}`
                        )
                      : t('register.missingValue')
                  }
                />
              ))}
            </CollapsibleFactBox>
          </Grid>
        )}

        {hasRoofData && <RoofSummarySection />}
        {hasFloorData && <FloorsSummarySection />}
        {hasDoors && <DoorsSummarySection />}
        {hasEnergyUsageData && <EnergyUsageSummarySection />}
        {hasConstructionDensityData && <ConstructionDensitySummarySection />}
      </Grid>
    </Fragment>
  );
};

export default SummaryReview;
