import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

if (window.config.VITE_SENTRY_DSN) {
  const history = createBrowserHistory();

  Sentry.init({
    dsn: window.config.VITE_SENTRY_DSN,
    normalizeDepth: 5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history: history
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true
      })
    ],
    sampleRate: 1,
    tracesSampleRate: 0.2
  });
}
