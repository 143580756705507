import { Box, Button, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import React, { FC, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { ErrorBox } from '../../../../../../../components/errorBox';
import { useXmlStepNavigation } from '../../hooks/useXmlStepNavigation';
import { RegistrationTypeProps } from '../../../types';
import { useXmlRegistrationSearchParams } from '../../hooks/useXmlRegistrationSearchParams';
import { NavigationWrapper } from '../../components/styledComponents';

import { FileUploadHandler } from './fileUploadHandler';

interface Props {
  handleCreateRegistrationHook: {
    handleCreateRegistration: (
      file?: File,
      buildingIds?: string[],
      groupName?: string
    ) => void;
    isLoading: boolean;
    errorMessage?: string;
  };
}

export const Upload: FC<RegistrationTypeProps & Props> = ({
  registrationType,
  handleCreateRegistrationHook
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File>();
  const { navigateToStep } = useXmlStepNavigation();
  const { selectedBuildingIds, groupName } = useXmlRegistrationSearchParams();
  const { errorMessage, handleCreateRegistration, isLoading } =
    handleCreateRegistrationHook;

  if (selectedBuildingIds.length === 0) {
    return <Redirect to={`/xml-registrering/${registrationType}/velg-bygg`} />;
  }

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <Typography variant="h2">{t('energyAssessment.title_xml')}</Typography>
      <Typography>{t('energyAssessment.description_xml')}</Typography>
      <Box alignContent="center">
        <Typography variant="h3">
          {t('expertToolsXML.step.lastOppFil')}
        </Typography>
        <Box my={5}>
          <FileUploadHandler
            file={file}
            setFile={setFile}
            isLoading={isLoading}
          />
        </Box>
      </Box>

      {errorMessage && (
        <ErrorBox
          descriptionKey={errorMessage}
          titleKey={t('expertToolsXML.error.noSelectedFile.title')}
        />
      )}

      <NavigationWrapper>
        <Button
          disabled={isLoading}
          onClick={() => navigateToStep('velg-bygg', registrationType)}
        >
          {t('previous')}
        </Button>
        <Button
          disabled={isLoading}
          onClick={() =>
            handleCreateRegistration(file, selectedBuildingIds, groupName || '')
          }
        >
          {t('expertToolsXML.actions.next_oppsummeringAvFil')}
        </Button>
      </NavigationWrapper>
    </Box>
  );
};
