import React, { FC } from 'react';

import { Address, Energiattest } from '../../../../types/building';

import { EnergyCertificate } from './components/energyCertificate';
import { CertificateWithReservation } from './components/certificateWithReservation';

interface Props {
  attest: Energiattest;
  address: Address;
  isOwner?: boolean;
}

export const EnergyCertificateWrapper: FC<Props> = ({
  attest,
  address,
  isOwner
}) => {
  // When synlighet = "reservert" only owner can view the certificate
  if (attest.synlighet === 'reservert' && !isOwner) {
    return <CertificateWithReservation />;
  }

  return <EnergyCertificate energyCertificate={attest} address={address} />;
};
