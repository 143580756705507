import React, { FC, Fragment } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useUser } from '../../hooks/useUser';
import { SessionExpiresDialog } from '../../components/sessionExpiresDialog';
import { AreaOfExpertise } from '../../types/user';

import { ProfileForm } from './form';
import { ProfileFormData } from './types';

const ProfileScreen: FC = () => {
  const { user } = useUser();
  const fagomrader = user?.fagomrader || [];

  const defaultValues = {
    rolle: user?.rolle,
    kjelOgVarmeanlegg: fagomrader.includes(AreaOfExpertise.KJELOGVARMEANLEGG),
    bygningsekspert: fagomrader.includes(AreaOfExpertise.BYGNINGSEKSPERT),
    energiradgiver: fagomrader.includes(AreaOfExpertise.ENERGIRADGIVER),
    ventilasjonsOgKjoleanlegg: fagomrader.includes(
      AreaOfExpertise.VENTILASJONOGKJOLEANLEGG
    )
  };

  const methods = useForm<ProfileFormData>({
    defaultValues
  });

  return (
    <Fragment>
      <FormProvider {...methods}>
        <ProfileForm />
      </FormProvider>
      <SessionExpiresDialog />
    </Fragment>
  );
};

export default ProfileScreen;
