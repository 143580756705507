import { Box, styled } from 'enova-frontend-components';

interface FloorAreaWrapperProps {
  isCurrentFloor?: boolean;
  isFirstElement?: boolean;
  isLastElement?: boolean;
}

export const FloorAreaWrapper = styled(Box)<FloorAreaWrapperProps>`
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 2px;
  border-color: ${({ theme, isCurrentFloor }) =>
    `${isCurrentFloor ? theme.palette.fjordGreen : '#d0d2d2'}`};
  padding: 15px;
  opacity: ${({ isCurrentFloor }) => `${isCurrentFloor ? 1 : 0.6}`};
  flex: 1;
  border-radius: ${({ isFirstElement, isLastElement }) => {
    if (isFirstElement) {
      return '5px 0 0 5px';
    }
    if (isLastElement) {
      return '0 5px 5px 0';
    }

    return '0';
  }};
`;
