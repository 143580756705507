import { useQuery } from 'react-query';

import { getEnergyAnalysis } from '../services/api';
import { queryKeys } from '../utils/react-query';

export const useEnergyAnalysis = (registrationId: string | undefined) => {
  const { data, isLoading } = useQuery(
    [queryKeys.energyAnalysis, registrationId],
    () => getEnergyAnalysis(registrationId),
    { enabled: !!registrationId }
  );

  const energyAnalysis = data?.[0];

  return {
    energyAnalysis,
    energyAnalysisLoading: isLoading
  };
};
