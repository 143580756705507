import React, { FC } from 'react';
import { EnergyRatingIndicator, styled } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { Energiattest } from '../../../../../../../types/building';

const StyledEnergyRatingIndicator = styled(EnergyRatingIndicator)`
  transform: scale(0.8);
`;

interface Props {
  energiattest?: Energiattest;
}

export const EnergyRatingIndicatorWrapper: FC<Props> = ({ energiattest }) => {
  const { t } = useTranslation();

  const energiKarakter = energiattest?.energiKarakter;
  const oppvarmingKarakter = energiattest?.oppvarmingKarakter;

  return (
    <StyledEnergyRatingIndicator
      title={!(energiKarakter && oppvarmingKarakter) ? t('noEnergyRating') : ''}
      energyGrade={energiKarakter}
      heatingGrade={oppvarmingKarakter}
    />
  );
};
