import React from 'react';
import { Accordion, Box, FactBox, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import useSelector from '../../../../hooks/useSelector';
import { getEtasjer } from '../../../../store/registration/selectors';
import { useFloorTitle } from '../../utils/useFloorTitle';

import { Wall } from './components/wall';
import { WindowsEmptyState } from './components/windowsEmptyState';

export const Windows = () => {
  const etasjer = useSelector(getEtasjer, shallowEqual);
  const { t } = useTranslation();
  const { getFloorTitle } = useFloorTitle();

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="h2">{t('register.windows.title')}</Typography>
      <Typography>{t('register.windows.description')}</Typography>

      {etasjer.map((etasje, floorIndex) => {
        const floorTitle = getFloorTitle(etasje.etasjeNummer, etasje.erKjeller);

        return (
          <FactBox key={floorIndex} title={floorTitle} dense>
            <Accordion>
              {etasje?.vegger?.length === 0 ? (
                <WindowsEmptyState />
              ) : (
                etasje.vegger?.map((wall, wallIndex) => (
                  <Wall
                    key={wallIndex}
                    wall={wall}
                    etasjeId={etasje?.id}
                    shape={etasje?.bygningsForm}
                  />
                ))
              )}
            </Accordion>
          </FactBox>
        );
      })}
    </Box>
  );
};
