import { format as formatDateFns } from 'date-fns';
import { useTranslation } from 'react-i18next';

type InputType = Date | number | string;

type BaseDateFormatter = (date?: InputType, outputFormat?: string) => string;

type DateFormatter = (date?: InputType) => string;

type DateFormatUtils = {
  format: BaseDateFormatter;
  formatDate: DateFormatter;
  formatDateMinimized: DateFormatter;
  formatDateTime: DateFormatter;
  formatDateTimeMinimized: DateFormatter;
  formatTime: DateFormatter;
  formatTimeMinimized: DateFormatter;
};

/**
 * Hook for consistent localized date formatting
 */
const useFormatDate = (): DateFormatUtils => {
  const { t } = useTranslation();

  const dateFormat = t('format.date');
  const dateFormatMinimized = t('format.dateMinimized');

  const timeFormat = t('format.time');
  const timeFormatMinimized = t('format.timeMinimized');

  const dateTimeFormat = `${dateFormat}, ${timeFormat}`;
  const dateTimeFormatMinimized = `${dateFormatMinimized}, ${timeFormatMinimized}`;

  const format: BaseDateFormatter = (date, outputFormat = dateFormat): string =>
    date ? formatDateFns(new Date(date), outputFormat) : '';

  const formatDate: DateFormatter = (date) => format(date, dateFormat);

  const formatDateMinimized: DateFormatter = (date) =>
    format(date, dateFormatMinimized);

  const formatTime: DateFormatter = (date) => format(date, timeFormat);

  const formatTimeMinimized: DateFormatter = (date) =>
    format(date, timeFormatMinimized);

  const formatDateTime: DateFormatter = (date) => format(date, dateTimeFormat);

  const formatDateTimeMinimized: DateFormatter = (date) =>
    format(date, dateTimeFormatMinimized);

  return {
    format,
    formatDate,
    formatDateMinimized,
    formatDateTime,
    formatDateTimeMinimized,
    formatTime,
    formatTimeMinimized
  };
};

export default useFormatDate;
