import React from 'react';
import { useTranslation } from 'react-i18next';

import DismissableInfoBox from '../../../components/dismissableInfoBox';
import BuildingFilterProvider from '../../../hooks/useBuildingFilter/provider';
import { useUser } from '../../../hooks/useUser';
import Buildings from '../buildings';
import { FilterCategory } from '../../../hooks/useBuildingFilter/context';

import { useUserSelectedBuildingList } from './useUserSelectedBuildingList';

export const OtherBuildingsTabContent = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  const { buildings, buildingsLoading, error, refreshBuildings } =
    useUserSelectedBuildingList();

  const filterCategories = [
    FilterCategory.GEOGRAPHY,
    FilterCategory.BUILDING_CATEGORY
  ];

  return (
    <React.Fragment>
      <DismissableInfoBox
        localStorageKey={user?.userId}
        title={t('mainScreen.otherBuildings.info.title')}
      >
        {t('mainScreen.otherBuildings.info.content')}
      </DismissableInfoBox>

      <BuildingFilterProvider
        allowFilter
        buildingList={{
          buildings,
          buildingsError: !!error,
          buildingsLoading,
          refreshBuildings
        }}
        categories={filterCategories}
      >
        <Buildings />
      </BuildingFilterProvider>
    </React.Fragment>
  );
};
