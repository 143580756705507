import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnack } from 'enova-frontend-components';

import { XMLResponse } from '../../../../../../services/types';
import { useFileUpload } from '../../common/steps/upload/useFileUpload';
import { createErrorMessage } from '../../../../../../utils/utils';
import { XMLReport } from '../../../../../../types/expertToolsXML';

import { useCreateEnhetRegistration } from './useCreateEnhetRegistration';

export const useHandleCreateEnhetRegistration = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const history = useHistory();
  const { t } = useTranslation();
  const { addSnack } = useSnack();
  const { parseAndUpload } = useFileUpload();

  const { createExpertRegistration, isLoading } =
    useCreateEnhetRegistration('buildingId');

  const handleXmlResponse = (response?: XMLResponse) => {
    const energianalyse =
      response?.energiAnalysePrivatBolig ?? response?.energiAnalyseYrkesBygg;

    if (response?.error) {
      setErrorMessage(`expertToolsXML.error.${response.error}`);
      return;
    }

    if (!energianalyse) {
      const errorMessage = createErrorMessage(
        t('expertToolsXML.error_couldNotCreateRegistration')
      );

      addSnack(errorMessage, { variant: 'error' });
      return;
    }

    history.push(
      `/xml-registrering/enhet/oppsummering-av-fil/${energianalyse.registreringId}`
    );
  };

  const handleCreateRegistration = (file?: File, buildingIds?: string[]) => {
    if (!file || !buildingIds || buildingIds.length === 0) {
      setErrorMessage(t('expertToolsXML.error.noSelectedFile.content'));
      return;
    }

    const onSingleUnitUpload = (report: XMLReport) =>
      createExpertRegistration(
        { report, bruksenhetsId: buildingIds[0] },
        {
          onSuccess: (data) => handleXmlResponse(data)
        }
      );

    parseAndUpload({ file, onUpload: onSingleUnitUpload });
  };

  return { handleCreateRegistration, isLoading, errorMessage };
};
