import { Radio, RadioGroup } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Malform } from '../../../../../../components/registering/utils/registerEnums';
import { SimpleEnergyAnalysis } from '../../../../../../types/building';
import { useChangeLanguage } from '../../../../../main/xmlRegistrationView/xmlRegistration/common/steps/summary/summaryTabs/tabs/calculatedEnergyRatingTab/useChangeLanguage';

interface Props {
  energianalyse?: SimpleEnergyAnalysis;
}

export const MalformRadio: FC<Props> = ({ energianalyse }) => {
  const { t } = useTranslation();

  const { changeLanguage, malform } = useChangeLanguage(energianalyse);

  return (
    <RadioGroup
      label={t(
        'register.summary.downloadEnergyCertificatePreview.selectMalform.label'
      )}
      marginBottom
      onChange={(_, val) => changeLanguage(val as Malform)}
      row
      value={malform}
    >
      <Radio label={t('malform.bokmal')} value={Malform.Bokmal} width="auto" />

      <Radio
        label={t('malform.nynorsk')}
        value={Malform.Nynorsk}
        width="auto"
      />
    </RadioGroup>
  );
};
