import { SoknadSteg } from '../../components/registering/utils/registerEnums';
import {
  DetailedBuildingDetails,
  SimpleEnergyAnalysis
} from '../../types/building';
import { SoknadStegMap } from '../../types/soknad';

// This should not live on the client. Creates a map of the
// different steps in the application process.
export const createSoknadStegMap = (
  building: DetailedBuildingDetails,
  simpleEnergyAnalyses: SimpleEnergyAnalysis[]
) => {
  const { gyldigSoknad } = building;

  if (!gyldigSoknad?.soknadId) {
    return undefined;
  }

  const soknadSteps = {
    [SoknadSteg.Steg1]: gyldigSoknad?.registreringForSteg1,
    [SoknadSteg.Steg2]: gyldigSoknad?.registreringForSteg2,
    [SoknadSteg.Steg3]: gyldigSoknad?.registreringForSteg3
  };

  return Object.entries(soknadSteps).reduce((acc, [soknadSteg, id]) => {
    if (!id) {
      return { ...acc, [soknadSteg]: undefined };
    }

    const analysis = simpleEnergyAnalyses?.find(
      ({ registreringId }) => registreringId === id
    );

    const lukketSoknadSteg2 =
      analysis?.energiplan?.energimerke?.properties?.lukketSoknadSteg2;
    const publisertDato = analysis?.registrering.publisertDato;
    const done =
      soknadSteg === SoknadSteg.Steg2 ? !!lukketSoknadSteg2 : !!publisertDato;

    return {
      ...acc,
      [soknadSteg]: {
        analysis,
        done,
        id,
        soknadSteg
      }
    };
  }, {} as SoknadStegMap);
};
