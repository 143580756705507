import { Button, Dialog } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateBuildingData } from './types';

export interface ExistingBuildingDialogProps {
  onConfirm: (buildingData?: CreateBuildingData, responseData?: string) => void;
  onCancel: () => void;
  buildingData?: CreateBuildingData;
  responseData?: string;
}

export const ExistingBuildingDialog: FC<ExistingBuildingDialogProps> = ({
  buildingData,
  responseData,
  onCancel,
  onConfirm
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={!!buildingData} size="md" onClose={onCancel}>
      <Dialog.Title>{t('building.existingDialog.title')}</Dialog.Title>

      <Dialog.Content>
        {t('building.existingDialog.content', {
          kommunenavn: buildingData?.kommunenavn
        })}
      </Dialog.Content>

      <Dialog.Actions>
        <Button variant="secondary" onClick={() => onCancel()}>
          {t('cancel')}
        </Button>
        <Button onClick={() => onConfirm(buildingData, responseData)}>
          {t('building.existingDialog.goToBuilding')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
