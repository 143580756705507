import React, { FC, Fragment, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FullScreenLoader,
  Grid,
  MainContentBox,
  Stepper,
  TextArea,
  TextField,
  Typography,
  useSnack
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  BuildingScreenTab,
  camelCasify,
  ExpertToolsParams,
  ExpertToolsStep,
  getBuildingScreenPath
} from '../../utils/navigation';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import RouterButtonLink from '../../components/routerButtonLink';
import RouterLink from '../../components/routerLink';
import useBuildingDetails from '../../hooks/useBuildingDetails';
import BuildingHeaderInfo from '../../components/buildingHeaderInfo';
import { ActionBarHeight } from '../../utils/layout';
import { ExpertReport } from '../../types/building';
import { SessionExpiresDialog } from '../../components/sessionExpiresDialog';
import { createErrorMessage } from '../../utils/utils';

import { useCreateExpertReport } from './useCreateExpertReport';

const StepperId = 'stepper';

const steps = Object.values(ExpertToolsStep);

const ExpertToolsScreen: FC = () => {
  const { t } = useTranslation();
  const { xsScreen } = useMediaQuery();
  const { buildingId, area } = useParams<ExpertToolsParams>();
  const { address } = useBuildingDetails();
  const { addSnack } = useSnack();

  const { createExpertReport, isLoading } = useCreateExpertReport(buildingId);

  const [step, setStep] = useState(ExpertToolsStep.UPLOAD);
  const [report, setReport] = useState<Partial<ExpertReport>>({
    tittel: '',
    rapport: ''
  });

  const { tittel, rapport } = report;

  const handleUpdateReportField = (
    field: keyof ExpertReport,
    value: string
  ) => {
    setReport((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const getStepperTOptions = () => {
    const stepIndex = Object.values(ExpertToolsStep).indexOf(step);

    return {
      stepCount: steps.length,
      stepName: steps[stepIndex]
        ? t(`expertTools.step.${camelCasify(steps[stepIndex])}`)
        : '',
      stepNo: stepIndex + 1
    };
  };

  const camelCasifiedStep = camelCasify(step);
  const camelCasifiedArea = camelCasify(area);

  const helmetTitle = [
    t(`expertTools.title`, {
      context: camelCasifiedArea
    }),
    address
  ].join(' | ');

  const submitReport = () => {
    if (!buildingId || !report?.tittel || !report?.rapport) {
      return;
    }

    const fullReport: ExpertReport = {
      tittel: report.tittel,
      rapport: report.rapport,
      energiObjektId: buildingId,
      ekspertRapportType: area
    };

    createExpertReport(fullReport, {
      onSuccess: () => setStep(ExpertToolsStep.CONFIRMATION),
      onError: () => {
        addSnack(createErrorMessage(), { variant: 'error' });
      }
    });
  };

  return (
    <Fragment>
      <Helmet title={helmetTitle} />

      <FullScreenLoader open={isLoading} />

      <MainContentBox>
        <RouterLink
          className="mb-3 as-start"
          to={getBuildingScreenPath({
            buildingId,
            tab: BuildingScreenTab.ENERGY_ASSESSMENT
          })}
        >
          {t('navigation.expertToolsScreen.back')}
        </RouterLink>

        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <Typography variant="h1" mb={0}>
            {t(`expertTools.title`, {
              context: camelCasifiedArea
            })}
          </Typography>

          <BuildingHeaderInfo
            energiobjektId={buildingId}
            AddressProps={{ variant: 'h2', component: 'p' }}
          />
        </Box>
      </MainContentBox>

      <Divider />

      <MainContentBox>
        <Grid container spacing={8}>
          <Grid item xs={12} md={4} lg={3} position="relative">
            <Box position="sticky" top={85 + ActionBarHeight}>
              <Box id={StepperId}>
                <Typography variant="srOnly">
                  {t('stepper', getStepperTOptions())}
                </Typography>

                <Stepper aria-hidden>
                  {steps.map((s, index) => (
                    <Stepper.Step
                      active={s === step}
                      done={step === ExpertToolsStep.CONFIRMATION}
                      key={s}
                      label={`${index + 1}. ${t(
                        `expertTools.step.${camelCasify(s)}`
                      )}`}
                    />
                  ))}
                </Stepper>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={8} lg={9}>
            <Grid container spacing={4}>
              {step === ExpertToolsStep.UPLOAD && (
                <Fragment>
                  <Grid item xs={12}>
                    <Typography variant="h2" gutterBottom>
                      {t(`expertTools.step.${camelCasifiedStep}.heading`, {
                        context: camelCasifiedArea
                      })}
                    </Typography>

                    <Typography paragraph className="text-preline">
                      {t(`expertTools.step.${camelCasifiedStep}.description1`, {
                        context: camelCasifiedArea
                      })}
                    </Typography>

                    <Typography paragraph className="text-preline">
                      {t(`expertTools.step.${camelCasifiedStep}.description2`, {
                        context: camelCasifiedArea
                      })}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth={xsScreen}
                      label={t(
                        `expertTools.step.${camelCasifiedStep}.report.title.label`
                      )}
                      name="tittel"
                      onChange={({ currentTarget: { name, value } }) =>
                        handleUpdateReportField(
                          name as keyof ExpertReport,
                          value
                        )
                      }
                      required
                      value={tittel}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextArea
                      fullWidth
                      label={t(
                        `expertTools.step.${camelCasifiedStep}.report.content.label`
                      )}
                      name="rapport"
                      onChange={({ currentTarget: { name, value } }) =>
                        handleUpdateReportField(
                          name as keyof ExpertReport,
                          value
                        )
                      }
                      required
                      value={rapport}
                    />
                  </Grid>

                  <Grid item xs={12} container spacing={4}>
                    <Grid item xs={12} sm="auto">
                      <RouterButtonLink
                        fullWidth
                        to={getBuildingScreenPath({
                          buildingId,
                          tab: BuildingScreenTab.ENERGY_ASSESSMENT
                        })}
                        variant="secondary"
                      >
                        {t('cancel')}
                      </RouterButtonLink>
                    </Grid>

                    <Grid item xs={12} sm="auto" marginLeft="auto">
                      <Button
                        disabled={!tittel || !rapport}
                        fullWidth
                        onClick={submitReport}
                      >
                        {t('expertTools.uploadReport.submit')}
                      </Button>
                    </Grid>
                  </Grid>
                </Fragment>
              )}

              {step === ExpertToolsStep.CONFIRMATION && (
                <Fragment>
                  <Grid item xs={12}>
                    <Typography variant="h2" gutterBottom>
                      {t(`expertTools.step.${camelCasifiedStep}.heading`)}
                    </Typography>

                    <Typography paragraph>
                      {t(`expertTools.step.${camelCasifiedStep}.description`)}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <RouterLink
                      to={getBuildingScreenPath({
                        buildingId,
                        tab: BuildingScreenTab.ENERGY_ASSESSMENT
                      })}
                    >
                      {t(`expertTools.step.${camelCasifiedStep}.goBack`)}
                    </RouterLink>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>
      </MainContentBox>
      <SessionExpiresDialog />
    </Fragment>
  );
};

export default ExpertToolsScreen;
