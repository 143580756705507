import { useMutation } from 'react-query';

import { archiveAndCloneRegistration } from '../../../services/api';
import { queryClient, queryKeys } from '../../../utils/react-query';

export const useArchiveAndCloneRegistration = () => {
  const { mutate, isLoading } = useMutation(
    ({
      registrationId,
      energiplanId
    }: {
      registrationId: string;
      energiplanId: string;
    }) => archiveAndCloneRegistration(registrationId, energiplanId),
    {
      onSuccess: async () => {
        return await queryClient.invalidateQueries([queryKeys.building]);
      }
    }
  );

  return {
    archiveRegistration: mutate,
    isLoading
  };
};
