import React, { FC, Fragment } from 'react';
import { Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { Tiltak } from '../../types/registration/tiltak';
import i18n, { Language } from '../../i18n';

export const TiltakView: FC<{ tiltak: Tiltak; tiltakIndex: number }> = ({
  tiltak,
  tiltakIndex
}) => {
  const { t } = useTranslation();

  const { beskrivelseBokmal, beskrivelseNynorsk, navnBokmal, navnNynorsk } =
    tiltak;

  const curLng = i18n.language as Language;
  const tiltakName = curLng === Language.NN_NO ? navnNynorsk : navnBokmal;
  const tiltakBeskrivelse =
    curLng === Language.NN_NO ? beskrivelseNynorsk : beskrivelseBokmal;

  return (
    <Fragment>
      <Typography bold component="h5">
        {t('keyValue', {
          key: t('tiltak.tiltakNo', {
            count: tiltakIndex + 1
          }),
          value: tiltakName
        })}
      </Typography>

      <Typography className="text-preline">{tiltakBeskrivelse}</Typography>
    </Fragment>
  );
};
