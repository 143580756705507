import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'rooks';

import { updateParameters } from '../../../../store/registration/actions';

import { State, useAdjustParametersFormContext } from './utils';

// This hook updates the Redux store with form data which allows saving when
// navigating via breadcrumbs on the left side of the registration page.
export const useUpdateReduxStore = () => {
  const dispatch = useDispatch();
  const { watch } = useAdjustParametersFormContext();

  const debouncedUpdate = useDebounce((data: State) => {
    dispatch(updateParameters({ data }));
  }, 1000);

  useEffect(() => {
    const subscription = watch((formValues) => {
      debouncedUpdate(formValues);
    });

    return () => subscription.unsubscribe();
  }, [watch]);
};
