import { Typography } from 'enova-frontend-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TilliggendeRom } from '../utils/registerEnums';

import { WindowType } from './utils';

interface WindowNotAllowedProps {
  adjoiningRoom: TilliggendeRom | undefined;
  type: WindowType;
}

export const WindowNotAllowed: FC<WindowNotAllowedProps> = ({
  adjoiningRoom,
  type
}) => {
  const { t } = useTranslation();

  if (type === 'roof') {
    return null;
  }

  return (
    <Typography>
      {t('register.windows.windowsNotAllowed', {
        context: type.unified ? 'wall' : 'zone',
        wallZoneId: `${type.veggId.toUpperCase()}${type.veggsoneIndex + 1}`,
        veggId: type.veggId.toUpperCase(),
        adjoiningRoom: t(
          `register.adjoiningRooms.${adjoiningRoom}`
        ).toLowerCase()
      })}
    </Typography>
  );
};
