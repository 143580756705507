import React, { FC } from 'react';
import {
  Box,
  Hidden,
  Logo,
  styled,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

const LogoWrapper = styled.div`
  align-items: center;
  display: flex;

  .logo-text {
    color: white;
    margin-left: 5px;
    font-size: 24px;
    font-weight: 100;
    height: 40px;
    width: 150px;
  }
`;

const EMSLogo: FC = () => {
  const { t } = useTranslation();

  return (
    <LogoWrapper>
      <Hidden smDown>
        <Logo title={t('companyLogo')} className="logo" />
        <Typography className="logo-text">| ENERGIMERKE</Typography>
      </Hidden>

      <Hidden smUp>
        <Box>
          <Logo title={t('companyLogo')} className="logo" />
        </Box>
      </Hidden>
    </LogoWrapper>
  );
};

export default EMSLogo;
