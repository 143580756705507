import { useMutation, useQueryClient } from 'react-query';

import * as api from '../services/api';
import { queryKeys } from '../utils/react-query';

export const usePublishRegistration = (
  registrationId: string | undefined,
  buildingId: string
) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    () => api.publishRegistration(registrationId),
    {
      onSuccess: () => {
        // Refresh the energyanalysis for the registration so we can get the updated published date.
        queryClient.invalidateQueries([
          queryKeys.energyAnalysis,
          registrationId
        ]);
        // Update all building queries. Dont know why this is important
        queryClient.invalidateQueries([queryKeys.building, buildingId]);
        queryClient.invalidateQueries([
          queryKeys.energyAnalysisList,
          buildingId
        ]);
      }
    }
  );

  return { publishRegistration: mutate, publishIsLoading: isLoading };
};
