import React, { FC } from 'react';
import { Select } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Controller, useWatch } from 'react-hook-form';

import { getInsulationOptions } from '../../../../../utils/insulation';
import {
  VeggTypeIsolasjonsTykkelseMap,
  WallZoneFormFieldProps
} from '../utils';

export const InsulationSelect: FC<WallZoneFormFieldProps> = ({ control }) => {
  const { t } = useTranslation();

  const wallType = useWatch({
    control,
    name: `wallZoneData.wallZone.veggKonstruksjon.veggType`
  });

  const options = getInsulationOptions(
    t,
    wallType ? VeggTypeIsolasjonsTykkelseMap[wallType] : []
  );

  return (
    <Controller
      control={control}
      name="wallZoneData.wallZone.veggKonstruksjon.isolasjonstykkelseIMillimeter"
      rules={{
        validate: {
          required: (value, { wallZoneData }) => {
            // Only required if detailing is set to 'construction'
            if (
              wallZoneData.detailing === 'construction' &&
              (!value || value == -1)
            ) {
              return t('required');
            }

            return true;
          }
        }
      }}
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error }
      }) => {
        return (
          <Select
            {...rest}
            disabled={!wallType}
            error={!!error}
            helperText={error?.message}
            label={t('register.isolation.label')}
            options={options}
            value={value ?? -1}
            onChange={(e) => onChange(e?.currentTarget?.value)}
            HelperBoxProps={{
              'aria-label': t('readFieldInformation'),
              children: t('register.wallIsolation.information')
            }}
          />
        );
      }}
    />
  );
};
