import React, { FC, useState } from 'react';
import { Box, Button, Paper } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { VeggId } from '../../../utils/registerEnums';
import { AddWindowForm } from '../window/addWindowForm';

interface Props {
  wallId: VeggId;
  etasjeId: string;
  formVisible?: boolean;
}

export const AddWindow: FC<Props> = ({ wallId, etasjeId, formVisible }) => {
  const { t } = useTranslation();
  const [addFormVisible, setAddFormVisible] = useState(!!formVisible);

  if (!addFormVisible) {
    return (
      <Box m={5}>
        <Button
          variant="secondary"
          onClick={() => {
            setAddFormVisible(true);
          }}
        >
          {t('register.window.addNew')}
        </Button>
      </Box>
    );
  }

  return (
    <Box m={5}>
      <Paper shadow="none" style={{ border: 'solid 1px #d0d2d2' }}>
        <AddWindowForm
          onSave={() => setAddFormVisible(false)}
          onCancel={() => setAddFormVisible(false)}
          wallId={wallId}
          etasjeId={etasjeId}
        />
      </Paper>
    </Box>
  );
};
