import React, { FC, Fragment } from 'react';
import {
  Box,
  Divider,
  MainContentBox,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { CreateWorkListForm } from './createWorkListForm';
import { WorklistTable } from './worklistTable/worklistTable';

export const WorklistOverview: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <MainContentBox>
        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <Typography variant="h1" mb={0}>
            {t('expertToolsXMLPortfolio.title')}
          </Typography>
        </Box>
      </MainContentBox>

      <Divider />

      <MainContentBox>
        <CreateWorkListForm />
        <WorklistTable />
      </MainContentBox>
    </Fragment>
  );
};
