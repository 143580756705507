import React, { FC, Fragment, useMemo } from 'react';
import { FactBox, Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import useSelector from '../../../../../../hooks/useSelector';
import useStore from '../../../../../../hooks/useStore';
import { getUtdata } from '../../../../../../store/registration/selectors';
import ReadonlyListField from '../../../../../../components/readonlyListField';

const SummaryResult: FC = () => {
  const { t } = useTranslation();
  const store = useStore();

  const {
    bygningsdetaljer: {
      totalBRA = undefined,
      totalOppvarmetBRA = undefined
    } = {}
  } = store.getState().register.userInput;

  const energiplan = useSelector(
    (state) => state.register.energiplan,
    shallowEqual
  );

  const {
    energimerke: {
      energiKarakter = undefined,
      properties: { oppvarmingKarakter = undefined } = {}
    } = {}
  } = energiplan || {};

  const utdata = useSelector(getUtdata);
  const { energiforbruk } = utdata;

  const filteredEnergiBehov = useMemo(() => {
    return {
      romoppvarming: energiforbruk.romoppvarming,
      varmtvann: energiforbruk.varmtvann,
      vifter: energiforbruk.vifter,
      pumper: energiforbruk.pumper,
      belysning: energiforbruk.belysning,
      tekniskutstyr: energiforbruk.teknisk_utstyr,
      total: energiforbruk.totalt_netto_energibehov
    };
  }, [energiforbruk]);

  const filteredLevertEnergi = useMemo(() => {
    return {
      elektrisitet: energiforbruk.elektrisitet,
      olje: energiforbruk.olje,
      gass: energiforbruk.gass,
      biobrensel: energiforbruk.biobrensel,
      fjernvarme: energiforbruk.fjernvarme,
      annenEnergivare: energiforbruk.annen_energivare,
      total: energiforbruk.totalt_levert_energi
    };
  }, [energiforbruk]);

  return (
    <Fragment>
      <Typography paragraph>
        {t('register.summary.result.description', { context: 1 })}
      </Typography>

      <Typography paragraph>
        {t('register.summary.result.description', { context: 2 })}
      </Typography>

      <Grid container spacing={4} direction="column">
        <Grid item>
          <FactBox title={t('register.summary.header.merkeAreal')}>
            <ReadonlyListField
              label={t('energyLabel.energyGrade')}
              value={energiKarakter?.toUpperCase()}
            />
            <ReadonlyListField
              label={t('energyLabel.heatingGrade')}
              value={t('energyLabel.heatingGrade', {
                context: oppvarmingKarakter
              })}
            />
            <ReadonlyListField
              label={t('register.summary.result.bruksAreal')}
              value={totalBRA ? totalBRA + ' m²' : '0 m²'}
            />
            <ReadonlyListField
              label={t('register.summary.result.oppvarmetAreal')}
              value={totalOppvarmetBRA ? totalOppvarmetBRA + ' m²' : '0 m²'}
            />
          </FactBox>
        </Grid>

        <Grid item>
          <FactBox title={t('register.summary.header.nettoEnergibehov')}>
            {Object.entries(filteredEnergiBehov).map(([key, value]) => (
              <ReadonlyListField
                key={key}
                label={t(`register.summary.result.${key}`)}
                value={value.toFixed(1) + ' kWh'}
              />
            ))}
          </FactBox>
        </Grid>

        <Grid item>
          <FactBox title={t('register.summary.header.spesifiktEnergibehov')}>
            {Object.entries(filteredEnergiBehov).map(([key, value]) => (
              <ReadonlyListField
                key={key}
                label={t(`register.summary.result.${key}`)}
                value={
                  totalOppvarmetBRA
                    ? (value / totalOppvarmetBRA).toFixed(2) + ' kWh/m²'
                    : '0 kWh/m²'
                }
              />
            ))}
          </FactBox>
        </Grid>

        <Grid item>
          <FactBox title={t('register.summary.header.levertEnergi')}>
            {Object.entries(filteredLevertEnergi).map(([key, value]) => (
              <ReadonlyListField
                key={key}
                label={t(`register.summary.result.${key}`)}
                value={value.toFixed(1) + ' kWh'}
              />
            ))}
          </FactBox>
        </Grid>

        <Grid item>
          <FactBox title={t('register.summary.header.spesifiktLevertEnergi')}>
            {Object.entries(filteredLevertEnergi).map(([key, value]) => (
              <ReadonlyListField
                key={key}
                label={t(`register.summary.result.${key}`)}
                value={
                  totalOppvarmetBRA
                    ? (value / totalOppvarmetBRA).toFixed(2) + ' kWh/m²'
                    : '0 kWh/m²'
                }
              />
            ))}
          </FactBox>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SummaryResult;
