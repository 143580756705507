import { useTranslation } from 'react-i18next';

export const useFloorTitle = () => {
  const { t } = useTranslation();

  const getFloorTitle = (
    floorNo: number,
    isBasement: boolean,
    sameConstructionAllFloors?: boolean
  ) => {
    if (sameConstructionAllFloors) {
      return t('register.buildingShape.allFloors');
    }

    if (isBasement) {
      return t('register.buildingShape.basement');
    }

    return t('floor', { floorNo });
  };

  return { getFloorTitle };
};
