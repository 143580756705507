import React, { FC, useEffect } from 'react';
import { Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import useRegistrationForm from '../../../../screens/registration/useRegistrationForm';
import useSelector from '../../../../hooks/useSelector';
import { updateDetailingSteps } from '../../../../store/registration/actions';
import { getPlasseringAvEnhetIByggVertikalt } from '../../../../store/registration/selectors';
import {
  DetailingStep,
  PlasseringAvEnhetIByggVertikalt
} from '../../utils/registerEnums';

import General from './general';
import TechnicalEquipment from './technicalEquipment';
import Ventilation from './ventilation';
import { DetailingCheckBoxes } from './detailingCheckBoxes';

const Base: FC = () => {
  const { t } = useTranslation();
  const { soknadSteg } = useRegistrationForm();
  const plasseringVertikalt = useSelector(getPlasseringAvEnhetIByggVertikalt);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!plasseringVertikalt) {
      return;
    }

    // Do not require takkonstruksjon if plasseringVertikalt has a value
    // and building is not located on the top floor
    if (plasseringVertikalt !== PlasseringAvEnhetIByggVertikalt.OversteEtasje) {
      dispatch(
        updateDetailingSteps({
          step: DetailingStep.Takkonstruksjon,
          value: false
        })
      );
    } else if (soknadSteg) {
      dispatch(
        updateDetailingSteps({
          step: DetailingStep.Takkonstruksjon,
          value: true
        })
      );
    }
  }, [plasseringVertikalt]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <General />
      </Grid>

      <Grid item xs={12}>
        <TechnicalEquipment />
      </Grid>

      <Grid item xs={12}>
        <Ventilation />
      </Grid>

      {soknadSteg ? (
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            {t('register.basicInformation.buildingDetailsSoknad.title')}
          </Typography>

          <Typography gutterBottom className="text-preline">
            {t('register.basicInformation.buildingDetailsSoknad.description')}
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            {t('register.basicInformation.buildingDetails.title')}
          </Typography>

          <Typography gutterBottom className="text-preline">
            {t('register.basicInformation.buildingDetails.description')}
          </Typography>

          <DetailingCheckBoxes />
        </Grid>
      )}
    </Grid>
  );
};

export default Base;
