import React, { FC } from 'react';
import { Accordion, FactBox } from 'enova-frontend-components';

import { Etasje as EtasjeType } from '../../../../../types/registration/userInput';
import { CustomAccordionItem } from '../../../../customAccordionItem';
import { useFloorTitle } from '../../../utils/useFloorTitle';

import { Wall } from './wall';
import { WallsEmptyState } from './wallsEmptyState';
import { WallTitle } from './wallTitle';

interface EtasjeProps {
  etasje: EtasjeType;
  sameConstructionAllFloors: boolean;
}

export const Etasje: FC<EtasjeProps> = ({
  etasje,
  sameConstructionAllFloors
}) => {
  const { erKjeller, etasjeNummer, id, bygningsForm, vegger } = etasje;

  const { getFloorTitle } = useFloorTitle();
  const floorTitle = getFloorTitle(
    etasjeNummer,
    erKjeller,
    sameConstructionAllFloors
  );

  // Floor has no walls defined
  if (vegger?.length === 0) {
    return (
      <FactBox title={floorTitle} dense>
        <WallsEmptyState />
      </FactBox>
    );
  }

  return (
    <FactBox title={floorTitle} dense>
      <Accordion>
        {etasje?.vegger?.map((wall, index) => (
          <CustomAccordionItem key={index} title={<WallTitle wall={wall} />}>
            <Wall wall={wall} etasjeId={id} shape={bygningsForm} />
          </CustomAccordionItem>
        ))}
      </Accordion>
    </FactBox>
  );
};
