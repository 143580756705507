import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { Typography } from 'enova-frontend-components';

import useSelector from '../../../../../../../hooks/useSelector';
import {
  getVeggsone,
  getWallHasMultipleZones
} from '../../../../../../../store/registration/selectors';
import ReadonlyField from '../../../../../../../components/readonlyField';
import { VeggId } from '../../../../../../../components/registering/utils/registerEnums';

type WallZoneSummaryContentProps = {
  etasjeId: string;
  veggId: VeggId;
  veggsoneId: string;
  veggsoneIndex: number;
};

const WallZoneSummaryContent: FC<WallZoneSummaryContentProps> = ({
  etasjeId,
  veggId,
  veggsoneId,
  veggsoneIndex
}) => {
  const { t } = useTranslation();

  const multipleZones = useSelector(
    (state) => getWallHasMultipleZones(state, etasjeId, veggId),
    shallowEqual
  );

  const veggsone = useSelector(
    (state) => getVeggsone(state, etasjeId, veggId, veggsoneId),
    shallowEqual
  );

  if (!veggsone) return null;

  const veggsoneIdentifier = `${veggId.toUpperCase()}${veggsoneIndex + 1}`;

  const {
    veggKonstruksjon: {
      rehabiliteringEllerPabyggingsAr,
      isolasjonstykkelseIMillimeter,
      uendretSidenOppforing,
      brukStandardVerdier,
      veggType
    } = {},
    lengdeIMeter,
    tilliggendeRom
  } = veggsone;

  const noValues =
    !rehabiliteringEllerPabyggingsAr &&
    isolasjonstykkelseIMillimeter != null &&
    !uendretSidenOppforing &&
    !veggType &&
    !tilliggendeRom &&
    (!multipleZones || (multipleZones && !lengdeIMeter));

  return (
    <Fragment>
      {multipleZones && (
        <ReadonlyField
          label={t('register.wallZone.length', {
            context: veggsoneIdentifier && 'zone'
          })}
          value={lengdeIMeter}
        />
      )}

      {tilliggendeRom && (
        <ReadonlyField
          label={t('register.wallZone.adjoiningRooms.label', {
            context: multipleZones && veggsoneIdentifier && 'zone',
            wallZoneId: veggsoneIdentifier
          })}
          value={t(`register.adjoiningRooms.option.${tilliggendeRom}`, {
            context: 'wall'
          })}
        />
      )}

      {veggType && (
        <ReadonlyField
          label={t('register.wallType.label')}
          value={t(`register.wallType.option.${veggType}`)}
        />
      )}

      {isolasjonstykkelseIMillimeter != null && (
        <ReadonlyField
          label={t('register.isolation.label')}
          value={t('register.isolation', {
            count: isolasjonstykkelseIMillimeter,
            context: 'count'
          })}
        />
      )}

      {rehabiliteringEllerPabyggingsAr && (
        <ReadonlyField
          label={t('register.wallZone.yearOfRehabilitation.label')}
          value={rehabiliteringEllerPabyggingsAr}
        />
      )}

      {uendretSidenOppforing && (
        <ReadonlyField
          label={t('register.wallZone.yearOfRehabilitation.label')}
          value={rehabiliteringEllerPabyggingsAr}
        />
      )}

      {(brukStandardVerdier || noValues) && (
        <Typography>{t('register.wallZone.constructionMissing')}</Typography>
      )}
    </Fragment>
  );
};

export default WallZoneSummaryContent;
