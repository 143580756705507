import { useTranslation } from 'react-i18next';

import { isFalsy } from '../utils/sanitize';

type BuildingIdentifierInput = {
  bnr?: string | number;
  fnr?: string | number;
  gnr?: string | number;
  knr?: string | number;
  snr?: string | number;
};

type BuildingIdenfierFunc = (
  buildingIdentifierInput: BuildingIdentifierInput
) => string | undefined;

/**
 * Hook for generating building identifier
 */
const useBuildingIdentifier = (): BuildingIdenfierFunc => {
  const { t } = useTranslation();

  const getBuildingIdentifier: BuildingIdenfierFunc = ({
    bnr,
    fnr,
    gnr,
    knr,
    snr
  }) => {
    const identifiers: string[] = [];

    Object.entries({ knr, gnr, bnr, fnr, snr }).forEach(([key, value]) => {
      if (!isFalsy(value))
        identifiers.push(t('building.identifier', { context: key, value }));
    });

    return identifiers.join('/');
  };

  return getBuildingIdentifier;
};

export default useBuildingIdentifier;
