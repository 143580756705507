import { Box, Button, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FloorDataWrapper,
  FloorWrapper,
  IconWrapper,
  StyledPaper,
  WallsWrapper
} from '../styledComponents';
import { ShapeIcon } from '../buildingShape/shapeIcon';
import { BygningsForm } from '../../../../utils/registerEnums';
import { useCalculateFloorArea } from '../../hooks/useCalculateFloorArea';
import { Etasje } from '../../../../../../types/registration/userInput';

interface Props {
  floor: Etasje;
  title: string;
  onEdit: () => void;
}

export const FloorDetails: FC<Props> = ({ floor, title, onEdit }) => {
  const { t } = useTranslation();
  const { area, isLoading: isCalculateAreaLoading } = useCalculateFloorArea(
    floor.bygningsForm ?? BygningsForm.Unknown,
    floor.vegger ?? [],
    floor.id
  );

  return (
    <StyledPaper>
      <Box display="flex" flexDirection="row" gap={4}>
        <FloorWrapper>
          <Typography variant="h4">{title}</Typography>
          <IconWrapper>
            <ShapeIcon shape={floor.bygningsForm ?? BygningsForm.Unknown} />
          </IconWrapper>
          <FloorDataWrapper>
            <Box>
              <Typography>{t('area')}</Typography>
              {!isCalculateAreaLoading && (
                <Typography variant="h4">
                  {area} {t('suffix.m2')}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography>
                {t('register.buildingShape.averageFloorHeight')}
              </Typography>
              <Typography variant="h4">
                {floor.gjennomsnittligEtasjehoyde ?? '-'} {t('suffix.m')}
              </Typography>
            </Box>
          </FloorDataWrapper>
        </FloorWrapper>
        <Box m={3}>
          <Typography mb={3} variant="h4">
            {t('register.buildingShape.wallLengths.title')}
          </Typography>
          <WallsWrapper>
            {floor.vegger?.map((wall, index) => (
              <Box key={index} display="flex" flexDirection="column">
                <Typography>
                  {t('wall', { veggId: wall.veggId.toUpperCase() })}
                </Typography>
                <Typography variant="h4">
                  {wall.lengdeIMeter} {t('suffix.m')}
                </Typography>
              </Box>
            ))}
          </WallsWrapper>
        </Box>

        <Box display="flex" alignItems="flex-end" ml="auto" p={4}>
          <Button
            variant="secondary"
            onClick={onEdit}
            aria-label={`${t('options')}`}
          >
            {t('register.buildingShape.form.edit')}
          </Button>
        </Box>
      </Box>
    </StyledPaper>
  );
};
