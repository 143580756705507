import {
  Box,
  FormMessage,
  styled,
  Typography
} from 'enova-frontend-components';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CertificateVisibilityConsent } from '../../../../../../../components/certificateVisibilityConsentDialog';
import { useEnergyAnalysis } from '../../../../../../../hooks/useEnergyAnalysis';
import { LoadingIndicator } from '../../../../../../../components/loadingIndicator';

const StyledFormMessage = styled(FormMessage)`
  border-color: #737373;
  background-color: #e0e0e0;
`;

export const VisibilityConsentWrapper: FC<{ registreringsId?: string }> = ({
  registreringsId
}) => {
  const { t } = useTranslation();
  const { energyAnalysis, energyAnalysisLoading } =
    useEnergyAnalysis(registreringsId);

  if (energyAnalysisLoading) {
    return <LoadingIndicator />;
  }

  if (!energyAnalysis?.energiplan) {
    return null;
  }

  return (
    <StyledFormMessage
      title={t('buildingScreen.tab.energiattest.reservationInfo.title')}
      fullWidth
    >
      <Typography>
        <Trans i18nKey="buildingScreen.tab.energiattest.reservationInfo.content" />
      </Typography>
      <Box mt={3}>
        <CertificateVisibilityConsent energiplan={energyAnalysis?.energiplan} />
      </Box>
    </StyledFormMessage>
  );
};
