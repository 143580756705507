import { useTranslation } from 'react-i18next';
import { NumberField } from 'enova-frontend-components';
import { Controller, useWatch } from 'react-hook-form';

import { useAdvancedSearchFormContext } from '../../types';

export const BruksnummerField = () => {
  const { t } = useTranslation();
  const label = t('search.advanced.field_bruksnummer');
  const { control, trigger } = useAdvancedSearchFormContext();
  const bygningsnummer = useWatch({ control, name: 'bygningsnummer' });

  return (
    <Controller
      rules={{
        validate: {
          required: (value) => {
            //Message vil be filtered out and replaced with custom error message in the error summary
            const errorMessage = 'Bruksnummer er påkrevd';
            // Ensure that either this value or gardsnummer and bruksnummer is provided
            if (!value && !bygningsnummer) {
              return errorMessage;
            }
            return true;
          }
        }
      }}
      control={control}
      name="bruksnummer"
      render={({ field: { value, onChange, ...rest }, fieldState }) => {
        return (
          <NumberField
            fullWidth
            label={label}
            inputProps={{
              'aria-label': label
            }}
            autoComplete
            error={!!fieldState.error}
            useThousandSeparator={false}
            allowLeadingZeros
            title={label}
            value={value ?? null}
            onValueChange={(_, e) => {
              onChange(e.event);
              trigger(['bygningsnummer', 'gardsnummer']);
            }}
            {...rest}
          />
        );
      }}
    />
  );
};
