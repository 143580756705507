import {
  BygningsForm,
  Himmelretning,
  PlasseringAvEnhetIByggVertikalt,
  VeggId
} from '../../components/registering/utils/registerEnums';
import {
  Bygningsdetaljer,
  Detaljeringssteg,
  Energibruk,
  Etasje,
  Gulvsone,
  Konstruksjonstetthet,
  Tak,
  TekniskUtstyr,
  Terrengskjerming,
  UserInput,
  Vegg,
  Veggsone,
  Ventilasjon,
  Vindu,
  YtterDor
} from '../../types/registration/userInput';
import { UtData } from '../../types/building';
import { RootState } from '../rootReducer';

export const getDetailingSteps = (state: RootState): Detaljeringssteg =>
  state.register.userInput?.detaljeringssteg ?? {};

export const getEtasjer = (state: RootState): Etasje[] =>
  state.register.userInput?.bygningsdetaljer?.etasjer || [];

export const getMinifiedEtasjer = (
  state: RootState
): Pick<Etasje, 'erKjeller' | 'etasjeNummer' | 'id'>[] =>
  (state.register.userInput?.bygningsdetaljer?.etasjer || []).map(
    ({ erKjeller, etasjeNummer, id }) => ({ erKjeller, etasjeNummer, id })
  );

export const getGeneralShape = (state: RootState): BygningsForm | undefined =>
  state.register.userInput?.bygningsdetaljer?.etasjer?.[0]?.bygningsForm ||
  BygningsForm.Unknown;

export const getEtasjeDirection = (
  state: RootState,
  etasjeId: string
): Himmelretning | undefined =>
  state.register.userInput?.bygningsdetaljer?.etasjer
    ?.find((etasje) => etasje.id === etasjeId)
    ?.vegger?.find((vegg) => vegg.veggId === VeggId.A)?.himmelretning ||
  Himmelretning.Nord;

export const getGeneralDirection = (
  state: RootState
): Himmelretning | undefined =>
  state.register.userInput?.bygningsdetaljer?.etasjer?.[0]?.vegger?.find(
    (vegg) => vegg.veggId === VeggId.A
  )?.himmelretning || Himmelretning.Nord;

export const getEtasje = (state: RootState, id: string): Etasje | undefined =>
  (state.register.userInput?.bygningsdetaljer?.etasjer || []).find(
    (e) => e.id === id
  );

export const getMinifiedEtasje = (
  state: RootState,
  id: string
):
  | Pick<Etasje, 'id' | 'bygningsForm' | 'erKjeller' | 'etasjeNummer'>
  | undefined => {
  const etasje = (
    state.register.userInput?.bygningsdetaljer?.etasjer || []
  ).find((e) => e.id === id);

  return etasje
    ? {
        id: etasje.id,
        bygningsForm: etasje.bygningsForm,
        erKjeller: etasje.erKjeller,
        etasjeNummer: etasje.etasjeNummer
      }
    : undefined;
};

export const getGulvsoneIds = (
  state: RootState,
  etasjeId: string
): string[] | undefined =>
  state.register.userInput?.bygningsdetaljer?.etasjer
    ?.find((etasje) => etasje.id === etasjeId)
    ?.gulvSoner?.map((sone) => sone.id);

export const getGulvsone = (
  state: RootState,
  etasjeId: string,
  gulvsoneId: string
): Gulvsone | undefined =>
  state.register.userInput?.bygningsdetaljer?.etasjer
    ?.find((e) => e.id === etasjeId)
    ?.gulvSoner?.find((sone) => sone.id === gulvsoneId);

export const getGulvHasMultipleZones = (
  state: RootState,
  etasjeId: string
): boolean =>
  (state.register.userInput?.bygningsdetaljer?.etasjer?.find(
    (e) => e.id === etasjeId
  )?.gulvSoner?.length || 0) > 1;

export type MinifiedWall = Pick<Vegg, 'veggId' | 'lengdeIMeter'>;

export const getMinifiedWalls = (
  state: RootState,
  id: string
): MinifiedWall[] | undefined => {
  const vegger = (
    state.register.userInput?.bygningsdetaljer?.etasjer || []
  ).find((e) => e.id === id)?.vegger;

  return (
    vegger?.map(({ veggId, lengdeIMeter }) => ({
      veggId,
      lengdeIMeter
    })) || undefined
  );
};

export const getWalls = (state: RootState, etasjeId: string): Vegg[] => {
  const walls = (
    state.register.userInput?.bygningsdetaljer?.etasjer || []
  ).find((e) => e.id === etasjeId)?.vegger;

  return walls ?? [];
};

export const getMinifiedWallSectionValues = (
  state: RootState,
  etasjeId: string,
  veggId: VeggId
): (Pick<Vegg, 'veggId'> & { veggsoner: Veggsone[] }) | undefined => {
  const vegg = (state.register.userInput?.bygningsdetaljer?.etasjer || [])
    .find((e) => e.id === etasjeId)
    ?.vegger?.find((v) => v.veggId === veggId);

  return vegg
    ? {
        veggId,
        veggsoner: vegg.veggSoner
      }
    : undefined;
};

export const getWallZoneWindows = (
  state: RootState,
  etasjeId: string,
  veggId: VeggId,
  veggsoneId: string
): Vindu[] | undefined =>
  (state.register.userInput?.bygningsdetaljer?.etasjer || [])
    .find((e) => e.id === etasjeId)
    ?.vegger?.find((v) => v.veggId === veggId)
    ?.veggSoner?.find(({ id }) => id === veggsoneId)?.vinduer;

export const getWallHasMultipleZones = (
  state: RootState,
  etasjeId: string,
  veggId: VeggId
): boolean =>
  ((state.register.userInput?.bygningsdetaljer?.etasjer || [])
    .find((e) => e.id === etasjeId)
    ?.vegger?.find((v) => v.veggId === veggId)?.veggSoner?.length || 0) > 1;

export const getMinifiedWallIds = (
  state: RootState,
  id: string
): VeggId[] | undefined => {
  const vegger = (
    state.register.userInput?.bygningsdetaljer?.etasjer || []
  ).find((e) => e.id === id)?.vegger;

  return vegger?.map(({ veggId }) => veggId) || undefined;
};

export const getVeggsone = (
  state: RootState,
  etasjeId: string,
  veggId: VeggId,
  veggsoneId: string
): Veggsone | undefined => {
  const etasje = state.register.userInput?.bygningsdetaljer?.etasjer?.find(
    (e) => e.id === etasjeId
  );

  const vegg = etasje?.vegger?.find((v) => v.veggId === veggId);
  return vegg?.veggSoner?.find((vs) => vs.id === veggsoneId);
};

export const getVeggsoner = (
  state: RootState,
  etasjeId: string,
  veggId: VeggId
): Veggsone[] | undefined => {
  const etasje = state.register.userInput?.bygningsdetaljer?.etasjer?.find(
    (e) => e.id === etasjeId
  );

  const vegg = etasje?.vegger?.find((v) => v.veggId === veggId);
  return vegg?.veggSoner;
};

export const getVinduer = (
  state: RootState,
  etasjeId: string,
  veggId: VeggId,
  veggsoneId: string
): Vindu[] =>
  state.register.userInput?.bygningsdetaljer?.etasjer
    ?.find((e) => e.id === etasjeId)
    ?.vegger?.find((v) => v.veggId === veggId)
    ?.veggSoner?.find((vs) => vs.id === veggsoneId)?.vinduer ?? [];

export const getTerrainShielding = (
  state: RootState
): Terrengskjerming | undefined =>
  state.register.userInput?.bygningsdetaljer?.terrengskjerming;

export const getEnergibruk = (state: RootState): Energibruk[] | undefined =>
  state.register.userInput?.energibruk;

export const getTekniskUtstyr = (state: RootState): TekniskUtstyr | undefined =>
  state.register.userInput?.tekniskUtstyr;

export const getRegistrationValues = (state: RootState): UserInput =>
  state.register.userInput;

export const getBygningsdetaljer = (state: RootState): Bygningsdetaljer =>
  state.register.userInput?.bygningsdetaljer;

export const getDoors = (state: RootState): YtterDor[] | undefined =>
  state.register.userInput?.bygningsdetaljer?.ytterDorer;

export const getTak = (state: RootState): Tak | undefined =>
  state.register.userInput?.bygningsdetaljer?.tak;

export const getTakVinduer = (state: RootState): Vindu[] =>
  state.register.userInput?.bygningsdetaljer?.tak?.vinduer ?? [];

export const getVentilation = (state: RootState): Ventilasjon | undefined =>
  state.register.userInput?.ventilasjon;

export const getPlasseringAvEnhetIByggVertikalt = (
  state: RootState
): PlasseringAvEnhetIByggVertikalt | undefined =>
  state.register.userInput?.bygningsdetaljer?.plasseringAvEnhetIByggVertikalt;

export const displayRoofConstructionCheck = (state: RootState): boolean =>
  !(
    state.register?.userInput?.bygningsdetaljer
      ?.plasseringAvEnhetIByggVertikalt &&
    state.register?.userInput?.bygningsdetaljer
      ?.plasseringAvEnhetIByggVertikalt !==
      PlasseringAvEnhetIByggVertikalt.OversteEtasje
  );

export const getKonstruksjonstetthet = (
  state: RootState
): Konstruksjonstetthet | undefined =>
  state.register.userInput?.bygningsdetaljer?.konstruksjonstetthet;

export const getHasTechnicalEquipmentData = (state: RootState): boolean =>
  Object.values(getTekniskUtstyr(state) || {}).some((i) => !!i);

export const getHasVentilationData = (state: RootState): boolean =>
  Object.values(getVentilation(state) || {}).some((i) => !!i);

export const getHasConstructionDensityData = (state: RootState): boolean =>
  Object.values(getKonstruksjonstetthet(state) || {}).some((i) => !!i);

export const getHasEnergyUsageData = (state: RootState): boolean =>
  Object.values(getEnergibruk(state) || {}).some((i) => !!i);

export const getHasDoors = (state: RootState): boolean =>
  Object.values(getDoors(state) || []).length > 0;

export const getHasWindows = (state: RootState): boolean =>
  state.register.userInput?.bygningsdetaljer?.etasjer?.some((etasje) =>
    etasje.vegger?.some((vegg) =>
      vegg.veggSoner?.some(
        (veggsone) => veggsone.vinduer && veggsone.vinduer.length > 0
      )
    )
  );

export const getHasWallData = (state: RootState): boolean =>
  state.register.userInput?.bygningsdetaljer?.etasjer?.some((etasje) =>
    etasje.vegger?.some((vegg) =>
      vegg.veggSoner?.some(
        (veggsone) =>
          veggsone.tilliggendeRom ||
          veggsone.lengdeIMeter ||
          (veggsone.veggKonstruksjon &&
            Object.values(veggsone.veggKonstruksjon).length > 0)
      )
    )
  );

export const getHasShapeAndDirectionData = (state: RootState): boolean =>
  state.register.userInput?.bygningsdetaljer?.etasjer?.some(
    (etasje) =>
      etasje.gjennomsnittligEtasjehoyde ||
      etasje.bygningsForm ||
      etasje.vegger?.some((vegg) => vegg.himmelretning || vegg.lengdeIMeter)
  );

export const getHasFloorData = (state: RootState): boolean =>
  state.register.userInput?.bygningsdetaljer?.etasjer?.some((etasje) =>
    etasje.gulvSoner?.some(
      (gulvsone) =>
        gulvsone.arealIKvm ||
        gulvsone.tilliggendeRom ||
        (gulvsone.gulvKonstruksjon &&
          Object.values(gulvsone.gulvKonstruksjon).length > 0)
    )
  );

export const getHasRoofData = (state: RootState): boolean => {
  const { takKonstruksjonsType, isolasjonstykkelseIMillimeter, vinduer } =
    getTak(state) || {};

  return !!(
    takKonstruksjonsType ||
    isolasjonstykkelseIMillimeter ||
    (vinduer && Object.values(vinduer).length > 0)
  );
};

export const getUtdata = (state: RootState): UtData =>
  state.register?.energiplan?.energimerke?.utdata || ({} as UtData);
