import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from 'enova-frontend-components';

import { useAdvancedSearchFormContext } from '../../types';

import { AdvancedSearchFieldProps } from './advancedSearchNumberField';

export const AdvancedSearchTextField: FC<AdvancedSearchFieldProps> = ({
  field,
  disabled,
  required
}) => {
  const { t } = useTranslation();
  const { register } = useAdvancedSearchFormContext();

  const label = t(`search.advanced.field_${field}`);

  return (
    <TextField
      fullWidth
      disabled={disabled}
      inputProps={{ 'aria-label': label }}
      label={label}
      title={label}
      {...register(field, {
        required: {
          value: !!required,
          message: label
        }
      })}
    />
  );
};
