import { TextField } from 'enova-frontend-components';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { useCreateBuildingFormContext } from '../utils';

interface GateBokstacProps {
  disabled: boolean;
}

export const GateBokstav: FC<GateBokstacProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const { xsScreen } = useMediaQuery();
  const { control } = useCreateBuildingFormContext();

  return (
    <Controller
      name="gateBokstav"
      control={control}
      render={({ field: { onChange, ...rest } }) => (
        <TextField
          {...rest}
          onChange={(e) => onChange(e.currentTarget.value.toUpperCase())}
          disabled={disabled}
          label={t('building.create.streetLetter')}
          fullWidth={xsScreen}
        />
      )}
    />
  );
};
