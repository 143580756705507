import { useParams } from 'react-router-dom';

import { XmlRegistrationStep } from '../types';

import { FlerboligRegistrationXMLParams } from './types';

export const checkFlerboligStepCompletion = (step: XmlRegistrationStep) => {
  const { step: currentStep, flerboligId } =
    useParams<FlerboligRegistrationXMLParams>();

  switch (step) {
    case 'velg-bygg':
      return currentStep !== 'velg-bygg';
    case 'last-opp-fil':
      return !!flerboligId && currentStep !== 'velg-bygg';
    case 'oppsummering-av-fil':
      return !!flerboligId && currentStep !== 'oppsummering-av-fil';
    case 'tiltak':
      return (
        (!!flerboligId && currentStep === 'oppsummering') ||
        currentStep === 'kvittering'
      );
    case 'oppsummering':
      return currentStep === 'kvittering';

    case 'kvittering':
      return false;
  }
};
