import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  MainContentBox,
  styled,
  Typography
} from 'enova-frontend-components';
import { Helmet } from 'react-helmet';

import pageNotFoundImg from '../assets/images/404.jpg';
import ImageBox from '../components/imageBox';

const StyledTypography = styled(Typography)`
  background: ${({ theme }) => theme.palette.common.white};
  width: fit-content;
  color: ${({ theme }) =>
    theme.palette.getContrastText(theme.palette.common.white)};
  padding: ${({ theme }) => theme.spacing()};
  margin-left: auto;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-left: 0;
  }
` as typeof Typography;

const PageNotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet title="404" />

      <ImageBox
        bgPosX="right"
        bgPosY="25%"
        className="f-fill"
        display="flex"
        height="100%"
        imgSrc={pageNotFoundImg}
        minHeight={{ xs: '35vw', sm: '40vw', lg: '30vw' }}
        width={1}
      >
        <MainContentBox>
          <Box
            width={{ xs: 0.8, sm: 0.5 }}
            marginLeft="auto"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            marginTop={30}
          >
            <StyledTypography
              paragraph
              display="inline"
              gutterBottom
              variant="h1"
            >
              {t('pageNotFound.title')}
            </StyledTypography>

            <StyledTypography display="inline">
              {t('pageNotFound.description')}
            </StyledTypography>
          </Box>
        </MainContentBox>
      </ImageBox>
    </Fragment>
  );
};

export default PageNotFound;
