// ENUMS
export enum ConstructionCodeEnum {
  None = 'None',
  Tek69 = 'Tek69',
  Tek87 = 'Tek87',
  Tek97 = 'Tek97',
  Tek07 = 'Tek07',
  Tek10 = 'Tek10',
  Tek17 = 'Tek17'
}

export enum BuildingCategoryEnum {
  Smahus = 'Smahus',
  Boligblokker = 'Boligblokker',
  Barnehager = 'Barnehager',
  Kontorbygg = 'Kontorbygg',
  Skolebygg = 'Skolebygg',
  UniversitetHoyskole = 'UniversitetHoyskole',
  Sykehus = 'Sykehus',
  Sykehjem = 'Sykehjem',
  Hoteller = 'Hoteller',
  Idrettsbygg = 'Idrettsbygg',
  Forretningsbygg = 'Forretningsbygg',
  Kulturbygg = 'Kulturbygg',
  LettIndustriVerksted = 'LettIndustriVerksted',
  Annenbygning = 'AnnenBygning'
}

export enum EnergiCertificateEnum {
  ValidCertificate = 'validCertificate',
  MustBeRenewed = 'mustBeRenewed',
  ValidCertificateMissing = 'validCertificateMissing'
}

export enum StatusEnum {
  ActionplanProduced = 'actionplanProduced',
  Marked = 'marked'
}

// FILTERS
// Filter values are translated
export const ConstructionCodeFilters: Record<string, string> = {
  ...Object.values(ConstructionCodeEnum).reduce(
    (acc, code) => ({
      ...acc,
      [code]: code
    }),
    {}
  )
};

export const BuildingCategoryFilters: Record<string, string> = {
  ...Object.values(BuildingCategoryEnum).reduce(
    (acc, cat) => ({
      ...acc,
      [cat]: cat
    }),
    {}
  )
};

export const EnergiCertificateFilters: Record<string, string> = {
  ...Object.values(EnergiCertificateEnum).reduce(
    (acc, certificate) => ({
      ...acc,
      [certificate]: certificate
    }),
    {}
  )
};

export const StatusFilters: Record<string, string> = {
  ...Object.values(StatusEnum).reduce(
    (acc, status) => ({
      ...acc,
      [status]: status
    }),
    {}
  )
};
