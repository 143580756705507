import {
  Box,
  Button,
  ButtonProps,
  LargeIcon,
  styled,
  Typography,
  useEnovaContext,
  useMediaQuery
} from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Energiattest } from '../../../../../../../types/building';

export const DownloadButton = styled((props: ButtonProps) => (
  <Button variant="secondary" {...props} />
))`
  display: flex;
  margin-left: ${({ theme }) => theme.spacing(2)};
  border-radius: 0;
  color: black;
  border: ${({ theme }) => `thin solid ${theme.palette.divider}`} !important;
  text-transform: none;

  &:hover {
    background: ${({ theme }) => theme.palette.action.focus};
  }
`;

interface Props {
  attest: Energiattest;
}

export const CertificateDownloadButton: FC<Props> = ({ attest }) => {
  const { theme } = useEnovaContext();
  const { t } = useTranslation();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const attestLabel = attest.attestSizeKb
    ? `PDF ${attest.attestSizeKb}kB`
    : t('downloadNotAvailable');

  return (
    <DownloadButton className="w-100" href={attest.attestUri}>
      <Box component="span" display="flex" flexDirection="column">
        <LargeIcon name="calendar" className="mb-1" />

        <Typography variant="h4" component="span" align="left" noWrap={lgUp}>
          {t('downloadEnergyCertificate')}
        </Typography>

        <Typography align="left" component="span">
          {attestLabel}
        </Typography>
      </Box>
    </DownloadButton>
  );
};
