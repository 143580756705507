import { useMutation } from 'react-query';

import { deleteRegistration } from '../../../../../services/api';

export const useDeleteRegistration = (refreshPortefoljeData: () => void) => {
  const { mutate, isLoading } = useMutation(
    (registrationId: string) => deleteRegistration(registrationId),
    { onSuccess: refreshPortefoljeData }
  );

  return { deleteRegistration: mutate, isLoading };
};
