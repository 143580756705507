import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  CheckGroup,
  EnergyGrade,
  EnergyGrades,
  Grid,
  HeatingGrade,
  HeatingGrades,
  Hidden,
  Paper,
  Radio,
  RadioGroup,
  TTableHeadCell,
  Typography,
  useEnovaContext
} from 'enova-frontend-components';
import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import RouterLink from '../../../../components/routerLink';
import CollapsibleFilterCheckbox from '../../../../components/statistics/collapsibleFilterCheckbox';
import useStatisticsFilter from '../../../../hooks/useStatisticsFilter';
import {
  CategoryOptions,
  FilterCategory,
  SubcategoryOptions
} from '../../../../hooks/useStatisticsFilter/context';
import StaticticsFilterDrawer from '../../../../components/statistics/statisticsFilterDrawer';
import {
  AggregatedStatisticsByEnergikarakterMap,
  AggregatedStatisticsByEnergikarakterTable,
  AggregatedStatisticsByEnergikarakterTableMap,
  StatistikkRequestType
} from '../../../../types/statistics';
import {
  StyledStatisticsTableByEnergikarakter,
  StyledStatisticsTableByOppvarmingskarakter
} from '../../utils';
import { getStatisticsScreenPath } from '../../../../utils/navigation';
import { loadStatistics } from '../../../../services/api';
import { queryKeys } from '../../../../utils/react-query';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

const chartOptionsOppvarming = {
  plugins: {
    legend: {
      display: false
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  }
};

const chartOptionsEnergi = {
  ...chartOptionsOppvarming,
  plugins: {
    legend: {
      labels: {
        font: {
          size: 16
        }
      }
    }
  }
};

enum Display {
  DIAGRAM = 'diagram',
  TABLE = 'table'
}

const maxWidthFilters = 300;

const StatisticsEnergikarakterView: FC = () => {
  const { t } = useTranslation();
  const { theme } = useEnovaContext();
  const [display, setDisplay] = useState(Display.TABLE);
  const [updateCharts, setUpdateCharts] = useState(true);

  const [statisticsEnergi, setStatisticsEnergi] =
    useState<AggregatedStatisticsByEnergikarakterMap>({});

  const [statisticsOppvarming, setStatisticsOppvarming] =
    useState<AggregatedStatisticsByEnergikarakterMap>({});

  const [statisticsEnergiTable, setStatisticsEnergiTable] =
    useState<AggregatedStatisticsByEnergikarakterTableMap>({});

  const [statisticsOppvarmingTable, setStatisticsOppvarmingTable] =
    useState<AggregatedStatisticsByEnergikarakterTableMap>({});

  const {
    categories,
    filterValues,
    subcategoryFilterValues,
    updateFilterValues,
    body
  } = useStatisticsFilter();

  const { data: statisticsByYear } = useQuery(
    [queryKeys.statistic, StatistikkRequestType.ENERGIKARAKTER, body],
    () => loadStatistics(StatistikkRequestType.ENERGIKARAKTER, body)
  );

  const updateDisplay = (disp: string) => {
    setUpdateCharts(disp === Display.DIAGRAM);
    setDisplay(disp as Display);
  };

  // TABLE HEADERS
  let TableHeadCellsEnergi: TTableHeadCell<AggregatedStatisticsByEnergikarakterTable>[] =
    [
      {
        id: 'publishedYear',
        isMainProperty: true,
        label: t('statistics.year'),
        numeric: false,
        disableSort: true
      }
    ];

  let TableHeadCellsOppvarming: TTableHeadCell<AggregatedStatisticsByEnergikarakterTable>[] =
    [
      {
        id: 'publishedYear',
        isMainProperty: true,
        label: t('statistics.year'),
        numeric: false,
        disableSort: true
      }
    ];

  let TableHeadCellsEnergiOppvarming: TTableHeadCell<AggregatedStatisticsByEnergikarakterTable>[] =
    [
      {
        id: 'energyGrade',
        isMainProperty: true,
        label: t('statistics.energyGrade'),
        numeric: false,
        disableSort: true
      }
    ];

  const headersEnergyGrades = EnergyGrades.map((grade) => ({
    id: grade,
    numeric: true,
    label: grade.toUpperCase(),
    disableSort: true
  }));

  const headersHeatingGrades = HeatingGrades.map((grade) => ({
    id: grade,
    numeric: true,
    label: '',
    disableSort: true
  }));

  TableHeadCellsEnergi = TableHeadCellsEnergi.concat(headersEnergyGrades);
  TableHeadCellsOppvarming =
    TableHeadCellsOppvarming.concat(headersHeatingGrades);
  TableHeadCellsEnergiOppvarming =
    TableHeadCellsEnergiOppvarming.concat(headersHeatingGrades);

  // UPDATE DATA IN USE EFFECT
  useEffect(() => {
    if (statisticsByYear && Object.keys(statisticsByYear).length === 0) return;

    const resultEnergi: AggregatedStatisticsByEnergikarakterMap = {};
    const resultOppvarming: AggregatedStatisticsByEnergikarakterMap = {};

    let allResults = 0;
    const accumulatedEnergyGrade: Record<EnergyGrade, number> = {
      ...EnergyGrades.reduce(
        (acc, grade) => ({ ...acc, [grade]: 0 }),
        {} as Record<EnergyGrade, number>
      )
    };
    const accumulatedHeatingGrade: Record<HeatingGrade, number> = {
      ...HeatingGrades.reduce(
        (acc, grade) => ({ ...acc, [grade]: 0 }),
        {} as Record<HeatingGrade, number>
      )
    };

    if (statisticsByYear)
      Object.values(statisticsByYear).map((obj) => {
        const { total, energiKarakter, oppvarmingKarakter, publisertAr } = obj;

        const year = publisertAr.toString();

        // Aggregate results for energy grade
        if (year in resultEnergi) {
          const row = resultEnergi[year];
          row[energiKarakter] += total;
          row[oppvarmingKarakter] += total;
          row.total += total;
        } else {
          const row = {
            id: year,
            publishedYear: obj.publisertAr.toString(),
            energyGrade: '',
            ...EnergyGrades.reduce(
              (acc, grade) => ({
                ...acc,
                [grade]: energiKarakter === grade ? total : 0
              }),
              {} as Record<EnergyGrade, number>
            ),
            ...HeatingGrades.reduce(
              (acc, grade) => ({
                ...acc,
                [grade]: oppvarmingKarakter === grade ? total : 0
              }),
              {} as Record<HeatingGrade, number>
            ),
            total: total,
            accumulated: 0
          };
          resultEnergi[year] = row;
        }

        // Aggregate results for heating grade
        if (energiKarakter.toUpperCase() in resultOppvarming) {
          const row = resultOppvarming[energiKarakter.toUpperCase()];
          row[energiKarakter] += total;
          row[oppvarmingKarakter] += total;
          row.total += total;
        } else {
          const row = {
            id: energiKarakter,
            publishedYear: '',
            energyGrade: energiKarakter.toUpperCase(),
            ...EnergyGrades.reduce(
              (acc, grade) => ({
                ...acc,
                [grade]: energiKarakter === grade ? total : 0
              }),
              {} as Record<EnergyGrade, number>
            ),
            ...HeatingGrades.reduce(
              (acc, grade) => ({
                ...acc,
                [grade]: oppvarmingKarakter === grade ? total : 0
              }),
              {} as Record<HeatingGrade, number>
            ),
            total: total,
            accumulated: 0
          };
          resultOppvarming[energiKarakter.toUpperCase()] = row;
        }

        // Add totals
        allResults += total;
        accumulatedEnergyGrade[energiKarakter] += total;
        accumulatedHeatingGrade[oppvarmingKarakter] += total;
      });

    // Copy objects, convert to array and sort
    const resultEnergiCopy: AggregatedStatisticsByEnergikarakterMap =
      JSON.parse(JSON.stringify(resultEnergi));
    const tempresultEnergi = Object.values(resultEnergiCopy).sort(
      (a, b) => (a.publishedYear > b.publishedYear && 1) || -1
    );

    const resultOppvarmingCopy: AggregatedStatisticsByEnergikarakterMap =
      JSON.parse(JSON.stringify(resultOppvarming));
    const tempresultOppvarming = Object.values(resultOppvarmingCopy).sort(
      (a, b) => (a.energyGrade > b.energyGrade && 1) || -1
    );

    // CHARTS
    // Convert back to objects
    const finalResultEnergi: AggregatedStatisticsByEnergikarakterMap =
      tempresultEnergi.reduce(
        (acc, result) => ({
          ...acc,
          [result.publishedYear.toString()]: result
        }),
        {}
      );

    const finalResultOppvarming: AggregatedStatisticsByEnergikarakterMap =
      tempresultOppvarming.reduce(
        (acc, result) => ({ ...acc, [result.energyGrade.toString()]: result }),
        {}
      );

    // Save chart results
    setStatisticsEnergi(finalResultEnergi);
    setStatisticsOppvarming(finalResultOppvarming);
    // Update charts once new data has been calculated
    setUpdateCharts(true);

    // TABLE ENERGIKARAKTER
    // Convert object to array and sort by year
    const tempresultEnergiTable = Object.values(resultEnergi).sort(
      (a, b) => (a.publishedYear > b.publishedYear && 1) || -1
    );

    // Calculate percentage
    for (const energi of tempresultEnergiTable) {
      for (const energyGrade of EnergyGrades) {
        energi[energyGrade as EnergyGrade] =
          Math.round(
            (energi[energyGrade as EnergyGrade] / allResults) * 10000
          ) / 100 || 0;
      }
      for (const heatingGrade of HeatingGrades) {
        energi[heatingGrade as HeatingGrade] =
          Math.round(
            (energi[heatingGrade as HeatingGrade] / allResults) * 10000
          ) / 100 || 0;
      }
      energi.total = energi.total / allResults || 0;
    }

    // Convert back to objects
    const resultEnergiTable: AggregatedStatisticsByEnergikarakterMap =
      tempresultEnergiTable.reduce(
        (acc, result) => ({
          ...acc,
          [result.publishedYear.toString()]: result
        }),
        {}
      );

    // Add last row with totals
    const lastRowEnergi = {
      id: 'total',
      publishedYear: 'Total',
      energyGrade: '',
      ...EnergyGrades.reduce(
        (acc, grade) => ({
          ...acc,
          [grade]:
            Math.round((accumulatedEnergyGrade[grade] / allResults) * 10000) /
              100 || 0
        }),
        {} as Record<EnergyGrade, number>
      ),
      ...HeatingGrades.reduce(
        (acc, grade) => ({
          ...acc,
          [grade]:
            Math.round((accumulatedHeatingGrade[grade] / allResults) * 10000) /
              100 || 0
        }),
        {} as Record<HeatingGrade, number>
      ),
      total: 1,
      accumulated: 0
    };
    resultEnergiTable['Total'] = lastRowEnergi;

    const finalResultEnergiTable: AggregatedStatisticsByEnergikarakterTableMap =
      {};
    for (const key in resultEnergiTable) {
      const object = resultEnergiTable[key];
      const energiobject: AggregatedStatisticsByEnergikarakterTable = {
        id: object.id,
        publishedYear: object.publishedYear,
        energyGrade: '',
        ...EnergyGrades.reduce(
          (acc, grade) => ({ ...acc, [grade]: object[grade].toFixed(2) + '%' }),
          {} as Record<EnergyGrade, string>
        ),
        ...HeatingGrades.reduce(
          (acc, grade) => ({ ...acc, [grade]: object[grade].toFixed(2) + '%' }),
          {} as Record<HeatingGrade, string>
        ),
        total: object.total.toFixed(2) + '%',
        accumulated: object.accumulated.toFixed(2) + '%'
      };
      finalResultEnergiTable[object.publishedYear] = energiobject;
    }

    // TABLE ENERGIKARAKTER VS OPPVARMINGSKARAKTER
    // Convert object to array and sort by energy grade
    const tempresultOppvarmingTable = Object.values(resultOppvarming).sort(
      (a, b) => (a.energyGrade > b.energyGrade && 1) || -1
    );

    // Calculate percentage
    for (const energi of tempresultOppvarmingTable) {
      for (const energyGrade of EnergyGrades) {
        energi[energyGrade as EnergyGrade] =
          Math.round(
            (energi[energyGrade as EnergyGrade] / allResults) * 10000
          ) / 100 || 0;
      }
      for (const heatingGrade of HeatingGrades) {
        energi[heatingGrade as HeatingGrade] =
          Math.round(
            (energi[heatingGrade as HeatingGrade] / allResults) * 10000
          ) / 100 || 0;
      }
      energi.total = energi.total / allResults || 0;
    }

    // Convert back to objects
    const resultOppvarmingTable: AggregatedStatisticsByEnergikarakterMap =
      tempresultOppvarmingTable.reduce(
        (acc, result) => ({ ...acc, [result.energyGrade.toString()]: result }),
        {}
      );

    // Add last row with totals
    const lastRowOppvarming = {
      id: 'total',
      publishedYear: '',
      energyGrade: 'Total',
      ...EnergyGrades.reduce(
        (acc, grade) => ({
          ...acc,
          [grade]:
            Math.round((accumulatedEnergyGrade[grade] / allResults) * 10000) /
              100 || 0
        }),
        {} as Record<EnergyGrade, number>
      ),
      ...HeatingGrades.reduce(
        (acc, grade) => ({
          ...acc,
          [grade]:
            Math.round((accumulatedHeatingGrade[grade] / allResults) * 10000) /
              100 || 0
        }),
        {} as Record<HeatingGrade, number>
      ),
      total: 1,
      accumulated: 0
    };
    resultOppvarmingTable['Total'] = lastRowOppvarming;

    // TABLE ENERGIKARAKTER VS OPPVARMINGSKARAKTER
    const finalResultOppvarmingTable: AggregatedStatisticsByEnergikarakterTableMap =
      {};
    for (const key in resultOppvarmingTable) {
      const object = resultOppvarmingTable[key];
      const energiobject: AggregatedStatisticsByEnergikarakterTable = {
        id: object.id,
        publishedYear: '',
        energyGrade: object.energyGrade,
        ...EnergyGrades.reduce(
          (acc, grade) => ({ ...acc, [grade]: object[grade].toFixed(2) + '%' }),
          {} as Record<EnergyGrade, string>
        ),
        ...HeatingGrades.reduce(
          (acc, grade) => ({ ...acc, [grade]: object[grade].toFixed(2) + '%' }),
          {} as Record<HeatingGrade, string>
        ),
        total: object.total.toFixed(2) + '%',
        accumulated: object.accumulated.toFixed(2) + '%'
      };
      finalResultOppvarmingTable[object.energyGrade] = energiobject;
    }

    // Save table results
    setStatisticsEnergiTable(finalResultEnergiTable);
    setStatisticsOppvarmingTable(finalResultOppvarmingTable);
  }, [statisticsByYear, filterValues, subcategoryFilterValues]);

  // CHARTS
  const chartRefEnergi = useRef<Chart | null>(null);
  const chartRefOppvarming = useRef<Chart | null>(null);
  const chartRefEnergiOppvarming = useRef<Chart | null>(null);

  const canvasCallbackEnergi = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    if (!updateCharts) return;
    const ctxEnergi = document.getElementById(
      'ChartEnergi'
    ) as HTMLCanvasElement;
    const energi = Object.values(statisticsEnergi).filter(
      (item) => item.publishedYear !== 'Total'
    );
    if (ctxEnergi) {
      chartRefEnergi.current?.destroy();
      chartRefEnergi.current = new Chart(ctxEnergi, {
        type: 'bar',
        data: {
          labels: energi.map((item) => item.publishedYear),
          datasets: [
            {
              label: 'A',
              data: energi.map((item) => item[EnergyGrade.A]),
              backgroundColor: theme.palette.primary.dark
            },
            {
              label: 'B',
              data: energi.map((item) => item[EnergyGrade.B]),
              backgroundColor: theme.palette.primary.light
            },
            {
              label: 'C',
              data: energi.map((item) => item[EnergyGrade.C]),
              backgroundColor: theme.palette.primary.main
            },
            {
              label: 'D',
              data: energi.map((item) => item[EnergyGrade.D]),
              backgroundColor: 'rgba(172, 16, 3, 0.3)'
            },
            {
              label: 'E',
              data: energi.map((item) => item[EnergyGrade.E]),
              backgroundColor: theme.palette.secondary.main
            },
            {
              label: 'F',
              data: energi.map((item) => item[EnergyGrade.F]),
              backgroundColor: theme.palette.secondary.light
            },
            {
              label: 'G',
              data: energi.map((item) => item[EnergyGrade.G]),
              backgroundColor: theme.palette.secondary.dark
            }
          ]
        },
        options: chartOptionsEnergi
      });
      setUpdateCharts(false);
    }
  };

  const canvasCallbackOppvarming = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    if (!updateCharts) return;
    const ctxOppvarming = document.getElementById(
      'ChartOppvarming'
    ) as HTMLCanvasElement;
    const energi = Object.values(statisticsEnergi).filter(
      (item) => item.publishedYear !== 'Total'
    );
    if (ctxOppvarming) {
      chartRefOppvarming.current?.destroy();
      chartRefOppvarming.current = new Chart(ctxOppvarming, {
        type: 'bar',
        data: {
          labels: energi.map((item) => item.publishedYear),
          datasets: [
            {
              data: energi.map((item) => item[HeatingGrade.Green]),
              backgroundColor: theme.palette.heatingGrade[HeatingGrade.Green]
            },
            {
              data: energi.map((item) => item[HeatingGrade.Lightgreen]),
              backgroundColor:
                theme.palette.heatingGrade[HeatingGrade.Lightgreen]
            },
            {
              data: energi.map((item) => item[HeatingGrade.Yellow]),
              backgroundColor: theme.palette.heatingGrade[HeatingGrade.Yellow]
            },
            {
              data: energi.map((item) => item[HeatingGrade.Orange]),
              backgroundColor: theme.palette.heatingGrade[HeatingGrade.Orange]
            },
            {
              data: energi.map((item) => item[HeatingGrade.Red]),
              backgroundColor: theme.palette.heatingGrade[HeatingGrade.Red]
            }
          ]
        },
        options: chartOptionsOppvarming
      });
      setUpdateCharts(false);
    }
  };

  const canvasCallbackEnergiOppvarming = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    if (!updateCharts) return;
    const ctxEnergiOppvarming = document.getElementById(
      'ChartEnergiOppvarming'
    ) as HTMLCanvasElement;
    const oppvarming = Object.values(statisticsOppvarming).filter(
      (item) => item.energyGrade !== 'Total'
    );
    if (ctxEnergiOppvarming) {
      chartRefEnergiOppvarming.current?.destroy();
      chartRefEnergiOppvarming.current = new Chart(ctxEnergiOppvarming, {
        type: 'bar',
        data: {
          labels: oppvarming.map((item) => item.energyGrade),
          datasets: [
            {
              data: oppvarming.map((item) => item[HeatingGrade.Green]),
              backgroundColor: theme.palette.heatingGrade[HeatingGrade.Green]
            },
            {
              data: oppvarming.map((item) => item[HeatingGrade.Lightgreen]),
              backgroundColor:
                theme.palette.heatingGrade[HeatingGrade.Lightgreen]
            },
            {
              data: oppvarming.map((item) => item[HeatingGrade.Yellow]),
              backgroundColor: theme.palette.heatingGrade[HeatingGrade.Yellow]
            },
            {
              data: oppvarming.map((item) => item[HeatingGrade.Orange]),
              backgroundColor: theme.palette.heatingGrade[HeatingGrade.Orange]
            },
            {
              data: oppvarming.map((item) => item[HeatingGrade.Red]),
              backgroundColor: theme.palette.heatingGrade[HeatingGrade.Red]
            }
          ]
        },
        options: chartOptionsOppvarming
      });
      setUpdateCharts(false);
    }
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box maxWidth={1100} alignSelf="center" justifySelf="center">
        <Box display="flex" flexDirection="column">
          <RouterLink className="mb-3 as-start" to={getStatisticsScreenPath()}>
            {t('navigation.statisticsScreen.back')}
          </RouterLink>

          <Typography variant="h2" component="span" gutterBottom>
            {t('statisticsScreen.energikarakter.title')}
          </Typography>

          <Typography pb={5}>
            {t('statisticsScreen.energikarakter.description')}
          </Typography>
        </Box>

        <Grid container spacing={4}>
          <Hidden mdDown>
            <Grid item md="auto" minWidth={maxWidthFilters}>
              {categories?.map((category) => (
                <Box key={category} maxWidth={maxWidthFilters}>
                  {category === FilterCategory.FYLKER ? (
                    <CheckGroup key={category} label={t(category)} marginBottom>
                      {CategoryOptions[category].map((option) => (
                        <CollapsibleFilterCheckbox
                          key={option}
                          category={category}
                          subcategory={SubcategoryOptions[category]}
                          option={option}
                        />
                      ))}
                    </CheckGroup>
                  ) : (
                    <CheckGroup key={category} label={t(category)} marginBottom>
                      {CategoryOptions[category].map((option) => (
                        <Checkbox
                          key={option}
                          label={t(option)}
                          width="fullWidth"
                          checked={filterValues[category].includes(option)}
                          onChange={(_, checked) =>
                            updateFilterValues(
                              category,
                              option,
                              checked,
                              SubcategoryOptions[category]
                            )
                          }
                        />
                      ))}
                    </CheckGroup>
                  )}
                </Box>
              ))}
            </Grid>
          </Hidden>

          <Grid
            item
            xs={12}
            maxWidth={{ md: `calc(100% - ${maxWidthFilters}px)` }}
          >
            {categories && (
              <Hidden mdUp>
                <StaticticsFilterDrawer />
              </Hidden>
            )}
            <Box className="w-100" display="flex" pt={6}>
              <RadioGroup
                aria-label="Visning"
                onChange={(_, val) => updateDisplay(val)}
                row
                value={display}
                marginBottom
                sx={{
                  alignItems: 'flex-end'
                }}
              >
                <Radio
                  width="auto"
                  label={t('showAsChart')}
                  value={Display.DIAGRAM}
                />
                <Radio
                  width="auto"
                  label={t('showInTable')}
                  value={Display.TABLE}
                />
              </RadioGroup>
            </Box>
            {display === Display.DIAGRAM && (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Paper>
                    <Box p={3}>
                      <Typography variant="h4" component="h2" gutterBottom>
                        {t('statistics.grade.chart.energy')}
                      </Typography>

                      <Box>
                        <canvas
                          id="ChartEnergi"
                          height="400"
                          ref={canvasCallbackEnergi}
                        />
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper>
                    <Box p={3}>
                      <Typography variant="h4" component="h2" gutterBottom>
                        {t('statistics.grade.chart.heating')}
                      </Typography>

                      <Box>
                        <canvas
                          id="ChartOppvarming"
                          height="400"
                          ref={canvasCallbackOppvarming}
                        />
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper>
                    <Box p={3}>
                      <Typography variant="h4" component="h2" gutterBottom>
                        {t('statistics.grade.chart.energyVsHeating')}
                      </Typography>

                      <Box>
                        <canvas
                          id="ChartEnergiOppvarming"
                          height="400"
                          ref={canvasCallbackEnergiOppvarming}
                        />
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            )}

            {display === Display.TABLE && (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <StyledStatisticsTableByEnergikarakter<AggregatedStatisticsByEnergikarakterTable>
                    alternating
                    dense={true}
                    tableTitle={t('statistics.grade.table.energy')}
                    headCells={TableHeadCellsEnergi}
                    rows={Object.values(statisticsEnergiTable)}
                    disablePagination={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <StyledStatisticsTableByOppvarmingskarakter<AggregatedStatisticsByEnergikarakterTable>
                    dense={true}
                    tableTitle={t('statistics.grade.table.heating')}
                    headCells={TableHeadCellsOppvarming}
                    rows={Object.values(statisticsEnergiTable)}
                    disablePagination={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <StyledStatisticsTableByOppvarmingskarakter<AggregatedStatisticsByEnergikarakterTable>
                    dense={true}
                    tableTitle={t('statistics.grade.table.energyVsHeating')}
                    headCells={TableHeadCellsEnergiOppvarming}
                    rows={Object.values(statisticsOppvarmingTable)}
                    disablePagination={true}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default StatisticsEnergikarakterView;
