import { Typography } from 'enova-frontend-components';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import useFormatDate from '../../../../../../../hooks/useFormatDate';

interface Props {
  publisertDato?: Date;
}

export const PublishedInfo: FC<Props> = ({ publisertDato }) => {
  const { formatDate } = useFormatDate();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Typography
        bold
        gridColumn={{ xs: 0, sm: 1 }}
        gridRow={{ xs: 0, sm: 1 }}
        mr={{ sm: 3 }}
      >
        {t('buildingScreen.tab.energiattest.signed')}
      </Typography>

      <Typography
        gridColumn={{ xs: 0, sm: 2 }}
        gridRow={{ xs: 0, sm: 1 }}
        mb={{ xs: 2, sm: 0 }}
      >
        {formatDate(publisertDato)}
      </Typography>
    </Fragment>
  );
};
