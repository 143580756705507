import { FC } from 'react';

import { ReactComponent as RectangleNorth } from '../../../../../../../assets/icons/shapes/rectangular/rectangular-1.svg';
import { ReactComponent as RectangleSouth } from '../../../../../../../assets/icons/shapes/rectangular/rectangular-1-1.svg';
import { ReactComponent as RectangleWest } from '../../../../../../../assets/icons/shapes/rectangular/rectangular-1-2.svg';
import { ReactComponent as RectangleEast } from '../../../../../../../assets/icons/shapes/rectangular/rectangular-2.svg';
import { Himmelretning } from '../../../../../utils/registerEnums';

import { ShapeProps } from './types';

export const Rectangle: FC<ShapeProps> = ({ direction }) => {
  switch (direction) {
    case Himmelretning.Nord:
      return <RectangleNorth />;
    case Himmelretning.Ost:
      return <RectangleEast />;
    case Himmelretning.Sor:
      return <RectangleSouth />;
    case Himmelretning.Vest:
      return <RectangleWest />;

    default:
      return <RectangleNorth />;
  }
};
