import { Grid, TextField } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { useCreateBuildingFormContext, validateStringAsNumber } from '../utils';

interface GardsAndBruksNummerProps {
  disabled: boolean;
}

export const GardsAndBruksNummer: FC<GardsAndBruksNummerProps> = ({
  disabled
}) => {
  const { t } = useTranslation();
  const { xsScreen } = useMediaQuery();

  const {
    register,
    formState: { errors }
  } = useCreateBuildingFormContext();

  return (
    <Grid container item columnSpacing={3}>
      <Grid item xs={12} sm={5}>
        <TextField
          required
          disabled={disabled}
          label={t('building.create.gardsNummer')}
          fullWidth={xsScreen}
          error={!!errors.gardsnummer}
          helperText={errors.gardsnummer?.message}
          {...register('gardsnummer', {
            required: t('required') as string,
            validate: {
              invalid: (val) =>
                validateStringAsNumber(val) || (t('invalid') as string)
            }
          })}
        />
      </Grid>

      <Grid item xs={12} sm={5}>
        <TextField
          required
          disabled={disabled}
          label={t('building.create.bruksnummer')}
          fullWidth={xsScreen}
          error={!!errors.bruksnummer}
          helperText={errors.bruksnummer?.message}
          {...register('bruksnummer', {
            required: t('required') as string,
            validate: {
              invalid: (val) =>
                validateStringAsNumber(val) || (t('invalid') as string)
            }
          })}
        />
      </Grid>
    </Grid>
  );
};
