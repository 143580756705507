import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Radio, Select } from 'enova-frontend-components';

import { camelCasify } from '../../../../../utils/navigation';
import { Vindu } from '../../../../../types/registration/userInput';
import { WindowSpecType } from '../../../utils/window';
import CollapsibleRegField from '../../../collapsibleRegField/collapsibleRegField';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';
import { WindowType as TypeOfWindow } from '../../utils';

import { useKarmAndGlassTypeOptions } from './useKarmAndGlassTypeOptions';

interface WindowTypeProps {
  onChangeGlassType: (value: string) => void;
  onChangeKarmType: (value: string) => void;
  type: TypeOfWindow;
  window: Vindu;
  windowSpecType: WindowSpecType | null;
}

export const WindowType: FC<WindowTypeProps> = ({
  onChangeGlassType,
  onChangeKarmType,
  type,
  window,
  windowSpecType
}) => {
  const { xsScreen } = useMediaQuery();
  const { t } = useTranslation();

  const { karmType, glassType } = window;

  const { glassTypeOptions, karmTypeOptions } =
    useKarmAndGlassTypeOptions(karmType);

  return (
    <CollapsibleRegField
      open={windowSpecType === WindowSpecType.WINDOW_TYPE}
      transparent={type === 'roof'}
    >
      <Radio
        width={xsScreen ? 'fullWidth' : 'auto'}
        helperText={t('register.windows.knownType.helperText')}
        className="mb-2"
        label={t(`register.window.${camelCasify(WindowSpecType.WINDOW_TYPE)}`)}
        value={WindowSpecType.WINDOW_TYPE}
      />

      <CollapsibleRegField.Collapse>
        <Grid container spacing={4}>
          <Grid item xs={12} sm="auto">
            <Select
              label={t('register.window.karmType.label')}
              value={karmType ?? -1}
              onChange={({ currentTarget: { value } }) =>
                onChangeKarmType(value)
              }
              options={karmTypeOptions}
              required
              title={
                karmType != null
                  ? (karmTypeOptions.find((option) => option.value === karmType)
                      ?.label as string)
                  : undefined
              }
            />
          </Grid>

          <Grid item xs={12} sm="auto">
            <Select
              disabled={!karmType}
              label={t('register.window.glassType.label')}
              onChange={({ currentTarget: { value } }) =>
                onChangeGlassType(value)
              }
              options={glassTypeOptions}
              required
              title={
                glassType != null
                  ? (glassTypeOptions.find(
                      (option) => option.value === glassType
                    )?.label as string)
                  : undefined
              }
              value={glassType ?? -1}
            />
          </Grid>
        </Grid>
      </CollapsibleRegField.Collapse>
    </CollapsibleRegField>
  );
};
