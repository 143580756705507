import React, { FC } from 'react';
import { Select } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { GetTypeFromMatrikkel } from '../../../../../../../../../utils/matrikkelBygningsTyper';
import { BuildingDetails } from '../../../../../../../../../types/building';
import { useCategoryFilter } from '../useCategoryFilter';

interface Props {
  buildings: BuildingDetails[];
}

export const BuildingCategorySelect: FC<Props> = ({ buildings }) => {
  const { selectedCategory, setCategoryFilter } = useCategoryFilter();
  const { t } = useTranslation();

  const distinctCategoryIdList = [
    ...new Set(buildings.map((building) => building.bygningsTypeNummer))
  ];

  const selectOptions = distinctCategoryIdList.map((categoryId) => ({
    label: GetTypeFromMatrikkel(categoryId),
    value: categoryId
  }));

  return (
    <Select
      value={selectedCategory || -1}
      aria-label={t('expertToolsXML.allCategories')}
      onChange={(e) => setCategoryFilter(e.currentTarget.value)}
      options={[
        { label: t('expertToolsXML.allCategories'), value: -1 },
        ...selectOptions
      ]}
    />
  );
};
