import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog } from 'enova-frontend-components';

interface ConfirmDialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: React.ReactNode;
  content: React.ReactNode;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  isOpen,
  onConfirm,
  onCancel,
  title,
  content
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen}>
      <Dialog.Title>{title}</Dialog.Title>
      <Dialog.Content>{content}</Dialog.Content>
      <Dialog.Actions>
        <Button variant="secondary" onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button onClick={onConfirm}>{t('confirm')}</Button>
      </Dialog.Actions>
    </Dialog>
  );
};
