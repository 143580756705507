import useSelector from '../../../../hooks/useSelector';

export const useConstructionDensityData = () => {
  return useSelector((state) => {
    return {
      konstruksjonstetthet:
        state.register.userInput.bygningsdetaljer.konstruksjonstetthet,
      byggAr: state.register.userInput.bygningsdetaljer.byggAr,
      user: state.register.user
    };
  });
};
