import React, { FC } from 'react';
import { FullScreenLoader, Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import useRegistrationForm from '../../../screens/registration/useRegistrationForm';
import EnergiplanTiltakSection from '../../tiltakSection/energiplan/energiplanTiltakSection';

const TiltakStep: FC = () => {
  const { t } = useTranslation();
  const { energiplanId, tiltakLoading, tiltakList } = useRegistrationForm();

  return (
    <Grid container spacing={4} direction="column">
      <FullScreenLoader open={tiltakLoading} />

      <Grid item>
        <Typography variant="h2" gutterBottom>
          {t('tiltak.heading')}
        </Typography>

        <Typography paragraph className="text-preline">
          {t('register.measures.description')}
        </Typography>

        <ol>
          <li>
            <Typography>{t('register.measures.descriptionList_1')}</Typography>
          </li>
          <li>
            <Typography>{t('register.measures.descriptionList_2')}</Typography>
          </li>
          <li>
            <Typography>{t('register.measures.descriptionList_3')}</Typography>
          </li>
        </ol>
      </Grid>

      {energiplanId && (
        <EnergiplanTiltakSection
          energiplanId={energiplanId}
          tiltakList={tiltakList}
        />
      )}
    </Grid>
  );
};

export default TiltakStep;
