import React, { FC } from 'react';
import { Button, Grid } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import {
  camelCasify,
  getExpertToolsXMLPortfolioBasePath
} from '../../../utils/navigation';
import RouterButtonLink from '../../../components/routerButtonLink';

import useExpertToolsXMLPortfolio from './useExpertToolsXMLPortfolio';

const ActionButtons: FC = () => {
  const { t } = useTranslation();
  const {
    goToNextStep,
    goToPrevStep,
    nextStep,
    prevStep,
    simpleEnergyAnalyses
  } = useExpertToolsXMLPortfolio();

  return (
    <Grid item xs={12} container spacing={4} mt={6}>
      <Grid item xs={12} sm="auto">
        {goToPrevStep && prevStep ? (
          <Button fullWidth onClick={goToPrevStep} variant="secondary">
            {t('expertToolsXMLPortfolio.worklist.actions.previous', {
              context: camelCasify(prevStep)
            })}
          </Button>
        ) : (
          <RouterButtonLink
            fullWidth
            to={getExpertToolsXMLPortfolioBasePath()}
            variant="secondary"
          >
            {t('expertToolsXMLPortfolio.worklist.actions.cancel')}
          </RouterButtonLink>
        )}
      </Grid>

      <Grid item xs={12} sm="auto" marginLeft="auto">
        {goToNextStep && nextStep && (
          <Button
            disabled={
              !simpleEnergyAnalyses || simpleEnergyAnalyses.length === 0
            }
            fullWidth
            onClick={goToNextStep}
          >
            {t('expertToolsXMLPortfolio.worklist.actions.next', {
              context: camelCasify(nextStep)
            })}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default ActionButtons;
