import { useState } from 'react';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import i18n, { Language } from '../../../../../../../../../../i18n';
import { Malform } from '../../../../../../../../../../components/registering/utils/registerEnums';
import { createErrorMessage } from '../../../../../../../../../../utils/utils';
import { useUpdateRegistrationLanguage } from '../../../../../../../../../../hooks/useUpdateRegistrationLanguage';
import { SimpleEnergyAnalysis } from '../../../../../../../../../../types/building';

export const useChangeLanguage = (energyAnalysis?: SimpleEnergyAnalysis) => {
  const { t } = useTranslation();
  const { addSnack } = useSnack();
  const [malform, setMalform] = useState(
    energyAnalysis?.registrering.malform ||
      (i18n.language === Language.NN_NO ? Malform.Nynorsk : Malform.Bokmal)
  );

  const buildingId = energyAnalysis?.registrering.energiObjektId ?? '';
  const registrationId = energyAnalysis?.registreringId;
  const { updateRegistreringLanguage } = useUpdateRegistrationLanguage(
    registrationId,
    buildingId
  );

  const changeLanguage = (language: Malform) => {
    const previousLanguage = malform;
    setMalform(language);

    updateRegistreringLanguage(language, {
      onError: () => {
        addSnack(createErrorMessage(t('registration.language.change.error')), {
          variant: 'error'
        });

        setMalform(previousLanguage);
      }
    });
  };

  return {
    malform,
    changeLanguage
  };
};
