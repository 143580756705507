import React, { FC } from 'react';
import {
  Grid,
  RadioButton,
  RadioGroup,
  Shape,
  styled,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setHasMultipleZonesGulvsoner } from '../../../../../store/registration/actions';
import { Etasje } from '../../../../../types/registration/userInput';

const StyledRadioGroup = styled(RadioGroup)`
  & * {
    height: 100%;
  }

  .rb-wrapper {
    height: auto;
  }
`;

interface FloorZoneRadioGroupProps {
  etasje: Etasje;
  multipleZones: boolean;
}

export const FloorZoneRadioGroup: FC<FloorZoneRadioGroupProps> = ({
  etasje,
  multipleZones
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const toggleMultipleZones = (newValue: boolean) =>
    dispatch(
      setHasMultipleZonesGulvsoner({
        etasjeId: etasje.id,
        hasMultipleZones: newValue
      })
    );

  return (
    <StyledRadioGroup
      label={t('register.floors.multipleZones.label', {
        context: etasje.erKjeller ? 'basement' : undefined,
        count: etasje.etasjeNummer
      })}
      marginBottom
      row
      onChange={(_, newValue) => {
        toggleMultipleZones(newValue === 'true');
      }}
      value={String(multipleZones)}
    >
      <Typography variant="body">
        {t('register.floors.multipleZones.helperText')}
      </Typography>

      <Grid container spacing={2}>
        <Grid className="rb-wrapper" item xs={12} sm={6} lg={4} xl={3}>
          <RadioButton
            icon={<Shape name="floor" />}
            invertedIcon={<Shape inverted name="floor" />}
            label={t('register.floors.multipleZones.single')}
            value="false"
            width="fullWidth"
          />
        </Grid>

        <Grid className="rb-wrapper" item xs={12} sm={6} lg={4} xl={3}>
          <RadioButton
            icon={<Shape name="floor_multizoned" />}
            invertedIcon={<Shape inverted name="floor_multizoned" />}
            label={t('register.floors.multipleZones.multi')}
            value="true"
            width="fullWidth"
          />
        </Grid>
      </Grid>
    </StyledRadioGroup>
  );
};
