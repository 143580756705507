/* eslint-disable max-len */
import React, { FC } from 'react';
import { FullScreenLoader } from 'enova-frontend-components';

import {
  useSubmitRegistrationAsQuery,
} from '../../../../screens/registration/useRegistrationForm/useSubmitRegistration';
import { ErrorBox } from '../../../errorBox';

import { transformBeregningskjerneInput } from './utils';
import { AdjustParametersPage } from './adjustParametersPage';

interface AdjustParametersProps {
  registrationId: string | undefined;
}

export const AdjustParameters: FC<AdjustParametersProps> = ({
  registrationId
}) => {
  // We submit a registration every time we mount this page.
  // In the future the data needs to be available when we arrive to this point.
  const {
    data: registrationData,
    loading,
    error,
    isFetchedAfterMount,
    refetch
  } = useSubmitRegistrationAsQuery(registrationId, 'adjustParameters');

  if (loading || !isFetchedAfterMount) {
    return <FullScreenLoader open />;
  }

  const beregningskjerneInput =
    registrationData?.energiplan?.energimerke?.inndata?.beregningskjerneInput;

  if (error || !beregningskjerneInput) {
    return (
      <ErrorBox
        titleKey="register.summary.error.title"
        descriptionKey="register.summary.error.description"
        onRetry={() => refetch()}
      />
    );
  }

  const { defaultValues, parameters } = transformBeregningskjerneInput(
    beregningskjerneInput
  );

  return (
    <AdjustParametersPage
      registrationId={registrationId}
      beregningskjerneInput={beregningskjerneInput}
      defaultValues={defaultValues}
      parameters={parameters}
    />
  );
};
