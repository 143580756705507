import './sentry';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './app';
import './i18n';

const container = document.getElementById('root');
// https://react.dev/blog/2022/03/08/react-18-upgrade-guide
const root = createRoot(container!);
root.render(<App />);
