import React, { FC, Fragment, useState } from 'react';
import {
  Box,
  NumberField,
  Radio,
  RadioGroup,
  styled,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { shallowEqual, useDispatch } from 'react-redux';
import { useDebounce } from 'rooks';

import useSelector from '../../../hooks/useSelector';
import {
  getEnergibruk,
  getTekniskUtstyr
} from '../../../store/registration/selectors';
import {
  updateEnergibruk,
  updateEnergibrukUnit
} from '../../../store/registration/actions';
import { energySourceUnitMap } from '../../../utils/register';
import {
  Energibruk,
  TekniskUtstyr
} from '../../../types/registration/userInput';
import { Energienhet } from '../utils/registerEnums';
import { TechnicalEquipmentFields } from '../utils/registerConfig';

const StyledRadioGroup = styled(RadioGroup)`
  & > * {
    flex-wrap: nowrap;
  }
`;

const GridWrapper = styled((props) => <Box {...props} />)`
  display: grid;
  grid-template-columns: min-content min-content min-content min-content auto;
  overflow-y: auto;
  margin: ${({ theme }) => theme.spacing(0, -4)};
  padding-right: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: minmax(200px, 300px) min-content min-content min-content auto;
  }

  & > * {
    padding: ${({ theme }) => theme.spacing()};
  }

  .energy-label {
    position: sticky;
    padding-left: 0;
    left: 0;
    padding-left: ${({ theme }) => theme.spacing(4)};
    z-index: 1;
    background: ${({ theme }) => theme.palette.background.default};

    &:after {
      content: '';
      position: absolute;
      left: 100%;
      width: 5px;
      height: 100%;
      box-shadow: inset 0 0 0 0 rgb(0 0 0 / 14%);
    }
  }

  &.shadow .energy-label:after {
    box-shadow: inset 5px 0 5px -2px rgb(0 0 0 / 14%);
  }
`;

export const currentYear = new Date().getFullYear();

export const yearCount = 3;

export const years = Array.from(Array(yearCount).keys())
  .reverse()
  .map((i) => currentYear - 1 - i);

const EnergyUsage: FC = () => {
  const { t } = useTranslation();

  const [units, setUnits] = useState<Record<string, Energienhet>>({
    oppvarmingGass: (
      energySourceUnitMap.oppvarmingGass!.unit as Energienhet[]
    )?.[0],
    oppvarmingVed: (
      energySourceUnitMap.oppvarmingVed!.unit as Energienhet[]
    )?.[0]
  });

  const dispatch = useDispatch();

  const handleChange = (obj: Energibruk) =>
    dispatch(updateEnergibruk({ energibruk: obj }));

  const handleChangeDebounced = useDebounce<typeof handleChange>(
    handleChange,
    500,
    { leading: true, trailing: true }
  );

  const energibruk = useSelector(getEnergibruk, shallowEqual);

  const tekniskUtstyr = useSelector(getTekniskUtstyr, shallowEqual);

  const el = document.getElementById('test-id');

  const [displayShadow, setDisplayShadow] = useState(false);

  el?.addEventListener('scroll', () => {
    if (displayShadow && el?.scrollLeft === 0) setDisplayShadow(false);
    else if (!displayShadow && el?.scrollLeft !== 0) setDisplayShadow(true);
  });

  return (
    <Fragment>
      <Typography variant="h2" gutterBottom>
        {t('register.energyUsage.title')}
      </Typography>

      <Typography paragraph>{t('register.energyUsage.description')}</Typography>

      <GridWrapper className={classNames({ shadow: displayShadow })}>
        <Box
          gridColumn={1}
          display="flex"
          alignItems="center"
          className="energy-label"
        >
          <Typography bold>{t('energySource')}</Typography>
        </Box>

        {years.map((year, yearIndex) => (
          <Box
            alignItems="center"
            display="flex"
            gridColumn={yearIndex + 2}
            gridRow={1}
            key={year}
          >
            <Typography bold>{year}</Typography>
          </Box>
        ))}

        {Object.entries(energySourceUnitMap)
          .filter(
            ([key]) =>
              (key as keyof TekniskUtstyr) === 'oppvarmingElektrisk' ||
              tekniskUtstyr?.[key as keyof TekniskUtstyr]
          )
          .map(([key, { energySource, unit }], energySourceIndex) => {
            const tLabelKey = TechnicalEquipmentFields[0].options.find(
              (option) => option.value === key
            )?.tLabelKey;

            const enhet = energibruk?.find(
              ({ energikilde: energikilde }) => energikilde === energySource
            )?.energienhet;

            return tLabelKey ? (
              <Fragment key={energySource}>
                <Box
                  className="energy-label"
                  alignItems="center"
                  display="flex"
                  gridColumn={1}
                  gridRow={energySourceIndex + 2}
                >
                  <Typography bold>{t(tLabelKey as string)}</Typography>
                </Box>

                {years.map((year, yearIndex) => {
                  const getObj = (val?: number) => ({
                    ar: year,
                    energikilde: energySource,
                    forbruk: val,
                    energienhet: (units[key] || unit) as Energienhet
                  });

                  const value = energibruk?.find(
                    ({ ar, energikilde: energikilde }) =>
                      ar === year && energikilde === energySource
                  )?.forbruk;

                  return (
                    <Box
                      alignItems="center"
                      display="flex"
                      gridColumn={yearIndex + 2}
                      gridRow={energySourceIndex + 2}
                      key={year}
                    >
                      <NumberField
                        autoComplete
                        inputProps={{
                          'aria-label': `${t(tLabelKey as string)} ${year}`
                        }}
                        name="energy-usage"
                        onValueChange={({ floatValue }) =>
                          handleChangeDebounced(getObj(floatValue))
                        }
                        value={value}
                      />
                    </Box>
                  );
                })}

                <Box
                  alignItems="center"
                  display="flex"
                  gridColumn={yearCount + 2}
                  gridRow={energySourceIndex + 2}
                >
                  {Array.isArray(unit) ? (
                    <StyledRadioGroup
                      value={enhet || unit[0]}
                      style={{ flexWrap: 'nowrap' }}
                      row
                      onChange={(_, val) => {
                        setUnits((prev) => ({
                          ...prev,
                          [energySource]: val as Energienhet
                        }));

                        dispatch(
                          updateEnergibrukUnit({
                            energikilde: energySource,
                            energienhet: val as Energienhet
                          })
                        );
                      }}
                    >
                      {unit.map((u) => (
                        <Radio
                          key={u}
                          width="auto"
                          value={u}
                          label={t(`register.energienhet.${Energienhet[u]}`)}
                        />
                      ))}
                    </StyledRadioGroup>
                  ) : (
                    <Typography>
                      {t(`register.energienhet.${Energienhet[unit]}`)}
                    </Typography>
                  )}
                </Box>
              </Fragment>
            ) : null;
          })}
      </GridWrapper>
    </Fragment>
  );
};

export default EnergyUsage;
