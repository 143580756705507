import React, { FC, useEffect, useState } from 'react';
import { Box, Checkbox, CheckGroup, Collapse } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import useStatisticsFilter from '../../hooks/useStatisticsFilter';
import {
  FilterCategory,
  FilterSubcategory
} from '../../hooks/useStatisticsFilter/context';
import { fylkerWithoutHistorical } from '../../utils/kommuner';
import { toCamelCase } from '../../utils/utils';

type CollapsibleFilterCheckboxProps = {
  category: FilterCategory;
  subcategory?: FilterSubcategory;
  option: string;
};

const CollapsibleFilterCheckbox: FC<CollapsibleFilterCheckboxProps> = ({
  category,
  subcategory,
  option
}) => {
  const { t } = useTranslation();
  const [fylkeChecked, setFylkeChecked] = useState(false);

  const {
    filterValues,
    subcategoryFilterValues,
    updateFilterValues,
    updateSubcategoryFilterValues
  } = useStatisticsFilter();

  const subcategoryValues =
    fylkerWithoutHistorical.find((fylke) => fylke.fylkesnavn === option)
      ?.kommuner ?? [];

  useEffect(() => {
    setFylkeChecked(filterValues[category].includes(option));
  }, [filterValues, option]);

  return (
    <Box>
      <Checkbox
        checked={fylkeChecked}
        label={t(`${category}.${toCamelCase(option)}`)}
        onChange={(_, checked) => {
          setFylkeChecked(!fylkeChecked);
          setTimeout(() => {
            updateFilterValues(category, option, checked, subcategory);
          }, 50);
        }}
        width="fullWidth"
      />

      <Collapse in={fylkeChecked}>
        <Box pl={4} pt={2}>
          {subcategory === FilterSubcategory.KOMMUNER && (
            <CheckGroup
              aria-label={t('selectMunicipalitiesInCounty', {
                county: t(`${category}.${toCamelCase(option)}`)
              })}
            >
              {subcategoryValues.map((sub) => (
                <Checkbox
                  checked={subcategoryFilterValues[subcategory].includes(
                    sub.kommunenummer
                  )}
                  key={sub.kommunenummer}
                  label={sub.kommunenavn}
                  onChange={(_, checked) =>
                    updateSubcategoryFilterValues(
                      subcategory,
                      sub.kommunenummer,
                      checked
                    )
                  }
                  width="fullWidth"
                />
              ))}
            </CheckGroup>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CollapsibleFilterCheckbox;
