import { useQuery } from 'react-query';
import { useSnack } from 'enova-frontend-components';

import { getFlerboligRegistration } from '../../../../../../services/api';
import { queryKeys } from '../../../../../../utils/react-query';

export const useGetFlerboligRegistration = (flerboligId: string) => {
  const { addSnack } = useSnack();

  const { data, isLoading, isError } = useQuery(
    [queryKeys.flerboligRegistration, flerboligId],
    () => getFlerboligRegistration(flerboligId),
    {
      onError: () => {
        addSnack('Feil ved henting av flerboligregistrering', {
          variant: 'error'
        });
      },
      enabled: !!flerboligId
    }
  );

  return {
    flerboligRegistrationData: data,
    isLoading,
    isError
  };
};
