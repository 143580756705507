import { useContext } from 'react';

import ExpertToolsXMLPortfolioContext, {
  ExpertToolsXMLPortfolioContextValues
} from './context';

const useExpertToolsXMLPortfolio = (): ExpertToolsXMLPortfolioContextValues =>
  useContext(ExpertToolsXMLPortfolioContext);

export default useExpertToolsXMLPortfolio;
