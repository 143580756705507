import {
  Box,
  Chip,
  FormMessage,
  Link,
  Loader,
  styled,
  Typography
} from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const WorklistLoader = () => (
  <Box display="flex" justifyContent="center" p={8}>
    <Loader size="large" />
  </Box>
);

interface WorklistErrorProps {
  refetch: () => void;
}

export const WorklistError: FC<WorklistErrorProps> = ({ refetch }) => {
  const { t } = useTranslation();

  return (
    <FormMessage fullWidth title={t('generalError.title')}>
      <Typography>{t('generalError.description')}</Typography>

      <Link button color="inherit" onClick={refetch}>
        {t('generalError.retry')}
      </Link>
    </FormMessage>
  );
};

export const StyledChip = styled(Chip)`
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  padding: ${({ theme }) => theme.spacing(3)};
  margin: 0 ${({ theme }) => theme.spacing(2)}
    ${({ theme }) => theme.spacing(2)} 0;
`;

interface WorklistFiltersProps {
  isPublished: boolean;
  setPublished: (published: boolean) => void;
}

export const WorklistFilters: FC<WorklistFiltersProps> = ({
  isPublished,
  setPublished
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <StyledChip
        onClick={() => setPublished(false)}
        variant={isPublished ? 'secondary' : 'primary'}
        label={t('expertToolsXMLPortfolio.worklists.notPublished')}
      />
      <StyledChip
        onClick={() => setPublished(true)}
        variant={isPublished ? 'primary' : 'secondary'}
        label={t('expertToolsXMLPortfolio.worklists.status.publisert')}
      />
    </React.Fragment>
  );
};
