import { Box, Button, Collapse } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { WallZoneFormData } from '../../types';
import { useWallFormContext } from '../../utils';
import { TilliggendeRom } from '../../../../utils/registerEnums';

import { WallZoneLength } from './components/wallZoneLength';
import { AdjoiningRoomSelect } from './components/adjoiningRoomSelect';
import { DetailingSelect } from './components/detailingSelect';
import { YearOfRenovation } from './components/yearOfRenovation';
import { WallTypeSelect } from './components/wallTypeSelect';
import { InsulationSelect } from './components/insulationSelect';
import { WallZoneSelect } from './components/wallZoneSelect';

interface Props {
  onSubmit: (data: WallZoneFormData) => void;
  onDelete: () => void;
  allWallsAgainstTheOpen: boolean;
}

export const Form: FC<Props> = ({
  onSubmit,
  allWallsAgainstTheOpen,
  onDelete
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch } = useWallFormContext();

  const detailing = watch('wallZoneData.detailing');
  const adjoiningRoom = watch('wallZoneData.wallZone.tilliggendeRom');

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box gap={4} display="flex" flexDirection="column" m={5}>
        <WallZoneSelect />
        <WallZoneLength control={control} />

        {/* If allWallsAgainstTheOpen adjoining room is set to TilliggendeRom.MotDetFri */}
        {!allWallsAgainstTheOpen && <AdjoiningRoomSelect control={control} />}

        {
          // No wall zone details needed if adjoining room is heated
          adjoiningRoom !== TilliggendeRom.OppvarmetRom && (
            <>
              {/* Controls which form fields to show */}
              <DetailingSelect control={control} />

              <Collapse in={detailing === 'yearOfRehabilitation'}>
                <YearOfRenovation control={control} />
              </Collapse>

              <Collapse in={detailing === 'construction'}>
                <Box display="flex" flexDirection="row" gap={3}>
                  <WallTypeSelect control={control} />
                  <InsulationSelect control={control} />
                </Box>
              </Collapse>
            </>
          )
        }
        <Box gap={3} display="flex" flexDirection="row">
          <Button size="medium" onClick={onDelete}>
            {t('register.wallZone.form.delete')}
          </Button>
          <Button size="medium" type="submit" variant="secondary">
            {t('register.wallZone.form.save')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
