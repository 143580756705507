import { useMutation, useQueryClient } from 'react-query';

import { XMLReport } from '../../../../../../types/expertToolsXML';
import { queryKeys } from '../../../../../../utils/react-query';
import * as api from '../../../../../../services/api';

export const useCreateEnhetRegistration = (buildingId: string) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    ({ report, bruksenhetsId }: { report: XMLReport; bruksenhetsId: string }) =>
      api.createExpertRegistration(report, bruksenhetsId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          queryKeys.energyAnalysisList,
          buildingId
        ]);
      }
    }
  );

  return { createExpertRegistration: mutate, isLoading };
};
