import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'enova-frontend-components';

import { BygningsForm } from '../../../../../utils/registerEnums';
import { Vegg } from '../../../../../../../types/registration/userInput';
import { useBygningsdetaljer, useCalculatedArea } from '../utils';
import { useCalculateFloorArea } from '../../../hooks/useCalculateFloorArea';

import { FloorAreaWrapper } from './floorAreaWrapper';

interface TotalAreaProps {
  currentFloorId: string;
  formShape: BygningsForm;
  walls: Vegg[];
}

export const TotalArea: FC<TotalAreaProps> = ({
  walls,
  currentFloorId,
  formShape
}) => {
  const { t } = useTranslation();
  const { totalOppvarmetBRA } = useBygningsdetaljer();
  const calculatedArea = useCalculatedArea();

  // Current floor area
  const { area: currentFloorArea } = useCalculateFloorArea(
    formShape,
    walls,
    currentFloorId
  );

  // Total area of all floors except current floor
  const otherFloorsArea = Object.entries(calculatedArea ?? {})
    .filter(([key]) => key !== currentFloorId)
    .map(([, value]) => value ?? 0)
    .reduce((totalArea, floorArea) => totalArea + floorArea, 0);

  return (
    <FloorAreaWrapper
      isLastElement
      style={{
        background: 'lightgrey',
        opacity: '1'
      }}
    >
      <Typography>
        <strong>{t('register.buildingShape.floor.area.total')}</strong>
      </Typography>
      <Typography>
        {t('register.area', {
          calculatedArea: otherFloorsArea + currentFloorArea,
          totalOppvarmetBRA
        })}
      </Typography>
    </FloorAreaWrapper>
  );
};
