import React, { FC } from 'react';

import { BygningsForm, Himmelretning } from '../../../../utils/registerEnums';

import {
  Atrium,
  LShape,
  LShapeMirrored,
  Rectangle,
  TShape,
  UShape,
  XShape,
  YShape,
  ZShape,
  ZShapeMirrored
} from './shapeComponents';

interface Props {
  shape?: BygningsForm;
  direction?: Himmelretning;
}
export const BuildingShape: FC<Props> = ({ shape, direction }) => {
  if (!shape) {
    return null;
  }

  switch (shape) {
    case BygningsForm.Rektangulaert:
      return <Rectangle direction={direction} />;

    case BygningsForm.TForm:
      return <TShape direction={direction} />;

    case BygningsForm.Atriumshus:
      return <Atrium direction={direction} />;

    case BygningsForm.YForm:
      return <YShape direction={direction} />;

    case BygningsForm.LForm:
      return <LShape direction={direction} />;

    case BygningsForm.UForm:
      return <UShape direction={direction} />;

    case BygningsForm.XForm:
      return <XShape direction={direction} />;

    case BygningsForm.ZForm:
      return <ZShape direction={direction} />;

    case BygningsForm.ZFormSpeilvendt:
      return <ZShapeMirrored direction={direction} />;

    case BygningsForm.LFormSpeilvendt:
      return <LShapeMirrored direction={direction} />;
  }
};
