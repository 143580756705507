import { Tabs } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleEnergyAnalysis } from '../../../../../../../types/building';
import { SummaryTab } from '../../../common/steps/summary/summaryTabs/tabs/types';
import { FileSummaryTab } from '../../../common/steps/summary/summaryTabs/tabs/fileSummaryTab';
import { BuildingInfoTab } from '../../../common/steps/summary/summaryTabs/tabs/buildingInfoTab';
import CalculatedEnergyRating from '../../../common/steps/summary/summaryTabs/tabs/calculatedEnergyRatingTab/calculatedEnergyRating';
import { camelCasify } from '../../../../../../../utils/navigation';
import { MeasuresTab } from '../../../common/steps/summary/summaryTabs/tabs/measuresTab';
import { useGetTiltakGrupperegistrering } from '../../../../../../../components/tiltakSection/grupperegistrering/useGetGrupperegistreringTiltak';
import { SummaryTabs } from '../../../common/steps/summary/summaryTabs';
import { useGetUnofficialEnergyCertificateFlerbolig } from '../../../../../../../hooks/useGetUnofficialEnergyCertificate';

interface Props {
  energyAnalysisList: SimpleEnergyAnalysis[];
  flerboligId: string;
}

export const FlerboligSummaryTabs: FC<Props> = ({
  energyAnalysisList,
  flerboligId
}) => {
  const tiltakQuery = useGetTiltakGrupperegistrering(flerboligId);
  const { getCertificate, isLoading } =
    useGetUnofficialEnergyCertificateFlerbolig(flerboligId);
  const { t } = useTranslation();
  const generateTabTitle = (tab: SummaryTab) =>
    t(`expertToolsXML.summary.tab.${camelCasify(tab)}.tabTitle`);

  return (
    <SummaryTabs>
      <Tabs.Tab
        identifier={SummaryTab.RATING}
        tabTitle={generateTabTitle(SummaryTab.RATING)}
      >
        <CalculatedEnergyRating
          onDownloadCertificate={getCertificate}
          energyAnalysis={energyAnalysisList[0]}
          isLoading={isLoading}
        />
      </Tabs.Tab>

      <Tabs.Tab
        identifier={SummaryTab.REVIEW}
        tabTitle={generateTabTitle(SummaryTab.REVIEW)}
      >
        <FileSummaryTab energyAnalysis={energyAnalysisList[0]} />
      </Tabs.Tab>

      <Tabs.Tab
        identifier={SummaryTab.MEASURES}
        tabTitle={generateTabTitle(SummaryTab.MEASURES)}
      >
        <MeasuresTab tiltakQuery={tiltakQuery} />
      </Tabs.Tab>

      <Tabs.Tab
        identifier={SummaryTab.BUILDING_INFORMATION}
        tabTitle={generateTabTitle(SummaryTab.BUILDING_INFORMATION)}
      >
        <BuildingInfoTab energyAnalysisList={energyAnalysisList} />
      </Tabs.Tab>
    </SummaryTabs>
  );
};
