import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Typography } from 'enova-frontend-components';

import { CalculationParameterMap } from '../../../../types/calculationParameters';

import { State } from './utils';
import { resolver } from './validation';
import { SubmitButtons } from './submitButtons';
import { ErrorMessage } from './errorMessage';
import { Categories } from './categories';

interface FormProps {
  parameters: CalculationParameterMap;
  defaultValues: State;
  onSubmit: (data: State) => void;
  goBack: (data: State) => void;
  isLoading: boolean;
}

export const Form: FC<FormProps> = ({
  parameters,
  defaultValues,
  isLoading,
  onSubmit,
  goBack
}) => {
  const { t } = useTranslation();
  const methods = useForm<State>({
    defaultValues,
    resolver
  });

  const navigateBack = () => goBack(methods.getValues());

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box>
          <Typography variant="h2" gutterBottom>
            {t('register.parameters.heading')}
          </Typography>

          <Typography paragraph className="text-preline">
            {t('register.parameters.description')}
          </Typography>
        </Box>

        <Categories beregningskjerneInput={parameters} />
        <ErrorMessage />
        <SubmitButtons isLoading={isLoading} goBack={navigateBack} />
      </form>
    </FormProvider>
  );
};
