import React, { FC } from 'react';

import { useAuthentication } from '../../hooks/useAuthentication';

import { SaveDialog } from './dialog';
import { AutoSaveParams } from './useSaveBeforeLogout';

interface SessionExpiresDialogProps {
  params?: AutoSaveParams;
}

export const SessionExpiresDialog: FC<SessionExpiresDialogProps> = ({
  params
}) => {
  const { sessionExpiry, closeSessionExpiresDialog } = useAuthentication();

  if (!sessionExpiry.isDialogOpen) {
    return null;
  }

  return (
    <SaveDialog
      params={params}
      isOpen={sessionExpiry.isDialogOpen}
      onClose={closeSessionExpiresDialog}
    />
  );
};
