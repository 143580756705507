import { useTranslation } from 'react-i18next';
import { Box, Grid, styled, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';

import { ShapeIcon } from '../../../components/buildingShape/shapeIcon';
import { BygningsForm } from '../../../../../utils/registerEnums';
import { SvgRadioButton } from '../../../../../../svgRadioButton';

interface Props {
  shape: BygningsForm;
}

const StyledSvgRadioButton = styled(SvgRadioButton)`
  label {
    min-height: 80px;
  }
`;

export const ShapeRadioButton: FC<Props> = ({ shape }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={3} lg={2} key={shape}>
      <StyledSvgRadioButton
        value={shape}
        label={
          <Box display="flex" flexDirection="row" gap={1} alignContent="center">
            <Box flexBasis={1}>
              <ShapeIcon shape={shape} />
            </Box>
            <Typography> {t(`register.buildingShape.${shape}`)}</Typography>
          </Box>
        }
      />
    </Grid>
  );
};
