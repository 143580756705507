import { styled } from 'enova-frontend-components';

const Wrapper = styled.div`
  overflow-x: auto;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  max-width: 100%;
`;

const SimpleTable = styled((props) => (
  <Wrapper>
    <table {...props} />
  </Wrapper>
))`
  border-collapse: collapse;
  width: 100%;
  margin: ${({ theme }) => theme.spacing(-2, 0)};

  td,
  th {
    padding: ${({ theme }) => theme.spacing(2)};

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &.actions {
      width: 1px;

      * {
        white-space: nowrap;
      }
    }
  }

  tbody tr:not(:last-child) td {
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  }
`;

export default SimpleTable;
