import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { getUserInput } from '../../services/api';
import { updateRegistration } from '../../store/registration/actions';
import { Detaljeringssteg } from '../../types/registration/userInput';
import { queryKeys } from '../../utils/react-query';

export const useGetUserInput = (
  regId: string | undefined,
  updateSteps: (detailingSteps?: Detaljeringssteg | undefined) => void
) => {
  const dispatch = useDispatch();

  const { data, isLoading, isError } = useQuery(
    [queryKeys.userInput, regId],
    () => getUserInput(regId),
    {
      onSuccess(userInput) {
        dispatch(updateRegistration({ userInput }));
        updateSteps(userInput?.detaljeringssteg);
      },
      enabled: !!regId
    }
  );

  return { response: data, isLoading, userInputError: isError };
};
