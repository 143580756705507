import { Box, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Veggsone } from '../../../../../types/registration/userInput';

interface Props {
  wallZone: Veggsone;
}

export const WallZoneInformation: FC<Props> = ({ wallZone }) => {
  const construction = wallZone.veggKonstruksjon ?? {};
  const { t } = useTranslation();
  const yearOfRehabilitation = construction.rehabiliteringEllerPabyggingsAr;
  const insulation = construction.isolasjonstykkelseIMillimeter;
  const wallType = construction.veggType;
  const unknownConstruction = construction.brukStandardVerdier;
  const unchanged = construction.uendretSidenOppforing;

  return (
    <Box display="flex" flexDirection="row" gap={4}>
      <Box display="flex" flexDirection="column">
        <Typography>{t('register.wallZone.length')}</Typography>
        <Typography flexWrap="wrap">
          <strong>
            {wallZone.lengdeIMeter} {t('suffix.m')}
          </strong>
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column">
        <Typography>{t('register.wallZone.adjoiningRoom')}</Typography>
        <Typography flexWrap="wrap">
          <strong>
            {t(`register.adjoiningRooms.option.${wallZone.tilliggendeRom}`)}
          </strong>
        </Typography>
      </Box>

      {yearOfRehabilitation && (
        <Box display="flex" flexDirection="column">
          <Typography>{t('register.wallZone.yearOfRehabilitation')}</Typography>
          <Typography flexWrap="wrap">
            <strong>{yearOfRehabilitation}</strong>
          </Typography>
        </Box>
      )}

      {wallType && insulation && (
        <Box display="flex" flexDirection="column">
          <Typography>{t('register.wallZone.construction')}</Typography>
          <Typography flexWrap="wrap">
            <strong>{t(`register.wallType.option.${wallType}`)}</strong>
          </Typography>
          <Typography flexWrap="wrap">
            <strong>
              {insulation} {t('suffix.mm')}
            </strong>
          </Typography>
        </Box>
      )}

      {unknownConstruction && (
        <Box display="flex" flexDirection="column">
          <Typography>{t('register.wallZone.construction')}</Typography>
          <Typography flexWrap="wrap">
            <strong>{'Ukjent'}</strong>
          </Typography>
        </Box>
      )}

      {unchanged && (
        <Box display="flex" flexDirection="column">
          <Typography>{t('register.wallZone.construction')}</Typography>
          <Typography flexWrap="wrap">
            <strong>{t('register.wallZone.unchanged')}</strong>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
