import { RadioButton, styled } from 'enova-frontend-components';

export const SvgRadioButton = styled(RadioButton)`
  border-radius: 3px;
  label {
    min-height: 50px;
  }

  .checked svg {
    stroke: white;
    fill: white;
  }

  .checked path {
    fill: white;
  }
`;
