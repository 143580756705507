import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledTable } from '../../styledTable';
import { SoknadStegMap } from '../../../../../../../../types/soknad';

import { SoknadTableRow } from './soknadTableRow';

interface SoknadTableProps {
  soknadStegMap?: SoknadStegMap;
}

const i18nPath = 'register.summary.energyCalculation.table.heading';

const SoknadTable: FC<SoknadTableProps> = ({ soknadStegMap }) => {
  const { t } = useTranslation();

  if (!soknadStegMap) {
    return null;
  }

  return (
    <StyledTable>
      <thead>
        <tr>
          <td>{t(`${i18nPath}.energyCalculation`)}</td>
          <td>{t(`${i18nPath}.heatLoss`)}</td>
          <td>{t(`${i18nPath}.netEnergReq`)}</td>
          <td>{t(`${i18nPath}.energyRating`)}</td>
        </tr>
      </thead>
      <tbody>
        {Object.entries(soknadStegMap).map(([steg, stegInfo]) => (
          <SoknadTableRow key={steg} stegInfo={stegInfo} steg={steg} />
        ))}
      </tbody>
    </StyledTable>
  );
};

export default SoknadTable;
