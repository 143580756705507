import { Box, Grid, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Address, Energiattest } from '../../../../../../types/building';

import {
  DownloadButton,
  EnergyGrade,
  ExpirationInfo,
  PublishedInfo,
  ShareButton,
  VisibilityConsentWrapper
} from './components';

interface Props {
  energyCertificate: Energiattest;
  address: Address;
}

export const EnergyCertificate: FC<Props> = ({
  energyCertificate,
  address
}) => {
  const { t } = useTranslation();
  const {
    energiKarakter,
    oppvarmingKarakter,
    publisertDato,
    utlopsDato,
    attestUri,
    synlighet,
    registreringId,
    tags
  } = energyCertificate;

  const isFlerbolig = tags.includes('flerbolig');

  return (
    <Box>
      {isFlerbolig && (
        <Typography mb={3} variant="h3">
          {t('buildingScreen.tab.energiattest.flerboligbygg')}
        </Typography>
      )}

      <Box display="grid" gap={{ sm: 2 }} width="fit-content">
        <PublishedInfo publisertDato={publisertDato} />
        <ExpirationInfo utlopsDato={utlopsDato} />
      </Box>

      {synlighet === 'reservert' && (
        <Box width={600} my={4}>
          <VisibilityConsentWrapper registreringsId={registreringId} />
        </Box>
      )}
      {energiKarakter && oppvarmingKarakter && (
        <Box my={5}>
          <EnergyGrade
            energyGrade={energiKarakter}
            heatingGrade={oppvarmingKarakter}
          />
        </Box>
      )}
      <Grid container spacing={4} className="mt-1 mb-2">
        <Grid item xs={12} sm="auto">
          <DownloadButton attestUri={attestUri} />
        </Grid>
        <Grid item xs={12} sm="auto">
          <ShareButton address={address} />
        </Grid>
      </Grid>
    </Box>
  );
};
