import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberField } from 'enova-frontend-components';
import { Control, Controller, UseFormTrigger } from 'react-hook-form';

import { State } from '../../utils';

interface ExpertValueProps {
  label?: string;
  parameterKey: string;
  control: Control<State, unknown>;
  trigger: UseFormTrigger<State>;
  allowNegative?: boolean;
}

export const ExpertValue: FC<ExpertValueProps> = ({
  label,
  parameterKey,
  control,
  trigger,
  allowNegative
}) => {
  const { t } = useTranslation();

  const expertValueLabel = `${t(
    `register.parameters.type.${parameterKey}`
  )}-${t('register.parameters.new')}}`;

  return (
    <Controller
      control={control}
      name={`${parameterKey}.expertValue`}
      render={({
        field: { value, onChange, ...rest },
        formState: { isSubmitted, errors }
      }) => {
        return (
          <NumberField
            {...rest}
            inputProps={{
              'aria-label': expertValueLabel
            }}
            decimalPrecision={2}
            allowNegative={allowNegative}
            error={!!errors?.[`${parameterKey}`]}
            fullWidth
            label={label}
            narrow={false}
            value={value ?? ''}
            onValueChange={({ floatValue }) => {
              onChange(floatValue ?? null);

              // Trigger validation when value is changed after a form submission
              // There seems to be a bug in react-hook-form where the validation is not triggered when using
              // a custom joi resolver
              if (isSubmitted) {
                trigger();
              }
            }}
          />
        );
      }}
    />
  );
};
