import { useQuery } from 'react-query';

import { getEnovaSupportLevels } from '../../../../../../../services/api';
import { SoknadSteg } from '../../../../../../../components/registering/utils/registerEnums';
import { queryKeys } from '../../../../../../../utils/react-query';

export const useGetEnovaSupportLevels = (
  soknadSteg: SoknadSteg,
  soknadId: string
) => {
  const { data, isLoading, isError, refetch } = useQuery(
    [queryKeys.supportLevels, soknadSteg, soknadId],
    () => getEnovaSupportLevels(soknadId, soknadSteg),
    {
      enabled: !!soknadId && !!soknadSteg
    }
  );

  return {
    enovaSupportLevels: data,
    isLoading,
    isError,
    refetchSupportLevels: refetch
  };
};
