import { useMutation } from 'react-query';

import { deleteRegistration } from '../../../../../services/api';
import { queryClient, queryKeys } from '../../../../../utils/react-query';

export const useDeleteEnhetRegistration = () => {
  const { mutate, isLoading } = useMutation(
    (registreringId: string) => deleteRegistration(registreringId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([queryKeys.userExpertRegistrations])
    }
  );
  return {
    isLoading: isLoading,
    deleteEnhetRegistration: mutate
  };
};
