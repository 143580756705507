import React, { FC, Fragment } from 'react';
import { Grid } from 'enova-frontend-components';
import { shallowEqual } from 'react-redux';

import useSelector from '../../../../../../../hooks/useSelector';
import { getMinifiedEtasjer } from '../../../../../../../store/registration/selectors';

import WallsSummaryContent from './wallsSummaryContent';

const WallsSummarySection: FC = () => {
  const allFloorsSameWallConstruction = useSelector(
    (state) => state.register.allFloorsSameWallConstruction
  );

  const etasjer = useSelector(getMinifiedEtasjer, shallowEqual);

  return (
    <Fragment>
      {allFloorsSameWallConstruction && etasjer?.[0] ? (
        <Grid item xs={12}>
          <WallsSummaryContent etasjeId={etasjer[0].id} />
        </Grid>
      ) : (
        etasjer.map(({ id }) => (
          <Grid item xs={12} key={id}>
            <WallsSummaryContent etasjeId={id} />
          </Grid>
        ))
      )}
    </Fragment>
  );
};

export default WallsSummarySection;
