import { Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import SimpleTable from '../../../../../../../../../../expertToolsXMLPortfolio/components/simpleTable';
import { SimpleEnergyAnalysis } from '../../../../../../../../../../../types/building';

import { TableRow } from './tableRow';

export const EnergyAnalysisTable: FC<{
  energyAnalysisList: SimpleEnergyAnalysis[];
}> = ({ energyAnalysisList }) => {
  const { t } = useTranslation();

  return (
    <SimpleTable>
      <thead>
        <tr>
          <td>
            <Typography bold>
              {t('expertToolsXML.summary.tab.buildingInformation.address')}
            </Typography>
          </td>
          <td>
            <Typography bold>
              {t(
                'expertToolsXML.summary.tab.buildingInformation.bruksenhetsnummer'
              )}
            </Typography>
          </td>
          <td>
            <Typography bold>
              {t('expertToolsXML.summary.tab.buildingInformation.identifier')}
            </Typography>
          </td>
        </tr>
      </thead>
      <tbody>
        {energyAnalysisList.map((energyAnalysis, index) => (
          <TableRow key={index} energyAnalysis={energyAnalysis} />
        ))}
      </tbody>
    </SimpleTable>
  );
};
