import { Box, Button } from 'enova-frontend-components';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Vindu } from '../../../types/registration/userInput';

import { AddorEditWindow } from './addOrEditWindow';
import { useAddWindowDialog } from './useAddWindowDialog';
import { WindowType } from './utils';

const disableAddWindowButton = (type: WindowType) => {
  if (type === 'roof' || type.hasWindows == null) {
    return false;
  }

  return !type.hasWindows;
};

interface AddWindowDialogProps {
  type: WindowType;
  handleAddWindow: (vindu: Vindu, amount: number) => void;
}

export const AddWindowDialog: FC<AddWindowDialogProps> = ({
  type,
  handleAddWindow
}) => {
  const { t } = useTranslation();
  const { isDialogOpen, open, cancel, closeDialog } = useAddWindowDialog();

  // If the user checks that the wall has no windows we close the dialog.
  useEffect(() => {
    if (type === 'roof') {
      return;
    }

    if (type.hasWindows === false && isDialogOpen) {
      closeDialog();
    }
  }, [type]);

  useEffect(() => {
    // Reset the dialog when the component unmounts.
    return () => {
      closeDialog();
    };
  }, []);

  if (!isDialogOpen) {
    // Disable button to add windows on a wall if hasWindows is set to false.
    // if this component is used to add windows on a roof its always enabled.
    const disableAddWindow = disableAddWindowButton(type);

    return (
      <Box marginTop={6}>
        <Button disabled={disableAddWindow} onClick={open}>
          {t('register.window.addNew')}
        </Button>
      </Box>
    );
  }

  const addWindow = (window: Vindu, amount: number) => {
    handleAddWindow(window, amount);
    closeDialog();
  };

  return (
    <AddorEditWindow handleCancel={cancel} onSubmit={addWindow} type={type} />
  );
};
