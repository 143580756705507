import React, { FC, Fragment, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  List,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import useStatisticsFilter from '../../hooks/useStatisticsFilter';
import {
  CategoryOptions,
  FilterCategory,
  SubcategoryOptions
} from '../../hooks/useStatisticsFilter/context';
import CollapsibleFilterList from '../../components/statistics/collapsibleFilterList';
import { camelCasify } from '../../utils/navigation';

const StatisticsFilterDrawer: FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const {
    categories,
    mode,
    setMode,
    clearAll,
    clearFilterCategory,
    filterValues,
    updateFilterValues
  } = useStatisticsFilter();

  const filterCount = Object.values(filterValues).reduce(
    (acc, cur) => acc + (cur?.length || 0),
    0
  );

  return (
    <Fragment>
      <Button
        onClick={() => setOpen(true)}
        className="mt-2"
        fullWidth
        variant="secondary"
      >
        {filterCount
          ? t('filterWithCount', {
              count: filterCount
            })
          : t('filter')}
      </Button>

      <Drawer open={open} onClose={() => setOpen(false)}>
        <Box flex={1}>
          <List>
            <List.Item divider>
              <Box display="flex" justifyContent="space-between">
                {mode ? (
                  <IconButton
                    aria-label={t('back')}
                    edge="start"
                    icon="arrow_left"
                    onClick={() => setMode(undefined)}
                  />
                ) : (
                  <IconButton
                    aria-label={t('close')}
                    edge="start"
                    icon="close"
                    onClick={() => setOpen(false)}
                  />
                )}

                <Typography
                  className="js-center d-flex ai-center"
                  display="inline"
                  variant="h4"
                  component="span"
                >
                  {mode ? t(mode) : t('filter')}
                </Typography>

                <Link
                  button
                  className="js-flex-end"
                  onClick={() =>
                    mode ? clearFilterCategory(mode) : clearAll()
                  }
                >
                  {t('reset')}
                </Link>
              </Box>
            </List.Item>

            {mode === FilterCategory.FYLKER ? (
              <Fragment>
                {CategoryOptions[mode].map((option) => (
                  <CollapsibleFilterList
                    key={option}
                    category={mode}
                    subcategory={SubcategoryOptions[mode]}
                    option={option}
                  />
                ))}
              </Fragment>
            ) : mode ? (
              <Fragment>
                {CategoryOptions[mode].map((option) => (
                  <List.Item
                    button
                    divider
                    key={option}
                    onClick={() =>
                      updateFilterValues(
                        mode,
                        option,
                        !filterValues[mode].includes(option),
                        SubcategoryOptions[mode]
                      )
                    }
                    rightIcon={
                      filterValues[mode].includes(option) ? 'check' : undefined
                    }
                    role="checkbox"
                  >
                    {t(`${mode}.${camelCasify(option)}`)}
                  </List.Item>
                ))}
              </Fragment>
            ) : (
              <Fragment>
                {categories?.map((category) => {
                  const subheading = filterValues[category]
                    .map((value) => t(value))
                    .join(', ');

                  return (
                    <List.Item
                      button
                      divider
                      key={category}
                      onClick={() => setMode(category)}
                      rightIcon="chevron_right"
                      subheading={
                        subheading ? (
                          <span className="d-flex">
                            <Typography
                              component="span"
                              noWrap
                              title={subheading}
                            >
                              {subheading}
                            </Typography>
                          </span>
                        ) : undefined
                      }
                    >
                      {t(category)}
                    </List.Item>
                  );
                })}
              </Fragment>
            )}
          </List>
        </Box>

        <Box p={2}>
          <Button onClick={() => setOpen(false)} fullWidth>
            {t('showResults')}
          </Button>
        </Box>
      </Drawer>
    </Fragment>
  );
};

export default StatisticsFilterDrawer;
