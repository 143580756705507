import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import useStore from '../../../hooks/useStore';
import { submitRegistration } from '../../../services/api';
import { UserInputErrorType } from '../../../services/types';
import { updateRegistration } from '../../../store/registration/actions';
import { queryKeys } from '../../../utils/react-query';

// Used only to differentiate the response in the query cache for now to avoid duplication of logic.
// Both adjust parameters and summary do a POST request on mount.
type SubmitRegistrationQueryKey = 'adjustParameters' | 'summary';

// This is a mutation disguised as a query. Need to fix this in the future.
export const useSubmitRegistrationAsQuery = (
  registrationId: string | undefined,
  queryKey: SubmitRegistrationQueryKey,
  buildingId?: string
) => {
  const dispatch = useDispatch();
  const store = useStore();

  const queryClient = useQueryClient();

  const { data, isLoading, isFetching, isError, isFetchedAfterMount, refetch } =
    useQuery(
      [queryKeys.UNSAFE_SumitRegistrationAsQuery, queryKey],
      () => {
        const userInput = store.getState().register.userInput;

        return submitRegistration(registrationId, userInput);
      },
      {
        onSuccess: (response) => {
          dispatch(
            updateRegistration({
              energiplan: response?.energiplan,
              userInput: response?.userInput
            })
          );

          if (queryKey === 'summary') {
            // Update all building queries. Dont know why this is important
            queryClient.invalidateQueries([queryKeys.building, buildingId]);
            queryClient.invalidateQueries([
              queryKeys.energyAnalysisList,
              buildingId
            ]);
          }
        },
        // Here you have to wait for the query to run until the redux store has all the state it needs.
        // This are all hacks to solve problems we have with the data flow in the client.
        enabled: !!store.getState().register.userInput.bygningsdetaljer
      }
    );

  // Only used to show a warning inside CalculatedEnergyRating component.
  const filteredErrors =
    data?.userInputErrorInformation?.filter(
      (item) =>
        item.errorType ===
        UserInputErrorType.KanIkkeFaKarakterenAUtenLekkasjetall
    ) ?? [];

  return {
    loading: isLoading || isFetching,
    error: isError,
    data,
    gradeLimitedToB: filteredErrors.length > 0,
    isFetchedAfterMount,
    refetch
  };
};
