import { FullScreenLoader } from 'enova-frontend-components';
import React, { FC } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { useAuthentication } from '../hooks/useAuthentication';
import { useUser } from '../hooks/useUser';

// Protected route that only authenticated users are allowed to access.
// If a user attempts to access the route without being authenticated, they
// will be redirected to the home page where they can log in.
export const ProtectedRoute: FC<RouteProps> = (props) => {
  const location = useLocation();
  const { isLoadingUserProfile, hasUserProfile, isFetchedAfterMount } =
    useUser();
  const { isLoading, isAuthenticated } = useAuthentication();

  if (isLoading || isLoadingUserProfile) {
    return <FullScreenLoader open />;
  }

  if (!isAuthenticated || !hasUserProfile) {
    return (
      <Redirect
        to={{
          pathname: '/',
          // Need to differentiate between a redirect to the home page after logout
          // or if an user is trying to access a protected route without being authenticated.

          // If the userProfile has been fetched after the component has mounted, we can assume
          // that the user either is trying to logout or their session has expired. If the userProfile
          // has not been fetched after the component has mounted, we can assume that the user is trying
          // to access a protected route while being unauthenticated.
          state: {
            from: isFetchedAfterMount
              ? null
              : `${location.pathname}${location.search}`
          }
        }}
      />
    );
  }

  return <Route {...props} />;
};
