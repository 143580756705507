import { useReducer } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Vindu } from '../../../../types/registration/userInput';
import { GlassType, KarmType } from '../../utils/registerEnums';
import { WindowSpecType } from '../../utils/window';
import { getWindowSpecType, VinduKarmTypeGlassTypeMap } from '../utils';

interface State {
  window: Vindu;
  equalNumberOfWindows?: number;
  windowSpecType: WindowSpecType;
}

export enum ActionType {
  UpdateType = 'UPDATE_TYPE',
  UpdateEqualNumberOfWindows = 'UPDATE_EQUAL_NUMBER_OF_WINDOWS',
  UpdateGlassType = 'UPDATE_GLASS_TYPE',
  UpdateKarmType = 'UPDATE_KARM_TYPE',
  UpdateValue = 'UPDATE_VALUE'
}

type Action =
  | { type: ActionType.UpdateType; payload: { value: WindowSpecType } }
  | { type: ActionType.UpdateEqualNumberOfWindows; payload: { value?: number } }
  | { type: ActionType.UpdateGlassType; payload: { value: GlassType } }
  | { type: ActionType.UpdateKarmType; payload: { value: KarmType } }
  | {
      type: ActionType.UpdateValue;
      payload: {
        key: Extract<keyof Vindu, 'arealIKvm' | 'fabrikasjonsAr' | 'uverdi'>;
        value: number | undefined;
      };
    };

const windowReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'UPDATE_TYPE': {
      const { value } = action.payload;

      // Reset all properties from window when window type is changed.
      const newWindow: Vindu = {
        id: state.window.id,
        arealIKvm: state.window.arealIKvm
      };

      if (value === WindowSpecType.UNKNOWN) {
        newWindow.brukStandardVerdier = true;
      }

      return { ...state, window: newWindow, windowSpecType: value };
    }
    case 'UPDATE_EQUAL_NUMBER_OF_WINDOWS': {
      const { value } = action.payload;

      return { ...state, equalNumberOfWindows: value };
    }
    case 'UPDATE_VALUE': {
      const { key, value } = action.payload;

      return { ...state, window: { ...state.window, [key]: value } };
    }
    case 'UPDATE_GLASS_TYPE': {
      const { value } = action.payload;

      return { ...state, window: { ...state.window, glassType: value } };
    }
    case 'UPDATE_KARM_TYPE': {
      const { value } = action.payload;

      const glassType = state.window.glassType;
      const doesNotExist =
        glassType && !VinduKarmTypeGlassTypeMap[value].includes(glassType);

      return {
        ...state,
        window: {
          ...state.window,
          karmType: value,
          glassType: doesNotExist ? undefined : glassType
        }
      };
    }
    default:
      return state;
  }
};

export const useWindowReducer = (
  vindu: Vindu | undefined,
  equalNumberOfWindows: number | undefined
) => {
  const defaultWindow = vindu ?? { id: uuidv4(), brukStandardVerdier: true };

  const [state, dispatch] = useReducer(windowReducer, {
    window: defaultWindow,
    equalNumberOfWindows: equalNumberOfWindows ?? 1,
    windowSpecType: getWindowSpecType(defaultWindow)
  });

  return { state, dispatch };
};
