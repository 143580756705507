import { FC } from 'react';

import { ReactComponent as ZShapeNorth } from '../../../../../../../assets/icons/shapes/z-shape/z-shape-1.svg';
import { ReactComponent as ZShapeEast } from '../../../../../../../assets/icons/shapes/z-shape/z-shape-2.svg';
import { ReactComponent as ZShapeSouth } from '../../../../../../../assets/icons/shapes/z-shape/z-shape-3.svg';
import { ReactComponent as ZShapeWest } from '../../../../../../../assets/icons/shapes/z-shape/z-shape-4.svg';
import { Himmelretning } from '../../../../../utils/registerEnums';

import { ShapeProps } from './types';

export const ZShape: FC<ShapeProps> = ({ direction }) => {
  switch (direction) {
    case Himmelretning.Nord:
      return <ZShapeNorth />;
    case Himmelretning.Ost:
      return <ZShapeEast />;
    case Himmelretning.Sor:
      return <ZShapeSouth />;
    case Himmelretning.Vest:
      return <ZShapeWest />;

    default:
      return <ZShapeNorth />;
  }
};
