import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { NumberField } from 'enova-frontend-components';
import { FC } from 'react';

import {
  AdvancedSearchFormData,
  useAdvancedSearchFormContext
} from '../../types';

export interface AdvancedSearchFieldProps {
  field: keyof Omit<AdvancedSearchFormData, 'kommuneOptions'>;
  disabled?: boolean;
  required?: boolean;
}
export const AdvancedSearchNumberField: FC<AdvancedSearchFieldProps> = ({
  field,
  required
}) => {
  const { t } = useTranslation();
  const { control } = useAdvancedSearchFormContext();

  const label = t(`search.advanced.field_${field}`);

  return (
    <Controller
      control={control}
      name={field}
      rules={{
        required: {
          value: !!required,
          message: label
        },
        validate: {
          invalid: (value) => required && value != ''
        }
      }}
      render={({ field: { value, ...rest }, fieldState }) => {
        return (
          <NumberField
            error={!!fieldState?.error}
            fullWidth
            label={label}
            inputProps={{ 'aria-label': label }}
            autoComplete
            useThousandSeparator={false}
            allowLeadingZeros
            title={label}
            value={value ?? null}
            {...rest}
          />
        );
      }}
    />
  );
};
