import { shallowEqual, useDispatch } from 'react-redux';
import { FC } from 'react';

import { DetailingStep } from '../../../../utils/registerEnums';
import useSelector from '../../../../../../hooks/useSelector';
import { getDetailingSteps } from '../../../../../../store/registration/selectors';
import { updateDetailingSteps } from '../../../../../../store/registration/actions';

import { StepCheckBox } from './stepCheckBox';

export const VeggerCheckBox: FC = () => {
  const dispatch = useDispatch();
  const detailingSteps = useSelector(getDetailingSteps, shallowEqual);

  // Disable Vegger if Bygningsform is not checked
  const bygningsformIsChecked = !!detailingSteps[DetailingStep.Bygningsform];

  const onVeggerChange = (checked: boolean) => {
    // Vegger is required for Vinduer step to be active
    // If Vegger is unchecked we programmatically uncheck Vinduer
    if (!checked) {
      dispatch(
        updateDetailingSteps({ step: DetailingStep.Vinduer, value: false })
      );
    }
  };

  return (
    <StepCheckBox
      step={DetailingStep.Vegger}
      onChange={onVeggerChange}
      disabled={!bygningsformIsChecked}
    />
  );
};
