import React, { FC, Fragment, useState } from 'react';
import {
  Button,
  FormMessage,
  Grid,
  Link,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import {
  camelCasify,
  getNewSoknadScreenPath,
  SoknadScreenParams
} from '../../../../utils/navigation';
import { SoknadSteg } from '../../../../components/registering/utils/registerEnums';
import { StartRegistrationDialog } from '../../../../components/startRegistrationDialog';
import { isBuildingGarageOrAnnex } from '../../../../utils/matrikkelBygningsTyper';

interface EnergyAnalysisErrorProps {
  onClick: () => void;
}

export const EnergyAnalysisError: FC<EnergyAnalysisErrorProps> = ({
  onClick
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Grid item xs={12}>
        <FormMessage fullWidth title={t('generalError.title')}>
          <Typography>{t('generalError.description')}</Typography>
          <Link button color="inherit" onClick={onClick}>
            {t('generalError.retry')}
          </Link>
        </FormMessage>
      </Grid>
    </Fragment>
  );
};

interface CreateApplicationLinkProps {
  bygningsTypeNummer: string;
  isHovedbygg: boolean;
  soknadSteg?: SoknadSteg;
}

export const CreateApplicationLink: FC<CreateApplicationLinkProps> = ({
  bygningsTypeNummer,
  isHovedbygg,
  soknadSteg
}) => {
  const { buildingId } = useParams<SoknadScreenParams>();
  const { push } = useHistory();
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const navigateToRegistration = () => push(getNewSoknadScreenPath(buildingId));

  const startRegistration = () => {
    if (!isHovedbygg || isBuildingGarageOrAnnex(bygningsTypeNummer)) {
      return setIsDialogOpen(true);
    }

    navigateToRegistration();
  };

  return (
    <Fragment>
      <Grid item xs={12} sm="auto">
        <Button fullWidth variant="secondary" onClick={startRegistration}>
          {t('documentsForEnovaApplication.create', {
            context: soknadSteg ? camelCasify(soknadSteg) : undefined
          })}
        </Button>
      </Grid>

      <StartRegistrationDialog
        bygningsTypeNummer={bygningsTypeNummer}
        isHovedBygg={isHovedbygg}
        isOpen={isDialogOpen}
        onConfirm={navigateToRegistration}
        onClose={() => setIsDialogOpen(false)}
      />
    </Fragment>
  );
};
