/* eslint-disable camelcase */
import { EnergyGrade, HeatingGrade } from 'enova-frontend-components';

import {
  ExpertToolsKuldeArea,
  ExpertToolsVarmeArea
} from '../utils/navigation';
import {
  ByggType,
  Malform,
  SoknadSteg
} from '../components/registering/utils/registerEnums';

import { Tiltak } from './registration/tiltak';
import { UserInput } from './registration/userInput';
import { CalculationParameterMap } from './calculationParameters';

export type BuildingListProps = {
  displayOptions?: boolean;
};

export type BuildingType = {
  kategori: string;
  kategoriNavn: string;
  type: string;
  typeNavn: string;
  energiObjektType: string;
};

export type Address = {
  gateNavn: string;
  nummer: string;
  bokstav: string;
  postNummer: string;
  postSted: string;
  kommuneNummer: string;
  kommuneNavn: string;
};

export type Property = {
  gardsNummer: string;
  bruksNummer: string;
  festeNummer: string;
  seksjonsNummer: string;
  bygningsTypeNummer: string;
  bygningsNummer: string;
  bruksEnhetsNummer: string;
  bruksenhetsTypeKode: string;
  bruksAreal: number;
  oppvarmetBruksAreal: number;
  byggAr: number;
  isHovedbygg: boolean;
  isAndel: boolean;
  andelNummer: string;
  borettslagNummer: string;
  borettslagNavn: string;
  bruksenhetsIdentId: string;
  adresse: Address;
  kanEnergimerkesAvPrivatPerson: boolean;
};

export type BuildingDetails = Property & {
  allePubliserteRegistreringList: string[];
  apenEkspertRegistreringList: string[];
  apenRegistreringList: string[];
  energiObjektId: string;
  forrigePublisertEnkeltRegistreringId: string;
  ikkeGyldigePubliserteEnergiplaner: SimpleEnergyPlan[];
  isHovedbygg?: boolean;
  publisertRegistreringList: string[];
  gyldigeEnergiattester: Energiattest[];
  gyldigSoknad?: Soknad;
};

export type Energiattest = {
  attestSizeKb?: number;
  attestUri: string;
  energiKarakter: EnergyGrade;
  oppvarmingKarakter: HeatingGrade;
  publisertDato: Date;
  utlopsDato: Date;
  synlighet: CertificateVisibility;
  registreringId: string;
  tags: string[];
};

export type Soknad = {
  soknadId: string;
  isOwner: boolean;
  registreringForSteg1?: string;
  registreringForSteg2?: string;
  registreringForSteg3?: string;
};

export type DetailedBuildingDetails = BuildingDetails & {
  utlopsDato?: Date;
} & Pick<EnergyPlan, 'gjenstaendeTiltak' | 'utforteTiltak'>;

export type BuildingSearch = {
  adressekode: number;
  adressenavn: string;
  adressetekst: string;
  adressetekstutenadressetilleggsnavn?: string;
  adressetilleggsnavn?: string;
  bokstav?: string;
  bruksnummer: number;
  festenummer?: number;
  gardsnummer: number;
  kommunenavn: string;
  kommunenummer: number;
  nummer?: number;
  objtype?: string;
  oppdateringsdato?: Date;
  postnummer: number;
  poststed: string;
  undernummer?: number;
  bruksenhetsnummer?: string[];
  bygningsnummer?: string;
};

export type BuildingSearchResponse = {
  adresser: BuildingSearch[];
};

export type SearchValues = {
  bokstav?: string;
  bruksnummer?: string | number;
  festenummer?: string | number;
  gardsnummer?: string | number;
  gatenavn?: string;
  kommunenummer?: string | number;
  kommunenavn?: string | number;
  nummer?: string | number;
  postnummer?: string | number;
  poststed?: string;
  seksjonsnummer?: string | number;
  bygningsnummer?: string | number;
  bruksenhetsnummer?: string;
  bareHovedbygg?: boolean;
};

export type SimpleEnergyAnalysis = {
  registreringId: string;
  registrering: SimpleBuildingRegistration;
  energiplan?: SimpleEnergyPlan;
};

export enum Typemerke {
  Advanced = 'advanced',
  Simple = 'simple',
  Schema = 'schema'
}

export type SimpleBuildingRegistration = {
  registreringId: string;
  soknadId?: string;
  opprettetDato: Date;
  endretDato: Date;
  utlopsDato: Date;
  publisertDato?: Date;
  energiObjektId: string;
  energiObjektType: string;
  bygningsType: BuildingType;
  bruksAreal: number;
  oppvarmetBruksAreal: number;
  byggAr: number;
  malform: Malform;
  typemerke: Typemerke;
  registreringEiendom: Property;
  lukketSoknadSteg2?: boolean;
};

export type EnergyPlan = {
  attestSizeKb?: number;
  attestUri?: string;
  beskrivelse: string;
  endretDato: Date;
  energimerke?: SimpleEnergyMarking;
  energiObjektType: string;
  energiplanId: string;
  gjenstaendeTiltak?: Tiltak[];
  navn: string;
  opprettetDato: Date;
  publisertDato?: Date;
  registreringId: string;
  typemerke: Typemerke;
  soknadSteg?: SoknadSteg;
  utforteTiltak?: Tiltak[];
  synlighet: CertificateVisibility;
};

export type CertificateVisibility = 'offentlig' | 'reservert';

export type SimpleEnergyPlan = Omit<
  EnergyPlan,
  'gjenstaendeTiltak' | 'utforteTiltak'
>;

export type SimpleEnergyMarkingProperties = {
  oppvarmingKarakter?: HeatingGrade;
  legacy?: boolean;
  lukketSoknadSteg2?: boolean;
};

export type Energiforbruk = {
  annen_energivare: number;
  belysning: number;
  biobrensel: number;
  co2_utslipp: number;
  elektrisitet: number;
  energi_kostnader: number;
  energi_politisk: number;
  fjernvarme: number;
  gass: number;
  kjoeling: number;
  olje: number;
  primaerenergi: number;
  pumper: number;
  romoppvarming: number;
  teknisk_utstyr: number;
  totalt_levert_energi: number;
  totalt_netto_energibehov: number;
  varmtvann: number;
  ventilasjonsvarme: number;
  vifter: number;
  varmetransportkoeff_wk: number;
};

export type UtData = {
  energiforbruk: Energiforbruk;
  andelFornybar: number;
};

export type SimpleEnergyMarking = {
  beskrivelse: string;
  ekspertInndata?: string;
  inndata?: Inndata;
  energiKarakter?: EnergyGrade;
  energimerkeId: string;
  energiplanId: string;
  properties: SimpleEnergyMarkingProperties | undefined;
  simulert: boolean;
  utdata: UtData | undefined;
};

export type Inndata = {
  beregningskjerneInput?: CalculationParameterMap;
};

export type RegistrationRequest = {
  registreringId?: string;
  soknadId?: string;
  soknadSteg?: SoknadSteg;
  opprettetDato?: Date;
  endretDato?: Date;
  utlopsDato?: Date;
  publisertDato?: Date;
  energiObjektId: string;
  energiObjektType: string;
  malform?: Malform;
  typemerke?: Typemerke;
  byggType?: ByggType;
  bruksAreal?: number;
  oppvarmetBruksAreal?: number;
  byggAr?: number;
  userInput?: UserInput;
};

export type Registration = {
  registreringId: string;
  soknadId?: string;
  soknadSteg?: SoknadSteg;
  opprettetDato: Date;
  endretDato: Date;
  utlopsDato: Date;
  publisertDato?: Date;
  energiObjektId: string;
  energiObjektType: string;
  malform: string;
  typemerke: Typemerke;
  bygningsType: BuildingType;
  bruksAreal: number;
  oppvarmetBruksAreal: number;
  byggAr: number;
  registreringEiendom: Property;
  userInput?: UserInput;
};

export type ExpertReport = {
  rapportId?: string;
  tittel: string;
  rapport: string;
  ekspertRapportType: ExpertToolsKuldeArea | ExpertToolsVarmeArea;
  energiObjektId: string;
  opprettetDato?: Date;
  utlopsDato?: Date;
};
