import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getInsulationValues } from '../../services/api';
import { queryKeys } from '../../utils/react-query';
import { createErrorMessage } from '../../utils/utils';

export const useGetInsulationData = () => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery(
    [queryKeys.insulation],
    () => getInsulationValues(),
    {
      staleTime: 24 * 3600 * 1000,
      cacheTime: Infinity,
      onError: () => {
        addSnack(createErrorMessage(t('register.insulation.error')), {
          variant: 'error'
        });
      }
    }
  );

  return {
    insulationData: data,
    isLoading,
    insulationError: isError
  };
};
