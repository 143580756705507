import { useContext } from 'react';

import { AuthenticationContext } from './provider';
import { REFERRER_KEY } from './utils';

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error(
      'useAuthenticationContext must be used within an AuthenticationContextProvider'
    );
  }

  return context;
};

export { REFERRER_KEY };
