import { useDispatch } from 'react-redux';

import {
  addEtasjerVeggsone,
  addEtasjeVeggsone,
  removeEtasjeVeggsone,
  updateEtasjerVeggsone,
  updateEtasjeVeggsone
} from '../../../../../../store/registration/actions';
import { Veggsone } from '../../../../../../types/registration/userInput';
import { VeggId } from '../../../../utils/registerEnums';

import { useBuildingConstructionData } from './useBuildingConstructionData';

export const useWallZoneOperations = (etasjeId: string, veggId: VeggId) => {
  const dispatch = useDispatch();

  const { allFloorsSameWallConstruction } = useBuildingConstructionData();

  const addVeggsone = (veggsone: Omit<Veggsone, 'id'>) =>
    dispatch(
      allFloorsSameWallConstruction
        ? addEtasjerVeggsone({ veggId, veggsone })
        : addEtasjeVeggsone({ veggId, etasjeId, veggsone })
    );

  const removeVeggsone = (veggsoneId: string) => {
    dispatch(removeEtasjeVeggsone({ veggsoneId }));
  };

  const updateVeggsone = (veggsone: Veggsone, veggsoneIndex: number) => {
    if (allFloorsSameWallConstruction) {
      dispatch(updateEtasjerVeggsone({ veggId, veggsone, veggsoneIndex }));
      return;
    }

    dispatch(
      updateEtasjeVeggsone({
        veggsone
      })
    );
  };

  return {
    addVeggsone,
    updateVeggsone,
    removeVeggsone
  };
};
