import { Box, styled } from 'enova-frontend-components';

import { VeggId } from './registering/utils/registerEnums';

interface HighlightProps {
  highlightedWallId: VeggId;
}

export const HighlightedShapeWrapper = styled(Box)<HighlightProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;

  #box-${({ highlightedWallId }) => highlightedWallId},
    #line-${({ highlightedWallId }) => highlightedWallId} {
    fill: ${({ theme }) => theme.palette.fjordGreen};
    stroke: ${({ theme }) => theme.palette.fjordGreen};
    stroke-width: 2px;
  }
`;
