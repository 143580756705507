import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  CheckGroup,
  Grid,
  Hidden,
  Select,
  TTableHeadCell,
  Typography
} from 'enova-frontend-components';
import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import { getYear } from 'date-fns';
import { useQuery } from 'react-query';

import RouterLink from '../../../../components/routerLink';
import {
  camelCasify,
  getStatisticsScreenPath
} from '../../../../utils/navigation';
import CollapsibleFilterCheckbox from '../../../../components/statistics/collapsibleFilterCheckbox';
import useStatisticsFilter from '../../../../hooks/useStatisticsFilter';
import {
  CategoryOptions,
  FilterCategory,
  SubcategoryOptions
} from '../../../../hooks/useStatisticsFilter/context';
import StaticticsFilterDrawer from '../../../../components/statistics/statisticsFilterDrawer';
import {
  AggregatedStatisticsByYear,
  AggregatedStatisticsByYearMap,
  StatistikkRequestType,
  Year,
  Years
} from '../../../../types/statistics';
import { StyledStatisticsTableByBuildingType } from '../../utils';
import i18n from '../../../../i18n';
import { loadStatistics } from '../../../../services/api';
import { queryKeys } from '../../../../utils/react-query';
import { toCamelCase } from '../../../../utils/utils';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

enum Range {
  FIVE = 'rangeFive',
  TEN = 'rangeTen',
  ALL = 'rangeAll'
}

const maxWidthFilters = 300;
const currentYear = getYear(new Date());

const StatisticsBuildingTypeView: FC = () => {
  const { t } = useTranslation();
  const [range, setRange] = useState(Range.TEN);
  const [yearRange, setYearRange] = useState<string[]>([]);

  const [statisticsBolig, setStatisticsBolig] =
    useState<AggregatedStatisticsByYearMap>({});

  const [statisticsYrkesBygg, setStatisticsYrkesBygg] =
    useState<AggregatedStatisticsByYearMap>({});

  const {
    categories,
    filterValues,
    subcategoryFilterValues,
    updateFilterValues,
    body
  } = useStatisticsFilter();

  const { data: statisticsByYear } = useQuery(
    [queryKeys.statistic, StatistikkRequestType.BYGGTYPE, body],
    () => loadStatistics(StatistikkRequestType.BYGGTYPE, body)
  );

  useEffect(() => {
    let diff = 999;
    if (range === Range.FIVE) diff = 5;
    if (range === Range.TEN) diff = 10;
    let count = 0;
    const updatedYears = [];

    // Select relevant year based on range choice
    for (const year of Years) {
      if (Number(year) <= currentYear) {
        if (count < diff) updatedYears.push(year);
        count += 1;
      }
    }
    const sortedYearsList = Object.values(updatedYears).sort(
      (a, b) => (a > b && 1) || -1
    );
    setYearRange(sortedYearsList);
  }, [range]);

  useEffect(() => {
    if (statisticsByYear && Object.keys(statisticsByYear).length === 0) return;

    const resultBolig: AggregatedStatisticsByYearMap = {};
    const resultYrkesBygg: AggregatedStatisticsByYearMap = {};

    statisticsByYear &&
      Object.values(statisticsByYear).map((obj) => {
        const year = obj.publisertAr.toString();
        const total = obj.total;
        const category =
          obj.bygningsKategori.charAt(0).toUpperCase() +
          obj.bygningsKategori.slice(1);
        const type: string =
          obj.byggType.charAt(0).toUpperCase() + obj.byggType.slice(1);

        const tempresult: AggregatedStatisticsByYearMap =
          obj.energiObjektType.toLowerCase() === 'privatbolig'
            ? resultBolig
            : resultYrkesBygg;

        // Aggregate results for tables
        if (type in tempresult && yearRange.includes(year)) {
          const row = tempresult[type];
          row[year as Year] += total;
          row.total += total;
        } else if (yearRange.includes(year)) {
          const row: AggregatedStatisticsByYear = {
            id: type,
            buildingCategory: t(category),
            buildingType: t(`buildingCategory.${camelCasify(type)}`),
            ...Years.reduce(
              (acc, cur) => ({
                ...acc,
                [cur]: year === cur ? total : 0
              }),
              {} as Record<Year, number>
            ),
            total: total,
            accumulated: 0
          };
          tempresult[type] = row;
        }
      });

    // Convert objects to array and sort by date
    const tempresultBolig = Object.values(resultBolig).sort((a, b) =>
      a.buildingType > b.buildingType ? 1 : -1
    );

    const tempresultYrkesBygg = Object.values(resultYrkesBygg).sort((a, b) =>
      a.buildingType > b.buildingType ? 1 : -1
    );

    // Convert back to objects
    const finalResultBolig: AggregatedStatisticsByYearMap =
      tempresultBolig.reduce(
        (acc, result) => ({ ...acc, [result.buildingType.toString()]: result }),
        {}
      );

    const finalResultYrkesBygg: AggregatedStatisticsByYearMap =
      tempresultYrkesBygg.reduce(
        (acc, result) => ({ ...acc, [result.buildingType.toString()]: result }),
        {}
      );

    // Save results
    setStatisticsBolig(finalResultBolig);
    setStatisticsYrkesBygg(finalResultYrkesBygg);
  }, [
    statisticsByYear,
    filterValues,
    subcategoryFilterValues,
    i18n.language,
    yearRange
  ]);

  // TABLE HEADERS
  const tableHeadCells = useMemo<
    readonly TTableHeadCell<AggregatedStatisticsByYear>[]
  >(() => {
    const TableHeadCells: TTableHeadCell<AggregatedStatisticsByYear>[] = [
      {
        id: 'buildingType',
        isMainProperty: true,
        label: t('buildingType'),
        numeric: false,
        disableSort: true
      }
    ];

    for (const year of yearRange) {
      const yearKey = year as keyof AggregatedStatisticsByYear;
      TableHeadCells.push({
        id: yearKey,
        numeric: true,
        label: year,
        disableSort: true
      });
    }
    TableHeadCells.push({
      id: 'total',
      numeric: true,
      label: 'Total',
      disableSort: true
    });

    return TableHeadCells as readonly TTableHeadCell<AggregatedStatisticsByYear>[];
  }, [i18n.language, yearRange]);

  return (
    <Fragment>
      <Box display="flex" flexDirection="column">
        <RouterLink className="mb-3 as-start" to={getStatisticsScreenPath()}>
          {t('navigation.statisticsScreen.back')}
        </RouterLink>

        <Typography variant="h2" component="span" gutterBottom>
          {t('statisticsScreen.byggtype.title')}
        </Typography>

        <Typography pb={5}>
          {t('statisticsScreen.byggtype.description')}
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Hidden mdDown>
          <Grid item md="auto" minWidth={maxWidthFilters}>
            {categories?.map((category) => (
              <Box key={category} maxWidth={maxWidthFilters}>
                {category === FilterCategory.FYLKER ? (
                  <CheckGroup key={category} label={t(category)} marginBottom>
                    {CategoryOptions[category].map((option) => (
                      <CollapsibleFilterCheckbox
                        key={option}
                        category={category}
                        subcategory={SubcategoryOptions[category]}
                        option={option}
                      />
                    ))}
                  </CheckGroup>
                ) : (
                  <CheckGroup key={category} label={t(category)} marginBottom>
                    {CategoryOptions[category].map((option) => (
                      <Checkbox
                        key={option}
                        label={t(`${category}.${toCamelCase(option)}`)}
                        width="fullWidth"
                        checked={filterValues[category].includes(option)}
                        onChange={(_, checked) =>
                          updateFilterValues(
                            category,
                            option,
                            checked,
                            SubcategoryOptions[category]
                          )
                        }
                      />
                    ))}
                  </CheckGroup>
                )}
              </Box>
            ))}
          </Grid>
        </Hidden>

        <Grid
          item
          xs={12}
          maxWidth={{ md: `calc(100% - ${maxWidthFilters}px)` }}
        >
          {categories && (
            <Hidden mdUp>
              <StaticticsFilterDrawer />
            </Hidden>
          )}

          <Box className="w-100" display="flex" pt={4}>
            <Select
              className="pt-3 pb-3"
              options={Object.values(Range).map((u) => ({
                label: String(t(u)),
                value: u
              }))}
              value={range}
              onChange={({ currentTarget: { value } }) =>
                setRange(value as Range)
              }
            />
          </Box>

          <Box pb={3}>
            <StyledStatisticsTableByBuildingType<AggregatedStatisticsByYear>
              alternating
              dense={true}
              disablePagination={true}
              headCells={tableHeadCells}
              rows={Object.values(statisticsBolig)}
              tableTitle={t('statistics.building.private.title')}
            />
          </Box>

          <Box pb={3}>
            <StyledStatisticsTableByBuildingType<AggregatedStatisticsByYear>
              alternating
              dense={true}
              disablePagination={true}
              headCells={tableHeadCells}
              rows={Object.values(statisticsYrkesBygg)}
              tableTitle={t('statistics.building.commercial.title')}
            />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default StatisticsBuildingTypeView;
