/* eslint-disable camelcase */

/* eslint-disable max-len */
export enum BygningsForm {
  Unknown = 'unknown',
  Rektangulaert = 'rektangulaert',
  LForm = 'lForm',
  LFormSpeilvendt = 'lFormSpeilvendt',
  TForm = 'tForm',
  UForm = 'uForm',
  Atriumshus = 'atriumshus',
  YForm = 'yForm',
  XForm = 'xForm',
  ZForm = 'zForm',
  ZFormSpeilvendt = 'zFormSpeilvendt'
}

export enum BygningsKategori {
  Barnehager = 'barnehager',
  Boligblokker = 'boligblokker',
  Forretningsbygg = 'forretningsbygg',
  Hoteller = 'hoteller',
  Idrettsbygg = 'idrettsbygg',
  Kontorbygg = 'kontorbygg',
  Kulturbygg = 'kulturbygg',
  LettIndustriVerksted = 'lettIndustriVerksted',
  None = 'none',
  Skolebygg = 'skolebygg',
  Smahus = 'smahus',
  Sykehjem = 'sykehjem',
  Sykehus = 'sykehus',
  UniversitetHoyskole = 'universitetHoyskole'
}

export enum ByggType {
  None = 'none',

  // Småhus
  Enebolig = 'enebolig',
  EneboligMedUtleiedel = 'eneboligMedUtleiedel',
  TomannsboligHorisontalDelt = 'tomannsboligHorisontalDelt',
  TomannsboligVertikalDelt = 'tomannsboligVertikalDelt',
  Firemannsbolig = 'firemannsbolig',
  KjedetEnebolig = 'kjedetEnebolig',
  Rekkehus = 'rekkehus',
  Fritidsbolig = 'fritidsbolig',
  AnnetSmahus = 'annetSmahus',

  // Boligblokker
  Leilighet = 'leilighet',
  AnnenBolig = 'annenBolig',

  // Barnehager
  Barnehagebygning = 'barnehagebygning',

  // Kontorbygg
  KontorerEnkle = 'kontorerEnkle',
  KontorerMedVentilasjonOgKjoleanlegg = 'kontorerMedVentilasjonOgKjoleanlegg',
  MesseKongressbygg = 'messeKongressbygg',
  AnnetKontorbygg = 'annetKontorbygg',

  // Skolebygg
  Undervisningslokaler = 'undervisningslokaler',
  SkoleMedSvommeanlegg = 'skoleMedSvommeanlegg',
  SkoleMedIdrettshall = 'skoleMedIdrettshall',

  // Universitets- og høyskolebygg
  Undervisningsbygg = 'undervisningsbygg',
  Laboratoriebygg = 'laboratoriebygg',
  AnnenForskningsbygning = 'annenForskningsbygning',

  // Sykehus
  Sykehus = 'sykehus',
  Sykehotell = 'sykehotell',
  SykehusLaboratoriebygg = 'sykehusLaboratoriebygg',

  // Sykehjem
  Sykehjemsbygning = 'sykehjemsbygning',

  // Hoteller
  Hotellbygning = 'hotellbygning',
  BygningForOvernatting = 'bygningForOvernatting',
  RestaurantBygning = 'restaurantBygning',
  AnnenHotellbygning = 'annenHotellbygning',

  // Idrettsbygg
  Idrettshall = 'idrettshall',
  IdrettshallMedDusjanlegg = 'idrettshallMedDusjanlegg',
  IdrettshallMedSvommeanlegg = 'idrettshallMedSvommeanlegg',

  // Forretningsbygg
  Butikk = 'butikk',
  ButikkMedKjolerom = 'butikkMedKjolerom',
  Bensinstasjon = 'bensinstasjon',

  // Kulturbygg
  Kulturbyggning = 'kulturbyggning',
  Kino = 'kino',
  MuseumBiblioteksbygning = 'museumBiblioteksbygning',
  BygningForReligioseAktiviteter = 'bygningForReligioseAktiviteter',

  // Lett industri, verksteder
  Industribygning = 'industribygning',
  Energiforsyningsbyggning = 'energiforsyningsbyggning',
  Lagerbygning = 'lagerbygning',
  FiskeriLandbruksbygning = 'fiskeriLandbruksbygning',
  AnnenIndustri = 'annenIndustri'
}

export enum DorType {
  Default = 'default',
  EkstraIsolertYtterdorBalkongdor_UVerdi_lte0p8 = 'ekstraIsolertYtterdorBalkongdor_UVerdi_lte0p8',
  EldreHeltredorBalkongdor30PctGlass_UVerdi_lte2p2 = 'eldreHeltredorBalkongdor30PctGlass_UVerdi_lte2p2',
  EldreHeltredorBalkongdor60PctGlass_UVerdi_lte2p4 = 'eldreHeltredorBalkongdor60PctGlass_UVerdi_lte2p4',
  EldreHeltredorBalkongdor90PctGlass_UVerdi_lte2p8 = 'eldreHeltredorBalkongdor90PctGlass_UVerdi_lte2p8',
  EldreHeltredorUtenGlass_UVerdi_lte2p0 = 'eldreHeltredorUtenGlass_UVerdi_lte2p0',
  GarasjedorPortEkstraIsolert_UVerdi_lte1p2 = 'garasjedorPortEkstraIsolert_UVerdi_lte1p2',
  GarasjedorPortIsolert_UVerdi_lte2p5 = 'garasjedorPortIsolert_UVerdi_lte2p5',
  GarasjedorPortUisolert_UVerdi_lte4p0 = 'garasjedorPortUisolert_UVerdi_lte4p0',
  IsolertYtterdorBalkongdor30PctGlass_UVerdi_lte1p7 = 'isolertYtterdorBalkongdor30PctGlass_UVerdi_lte1p7',
  IsolertYtterdorBalkongdor60PctGlass_UVerdi_lte1p9 = 'isolertYtterdorBalkongdor60PctGlass_UVerdi_lte1p9',
  IsolertYtterdorBalkongdor90PctGlass_UVerdi_lte2p3 = 'isolertYtterdorBalkongdor90PctGlass_UVerdi_lte2p3',
  IsolertYtterdorUtenGlass_UVerdi_lte1p5 = 'isolertYtterdorUtenGlass_UVerdi_lte1p5',
  NyereStandardIsolertYtterdorBalkongdorGlassdor_UVerdi_lte1p2 = 'nyereStandardIsolertYtterdorBalkongdorGlassdor_UVerdi_lte1p2',
  SuperisolertYtterdorUtenGlass_UVerdi_lte0p6 = 'superisolertYtterdorUtenGlass_UVerdi_lte0p6'
}

export enum Energienhet {
  favner = 'favner',
  kg = 'kg',
  kWh = 'kWh',
  liter = 'liter',
  standardKubikkmeter = 'standardKubikkmeter'
}

export enum Energikilde {
  Bioenergi = 'bioenergi',
  Elektrisk = 'elektrisk',
  Fjernvarme = 'fjernvarme',
  Gass = 'gass',
  OljeParafin = 'oljeParafin',
  Ved = 'ved'
}

export enum GlassType {
  EttLagGlassMedVarevinduAv1LagGlass = 'ettLagGlassMedVarevinduAv1LagGlass',
  EttLagGlassMedVarevinduAv2LagIsolerruteLuftfylt = 'ettLagGlassMedVarevinduAv2LagIsolerruteLuftfylt',
  EttLagVanligGlass = 'ettLagVanligGlass',
  KobletRammeMed1LagGlassIHverRamme = 'kobletRammeMed1LagGlassIHverRamme',
  ToLagIsolerrute1EnergibeleggGassfylt = 'toLagIsolerrute1EnergibeleggGassfylt',
  ToLagIsolerrute1EnergibeleggGassfyltMedSuperspacer = 'toLagIsolerrute1EnergibeleggGassfyltMedSuperspacer',
  ToLagIsolerrute1EnergibeleggLuftfylt = 'toLagIsolerrute1EnergibeleggLuftfylt',
  ToLagIsolerrute1EnergibeleggSolreflekterendeGassfylt = 'toLagIsolerrute1EnergibeleggSolreflekterendeGassfylt',
  ToLagIsolerrute1EnergibeleggSolreflekterendeGassfyltMedSuperspacer = 'toLagIsolerrute1EnergibeleggSolreflekterendeGassfyltMedSuperspacer',
  ToLagIsolerrute1EnergibeleggSolreflekterendeLuftfylt = 'toLagIsolerrute1EnergibeleggSolreflekterendeLuftfylt',
  ToLagIsolerruteGassfylt = 'toLagIsolerruteGassfylt',
  ToLagIsolerruteLuftfylt = 'toLagIsolerruteLuftfylt',
  ToLagIsolerruteSolreflekterendeLuftfylt = 'toLagIsolerruteSolreflekterendeLuftfylt',
  TreLagIsolerrute1EnergibeleggGassfylt = 'treLagIsolerrute1EnergibeleggGassfylt',
  TreLagIsolerrute1EnergibeleggLuftfylt = 'treLagIsolerrute1EnergibeleggLuftfylt',
  TreLagIsolerrute2EnergibeleggGassfylt = 'treLagIsolerrute2EnergibeleggGassfylt',
  TreLagIsolerrute2EnergibeleggGassfyltMedSuperspacer = 'treLagIsolerrute2EnergibeleggGassfyltMedSuperspacer',
  TreLagIsolerruteGassfylt = 'treLagIsolerruteGassfylt',
  TreLagIsolerruteLuftfylt = 'treLagIsolerruteLuftfylt'
}

export enum Grunnforhold {
  Fjell = 'fjell',
  FuktigJord = 'fuktigJord',
  LeireOgSilt = 'leireOgSilt',
  SandOgGrus = 'sandOgGrus',
  Ukjent = 'ukjent'
}

export enum Himmelretning {
  Nord = 'nord',
  Ost = 'ost',
  Sor = 'sor',
  Vest = 'vest'
}

export enum JaNeiVetIkke {
  Ja = 'ja',
  Nei = 'nei',
  VetIkke = 'vetIkke'
}

export enum KarmType {
  IsolertTrekarm = 'isolertTrekarm',
  MetallkarmMedKuldebrobryter = 'metallkarmMedKuldebrobryter',
  MetallkarmUtenKuldebrobryter = 'metallkarmUtenKuldebrobryter',
  Plastkarm = 'plastkarm',
  SuperisolertMetallkarmMedKuldebrobryter = 'superisolertMetallkarmMedKuldebrobryter',
  Trekarm = 'trekarm'
}

export enum Konstruksjonsmateriale {
  Betong = 'betong',
  MurTeglstein = 'murTeglstein',
  Stal = 'stal',
  Tre = 'tre'
}

export enum OppvarmingBruk {
  TappevannOgOppvarming = 'tappevannOgOppvarming',
  Tappevann = 'tappevann',
  Oppvarming = 'oppvarming',
  Ukjent = 'ukjent'
}

export enum PlasseringAvEnhetIByggHorisontalt {
  Endeleilighet = 'endeleilighet',
  Enderekkehus = 'enderekkehus',
  FrittstaendeLeilighet = 'frittstaendeLeilighet',
  Hjorneleilighet = 'hjorneleilighet',
  Midtleilighet = 'midtleilighet',
  Midtrekkehus = 'midtrekkehus',
  Ukjent = 'ukjent'
}

export enum PlasseringAvEnhetIByggVertikalt {
  MellomliggendeEtasje = 'mellomliggendeEtasje',
  NedersteEtasje = 'nedersteEtasje',
  OversteEtasje = 'oversteEtasje'
}

export enum Skjerming {
  Default = 'default',
  IngenSkjerming = 'ingenSkjerming',
  ModeratSkjerming = 'moderatSkjerming',
  HoySkjerming = 'hoySkjerming'
}

export enum TakKonstruksjonsType {
  SkratakMedOppvarmetLoft = 'skratakMedOppvarmetLoft',
  SkratakMedUoppvarmetLoft = 'skratakMedUoppvarmetLoft',
  SkratakUtenLoft = 'skratakUtenLoft',
  FlattTak = 'flattTak'
}

export enum Termostatstyring {
  TermostatstyrtUtenTidsstyring = 'termostatstyrtUtenTidsstyring',
  TermostatstyrtMedTidsstyring = 'termostatstyrtMedTidsstyring',
  UtenTermostat = 'utenTermostat',
  Ukjent = 'ukjent'
}

export enum TermostatstyringGulv {
  Trinnbryter = 'trinnbryter',
  TrinnlosRegulering = 'trinnlosRegulering',
  TermostatstyrtUtenTidsstyring = 'termostatstyrtUtenTidsstyring',
  TermostatstyrtMedTidsstyring = 'termostatstyrtMedTidsstyring',
  Ukjent = 'ukjent'
}

export enum TilliggendeRom {
  MotDetFri = 'motDetFri',
  MotGrunn = 'motGrunn',
  MotGrunnOgDetFri = 'motGrunnOgDetFri',
  OppvarmetRom = 'oppvarmetRom',
  UoppvarmetRom = 'uoppvarmetRom',
  Krypkjeller = 'krypkjeller',
  Ukjent = 'ukjent'
}

export enum Varmesentral {
  AutomatikkMedUtefolerTidsstyrt = 'automatikkMedUtefolerTidsstyrt',
  AutomatikkMedUtefolerIkkeTidsstyrt = 'automatikkMedUtefolerIkkeTidsstyrt',
  UtenAutomatikk = 'utenAutomatikk',
  Ukjent = 'ukjent'
}

export enum VeggId {
  A = 'a',
  B = 'b',
  C = 'c',
  D = 'd',
  E = 'e',
  F = 'f',
  G = 'g',
  H = 'h',
  I = 'i',
  J = 'j',
  K = 'k',
  L = 'l'
}

export enum VeggType {
  BetongMed100mmKontinuerligUtvendigIsolasjonOgInnvendigIsolertPaforing = 'betongMed100mmKontinuerligUtvendigIsolasjonOgInnvendigIsolertPaforing',
  BetongMed150mmKontinuerligUtvendigIsolasjonOgInnvendigIsolertPaforing = 'betongMed150mmKontinuerligUtvendigIsolasjonOgInnvendigIsolertPaforing',
  BetongMed50mmKontinuerligUtvendigIsolasjonOgInnvendigIsolertPaforing = 'betongMed50mmKontinuerligUtvendigIsolasjonOgInnvendigIsolertPaforing',
  BetongMedIsolertPaforing = 'betongMedIsolertPaforing',
  BindingsKonstruksjonMedGjennomGaendeStendere = 'bindingsKonstruksjonMedGjennomGaendeStendere',
  BindingsverkMed50mmIsolasjonOgKontinuerligUtvendigIsolasjon = 'bindingsverkMed50mmIsolasjonOgKontinuerligUtvendigIsolasjon',
  BindingsverkMed75mmIsolasjonOgKontinuerligUtvendigIsolasjon = 'bindingsverkMed75mmIsolasjonOgKontinuerligUtvendigIsolasjon',
  BindingsverkMed100mmIsolasjonOgKontinuerligUtvendigIsolasjon = 'bindingsverkMed100mmIsolasjonOgKontinuerligUtvendigIsolasjon',
  BindingsverkMed150mmIsolasjonOgKontinuerligUtvendigIsolasjon = 'bindingsverkMed150mmIsolasjonOgKontinuerligUtvendigIsolasjon',
  BindingsverkMed200mmIsolasjonOgKontinuerligUtvendigIsolasjon = 'bindingsverkMed200mmIsolasjonOgKontinuerligUtvendigIsolasjon',
  BindingsverkMed250mmIsolasjonOgKontinuerligUtvendigIsolasjon = 'bindingsverkMed250mmIsolasjonOgKontinuerligUtvendigIsolasjon',
  BindingsverkMed300mmIsolasjonOgKontinuerligUtvendigIsolasjon = 'bindingsverkMed300mmIsolasjonOgKontinuerligUtvendigIsolasjon',
  BindingsverkMed50mmMurisolasjonOgTeglforblending = 'bindingsverkMed50mmMurisolasjonOgTeglforblending',
  BindingsverkMedDobbeltveggkonstruksjon = 'bindingsverkMedDobbeltveggkonstruksjon',
  LaftetYttervegg = 'laftetYttervegg',
  LecaIsoblokk250mmMedIsolertPaforing = 'lecaIsoblokk250mmMedIsolertPaforing',
  LecaIsoblokk300mmMedIsolertPaforing = 'lecaIsoblokk300mmMedIsolertPaforing',
  LecaIsoblokk350mmMedIsolertPaforing = 'lecaIsoblokk350mmMedIsolertPaforing',
  Lettklinkerbetong150mmMedIsolertPaforing = 'lettklinkerbetong150mmMedIsolertPaforing',
  Lettklinkerbetong250mmMedIsolertPaforing = 'lettklinkerbetong250mmMedIsolertPaforing',
  Porebetong150mmMedIsolertPaforing = 'porebetong150mmMedIsolertPaforing',
  Porebetong240mmMedIsolertPaforing = 'porebetong240mmMedIsolertPaforing',
  SkallmurAvTegl = 'skallmurAvTegl',
  Veggelementer = 'veggelementer'
}

export enum VentilasjonVarmegjenvinningsType {
  VentilasjonMedBatterigjenvinner = 'ventilasjonMedBatterigjenvinner',
  VentilasjonMedPlategjenvinner = 'ventilasjonMedPlategjenvinner',
  VentilasjonMedRoterendeGjenvinner = 'ventilasjonMedRoterendeGjenvinner',
  VentilasjonMedKammergjenvinner = 'ventilasjonMedKammergjenvinner',
  Ukjent = 'ukjent'
}

export enum VentilasjonsType {
  Naturlig = 'naturlig',
  PeriodiskAvtrekk = 'periodiskAvtrekk',
  MekaniskAvtrekk = 'mekaniskAvtrekk',
  Balansert = 'balansert',
  Behovsstyring = 'behovsstyring',
  Ukjent = 'ukjent'
}

export enum Varmekilde {
  LuftFraDetFri = 'luftFraDetFri',
  LuftFraVentilasjonsanlegg = 'luftFraVentilasjonsanlegg',
  Vann = 'vann',
  JordGrunnFjell = 'jordGrunnFjell',
  Ukjent = 'ukjent'
}

export enum DetailingStep {
  Bygningsform = 'bygningsform',
  Vegger = 'vegger',
  Vinduer = 'vinduer',
  Takkonstruksjon = 'takkonstruksjon',
  Gulv = 'gulv',
  Ytterdorer = 'ytterdorer',
  Energibruk = 'energibruk',
  Lekkasjetall = 'lekkasjetall'
}

export enum Malform {
  Bokmal = 'bokmal',
  Nynorsk = 'nynorsk'
}

export enum SoknadSteg {
  Steg1 = 'steg1',
  Steg2 = 'steg2',
  Steg3 = 'steg3'
}
