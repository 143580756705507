import { FC } from 'react';
import { FullScreenLoader, Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { Form } from './form';
import { SearchResults } from './searchResults';
import { AdvancedSearchFormData } from './types';
import { useAdvancedSearchDefaultValues } from './form/useAdvancedSearchDefaultValues';
import { useSearchBuilding } from './useSearchBuilding';

const AdvancedSearchView: FC = () => {
  const { t } = useTranslation();
  const { defaultValues } = useAdvancedSearchDefaultValues();

  // Require kommunenummer, gårdsnummer and bruksnummer to search
  const isSearchEnabled =
    !!defaultValues.kommunenummer &&
    (!!defaultValues.bygningsnummer ||
      (!!defaultValues.gardsnummer && !!defaultValues.bruksnummer));

  const methods = useForm<AdvancedSearchFormData>({
    defaultValues
  });

  const { error, searchResults, isLoading, refetch } = useSearchBuilding(
    defaultValues,
    isSearchEnabled
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mb={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3" component="span">
              {t('search.title')}
            </Typography>
          </Grid>

          <Grid item>
            <FormProvider {...methods}>
              <Form />
            </FormProvider>
          </Grid>
        </Grid>
      </Grid>

      <SearchResults
        error={error}
        searchResults={searchResults}
        refetch={refetch}
      />
      <FullScreenLoader open={isLoading} />
    </Grid>
  );
};

export default AdvancedSearchView;
