import { Button, Typography } from 'enova-frontend-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { readFromLocalStorage } from '../../services/tracelog';

export const TracelogTableHeader: FC = () => {
  const { t } = useTranslation();
  return (
    <thead>
      <tr>
        <td>
          <Typography bold>{t('traceLog.header.dateTime')}</Typography>
        </td>

        <td>
          <Typography bold>{t('traceLog.header.traceId')}</Typography>
        </td>

        <td className="actions">
        </td>
      </tr>
    </thead>
  );
};
