import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { QueryClient, useMutation, useQueryClient } from 'react-query';

import { useUser } from '../../../../../../hooks/useUser';
import { deleteBuildingFromUserSelection } from '../../../../../../services/api';
import { BuildingDetails } from '../../../../../../types/building';
import { queryKeys } from '../../../../../../utils/react-query';
import { createErrorMessage } from '../../../../../../utils/utils';

const filterAndRefetchBuildings = (
  energiObjektId: string,
  queryClient: QueryClient
) => {
  // Cancel any pending queries from 'bruker/valgtebygninger' so we dont return
  // stale server state to the user
  queryClient.cancelQueries([queryKeys.userSelectedBuildingList]);

  // Remove the building from the the query cache so the user dont see the deleted
  // building in 'Mine bygninger' tab if the request to fetch updated list is slow
  queryClient.setQueryData<BuildingDetails[]>(
    [queryKeys.userSelectedBuildingList],
    (buildings) => {
      if (!buildings) {
        return [];
      }

      const filteredBuildings = buildings.filter(
        (building) => building.energiObjektId !== energiObjektId
      );

      return filteredBuildings;
    }
  );

  // Refetch the building list in the background so we get updated server data
  queryClient.invalidateQueries([queryKeys.userSelectedBuildingList]);
};

export const useDeleteBuilding = (energiObjektId: string) => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();
  const { user } = useUser();

  const queryClient = useQueryClient();

  const mutation = useMutation(
    () => deleteBuildingFromUserSelection(energiObjektId),
    {
      onSuccess() {
        // "Andres bygg" tab dont have a pending indicator for when the delete-function
        // is complete so we get a snack that says the building is deleted, but its still visible in the UI because
        // the refetch from the API is still in progress. To avoid implementing a pending UI thingy we just
        // update the cache ourselves so the user get an immediate respons and then refetch the buildings from
        // the server.
        filterAndRefetchBuildings(energiObjektId, queryClient);
        addSnack(
          t('mainScreen.removeBuildingSuccess', { context: user?.type }),
          {
            variant: 'success'
          }
        );
      },
      onError() {
        addSnack(
          createErrorMessage(
            t('mainScreen.removeBuildingError', {
              context: user?.type
            })
          ),
          { variant: 'error' }
        );
      }
    }
  );

  return { deleteBuildingFromUserSelection: mutation.mutate };
};
