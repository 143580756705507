import { useMutation, useQueryClient } from 'react-query';

import { deleteTiltakEnergiplan } from '../../../services/api';
import { queryKeys } from '../../../utils/react-query';
import { Tiltak } from '../../../types/registration/tiltak';

export const useDeleteGrupperegistreringTiltak = (
  grupperegistreringId: string | undefined
) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    ({ tiltakId }: Tiltak) => deleteTiltakEnergiplan(tiltakId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          queryKeys.grupperegistreringMeasures,
          grupperegistreringId
        ])
    }
  );

  return { mutate, isLoading };
};
