import { useTranslation } from 'react-i18next';
import { NumberField } from 'enova-frontend-components';
import { Controller, useWatch } from 'react-hook-form';
import { useEffect } from 'react';

import { useAdvancedSearchFormContext } from '../../types';

export const PostnummerField = () => {
  const { t } = useTranslation();
  const label = t('search.advanced.field_postnummer');
  const {
    control,
    setValue,
    getFieldState,
    trigger,
    formState: { isSubmitted }
  } = useAdvancedSearchFormContext();

  const kommunenummer = useWatch({ control, name: 'kommunenummer' });

  useEffect(() => {
    if (isSubmitted) {
      trigger('kommunenummer');
    }
  }, [kommunenummer]);

  return (
    <Controller
      control={control}
      name="postnummer"
      render={({ field: { value, onChange, ...rest } }) => {
        return (
          <NumberField
            fullWidth
            label={label}
            inputProps={{
              'aria-label': label
            }}
            autoComplete
            error={!!getFieldState('kommunenummer').error}
            useThousandSeparator={false}
            allowLeadingZeros
            title={label}
            value={value ?? null}
            onValueChange={(values, e) => {
              onChange(e.event);
              // Reset poststed and kommunenummer if postnummer length is not 4
              if (values.value.length != 4) {
                setValue('poststed', '');
                setValue('kommunenummer', '');
              }
            }}
            {...rest}
          />
        );
      }}
    />
  );
};
