import React, { FC, ReactNode } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  useSnack
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction } from 'react-query';

import { useXmlStepNavigation } from '../../hooks/useXmlStepNavigation';
import { createErrorMessage } from '../../../../../../../utils/utils';
import { RegistrationTypeProps } from '../../../types';
import { NavigationWrapper } from '../../components/styledComponents';

interface Props {
  onPublishRegistration: UseMutateFunction;
  summaryTabs: ReactNode;
}

const Summary: FC<Props & RegistrationTypeProps> = ({
  registrationType,
  onPublishRegistration,
  summaryTabs
}) => {
  const { t } = useTranslation();
  const { navigateToStep } = useXmlStepNavigation();
  const { addSnack } = useSnack();

  const onConfirm = () => {
    onPublishRegistration(undefined, {
      onSuccess: () => navigateToStep('kvittering', registrationType),
      onError: () => {
        addSnack(createErrorMessage(), { variant: 'error' });
      }
    });
  };

  return (
    <Box>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          {t('expertToolsXML.step.summary.heading')}
        </Typography>

        <Typography paragraph>
          {t('expertToolsXML.step.summary.description1')}
        </Typography>

        <Typography paragraph>
          {t('expertToolsXML.step.summary.description2')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {summaryTabs}
      </Grid>
      <NavigationWrapper>
        <Button
          variant="secondary"
          onClick={() => navigateToStep('tiltak', registrationType)}
        >
          {t('expertToolsXML.actions.previous')}
        </Button>
        <Button onClick={onConfirm}>
          {t('expertToolsXML.actions.next_bekreftelse')}
        </Button>
      </NavigationWrapper>
    </Box>
  );
};

export default Summary;
