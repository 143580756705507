import React, { FC } from 'react';
import { Box, Typography, TypographyProps } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import useBuildingIdentifier from '../hooks/useBuildingIdentifier';
import { useBuildingData } from '../hooks/useBuildingDetails/buildingDetailsHooks';
import { useGetBuildingAddress } from '../hooks/useGetBuildingAddress';

import { LoadingIndicator } from './loadingIndicator';

type BuildingHeaderInfoProps = {
  energiobjektId: string;
  AddressProps?: TypographyProps;
  BruksEnhetsNummerProps?: TypographyProps;
  KnrGnrBnrProps?: TypographyProps;
  BygningsNummerProps?: TypographyProps;
};

const BuildingHeaderInfo: FC<BuildingHeaderInfoProps> = ({
  energiobjektId,
  AddressProps,
  BruksEnhetsNummerProps,
  BygningsNummerProps,
  KnrGnrBnrProps
}) => {
  const { t } = useTranslation();
  const { building, buildingLoading } = useBuildingData(energiobjektId);
  const { getBuildingAddress } = useGetBuildingAddress();
  const getBuildingIdentifier = useBuildingIdentifier();

  if (buildingLoading) {
    return <LoadingIndicator text={t('loadingContent')} />;
  }

  if (!building) {
    return null;
  }

  return (
    <Box>
      <Typography variant={AddressProps?.variant || 'h1'} {...AddressProps}>
        {getBuildingAddress(building.adresse)}
      </Typography>

      {building.bruksEnhetsNummer && (
        <Typography
          component={BruksEnhetsNummerProps?.component || 'p'}
          variant={BruksEnhetsNummerProps?.variant || 'h3'}
          {...BruksEnhetsNummerProps}
        >
          {building.bruksEnhetsNummer}
        </Typography>
      )}

      <Typography {...KnrGnrBnrProps}>
        {getBuildingIdentifier({
          bnr: building.bruksNummer,
          fnr: building.festeNummer,
          gnr: building.gardsNummer,
          knr: building.adresse?.kommuneNummer,
          snr: building.seksjonsNummer
        })}
      </Typography>

      <Typography
        variant={BygningsNummerProps?.variant || 'body2'}
        {...BygningsNummerProps}
      >
        {t('building.identifier', {
          context: 'byggnr',
          value: building.bygningsNummer
        })}
      </Typography>
    </Box>
  );
};

export default BuildingHeaderInfo;
