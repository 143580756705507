import React, { FC, Fragment } from 'react';
import { Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import {
  camelCasify,
  getExpertToolsXMLPortfolioBasePath
} from '../../../../utils/navigation';
import useExpertToolsXMLPortfolio from '../useExpertToolsXMLPortfolio';
import RouterLink from '../../../../components/routerLink';

const Confirmation: FC = () => {
  const { t } = useTranslation();
  const { step } = useExpertToolsXMLPortfolio();
  const camelCasifiedStep = camelCasify(step);

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          {t(
            `expertToolsXMLPortfolio.worklist.step.${camelCasifiedStep}.heading`
          )}
        </Typography>

        <Typography paragraph>
          {t(
            `expertToolsXMLPortfolio.worklist.step.${camelCasifiedStep}.description`
          )}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <RouterLink to={getExpertToolsXMLPortfolioBasePath()}>
          {t(
            `expertToolsXMLPortfolio.worklist.step.${camelCasifiedStep}.goBack`
          )}
        </RouterLink>
      </Grid>
    </Fragment>
  );
};

export default Confirmation;
