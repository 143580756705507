import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from 'enova-frontend-components';

import { useBuildingSearchFormContext } from '../../types';

import { BuildingSearchFieldProps } from './searchFormNumberField';

export const SearchFormTextField: FC<BuildingSearchFieldProps> = ({
  field,
  fieldDependencies
}) => {
  const { t } = useTranslation();
  const { register, formState } = useBuildingSearchFormContext();

  const label = t(`search.advanced.field_${field}`);

  return (
    <TextField
      fullWidth
      inputProps={{ 'aria-label': label }}
      label={label}
      title={label}
      {...register(field, { deps: fieldDependencies })}
      error={!!formState?.errors[field]}
      helperText={formState.errors[field]?.message as string}
    />
  );
};
