import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { getBuildingScreenPath } from '../../utils/navigation';
import { getBygningstypeFromStringValue } from '../../utils/matrikkelBygningsTyper';

import { CreateBuildingData, CreateBuildingUrlParameters } from './types';

export const useCreateBuildingFormContext = () => {
  return useFormContext<CreateBuildingData>();
};

export const useCreateBuildingDefaultValues = () => {
  const location = useLocation();

  const search = new URLSearchParams(location.search);
  const gateBokstav = search.get('bokstav');
  const andelsnummer = search.get('andelsnummer');
  const bruksnummer = search.get('bruksNummer');
  const bygningsnummer = search.get('bygningsnummer');
  const festenummer = search.get('festenummer');
  const gardsnummer = search.get('gardsNummer');
  const gatenavn = search.get('gatenavn');
  const gatenummer = search.get('nummer');
  const kommunenummer = search.get('kommuneNummer');
  const postnummer = search.get('postNummer');
  const poststed = search.get('poststed');
  const bygningstype = search.get('bygningstype');
  const seksjonsnummer = search.get('seksjonsnummer');
  const organisasjonsnummer = {
    value: search.get('organisasjonsnummer') ?? undefined,
    label: search.get('firma') ?? undefined
  };

  return {
    gateBokstav: gateBokstav ?? '',
    gatenummer: gatenummer ?? '',
    bruksnummer: bruksnummer ?? '',
    festenummer: festenummer ?? '',
    gardsnummer: gardsnummer ?? '',
    gatenavn: gatenavn ?? '',
    kommunenummer: kommunenummer ?? '',
    postnummer: postnummer ?? '',
    poststed: poststed ?? '',
    bygningsnummer: bygningsnummer ?? '',
    bygningstype: getBygningstypeFromStringValue(bygningstype),
    organisasjonsnummer,
    seksjonsnummer: seksjonsnummer ?? '',
    andelsnummer: andelsnummer ?? '',
    bruksenhetsnummer: ''
  };
};

export const containsOnlyDigits = (str: string) => {
  return /^\d+$/.test(str);
};

export const validateStringAsNumber = (str: string | undefined) => {
  if (!str) {
    return true;
  }

  return containsOnlyDigits(str);
};

export const getCreateBuildingPath = (data: CreateBuildingUrlParameters) => {
  const urlParams = new URLSearchParams();
  data.gatenavn && urlParams.set('gatenavn', data.gatenavn);
  data.gatenummer && urlParams.set('nummer', data.gatenummer);
  data.bruksnummer && urlParams.set('bruksNummer', data.bruksnummer);
  data.gardsnummer && urlParams.set('gardsNummer', data.gardsnummer);
  data.postnummer && urlParams.set('postNummer', data.postnummer);
  data.bygningsnummer && urlParams.set('bygningsnummer', data.bygningsnummer);
  data.seksjonsnummer && urlParams.set('seksjonsnummer', data.seksjonsnummer);
  data.festenummer && urlParams.set('festenummer', data.festenummer);
  data.bygningstype?.toString() &&
    urlParams.set('bygningstype', data.bygningstype.toString());
  data.organisasjonsnummer?.label &&
    urlParams.set('firma', data.organisasjonsnummer?.label ?? '');
  data.organisasjonsnummer?.value &&
    urlParams.set('organisasjonsnummer', data.organisasjonsnummer?.value ?? '');

  return '/opprett-bygg?' + urlParams.toString();
};

export const getFormSubmitRedirectUrl = (
  data: CreateBuildingData,
  buildingId: string
) => {
  if (data.leggTilNyBygning) {
    return getCreateBuildingPath(data);
  }

  return getBuildingScreenPath({
    buildingId
  });
};
