import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog } from 'enova-frontend-components';

import { Vindu } from '../../../../types/registration/userInput';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import { AddorEditWindow } from '../addOrEditWindow';
import { WindowType } from '../utils';

interface EditWindowDialogProps {
  numberOfEqualWindows?: number;
  onSubmit: (window: Vindu, numberOfWindows: number) => void;
  type?: WindowType;
  window: Vindu;
}

export const EditWindowDialog: FC<EditWindowDialogProps> = ({
  numberOfEqualWindows,
  onSubmit,
  type,
  window
}) => {
  const { t } = useTranslation();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const { xsScreen } = useMediaQuery();

  if (!type) {
    return null;
  }

  const onClose = () => setEditDialogOpen(false);

  const handleAddWindow = (vindu: Vindu, numberOfWindows: number) => {
    onSubmit(vindu, numberOfWindows);
    onClose();
  };

  return (
    <Fragment>
      <Button
        variant="secondary"
        size="medium"
        fullWidth={xsScreen}
        onClick={() => setEditDialogOpen(true)}
      >
        {t('register.window.edit.button')}
      </Button>

      <Dialog open={editDialogOpen} onClose={onClose}>
        <Dialog.Content>
          <Box marginTop={4}>
            <AddorEditWindow
              type={type}
              windowToEdit={window}
              handleCancel={onClose}
              onSubmit={handleAddWindow}
              numberOfEqualWindows={numberOfEqualWindows}
            />
          </Box>
        </Dialog.Content>
      </Dialog>
    </Fragment>
  );
};
