/* eslint-disable camelcase */
import { UserManager, UserManagerSettings } from 'oidc-client-ts';

export const authConfig: UserManagerSettings = {
  authority: window.config.VITE_IDPORTEN_AUTHORITY ?? '',
  client_id: window.config.VITE_IDPORTEN_CLIENTID ?? '',
  response_type: 'code',
  scope: 'openid profile',
  loadUserInfo: false,
  redirect_uri: window.config.VITE_IDPORTEN_REDIRECTURL ?? '',
  post_logout_redirect_uri: window.config.VITE_IDPORTEN_POSTLOGOUTREDIRECTURL
};

export const authManager = new UserManager({ ...authConfig });
