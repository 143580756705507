import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberField } from 'enova-frontend-components';

interface CalculatedValueProps {
  parameterKey: string;
  calculatedValue: number;
  label?: string;
  allowNegative?: boolean;
}

export const CalculatedValue: FC<CalculatedValueProps> = ({
  parameterKey,
  calculatedValue,
  label,
  allowNegative
}) => {
  const { t } = useTranslation();

  const calculatedLabel = `${t(`register.parameters.type.${parameterKey}`)}-${t(
    'register.parameters.calculated'
  )}`;

  return (
    <NumberField
      inputProps={{
        'aria-label': calculatedLabel
      }}
      decimalPrecision={2}
      allowNegative={allowNegative}
      disabled
      fullWidth
      label={label}
      narrow={false}
      value={calculatedValue}
    />
  );
};
