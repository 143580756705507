import React, { FC, Fragment } from 'react';
import { Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { BuildingSearch } from '../../../../../../types/building';
import SearchMatchGroup from '../searchMatchListItems/searchMatchGroup';
import { ErrorBox } from '../../../../../../components/errorBox';
import { MissingUnitBox } from '../searchMatchListItems/missingUnitBox';

import { NoSearchResult } from './noSearchResult';

interface SearchResultsProps {
  results?: BuildingSearch[];
  error: boolean;
  onSearch: () => void;
}

export const SearchResults: FC<SearchResultsProps> = ({
  error,
  results,
  onSearch
}) => {
  const { t } = useTranslation();

  if (error) {
    return (
      <ErrorBox onRetry={onSearch} descriptionKey="search.error.description" />
    );
  }

  if (!results) {
    return null;
  }

  if (results.length === 0) {
    return <NoSearchResult />;
  }

  return (
    <Fragment>
      <Grid item xs={12} display="flex" flexDirection="column">
        <Typography variant="h4" component="h2" gutterBottom>
          {t('search.results')}
        </Typography>
      </Grid>

      {results.map((building) => (
        <Grid xs={12} item display="flex" key={JSON.stringify(building)}>
          <SearchMatchGroup {...building} />
        </Grid>
      ))}

      <MissingUnitBox />
    </Fragment>
  );
};
