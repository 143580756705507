import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'enova-frontend-components';

import { camelCasify } from '../../../../../utils/navigation';
import { WindowSpecType } from '../../../utils/window';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';

export const UnknownWindowType: FC = () => {
  const { xsScreen } = useMediaQuery();
  const { t } = useTranslation();

  return (
    <Radio
      width={xsScreen ? 'fullWidth' : 'auto'}
      label={t(`register.window.${camelCasify(WindowSpecType.UNKNOWN)}`)}
      value={WindowSpecType.UNKNOWN}
      helperText={t('register.windows.unknown.helperText')}
      className="mb-2"
    />
  );
};
