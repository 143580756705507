import { Box, styled, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { ErrorBox } from '../../components/errorBox';
import { UserRole } from '../../types/user';

import { useUpdateUser } from './useUpdateUser';
import { useProfileFormContext } from './types';
import { Fagomrader, FormButtons, UserRoleRadioGroup } from './components';
import { fagomradeIsNotSelected } from './utils';
import { UserIdButton } from './components/userIdButton';

const FormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  align-self: center;
`;

export const ProfileForm = () => {
  const { updateUser, isLoading } = useUpdateUser();
  const { t } = useTranslation();
  const { handleSubmit, reset, formState, getValues } = useProfileFormContext();
  const onFormSubmit = handleSubmit((values) => {
    updateUser(values);
  });

  return (
    <FormWrapper mx={3}>
      <Typography my={5} variant="h1">
        {t('profileScreen.pageTitle')}
      </Typography>

      <form onSubmit={onFormSubmit} noValidate>
        <Typography id="professional-label" variant="h3">
          {t('profileScreen.yourCompetency.label')}
        </Typography>

        <Typography id="professional-desc" className="text-preline">
          {t('profileScreen.yourCompetency.description')}
        </Typography>

        <UserRoleRadioGroup loading={isLoading} />

        <Fagomrader loading={isLoading} />

        {UserRole.PROFESJONELL === getValues('rolle') && (
          <>
            <Typography variant="h4">
              {t('profileScreen.professional.apiaccess.title')}
            </Typography>
            <Typography variant="p">
              {t('profileScreen.professional.apiaccess.description')}
            </Typography>
            <Box pt={5}>
              <UserIdButton />
            </Box>
          </>
        )}

        {fagomradeIsNotSelected(formState.errors) && (
          <ErrorBox
            titleKey="profile.errorMessage.title"
            descriptionKey="profile.errorMessage.description"
          />
        )}

        <FormButtons onReset={reset} loading={isLoading} />
      </form>
    </FormWrapper>
  );
};
