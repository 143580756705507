import { useParams } from 'react-router-dom';
import { FC } from 'react';
import { Box } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import Summary from '../../../common/steps/summary';
import { FlerboligRegistrationXMLParams } from '../../types';
import { usePublishFlerboligRegistration } from '../../hooks/usePublishFlerboligRegistration';
import { SimpleEnergyAnalysis } from '../../../../../../../types/building';
import { LoadingIndicator } from '../../../../../../../components/loadingIndicator';

import { FlerboligSummaryTabs } from './flerboligSummaryTabs';

interface Props {
  energianalyser?: SimpleEnergyAnalysis[];
}

export const FlerboligSummary: FC<Props> = ({ energianalyser }) => {
  const { flerboligId } = useParams<FlerboligRegistrationXMLParams>();
  const { t } = useTranslation();

  const { publishRegistration, isLoading: publishIsLoading } =
    usePublishFlerboligRegistration(flerboligId);

  if (publishIsLoading) {
    return (
      <Box minWidth={400} minHeight={400}>
        <LoadingIndicator text={t('expertToolsXML.publish.loading')} />
      </Box>
    );
  }

  if (!energianalyser || energianalyser.length === 0) {
    return null;
  }

  return (
    <Summary
      registrationType="flerbolig"
      onPublishRegistration={publishRegistration}
      summaryTabs={
        <FlerboligSummaryTabs
          energyAnalysisList={energianalyser}
          flerboligId={flerboligId}
        />
      }
    />
  );
};
