import React, { Dispatch, FC, SetStateAction } from 'react';
import {
  Checkbox,
  CheckButton,
  CheckGroup,
  css,
  DatePicker,
  DatePickerProps,
  Grid,
  NumberField,
  Radio,
  RadioButton,
  RadioGroup,
  Select,
  styled,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'rooks';
import _ from 'underscore';

import { useMediaQuery } from '../../hooks/useMediaQuery';
import { kebabCasify } from '../../utils/navigation';
import useSelector from '../../hooks/useSelector';
import { updateRegistrationField } from '../../store/registration/actions';
import useRegistrationForm from '../../screens/registration/useRegistrationForm';
import { NullishValues } from '../../utils/register';
import { RegistrationTypeAlias } from '../../types/registration/userInput';
import {
  CheckboxType,
  CheckButtonType,
  CheckFieldTypes,
  Field,
  FieldType,
  InputFieldType,
  NumberFieldTypes,
  NumberType,
  RadioButtonType,
  RadioFieldTypes,
  RadioType,
  SelectType,
  TypographyType
} from '../../types/registration/fieldTypes';

import CollapsibleRegField, {
  CollapsibleRegFieldProps
} from './collapsibleRegField/collapsibleRegField';
import { FieldError } from './utils/validation';

export type RegistrationChangeHandler = Dispatch<
  SetStateAction<RegistrationTypeAlias>
>;

type GenericDynamicFieldProps<T> = Omit<T, 'onChange'> &
  Pick<CollapsibleRegFieldProps, 'start' | 'end'> & {
    innerField?: boolean;
    disabled?: boolean;
  };

type DynamicFieldProps = GenericDynamicFieldProps<FieldType>;

const NarrowFieldCSS = (width: number) => css`
  width: auto !important;

  label {
    white-space: normal;
  }

  & > div {
    width: ${width}px !important;
  }
`;

const NarrowDatePicker = styled(DatePicker)`
  ${NarrowFieldCSS(180)}
`;

const StyledCheckGroup = styled(CheckGroup)`
  & > div:last-child {
    gap: ${({ theme }) => theme.spacing(2)};
    display: flex;
  }
`;

const DynamicField: FC<DynamicFieldProps> = ({
  attrKey,
  condition,
  end,
  innerField,
  path,
  selector,
  start,
  tDescriptionKey,
  tErrorKey,
  tHelperTextKey,
  tInformationKey,
  tLabelKey,
  type,
  validator,
  ...props
}) => {
  const { t } = useTranslation();

  if (type === Field.TYPOGRAPHY) {
    const { tContentKey, ...typographyProps } = props as TypographyType;
    return <Typography {...typographyProps}>{t(tContentKey)}</Typography>;
  }

  const { required } = props as GenericDynamicFieldProps<InputFieldType>;
  const { xsScreen } = useMediaQuery();
  const dispatch = useDispatch();
  const { displayErrors } = useRegistrationForm();

  const selectorValue = useSelector(
    (state) => (selector ? selector(state) : undefined),
    _.isEqual
  );

  const fieldError = useSelector((state) => {
    if (displayErrors) {
      if (required && (NullishValues as unknown[]).includes(selectorValue))
        return FieldError.Missing;

      if (validator && selector) {
        const { error } = validator(state, selector);
        if (error !== FieldError.None) return error;
      }

      return FieldError.None;
    }
    return FieldError.None;
  });

  const handleChange = (value: unknown, inputPath?: string[]) =>
    dispatch(
      updateRegistrationField({
        objectPath: inputPath ?? [...(path || [])],
        value
      })
    );

  const handleDebouncedChange = useDebounce<typeof handleChange>(
    handleChange,
    500,
    { leading: true, trailing: true }
  );

  const displayField = useSelector((state) =>
    condition ? condition(state) : true
  );

  if (!displayField) return null;

  const rest = {
    ...props,
    ...(tLabelKey ? { label: t(tLabelKey) } : {}),
    ...(tHelperTextKey ? { helperText: t(tHelperTextKey) } : {}),
    ...(tInformationKey
      ? {
          HelperBoxProps: {
            'aria-label': t('readFieldInformation'),
            children: t(tInformationKey)
          }
        }
      : {}),
    ...(tDescriptionKey ? { description: t(tDescriptionKey) } : {}),
    ...(fieldError !== FieldError.None ? { error: true } : {}),
    ...(fieldError !== FieldError.None && tErrorKey
      ? { helperText: t(tErrorKey, { context: fieldError || undefined }) }
      : {}),
    required
  };

  if (NumberFieldTypes.includes(type)) {
    const { tSuffixKey, ...numberFieldProps } = rest as NumberType;

    return (
      <NumberField
        {...numberFieldProps}
        autoComplete
        format={type === Field.YEAR ? '####' : numberFieldProps.format}
        integer={type === Field.INTEGER}
        name={kebabCasify(attrKey!)}
        onValueChange={({ floatValue }) => handleDebouncedChange(floatValue)}
        suffix={tSuffixKey ? t(tSuffixKey) : undefined}
        title={selectorValue != null ? String(selectorValue) : undefined}
        value={selectorValue as number}
      />
    );
  }

  if (type === Field.DATE) {
    const dateValue =
      selectorValue && selectorValue instanceof Date
        ? selectorValue
        : selectorValue && typeof selectorValue === 'string'
        ? new Date(selectorValue)
        : '';

    return (
      <NarrowDatePicker
        {...(rest as DatePickerProps)}
        value={dateValue}
        onChange={(newValue) =>
          handleChange(newValue instanceof Date ? newValue : undefined)
        }
        fullWidth
      />
    );
  }

  if (type === Field.SELECT) {
    const { options, ...selectProps } = rest as SelectType;

    const selectOptions = options.map((option) => ({
      ...option,
      label: t(option.label as string) as string
    }));

    return (
      <Grid container>
        <Grid item xs sm="auto">
          <Select
            {...selectProps}
            fullWidth
            onChange={({ currentTarget: { value: val } }) => handleChange(val)}
            options={selectOptions}
            value={
              selectOptions.some((op) => op.value === selectorValue)
                ? selectorValue
                : -1
            }
          />
        </Grid>
      </Grid>
    );
  }

  const CollapsibleComponent = innerField
    ? CollapsibleRegField.InnerCollapsible
    : CollapsibleRegField;

  if (RadioFieldTypes.includes(type)) {
    const {
      commonFields,
      options,
      valueType = 'string',
      ...radioGroupProps
    } = rest as RadioButtonType | RadioType;

    const content = (
      <RadioGroup
        {...radioGroupProps}
        marginBottom={
          radioGroupProps.marginBottom ||
          commonFields?.hasOwnProperty(String(selectorValue))
        }
        value={selectorValue ?? -1}
        onChange={(e, val) => {
          if (valueType === 'object')
            handleChange(val === 'true' ? {} : undefined);
          else {
            const castedValue = valueType === 'boolean' ? val === 'true' : val;
            handleChange(castedValue);
          }
        }}
      >
        {type === Field.RADIOBUTTON ? (
          <Grid container spacing={2}>
            {options.map(
              (
                {
                  icon: Icon,
                  invertedIcon: InvertedIcon,
                  tLabelKey: optionTLabelKey,
                  tHelperTextKey: optionTHelperTextKey,
                  fields,
                  ...optionProps
                },
                optionIndex
              ) => {
                const radio = (
                  <RadioButton
                    {...optionProps}
                    label={t(optionTLabelKey)}
                    helperText={
                      optionTHelperTextKey ? t(optionTHelperTextKey) : undefined
                    }
                    width="fullWidth"
                    icon={Icon ? <Icon /> : undefined}
                    invertedIcon={InvertedIcon ? <InvertedIcon /> : undefined}
                  />
                );

                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={String(optionProps.value)}
                  >
                    {fields ? (
                      <CollapsibleComponent
                        {...(innerField
                          ? {}
                          : {
                              start,
                              end: end ?? optionIndex === options.length - 1
                            })}
                        open={selectorValue === optionProps.value}
                      >
                        {radio}

                        <CollapsibleComponent.Collapse>
                          {fields?.map((field, fieldIndex) => (
                            <DynamicField
                              {...field}
                              innerField
                              key={field.key ?? field.attrKey}
                              marginBottom={fieldIndex !== fields.length - 1}
                            />
                          ))}
                        </CollapsibleComponent.Collapse>
                      </CollapsibleComponent>
                    ) : (
                      radio
                    )}
                  </Grid>
                );
              }
            )}
          </Grid>
        ) : (
          options.map(
            (
              {
                tLabelKey: optionTLabelKey,
                tHelperTextKey: optionTHelperTextKey,
                fields,
                ...optionProps
              },
              optionIndex
            ) => {
              const radio = (
                <Radio
                  {...optionProps}
                  label={t(optionTLabelKey)}
                  helperText={
                    optionTHelperTextKey ? t(optionTHelperTextKey) : undefined
                  }
                  key={String(optionProps.value)}
                  width={
                    optionProps.width ?? (xsScreen ? 'fullWidth' : undefined)
                  }
                />
              );

              return fields ? (
                <CollapsibleComponent
                  {...(innerField
                    ? {}
                    : {
                        start,
                        end: end ?? optionIndex === options.length - 1
                      })}
                  open={selectorValue === optionProps.value}
                  key={String(optionProps.value)}
                >
                  {radio}

                  <CollapsibleComponent.Collapse>
                    {fields?.map((field, fieldIndex) => (
                      <DynamicField
                        {...field}
                        {...(innerField
                          ? {}
                          : {
                              end: fieldIndex === fields.length - 1
                            })}
                        innerField
                        key={field.key ?? field.attrKey}
                        marginBottom={fieldIndex !== fields.length - 1}
                      />
                    ))}
                  </CollapsibleComponent.Collapse>
                </CollapsibleComponent>
              ) : (
                radio
              );
            }
          )
        )}
      </RadioGroup>
    );

    return commonFields ? (
      <CollapsibleComponent
        {...(innerField ? {} : { start, end })}
        open={commonFields.hasOwnProperty(String(selectorValue))}
      >
        {content}

        <CollapsibleComponent.Collapse>
          {commonFields[selectorValue as string]?.map((field, fieldIndex) => (
            <DynamicField
              {...field}
              {...(innerField
                ? {}
                : {
                    end:
                      fieldIndex ===
                      commonFields[selectorValue as string].length - 1
                  })}
              innerField
              key={field.key ?? field.attrKey}
              marginBottom={
                fieldIndex !== commonFields[selectorValue as string].length - 1
              }
            />
          ))}
        </CollapsibleComponent.Collapse>
      </CollapsibleComponent>
    ) : (
      content
    );
  }

  if (CheckFieldTypes.includes(type)) {
    const { options, ...checkGroupProps } = rest as
      | CheckButtonType
      | CheckboxType;

    const CheckComponent = type === Field.CHECKBUTTON ? CheckButton : Checkbox;

    return (
      <StyledCheckGroup {...checkGroupProps}>
        {options.map(
          (
            {
              fields,
              tLabelKey: optionTLabelKey,
              tHelperTextKey: optionTHelperTextKey,
              defaultValueOnCheck,
              ...optionProps
            },
            optionIndex
          ) => {
            const isChecked = !!((selectorValue || []) as string[])?.includes(
              optionProps.value as string
            );

            if (fields)
              return (
                <CollapsibleComponent
                  {...(innerField
                    ? {}
                    : {
                        end: optionIndex === options.length - 1,
                        start
                      })}
                  key={optionProps.value}
                  open={isChecked}
                >
                  <CheckComponent
                    {...optionProps}
                    width={
                      optionProps.width ?? (xsScreen ? 'fullWidth' : undefined)
                    }
                    label={t(optionTLabelKey)}
                    helperText={
                      optionTHelperTextKey ? t(optionTHelperTextKey) : undefined
                    }
                    checked={isChecked}
                    onChange={(e, checked) =>
                      handleChange(
                        checked ? defaultValueOnCheck ?? {} : undefined,
                        [...(path ?? []), optionProps.value as string]
                      )
                    }
                  />

                  <CollapsibleComponent.Collapse>
                    {fields.map((field, fieldIndex) => (
                      <DynamicField
                        {...field}
                        marginBottom={
                          !(
                            optionIndex === options.length - 1 &&
                            fieldIndex === fields.length - 1
                          )
                        }
                        attrKey={field.attrKey}
                        innerField
                        key={field.key ?? field.attrKey}
                      />
                    ))}
                  </CollapsibleComponent.Collapse>
                </CollapsibleComponent>
              );

            return (
              <CheckComponent
                {...optionProps}
                label={t(optionTLabelKey)}
                helperText={
                  optionTHelperTextKey ? t(optionTHelperTextKey) : undefined
                }
                checked={
                  !!(selectorValue as Record<string, unknown>)?.[
                    optionProps.value
                  ]
                }
                key={optionProps.value}
                onChange={(e, checked) =>
                  handleChange(checked ? {} : undefined, [
                    ...(path ?? []),
                    optionProps.value as string
                  ])
                }
              />
            );
          }
        )}
      </StyledCheckGroup>
    );
  }

  return null;
};

export default DynamicField;
