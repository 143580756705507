import { Select, SelectOption } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { WindowDetailingOption } from '../../types';
import { useWindowFormContext, WindowFormFieldProps } from '../utils';

export const WindowDetailingSelect: FC<WindowFormFieldProps> = ({
  control
}) => {
  const { t } = useTranslation();
  const { setValue } = useWindowFormContext();

  const detailingOptions: SelectOption[] = [
    {
      label: t('select'),
      value: -1,
      disabled: true
    },
    {
      label: t('register.window.detailing.windowType'),
      value: 'windowType',
      disabled: false
    },
    {
      label: t('register.window.detailing.yearOfProduction'),
      value: 'yearOfProduction',
      disabled: false
    },
    {
      label: t('register.window.detailing.uValue'),
      value: 'uValue',
      disabled: false
    },
    {
      label: t('register.window.detailing.unknown'),
      value: 'unknown',
      disabled: false
    }
  ];

  const handleValueChange = (detailingOption: WindowDetailingOption) => {
    // Reset window data on change
    setValue('windowData.window', undefined);

    // Set window data programmatically if 'unknown' is selected
    if (detailingOption === 'unknown') {
      setValue('windowData.window.brukStandardVerdier', true);
    }
  };

  return (
    <Controller
      control={control}
      name="detailing"
      rules={{
        validate: (value) => value !== -1 || t('required')
      }}
      render={({ field: { value, onChange, ...rest }, fieldState }) => {
        return (
          <Select
            {...rest}
            error={!!fieldState.error}
            HelperBoxProps={{
              'aria-label': t('readFieldInformation'),
              children: t('register.wallType.information')
            }}
            label={t('register.window.information')}
            aria-label={t('register.window.information')}
            onChange={(e) => {
              onChange(e.currentTarget.value);
              handleValueChange(e.currentTarget.value as WindowDetailingOption);
            }}
            options={detailingOptions}
            value={value ?? -1}
          />
        );
      }}
    />
  );
};
