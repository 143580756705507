import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  CheckGroup,
  Grid,
  Hidden,
  Paper,
  Radio,
  RadioGroup,
  TTableHeadCell,
  Typography,
  useEnovaContext
} from 'enova-frontend-components';
import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import RouterLink from '../../../../components/routerLink';
import { getStatisticsScreenPath } from '../../../../utils/navigation';
import CollapsibleFilterCheckbox from '../../../../components/statistics/collapsibleFilterCheckbox';
import useStatisticsFilter from '../../../../hooks/useStatisticsFilter';
import {
  CategoryOptions,
  FilterCategory,
  SubcategoryOptions
} from '../../../../hooks/useStatisticsFilter/context';
import StaticticsFilterDrawer from '../../../../components/statistics/statisticsFilterDrawer';
import {
  AggregatedStatisticsByMonth,
  AggregatedStatisticsByMonthMap,
  StatistikkRequestType
} from '../../../../types/statistics';
import { StyledStatisticsTableByYear } from '../../utils';
import { loadStatistics } from '../../../../services/api';
import { queryKeys } from '../../../../utils/react-query';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

const chartOptions = {
  plugins: {
    legend: {
      labels: {
        font: {
          size: 16
        }
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  }
};

enum Display {
  DIAGRAM = 'diagram',
  TABLE = 'table'
}

const maxWidthFilters = 300;

const StatisticsOverTimeView: FC = () => {
  const { t } = useTranslation();
  const { theme } = useEnovaContext();
  const [display, setDisplay] = useState(Display.TABLE);
  const [updateCharts, setUpdateCharts] = useState(true);

  const [statisticsBolig, setStatisticsBolig] =
    useState<AggregatedStatisticsByMonthMap>({});

  const [statisticsYrkesBygg, setStatisticsYrkesBygg] =
    useState<AggregatedStatisticsByMonthMap>({});

  const {
    categories,
    filterValues,
    subcategoryFilterValues,
    updateFilterValues,
    body
  } = useStatisticsFilter();

  const updateDisplay = (disp: string) => {
    setUpdateCharts(disp === Display.DIAGRAM);
    setDisplay(disp as Display);
  };

  const { data: statisticsByYear } = useQuery(
    [queryKeys.statistic, StatistikkRequestType.OVERTID, body],
    () => loadStatistics(StatistikkRequestType.OVERTID, body)
  );

  useEffect(() => {
    if (statisticsByYear && Object.keys(statisticsByYear).length === 0) return;

    const resultBolig: AggregatedStatisticsByMonthMap = {};
    const resultYrkesBygg: AggregatedStatisticsByMonthMap = {};

    statisticsByYear &&
      Object.values(statisticsByYear).map((obj) => {
        const month = Number(obj.publisertManed);
        const year = obj.publisertAr.toString();
        const total = obj.total;

        const tempresult: AggregatedStatisticsByMonthMap =
          obj.energiObjektType.toLowerCase() === 'privatbolig'
            ? resultBolig
            : resultYrkesBygg;

        // Aggregate results for tables
        if (year in tempresult) {
          const row = tempresult[year];
          if (month === 1) row.january += total;
          else if (month === 2) row.february += total;
          else if (month === 3) row.march += total;
          else if (month === 4) row.april += total;
          else if (month === 5) row.may += total;
          else if (month === 6) row.june += total;
          else if (month === 7) row.july += total;
          else if (month === 8) row.august += total;
          else if (month === 9) row.september += total;
          else if (month === 10) row.october += total;
          else if (month === 11) row.november += total;
          else if (month === 12) row.december += total;
          row.total += total;
        } else {
          const row = {
            id: year,
            publishedYear: obj.publisertAr,
            january: month === 1 ? total : 0,
            february: month === 2 ? total : 0,
            march: month === 3 ? total : 0,
            april: month === 4 ? total : 0,
            may: month === 5 ? total : 0,
            june: month === 6 ? total : 0,
            july: month === 7 ? total : 0,
            august: month === 8 ? total : 0,
            september: month === 9 ? total : 0,
            october: month === 10 ? total : 0,
            november: month === 11 ? total : 0,
            december: month === 12 ? total : 0,
            total: total,
            accumulated: 0
          };
          tempresult[year] = row;
        }
      });

    // Convert objects to array and sort by date
    const tempresultBolig = Object.values(resultBolig).sort(
      (a, b) => (a.publishedYear > b.publishedYear && 1) || -1
    );

    const tempresultYrkesBygg = Object.values(resultYrkesBygg).sort(
      (a, b) => (a.publishedYear > b.publishedYear && 1) || -1
    );

    // Calculate accumulated results
    let accumulatedBolig = 0;
    for (const bolig of tempresultBolig) {
      accumulatedBolig += bolig.total;
      bolig.accumulated = accumulatedBolig;
    }

    let accumulatedYrkesBygg = 0;
    for (const yrkesBygg of tempresultYrkesBygg) {
      accumulatedYrkesBygg += yrkesBygg.total;
      yrkesBygg.accumulated = accumulatedYrkesBygg;
    }

    // Convert back to objects
    const finalResultBolig: AggregatedStatisticsByMonthMap =
      tempresultBolig.reduce(
        (acc, result) => ({
          ...acc,
          [result.publishedYear.toString()]: result
        }),
        {}
      );

    const finalResultYrkesBygg: AggregatedStatisticsByMonthMap =
      tempresultYrkesBygg.reduce(
        (acc, result) => ({
          ...acc,
          [result.publishedYear.toString()]: result
        }),
        {}
      );

    // Save results
    setStatisticsBolig(finalResultBolig);
    setStatisticsYrkesBygg(finalResultYrkesBygg);
    // Update charts once new data has been calculated
    setUpdateCharts(true);
  }, [statisticsByYear, filterValues, subcategoryFilterValues]);

  // TABLE HEADERS
  const TableHeadCells: readonly TTableHeadCell<AggregatedStatisticsByMonth>[] =
    [
      {
        id: 'publishedYear',
        isMainProperty: true,
        label: t('statistics.year'),
        numeric: false,
        disableSort: true
      },
      {
        id: 'january',
        numeric: true,
        label: t('statistics.january'),
        disableSort: true
      },
      {
        id: 'february',
        numeric: true,
        label: t('statistics.february'),
        disableSort: true
      },
      {
        id: 'march',
        numeric: true,
        label: t('statistics.march'),
        disableSort: true
      },
      {
        id: 'april',
        numeric: true,
        label: t('statistics.april'),
        disableSort: true
      },
      {
        id: 'may',
        numeric: true,
        label: t('statistics.may'),
        disableSort: true
      },
      {
        id: 'june',
        numeric: true,
        label: t('statistics.june'),
        disableSort: true
      },
      {
        id: 'july',
        numeric: true,
        label: t('statistics.july'),
        disableSort: true
      },
      {
        id: 'august',
        numeric: true,
        label: t('statistics.august'),
        disableSort: true
      },
      {
        id: 'september',
        numeric: true,
        label: t('statistics.september'),
        disableSort: true
      },
      {
        id: 'october',
        numeric: true,
        label: t('statistics.october'),
        disableSort: true
      },
      {
        id: 'november',
        numeric: true,
        label: t('statistics.november'),
        disableSort: true
      },
      {
        id: 'december',
        numeric: true,
        label: t('statistics.december'),
        disableSort: true
      },
      {
        id: 'total',
        numeric: true,
        label: t('statistics.total'),
        disableSort: true
      }
    ];

  // CHARTS
  const chartRefBolig = useRef<Chart | null>(null);
  const chartRefYrkesBygg = useRef<Chart | null>(null);

  const canvasCallbackBolig = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    if (!updateCharts) return;
    const ctxBolig = document.getElementById('ChartBolig') as HTMLCanvasElement;
    const bolig = Object.values(statisticsBolig);
    if (ctxBolig) {
      chartRefBolig.current?.destroy();
      chartRefBolig.current = new Chart(ctxBolig, {
        type: 'bar',
        data: {
          labels: bolig.map((item) => item.publishedYear),
          datasets: [
            {
              label: t('statisticsChart.overTime.legend.total'),
              data: bolig.map((item) => item.total),
              backgroundColor: theme.palette.secondary.main
            },
            {
              label: t('statisticsChart.overTime.legend.accumulated'),
              data: bolig.map((item) => item.accumulated - item.total),
              backgroundColor: theme.palette.primary.main
            }
          ]
        },
        options: chartOptions
      });
      setUpdateCharts(false);
    }
  };

  const canvasCallbackYrkesBygg = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    if (!updateCharts) return;
    const ctxYrkesBygg = document.getElementById(
      'ChartYrkesBygg'
    ) as HTMLCanvasElement;
    const yrkesBygg = Object.values(statisticsYrkesBygg);
    if (ctxYrkesBygg) {
      chartRefYrkesBygg.current?.destroy();
      chartRefYrkesBygg.current = new Chart(ctxYrkesBygg, {
        type: 'bar',
        data: {
          labels: yrkesBygg.map((item) => item.publishedYear),
          datasets: [
            {
              label: t('statisticsChart.overTime.legend.total'),
              data: yrkesBygg.map((item) => item.total),
              backgroundColor: theme.palette.secondary.main
            },
            {
              label: t('statisticsChart.overTime.legend.accumulated'),
              data: yrkesBygg.map((item) => item.accumulated - item.total),
              backgroundColor: theme.palette.primary.main
            }
          ]
        },
        options: chartOptions
      });
      setUpdateCharts(false);
    }
  };

  return (
    <Fragment>
      <Box display="flex" flexDirection="column">
        <RouterLink className="mb-3 as-start" to={getStatisticsScreenPath()}>
          {t('navigation.statisticsScreen.back')}
        </RouterLink>

        <Typography variant="h2" component="span" gutterBottom>
          {t('statisticsScreen.overTid.title')}
        </Typography>

        <Typography pb={5}>
          {t('statisticsScreen.overTid.description')}
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Hidden mdDown>
          <Grid item md="auto" minWidth={maxWidthFilters}>
            {categories?.map((category) => (
              <Box key={category} maxWidth={maxWidthFilters}>
                {category === FilterCategory.FYLKER ? (
                  <CheckGroup key={category} label={t(category)} marginBottom>
                    {CategoryOptions[category].map((option) => (
                      <CollapsibleFilterCheckbox
                        key={option}
                        category={category}
                        subcategory={SubcategoryOptions[category]}
                        option={option}
                      />
                    ))}
                  </CheckGroup>
                ) : (
                  <CheckGroup key={category} label={t(category)} marginBottom>
                    {CategoryOptions[category].map((option) => (
                      <Checkbox
                        key={option}
                        label={t(option)}
                        width="fullWidth"
                        checked={filterValues[category].includes(option)}
                        onChange={(_, checked) =>
                          updateFilterValues(
                            category,
                            option,
                            checked,
                            SubcategoryOptions[category]
                          )
                        }
                      />
                    ))}
                  </CheckGroup>
                )}
              </Box>
            ))}
          </Grid>
        </Hidden>

        <Grid
          item
          xs={12}
          maxWidth={{ md: `calc(100% - ${maxWidthFilters}px)` }}
        >
          {categories && (
            <Hidden mdUp>
              <StaticticsFilterDrawer />
            </Hidden>
          )}

          <Box className="w-100" display="flex" pt={6}>
            <RadioGroup
              aria-label={t('display')}
              onChange={(_, val) => updateDisplay(val)}
              row
              value={display}
              marginBottom
              sx={{
                alignItems: 'flex-end'
              }}
            >
              <Radio
                width="auto"
                label={t('showAsChart')}
                value={Display.DIAGRAM}
              />
              <Radio
                width="auto"
                label={t('showInTable')}
                value={Display.TABLE}
              />
            </RadioGroup>
          </Box>

          {display === Display.DIAGRAM && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Paper>
                  <Box p={3}>
                    <Typography variant="h4" component="h2" gutterBottom>
                      {t('statistics.building.private.title')}
                    </Typography>

                    <Box>
                      <canvas
                        id="ChartBolig"
                        height="400"
                        ref={canvasCallbackBolig}
                      />
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper>
                  <Box p={3}>
                    <Typography variant="h4" component="h2" gutterBottom>
                      {t('statistics.building.commercial.title')}
                    </Typography>

                    <Box>
                      <canvas
                        id="ChartYrkesBygg"
                        height="400"
                        ref={canvasCallbackYrkesBygg}
                      />
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          )}

          {display === Display.TABLE && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <StyledStatisticsTableByYear<AggregatedStatisticsByMonth>
                  alternating
                  dense={true}
                  tableTitle={t('statistics.building.private.title')}
                  headCells={TableHeadCells}
                  rows={Object.values(statisticsBolig)}
                  disablePagination={true}
                />
              </Grid>

              <Grid item xs={12}>
                <StyledStatisticsTableByYear<AggregatedStatisticsByMonth>
                  alternating
                  dense={true}
                  tableTitle={t('statistics.building.commercial.title')}
                  headCells={TableHeadCells}
                  rows={Object.values(statisticsYrkesBygg)}
                  disablePagination={true}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default StatisticsOverTimeView;
