export enum ExpertToolsXMLStep {
  UPLOAD = 'last-opp',
  REVIEW = 'oppsummering-av-fil',
  MEASURES = 'tiltak',
  SUMMARY = 'oppsummering',
  CONFIRMATION = 'bekreftelse'
}

export enum ExpertToolsXMLPortfolioStep {
  UPLOAD = 'last-opp',
  MEASURES = 'tiltak',
  CONFIRMATION = 'bekreftelse'
}
