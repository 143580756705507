import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps
} from 'enova-frontend-components';

export type ReadonlyFieldProps = BoxProps & {
  label?: string;
  LabelProps?: Partial<TypographyProps>;
  value?: unknown;
  ValueProps?: Partial<TypographyProps>;
};

const Wrapper = styled((props) => <Box {...props} />)`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

const ReadonlyField: FC<ReadonlyFieldProps> = ({
  label,
  LabelProps,
  value,
  ValueProps,
  ...boxProps
}) => (
  <Wrapper {...boxProps}>
    {label && (
      <Typography variant="h4" component="p" {...LabelProps}>
        {label}
      </Typography>
    )}

    {value != null && value !== '' && (
      <Typography {...ValueProps}>{String(value)}</Typography>
    )}
  </Wrapper>
);

export default ReadonlyField;
