import { TextField } from 'enova-frontend-components';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreateBuildingData } from '../types';
import { validateStringAsNumber } from '../utils';

interface AndelsnummerProps {
  disabled: boolean;
}

export const Andelsnummer: FC<AndelsnummerProps> = ({ disabled }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext<CreateBuildingData>();
  const { t } = useTranslation();

  return (
    <TextField
      HelperBoxProps={{
        'aria-label': t('readFieldInformation'),
        children: t('building.create.andelsnummer.helperText')
      }}
      fullWidth
      label={t('building.create.andelsnummer')}
      disabled={disabled}
      error={!!errors.andelsnummer}
      helperText={errors.andelsnummer?.message}
      {...register('andelsnummer', {
        validate: {
          invalid: (val) =>
            validateStringAsNumber(val) || (t('invalid') as string)
        }
      })}
    />
  );
};
