import React, { FC, useState } from 'react';
import { Box, Menu, styled } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { WindowData } from '../types';
import { Vindu } from '../../../../../types/registration/userInput';
import { useWindowOperations } from '../hooks/useWindowOperations';
import { VeggId } from '../../../utils/registerEnums';
import { CustomMenuButton } from '../../../../customMenuButton';

import { WindowInformation } from './windowInformation';
import { EditWindowForm } from './editWindowForm';

const WindowDataWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
`;

interface WindowProps {
  windowGroup: Vindu[];
  wallId: VeggId;
  etasjeId: string;
  veggsoneId: string;
}

export const Window: FC<WindowProps> = ({
  windowGroup,
  wallId,
  etasjeId,
  veggsoneId
}) => {
  const [isEditMode, setEditMode] = useState<boolean>();
  const { t } = useTranslation();
  const { removeWindow } = useWindowOperations(etasjeId, wallId);

  const handleRemoveWindow = (windowGroup: Vindu[]) => {
    const vinduIds = windowGroup.map((window) => window.id);

    removeWindow(vinduIds, veggsoneId);
  };

  const windowData: WindowData = {
    window: windowGroup[0],
    amount: windowGroup.length,
    etasjeId,
    wallId,
    wallZoneId: veggsoneId
  };

  if (isEditMode) {
    return (
      <EditWindowForm
        windowGroup={windowGroup}
        windowData={windowData}
        onSave={() => {
          setEditMode(false);
        }}
      />
    );
  }

  return (
    <WindowDataWrapper>
      <Box p={5} flexGrow={1}>
        <WindowInformation data={windowData} />
      </Box>
      {/*Action buttons*/}
      <Box display="flex" alignItems="stretch" ml={2}>
        <Menu>
          <CustomMenuButton />

          <Menu.Content>
            <Menu.Item
              onClick={() => handleRemoveWindow(windowGroup)}
              aria-label={t('register.window.form.delete')}
            >
              {t('register.window.form.delete')}
            </Menu.Item>
            <Menu.Item
              onClick={() => setEditMode(true)}
              aria-label={t('register.window.form.edit')}
            >
              {t('register.window.form.edit')}
            </Menu.Item>
          </Menu.Content>
        </Menu>
      </Box>
    </WindowDataWrapper>
  );
};
