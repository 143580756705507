import { Bygningstype } from '../screens/createBuilding/types';

export enum EnergiObjektType {
  PrivatBolig = 'privatBolig',
  YrkesBygg = 'yrkesBygg'
}

export type MatrikkelBygningsType = {
  bygningsTypeNummer: string;
  kategoriMatrikkel: string;
  typeMatrikkel: string;
  kategori: string;
};

export type MatrikkelBygningsTypeList = {
  [key: string]: MatrikkelBygningsType;
};

export const matrikkelBygningsTyper: MatrikkelBygningsTypeList = {
  '111': {
    bygningsTypeNummer: '111',
    kategoriMatrikkel: 'Enebolig',
    typeMatrikkel: 'Enebolig',
    kategori: 'Smahus'
  },
  '112': {
    bygningsTypeNummer: '112',
    kategoriMatrikkel: 'Enebolig',
    typeMatrikkel: 'Enebolig med hybelleilighet, sokkelleilighet o.l.',
    kategori: 'Smahus'
  },
  '113': {
    bygningsTypeNummer: '113',
    kategoriMatrikkel: 'Enebolig',
    typeMatrikkel: 'Våningshus',
    kategori: 'Smahus'
  },
  '121': {
    bygningsTypeNummer: '121',
    kategoriMatrikkel: 'Tomannsbolig',
    typeMatrikkel: 'Tomannsbolig, vertikaldelt',
    kategori: 'Smahus'
  },
  '122': {
    bygningsTypeNummer: '122',
    kategoriMatrikkel: 'Tomannsbolig',
    typeMatrikkel: 'Tomannsbolig, horisontaldelt',
    kategori: 'Smahus'
  },
  '123': {
    bygningsTypeNummer: '123',
    kategoriMatrikkel: 'Tomannsbolig',
    typeMatrikkel: 'Våningshus, tomannsbolig, vertikaldelt',
    kategori: 'Smahus'
  },
  '124': {
    bygningsTypeNummer: '124',
    kategoriMatrikkel: 'Tomannsbolig',
    typeMatrikkel: 'Våningshus, tomannsbolig, horisontaldelt',
    kategori: 'Smahus'
  },
  '131': {
    bygningsTypeNummer: '131',
    kategoriMatrikkel: 'Rekkehus, kjedehus, andre småhus',
    typeMatrikkel: 'Rekkehus',
    kategori: 'Smahus'
  },
  '133': {
    bygningsTypeNummer: '133',
    kategoriMatrikkel: 'Rekkehus, kjedehus, andre småhus',
    typeMatrikkel: 'Kjedehus inkl. atriumhus',
    kategori: 'Smahus'
  },
  '135': {
    bygningsTypeNummer: '135',
    kategoriMatrikkel: 'Rekkehus, kjedehus, andre småhus',
    typeMatrikkel: 'Terrassehus',
    kategori: 'Smahus'
  },
  '136': {
    bygningsTypeNummer: '136',
    kategoriMatrikkel: 'Rekkehus, kjedehus, andre småhus',
    typeMatrikkel: 'Andre småhus med 3 boliger eller flere',
    kategori: 'Smahus'
  },
  '141': {
    bygningsTypeNummer: '141',
    kategoriMatrikkel: 'Store boligbygg',
    typeMatrikkel: 'Store frittliggende boligbygg på 2 etasjer',
    kategori: 'Boligblokker'
  },
  '142': {
    bygningsTypeNummer: '142',
    kategoriMatrikkel: 'Store boligbygg',
    typeMatrikkel: 'Store frittliggende boligbygg på 3 og 4 etasjer',
    kategori: 'Boligblokker'
  },
  '143': {
    bygningsTypeNummer: '143',
    kategoriMatrikkel: 'Store boligbygg',
    typeMatrikkel: 'Store frittliggende boligbygg på 5 etasjer eller over',
    kategori: 'Boligblokker'
  },
  '144': {
    bygningsTypeNummer: '144',
    kategoriMatrikkel: 'Store boligbygg',
    typeMatrikkel: 'Store sammenbygde boligbygg på 2 etasjer',
    kategori: 'Boligblokker'
  },
  '145': {
    bygningsTypeNummer: '145',
    kategoriMatrikkel: 'Store boligbygg',
    typeMatrikkel: 'Store sammenbygde boligbygg på 3 og 4 etasjer',
    kategori: 'Boligblokker'
  },
  '146': {
    bygningsTypeNummer: '146',
    kategoriMatrikkel: 'Store boligbygg',
    typeMatrikkel: 'Store sammenbygde boligbygg på 5 etasjer og over',
    kategori: 'Boligblokker'
  },
  '151': {
    bygningsTypeNummer: '151',
    kategoriMatrikkel: 'Bygning for bofellesskap',
    typeMatrikkel: 'Bo- og servicesenter',
    kategori: 'Smahus'
  },
  '152': {
    bygningsTypeNummer: '152',
    kategoriMatrikkel: 'Bygning for bofellesskap',
    typeMatrikkel: 'Studenthjem/studentboliger',
    kategori: 'Smahus'
  },
  '159': {
    bygningsTypeNummer: '159',
    kategoriMatrikkel: 'Bygning for bofellesskap',
    typeMatrikkel: 'Annen bygning for bofellesskap',
    kategori: 'Smahus'
  },
  '161': {
    bygningsTypeNummer: '161',
    kategoriMatrikkel: 'Fritidsbolig',
    typeMatrikkel: 'Fritidsbygning (hytter, sommerhus o.l.)',
    kategori: 'Smahus'
  },
  '162': {
    bygningsTypeNummer: '162',
    kategoriMatrikkel: 'Fritidsbolig',
    typeMatrikkel: 'Helårsbolig benyttet som fritidsbolig',
    kategori: 'Smahus'
  },
  '163': {
    bygningsTypeNummer: '163',
    kategoriMatrikkel: 'Fritidsbolig',
    typeMatrikkel: 'Våningshus benyttet som fritidsbolig',
    kategori: 'Smahus'
  },
  '171': {
    bygningsTypeNummer: '171',
    kategoriMatrikkel: 'Koie, seterhus og lignende',
    typeMatrikkel: 'Seterhus, sel, rorbu o.l.',
    kategori: 'Smahus'
  },
  '172': {
    bygningsTypeNummer: '172',
    kategoriMatrikkel: 'Koie, seterhus og lignende',
    typeMatrikkel: 'Skogs- og utmarkskoie, gamme',
    kategori: 'Smahus'
  },
  '181': {
    bygningsTypeNummer: '181',
    kategoriMatrikkel: 'Garasje og uthus til bolig',
    typeMatrikkel: 'Garasje, uthus, anneks knyttet til bolig',
    kategori: 'Smahus'
  },
  '182': {
    bygningsTypeNummer: '182',
    kategoriMatrikkel: 'Garasje og uthus til bolig',
    typeMatrikkel: 'Garasje, uthus, anneks knyttet til fritidsbolig',
    kategori: 'Smahus'
  },
  '183': {
    bygningsTypeNummer: '183',
    kategoriMatrikkel: 'Garasje og uthus til bolig',
    typeMatrikkel: 'Naust, båthus, sjøbu',
    kategori: 'Smahus'
  },
  '193': {
    bygningsTypeNummer: '193',
    kategoriMatrikkel: 'Annen boligbygning',
    typeMatrikkel: 'Boligbrakker',
    kategori: 'Boligblokker'
  },
  '199': {
    bygningsTypeNummer: '199',
    kategoriMatrikkel: 'Annen boligbygning',
    typeMatrikkel: 'Annen boligbygning (f.eks. sekundærbolig reindrift)',
    kategori: 'Boligblokker'
  },
  '211': {
    bygningsTypeNummer: '211',
    kategoriMatrikkel: 'Industribygning',
    typeMatrikkel: 'Fabrikkbygning',
    kategori: 'LettIndustriVerksted'
  },
  '212': {
    bygningsTypeNummer: '212',
    kategoriMatrikkel: 'Industribygning',
    typeMatrikkel: 'Verkstedbygning',
    kategori: 'LettIndustriVerksted'
  },
  '214': {
    bygningsTypeNummer: '214',
    kategoriMatrikkel: 'Industribygning',
    typeMatrikkel: 'Bygning for renseanlegg',
    kategori: 'LettIndustriVerksted'
  },
  '216': {
    bygningsTypeNummer: '216',
    kategoriMatrikkel: 'Industribygning',
    typeMatrikkel: 'Bygning for vannforsyning, bl.a. pumpestasjon',
    kategori: 'LettIndustriVerksted'
  },
  '219': {
    bygningsTypeNummer: '219',
    kategoriMatrikkel: 'Industribygning',
    typeMatrikkel: 'Annen industribygning',
    kategori: 'LettIndustriVerksted'
  },
  '221': {
    bygningsTypeNummer: '221',
    kategoriMatrikkel: 'Energiforsyningsbygning',
    typeMatrikkel: 'Kraftstasjon (>15 000 kVA)',
    kategori: 'LettIndustriVerksted'
  },
  '223': {
    bygningsTypeNummer: '223',
    kategoriMatrikkel: 'Energiforsyningsbygning',
    typeMatrikkel: 'Transformatorstasjon (>10 000 kVA)',
    kategori: 'LettIndustriVerksted'
  },
  '229': {
    bygningsTypeNummer: '229',
    kategoriMatrikkel: 'Energiforsyningsbygning',
    typeMatrikkel: 'Annen energiforsyningsbygning',
    kategori: 'LettIndustriVerksted'
  },
  '231': {
    bygningsTypeNummer: '231',
    kategoriMatrikkel: 'Lagerbygning',
    typeMatrikkel: 'Lagerhall',
    kategori: 'LettIndustriVerksted'
  },
  '232': {
    bygningsTypeNummer: '232',
    kategoriMatrikkel: 'Lagerbygning',
    typeMatrikkel: 'Kjøle- og fryselager',
    kategori: 'LettIndustriVerksted'
  },
  '233': {
    bygningsTypeNummer: '233',
    kategoriMatrikkel: 'Lagerbygning',
    typeMatrikkel: 'Silobygning',
    kategori: 'LettIndustriVerksted'
  },
  '239': {
    bygningsTypeNummer: '239',
    kategoriMatrikkel: 'Lagerbygning',
    typeMatrikkel: 'Annen lagerbygning',
    kategori: 'LettIndustriVerksted'
  },
  '241': {
    bygningsTypeNummer: '241',
    kategoriMatrikkel: 'Fiskeri- og landbruksbygning',
    typeMatrikkel:
      'Hus for dyr, fôrlager, strølager, frukt- og grønnsakslager, landbrukssilo, høy-/korntørke',
    kategori: 'LettIndustriVerksted'
  },
  '243': {
    bygningsTypeNummer: '243',
    kategoriMatrikkel: 'Fiskeri- og landbruksbygning',
    typeMatrikkel: 'Veksthus',
    kategori: 'LettIndustriVerksted'
  },
  '244': {
    bygningsTypeNummer: '244',
    kategoriMatrikkel: 'Fiskeri- og landbruksbygning',
    typeMatrikkel: 'Driftsbygning for fiske og fangst, inkl. oppdrettsanlegg',
    kategori: 'LettIndustriVerksted'
  },
  '245': {
    bygningsTypeNummer: '245',
    kategoriMatrikkel: 'Fiskeri- og landbruksbygning',
    typeMatrikkel: 'Naust/redskapshus for fiske',
    kategori: 'LettIndustriVerksted'
  },
  '248': {
    bygningsTypeNummer: '248',
    kategoriMatrikkel: 'Fiskeri- og landbruksbygning',
    typeMatrikkel: 'Annen fiskeri- og fangstbygning',
    kategori: 'LettIndustriVerksted'
  },
  '249': {
    bygningsTypeNummer: '249',
    kategoriMatrikkel: 'Fiskeri- og landbruksbygning',
    typeMatrikkel: 'Annen landbruksbygning',
    kategori: 'LettIndustriVerksted'
  },
  '311': {
    bygningsTypeNummer: '311',
    kategoriMatrikkel: 'Kontorbygning',
    typeMatrikkel: 'Kontor- og administrasjonsbygning, rådhus',
    kategori: 'Kontorbygg'
  },
  '312': {
    bygningsTypeNummer: '312',
    kategoriMatrikkel: 'Kontorbygning',
    typeMatrikkel: 'Bankbygning, posthus',
    kategori: 'Kontorbygg'
  },
  '313': {
    bygningsTypeNummer: '313',
    kategoriMatrikkel: 'Kontorbygning',
    typeMatrikkel: 'Mediebygning',
    kategori: 'Kontorbygg'
  },
  '319': {
    bygningsTypeNummer: '319',
    kategoriMatrikkel: 'Kontorbygning',
    typeMatrikkel: 'Annen kontorbygning',
    kategori: 'Kontorbygg'
  },
  '321': {
    bygningsTypeNummer: '321',
    kategoriMatrikkel: 'Forretningsbygning',
    typeMatrikkel: 'Kjøpesenter, varehus',
    kategori: 'Forretningsbygg'
  },
  '322': {
    bygningsTypeNummer: '322',
    kategoriMatrikkel: 'Forretningsbygning',
    typeMatrikkel: 'Butikkbygning',
    kategori: 'Forretningsbygg'
  },
  '323': {
    bygningsTypeNummer: '323',
    kategoriMatrikkel: 'Forretningsbygning',
    typeMatrikkel: 'Bensinstasjon',
    kategori: 'Forretningsbygg'
  },
  '329': {
    bygningsTypeNummer: '329',
    kategoriMatrikkel: 'Forretningsbygning',
    typeMatrikkel: 'Annen forretningsbygning',
    kategori: 'Forretningsbygg'
  },
  '330': {
    bygningsTypeNummer: '330',
    kategoriMatrikkel: 'Forretningsbygning',
    typeMatrikkel: 'Messe- og kongressbygning',
    kategori: 'Forretningsbygg'
  },
  '411': {
    bygningsTypeNummer: '411',
    kategoriMatrikkel: 'Ekspedisjonsbygning, terminal',
    typeMatrikkel: 'Ekspedisjonsbygning, flyterminal, kontrolltårn',
    kategori: 'AnnenBygning'
  },
  '412': {
    bygningsTypeNummer: '412',
    kategoriMatrikkel: 'Ekspedisjonsbygning, terminal',
    typeMatrikkel: 'Jernbane- og T-banestasjon',
    kategori: 'AnnenBygning'
  },
  '415': {
    bygningsTypeNummer: '415',
    kategoriMatrikkel: 'Ekspedisjonsbygning, terminal',
    typeMatrikkel: 'Godsterminal',
    kategori: 'AnnenBygning'
  },
  '416': {
    bygningsTypeNummer: '416',
    kategoriMatrikkel: 'Ekspedisjonsbygning, terminal',
    typeMatrikkel: 'Postterminal',
    kategori: 'AnnenBygning'
  },
  '419': {
    bygningsTypeNummer: '419',
    kategoriMatrikkel: 'Ekspedisjonsbygning, terminal',
    typeMatrikkel: 'Annen ekspedisjons- og terminalbygning',
    kategori: 'AnnenBygning'
  },
  '429': {
    bygningsTypeNummer: '429',
    kategoriMatrikkel: 'Telekommunikasjonsbygning',
    typeMatrikkel: 'Telekommunikasjonsbygning',
    kategori: 'AnnenBygning'
  },
  '431': {
    bygningsTypeNummer: '431',
    kategoriMatrikkel: 'Garasje- og hangarbygning',
    typeMatrikkel: 'Parkeringshus',
    kategori: 'AnnenBygning'
  },
  '439': {
    bygningsTypeNummer: '439',
    kategoriMatrikkel: 'Garasje- og hangarbygning',
    typeMatrikkel: 'Annen garasje- hangarbygning',
    kategori: 'AnnenBygning'
  },
  '441': {
    bygningsTypeNummer: '441',
    kategoriMatrikkel: 'Veg- og trafikktilsynsbygning',
    typeMatrikkel: 'Trafikktilsynsbygning',
    kategori: 'AnnenBygning'
  },
  '449': {
    bygningsTypeNummer: '449',
    kategoriMatrikkel: 'Veg- og trafikktilsynsbygning',
    typeMatrikkel: 'Annen veg- og trafikktilsynsbygning',
    kategori: 'AnnenBygning'
  },
  '511': {
    bygningsTypeNummer: '511',
    kategoriMatrikkel: 'Hotellbygning',
    typeMatrikkel: 'Hotellbygning',
    kategori: 'Hoteller'
  },
  '512': {
    bygningsTypeNummer: '512',
    kategoriMatrikkel: 'Hotellbygning',
    typeMatrikkel: 'Motellbygning',
    kategori: 'Hoteller'
  },
  '519': {
    bygningsTypeNummer: '519',
    kategoriMatrikkel: 'Hotellbygning',
    typeMatrikkel: 'Annen hotellbygning',
    kategori: 'Hoteller'
  },
  '521': {
    bygningsTypeNummer: '521',
    kategoriMatrikkel: 'Bygning for overnatting',
    typeMatrikkel: 'Hospits, pensjonat',
    kategori: 'Hoteller'
  },
  '522': {
    bygningsTypeNummer: '522',
    kategoriMatrikkel: 'Bygning for overnatting',
    typeMatrikkel: 'Vandrerhjem, feriehjem/-koloni, turisthytte',
    kategori: 'Hoteller'
  },
  '523': {
    bygningsTypeNummer: '523',
    kategoriMatrikkel: 'Bygning for overnatting',
    typeMatrikkel: 'Appartement',
    kategori: 'Hoteller'
  },
  '524': {
    bygningsTypeNummer: '524',
    kategoriMatrikkel: 'Bygning for overnatting',
    typeMatrikkel: 'Campinghytte/utleiehytte',
    kategori: 'Hoteller'
  },
  '529': {
    bygningsTypeNummer: '529',
    kategoriMatrikkel: 'Bygning for overnatting',
    typeMatrikkel: 'Annen bygning for overnatting',
    kategori: 'Hoteller'
  },
  '531': {
    bygningsTypeNummer: '531',
    kategoriMatrikkel: 'Restaurantbygning',
    typeMatrikkel: 'Restaurantbygning, kafébygning',
    kategori: 'Hoteller'
  },
  '532': {
    bygningsTypeNummer: '532',
    kategoriMatrikkel: 'Restaurantbygning',
    typeMatrikkel: 'Sentralkjøkken, kantinebygning',
    kategori: 'Hoteller'
  },
  '533': {
    bygningsTypeNummer: '533',
    kategoriMatrikkel: 'Restaurantbygning',
    typeMatrikkel: 'Gatekjøkken, kioskbygning',
    kategori: 'Hoteller'
  },
  '539': {
    bygningsTypeNummer: '539',
    kategoriMatrikkel: 'Restaurantbygning',
    typeMatrikkel: 'Annen restaurantbygning',
    kategori: 'Hoteller'
  },
  '611': {
    bygningsTypeNummer: '611',
    kategoriMatrikkel: 'Skolebygning',
    typeMatrikkel: 'Lekepark',
    kategori: 'Skolebygg'
  },
  '612': {
    bygningsTypeNummer: '612',
    kategoriMatrikkel: 'Skolebygning',
    typeMatrikkel: 'Barnehage',
    kategori: 'Barnehage'
  },
  '613': {
    bygningsTypeNummer: '613',
    kategoriMatrikkel: 'Skolebygning',
    typeMatrikkel: 'Barneskole',
    kategori: 'Skolebygg'
  },
  '614': {
    bygningsTypeNummer: '614',
    kategoriMatrikkel: 'Skolebygning',
    typeMatrikkel: 'Ungdomsskole',
    kategori: 'Skolebygg'
  },
  '615': {
    bygningsTypeNummer: '615',
    kategoriMatrikkel: 'Skolebygning',
    typeMatrikkel: 'Kombinert barne- og ungdomsskole',
    kategori: 'Skolebygg'
  },
  '616': {
    bygningsTypeNummer: '616',
    kategoriMatrikkel: 'Skolebygning',
    typeMatrikkel: 'Videregående skole',
    kategori: 'Skolebygg'
  },
  '619': {
    bygningsTypeNummer: '619',
    kategoriMatrikkel: 'Skolebygning',
    typeMatrikkel: 'Annen skolebygning',
    kategori: 'Skolebygg'
  },
  '621': {
    bygningsTypeNummer: '621',
    kategoriMatrikkel: 'Universitet- og høgskolebygning',
    typeMatrikkel:
      'Universitets- og høgskolebygning med integrerte funksjoner, auditorium, lesesal o.a.',
    kategori: 'UniversitetHoyskole'
  },
  '623': {
    bygningsTypeNummer: '623',
    kategoriMatrikkel: 'Universitet- og høgskolebygning',
    typeMatrikkel: 'Laboratoriebygning',
    kategori: 'UniversitetHoyskole'
  },
  '629': {
    bygningsTypeNummer: '629',
    kategoriMatrikkel: 'Universitet- og høgskolebygning',
    typeMatrikkel: 'Annen universitets-, høgskole- og forskningsbygning',
    kategori: 'UniversitetHoyskole'
  },
  '641': {
    bygningsTypeNummer: '641',
    kategoriMatrikkel: 'Museums- og biblioteksbygning',
    typeMatrikkel: 'Museum, kunstgalleri',
    kategori: 'Kulturbygg'
  },
  '642': {
    bygningsTypeNummer: '642',
    kategoriMatrikkel: 'Museums- og biblioteksbygning',
    typeMatrikkel: 'Bibliotek, mediatek',
    kategori: 'Kulturbygg'
  },
  '643': {
    bygningsTypeNummer: '643',
    kategoriMatrikkel: 'Museums- og biblioteksbygning',
    typeMatrikkel: 'Zoologisk og botanisk hage',
    kategori: 'Kulturbygg'
  },
  '649': {
    bygningsTypeNummer: '649',
    kategoriMatrikkel: 'Museums- og biblioteksbygning',
    typeMatrikkel: 'Annen museums- og bibliotekbygning',
    kategori: 'Kulturbygg'
  },
  '651': {
    bygningsTypeNummer: '651',
    kategoriMatrikkel: 'Idrettsbygning',
    typeMatrikkel: 'Idrettshall',
    kategori: 'Idrettsbygg'
  },
  '652': {
    bygningsTypeNummer: '652',
    kategoriMatrikkel: 'Idrettsbygning',
    typeMatrikkel: 'Ishall',
    kategori: 'Idrettsbygg'
  },
  '653': {
    bygningsTypeNummer: '653',
    kategoriMatrikkel: 'Idrettsbygning',
    typeMatrikkel: 'Svømmehall',
    kategori: 'Idrettsbygg'
  },
  '654': {
    bygningsTypeNummer: '654',
    kategoriMatrikkel: 'Idrettsbygning',
    typeMatrikkel: 'Tribune og idrettsgarderobe',
    kategori: 'Idrettsbygg'
  },
  '655': {
    bygningsTypeNummer: '655',
    kategoriMatrikkel: 'Idrettsbygning',
    typeMatrikkel: 'Helsestudio',
    kategori: 'Idrettsbygg'
  },
  '659': {
    bygningsTypeNummer: '659',
    kategoriMatrikkel: 'Idrettsbygning',
    typeMatrikkel: 'Annen idrettsbygning',
    kategori: 'Idrettsbygg'
  },
  '661': {
    bygningsTypeNummer: '661',
    kategoriMatrikkel: 'Kulturhus',
    typeMatrikkel: 'Kinobygning, teaterbygning, opera/konserthus',
    kategori: 'Kulturbygg'
  },
  '662': {
    bygningsTypeNummer: '662',
    kategoriMatrikkel: 'Kulturhus',
    typeMatrikkel: 'Samfunnshus, grendehus',
    kategori: 'Kulturbygg'
  },
  '663': {
    bygningsTypeNummer: '663',
    kategoriMatrikkel: 'Kulturhus',
    typeMatrikkel: 'Diskotek',
    kategori: 'Kulturbygg'
  },
  '669': {
    bygningsTypeNummer: '669',
    kategoriMatrikkel: 'Kulturhus',
    typeMatrikkel: 'Annet kulturhus',
    kategori: 'Kulturbygg'
  },
  '671': {
    bygningsTypeNummer: '671',
    kategoriMatrikkel: 'Bygning for religiøse aktiviteter',
    typeMatrikkel: 'Kirke, kapell',
    kategori: 'Kulturbygg'
  },
  '672': {
    bygningsTypeNummer: '672',
    kategoriMatrikkel: 'Bygning for religiøse aktiviteter',
    typeMatrikkel: 'Bedehus, menighetshus',
    kategori: 'Kulturbygg'
  },
  '673': {
    bygningsTypeNummer: '673',
    kategoriMatrikkel: 'Bygning for religiøse aktiviteter',
    typeMatrikkel: 'Krematorium, gravkapell, bårehus',
    kategori: 'Kulturbygg'
  },
  '674': {
    bygningsTypeNummer: '674',
    kategoriMatrikkel: 'Bygning for religiøse aktiviteter',
    typeMatrikkel: 'Synagoge, moské',
    kategori: 'Kulturbygg'
  },
  '675': {
    bygningsTypeNummer: '675',
    kategoriMatrikkel: 'Bygning for religiøse aktiviteter',
    typeMatrikkel: 'Kloster',
    kategori: 'Kulturbygg'
  },
  '679': {
    bygningsTypeNummer: '679',
    kategoriMatrikkel: 'Bygning for religiøse aktiviteter',
    typeMatrikkel: 'Annen bygning for religiøse aktiviteter',
    kategori: 'Kulturbygg'
  },
  '719': {
    bygningsTypeNummer: '719',
    kategoriMatrikkel: 'Sykehus',
    typeMatrikkel: 'Sykehus',
    kategori: 'Sykehus'
  },
  '721': {
    bygningsTypeNummer: '721',
    kategoriMatrikkel: 'Sykehjem',
    typeMatrikkel: 'Sykehjem',
    kategori: 'Sykehjem'
  },
  '722': {
    bygningsTypeNummer: '722',
    kategoriMatrikkel: 'Sykehjem',
    typeMatrikkel: 'Bo- og behandlingssenter, aldershjem',
    kategori: 'Sykehjem'
  },
  '723': {
    bygningsTypeNummer: '723',
    kategoriMatrikkel: 'Sykehjem',
    typeMatrikkel: 'Rehabiliteringsinstitusjon, kurbad',
    kategori: 'Sykehjem'
  },
  '729': {
    bygningsTypeNummer: '729',
    kategoriMatrikkel: 'Sykehjem',
    typeMatrikkel: 'Annet sykehjem',
    kategori: 'Sykehjem'
  },
  '731': {
    bygningsTypeNummer: '731',
    kategoriMatrikkel: 'Primærhelsebygning',
    typeMatrikkel: 'Klinikk, legekontor/-senter/-vakt',
    kategori: 'Sykehus'
  },
  '732': {
    bygningsTypeNummer: '732',
    kategoriMatrikkel: 'Primærhelsebygning',
    typeMatrikkel: 'Helse- og sosialsenter, helsestasjon',
    kategori: 'Sykehus'
  },
  '739': {
    bygningsTypeNummer: '739',
    kategoriMatrikkel: 'Primærhelsebygning',
    typeMatrikkel: 'Annen primærhelsebygning',
    kategori: 'Sykehus'
  },
  '819': {
    bygningsTypeNummer: '819',
    kategoriMatrikkel: 'Fengselsbygning',
    typeMatrikkel: 'Fengselsbygning',
    kategori: 'AnnenBygning'
  },
  '821': {
    bygningsTypeNummer: '821',
    kategoriMatrikkel: 'Beredskapsbygning',
    typeMatrikkel: 'Politistasjon',
    kategori: 'AnnenBygning'
  },
  '822': {
    bygningsTypeNummer: '822',
    kategoriMatrikkel: 'Beredskapsbygning',
    typeMatrikkel: 'Brannstasjon, ambulansestasjon',
    kategori: 'AnnenBygning'
  },
  '823': {
    bygningsTypeNummer: '823',
    kategoriMatrikkel: 'Beredskapsbygning',
    typeMatrikkel: 'Fyrstasjon, losstasjon',
    kategori: 'AnnenBygning'
  },
  '824': {
    bygningsTypeNummer: '824',
    kategoriMatrikkel: 'Beredskapsbygning',
    typeMatrikkel: 'Stasjon for radarovervåkning av fly- og/eller skipstrafikk',
    kategori: 'AnnenBygning'
  },
  '825': {
    bygningsTypeNummer: '825',
    kategoriMatrikkel: 'Beredskapsbygning',
    typeMatrikkel: 'Tilfluktsrom/bunker',
    kategori: 'AnnenBygning'
  },
  '829': {
    bygningsTypeNummer: '829',
    kategoriMatrikkel: 'Beredskapsbygning',
    typeMatrikkel: 'Annen beredskapsbygning',
    kategori: 'AnnenBygning'
  },
  '830': {
    bygningsTypeNummer: '830',
    kategoriMatrikkel: 'Monument',
    typeMatrikkel: 'Monument',
    kategori: 'AnnenBygning'
  },
  '840': {
    bygningsTypeNummer: '840',
    kategoriMatrikkel: 'Offentlig toalett',
    typeMatrikkel: 'Offentlig toalett',
    kategori: 'AnnenBygning'
  },
  '999': {
    bygningsTypeNummer: '999',
    kategoriMatrikkel: 'Ukjent bygningstype',
    typeMatrikkel: 'Ukjent bygningstype',
    kategori: 'Ukjent'
  }
};

export const GetTypeFromMatrikkel = (bygningsTypeNummer: string): string =>
  matrikkelBygningsTyper[bygningsTypeNummer]?.typeMatrikkel || '';

export const GetKategoriFromMatrikkel = (bygningsTypeNummer: string): string =>
  matrikkelBygningsTyper[bygningsTypeNummer]?.kategoriMatrikkel || '';

export const GetEnovaKategoriFromMatrikkel = (
  bygningsTypeNummer: string
): string => matrikkelBygningsTyper[bygningsTypeNummer]?.kategori || '';

// This uses the kanEnergimerkesAvPrivatPerson value from backend which encapsulates
// all business logic
export const GetEnergiObjektTypeFromMatrikkel = (
  kanEnergimerkesAvPrivatPerson: boolean
): EnergiObjektType => {
  return kanEnergimerkesAvPrivatPerson
    ? EnergiObjektType.PrivatBolig
    : EnergiObjektType.YrkesBygg;
};

/*
 * Function to check if a string contains a negative number or a positive number
 * to indicate whether its a temporary budiling or not.

 * Returns true for "-123" and "-0".
 * Returns false for "--123" and "-123a", "234", "0234" and "01234234asdf"

 * Regex:
    ^   matches the start of the string.
    -  matches a dash at the beginning of the string.
    \d+ matches one or more digits.
    $   matches the end of the string.

 * This should probably come from the backend.
 */
export const isBuildingTemporary = (bruksenhetsId: string) => {
  const regex = /^-\d+$/;

  return regex.test(bruksenhetsId);
};

export const getBygningstypeFromStringValue = (stringValue: string | null) => {
  if (stringValue === '100') {
    return Bygningstype.BoligBygg;
  }
  if (stringValue === '200') {
    return Bygningstype.YrkesBygg;
  }

  return undefined;
};

export const isBuildingGarageOrAnnex = (bygningsTypeNummer: string) => {
  return bygningsTypeNummer === '181' || bygningsTypeNummer === '182';
};
