import React, { FC, Fragment } from 'react';
import {
  Box,
  FormMessage,
  Grid,
  Link,
  Tabs,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import {
  camelCasify,
  RegistrationSummaryScreenParams,
  RegistrationSummaryTab
} from '../../../../utils/navigation';
import EnovaApplicationList from '../../../../components/enovaApplicationList';
import { useUser } from '../../../../hooks/useUser';
import useRegistrationForm from '../../useRegistrationForm';
import { SoknadSteg } from '../../../../components/registering/utils/registerEnums';
import { AreaOfExpertise } from '../../../../types/user';
import { useSubmitRegistrationAsQuery } from '../../useRegistrationForm/useSubmitRegistration';
import TiltakListReadOnly from '../../../../components/tiltakSection/tiltakListReadOnly';

import CalculatedEnergyRating from './tabs/calculatedEnergyRating';
import SummaryReview from './tabs/review';
import SummaryResult from './tabs/result';

const Summary: FC = () => {
  const { replace, go } = useHistory();
  const { t } = useTranslation();
  const { tab = RegistrationSummaryTab.CALCULATED_ENERGY_RATING, ...params } =
    useParams<RegistrationSummaryScreenParams>();

  const { tiltakList, registrationId, generateSummaryPath, soknadSteg } =
    useRegistrationForm();
  const { user, user: { type: userType } = {} } = useUser();

  // We submit a registration every time we mount this page.
  // In the future the data needs to be available when we arrive to this point.
  const { data, loading, error, gradeLimitedToB, isFetchedAfterMount } =
    useSubmitRegistrationAsQuery(registrationId, 'summary', params.buildingId);

  // RegistrationActionButtons handles the loading indicator so we dont
  // have multiple loading indicators.
  if (!isFetchedAfterMount || loading) {
    return null;
  }

  if (error || !data) {
    return (
      <FormMessage fullWidth title={t('register.summary.error.title')}>
        <Typography>{t('register.summary.error.description')}</Typography>

        <Link button color="inherit" onClick={() => go(0)}>
          {t('register.summary.error.retry')}
        </Link>
      </FormMessage>
    );
  }

  const getNewPath = (newTab: RegistrationSummaryTab) =>
    generateSummaryPath({ ...params, tab: newTab });

  const getTabTitle = (tabName: RegistrationSummaryTab) =>
    t(`register.summary.tab.${camelCasify(tabName)}.tabTitle`, {
      context: userType
    });

  return (
    <Fragment>
      <Typography variant="h2" gutterBottom>
        {t('register.summary.heading')}
      </Typography>

      <Typography paragraph>{t('register.summary.description')}</Typography>

      <Box mb={-8}>
        <Tabs
          onChange={(_, newTab) =>
            replace(getNewPath(newTab as RegistrationSummaryTab))
          }
          selectedTab={tab}
          contained
        >
          <Tabs.Tab
            identifier={RegistrationSummaryTab.CALCULATED_ENERGY_RATING}
            tabTitle={getTabTitle(
              RegistrationSummaryTab.CALCULATED_ENERGY_RATING
            )}
          >
            <CalculatedEnergyRating gradeLimitedToB={gradeLimitedToB} />
          </Tabs.Tab>

          <Tabs.Tab
            identifier={RegistrationSummaryTab.REVIEW}
            tabTitle={getTabTitle(RegistrationSummaryTab.REVIEW)}
          >
            <SummaryReview />
          </Tabs.Tab>

          {(user?.fagomrader.includes(AreaOfExpertise.BYGNINGSEKSPERT) ||
            user?.fagomrader.includes(AreaOfExpertise.ENERGIRADGIVER)) && (
            <Tabs.Tab
              identifier={RegistrationSummaryTab.RESULT_CALCULATION}
              tabTitle={getTabTitle(RegistrationSummaryTab.RESULT_CALCULATION)}
            >
              <SummaryResult />
            </Tabs.Tab>
          )}

          {soknadSteg !== SoknadSteg.Steg1 && (
            <Tabs.Tab
              identifier={RegistrationSummaryTab.MEASURES}
              tabTitle={getTabTitle(RegistrationSummaryTab.MEASURES)}
            >
              {tiltakList && tiltakList.length > 0 ? (
                <Grid container spacing={4} direction="column">
                  <TiltakListReadOnly tiltakList={tiltakList} />
                </Grid>
              ) : (
                <Typography>{t('energySavingMeasures.emptyState')}</Typography>
              )}
            </Tabs.Tab>
          )}

          {soknadSteg && (
            <Tabs.Tab
              identifier={RegistrationSummaryTab.DOCUMENTS}
              tabTitle={getTabTitle(RegistrationSummaryTab.DOCUMENTS)}
            >
              <Grid container spacing={4}>
                <EnovaApplicationList />
              </Grid>
            </Tabs.Tab>
          )}
        </Tabs>
      </Box>
    </Fragment>
  );
};

export default Summary;
