import { HelperBox, Select } from 'enova-frontend-components';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { EnergiObjektType } from '../../../utils/matrikkelBygningsTyper';

interface Props {
  value: EnergiObjektType;
  onChange: (type: EnergiObjektType) => void;
  disabled?: boolean;
}

export const EnergiObjektTypeSelect: FC<Props> = ({
  value,
  onChange,
  disabled
}) => {
  const { t } = useTranslation();
  const options = [
    {
      label: t('building.create.bygningstype.boligBygg'),
      value: EnergiObjektType.PrivatBolig
    },
    {
      label: t('building.create.bygningstype.yrkesBygg'),
      value: EnergiObjektType.YrkesBygg
    }
  ];

  return (
    <Fragment>
      <Select
        value={value}
        disabled={disabled}
        options={options}
        label={t('expertToolsXML.measures.typeSelect.label')}
        onChange={(e) => {
          onChange(e.currentTarget.value as EnergiObjektType);
        }}
      />
      <HelperBox aria-label={t('tiltak.energiobjektType.description')}>
        {t('tiltak.energiobjektType.description')}
      </HelperBox>
    </Fragment>
  );
};
