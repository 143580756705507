import { Typography } from 'enova-frontend-components';
import React, { FC } from 'react';

import { useGetBuildingAddress } from '../../../../../../../../../../../hooks/useGetBuildingAddress';
import useBuildingIdentifier from '../../../../../../../../../../../hooks/useBuildingIdentifier';
import { SimpleEnergyAnalysis } from '../../../../../../../../../../../types/building';

interface Props {
  energyAnalysis: SimpleEnergyAnalysis;
}

export const TableRow: FC<Props> = ({ energyAnalysis }) => {
  const { getBuildingAddress } = useGetBuildingAddress();
  const getBuildingIdentifier = useBuildingIdentifier();

  const property = energyAnalysis.registrering?.registreringEiendom;

  return (
    <tr>
      <td>
        <Typography>{getBuildingAddress(property?.adresse)}</Typography>
      </td>
      <td>
        <Typography>{property?.bruksEnhetsNummer}</Typography>
      </td>
      <td>
        <Typography>
          {getBuildingIdentifier({
            bnr: property?.bruksNummer,
            gnr: property?.gardsNummer,
            knr: property?.adresse.kommuneNummer,
            snr: property?.seksjonsNummer
          })}
        </Typography>
      </td>
    </tr>
  );
};
