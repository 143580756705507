import React, { FC } from 'react';
import { Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FlerboligRegistrationResponse } from '../../../../../services/types';
import { SmallTypography } from '../../../buildings/utils';
import { useDeleteFlerboligRegistration } from '../hooks/useDeleteFlerboligRegistration';

import { RegistrationListItemWrapper } from './registrationListItemWrapper';

export const FlerboligRegistrationUnpublished: FC<
  FlerboligRegistrationResponse
> = ({ flerboligbygg, energianalyser }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { isLoading, deleteFlerboligRegistration } =
    useDeleteFlerboligRegistration();

  const navigateToRegistration = () => {
    history.push(
      `/xml-registrering/flerbolig/oppsummering-av-fil/${flerboligbygg.flerboligbyggId}`
    );
  };
  const onDelete = () => {
    deleteFlerboligRegistration(flerboligbygg.flerboligbyggId);
  };

  return (
    <RegistrationListItemWrapper
      dateCreated={energianalyser?.[0].registrering.opprettetDato}
      onDeleteRegistration={onDelete}
      onContinueRegistration={navigateToRegistration}
      isLoading={isLoading}
    >
      <SmallTypography variant="h4" component="p">
        {flerboligbygg.navn}
      </SmallTypography>
      <Typography>
        {t('xmlOverview.flerboligbygg.infoText', {
          count: energianalyser?.length
        })}
      </Typography>
    </RegistrationListItemWrapper>
  );
};
