import React, { FC, Fragment, useEffect } from 'react';
import {
  CollapsibleFactBox,
  FactBox,
  Grid,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { useDispatch } from 'react-redux';

import useSelector from '../../../../hooks/useSelector';
import { getMinifiedEtasjer } from '../../../../store/registration/selectors';
import { initGulvsoner } from '../../../../store/registration/actions';

import FloorSection from './floorSection';

const Floors: FC = () => {
  const { t } = useTranslation();

  const basicInfoEtasjer = useSelector(
    (state) => getMinifiedEtasjer(state),
    _.isEqual
  );

  const hasEtasjer = basicInfoEtasjer && basicInfoEtasjer.length > 0;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initGulvsoner());
  }, []);

  return (
    <Fragment>
      <Typography variant="h2" gutterBottom>
        {t('register.floors.title')}
      </Typography>

      <Typography paragraph>{t('register.floors.helperText')}</Typography>

      {hasEtasjer ? (
        basicInfoEtasjer.length === 1 ? (
          <FactBox className="mb-3" title={t('register.floors.sectionTitle')}>
            <FloorSection etasjeId={basicInfoEtasjer[0].id} />
          </FactBox>
        ) : (
          <Grid container spacing={4}>
            {basicInfoEtasjer?.map((etasje, etasjeIndex) => (
              <Grid item xs={12} key={etasje.id}>
                <CollapsibleFactBox
                  title={t('register.floors.sectionTitle', {
                    context: etasje.erKjeller ? 'basement' : 'floorNo',
                    count: etasje.etasjeNummer
                  })}
                  initExpanded={etasjeIndex === 0}
                >
                  <FloorSection etasjeId={etasje.id} />
                </CollapsibleFactBox>
              </Grid>
            ))}
          </Grid>
        )
      ) : (
        // TODO
        <Typography>Empty state placeholder</Typography>
      )}
    </Fragment>
  );
};

export default Floors;
