import React, { FC } from 'react';
import {
  Box,
  Select,
  SelectOption,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch } from 'react-redux';

import { Himmelretning, Skjerming } from '../../../utils/registerEnums';
import { updateTerrengskjerming } from '../../../../../store/registration/actions';
import useSelector from '../../../../../hooks/useSelector';
import { getTerrainShielding } from '../../../../../store/registration/selectors';

interface TerrainShieldingSelectProps {
  options: SelectOption[];
  value?: Skjerming;
  direction: Himmelretning;
}

const TerrainShieldSelect: FC<TerrainShieldingSelectProps> = ({
  options,
  value,
  direction
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSelect = (value: Skjerming) => {
    dispatch(
      updateTerrengskjerming({
        field: direction,
        value: value
      })
    );
  };

  return (
    <Select
      label={t(`direction.${direction}`)}
      options={options}
      value={value ?? -1}
      onChange={(e) => {
        const selectedValue = e.currentTarget.value as Skjerming;
        onSelect(selectedValue);
      }}
    />
  );
};

export const TerrainShielding = () => {
  const { t } = useTranslation();
  const terrainShielding = useSelector(getTerrainShielding, shallowEqual);

  const createOption = (option: Skjerming) => ({
    label: t(`register.terrainShielding.option.${option}`),
    value: option
  });

  const terrainOptions = [
    {
      label: t('register.terrainShielding.option', { context: 'default' }),
      value: -1,
      disabled: true
    },
    createOption(Skjerming.Default),
    createOption(Skjerming.IngenSkjerming),
    createOption(Skjerming.ModeratSkjerming),
    createOption(Skjerming.HoySkjerming)
  ];

  return (
    <Box gap={2}>
      <Typography variant="h3">
        {t('register.terrainShielding.title')}
      </Typography>
      <Typography>{t('register.terrainShielding.description')}</Typography>
      <Box gap={2} my={3} display={'flex'} flexDirection={'row'}>
        <TerrainShieldSelect
          options={terrainOptions}
          value={terrainShielding?.nord}
          direction={Himmelretning.Nord}
        />
        <TerrainShieldSelect
          options={terrainOptions}
          value={terrainShielding?.ost}
          direction={Himmelretning.Ost}
        />
        <TerrainShieldSelect
          options={terrainOptions}
          value={terrainShielding?.vest}
          direction={Himmelretning.Vest}
        />
        <TerrainShieldSelect
          options={terrainOptions}
          value={terrainShielding?.sor}
          direction={Himmelretning.Sor}
        />
      </Box>
    </Box>
  );
};
