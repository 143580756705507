import React, { FC } from 'react';
import { Box, Paper, styled, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { VeggId } from '../../../utils/registerEnums';
import { useWallZones, useWindows } from '../utils';
import { Window } from '../window';

const StyledPaper = styled(Paper)`
  border: solid 1px #d0d2d2;
`;

const TitleWrapper = styled(Box)`
  padding: 15px;
  border-bottom: 1px solid #d0d2d2;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

interface Props {
  wallId: VeggId;
  etasjeId: string;
  veggsoneId: string;
}

export const WindowList: FC<Props> = ({ wallId, veggsoneId, etasjeId }) => {
  const { t } = useTranslation();
  const { windowRecord } = useWindows(veggsoneId, etasjeId, wallId);

  const { wallZones } = useWallZones(etasjeId, wallId);

  const zoneNumber =
    (wallZones?.findIndex((zone) => zone.id === veggsoneId) || 0) + 1;

  return (
    <Box px={5} display="flex" flexDirection="column" gap={3}>
      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
      {Object.entries(windowRecord).map(([_key, windowGroup], index) => (
        <StyledPaper key={index} shadow="none">
          <TitleWrapper>
            <Typography variant="h4">
              {t('register.wallZone.wallZoneId', { wallZoneId: zoneNumber })} -
            </Typography>

            <Typography variant="h4">
              {t('register.window.title', { number: index + 1 })}
            </Typography>
          </TitleWrapper>
          <Window
            wallId={wallId}
            etasjeId={etasjeId}
            veggsoneId={veggsoneId}
            windowGroup={windowGroup}
          />
        </StyledPaper>
      ))}
    </Box>
  );
};
