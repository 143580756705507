import { Select } from 'enova-frontend-components';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useKarmAndGlassTypeOptions } from '../useKarmAndGlassTypeOptions';
import { useWindowFormContext, WindowFormFieldProps } from '../utils';

export const WindowFrameSelect: FC<WindowFormFieldProps> = ({ control }) => {
  const { t } = useTranslation();
  const { setValue } = useWindowFormContext();

  const { karmTypeOptions } = useKarmAndGlassTypeOptions(undefined);
  return (
    <Controller
      control={control}
      name="windowData.window.karmType"
      rules={{
        validate: (value, formValues) => {
          if (formValues.detailing !== 'windowType') {
            return;
          }

          return !!value || t('required');
        }
      }}
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error }
      }) => (
        <Select
          {...rest}
          error={!!error}
          helperText={error?.message}
          label={t('register.window.karmType.label')}
          value={value ?? -1}
          onChange={(e) => {
            onChange(e?.currentTarget?.value);

            // Reset window glass select value
            setValue('windowData.window.glassType', undefined);
          }}
          options={karmTypeOptions}
          required
        />
      )}
    />
  );
};
