import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { AdvancedSearchScreenPath } from '../../../../../utils/navigation';

import AdvancedSearchView from './advancedSearch';
import SimpleSearch from './simpleSearch';

const SearchView: FC = () => (
  <Switch>
    <Route path={AdvancedSearchScreenPath} component={AdvancedSearchView} />
    <Route component={SimpleSearch} />
  </Switch>
);

export default SearchView;
