import { FormMessage, Grid, Link, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ErrorProps {
  titleKey?: string;
  descriptionKey?: string;
  onRetry?: () => void;
}

export const ErrorBox: FC<ErrorProps> = ({
  titleKey = 'generalError.title',
  descriptionKey = 'generalError.description',
  onRetry
}) => {
  const { t } = useTranslation();

  return (
    <Grid xs={12} item>
      <FormMessage fullWidth title={t(titleKey)}>
        <Typography>{t(descriptionKey)}</Typography>

        {onRetry && (
          <Link button transparentBackground={true} onClick={onRetry}>
            {t('generalError.retry')}
          </Link>
        )}
      </FormMessage>
    </Grid>
  );
};
