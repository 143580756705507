import React, { FC } from 'react';
import { Button, Stack } from 'enova-frontend-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Property, SimpleEnergyAnalysis } from '../../../types/building';
import { getBuildingScreenPath } from '../../../utils/navigation';

const getPropertyString = (eiendom: Property) => {
  return [
    eiendom.adresse.kommuneNummer,
    eiendom.gardsNummer,
    eiendom.bruksNummer,
    eiendom.seksjonsNummer
  ].join('/');
};

interface Props {
  energyAnalysis: SimpleEnergyAnalysis;
}

export const EnergyAnalysisRow: FC<Props> = ({ energyAnalysis }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const navigateToBuilding = () =>
    push(
      getBuildingScreenPath({
        buildingId: energyAnalysis.registrering.energiObjektId
      })
    );

  const eiendom = energyAnalysis.registrering.registreringEiendom;
  const uri = energyAnalysis.energiplan?.attestUri;

  return (
    <tr>
      <td>{eiendom.bruksenhetsIdentId}</td>
      <td>{getPropertyString(eiendom)}</td>
      <td>{eiendom.adresse.gateNavn + ' ' + eiendom.adresse.nummer}</td>
      <td className="actions">
        <Stack spacing={2} direction="row">
          <Button variant="secondary" onClick={navigateToBuilding}>
            {t('expertToolsXMLPortfolio.worklist.show')}
          </Button>
          <Button variant="secondary" disabled={!uri} href={uri}>
            {t('download')}
          </Button>
        </Stack>
      </td>
    </tr>
  );
};
