import { Box, styled } from 'enova-frontend-components';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MultiSelectWrapper = styled(({ isMultipleSelected, ...props }) => (
  <Box {...props} />
))<{ isMultipleSelected?: boolean }>`
  width: 550px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const NavigationWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin-top: 50px;
`;

export const NewBadge = styled((props) => <Box {...props}>Nytt!</Box>)`
  background-color: #5eca9b;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  color: white;
  position: relative;
  left: -20px;
  top: -15px;
  font-weight: 600;
`;
