import { FC } from 'react';

import { ReactComponent as LShapeNorth } from '../../../../../../../assets/icons/shapes/l-shape/l-shape-1.svg';
import { ReactComponent as LShapeEast } from '../../../../../../../assets/icons/shapes/l-shape/l-shape-2.svg';
import { ReactComponent as LShapeSouth } from '../../../../../../../assets/icons/shapes/l-shape/l-shape-3.svg';
import { ReactComponent as LShapeWest } from '../../../../../../../assets/icons/shapes/l-shape/l-shape-4.svg';
import { Himmelretning } from '../../../../../utils/registerEnums';

import { ShapeProps } from './types';

export const LShape: FC<ShapeProps> = ({ direction }) => {
  switch (direction) {
    case Himmelretning.Nord:
      return <LShapeNorth />;
    case Himmelretning.Ost:
      return <LShapeEast />;
    case Himmelretning.Sor:
      return <LShapeSouth />;
    case Himmelretning.Vest:
      return <LShapeWest />;

    default:
      return <LShapeNorth />;
  }
};
