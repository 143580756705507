import { Stack, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import RouterButtonLink from '../../../../components/routerButtonLink';
import { getExpertToolsXMLPortfolioWorklistPath } from '../../../../utils/navigation';
import useFormatDate from '../../../../hooks/useFormatDate';
import { Portefolje } from '../../types';
import { DeleteWorklistButton } from '../deleteWorklistButton';

interface WorklistRowProps {
  portefolje: Portefolje;
}

export const WorkListRow: FC<WorklistRowProps> = ({ portefolje }) => {
  const { formatDate } = useFormatDate();
  const { t } = useTranslation();

  const {
    opprettetDato,
    endretDato,
    portefoljeId,
    navn,
    status,
    publisertDato
  } = portefolje;

  const isPublished = !!publisertDato;

  const routerButtonText = t(
    `expertToolsXMLPortfolio.worklists.table.${
      isPublished ? 'showList' : 'goToList'
    }`
  );

  const worklistStatus = isPublished ? 'publisert' : status;

  return (
    <tr key={portefoljeId}>
      <td>
        <Typography>{navn}</Typography>
      </td>
      <td>
        <Typography>{formatDate(opprettetDato)}</Typography>
      </td>
      <td>
        <Typography>{formatDate(endretDato)}</Typography>
      </td>
      <td>
        <Typography>
          {t(`expertToolsXMLPortfolio.worklists.status.${worklistStatus}`)}
        </Typography>
      </td>
      <td className="actions">
        <Stack spacing={2} direction="row">
          {!isPublished && <DeleteWorklistButton portefolje={portefolje} />}
          <RouterButtonLink
            to={getExpertToolsXMLPortfolioWorklistPath({
              portefoljeId
            })}
            variant="secondary"
          >
            {routerButtonText}
          </RouterButtonLink>
        </Stack>
      </td>
    </tr>
  );
};
