import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberField } from 'enova-frontend-components';

interface NumberOfWindowsProps {
  onChange: (floatValue: number | undefined) => void;
  value: number | undefined;
}

export const NumberOfWindows: FC<NumberOfWindowsProps> = ({
  onChange,
  value
}) => {
  const { t } = useTranslation();

  return (
    <NumberField
      autoComplete
      label={t('register.window.numberOfEqualWindows.label')}
      name="antall-like-vindu-samme-vegg"
      onValueChange={({ floatValue }) => onChange(floatValue)}
      value={value}
      suffix={t('pieces')}
    />
  );
};
