import { Box, Grid } from 'enova-frontend-components';
import { useParams } from 'react-router-dom';
import React, { FC, Fragment, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '../../../../../hooks/useMediaQuery';
import { camelCasify } from '../../../../../utils/navigation';
import { XMLParams } from '../types';

interface Props {
  header: ReactNode;
  registrationStep: ReactNode;
  stepper: ReactNode;
}

export const XmlRegistration: FC<Props> = ({
  header,
  registrationStep,
  stepper
}) => {
  const { step } = useParams<XMLParams>();
  const { xsScreen, smScreen } = useMediaQuery();
  const { t } = useTranslation();
  const displayStepper = !xsScreen && !smScreen;
  const helmetTitle = t(`expertToolsXML.step.${camelCasify(step)}`);

  return (
    <Fragment>
      <Helmet title={helmetTitle} />
      <Grid item md={10} lg={8} xl={6} m={3} p={3} alignSelf="center">
        <Box mb={10} width="100%">
          {header}
        </Box>
        <Box display="flex" gap={10}>
          {displayStepper && (
            <Grid item xs={12} md={4} lg={2} minWidth={220}>
              {stepper}
            </Grid>
          )}
          {registrationStep}
        </Box>
      </Grid>
    </Fragment>
  );
};
