import { useDispatch } from 'react-redux';

import {
  addVindu,
  editVindu,
  removeVindu
} from '../../../../../store/registration/actions';
import { Vindu } from '../../../../../types/registration/userInput';
import { createWindows } from '../utils';
import { VeggId } from '../../../utils/registerEnums';

export const useWindowOperations = (etasjeId: string, veggId: VeggId) => {
  const dispatch = useDispatch();

  const addWindow = (
    windowData: Omit<Vindu, 'id'>,
    veggsoneId: string,
    amount: number
  ) => {
    dispatch(
      addVindu({
        etasjeId: etasjeId,
        veggId: veggId,
        veggsoneId: veggsoneId,
        windows: createWindows(windowData, amount)
      })
    );
  };

  const removeWindow = (vinduIds: string[], veggsoneId: string) =>
    dispatch(
      removeVindu({
        etasjeId,
        veggId,
        veggsoneId: veggsoneId,
        vinduIds
      })
    );

  const editWindow = (
    vindu: Vindu,
    numberOfEqualWindows: number,
    vinduIds: string[],
    veggsoneId: string
  ) =>
    dispatch(
      editVindu({
        etasjeId,
        veggId,
        veggsoneId,
        windows: createWindows(vindu, numberOfEqualWindows),
        vinduIds
      })
    );

  return { addWindow, editWindow, removeWindow };
};
