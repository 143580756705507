import { Box, RadioGroup, styled, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Ensartet, Soner } from '../../../../../assets/icons/wall';
import { SvgRadioButton } from '../../../../svgRadioButton';

const RadioButtonsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
`;

interface Props {
  onSelect: (isUniformWall: boolean) => void;
  value: boolean | null;
}

export const UniformWallRadio: FC<Props> = ({ onSelect, value }) => {
  const { t } = useTranslation();
  return (
    <Box
      my={5}
      display="flex"
      flexDirection="column"
      gap={5}
      justifyContent="center"
    >
      <RadioGroup
        label={t('register.wallZone.radio.uniformWall.label')}
        marginBottom
        value={value}
        row
        onChange={(e, value) => {
          const bool = value === 'true';
          onSelect(bool);
        }}
      >
        <RadioButtonsWrapper>
          <SvgRadioButton
            label={
              <Box display="flex" flexDirection="row" gap={1}>
                <Ensartet />
                <Typography>
                  {t('register.wallZone.radio.uniformWall.uniform')}
                </Typography>
              </Box>
            }
            value={true}
          />
          <SvgRadioButton
            label={
              <Box gap={1} display="flex" flexDirection="row">
                <Soner />
                <Typography>
                  {t('register.wallZone.radio.uniformWall.zoned')}
                </Typography>
              </Box>
            }
            value={false}
          />
        </RadioButtonsWrapper>
      </RadioGroup>
    </Box>
  );
};
