import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useSnack
} from 'enova-frontend-components';

import useBuildingDetails from '../../hooks/useBuildingDetails';
import useFormatDate from '../../hooks/useFormatDate';
import {
  ExpertToolsXMLParams,
  RegistrationScreenParams
} from '../../utils/navigation';
import RouterButtonLink from '../routerButtonLink';
import { createErrorMessage } from '../../utils/utils';

import { useDeleteOpenRegistration } from './useDeleteOpenRegistration';
import { DeleteRegistrationDialog } from './deleteRegistrationDialog';
import { RegistrationType } from './models';

export type OpenRegistrationListItemProps = {
  registrationId: string;
  getPath: (
    params: Pick<
      RegistrationScreenParams | ExpertToolsXMLParams,
      'buildingId' | 'registrationId'
    >
  ) => string;
  registrationType: RegistrationType;
};

const OpenRegistrationListItem: FC<OpenRegistrationListItemProps> = ({
  registrationId,
  getPath,
  registrationType
}) => {
  const {
    building: { energiObjektId },
    simpleEnergyAnalyses
  } = useBuildingDetails();

  const { formatDate } = useFormatDate();
  const { t } = useTranslation();
  const { addSnack } = useSnack();

  const { deleteRegistration, isLoading } = useDeleteOpenRegistration(
    energiObjektId,
    registrationType
  );

  const [isDialogOpen, setDialogOpen] = useState(false);

  const confirmDeleteRegistration = () => {
    deleteRegistration(registrationId, {
      onSuccess: () => {
        setDialogOpen(false);
        addSnack(t('registration.delete.success'), {
          variant: 'success'
        });
      },
      onError: () => {
        addSnack(createErrorMessage(t('registration.delete.error')), {
          variant: 'error'
        });
      }
    });
  };

  const analysis = simpleEnergyAnalyses?.find(
    ({ registreringId }) => registreringId === registrationId
  );

  if (!analysis) return null;

  const {
    registrering: { opprettetDato }
  } = analysis;

  const date = formatDate(opprettetDato);

  return (
    <Fragment>
      <Grid item xs={12}>
        <Paper>
          <Box p={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm display="flex" alignItems="center">
                <Typography className="f-fill" bold>
                  {t('energyrating.continue.existing.registration', {
                    date
                  })}
                </Typography>
              </Grid>

              <Grid item xs={12} sm="auto" container spacing={3}>
                <Grid item xs={12} sm="auto">
                  <RouterButtonLink
                    fullWidth
                    variant="secondary"
                    to={getPath({
                      buildingId: energiObjektId,
                      registrationId
                    })}
                  >
                    {t('energyrating.continue.existing.continue')}
                  </RouterButtonLink>
                </Grid>

                <Grid item xs={12} sm="auto">
                  <Button
                    fullWidth
                    variant="secondary"
                    onClick={() => setDialogOpen(true)}
                  >
                    {t('energyrating.continue.existing.delete')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>

      <DeleteRegistrationDialog
        isOpen={isDialogOpen}
        isLoading={isLoading}
        createdDate={date}
        onClose={isLoading ? undefined : () => setDialogOpen(false)}
        onConfirm={confirmDeleteRegistration}
      />
    </Fragment>
  );
};

export default OpenRegistrationListItem;
