/* eslint-disable camelcase */
import { getYear } from 'date-fns';

import { RootState } from '../../../store/rootReducer';
import { RegistrationStep } from '../../../utils/navigation';
import { NullishValues } from '../../../utils/register';
import {
  KjelKaminVannbarenVarme,
  OppvarmingSol,
  OppvarmingVarmepumpe,
  TekniskUtstyr,
} from '../../../types/registration/userInput';
import { UserRole } from '../../../types/user';
import { formatArea } from '../../../utils/utils';

import {
  checkTeksniskUtstyrHasRomoppvarming,
  getRequiredFields,
} from './validationFields';
import {
  ByggType,
  BygningsForm,
  PlasseringAvEnhetIByggVertikalt,
} from './registerEnums';

export enum FieldError {
  None = 'none',
  Missing = 'missing',
  Invalid = 'invalid',
  Max = 'max',
  MaxProfessional = 'maxProfessional',
  Max10 = 'max10',
  Max100 = 'max100',
  MaxSum = 'maxsum',
  Min = 'min',
  Custom = 'custom'
}

export type NumberedFieldError = {
  fieldError: FieldError;
  number: number;
};

export type FieldErrorMap = Record<string, ValidatorError>;

export type NumberedFieldErrorMap = Record<string, NumberedFieldError>;

export type SelectorFunc = (state: RootState) => unknown;

export type ValidatorError = {
  error: FieldError;
  // Values are used to add context to the translation hook.
  // Example if values consists of {totalCalculatedArea: 20, totalOppgittBRA: 10} they can be used inside
  // the translation like 'This should be {{totalCalculatedArea}} and {{totalOppgittBRA}}' and result in
  // 'This should be 20 and 10'
  values?: Record<string, unknown>;
};
export type ValidatorFunc = (
  state: RootState,
  selector: SelectorFunc
) => ValidatorError;

export const MAX_NUMBER_OF_YEARS_IN_THE_FUTURE = 5;

interface CalculatedAreaOptions {
  area: number;
  allFloorsSameShape: boolean;
  antallEtasjer: number;
  harBoligenKjeller?: boolean | undefined;
  heleEllerDelerAvKjellerErOppvarmet?: boolean | undefined;
}

export const getTotalCalculatedArea = (options: CalculatedAreaOptions) => {
  const {
    area,
    allFloorsSameShape,
    antallEtasjer,
    harBoligenKjeller,
    heleEllerDelerAvKjellerErOppvarmet
  } = options;

  // Check if the building have a basement that needs to be calculated towards the total area
  const kjeller =
    harBoligenKjeller && heleEllerDelerAvKjellerErOppvarmet ? 1 : 0;

  // If allFloorsSameShape are true the area variable will contain area for one floor only.
  // To find the total calculated area for the whole building we need to multiply this area
  // with the amount of floors the user has registered.
  // If allFloorsSameShape are false the area variable will contain area for the whole building.
  const totalCalculatedArea = allFloorsSameShape
    ? area * (antallEtasjer + kjeller)
    : area;

  return totalCalculatedArea;
};

export const harBoligenKjellerValidator: ValidatorFunc = (state, selector) => {
  const harBoligenKjellerValue = selector(state);

  const byggType = state.register?.userInput?.bygningsdetaljer?.byggType;
  const plasseringAvEnhetIByggVertikalt =
    state.register?.userInput?.bygningsdetaljer
      ?.plasseringAvEnhetIByggVertikalt;

  if (
    byggType === ByggType.Leilighet &&
    plasseringAvEnhetIByggVertikalt != null &&
    plasseringAvEnhetIByggVertikalt !==
      PlasseringAvEnhetIByggVertikalt.NedersteEtasje
  ) {
    return { error: FieldError.None };
  }

  if (harBoligenKjellerValue == null) {
    return { error: FieldError.Missing };
  }

  return { error: FieldError.None };
};

export const byggArValidator: ValidatorFunc = (state, selector) => {
  const value = selector(state);
  const isProfessional = state.register.user?.rolle === UserRole.PROFESJONELL;

  if (!value) return { error: FieldError.Missing };
  if (typeof value !== 'number' || String(value).length !== 4)
    return { error: FieldError.Invalid };

  const currentYear = getYear(Date.now());

  if (!isProfessional && value > currentYear) {
    return { error: FieldError.Max };
  }

  if (value > currentYear + MAX_NUMBER_OF_YEARS_IN_THE_FUTURE)
    return { error: FieldError.MaxProfessional };

  return { error: FieldError.None };
};

export const yearValidator: ValidatorFunc = (state, selector) => {
  const value = selector(state);
  const byggAr = state.register?.userInput?.bygningsdetaljer?.byggAr;
  const isProfessional = state.register.user?.rolle === UserRole.PROFESJONELL;

  if (!value) return { error: FieldError.Missing };
  if (typeof value !== 'number' || String(value).length !== 4)
    return { error: FieldError.Invalid };

  const currentYear = getYear(Date.now());

  if (!isProfessional && value > currentYear) {
    return { error: FieldError.Max };
  }

  if (value > currentYear + MAX_NUMBER_OF_YEARS_IN_THE_FUTURE)
    return { error: FieldError.MaxProfessional };
  if (byggAr && value < byggAr) return { error: FieldError.Min };

  return { error: FieldError.None };
};

const antallEtasjerValidator: ValidatorFunc = (state, selector) => {
  const value = selector(state);
  if (!value || typeof value !== 'number') return { error: FieldError.Missing };
  return value > 10
    ? { error: FieldError.Invalid }
    : { error: FieldError.None };
};

const numberValidator: ValidatorFunc = (state, selector) => {
  const value = selector(state);
  if (!value || typeof value !== 'number') return { error: FieldError.Missing };
  return value <= 0
    ? { error: FieldError.Invalid }
    : { error: FieldError.None };
};

const bygningsFormValidator: ValidatorFunc = (state, selector) => {
  const value = selector(state) as BygningsForm;
  return !value || !Object.values(BygningsForm).includes(value)
    ? { error: FieldError.Missing }
    : { error: FieldError.None };
};

const objValidator: ValidatorFunc = (state, selector) => {
  const value = selector(state);

  return !value || typeof value !== 'object' || Object.keys(value).length === 0
    ? { error: FieldError.Missing }
    : { error: FieldError.None };
};

const kjelKaminValidator: ValidatorFunc = (state, selector) => {
  const value = selector(state) as KjelKaminVannbarenVarme;
  return !('gulvvarme' in value) && !('radiator' in value)
    ? { error: FieldError.Invalid }
    : { error: FieldError.None };
};

const oppvarmingVarmepumpeValidator: ValidatorFunc = (state, selector) => {
  const value = selector(state) as OppvarmingVarmepumpe;
  return !('punktoppvarming' in value) &&
    !('vannbarenVarme' in value) &&
    !('ventilasjon' in value)
    ? { error: FieldError.Invalid }
    : { error: FieldError.None };
};

export const calculatedAreaValidator: ValidatorFunc = (state) => {
  const bygningsdetaljer = state.register.userInput.bygningsdetaljer;
  const allFloorsSameShape = state.register.allFloorsSameShape;

  const antallEtasjer = bygningsdetaljer.antallEtasjerUnntattKjeller ?? 0;
  const totalOppvarmetBRA = bygningsdetaljer.totalOppvarmetBRA ?? 0;
  const calculatedArea = state.register.calculatedArea;

  let error = FieldError.None;

  if (calculatedArea == null) {
    return { error };
  }

  let area = 0;
  // Summarize all floor areas to get the total calculated area
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  for (const [_, value] of Object.entries(calculatedArea)) {
    // If one floor dont have a value we can assume that something bad has happened.
    // The user should still be able to continue with the registration as the area
    // of the building is also calculated on the backend. The user will then still get
    // feedback if the calculated area is different than the stated area in bygningsDetaljer.
    if (value == null) {
      return { error };
    }

    area += value;
  }

  const totalCalculatedArea = getTotalCalculatedArea({
    allFloorsSameShape,
    antallEtasjer,
    area: area,
    harBoligenKjeller: bygningsdetaljer.harBoligenKjeller,
    heleEllerDelerAvKjellerErOppvarmet:
      bygningsdetaljer.heleEllerDelerAvKjellerErOppvarmet
  });

  // Upper and lower treshold for how much the calculated area can deviate from totalOppvarmetBRA
  const totalOppvarmetBRALowerTreshold = 0.98 * totalOppvarmetBRA;
  const totalOppvarmetBRAUpperTreshold = 1.02 * totalOppvarmetBRA;

  if (
    totalCalculatedArea > totalOppvarmetBRAUpperTreshold ||
    totalCalculatedArea < totalOppvarmetBRALowerTreshold
  ) {
    error = FieldError.Invalid;
  }

  return {
    error,
    values: {
      totalCalculatedArea: formatArea(totalCalculatedArea),
      totalOppvarmetBRA
    }
  };
};

export const oppvarmetArealValidator: ValidatorFunc = (state, selector) => {
  const value = selector(state);
  const areal = state.register?.userInput?.bygningsdetaljer?.totalBRA;
  if (!value) return { error: FieldError.Missing };
  if (typeof value !== 'number') return { error: FieldError.Invalid };
  if (areal && value > areal) return { error: FieldError.Custom };
  return { error: FieldError.None };
};

export const etasjeHoydeValidator: ValidatorFunc = (state, selector) => {
  const value = selector(state);
  if (!value) return { error: FieldError.Missing };
  if (typeof value !== 'number') return { error: FieldError.Invalid };
  if (value < 1.7) return { error: FieldError.Min };
  if (value > 50) return { error: FieldError.Max };
  return { error: FieldError.None };
};

export const arealValidator: ValidatorFunc = (state, selector) => {
  const value = selector(state);
  if (!value) return { error: FieldError.Missing };
  if (typeof value !== 'number' || String(value).length < 1)
    return { error: FieldError.Invalid };
  return { error: FieldError.None };
};

export const maxValueValidator: ValidatorFunc = (state, selector) => {
  const value = selector(state);
  if (value && typeof value === 'number' && value > 1)
    return { error: FieldError.Max };
  return { error: FieldError.None };
};

export const maxValue10Validator: ValidatorFunc = (state, selector) => {
  const value = selector(state);
  if (value && typeof value === 'number' && value > 10)
    return { error: FieldError.Max10 };
  return { error: FieldError.None };
};

export const maxValue100Validator: ValidatorFunc = (state, selector) => {
  const value = selector(state);
  if (value && typeof value === 'number' && value > 100)
    return { error: FieldError.Max100 };
  return { error: FieldError.None };
};

const tekniskUtstyrValidator: ValidatorFunc = (state, selector) => {
  const value = selector(state) as TekniskUtstyr;

  if (!value || typeof value !== 'object' || Object.keys(value).length === 0)
    return { error: FieldError.Missing };
  if (
    Object.keys(value).length > 0 &&
    !checkTeksniskUtstyrHasRomoppvarming(value)
  )
    return { error: FieldError.Custom };

  return { error: FieldError.None };
};

const oppvarmingSolValidator: ValidatorFunc = (state, selector) => {
  const value = selector(state) as OppvarmingSol;
  if (!value || typeof value !== 'object' || Object.keys(value).length === 0)
    return { error: FieldError.Missing };
  if (value.solceller === false && !('solfangerMedVannbarenVarme' in value))
    return { error: FieldError.Custom };
  return { error: FieldError.None };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const takKonstruksjonsTypeValidator: ValidatorFunc = (state, _) => {
  const value =
    state.register?.userInput.bygningsdetaljer.tak?.takKonstruksjonsType;
  const error = !value ? FieldError.Missing : FieldError.None;

  return { error };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const isolasjonstykkelseIMillimeterValidator: ValidatorFunc = (state, _) => {
  const value =
    state.register?.userInput.bygningsdetaljer.tak
      ?.isolasjonstykkelseIMillimeter;
  const error = value == null ? FieldError.Missing : FieldError.None;

  return { error };
};

export const validators: Record<string, ValidatorFunc> = {
  takKonstruksjonsType: takKonstruksjonsTypeValidator,
  isolasjonstykkelseIMillimeter: isolasjonstykkelseIMillimeterValidator,
  calculatedArea: calculatedAreaValidator,
  harBoligenKjeller: harBoligenKjellerValidator,
  byggAr: byggArValidator,
  antallEtasjerUnntattKjeller: antallEtasjerValidator,
  tekniskUtstyr: tekniskUtstyrValidator,
  oppvarmingElektrisk: objValidator,
  oppvarmingFjernvarme: objValidator,
  oppvarmingBioenergi: objValidator,
  oppvarmingOlje: objValidator,
  oppvarmingGass: objValidator,
  oppvarmingSol: oppvarmingSolValidator,
  elKjel: kjelKaminValidator,
  gassKjel: kjelKaminValidator,
  bioKjel: kjelKaminValidator,
  bioKamin: kjelKaminValidator,
  oljeKjel: kjelKaminValidator,
  vannbarenVarmeVarmepumpe: kjelKaminValidator,
  solfangerMedVannbarenVarme: kjelKaminValidator,
  totalOppvarmetBRA: oppvarmetArealValidator,
  totalBRA: arealValidator,
  oppvarmingVarmepumpe: oppvarmingVarmepumpeValidator,
  varmetapsfaktor_uoppv: maxValueValidator,
  tempvirkningsgrad_varmegjenvinner: maxValueValidator,
  lengdeIMeter: numberValidator,
  bygningsForm: bygningsFormValidator,
  gjennomsnittligEtasjehoyde: etasjeHoydeValidator,
  // Datoer
  gassKjelInstallasjonsAr: yearValidator,
  bioKjelInstallasjonsAr: yearValidator,
  bioKaminInstallasjonsAr: yearValidator,
  elKjelInstallasjonsAr: yearValidator,
  oljeKjelInstallasjonsAr: yearValidator,
  solfangerInstallasjonsAr: yearValidator,
  varmepumpeVannbarenInstallasjonsAr: yearValidator,
  varmepumpePunktInstallasjonsAr: yearValidator,
  varmepumpeVentilasjonInstallasjonsAr: yearValidator,
  gulvRehabiliteringAr: yearValidator,
  veggRehabiliteringAr: yearValidator
};

const isValid = (
  state: RootState,
  field: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): ValidatorError => {
  const validator = validators[field as keyof typeof validators];

  if (validator) {
    return validator(state, () => value);
  }

  if (NullishValues.includes(value)) {
    return { error: FieldError.Missing };
  }

  return { error: FieldError.None };
};

export const getHasErrors = (
  state: RootState,
  step?: RegistrationStep
): boolean => {
  const requiredFields = getRequiredFields(state, step);

  if (!requiredFields) {
    return false;
  }

  return Object.entries(requiredFields).some(([field, value]) => {
    const { error } = isValid(state, field, value);

    return error !== FieldError.None;
  });
};

export const getInvalidFields = (
  state: RootState,
  step?: RegistrationStep
): FieldErrorMap | undefined => {
  if (!step) return undefined;

  const requiredFields = getRequiredFields(state, step);

  if (!requiredFields) {
    return undefined;
  }

  return Object.entries(requiredFields).reduce(
    (acc: FieldErrorMap, [field, value]) => {
      const validatorError = isValid(state, field, value);

      return validatorError.error === FieldError.None
        ? acc
        : { ...acc, [field]: validatorError };
    },
    {}
  );
};
