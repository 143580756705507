import React, { FC, Fragment } from 'react';
import { Box, Grid, Loader } from 'enova-frontend-components';

import {
  BuildingDetails,
  BuildingSearch
} from '../../../../../../types/building';
import { ErrorBox } from '../../../../../../components/errorBox';

import { SearchMatchLink } from './searchMatchLink';
import { MissingUnitBox } from './missingUnitBox';

interface SearchMatchGroupContentProps {
  content: BuildingDetails[] | undefined;
  error: unknown;
  fetch: () => Promise<unknown>;
  loading: boolean;
  queryParams: Partial<BuildingSearch>;
}

export const SearchMatchGroupContent: FC<SearchMatchGroupContentProps> = ({
  content,
  error,
  fetch,
  loading,
  queryParams
}) => {
  if (loading) {
    return (
      <Box p={8} display="flex" justifyContent="center" width={1}>
        <Loader size="large" />
      </Box>
    );
  }

  if (error) {
    return (
      <ErrorBox
        onRetry={fetch}
        titleKey="search.error.title"
        descriptionKey="search.error.description"
      />
    );
  }

  if (!content || content?.length === 0) {
    return <MissingUnitBox queryParams={queryParams} />;
  }

  return (
    <Fragment>
      <Grid container spacing={2} p={{ xs: 2, sm: 3 }}>
        {content?.map((item) => (
          <Grid
            container
            display="flex"
            item
            key={item.energiObjektId}
            spacing={2}
            xs={12}
          >
            <SearchMatchLink building={item} minimizedView />
          </Grid>
        ))}
        <MissingUnitBox
          queryParams={{
            bygningsnummer: content?.[0].bygningsNummer ?? '',
            ...queryParams
          }}
        />
      </Grid>
    </Fragment>
  );
};
