import React, { FC } from 'react';
import { Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { SoknadSteg } from '../../../../../../../components/registering/utils/registerEnums';
import PassFailIndicator from '../../../../../../../components/passFailIndicator';
import { SupportLevel } from '../../../../../../../types/soknad';

import { getNumberFromLevel } from './utils';

interface QualificationSummaryProps {
  soknadSteg?: SoknadSteg;
  godkjentNiva?: SupportLevel;
}

export const QualificationSummary: FC<QualificationSummaryProps> = ({
  soknadSteg,
  godkjentNiva
}) => {
  const { t } = useTranslation();

  if (soknadSteg !== SoknadSteg.Steg2 && soknadSteg !== SoknadSteg.Steg3) {
    return null;
  }

  return (
    <Grid alignItems="center" container display="flex" item spacing={4} xs={12}>
      <Grid item xs="auto">
        <PassFailIndicator pass={!!godkjentNiva} />
      </Grid>

      <Grid item xs>
        <Typography variant="h3">
          {t(
            `register.summary.tilstandsrapport.criteria.${soknadSteg}.heading`,
            {
              context: godkjentNiva ? 'pass' : 'fail'
            }
          )}
        </Typography>

        <Typography>
          {t(
            `register.summary.tilstandsrapport.criteria.${soknadSteg}.description`,
            {
              context: godkjentNiva ? 'pass' : 'fail',
              level: getNumberFromLevel(godkjentNiva)
            }
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};
