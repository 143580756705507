/* eslint-disable max-len */
import { UserInput } from '../types/registration/userInput';
import {
  EnergyPlan,
  Registration,
  SimpleEnergyAnalysis
} from '../types/building';

// TODO: Add more values when backend supports them
export enum UserInputErrorType {
  // BYGNINGSFORM, HIMMELRETNING, VEGGER OG VINDUER
  BygningsFormIkkeSatt = 'bygningsFormIkkeSatt',
  GjennomsnittligEtasjehoydeIkkeSatt = 'gjennomsnittligEtasjehoydeIkkeSatt',
  GlasstypeOgKarmtypeBeggeEllerIngen = 'glasstypeOgKarmtypeBeggeEllerIngen',
  IngenVerdierValgtForVeggkonstruksjon = 'ingenVerdierValgtForVeggkonstruksjon',
  IngenVinduerDetaljert = 'ingenVinduerDetaljert',
  LengdeIkkeSattForVegg = 'lengdeIkkeSattForVegg',
  LengdeIkkeSattForVeggSone = 'lengdeIkkeSattForVeggSone',
  TilliggendeRomIkkeSattForVeggSone = 'tilliggendeRomIkkeSattForVeggSone',
  VeggKonstruksjonOgIsolasjonstykkelseBeggeEllerIngen = 'veggKonstruksjonOgIsolasjonstykkelseBeggeEllerIngen',
  TotalEtasjeArealStorreEnnOppvarmetAreal = 'totalEtasjeArealStorreEnnOppvarmetAreal',
  TotalEtasjeArealMindreEnnOppvarmetAreal = 'totalEtasjeArealMindreEnnOppvarmetAreal',
  GjennomsnittligEtasjehoydeUnderMinimum = 'gjennomsnittligEtasjehoydeUnderMinimum',
  LengdeForVeggSonerStemmerIkke = 'lengdeForVeggSonerStemmerIkke',
  VinduArealStorreEnnVegg = 'vinduArealStorreEnnVegg',
  VinduArealStorreEnnVeggSone = 'vinduArealStorreEnnVeggSone',

  // GULV
  ArealIkkeSattForGulvsone = 'arealIkkeSattForGulvsone',
  GulvKonstruksjonOgIsolasjonstykkelseBeggeEllerIngen = 'gulvKonstruksjonOgIsolasjonstykkelseBeggeEllerIngen',
  IngenVerdierValgtForGulvkonstruksjon = 'ingenVerdierValgtForGulvkonstruksjon',
  TilliggendeRomIkkeSattForGulvSone = 'tilliggendeRomIkkeSattForGulvSone',
  EtasjeOgGulvArealStemmerIkke = 'etasjeOgGulvArealStemmerIkke',

  // OTHER
  KanIkkeFaKarakterenAUtenLekkasjetall = 'kanIkkeFaKarakterenAUtenLekkasjetall',
  IkkeEkspertKreverLekkasjeDato = 'ikkeEkspertKreverLekkasjeDato',

  // NOT RELEVANT
  /**
   * Windows can't be added without area
   */
  ArealIkkeSattForVindu = 'arealIkkeSattForVindu',
  /**
   * Walls are added when shape is selected
   */
  IngenVeggerDetaljert = 'ingenVeggerDetaljert',
  /**
   * All walls get 1 zone upon initialization, and this zone can't be removed
   */
  IngenVeggsoneSattForVegg = 'ingenVeggsoneSattForVegg',
  /**
   * Can't add windows without all relevant values specified
   */
  IngenVerdierValgtForVindu = 'ingenVerdierValgtForVindu',
  /**
   * Walls are initialized with VeggId, so VeggId always exists.
   * Direction is set when shape is selected and if the user rotate the building/floor.
   * Direction is only missing when shape is alsomissing, and this case has its own error type.
   */
  VeggIdEllerHimmelretningIkkeSatt = 'veggIdEllerHimmelretningIkkeSatt'
}

export type UserInputErrorInformation = {
  generateAllFloors: boolean;
  generateWallsOnEntireFloor: boolean;
  generateWindowsOnEntireFloor: boolean;
  ids: { id: string; type: string }[];
  errorType: UserInputErrorType;
};

export type UpdateRegistrationDTO = {
  userInput: UserInput;
  userInputErrorInformation: UserInputErrorInformation[];
};

export type SubmitRegistrationDTO = {
  energiplan?: EnergyPlan;
  userInput: UserInput;
  userInputErrorInformation: UserInputErrorInformation[];
};

export type PublishRegistrationDTO = EnergyPlan;

type Energianalyse = {
  registreringId: string;
  registrering: Registration;
  energiplan: EnergyPlan;
};

export type XMLResponse = {
  energiAnalysePrivatBolig?: Energianalyse;
  energiAnalyseYrkesBygg?: Energianalyse;
  energiAnalyseFlerboligbygg?: Energianalyse[];
  error?: string;
  jsonInput?: string;
};

export type Flerboligbygg = {
  flerboligbyggId: string;
  navn: string;
};

export type UserEnergianalyseResponse = {
  flerboligbyggEnergiAnalyser: FlerboligRegistrationResponse[];
  energiAnalyser: SimpleEnergyAnalysis[];
};

export type FlerboligRegistrationResponse = {
  flerboligbygg: Flerboligbygg;
  energianalyser?: SimpleEnergyAnalysis[];
};

export type XMLFlerboligResponse = {
  energiAnalyseFlerboligBygg: FlerboligRegistrationResponse;
  error?: string;
};

export type CreateBuildingPayload = {
  gatenavn: string | null;
  gatenummer: string | null;
  gatebokstav: string | null;
  postnummer: string;
  poststed: string;
  kommunenummer: string;
  kommunenavn: string;
  gardsnummer: number | null;
  bruksnummer: number | null;
  seksjonsnummer: number | null;
  bruksenhetsnummer: string | null;
  festenummer: string | null;
  bygningsnummer: string | null;
  organisasjonsnummer: string | null;
  andelsnummer: number | null;
  bygningstype: number | null;
};

// Types for companyname search from brreg-api: https://data.brreg.no/enhetsregisteret/api/docs/index.html#enheter-sok-detaljer-navnesok
export interface Self {
  href: string;
}

export interface Links {
  self: Self;
}

export interface Organisasjonsform {
  kode: string;
  beskrivelse: string;
  _links: Links;
}

export interface Forretningsadresse {
  land: string;
  landkode: string;
  poststed: string;
  adresse: string[];
  postnummer: string;
  kommune: string;
  kommunenummer: string;
}

export interface Naeringskode1 {
  beskrivelse: string;
  kode: string;
}

export interface InstitusjonellSektorkode {
  kode: string;
  beskrivelse: string;
}

export interface Naeringskode2 {
  beskrivelse: string;
  kode: string;
  hjelpeenhetskode: boolean;
}

export interface Enhet {
  antallAnsatte: number;
  forretningsadresse: Forretningsadresse;
  hjemmeside: string;
  institusjonellSektorkode: InstitusjonellSektorkode;
  konkurs: boolean;
  maalform: string;
  navn: string;
  naeringskode1: Naeringskode1;
  naeringskode2: Naeringskode2;
  organisasjonsnummer: string;
  organisasjonsform: Organisasjonsform;
  registreringsdatoEnhetsregisteret: string;
  registrertIMvaregisteret: boolean;
  registrertIForetaksregisteret: boolean;
  registrertIStiftelsesregisteret: boolean;
  registrertIFrivillighetsregisteret: boolean;
  sisteInnsendteAarsregnskap: string;
  stiftelsesdato: string;
  underAvvikling: boolean;
  underTvangsavviklingEllerTvangsopplosning: boolean;
  _links: Links;
}

export interface Embedded {
  enheter: Enhet[];
}

export interface Page {
  size: number;
  totalElements: number;
  totalPages: number;
  number: number;
}

export interface CompanySearchResponse {
  _embedded: Embedded;
  _links: Links;
  page: Page;
}

export type SearchOptions = {
  page?: string;
  hitsPerPage?: string;
  fuzzySearch?: boolean;
};

export type NavnSokResponse = {
  kommuner: Array<{
    kommunenummer?: string;
    kommunenavnNorsk?: string;
  }>;
};

export type NummerSokResponse = {
  kommunenavnNorsk?: string;
  kommunenummer?: string;
};
