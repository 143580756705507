import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, RadioGroup } from 'enova-frontend-components';

interface Props {
  onChange: (value: boolean) => void;
  value: boolean;
}
export const AllWallsAgainstTheOpenRadio: FC<Props> = ({ onChange, value }) => {
  const { t } = useTranslation();

  return (
    <RadioGroup
      label={t('register.wallsAndIsolation.allWallsAgainstTheOpen.label')}
      marginBottom
      row
      value={value}
      onChange={(_, val) => onChange(val === 'true')}
    >
      <Radio label={t('yes')} value={true} width="auto" />
      <Radio label={t('no')} value={false} width="auto" />
    </RadioGroup>
  );
};
