import { useLocation } from 'react-router-dom';

import { BuildingDetails } from '../../../../../../types/building';
import { useXmlRegistrationSearchParams } from '../../common/hooks/useXmlRegistrationSearchParams';
import { useXmlStepNavigation } from '../../common/hooks/useXmlStepNavigation';

export const useBuildingSelectFlerbolig = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { setBuildingSelection, selectedBuildingIds } =
    useXmlRegistrationSearchParams();

  const { navigateToStep } = useXmlStepNavigation();

  const toggleMultiSelect = () => {
    // Reset selection if multiple units is selected.
    if (selectedBuildingIds.length > 1) {
      searchParams.delete('valgte-bygg');
    }

    navigateToStep('velg-bygg', 'enhet');
  };

  const onSelect = (building: BuildingDetails) => {
    const { bruksenhetsIdentId, energiObjektId } = building;
    const buildingIsSelected = selectedBuildingIds.includes(bruksenhetsIdentId);

    if (buildingIsSelected) {
      // Remove building from selection
      const filteredList = selectedBuildingIds.filter(
        (id) => id !== bruksenhetsIdentId
      );

      setBuildingSelection(filteredList, {
        energiobjektId: energiObjektId
      });
      return;
    }

    // Add building to selection
    const updatedSelection = [...selectedBuildingIds, bruksenhetsIdentId];

    setBuildingSelection(updatedSelection, {
      energiobjektId: energiObjektId
    });
  };

  return {
    onSelect,
    toggleMultiSelect
  };
};
