import { useQuery } from 'react-query';

import { getSearchAddress } from '../../../../../services/api';
import { queryKeys } from '../../../../../utils/react-query';

// useSimpleSearch fires two queries in parallel. The second query has appended
// a '*'. Not exactly sure what it does with the result from the endpoint but
// maybe it widens the search.

export const useSimpleSearch = (query: string | null) => {
  const queryOptions = { enabled: !!query };

  const sanitizedQuery = query?.replaceAll('*', '');

  const {
    error: firstError,
    isLoading: firstIsLoading,
    data: firstData,
    refetch: firstRefetch
  } = useQuery(
    [queryKeys.search, sanitizedQuery],
    () => getSearchAddress(sanitizedQuery, { fuzzySearch: true }),
    {
      ...queryOptions
    }
  );

  const {
    error: secondError,
    isLoading: secondIsLoading,
    data: secondData,
    refetch: secondRefetch
  } = useQuery(
    [queryKeys.search, sanitizedQuery, '*'],
    () => getSearchAddress(sanitizedQuery + '*'),
    { ...queryOptions }
  );

  const search = () => {
    firstRefetch();
    secondRefetch();
  };

  const firstResults = firstData?.adresser ?? [];
  const secondResults = secondData?.adresser ?? [];

  // The first query results have priority over the second. If no data
  // is returned from either just return an empty array.
  const results =
    firstResults.length > 0
      ? firstResults
      : secondResults.length > 0
      ? secondResults
      : [];

  return {
    results: query ? results : undefined,
    search,
    error: !!firstError && !!secondError,
    loading: firstIsLoading || secondIsLoading
  };
};
