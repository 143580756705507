import { useState } from 'react';

import useRegistrationForm from '../../../screens/registration/useRegistrationForm';
import { FieldError } from '../utils/validation';

export const useAddWindowDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { startedObjectErrors, setStartedObjectErrors } = useRegistrationForm();

  const openAddNewWindowDialog = () => {
    setIsDialogOpen(true);

    if ('window' in startedObjectErrors) {
      const numberWindows = startedObjectErrors.window.number;
      const newWindow = {
        fieldError: FieldError.Invalid,
        number: numberWindows + 1
      };

      setStartedObjectErrors({
        ...startedObjectErrors,
        window: newWindow
      });
    } else {
      setStartedObjectErrors({
        window: { fieldError: FieldError.Invalid, number: 1 }
      });
    }
  };

  const removeWindowFromStartedObjects = () => {
    const numberWindows = startedObjectErrors?.window?.number ?? 0;

    if (numberWindows > 1) {
      const newWindow = {
        fieldError: FieldError.Invalid,
        number: numberWindows - 1
      };

      setStartedObjectErrors({ ...startedObjectErrors, window: newWindow });
    } else {
      setStartedObjectErrors({});
    }

    setIsDialogOpen(false);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    removeWindowFromStartedObjects();
  };

  return {
    closeDialog,
    isDialogOpen,
    open: openAddNewWindowDialog,
    cancel: removeWindowFromStartedObjects
  };
};
