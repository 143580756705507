import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';

import { FloorWrapper, StyledPaper } from '../styledComponents';

interface Props {
  title: string;
  onEdit: () => void;
}

export const FloorEmptyState: FC<Props> = ({ onEdit, title }) => {
  const { t } = useTranslation();

  return (
    <StyledPaper>
      <FloorWrapper>
        <Typography variant="h4">{title}</Typography>
        <Typography>{t('register.buildingShape.noData')}</Typography>
        <Box py={5}>
          <Button onClick={onEdit}>
            {t('register.buildingShape.detailFloor')}
          </Button>
        </Box>
      </FloorWrapper>
    </StyledPaper>
  );
};
