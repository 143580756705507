import React, { FC } from 'react';
import { NumberField } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { WindowFormFieldProps } from '../utils';

export const WindowArea: FC<WindowFormFieldProps> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      rules={{
        validate: (value) => (value && value > 0) || t('required')
      }}
      name="windowData.window.arealIKvm"
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error }
      }) => (
        <NumberField
          {...rest}
          error={!!error}
          helperText={error?.message}
          autoComplete
          label={t('register.window.vindusarealIKvm')}
          name="vindusareal-i-kvm"
          value={value ?? null}
          marginBottom
          onValueChange={({ floatValue }) => onChange(floatValue)}
          suffix={t('suffix.m2')}
        />
      )}
    />
  );
};
