import React from 'react';

import { Upload } from '../../common/steps/upload';
import { useHandleCreateEnhetRegistration } from '../hooks/useHandleCreateEnhetRegistration';

export const EnhetUpload = () => {
  const handleRegistrationHook = useHandleCreateEnhetRegistration();

  return (
    <Upload
      registrationType="enhet"
      handleCreateRegistrationHook={handleRegistrationHook}
    />
  );
};
