import { HeatingGrade, styled, Table } from 'enova-frontend-components';

// TODO: Add when bata is available
export enum StatisticsScreenViews {
  OVER_TIME = 'over-tid',
  BUILDING_TYPE = 'byggtype',
  ENERGY_GRADE = 'energikarakter'
  // CALCULATION_TYPE = 'registreringstype',
  // BUILDING_CODE = 'byggtekniskforskrift',
}

export const StyledStatisticsTableBase = styled(Table)`
  padding-bottom: ${({ theme }) => theme.spacing()};
  padding-right: ${({ theme }) => theme.spacing()};

  thead tr th {
    border-bottom: 0;

    &:first-child {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
      border-right: ${({ theme }) =>
        `thin solid ${theme.palette.divider}`} !important;
    }

    &:last-child {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    }
  }

  tbody tr td {
    &:first-child {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
      border-right: ${({ theme }) =>
        `thin solid ${theme.palette.divider}`} !important;
      background: ${({ theme }) => theme.palette.background.paper};
    }

    &:last-child {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    }
  }
` as typeof Table;

// TABLES WITHOUT TOTAL
export const StyledStatisticsTableByYear = styled(StyledStatisticsTableBase)`
  tbody tr td {
    border-bottom: 0;
  }
` as typeof Table;

export const StyledStatisticsTableByBuildingType = styled(
  StyledStatisticsTableByYear
)`
  thead tr th:first-child {
    width: 175px;
    min-width: 175px;
  }
` as typeof Table;

// TABLES WITH TOTAL
export const StyledStatisticsTableTotal = styled(StyledStatisticsTableBase)`
  tbody tr:last-child td {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    background: ${({ theme }) => theme.palette.background.paper};
    border-top: ${({ theme }) =>
      `thin solid ${theme.palette.divider}`} !important;

    &:first-child {
      border-top: none !important;
    }
  }
` as typeof Table;

export const StyledStatisticsTableByEnergikarakter = styled(
  StyledStatisticsTableTotal
)`
  tbody tr td {
    border-bottom: 0;
  }
` as typeof Table;

export const StyledStatisticsTableByOppvarmingskarakter = styled(
  StyledStatisticsTableTotal
)`
  thead tr th:first-child {
    border-right: none !important;
    min-width: 125px;
    width: 125px;
    max-width: 125px;
  }

  tbody tr {
    td {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};

      &:first-child {
        border-bottom: 0;
        border-right: ${({ theme }) =>
          `thin solid ${theme.palette.divider}`} !important;
      }

      &:nth-child(2) {
        background: ${({ theme }) =>
          theme.palette.heatingGrade[HeatingGrade.Green]};
        color: ${({ theme }) => theme.palette.primary.contrastText};
      }

      &:nth-child(3) {
        background: ${({ theme }) =>
          theme.palette.heatingGrade[HeatingGrade.Lightgreen]};
      }

      &:nth-child(4) {
        background: ${({ theme }) =>
          theme.palette.heatingGrade[HeatingGrade.Yellow]};
      }

      &:nth-child(5) {
        background: ${({ theme }) =>
          theme.palette.heatingGrade[HeatingGrade.Orange]};
        color: ${({ theme }) => theme.palette.primary.contrastText};
      }

      &:nth-child(6) {
        background: ${({ theme }) =>
          theme.palette.heatingGrade[HeatingGrade.Red]};
        color: ${({ theme }) => theme.palette.primary.contrastText};
      }
    }

    &:last-child td {
      border-bottom: 0;
      background: ${({ theme }) => theme.palette.background.paper};
      border-top: ${({ theme }) =>
        `thin solid ${theme.palette.divider}`} !important;
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
` as typeof Table;
