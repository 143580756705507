import type { X2jOptionsOptional } from 'fast-xml-parser';

import { XMLReport } from '../../types/expertToolsXML';

// TODO: Check if this can be replaced with useBuildingIdentifier
export const getBuildingIdentifier = (report: XMLReport): string => {
  const adresseInfo = report.Enheter.Enhet.Adresser.BygningsAdresse.AdresseInfo;
  return [
    adresseInfo?.KommuneNr,
    adresseInfo?.GNr,
    adresseInfo?.BNr,
    adresseInfo?.SNr
  ]
    .map((i) => i || '-')
    .join('/');
};

const tagValueProcessor = ((tagName: string, tagValue: string) => {
  if (tagName === 'Postnummer') return undefined;
  return tagValue;
}) as () => string;

export const XMLParserOptions: X2jOptionsOptional = {
  ignoreDeclaration: true,
  tagValueProcessor
};
