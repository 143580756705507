import React, { FC } from 'react';
import { Box, IconButton, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { LoadingIndicator } from '../../../../../../../components/loadingIndicator';
import FileUpload from '../../../../../../../components/fileUpload/fileUpload';
import { FileType } from '../../../../../../../components/fileUpload/utils';

interface Props {
  setFile: (file?: File) => void;
  file?: File;
  isLoading: boolean;
}

export const FileUploadHandler: FC<Props> = ({ isLoading, file, setFile }) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <LoadingIndicator text={t('expertToolsXML.step.lastOppFil.loading')} />
    );
  }

  if (!file) {
    return (
      <FileUpload
        setFile={setFile}
        disabled={isLoading}
        acceptedFileTypes={[FileType.XML]}
        ButtonProps={{ variant: 'secondary' }}
      >
        {t('expertToolsXML.selectFile')}
      </FileUpload>
    );
  }

  return (
    <Box display="flex" alignItems="center" gap={3}>
      <Typography>{file.name}</Typography>
      <IconButton
        icon="delete"
        aria-label={t('delete')}
        onClick={() => setFile(undefined)}
      />
    </Box>
  );
};
