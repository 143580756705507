import { Typography } from 'enova-frontend-components';
import { FC } from 'react';

interface HeadingProps {
  heading: string;
}

export const Heading: FC<HeadingProps> = ({ heading }) => {
  return (
    <Typography variant="h4" component="h5" gutterBottom>
      {heading}
    </Typography>
  );
};
