import { shallowEqual } from 'react-redux';

import { DetailingStep } from '../../../../utils/registerEnums';
import useSelector from '../../../../../../hooks/useSelector';
import { getDetailingSteps } from '../../../../../../store/registration/selectors';

import { StepCheckBox } from './stepCheckBox';

export const VinduerCheckBox = () => {
  const detailingSteps = useSelector(getDetailingSteps, shallowEqual);

  // Disable Vinduer if Vegger is not checked
  const veggerIsChecked = detailingSteps[DetailingStep.Vegger];

  return (
    <StepCheckBox step={DetailingStep.Vinduer} disabled={!veggerIsChecked} />
  );
};
