import { useFormContext } from 'react-hook-form';

import { UserRole } from '../../types/user';

export type ProfileFormData = {
  rolle: UserRole;
  energiradgiver: boolean;
  bygningsekspert: boolean;
  kjelOgVarmeanlegg: boolean;
  ventilasjonsOgKjoleanlegg: boolean;
};

export const useProfileFormContext = () => {
  return useFormContext<ProfileFormData>();
};
