import React, { FC, Fragment, useState } from 'react';
import { Button, Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch } from 'react-redux';

import useSelector from '../../../../hooks/useSelector';
import { addDor, removeDor } from '../../../../store/registration/actions';
import { YtterDor } from '../../../../types/registration/userInput';
import { getDoors } from '../../../../store/registration/selectors';
import useRegistrationForm from '../../../../screens/registration/useRegistrationForm';
import { FieldError } from '../../utils/validation';

import NewDoor from './newDoor';
import ExistingDoor from './existingDoor';

const Doors: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const doors = useSelector(getDoors, shallowEqual);
  const [addNew, setAddNew] = useState(false);
  const { setStartedObjectErrors } = useRegistrationForm();

  const handleAddDor = (dor: YtterDor) => {
    dispatch(addDor({ dor }));
    setAddNew(false);
    setStartedObjectErrors({});
  };

  return (
    <Fragment>
      <Typography variant="h2" gutterBottom>
        {t('register.doors.title')}
      </Typography>

      <Typography paragraph>{t('register.doors.description')}</Typography>

      <Grid container spacing={4}>
        {doors && doors.length > 0 ? (
          <Fragment>
            <Grid item xs={12}>
              <Typography variant="h3">
                {t('register.doors.registeredDoors')}
              </Typography>
            </Grid>

            {doors?.map((dor, index) => (
              <Grid item xs={12} key={dor.id}>
                <ExistingDoor
                  HeadingProps={{ variant: 'h4' }}
                  index={index}
                  door={dor}
                  removeDoor={() =>
                    dispatch(
                      removeDor({
                        dorId: dor.id
                      })
                    )
                  }
                />
              </Grid>
            ))}
          </Fragment>
        ) : (
          !addNew && (
            <Grid item xs={12}>
              <Typography>{t('register.door.empty')}</Typography>
            </Grid>
          )
        )}

        <Grid item xs={12}>
          {addNew ? (
            <NewDoor
              handleCancel={() => {
                setAddNew(false);
                setStartedObjectErrors({});
              }}
              saveDoor={handleAddDor}
            />
          ) : (
            <Button
              variant="primary"
              onClick={() => {
                setAddNew(true);
                setStartedObjectErrors({
                  door: { fieldError: FieldError.Invalid, number: 1 }
                });
              }}
            >
              {t('register.door.addNew')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};
export default Doors;
