import React, { FC } from 'react';
import { Button, Dialog } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

interface DeleteRegistrationDialogProps {
  createdDate: string;
  isLoading: boolean;
  isOpen: boolean;
  onClose: (() => void) | undefined;
  onConfirm: () => void;
}

export const DeleteRegistrationDialog: FC<DeleteRegistrationDialogProps> = ({
  createdDate,
  isLoading,
  isOpen,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Title>
        {t('registration.delete.warning.title', { date: createdDate })}
      </Dialog.Title>

      <Dialog.Content>
        {t('registration.delete.warning.content', { date: createdDate })}
      </Dialog.Content>

      <Dialog.Actions>
        <Button variant="secondary" disabled={isLoading} onClick={onClose}>
          {t('cancel')}
        </Button>

        <Button loading={isLoading} onClick={onConfirm}>
          {t('confirm')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
