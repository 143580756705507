import { css } from 'enova-frontend-components';

import { AdvancedSearchFormData } from './advancedSearch/types';

export const SearchMatchBaseCSS = css`
  display: flex;
  background: ${({ theme }) => theme.palette.background.paper};
  color: ${({ theme }) => theme.palette.text.primary} !important;
  border: ${({ theme }) => `thin solid ${theme.palette.divider}`};
  position: relative;
  grid-column: 1;
  border-radius: 0;
  box-shadow: none;
  padding: ${({ theme }) => theme.spacing(0)};
  text-transform: none;
  text-align: left;
  transition: ${({ theme }) =>
    `${theme.transitions.duration.short}ms  ${theme.transitions.easing.easeInOut}`};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    border-right: 'none !important';
  }
`;

export const SearchMatchCSS = css`
  ${SearchMatchBaseCSS}

  &:hover {
    background: ${({ theme }) => theme.palette.action.focus};
  }

  &:focus-visible {
    background: ${({ theme }) => theme.palette.action.focus};
  }
`;

export const createSearchParams = (data: AdvancedSearchFormData) => {
  const searchParams = new URLSearchParams();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const entries = Object.entries(data) as [keyof AdvancedSearchFormData, any][];

  for (const [key, value] of entries) {
    // Skip creating search params for kommuneOptions and post informations as its onlye used to find kommune number.
    if (
      key === 'kommuneOptions' ||
      key === 'poststed' ||
      key === 'postnummer'
    ) {
      continue;
    }

    if (value) {
      searchParams.append(key, value);
    }
  }
  
  return searchParams;
};
