import { Grid, TextField, useSnack } from 'enova-frontend-components';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { getSearchPoststed } from '../../../services/api';
import { queryKeys } from '../../../utils/react-query';
import { CreateBuildingData } from '../types';

const usePostalAddressSearch = () => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext<CreateBuildingData>();
  const postnummer = watch('postnummer');

  useQuery(
    [queryKeys.searchPoststed, postnummer],
    () => getSearchPoststed(postnummer),
    {
      enabled: postnummer?.length > 3,
      onSuccess(data) {
        if (!data) {
          return;
        }

        if (data.adresser.length === 0) {
          // Reset values if the search dont return any result
          setValue('poststed', '', { shouldValidate: true });
          setValue('kommunenavn', '', { shouldValidate: true });
          setValue('kommunenummer', '', { shouldValidate: true });

          addSnack(
            t('building.create.error.poststed.noResult', {
              postnummer
            }),
            { variant: 'error' }
          );

          return;
        }

        const result = data.adresser?.[0];
        const poststed = result?.poststed;

        setValue('poststed', poststed, { shouldValidate: true });
        setValue('kommunenavn', result?.kommunenavn, { shouldValidate: true });
        setValue('kommunenummer', String(result?.kommunenummer), {
          shouldValidate: true
        });
      },
      onError: () => {
        addSnack(t('building.create.error.poststed.errorResult')),
          { variant: 'error' };
      }
    }
  );
};

interface PostalAddressProps {
  disabled: boolean;
}

export const PostalAddress: FC<PostalAddressProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors }
  } = useFormContext<CreateBuildingData>();
  const { xsScreen } = useMediaQuery();

  usePostalAddressSearch();

  return (
    <Grid container item columnSpacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          disabled={disabled}
          label={t('building.create.postalCode')}
          required
          fullWidth={xsScreen}
          error={!!errors?.postnummer}
          helperText={errors?.postnummer?.message}
          {...register('postnummer', {
            required: t('required') as string,
            validate: {
              invalid: (value) =>
                value.length !== 4 ? (t('invalid') as string) : true
            }
          })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={t('building.create.poststed')}
          required
          disabled
          fullWidth={xsScreen}
          error={!!errors?.poststed}
          helperText={errors?.poststed?.message}
          {...register('poststed', { required: t('required') as string })}
        />
      </Grid>
    </Grid>
  );
};
