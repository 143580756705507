import { useTranslation } from 'react-i18next';

import { Vindu } from '../../../../types/registration/userInput';
import { WindowSpecType } from '../../utils/window';
import { getWindowSpecType } from '../utils';

export const useWindowInfo = (window: Vindu) => {
  const { t } = useTranslation();

  const { glassType, karmType, uverdi, fabrikasjonsAr } = window;

  const windowSpecType = getWindowSpecType(window);

  const getValueBasedOnWindowSpecType = () => {
    switch (windowSpecType) {
      case WindowSpecType.WINDOW_TYPE:
        return [
          ...(karmType
            ? [t(`register.window.karmType.option.${karmType}`)]
            : []),
          ...(glassType
            ? [t(`register.window.glassType.option.${glassType}`)]
            : [])
        ].join(', ');
      case WindowSpecType.U_VALUE:
        return uverdi;
      case WindowSpecType.YEAR_OF_CHANGE:
        return fabrikasjonsAr;
      default:
        return null;
    }
  };

  return { windowSpecType, windowValue: getValueBasedOnWindowSpecType() };
};
