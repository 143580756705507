import React, { FC } from 'react';
import { Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import {
  XMLReportError,
  XMLValidationError
} from '../../../../../../utils/expertToolsXML/typeGuard';

const useXMLValidationError = (validationError: XMLValidationError) => {
  const { t } = useTranslation();

  switch (validationError.keyword) {
    case 'required':
      return t('expertToolsXML.validationError.required', {
        path: validationError.instancePath,
        missing: validationError.params.missingProperty
      });
    case 'additionalProperties':
      return t('expertToolsXML.validationError.additionalProperties', {
        path: validationError.instancePath,
        property: validationError.params.additionalProperty
      });
    case 'type':
      return t('expertToolsXML.validationError.type', {
        path: validationError.instancePath,
        type: validationError.params.type
      });
    default:
      return t('expertToolsXML.validationError.unknown', {
        path: validationError.instancePath,
        message: validationError.message
      });
  }
};

const ErrorMessage: FC<{ validationError: XMLValidationError }> = ({
  validationError
}) => {
  const translatedError = useXMLValidationError(validationError);
  return (
    <li>
      <Typography>{translatedError}</Typography>
    </li>
  );
};

export const XMLValidationErrorList: FC<{ xmlError: XMLReportError }> = ({
  xmlError
}) => {
  return (
    <ul>
      {xmlError.errors.map((e, index) => (
        // Intentionally using index, as we're not manipulating the list
        // eslint-disable-next-line react/no-array-index-key
        <ErrorMessage key={index} validationError={e} />
      ))}
    </ul>
  );
};
