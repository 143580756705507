import { differenceInMonths, differenceInYears } from 'date-fns';

import i18n from '../i18n';

interface DownloadOptions {
  uri: string | undefined;
  filename?: string;
}

export const downloadFile = (
  options: DownloadOptions,
  handleError: () => void
): { ok: boolean } => {
  if (!options.uri) {
    handleError();

    return { ok: false };
  }

  const link = document.createElement('a');
  link.setAttribute('target', '_blank');
  link.setAttribute('rel', 'noopener noreferrer');

  if (options.filename) {
    link.setAttribute('download', options.filename);
  }

  link.href = options.uri;
  document.body.appendChild(link);
  link.click();
  link.remove();

  return { ok: true };
};

export const mailto = (address: string | undefined): { ok: boolean } => {
  const emailSubject = `Link til energimerke for ${address ? address : ''}`;
  // eslint-disable-next-line max-len
  const emailBody = `Her er linken til energimerket. Du må være logget inn i energimerkesystemet før du kan åpne denne linken:%0D%0A%0D%0A${window.location}`;
  const link = document.createElement('a');
  link.setAttribute('target', '_blank');
  link.setAttribute('rel', 'noopener noreferrer');
  link.href = 'mailto:' + '?subject=' + emailSubject + '&body=' + emailBody;
  document.body.appendChild(link);
  link.click();
  link.remove();
  return { ok: true };
};

export const calculateDifferenceYearsAndMonths = (
  date?: Date
): { diffYear: number; diffMonth: number } => {
  if (!date) return { diffYear: 0, diffMonth: 0 };

  const diffYear = differenceInYears(new Date(date), new Date());

  const diffMonth =
    differenceInMonths(new Date(date), new Date()) - diffYear * 12;

  return { diffYear, diffMonth };
};

export const base64toBlob = (
  b64Data: string,
  contentType = '',
  sliceSize = 512
): Blob => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const decimalsCount = (num: number | undefined) => {
  if (!num || Number.isInteger(num)) {
    return 0;
  }

  const decimalStr = num.toString().split('.')?.[1];

  return decimalStr.length ?? 0;
};

export const formatArea = (area: number) => {
  const decimals = decimalsCount(area);

  return area.toFixed(decimals > 2 ? 2 : decimals).replace('.', ',');
};

// Create an error message with a default message and a timestamp to be used
// in snacks and toasts in the ui. Add a custom message to supplement the default
// message if needed.
export const createErrorMessage = (message?: string): string => {
  const intlOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false
  };

  const timeFormatter = new Intl.DateTimeFormat(i18n.language, intlOptions);
  const formattedTime = timeFormatter.format(new Date());

  const formattedMessage = `${i18n.t('somethingWentWrong')} ${
    message ?? ''
  } ${formattedTime}`;

  return formattedMessage;
};

// noinspection NonAsciiCharacters
const replacements: Record<string, string> = {
  ø: 'o',
  å: 'a',
  æ: 'ae',
  Ø: 'O',
  Å: 'A',
  Æ: 'Ae'
};

export const toCamelCase = (str: string) => {
  const camelCaseString = str
    .replace(/[øåæØÅÆ]/g, (match) => replacements[match] || match)
    .replace(/(?:^|\s+)(\w)/g, (_, char) => char.toUpperCase());

  return camelCaseString.charAt(0).toLowerCase() + camelCaseString.slice(1);
};
