import React, { FC, Fragment } from 'react';
import { Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { SmallTypography } from '../../../../../utils';
import { GetTypeFromMatrikkel } from '../../../../../../../../utils/matrikkelBygningsTyper';
import { UserType } from '../../../../../../../../types/user';
import { useUser } from '../../../../../../../../hooks/useUser';
import { BuildingDetails } from '../../../../../../../../types/building';

interface Props {
  building: BuildingDetails;
}

export const CompactBuildingInfo: FC<Props> = ({ building }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const {
    bruksEnhetsNummer,
    bygningsNummer,
    bygningsTypeNummer,
    bruksenhetsIdentId,
    isHovedbygg
  } = building;

  return (
    <Fragment>
      <Typography variant="h4" component="p">
        {bruksEnhetsNummer
          ? `${bruksEnhetsNummer} `
          : `${t('building.bruksenhetsnummerMissing')}`}
      </Typography>

      <SmallTypography>
        {`${GetTypeFromMatrikkel(bygningsTypeNummer)}`}
        {!isHovedbygg && ` - ${t('building.tilbygg')}`}
      </SmallTypography>

      {user?.type === UserType.EXPERT && (
        <SmallTypography>
          {t('building.identifier', {
            value: bruksenhetsIdentId,
            context: 'bruksenhetsIdentId'
          })}
        </SmallTypography>
      )}

      <SmallTypography>
        {t('building.identifier', {
          value: bygningsNummer,
          context: 'byggnr'
        })}
      </SmallTypography>
    </Fragment>
  );
};
