import { useTranslation } from 'react-i18next';

import { Address } from '../types/building';

export const useGetBuildingAddress = () => {
  const { t } = useTranslation();

  const formatAddressText = ({ bokstav, gateNavn, nummer }: Address) =>
    t('buildingAddressText', {
      bokstav,
      gateNavn,
      nummer
    });

  return {
    formatAddressText,
    getBuildingAddress: (adresse?: Address) => {
      if (!adresse || !adresse.gateNavn) {
        return t('buildingAddress.missing');
      }

      return t('buildingAddress', {
        adressetekst: formatAddressText(adresse),
        postNummer: adresse.postNummer,
        postSted: adresse.postSted
      });
    }
  };
};
