import { Box, Button } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '../../../hooks/useMediaQuery';

interface Props {
  onReset: () => void;
  loading: boolean;
}

export const FormButtons: FC<Props> = ({ onReset, loading }) => {
  const { xsScreen } = useMediaQuery();
  const { t } = useTranslation();

  return (
    <Box display="flex" my={5} columnGap={3}>
      <Button
        fullWidth={xsScreen}
        onClick={onReset}
        disabled={loading}
        variant="secondary"
      >
        {t('reset')}
      </Button>

      <Button type="submit" fullWidth={xsScreen} loading={loading}>
        {t('save')}
      </Button>
    </Box>
  );
};
