import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'rooks';
import { UseFormWatch } from 'react-hook-form';

import { updateKonstruksjonstetthet } from '../../../../store/registration/actions';

import { State } from './types';

// This hook updates the Redux store with form data which allows saving when
// navigating via breadcrumbs on the left side of the registration page and also
// use the navigation in useRegistrationformProvider until every page can be more
// independent.
export const useUpdateReduxStore = (watch: UseFormWatch<State>) => {
  const dispatch = useDispatch();

  const debouncedUpdate = useDebounce((data: State) => {
    dispatch(
      updateKonstruksjonstetthet({
        data: {
          lekkasjetall: data.lekkasjetall,
          dato: data.lekkasjetallDato
        }
      })
    );
  }, 200);

  useEffect(() => {
    const subscription = watch((formValues) => {
      debouncedUpdate(formValues);
    });

    return () => subscription.unsubscribe();
  }, [watch]);
};
