import fylkerJson from './fylker.json';

export interface Fylke {
  fylkesnavn: string;
  fylkesnummer: string;
  kommuner: Kommune[];
}

export interface Kommune {
  kommunenavn: string;
  kommunenummer: string;
  gyldigtil?: string;
  erstatter?: Kommune[];
}

export const fylker: Fylke[] = Object.assign(fylkerJson) as Fylke[];

export const fylkerWithoutErstatter = (fylker: Fylke[]): Fylke[] =>
  fylker.map((fylke) => ({
    ...fylke,
    kommuner: fylke.kommuner
      .sort((a, b) => a.kommunenavn.localeCompare(b.kommunenavn))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ erstatter, ...rest }) => rest)
  }));

function kommunenummerWithHistory(
  kommuner: Kommune[],
  kommunenummerArray: string[]
): void {
  for (const kommune of kommuner) {
    kommunenummerArray.push(kommune.kommunenummer);
    if (kommune.erstatter) {
      kommunenummerWithHistory(kommune.erstatter, kommunenummerArray);
    }
  }
}

/**
 * Returns a list of kommunenumre for given fylkesnavn. Includes historical kommunenumre replaced by current,
 * valid kommunenummer.
 * @param {string} key:fylkesnavn
 * @public
 */
export const fylkerDictionary: Record<string, string[]> = fylker.reduce(
  (acc, fylke) => {
    const kommunenumre: string[] = [];
    kommunenummerWithHistory(fylke.kommuner, kommunenumre);
    return { ...acc, [fylke.fylkesnavn]: kommunenumre };
  },
  {}
);

/**
 * Returns a list for given kommunenummer. Given kommunenummer plus any kommunenumre replaced by this
 * @param {string} key:kommunenummer
 * @public
 */
export const kommuneDictionary: Record<string, string[]> = fylker.reduce(
  (acc: Record<string, string[]>, fylke) => {
    for (const { kommunenummer, erstatter } of fylke.kommuner) {
      const kommunenumre: string[] = [kommunenummer];
      if (erstatter !== undefined) {
        kommunenummerWithHistory(erstatter, kommunenumre);
      }
      acc[kommunenummer] = kommunenumre;
    }
    return acc;
  },
  {}
);

/**
 * Returns a top-level list of kommunenumre, valid for present date
 * @public
 */
export const fylkerWithoutHistorical = fylkerWithoutErstatter(fylker);

export const fylkerNames = fylker
  .map((fylke) => fylke.fylkesnavn)
  .sort((a, b) => a.localeCompare(b));
