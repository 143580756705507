import React, { FC } from 'react';
import {
  Box,
  Checkbox,
  CheckGroup,
  Divider,
  Grid,
  Hidden
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'rooks';

import useBuildingFilter from '../../../hooks/useBuildingFilter';
import { CategoryOptions } from '../../../hooks/useBuildingFilter/context';
import { toCamelCase } from '../../../utils/utils';

import BuildingList from './components/buildingList';

const Buildings: FC = () => {
  const { t } = useTranslation();

  const { allowFilter, categories, filterValues, updateFilterValues } =
    useBuildingFilter();

  const updateFilterValuesDebounced = useDebounce<typeof updateFilterValues>(
    updateFilterValues,
    500,
    { leading: true, trailing: true }
  );

  return (
    <Grid container spacing={8} mt={-4}>
      {allowFilter && categories && (
        <Hidden mdDown>
          <Grid item md={4} lg="auto">
            <Box display="flex" flexDirection="column">
              {categories.map((category) => (
                <CheckGroup key={category} label={t(category)} marginBottom>
                  {CategoryOptions[category].map((option) => (
                    <Checkbox
                      checked={filterValues[category].includes(option)}
                      key={option}
                      label={t(`${category}.${toCamelCase(option)}`)}
                      onChange={(_, checked) =>
                        updateFilterValuesDebounced(category, option, checked)
                      }
                      width="fullWidth"
                    />
                  ))}
                </CheckGroup>
              ))}
            </Box>
          </Grid>

          <Grid item md="auto" mx={-4}>
            <Divider orientation="vertical" />
          </Grid>
        </Hidden>
      )}

      <Grid item xs={12} md>
        <BuildingList />
      </Grid>
    </Grid>
  );
};

export default Buildings;
