import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup, RadioGroupProps } from 'enova-frontend-components';

import { WindowSpecType } from '../../../utils/window';

interface WindowTypeRadioGroupProps {
  onChange: (val: WindowSpecType) => void;
  children: ReactNode & RadioGroupProps['children'];
  value: WindowSpecType | null;
}

export const WindowTypeRadioGroup: FC<WindowTypeRadioGroupProps> = ({
  children,
  onChange,
  value
}) => {
  const { t } = useTranslation();

  return (
    <RadioGroup
      aria-label={t('register.window.radioGroup.label')}
      onChange={(_, val) => onChange(val as WindowSpecType)}
      required
      value={value}
    >
      {children}
    </RadioGroup>
  );
};
