import React, { FC } from 'react';
import { FormMessage, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import useRegistrationForm from '../useRegistrationForm';
import { useErrors } from '../utils';

const ErrorMessage: FC = () => {
  const { t } = useTranslation();
  const { step, startedObjectErrors } = useRegistrationForm();
  const { errors } = useErrors(step);

  return errors || Object.keys(startedObjectErrors).length > 0 ? (
    <FormMessage title={t('register.errorMessage.title')} fullWidth>
      <Typography>{t('register.errorMessage.description')}</Typography>

      <ul aria-label={t('register.errorMessage.list')}>
        {errors &&
          Object.entries(errors).map(([field, fieldError]) => {
            const errorMessage = t(`register.errorMessage.field.${field}`, {
              context: fieldError.error,
              ...fieldError.values
            });

            return (
              <li key={field} aria-label={errorMessage}>
                {errorMessage}
              </li>
            );
          })}
        {startedObjectErrors &&
          Object.entries(startedObjectErrors).map(
            ([field, numberedFieldError]) => {
              const errorMessage = t(`register.errorMessage.field.${field}`, {
                context: numberedFieldError?.fieldError
              });

              return (
                <li key={field} aria-label={errorMessage}>
                  {errorMessage}
                </li>
              );
            }
          )}
      </ul>
    </FormMessage>
  ) : null;
};

export default ErrorMessage;
