import { createContext, Dispatch, SetStateAction } from 'react';

import { SoknadSteg } from '../../../components/registering/utils/registerEnums';
import { NumberedFieldErrorMap } from '../../../components/registering/utils/validation';
import { Tiltak } from '../../../types/registration/tiltak';
import { Detaljeringssteg } from '../../../types/registration/userInput';
import {
  RegistrationCommonStep,
  RegistrationStep
} from '../../../utils/navigation';

export type RegistrationFormValues = {
  confirmNavigation: () => void;
  displayErrors: boolean;
  startedObjectErrors: NumberedFieldErrorMap;
  setStartedObjectErrors: Dispatch<SetStateAction<NumberedFieldErrorMap>>;
  energiplanId?: string;
  errorInformation?: string[];
  generateRoute: (step: RegistrationStep) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generateSummaryPath: (params: any) => string;
  goToStep: (step: RegistrationStep) => void;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  loadingStep: boolean;
  nextStep?: RegistrationStep;
  prevStep?: RegistrationStep;
  regId?: string;
  registrationId?: string;
  registrationIsPublished: boolean;
  showErrorMessage?: () => void;
  soknadSteg?: SoknadSteg;
  step: RegistrationStep;
  steps: RegistrationStep[];
  tiltakList?: Tiltak[];
  tiltakLoading: boolean;
  updateSteps: (detailingSteps?: Detaljeringssteg) => void;
  attestUrl?: string;
};

const RegistrationFormContext = createContext<RegistrationFormValues>({
  confirmNavigation: () => undefined,
  displayErrors: false,
  showErrorMessage: () => undefined,
  startedObjectErrors: {},
  setStartedObjectErrors: () => undefined,
  generateRoute: () => '',
  generateSummaryPath: () => '',
  goToStep: () => undefined,
  goToNextStep: () => undefined,
  goToPrevStep: () => undefined,
  loadingStep: false,
  registrationIsPublished: false,
  step: RegistrationCommonStep.ABOUT,
  steps: [],
  tiltakLoading: false,
  updateSteps: () => undefined
});

export default RegistrationFormContext;
