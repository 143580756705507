import { useMutation } from 'react-query';

import { Tiltak } from '../../../types/registration/tiltak';
import { updateTiltakGrupperegistrering } from '../../../services/api';
import { queryClient, queryKeys } from '../../../utils/react-query';

export const useUpdateGrupperegistreringTiltak = (
  grupperegistreringId: string
) => {
  const { mutate, isLoading } = useMutation(
    (tiltak: Tiltak) =>
      updateTiltakGrupperegistrering({
        ...tiltak,
        grupperegistreringId
      }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          queryKeys.grupperegistreringMeasures,
          grupperegistreringId
        ])
    }
  );

  return { mutate, isLoading };
};
