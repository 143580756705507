import { FC, useEffect, useState } from 'react';

import { RegistrationStep } from '../../../../utils/navigation';

import { ErrorDialog } from './errorDialog';

// Component for displaying backend error information after registration update.
// User can choose to ignore errors and proceed to next step.
interface BackendErrorInformationProps {
  confirmNavigation: () => void;
  errorInformation: string[];
  step: RegistrationStep;
}

export const BackendErrorInformation: FC<BackendErrorInformationProps> = ({
  confirmNavigation,
  errorInformation,
  step
}) => {
  const [errorInformationDialogOpen, setErrorInformationDialogOpen] =
    useState(false);

  useEffect(() => setErrorInformationDialogOpen(false), [step]);

  useEffect(() => {
    if (errorInformation.length > 0) {
      setErrorInformationDialogOpen(true);
    }
  }, [errorInformation]);

  return (
    <ErrorDialog
      open={errorInformationDialogOpen}
      onCancel={() => setErrorInformationDialogOpen(false)}
      onConfirm={confirmNavigation}
      errorInformation={errorInformation}
    />
  );
};
