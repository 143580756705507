import { Box, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleEnergyAnalysis } from '../../../../../../../../../../types/building';

import { EnergyAnalysisTable } from './energyAnalysisTable';

interface Props {
  energyAnalysisList: SimpleEnergyAnalysis[];
}

export const BuildingInfoTab: FC<Props> = ({ energyAnalysisList }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Typography variant="p" mt={10}>
        {t('expertToolsXML.summary.tab.buildingInformation.infoText')}
      </Typography>

      <EnergyAnalysisTable energyAnalysisList={energyAnalysisList} />
    </Box>
  );
};
