import React, { FC } from 'react';
import { Grid } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { PortefoljeEnergiAnalyseResponse } from '../types';
import SimpleTable from '../components/simpleTable';
import { getExpertToolsXMLPortfolioBasePath } from '../../../utils/navigation';
import RouterButtonLink from '../../../components/routerButtonLink';

import { FileListTableHeader } from './steps/upload/fileListTableHeader';
import { EnergyAnalysisRow } from './energyAnalysisRow';

interface Props {
  data: PortefoljeEnergiAnalyseResponse;
}

export const DisplayWorklist: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const simpleEnergyAnalysis = [
    ...(data.energiAnalysePrivatBoligList || []),
    ...(data.energiAnalyseYrkesByggList || [])
  ];

  return (
    <React.Fragment>
      <SimpleTable aria-labelledby="tableCaptionId">
        <FileListTableHeader />
        <tbody>
          {simpleEnergyAnalysis.map((energyAnalysis) => (
            <EnergyAnalysisRow
              key={energyAnalysis.registreringId}
              energyAnalysis={energyAnalysis}
            />
          ))}
        </tbody>
      </SimpleTable>

      <Grid item xs={12} container spacing={4} mt={6}>
        <Grid item xs={12} sm="auto" marginLeft="auto">
          <RouterButtonLink
            fullWidth
            to={getExpertToolsXMLPortfolioBasePath()}
            variant="secondary"
          >
            {t('expertToolsXMLPortfolio.worklist.actions.cancel')}
          </RouterButtonLink>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
