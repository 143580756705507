import React, { FC, Fragment } from 'react';
import { Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import useFormatDate from '../../../../../../../hooks/useFormatDate';
import { calculateDifferenceYearsAndMonths } from '../../../../../../../utils/utils';

interface Props {
  utlopsDato?: Date;
}

export const ExpirationInfo: FC<Props> = ({ utlopsDato }) => {
  const { formatDate } = useFormatDate();
  const { t } = useTranslation();

  const { diffYear, diffMonth } = calculateDifferenceYearsAndMonths(utlopsDato);
  const expiryDate = formatDate(utlopsDato);

  const expiryDateText =
    expiryDate !== ''
      ? t('buildingScreen.tab.energiattest.expires', {
          diffYear,
          diffMonth,
          expiryDate
        })
      : '';

  return (
    <Fragment>
      <Typography
        bold
        gridColumn={{ xs: 0, sm: 1 }}
        gridRow={{ xs: 0, sm: 2 }}
        mr={{ sm: 3 }}
      >
        {t('buildingScreen.tab.energiattest.expiration')}
      </Typography>

      <Typography gridColumn={{ xs: 0, sm: 2 }} gridRow={{ xs: 0, sm: 2 }}>
        {expiryDateText}
      </Typography>
    </Fragment>
  );
};
