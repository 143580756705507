import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useStore } from 'react-redux';

import {
  Malform,
  SoknadSteg
} from '../../../components/registering/utils/registerEnums';
import * as api from '../../../services/api';
import { RegistrationRequest } from '../../../types/building';
import { queryKeys } from '../../../utils/react-query';
import { createErrorMessage } from '../../../utils/utils';

interface CreateRegistrationInput {
  soknadId?: string | undefined;
  soknadSteg?: SoknadSteg | undefined;
}

export const useCreateRegistration = (buildingId: string) => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const store = useStore();

  const { mutate, isLoading } = useMutation(
    (input: CreateRegistrationInput) => {
      const { userInput } = store.getState().register;
      const bygningsdetaljer = userInput.bygningsdetaljer;

      const newRegistration: RegistrationRequest = {
        bruksAreal: bygningsdetaljer.totalBRA,
        byggAr: bygningsdetaljer.byggAr,
        byggType: bygningsdetaljer.byggType,
        energiObjektId: buildingId,
        energiObjektType: '',
        malform: Malform.Bokmal,
        oppvarmetBruksAreal: bygningsdetaljer.totalOppvarmetBRA,
        soknadId: input.soknadId,
        soknadSteg: input.soknadSteg,
        userInput
      };

      return api.createRegistration(newRegistration);
    },
    {
      onSuccess: () => {
        // Update all building queries. Dont know why this is important
        queryClient.invalidateQueries([queryKeys.building, buildingId]);
        queryClient.invalidateQueries([
          queryKeys.energyAnalysisList,
          buildingId
        ]);
      },
      onError: () => {
        addSnack(
          createErrorMessage(t('registerScreen.saveRegistration.saveFailed')),
          { variant: 'error' }
        );
      }
    }
  );

  return { createRegistration: mutate, createIsLoading: isLoading };
};
