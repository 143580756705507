import React, { FC, Fragment } from 'react';
import {
  Accordion,
  Box,
  NumberPicker,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { useDispatch } from 'react-redux';

import useSelector from '../../../../../hooks/useSelector';
import { getEtasje } from '../../../../../store/registration/selectors';
import {
  addGulvsone,
  removeGulvsone
} from '../../../../../store/registration/actions';
import { InnerAccordion } from '../../../../innerAccordion';
import FloorZone from '../floorZone';

import { FloorZoneRadioGroup } from './floorZoneRadioGroup';

type FloorSectionProps = {
  etasjeId: string;
};

const FloorSection: FC<FloorSectionProps> = ({ etasjeId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const etasje = useSelector((state) => getEtasje(state, etasjeId), _.isEqual);

  if (!etasje) {
    return null;
  }

  const gulvsoner = etasje?.gulvSoner ?? [];
  const multipleZones = gulvsoner.length > 1;

  const addFloorZone = () => dispatch(addGulvsone({ etasjeId }));
  const removeFloorZone = () => dispatch(removeGulvsone({ etasjeId }));

  const addOrRemoveFloorZone = (val: number) => {
    if (gulvsoner.length > val) {
      return removeFloorZone();
    }

    return addFloorZone();
  };

  return (
    <Fragment>
      <FloorZoneRadioGroup etasje={etasje} multipleZones={multipleZones} />

      {multipleZones && (
        <NumberPicker
          value={gulvsoner?.length || 1}
          marginBottom
          min={2}
          onChange={addOrRemoveFloorZone}
          label={t('register.floors.gulvsoneCount.label')}
        />
      )}

      {multipleZones ? (
        <InnerAccordion>
          <Box mb={-2}>
            {gulvsoner?.map((gulvsone, gulvsoneIndex) => (
              <Accordion.Item
                title={
                  <Typography bold px={1} component="h4">
                    {t('register.floors.gulvsoneId', {
                      count: gulvsoneIndex + 1
                    })}
                  </Typography>
                }
                unmountOnExit={false}
                initExpanded={gulvsoneIndex === 0}
                key={gulvsone.id}
              >
                <Box px={1} my={-2}>
                  <FloorZone
                    etasjeId={etasjeId}
                    gulvsoneId={gulvsone.id}
                    gulvsoneIndex={gulvsoneIndex}
                  />
                </Box>
              </Accordion.Item>
            ))}
          </Box>
        </InnerAccordion>
      ) : (
        gulvsoner?.[0]?.id && (
          <Box mb={-2}>
            <FloorZone etasjeId={etasjeId} gulvsoneId={gulvsoner?.[0]?.id} />
          </Box>
        )
      )}
    </Fragment>
  );
};

export default FloorSection;
