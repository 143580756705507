import {
  BygningsForm,
  Himmelretning,
  VeggId
} from '../../../utils/registerEnums';
import { Vegg } from '../../../../../types/registration/userInput';

const createWall = (veggId: VeggId, direction: Himmelretning): Vegg => ({
  veggId,
  himmelretning: direction,
  veggSoner: [],
  lengdeIMeter: 0
});

export const getWallsByShape = (shape: BygningsForm | undefined): Vegg[] => {
  if (!shape) {
    return [];
  }

  switch (shape) {
    case BygningsForm.Rektangulaert:
      return [
        createWall(VeggId.A, Himmelretning.Nord),
        createWall(VeggId.B, Himmelretning.Ost),
        createWall(VeggId.C, Himmelretning.Sor),
        createWall(VeggId.D, Himmelretning.Vest)
      ];

    case BygningsForm.TForm:
      return [
        createWall(VeggId.A, Himmelretning.Nord),
        createWall(VeggId.B, Himmelretning.Ost),
        createWall(VeggId.C, Himmelretning.Sor),
        createWall(VeggId.D, Himmelretning.Ost),
        createWall(VeggId.E, Himmelretning.Sor),
        createWall(VeggId.F, Himmelretning.Vest),
        createWall(VeggId.G, Himmelretning.Sor),
        createWall(VeggId.H, Himmelretning.Vest)
      ];

    case BygningsForm.Atriumshus:
      return [
        createWall(VeggId.A, Himmelretning.Nord),
        createWall(VeggId.B, Himmelretning.Ost),
        createWall(VeggId.C, Himmelretning.Sor),
        createWall(VeggId.D, Himmelretning.Vest),
        createWall(VeggId.E, Himmelretning.Nord),
        createWall(VeggId.F, Himmelretning.Ost),
        createWall(VeggId.G, Himmelretning.Sor),
        createWall(VeggId.H, Himmelretning.Vest)
      ];

    case BygningsForm.YForm:
      return [
        createWall(VeggId.A, Himmelretning.Nord),
        createWall(VeggId.B, Himmelretning.Ost),
        createWall(VeggId.C, Himmelretning.Nord),
        createWall(VeggId.D, Himmelretning.Vest),
        createWall(VeggId.E, Himmelretning.Nord),
        createWall(VeggId.F, Himmelretning.Ost),
        createWall(VeggId.G, Himmelretning.Sor),
        createWall(VeggId.H, Himmelretning.Ost),
        createWall(VeggId.I, Himmelretning.Sor),
        createWall(VeggId.J, Himmelretning.Vest),
        createWall(VeggId.K, Himmelretning.Sor),
        createWall(VeggId.L, Himmelretning.Vest)
      ];

    case BygningsForm.LForm:
      return [
        createWall(VeggId.A, Himmelretning.Nord),
        createWall(VeggId.B, Himmelretning.Ost),
        createWall(VeggId.C, Himmelretning.Sor),
        createWall(VeggId.D, Himmelretning.Ost),
        createWall(VeggId.E, Himmelretning.Sor),
        createWall(VeggId.F, Himmelretning.Vest)
      ];

    case BygningsForm.UForm:
      return [
        createWall(VeggId.A, Himmelretning.Nord),
        createWall(VeggId.B, Himmelretning.Ost),
        createWall(VeggId.C, Himmelretning.Nord),
        createWall(VeggId.D, Himmelretning.Vest),
        createWall(VeggId.E, Himmelretning.Nord),
        createWall(VeggId.F, Himmelretning.Ost),
        createWall(VeggId.G, Himmelretning.Sor),
        createWall(VeggId.H, Himmelretning.Vest)
      ];

    case BygningsForm.XForm:
      return [
        createWall(VeggId.A, Himmelretning.Nord),
        createWall(VeggId.B, Himmelretning.Ost),
        createWall(VeggId.C, Himmelretning.Nord),
        createWall(VeggId.D, Himmelretning.Ost),
        createWall(VeggId.E, Himmelretning.Sor),
        createWall(VeggId.F, Himmelretning.Ost),
        createWall(VeggId.G, Himmelretning.Sor),
        createWall(VeggId.H, Himmelretning.Vest),
        createWall(VeggId.I, Himmelretning.Sor),
        createWall(VeggId.J, Himmelretning.Vest),
        createWall(VeggId.K, Himmelretning.Nord),
        createWall(VeggId.L, Himmelretning.Vest)
      ];

    case BygningsForm.ZForm:
      return [
        createWall(VeggId.A, Himmelretning.Nord),
        createWall(VeggId.B, Himmelretning.Ost),
        createWall(VeggId.C, Himmelretning.Sor),
        createWall(VeggId.D, Himmelretning.Ost),
        createWall(VeggId.E, Himmelretning.Sor),
        createWall(VeggId.F, Himmelretning.Vest),
        createWall(VeggId.G, Himmelretning.Nord),
        createWall(VeggId.H, Himmelretning.Vest)
      ];

    case BygningsForm.ZFormSpeilvendt:
      return [
        createWall(VeggId.A, Himmelretning.Nord),
        createWall(VeggId.B, Himmelretning.Ost),
        createWall(VeggId.C, Himmelretning.Sor),
        createWall(VeggId.D, Himmelretning.Ost),
        createWall(VeggId.E, Himmelretning.Sor),
        createWall(VeggId.F, Himmelretning.Vest),
        createWall(VeggId.G, Himmelretning.Sor),
        createWall(VeggId.H, Himmelretning.Vest)
      ];

    case BygningsForm.LFormSpeilvendt:
      return [
        createWall(VeggId.A, Himmelretning.Nord),
        createWall(VeggId.B, Himmelretning.Ost),
        createWall(VeggId.C, Himmelretning.Sor),
        createWall(VeggId.D, Himmelretning.Vest),
        createWall(VeggId.E, Himmelretning.Sor),
        createWall(VeggId.F, Himmelretning.Vest)
      ];

    default:
      return [];
  }
};

const rotateClockwise = (currentDirection?: Himmelretning) => {
  if (!currentDirection) {
    return;
  }

  switch (currentDirection) {
    case Himmelretning.Ost:
      return Himmelretning.Sor;

    case Himmelretning.Nord:
      return Himmelretning.Ost;

    case Himmelretning.Vest:
      return Himmelretning.Nord;

    case Himmelretning.Sor:
      return Himmelretning.Vest;
  }
};

export const rotateWalls = (walls: Vegg[]) => {
  return walls.map((wall) => ({
    ...wall,
    himmelretning: rotateClockwise(wall.himmelretning)
  }));
};
