import { Box, DatePicker, NumberField } from 'enova-frontend-components';
import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { User } from '../../../../types/user';

import { State } from './types';
import {
  datoIsInvalid,
  datoIsRequired,
  lekkasjeTallIsRequired
} from './validators';

interface FormProps {
  user: User | undefined;
  control: Control<State>;
}

export const Form: FC<FormProps> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="row" gap={4}>
      <Controller
        control={control}
        name="lekkasjetall"
        rules={{
          validate: {
            required: (lekkasjetall, values) =>
              lekkasjeTallIsRequired(lekkasjetall, values)
          }
        }}
        render={({
          field: { value, onChange, ...rest },
          fieldState: { error }
        }) => {
          return (
            <NumberField
              label={t('register.lekkasjetall.label')}
              value={value}
              onValueChange={({ floatValue }) => onChange(floatValue ?? null)}
              error={!!error}
              {...rest}
            />
          );
        }}
      />

      <Controller
        control={control}
        name="lekkasjetallDato"
        rules={{
          validate: {
            required: (dato, values) => datoIsRequired(dato, values),
            invalid: (dato, values) => datoIsInvalid(dato, values)
          }
        }}
        render={({
          field: { value, onChange, ...rest },
          fieldState: { error }
        }) => {
          return (
            <DatePicker
              value={value ?? null}
              onChange={onChange}
              label={t('register.lekkasjetall.dato.label')}
              error={!!error}
              {...rest}
            />
          );
        }}
      />
    </Box>
  );
};
