import { Button, Dialog, useSnack } from 'enova-frontend-components';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useDeleteSoknad } from '../useDeleteSoknad';
import { SoknadScreenParams } from '../../../../utils/navigation';
import { createErrorMessage } from '../../../../utils/utils';

interface Props {
  soknadId: string;
}

export const DeleteApplication = ({ soknadId }: Props) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { addSnack } = useSnack();
  const { t } = useTranslation();
  const { buildingId } = useParams<SoknadScreenParams>();
  const { deleteSoknad, isLoading } = useDeleteSoknad(buildingId);

  const handleDeleteSoknad = () =>
    deleteSoknad(soknadId, {
      onSuccess: () => setDialogOpen(false),
      onError: () => {
        addSnack(createErrorMessage(), { variant: 'error' });
      }
    });

  return (
    <Fragment>
      <Button fullWidth onClick={() => setDialogOpen(true)} variant="secondary">
        {t('documentsForEnovaApplication.delete')}
      </Button>
      {isDialogOpen && (
        <Dialog
          open={isDialogOpen}
          onClose={!isLoading ? () => setDialogOpen(false) : undefined}
        >
          <Dialog.Title>
            {t('documentsForEnovaApplication.remove.title')}
          </Dialog.Title>

          <Dialog.Content>
            {t('documentsForEnovaApplication.remove.description')}
          </Dialog.Content>

          <Dialog.Actions>
            <Button
              disabled={isLoading}
              onClick={() => setDialogOpen(false)}
              variant="secondary"
            >
              {t('cancel')}
            </Button>

            <Button onClick={handleDeleteSoknad} loading={isLoading}>
              {t('confirm')}
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </Fragment>
  );
};
