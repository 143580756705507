import React, { FC } from 'react';
import { NumberField } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { WindowFormFieldProps } from '../utils';

export const WindowAmount: FC<WindowFormFieldProps> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name="windowData.amount"
      rules={{ validate: (value) => (value && value > 0) || t('required') }}
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error }
      }) => (
        <NumberField
          {...rest}
          error={!!error}
          helperText={error?.message}
          autoComplete
          label={t('register.window.numberOfEqualWindows.label')}
          value={value ?? null}
          marginBottom
          name="antall-like-vindu-samme-vegg"
          onValueChange={({ floatValue }) => onChange(floatValue)}
        />
      )}
    />
  );
};
