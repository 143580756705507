import { useMutation, useQueryClient } from 'react-query';

import { createTiltakPortefolje } from '../../../services/api';
import { Tiltak } from '../../../types/registration/tiltak';
import { queryKeys } from '../../../utils/react-query';

export const useAddTiltakPortefolje = (portefoljeId: string | undefined) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (tiltak: Tiltak) => createTiltakPortefolje(portefoljeId, tiltak),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          queryKeys.portfolioMeasures,
          portefoljeId
        ])
    }
  );

  return { mutate, isLoading };
};
