import React, { FC, Fragment } from 'react';
import {
  Box,
  Button,
  Form,
  FullScreenLoader,
  Grid,
  InfoBox,
  Typography
} from 'enova-frontend-components';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { SoknadSteg } from '../../../../../../components/registering/utils/registerEnums';
import TranslatedEnergyRating2D from '../../../../../../components/translatedEnergyRating2D';
import useRegistrationForm from '../../../../useRegistrationForm';
import { useGetUnofficialEnergyCertificate } from '../../../../../../hooks/useGetUnofficialEnergyCertificate';
import { CertificateVisibilityConsent } from '../../../../../../components/certificateVisibilityConsentDialog';
import { useEnergyAnalysis } from '../../../../../../hooks/useEnergyAnalysis';

import EnovaApplicationQualifications from './enovaApplicationQualifications';
import { MalformRadio } from './malformRadio';

export interface ApplicationSummaryParams {
  soknadSteg: SoknadSteg;
  soknadId: string;
}

interface CalculatedEnergyRatingProps {
  gradeLimitedToB: boolean;
}

const CalculatedEnergyRating: FC<CalculatedEnergyRatingProps> = ({
  gradeLimitedToB
}) => {
  const { t } = useTranslation();
  const { registrationId } = useRegistrationForm();
  const { soknadSteg, soknadId } = useParams<ApplicationSummaryParams>();
  const { energyAnalysis } = useEnergyAnalysis(registrationId);
  const { getCertificate, isLoading } =
    useGetUnofficialEnergyCertificate(registrationId);

  const energiplan = energyAnalysis?.energiplan;

  const energiKarakter = energiplan?.energimerke?.energiKarakter;
  const oppvarmingKarakter =
    energiplan?.energimerke?.properties?.oppvarmingKarakter;

  return (
    <Fragment>
      <FullScreenLoader open={isLoading} />

      <Typography paragraph>
        {t('register.summary.calculatedRating.description', { context: 1 })}
      </Typography>

      <Typography paragraph>
        {t('register.summary.calculatedRating.description', { context: 2 })}
      </Typography>

      {energiKarakter && oppvarmingKarakter && gradeLimitedToB && (
        <InfoBox dismissable={false}>
          {t('register.summary.calculatedRating.cannotGetGradeA', {
            context: 2
          })}
        </InfoBox>
      )}

      {energiKarakter && oppvarmingKarakter && (
        <Box my={8}>
          <TranslatedEnergyRating2D
            energyGrade={energiKarakter}
            heatingGrade={oppvarmingKarakter}
          />
        </Box>
      )}

      <Fragment>
        <Typography paragraph>
          <Trans
            i18nKey="register.summary.calculatedRating.description"
            values={{ context: 3 }}
          />
        </Typography>
        <Box my={5}>
          {energyAnalysis?.energiplan && (
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Typography>
                {t('energyCertificateVisibilityConsent.description')}
              </Typography>
              <Box>
                <CertificateVisibilityConsent
                  energiplan={energyAnalysis.energiplan}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Typography paragraph>
          {t('register.summary.calculatedRating.description', { context: 4 })}
        </Typography>

        <Form>
          <MalformRadio energianalyse={energyAnalysis} />

          <Button
            className="mb-4"
            leftIcon="download"
            onClick={getCertificate}
            type="submit"
            variant="secondary"
          >
            {t('register.summary.downloadEnergyCertificatePreview')}
          </Button>
        </Form>
      </Fragment>

      <Grid container spacing={4}>
        {soknadSteg && soknadId && (
          <EnovaApplicationQualifications
            soknadSteg={soknadSteg}
            soknadId={soknadId}
          />
        )}
      </Grid>
    </Fragment>
  );
};

export default CalculatedEnergyRating;
