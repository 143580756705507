import { Box, Hidden } from 'enova-frontend-components';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SortAttribute,
  SortOrder
} from '../../../../../hooks/useBuildingFilter/context';
import {
  BuildingListItemEnergyIndicatorWidth,
  BuildingListItemRegistrationDateWidth
} from '../../utils';

import { RotatingIcon, StyledChip } from './components';

interface OrderProps {
  sort: SortAttribute;
  dir: SortOrder;
  updateOrder: (order: SortAttribute) => void;
}

export const OrderBuildingListMenu = ({
  sort,
  dir,
  updateOrder
}: OrderProps) => {
  const { t } = useTranslation();

  const isAscending = dir === SortOrder.ASC;

  const getChipAriaLabel = (attr: SortAttribute) =>
    t('orderBy', {
      attribute: t(attr).toLowerCase(),
      direction: t(
        `dir.${sort === attr && isAscending ? SortOrder.DESC : SortOrder.ASC}`
      ).toLowerCase()
    });

  const ListOrderChip = ({
    sortAttribute
  }: {
    sortAttribute: SortAttribute;
  }) => (
    <StyledChip
      aria-label={getChipAriaLabel(sortAttribute)}
      label={
        <Fragment>
          {t(sortAttribute)}
          <RotatingIcon
            turn={sort === sortAttribute && isAscending}
            name="arrow_up"
          />
        </Fragment>
      }
      variant={sort === sortAttribute ? 'primary' : 'secondary'}
      onClick={() => updateOrder(sortAttribute)}
    />
  );

  const totalWidth =
    BuildingListItemRegistrationDateWidth +
    BuildingListItemEnergyIndicatorWidth;

  return (
    <Hidden smDown>
      <Box display="flex">
        <Box className="f-fill" pl={1}>
          <ListOrderChip sortAttribute={SortAttribute.ADDRESS} />
        </Box>

        <Box width={totalWidth} pl={2}>
          <ListOrderChip sortAttribute={SortAttribute.REGISTERED} />
        </Box>
      </Box>
    </Hidden>
  );
};
