import React, { FC, Fragment } from 'react';
import {
  Box,
  Grid,
  LineArt,
  MainContentBox,
  Tabs
} from 'enova-frontend-components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import RouterLink from '../../components/routerLink';
import StylizedRouterButtonLink from '../../components/stylizedRouterButtonLink';
import { useUser } from '../../hooks/useUser';
import useBuildingDetails from '../../hooks/useBuildingDetails';
import { AreaOfExpertise, UserType } from '../../types/user';
import {
  AppRoute,
  BuildingScreenParams,
  BuildingScreenTab,
  BuildingScreenTabMap,
  camelCasify,
  DefaultMainScreenTab,
  getBuildingScreenPath,
  getNewRegistrationScreenPath,
  hasPermission
} from '../../utils/navigation';
import BuildingHeaderInfo from '../../components/buildingHeaderInfo';
import { GetEnergiObjektTypeFromMatrikkel } from '../../utils/matrikkelBygningsTyper';
import { SessionExpiresDialog } from '../../components/sessionExpiresDialog';

import EnergyRating from './tabs/energyRating';
import About from './tabs/about';
import EnergyAssessment from './tabs/energyAssessment';
import TiltakTab from './tabs/tiltak';
import DocumentsForEnovaApplicationWrapper from './tabs/enovaApplication';
import { EnergyCertificateTabContent } from './tabs/energyCertificateTabContent';

type LocationState = { fromName: string; fromPath: string } | undefined;

const BuildingScreen: FC = () => {
  const { buildingId, tab } = useParams<BuildingScreenParams>();
  const { replace } = useHistory();
  const { t } = useTranslation();
  const { user } = useUser();
  const { state: locationState } = useLocation<LocationState>();

  const { building, address } = useBuildingDetails();

  if (!user) {
    return <Redirect to="/" />;
  }

  const { gyldigSoknad, kanEnergimerkesAvPrivatPerson, gyldigeEnergiattester } =
    building;

  const { type: userType, fagomrader } = user;

  const energiObjektType = GetEnergiObjektTypeFromMatrikkel(
    kanEnergimerkesAvPrivatPerson
  );

  const hasDocumentAccess =
    kanEnergimerkesAvPrivatPerson &&
    (hasPermission(AppRoute.SOKNAD, user) ||
      gyldigSoknad?.registreringForSteg1);

  // Redirect to default tab if not provided by URL params
  if (!tab || !BuildingScreenTabMap[userType].includes(tab)) {
    const defaultTab = hasDocumentAccess
      ? BuildingScreenTab.DOCUMENTS
      : BuildingScreenTab.ENERGY_CERTIFICATE;

    return (
      <Redirect
        to={getBuildingScreenPath({
          buildingId,
          tab: defaultTab
        })}
      />
    );
  }

  const helmetTitle = [
    t(`buildingScreen.tab.${camelCasify(tab)}.tabTitle`, {
      context: energiObjektType
    }),
    address
  ].join(' | ');

  return (
    <Fragment>
      <Helmet title={helmetTitle} />

      <MainContentBox padding="xtOnly">
        <Box display="flex" mb={3} alignItems="flex-start">
          <LineArt name="house" className="mr-4 mt-3" />

          <Box>
            <BuildingHeaderInfo energiobjektId={building.energiObjektId} />
          </Box>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {locationState ? (
              <RouterLink className="mb-3 as-start" to={locationState.fromPath}>
                {t('navigation.backToScreen', {
                  screen: locationState.fromName.toLowerCase()
                })}
              </RouterLink>
            ) : (
              <RouterLink className="mb-3 as-start" to="/">
                {t('navigation.goToScreen', {
                  screen: t(
                    `mainScreen.tab.${camelCasify(DefaultMainScreenTab)}`,
                    { context: userType }
                  ).toLowerCase()
                })}
              </RouterLink>
            )}
          </Grid>

          {kanEnergimerkesAvPrivatPerson && (
            <Grid item xs={12} sm="auto">
              <StylizedRouterButtonLink
                fullWidth
                id="link-to-energimerke"
                size="small"
                title={t('energyRateBuilding', {
                  context: energiObjektType
                })}
                to={getNewRegistrationScreenPath({ buildingId })}
              />
            </Grid>
          )}
        </Grid>
      </MainContentBox>

      <Tabs onChange={(_, newTab) => replace(String(newTab))} selectedTab={tab}>
        <Tabs.Tab
          identifier={BuildingScreenTab.ENERGY_CERTIFICATE}
          tabTitle={t(
            `buildingScreen.tab.${camelCasify(
              BuildingScreenTab.ENERGY_CERTIFICATE
            )}.tabTitle`
          )}
        >
          <EnergyCertificateTabContent />
        </Tabs.Tab>

        <Tabs.Tab
          identifier={BuildingScreenTab.ABOUT}
          tabTitle={t(
            `buildingScreen.tab.${camelCasify(
              BuildingScreenTab.ABOUT
            )}.tabTitle`,
            {
              context: energiObjektType
            }
          )}
        >
          <About building={building} user={user} />
        </Tabs.Tab>

        {(kanEnergimerkesAvPrivatPerson ||
          (!kanEnergimerkesAvPrivatPerson &&
            fagomrader?.includes(AreaOfExpertise.BYGNINGSEKSPERT))) && (
          <Tabs.Tab
            identifier={BuildingScreenTab.ENERGY_RATING}
            tabTitle={t(
              `buildingScreen.tab.${camelCasify(
                BuildingScreenTab.ENERGY_RATING
              )}.tabTitle`
            )}
          >
            <EnergyRating />
          </Tabs.Tab>
        )}
        {hasDocumentAccess && (
          <Tabs.Tab
            identifier={BuildingScreenTab.DOCUMENTS}
            tabTitle={t(
              `buildingScreen.tab.${camelCasify(
                BuildingScreenTab.DOCUMENTS
              )}.tabTitle`
            )}
          >
            <DocumentsForEnovaApplicationWrapper />
          </Tabs.Tab>
        )}

        {userType === UserType.EXPERT &&
          (fagomrader?.includes(AreaOfExpertise.KJELOGVARMEANLEGG) ||
            fagomrader?.includes(AreaOfExpertise.VENTILASJONOGKJOLEANLEGG)) && (
            <Tabs.Tab
              identifier={BuildingScreenTab.ENERGY_ASSESSMENT}
              tabTitle={t(
                `buildingScreen.tab.${camelCasify(
                  BuildingScreenTab.ENERGY_ASSESSMENT
                )}.tabTitle`
              )}
            >
              <EnergyAssessment />
            </Tabs.Tab>
          )}

        {gyldigeEnergiattester.length > 0 && (
          <Tabs.Tab
            identifier={BuildingScreenTab.ENERGY_SAVING_MEASURES}
            tabTitle={t(
              `buildingScreen.tab.${camelCasify(
                BuildingScreenTab.ENERGY_SAVING_MEASURES
              )}.tabTitle`
            )}
          >
            <TiltakTab />
          </Tabs.Tab>
        )}
      </Tabs>
      <SessionExpiresDialog />
    </Fragment>
  );
};

export default BuildingScreen;
