import { useHistory, useLocation } from 'react-router-dom';

export const useXmlRegistrationSearchParams = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(search);

  const pushUrlWithParams = (state?: object) => {
    history.push({
      pathname,
      search: searchParams.toString(),
      state
    });
  };

  const setBuildingSelection = (buildingIds: string[], state?: object) => {
    if (buildingIds.length === 0) {
      // Clear building selection
      searchParams.delete('valgte-bygg');
      pushUrlWithParams(state);
      return;
    }

    searchParams.set('valgte-bygg', buildingIds.join(','));
    pushUrlWithParams(state);
  };

  const setGroupName = (name: string) => {
    if (name === '') {
      searchParams.delete('attestnavn');
    } else {
      searchParams.set('attestnavn', name);
    }

    pushUrlWithParams();
  };

  return {
    selectedBuildingIds: searchParams.get('valgte-bygg')?.split(',') || [],
    groupName: searchParams.get('attestnavn') || '',
    setGroupName,
    setBuildingSelection,
    pushUrlWithParams
  };
};
