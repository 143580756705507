import { Box, Button } from 'enova-frontend-components';
import React, { FC } from 'react';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreateBuildingData } from '../types';
import { useCreateBuildingFormContext } from '../utils';

interface SubmitButtonsProps {
  errors: FieldErrors<CreateBuildingData>;
  isLoading: boolean;
  onCancel: () => void;
}

export const SubmitButtons: FC<SubmitButtonsProps> = ({
  isLoading,
  onCancel,
  errors
}) => {
  const { t } = useTranslation();
  const { watch } = useCreateBuildingFormContext();

  const addNewBuilding = watch('leggTilNyBygning');
  const submitIsDisabled = isLoading || Object.keys(errors).length > 0;

  return (
    <Box display="flex" mt={5} columnGap={3}>
      <Button
        variant="secondary"
        type="button"
        onClick={onCancel}
        disabled={isLoading}
      >
        {t('cancel')}
      </Button>

      <Button disabled={submitIsDisabled} loading={isLoading} type="submit">
        {t(`building.create.confirm`, {
          context: addNewBuilding && 'andAddNew'
        })}
      </Button>
    </Box>
  );
};
