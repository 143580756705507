import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'underscore';
import { useIsFetching } from 'react-query';

import { SoknadSteg } from '../../../components/registering/utils/registerEnums';
import { useUser } from '../../../hooks/useUser';
import useStore from '../../../hooks/useStore';
import {
  AllRegistrationScreenParams,
  RegistrationCommonStep,
  RegistrationScreenParams,
  RegistrationStep,
  SoknadScreenParams
} from '../../../utils/navigation';
import { withStepperId } from '../utils';
import {
  getHasErrors,
  NumberedFieldErrorMap
} from '../../../components/registering/utils/validation';
import { updateRegistration as updateRegistrationAction } from '../../../store/registration/actions';
import {
  Detaljeringssteg,
  UserInput
} from '../../../types/registration/userInput';
import { getDetailingSteps } from '../../../store/registration/selectors';
import { Registration } from '../../../types/building';
import { SessionExpiresDialog } from '../../../components/sessionExpiresDialog';
import { useTiltakEnergiPlan } from '../../../hooks/useTiltakEnergiplan';
import { useEnergyAnalysis } from '../../../hooks/useEnergyAnalysis';
import { queryKeys } from '../../../utils/react-query';
import { usePublishRegistration } from '../../../hooks/usePublishRegistration';
import { createErrorMessage } from '../../../utils/utils';
import { BackendErrorInformation } from '../components/backendErrorInformation';

import RegistrationFormContext, { RegistrationFormValues } from './context';
import { useCreateRegistration } from './useCreateRegistration';
import { useUpdateRegistration } from './useUpdateRegistration';
import { getSteps } from './utils';
import useStepErrorInformation from './useStepErrorInformation';
import { useUpdateParameters } from './useUpdateParameters';

/**
 * For a registration, regId and registrationId will always be the same. When creating a soknad, regId and
 * registrationId will be the same for steg1. For steg2/steg3 regId is the registrationId of the previous
 * step and registrationId is undefined. After steg2/steg3 is created regId and registrationId continues to
 * mirror each other. All of this can be discarded if we start to create a registration/soknad before
 * navigating to the Registration page.
 */
type RegistrationFormProviderProps = Pick<
  RegistrationFormValues,
  'generateSummaryPath'
> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generatePath: (params: any) => string;
  /**
   * ID of registration to be retrieved
   */
  regId?: string;
  /**
   * ID of the current registration
   */
  registrationId?: string;

  soknad?: {
    soknadSteg: SoknadSteg;
    soknadId?: string;
  };
  children: ReactNode;
};

const RegistrationFormProvider: FC<RegistrationFormProviderProps> = ({
  children,
  generatePath,
  generateSummaryPath,
  regId,
  registrationId,
  soknad
}) => {
  // External hooks
  const { addSnack } = useSnack();
  const { push } = useHistory();
  const { t } = useTranslation();
  const params = useParams<AllRegistrationScreenParams>();
  const { buildingId, step = RegistrationCommonStep.ABOUT } = params;

  // Internal hooks
  const { user } = useUser();

  // API requests
  const { energyAnalysis } = useEnergyAnalysis(registrationId);
  const {
    isLoading: tiltakLoading,
    tiltakList,
    energiplanId
  } = useTiltakEnergiPlan(energyAnalysis?.energiplan?.energiplanId);

  const { createRegistration, createIsLoading } =
    useCreateRegistration(buildingId);

  const { updateRegistration, updateIsLoading } =
    useUpdateRegistration(registrationId);

  const { publishRegistration, publishIsLoading } = usePublishRegistration(
    registrationId,
    buildingId
  );
  const { updateParameters, updateParametersIsLoading } =
    useUpdateParameters(registrationId);

  // Used to check if useSubmitRegistrationQuery is submitting. Is used in summary page.
  // Will be removed in the future
  const registrationIsSubmitting = useIsFetching([
    queryKeys.UNSAFE_SumitRegistrationAsQuery
  ]);

  // Redux stuff
  const detailingSteps = useSelector(getDetailingSteps, _.isEqual);
  const dispatch = useDispatch();
  const store = useStore();

  // Errors
  const getStepErrorInformation = useStepErrorInformation();
  const [displayErrors, setDisplayErrors] = useState(false);
  const [errorInformation, setErrorInformation] = useState<string[]>();

  // Handle errors when adding a new door or window
  const [startedObjectErrors, setStartedObjectErrors] =
    useState<NumberedFieldErrorMap>({});

  // Step
  const [steps, setSteps] = useState<RegistrationStep[]>(() =>
    getSteps(user, detailingSteps, soknad?.soknadSteg)
  );
  const updateSteps = (ds?: Detaljeringssteg) =>
    setSteps(getSteps(user, ds, soknad?.soknadSteg));

  useEffect(() => updateSteps(detailingSteps), [detailingSteps]);
  useEffect(() => window.scrollTo(0, 0), [step]);

  const nextStep = step ? steps[steps.indexOf(step) + 1] : undefined;
  const prevStep =
    step && step !== RegistrationCommonStep.ENERGY_CERTIFICATE
      ? steps[steps.indexOf(step) - 1]
      : undefined;

  const generateRoute = (
    newParams?: Partial<RegistrationScreenParams | SoknadScreenParams>
  ) => withStepperId(generatePath({ ...params, ...newParams }));

  const updateUserInput = (userInput?: UserInput) => {
    dispatch(updateRegistrationAction({ userInput }));
    addSnack(t('registerScreen.saveRegistration.saveSucceeded'));
  };

  const showErrorMessage = (context?: 'publish') => {
    addSnack(
      createErrorMessage(
        t('registerScreen.saveRegistration.saveFailed', {
          context
        })
      ),
      { variant: 'error' }
    );
  };

  const confirmNavigation = () => {
    if (nextStep) {
      push(generateRoute({ step: nextStep }));
      setErrorInformation([]);
    }
  };

  const navigate = (
    newStep: RegistrationStep | undefined,
    registration?: Registration
  ) => {
    const registreringId =
      registration?.registreringId ?? regId ?? registrationId;
    const soknadId = soknad?.soknadId ?? registration?.soknadId;

    if (!newStep) {
      return;
    }

    if (soknad)
      push(
        generateRoute({
          ...(soknadId ? { soknadId } : {}),
          soknadSteg: soknad.soknadSteg,
          step: newStep
        })
      );
    else
      push(
        generateRoute({
          ...(registreringId ? { registrationId: registreringId } : {}),
          step: newStep
        })
      );
  };

  const goToNextStep = () => {
    if (
      getHasErrors(store.getState(), step) ||
      Object.keys(startedObjectErrors).length > 0
    ) {
      setDisplayErrors(true);

      return;
    }

    setDisplayErrors(false);

    if (!nextStep) {
      return;
    }

    if (!registrationId) {
      createRegistration(
        { soknadId: soknad?.soknadId, soknadSteg: soknad?.soknadSteg },
        {
          onSuccess: (data) => {
            updateUserInput(data.userInput);
            navigate(nextStep, data);
          }
        }
      );
    } else if (step === RegistrationCommonStep.SUMMARY) {
      publishRegistration(undefined, {
        onSuccess: () => push(generateRoute({ step: nextStep })),
        onError: () => showErrorMessage('publish')
      });
    } else {
      updateRegistration(store.getState().register.userInput, {
        onSuccess: (data) => {
          const userInput = data?.userInput;
          const userInputErrorInformation = data?.userInputErrorInformation;

          const newErrorInformation = getStepErrorInformation(
            step,
            userInputErrorInformation
          );

          setErrorInformation(newErrorInformation);
          updateUserInput(userInput);

          if ((newErrorInformation?.length ?? 0) === 0) {
            navigate(nextStep);
          }
        },
        onError: () => showErrorMessage()
      });
    }
  };

  const goToPrevStep = () => {
    setDisplayErrors(false);
    // If we start to add a new door/window but dont want to finish, we should be able to
    // navigate to a previous step without errors about not having saved the door/window.
    // This will only affect navigating backwards. Forwards we will still validate
    // before navigating.
    setStartedObjectErrors({});

    if (!prevStep) {
      return;
    }

    if (step === RegistrationCommonStep.SUMMARY)
      push(generateRoute({ step: prevStep }));
    else {
      // Navigate back and try to save a snapshot of the registration.
      updateRegistration(store.getState().register.userInput, {
        onSettled: () => push(generateRoute({ step: prevStep }))
      });
    }
  };

  const goToStep = (registrationStep: RegistrationStep) => {
    if (registrationStep === RegistrationCommonStep.PARAMETERS) {
      // Navigate and try to save a snapshot of the parameters.
      updateParameters(undefined, {
        onSettled: () => push(generateRoute({ step: registrationStep }))
      });
    } else {
      // Navigate and try to save a snapshot of the registration.
      updateRegistration(store.getState().register.userInput, {
        onSettled: () => push(generateRoute({ step: registrationStep }))
      });
    }
  };

  const values: RegistrationFormValues = {
    confirmNavigation,
    displayErrors,
    startedObjectErrors,
    setStartedObjectErrors,
    energiplanId,
    errorInformation,
    generateRoute: (registrationStep: RegistrationStep) =>
      generateRoute({ step: registrationStep }),
    generateSummaryPath,
    goToStep,
    goToNextStep,
    goToPrevStep,
    loadingStep:
      createIsLoading ||
      updateIsLoading ||
      publishIsLoading ||
      updateParametersIsLoading ||
      registrationIsSubmitting > 0,
    nextStep,
    prevStep,
    regId,
    registrationId,
    registrationIsPublished: !!energyAnalysis?.registrering.publisertDato,
    showErrorMessage,
    soknadSteg: soknad?.soknadSteg,
    step,
    steps,
    tiltakList,
    tiltakLoading,
    updateSteps,
    attestUrl: energyAnalysis?.energiplan?.attestUri
  };

  return (
    <RegistrationFormContext.Provider value={values}>
      {children}
      <BackendErrorInformation
        confirmNavigation={confirmNavigation}
        errorInformation={errorInformation ?? []}
        step={step}
      />
      <SessionExpiresDialog params={{ registrationId, step }} />
    </RegistrationFormContext.Provider>
  );
};

export default RegistrationFormProvider;
