/* eslint-disable max-len */
import { BygningsForm, VeggId } from '../../utils/registerEnums';
import { MinifiedWall } from '../../../../store/registration/selectors';

// Walls that should be disabled
export const lengthDisabled = (
  bygningsForm: BygningsForm | undefined,
  wallId: VeggId | undefined
): boolean => {
  switch (bygningsForm) {
    case BygningsForm.Rektangulaert:
    case BygningsForm.Unknown:
      if (wallId === VeggId.C) return true;
      if (wallId === VeggId.D) return true;
      return false;
    case BygningsForm.LFormSpeilvendt:
      if (wallId === VeggId.C) return true;
      if (wallId === VeggId.F) return true;
      return false;
    case BygningsForm.LForm:
      if (wallId === VeggId.B) return true;
      if (wallId === VeggId.C) return true;
      return false;
    case BygningsForm.TForm:
      if (wallId === VeggId.E) return true;
      if (wallId === VeggId.H) return true;
      return false;
    case BygningsForm.UForm:
      if (wallId === VeggId.D) return true;
      if (wallId === VeggId.E) return true;
      return false;
    case BygningsForm.Atriumshus:
      if (wallId === VeggId.C) return true;
      if (wallId === VeggId.D) return true;
      if (wallId === VeggId.G) return true;
      if (wallId === VeggId.H) return true;
      return false;
    case BygningsForm.YForm:
      if (wallId === VeggId.K) return true;
      if (wallId === VeggId.J) return true;
      return false;
    case BygningsForm.XForm:
      if (wallId === VeggId.B) return true;
      if (wallId === VeggId.E) return true;
      return false;
    case BygningsForm.ZForm:
      if (wallId === VeggId.B) return true;
      if (wallId === VeggId.C) return true;
      return false;
    case BygningsForm.ZFormSpeilvendt:
      if (wallId === VeggId.B) return true;
      if (wallId === VeggId.E) return true;
      return false;
  }

  return false;
};

// Walls that should be derived from other walls
export const getAffectedWalls = (
  bygningsForm: BygningsForm | undefined,
  wallId: VeggId | undefined
): VeggId[] => {
  switch (bygningsForm) {
    case BygningsForm.Rektangulaert:
    case BygningsForm.Unknown:
      if (wallId === VeggId.A) return [VeggId.C];
      if (wallId === VeggId.B) return [VeggId.D];
      return [];
    case BygningsForm.LForm:
      if (wallId === VeggId.A) return [VeggId.C];
      if (wallId === VeggId.D) return [VeggId.B];
      if (wallId === VeggId.F) return [VeggId.B];
      if (wallId === VeggId.E) return [VeggId.C];
      return [];
    case BygningsForm.LFormSpeilvendt:
      if (wallId === VeggId.A) return [VeggId.C];
      if (wallId === VeggId.B) return [VeggId.F];
      if (wallId === VeggId.D) return [VeggId.F];
      if (wallId === VeggId.E) return [VeggId.C];
      return [];
    case BygningsForm.TForm:
      if (wallId === VeggId.A) return [VeggId.E];
      if (wallId === VeggId.B) return [VeggId.H];
      if (wallId === VeggId.C) return [VeggId.E];
      if (wallId === VeggId.D) return [VeggId.H];
      if (wallId === VeggId.F) return [VeggId.H];
      if (wallId === VeggId.G) return [VeggId.E];
      return [];
    case BygningsForm.UForm:
      if (wallId === VeggId.A) return [VeggId.E];
      if (wallId === VeggId.B) return [VeggId.D];
      if (wallId === VeggId.C) return [VeggId.E];
      if (wallId === VeggId.F) return [VeggId.D];
      if (wallId === VeggId.G) return [VeggId.E];
      if (wallId === VeggId.H) return [VeggId.D];
      return [];
    case BygningsForm.Atriumshus:
      if (wallId === VeggId.A) return [VeggId.C];
      if (wallId === VeggId.B) return [VeggId.D];
      if (wallId === VeggId.E) return [VeggId.G];
      if (wallId === VeggId.F) return [VeggId.H];
      return [];
    case BygningsForm.YForm:
      if (wallId === VeggId.A) return [VeggId.K];
      if (wallId === VeggId.B) return [VeggId.J];
      if (wallId === VeggId.C) return [VeggId.K];
      if (wallId === VeggId.D) return [VeggId.J];
      if (wallId === VeggId.E) return [VeggId.K];
      if (wallId === VeggId.F) return [VeggId.J];
      if (wallId === VeggId.G) return [VeggId.K];
      if (wallId === VeggId.H) return [VeggId.J];
      if (wallId === VeggId.I) return [VeggId.K];
      if (wallId === VeggId.L) return [VeggId.J];
      return [];
    case BygningsForm.XForm:
      if (wallId === VeggId.A) return [VeggId.E];
      if (wallId === VeggId.C) return [VeggId.E];
      if (wallId === VeggId.D) return [VeggId.B];
      if (wallId === VeggId.F) return [VeggId.B];
      if (wallId === VeggId.G) return [VeggId.E];
      if (wallId === VeggId.H) return [VeggId.B];
      if (wallId === VeggId.I) return [VeggId.E];
      if (wallId === VeggId.J) return [VeggId.B];
      if (wallId === VeggId.K) return [VeggId.E];
      if (wallId === VeggId.L) return [VeggId.B];
      return [];
    case BygningsForm.ZForm:
      if (wallId === VeggId.A) return [VeggId.C];
      if (wallId === VeggId.D) return [VeggId.B];
      if (wallId === VeggId.E) return [VeggId.C];
      if (wallId === VeggId.F) return [VeggId.B];
      if (wallId === VeggId.G) return [VeggId.C];
      if (wallId === VeggId.H) return [VeggId.B];
      return [];
    case BygningsForm.ZFormSpeilvendt:
      if (wallId === VeggId.A) return [VeggId.E];
      if (wallId === VeggId.C) return [VeggId.E];
      if (wallId === VeggId.D) return [VeggId.B];
      if (wallId === VeggId.F) return [VeggId.B];
      if (wallId === VeggId.G) return [VeggId.E];
      if (wallId === VeggId.H) return [VeggId.B];
      return [];
  }

  return [];
};

const getLength = (
  wallId: VeggId | undefined,
  walls: MinifiedWall[] | undefined
): number => {
  if (!wallId || !walls) {
    return 0;
  }
  const filtered = walls.filter((item) => item.veggId === wallId);

  if (!filtered || filtered.length <= 0 || !filtered[0].lengdeIMeter) {
    return 0;
  }

  return filtered[0].lengdeIMeter;
};

export const getCalculatedLength = (
  bygningsForm: BygningsForm | undefined,
  wallId: VeggId | undefined,
  walls: MinifiedWall[] | undefined
): number => {
  switch (bygningsForm) {
    case BygningsForm.Rektangulaert:
    case BygningsForm.Unknown:
      if (wallId === VeggId.C) return getLength(VeggId.A, walls);
      if (wallId === VeggId.D) return getLength(VeggId.B, walls);
      return 0;
    case BygningsForm.LForm:
      if (wallId === VeggId.B)
        return getLength(VeggId.F, walls) - getLength(VeggId.D, walls);
      if (wallId === VeggId.C)
        return getLength(VeggId.A, walls) - getLength(VeggId.E, walls);
      return 0;
    case BygningsForm.LFormSpeilvendt:
      if (wallId === VeggId.C)
        return getLength(VeggId.A, walls) - getLength(VeggId.E, walls);
      if (wallId === VeggId.F)
        return getLength(VeggId.B, walls) - getLength(VeggId.D, walls);
      return 0;
    case BygningsForm.TForm:
      if (wallId === VeggId.H) {
        return (
          getLength(VeggId.B, walls) +
          getLength(VeggId.D, walls) -
          getLength(VeggId.F, walls)
        );
      }
      if (wallId === VeggId.E)
        return (
          getLength(VeggId.A, walls) -
          getLength(VeggId.C, walls) -
          getLength(VeggId.G, walls)
        );
      return 0;
    case BygningsForm.UForm:
      if (wallId === VeggId.E)
        return (
          getLength(VeggId.G, walls) -
          getLength(VeggId.A, walls) -
          getLength(VeggId.C, walls)
        );
      if (wallId === VeggId.D)
        return (
          getLength(VeggId.F, walls) -
          getLength(VeggId.H, walls) +
          getLength(VeggId.B, walls)
        );
      return 0;
    case BygningsForm.Atriumshus:
      if (wallId === VeggId.C) return getLength(VeggId.A, walls);
      if (wallId === VeggId.D) return getLength(VeggId.B, walls);
      if (wallId === VeggId.G) return getLength(VeggId.E, walls);
      if (wallId === VeggId.H) return getLength(VeggId.F, walls);
      return 0;
    case BygningsForm.YForm:
      if (wallId === VeggId.J)
        return (
          getLength(VeggId.F, walls) +
          getLength(VeggId.H, walls) -
          getLength(VeggId.D, walls) +
          getLength(VeggId.B, walls) -
          getLength(VeggId.L, walls)
        );
      if (wallId === VeggId.K)
        return (
          getLength(VeggId.A, walls) +
          getLength(VeggId.C, walls) +
          getLength(VeggId.E, walls) -
          getLength(VeggId.G, walls) -
          getLength(VeggId.I, walls)
        );
      return 0;
    case BygningsForm.XForm:
      if (wallId === VeggId.E)
        return (
          getLength(VeggId.K, walls) +
          getLength(VeggId.A, walls) +
          getLength(VeggId.C, walls) -
          getLength(VeggId.I, walls) -
          getLength(VeggId.G, walls)
        );
      if (wallId === VeggId.B)
        return (
          getLength(VeggId.L, walls) +
          getLength(VeggId.J, walls) +
          getLength(VeggId.H, walls) -
          getLength(VeggId.F, walls) -
          getLength(VeggId.D, walls)
        );
      return 0;
    case BygningsForm.ZForm:
      if (wallId === VeggId.B)
        return (
          getLength(VeggId.F, walls) +
          getLength(VeggId.H, walls) -
          getLength(VeggId.D, walls)
        );
      if (wallId === VeggId.C)
        return (
          getLength(VeggId.A, walls) +
          getLength(VeggId.G, walls) -
          getLength(VeggId.E, walls)
        );
      return 0;
    case BygningsForm.ZFormSpeilvendt:
      if (wallId === VeggId.B)
        return (
          getLength(VeggId.H, walls) +
          getLength(VeggId.F, walls) -
          getLength(VeggId.D, walls)
        );
      if (wallId === VeggId.E)
        return (
          getLength(VeggId.C, walls) +
          getLength(VeggId.A, walls) -
          getLength(VeggId.G, walls)
        );
      return 0;
  }

  return 0;
};
