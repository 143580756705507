import { Typography } from 'enova-frontend-components';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { UserType } from '../../../../../types/user';
import { useUser } from '../../../../../hooks/useUser';
import { GetEnergiObjektTypeFromMatrikkel } from '../../../../../utils/matrikkelBygningsTyper';
import { DetailedBuildingDetails } from '../../../../../types/building';

interface Props {
  building: DetailedBuildingDetails;
}

export const NoValidCertificate: FC<Props> = ({ building }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const { kanEnergimerkesAvPrivatPerson } = building;

  const energiObjektType = GetEnergiObjektTypeFromMatrikkel(
    kanEnergimerkesAvPrivatPerson
  );

  return (
    <Fragment>
      <Typography gutterBottom variant="h2">
        {t('buildingScreen.tab.energiattest.noValidCertificate.title')}
      </Typography>
      <Typography>
        {t('buildingScreen.tab.energiattest.noValidCertificate.content', {
          context: energiObjektType
        })}
      </Typography>

      {(kanEnergimerkesAvPrivatPerson ||
        (!kanEnergimerkesAvPrivatPerson && user?.type !== UserType.PERSON)) && (
        <Typography gutterBottom>
          {t('buildingScreen.tab.energiattest.noValidCertificate.description', {
            context: energiObjektType
          })}
        </Typography>
      )}
    </Fragment>
  );
};
