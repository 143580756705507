import { Redirect, useParams } from 'react-router-dom';
import React, { FC, Fragment } from 'react';
import { FullScreenLoader } from 'enova-frontend-components';

import { XMLParams } from '../types';
import { XmlRegistration } from '../common/xmlRegistration';
import { XmlRegistrationStepper } from '../common/components/xmlRegistrationStepper';
import { useEnergyAnalysis } from '../../../../../hooks/useEnergyAnalysis';
import FileReviewContent from '../common/steps/fileReviewContent';
import Confirmation from '../common/steps/confirmation';

import { EnhetBuildingSelect } from './steps/enhetBuildingSelect';
import { EnhetUpload } from './steps/enhetUpload';
import { EnhetSummary } from './steps/enhetSummary';
import { EnhetRegistrationHeader } from './enhetRegistrationHeader';
import { checkEnhetStepCompletion } from './utils';
import { EnhetMeasures } from './steps/enhetMeasures';

export const EnhetXmlRegistration: FC = () => {
  const { step } = useParams<XMLParams>();

  const { registrationId } = useParams<XMLParams>();
  const { energyAnalysis, energyAnalysisLoading } =
    useEnergyAnalysis(registrationId);

  if (!step) {
    // Use 'velg-bygg' as default step
    return <Redirect to="/xml-registrering/enhet/velg-bygg" />;
  }

  if (energyAnalysisLoading) {
    return <FullScreenLoader open />;
  }

  return (
    <XmlRegistration
      header={<EnhetRegistrationHeader energianalyse={energyAnalysis} />}
      stepper={
        <XmlRegistrationStepper
          checkStepCompletion={checkEnhetStepCompletion}
        />
      }
      registrationStep={
        <Fragment>
          {step === 'velg-bygg' && <EnhetBuildingSelect />}
          {step === 'last-opp-fil' && <EnhetUpload />}
          {step === 'tiltak' && <EnhetMeasures />}
          {step === 'kvittering' && (
            <Confirmation energianalyse={energyAnalysis} />
          )}
          {step === 'oppsummering' && (
            <EnhetSummary energianalyse={energyAnalysis} />
          )}
          {step === 'oppsummering-av-fil' && (
            <FileReviewContent
              energianalyse={energyAnalysis}
              registrationType="enhet"
            />
          )}
        </Fragment>
      }
    />
  );
};
