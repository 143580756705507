import React, { FC, Fragment } from 'react';
import { Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import RouterButtonLink from '../../../components/routerButtonLink';
import {
  AppRoute,
  ExpertToolsKuldeArea,
  ExpertToolsVarmeArea,
  getExpertToolsKuldePath,
  getExpertToolsVarmePath,
  hasPermission
} from '../../../utils/navigation';
import { useUser } from '../../../hooks/useUser';
import useBuildingDetails from '../../../hooks/useBuildingDetails';

const EnergyAssessment: FC = () => {
  const {
    building: { energiObjektId }
  } = useBuildingDetails();
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <Grid container spacing={4}>
      {hasPermission(AppRoute.EXPERT_TOOLS_VARME, user) &&
        Object.values(ExpertToolsVarmeArea).map((area) => (
          <Fragment key={area}>
            <Grid item xs={12}>
              <Typography gutterBottom mt={5} variant="h2">
                {t('energyAssessment.title', { context: area })}
              </Typography>

              <Typography>
                {t('energyAssessment.description', { context: area })}
              </Typography>
            </Grid>

            <Grid item xs={12} sm="auto">
              <RouterButtonLink
                fullWidth
                to={getExpertToolsVarmePath({
                  buildingId: energiObjektId,
                  area
                })}
              >
                {t('energyAssessment.button', { context: area })}
              </RouterButtonLink>
            </Grid>
          </Fragment>
        ))}

      {hasPermission(AppRoute.EXPERT_TOOLS_KULDE, user) &&
        Object.values(ExpertToolsKuldeArea).map((area) => (
          <Fragment key={area}>
            <Grid item xs={12}>
              <Typography gutterBottom mt={5} variant="h2">
                {t('energyAssessment.title', { context: area })}
              </Typography>

              <Typography>
                {t('energyAssessment.description', { context: area })}
              </Typography>
            </Grid>

            <Grid item xs={12} sm="auto">
              <RouterButtonLink
                fullWidth
                to={getExpertToolsKuldePath({
                  buildingId: energiObjektId,
                  area
                })}
              >
                {t('energyAssessment.button', { context: area })}
              </RouterButtonLink>
            </Grid>
          </Fragment>
        ))}
    </Grid>
  );
};

export default EnergyAssessment;
