import React, { FC } from 'react';
import { Box, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { WindowData } from '../types';

interface Props {
  data: WindowData;
}

export const WindowInformation: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  if (!data.window) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Box display="flex" gap={4}>
        {data.window.uverdi && (
          <Box display="flex" flexDirection="column">
            <Typography>{t('register.window.uValue.label')}</Typography>
            <Typography flexWrap="wrap">
              <strong>{data.window.uverdi + ' ' + t('suffix.uvalue')}</strong>
            </Typography>
          </Box>
        )}

        {data.window.fabrikasjonsAr && (
          <Box display="flex" flexDirection="column">
            <Typography>
              {t('register.window.specType_yearOfChange')}
            </Typography>
            <Typography flexWrap="wrap">
              <strong>{data.window.fabrikasjonsAr}</strong>
            </Typography>
          </Box>
        )}

        {data.window.karmType && data.window.glassType && (
          <Box display="flex" flexDirection="column" maxWidth="400px">
            <Typography>{t('register.window.information')}</Typography>
            <Typography flexWrap="wrap">
              <strong>
                {t(`register.window.karmType.option.${data.window.karmType}`)}
              </strong>
            </Typography>
            <Typography flexWrap="wrap">
              <strong>
                {t(`register.window.glassType.option.${data.window.glassType}`)}
              </strong>
            </Typography>
          </Box>
        )}

        {data.window.brukStandardVerdier && (
          <Box display="flex" flexDirection="column">
            <Typography>{t('register.window.information')}</Typography>
            <Typography flexWrap="wrap">
              <strong>{t('register.window.detailing.unknown')}</strong>
            </Typography>
          </Box>
        )}
      </Box>

      <Box display="flex" gap={4}>
        <Box display="flex" flexDirection="column">
          <Typography>{t('area')}</Typography>
          <Typography flexWrap="wrap">
            <strong>
              {data.window.arealIKvm} {t('suffix.m2')}
            </strong>
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography>{t('register.window.amount')}</Typography>
          <Typography flexWrap="wrap">
            <strong>{data.amount}</strong>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
