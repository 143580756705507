import { Icon, Menu, styled, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const CustomMenuButton = styled((props) => {
  const { t } = useTranslation();
  return (
    <Menu.Button aria-label={`${t('options')}`} {...props}>
      <Icon name="horizontal_dots" />
      <Typography>{t('options')}</Typography>
    </Menu.Button>
  );
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => ` ${theme.palette.background.paper}`};
  color: black;
  border-style: none;
  border-left: ${({ theme }) => `1px solid ${theme.palette.greys.grey2}`};
  border-radius: 0 0 5px 0;

  :hover {
    background-color: ${({ theme }) => ` ${theme.palette.action.hover}`};
    border-left: ${({ theme }) => `1px solid ${theme.palette.greys.grey2}`};
  }
`;
