import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'enova-frontend-components';

import { LoadingIndicator } from '../../../../../../../../../../components/loadingIndicator';
import TiltakListReadOnly from '../../../../../../../../../../components/tiltakSection/tiltakListReadOnly';
import { Tiltak } from '../../../../../../../../../../types/registration/tiltak';

type TiltakQuery = {
  tiltakList?: Tiltak[];
  isLoading: boolean;
};

interface Props {
  tiltakQuery: TiltakQuery;
}

export const MeasuresTab: FC<Props> = ({ tiltakQuery }) => {
  const { tiltakList, isLoading } = tiltakQuery;
  const { t } = useTranslation();

  const hasTiltak = tiltakList && tiltakList.length > 0;

  if (isLoading) {
    return <LoadingIndicator text={t('energySavingMeasures.loading')} />;
  }

  if (!hasTiltak) {
    return <Typography>{t('energySavingMeasures.emptyState')}</Typography>;
  }

  return (
    <Grid container spacing={4} direction="column">
      <TiltakListReadOnly tiltakList={tiltakList} />
    </Grid>
  );
};
