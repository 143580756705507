import { Box, Typography } from 'enova-frontend-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import SimpleTable from '../../components/simpleTable';
import { queryKeys } from '../../../../utils/react-query';
import { getPortefoljer } from '../../../../services/api';

import { WorklistFilters, WorklistError, WorklistLoader } from './components';
import { WorkListRow } from './workListRow';
import { WorklistTableHeader } from './worklistTableHeader';

export const WorklistTable = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { push } = useHistory();

  const isPublished = new URLSearchParams(search).has('published');

  const portefoljeQuery = useQuery([queryKeys.portfolioList, isPublished], () =>
    getPortefoljer(isPublished)
  );

  if (portefoljeQuery.isLoading) {
    return <WorklistLoader />;
  }

  if (!portefoljeQuery.isSuccess) {
    return <WorklistError refetch={portefoljeQuery.refetch} />;
  }

  const hasWorklists = portefoljeQuery.data.length > 0;

  return (
    <React.Fragment>
      <Typography
        variant="h2"
        gutterBottom
        id="expertToolsXMLPortfolio-worklists-heading"
      >
        {t('expertToolsXMLPortfolio.worklists.heading')}
      </Typography>

      <WorklistFilters
        isPublished={isPublished}
        setPublished={(published) => push(published ? '?published' : '?')}
      />

      {!hasWorklists && (
        <Box>
          <Typography>
            {t('expertToolsXMLPortfolio.worklists.empty')}
          </Typography>
        </Box>
      )}

      {hasWorklists && (
        <SimpleTable aria-labelledby="expertToolsXMLPortfolio-worklists-heading">
          <WorklistTableHeader />
          <tbody>
            {portefoljeQuery.data.map((portefolje) => (
              <WorkListRow
                key={portefolje.portefoljeId}
                portefolje={portefolje}
              />
            ))}
          </tbody>
        </SimpleTable>
      )}
    </React.Fragment>
  );
};
