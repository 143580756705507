import React, { FC } from 'react';
import { NumberField } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { camelCasify } from '../../../../../../utils/navigation';
import { WindowSpecType } from '../../../../utils/window';
import { WindowFormFieldProps } from '../utils';

export const WindowUValue: FC<WindowFormFieldProps> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name="windowData.window.uverdi"
      rules={{
        validate: {
          required: (value, values) => {
            // Only required if detailing selection is uValue
            if (values.detailing !== 'uValue') {
              return;
            }

            return (value && value > 0) || t('required');
          }
        }
      }}
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error }
      }) => (
        <NumberField
          {...rest}
          error={!!error}
          helperText={error?.message}
          autoComplete
          label={t(
            `register.window.${camelCasify(WindowSpecType.U_VALUE)}.label`
          )}
          value={value ?? null}
          marginBottom
          name="u-verdi"
          onValueChange={({ floatValue }) => onChange(floatValue)}
          suffix={t('suffix.uvalue')}
        />
      )}
    />
  );
};
