import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import {
  getUnofficialEnergyCertificate,
  getUnofficialEnergyCertificateFlerboligbygg
} from '../services/api';
import { queryKeys } from '../utils/react-query';
import { createErrorMessage, downloadFile } from '../utils/utils';

export const useGetUnofficialEnergyCertificate = (
  registrationId: string | undefined
) => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const errorMessage = createErrorMessage(t('downloadError'));

  const { isFetching, refetch } = useQuery(
    [queryKeys.unofficialEnergyCertificate, registrationId],
    () => getUnofficialEnergyCertificate(registrationId),
    {
      onSuccess: ({ data, filename }) => {
        const blobUrl = URL.createObjectURL(data);

        downloadFile(
          {
            uri: blobUrl,
            filename
          },
          () => addSnack(errorMessage, { variant: 'error' })
        );
      },
      onError: () => {
        addSnack(errorMessage, { variant: 'error' });
      },
      enabled: false
    }
  );

  return { getCertificate: () => refetch(), isLoading: isFetching };
};

export const useGetUnofficialEnergyCertificateFlerbolig = (
  flerboligbyggId: string | undefined
) => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const errorMessage = createErrorMessage(t('downloadError'));

  const { isFetching, refetch } = useQuery(
    [queryKeys.unofficialEnergyCertificate, flerboligbyggId],
    () => getUnofficialEnergyCertificateFlerboligbygg(flerboligbyggId),
    {
      onSuccess: ({ data, filename }) => {
        const blobUrl = URL.createObjectURL(data);

        downloadFile(
          {
            uri: blobUrl,
            filename
          },
          () => addSnack(errorMessage, { variant: 'error' })
        );
      },
      onError: () => {
        addSnack(errorMessage, { variant: 'error' });
      },
      enabled: false
    }
  );

  return { getCertificate: () => refetch(), isLoading: isFetching };
};
