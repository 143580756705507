import { HeatingGrade } from 'enova-frontend-components';

import { SoknadSteg } from '../components/registering/utils/registerEnums';

import { SimpleEnergyAnalysis } from './building';

export type SoknadStegInfo = {
  analysis?: SimpleEnergyAnalysis;
  done: boolean;
  id: string;
  soknadSteg: SoknadSteg;
};

export type SoknadStegMap = Record<SoknadSteg, SoknadStegInfo | undefined>;

export enum SupportLevel {
  Level1 = 'level1',
  Level2 = 'level2',
  Level3 = 'level3'
}

export type LevelCriteria = {
  enovaStotteNiva: SupportLevel;
  maksimaltVarmetapstall: number;
  maksimaltArligNettoEnergibehov: number;
  lavesteAkseptertOppvarmingKarakter: HeatingGrade;
};

export type EnovaStotteCriteria = {
  enovaStotteKriteria: LevelCriteria[];
  godkjentNiva?: SupportLevel;
};
