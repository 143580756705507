import { Box, Button } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleEnergyAnalysis } from '../../../../../../../types/building';
import { XmlRegistrationType } from '../../../types';
import { NavigationWrapper } from '../../components/styledComponents';
import { useXmlStepNavigation } from '../../hooks/useXmlStepNavigation';

import FileContent from './fileContent';

interface Props {
  energianalyse?: SimpleEnergyAnalysis;
  registrationType: XmlRegistrationType;
}

const FileReviewContent: FC<Props> = ({ energianalyse, registrationType }) => {
  const { navigateToStep } = useXmlStepNavigation();
  const { t } = useTranslation();

  if (!energianalyse) {
    return null;
  }

  return (
    <Box>
      <FileContent energyAnalysis={energianalyse} />
      <NavigationWrapper>
        <Button
          style={{ marginLeft: 'auto' }}
          onClick={() => navigateToStep('tiltak', registrationType)}
        >
          {t('expertToolsXML.actions.next_tiltak')}
        </Button>
      </NavigationWrapper>
    </Box>
  );
};

export default FileReviewContent;
