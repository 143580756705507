import { useMutation } from 'react-query';

import * as api from '../../../services/api';
import { UserInput } from '../../../types/registration/userInput';

export const useUpdateRegistration = (registrationId: string | undefined) => {
  const { mutate, isLoading } = useMutation((input: UserInput) =>
    api.updateRegistration(registrationId, input)
  );

  return {
    updateRegistration: mutate,
    updateIsLoading: isLoading
  };
};
