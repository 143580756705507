import React, { FC, Fragment, useState } from 'react';
import { Grid, Menu, useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useUser } from '../../../../../../hooks/useUser';
import {
  getBuildingScreenPath,
  getNewRegistrationScreenPath
} from '../../../../../../utils/navigation';
import { addBuildingToUserSelection } from '../../../../../../services/api';
import { StyledMenuButton } from '../../../utils';
import { BuildingDetails } from '../../../../../../types/building';
import {
  GetEnergiObjektTypeFromMatrikkel,
  isBuildingGarageOrAnnex
} from '../../../../../../utils/matrikkelBygningsTyper';
import { useGetBuildingAddress } from '../../../../../../hooks/useGetBuildingAddress';
import { StartRegistrationDialog } from '../../../../../../components/startRegistrationDialog';
import { createErrorMessage } from '../../../../../../utils/utils';
import { BuildingLink } from '../../buildingList/buildingListItem/components/buildingLink';

type SearchMatchLinkProps = {
  building: BuildingDetails;
  minimizedView?: boolean;
};

export const SearchMatchLink: FC<SearchMatchLinkProps> = ({
  building,
  building: {
    adresse,
    energiObjektId,
    bygningsTypeNummer,
    isHovedbygg,
    kanEnergimerkesAvPrivatPerson
  },
  minimizedView
}) => {
  const { addSnack } = useSnack();
  const { push } = useHistory();
  const { t } = useTranslation();

  const { getBuildingAddress } = useGetBuildingAddress();
  const { user: { type: userType } = {} } = useUser();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const address = getBuildingAddress(adresse);

  const addToUserSelectedBuildings = () =>
    addBuildingToUserSelection(energiObjektId)
      .then((response) => {
        if (response.ok)
          addSnack(
            t('searchScreen.addBuildingSuccess', { context: userType }),
            { variant: 'success' }
          );
        else throw new Error();
      })
      .catch(() =>
        addSnack(
          createErrorMessage(
            t('searchScreen.addBuildingError', { context: userType })
          ),
          { variant: 'error' }
        )
      );

  const energiObjektType = GetEnergiObjektTypeFromMatrikkel(
    kanEnergimerkesAvPrivatPerson
  );

  const navigateToRegistration = () =>
    push(getNewRegistrationScreenPath({ buildingId: energiObjektId }));

  const startRegistration = () => {
    if (!isHovedbygg || isBuildingGarageOrAnnex(bygningsTypeNummer)) {
      return setIsDialogOpen(true);
    }

    navigateToRegistration();
  };

  return (
    <Fragment>
      <Grid alignItems="stretch" display="flex" item xs>
        <BuildingLink
          isTilbygg={!building.isHovedbygg}
          building={building}
          minimizedView={minimizedView}
        />
      </Grid>

      <Grid alignItems="stretch" display="flex" item xs="auto">
        <Menu>
          <StyledMenuButton
            isHovedBygg={isHovedbygg}
            aria-label={`${t('options')} ${address}`}
          >
            {t('options')}
          </StyledMenuButton>

          <Menu.Content>
            <Menu.Item divider onClick={addToUserSelectedBuildings}>
              {t('addBuilding', { context: userType })}
            </Menu.Item>

            <Menu.Item
              divider
              onClick={() =>
                push(getBuildingScreenPath({ buildingId: energiObjektId }))
              }
            >
              {t('showBuilding', { context: energiObjektType })}
            </Menu.Item>

            {kanEnergimerkesAvPrivatPerson && (
              <Menu.Item onClick={startRegistration}>
                {t('startNewRegistration')}
              </Menu.Item>
            )}
          </Menu.Content>
        </Menu>
      </Grid>

      <StartRegistrationDialog
        bygningsTypeNummer={building.bygningsTypeNummer}
        isHovedBygg={isHovedbygg}
        isOpen={isDialogOpen}
        onConfirm={navigateToRegistration}
        onClose={() => setIsDialogOpen(false)}
      />
    </Fragment>
  );
};
