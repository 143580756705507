import { useQuery } from 'react-query';

import { getEnergiplanTiltak } from '../services/api';
import { queryKeys } from '../utils/react-query';

export const useTiltakEnergiPlan = (energiplanId: string | undefined) => {
  const { isLoading, data: tiltakList } = useQuery(
    [queryKeys.energyplanMeasures, energiplanId],
    () => getEnergiplanTiltak(energiplanId),
    {
      enabled: !!energiplanId
    }
  );

  return {
    energiplanId,
    tiltakList,
    isLoading
  };
};
