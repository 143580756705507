import { SupportLevel } from '../../../../../../../types/soknad';

export const getNumberFromLevel = (
  level?: SupportLevel
): number | undefined => {
  if (level === SupportLevel.Level1) return 1;
  if (level === SupportLevel.Level2) return 2;
  if (level === SupportLevel.Level3) return 3;
  return undefined;
};
