import React, { HTMLAttributes } from 'react';
import { EnovaTheme, Icon, styled } from 'enova-frontend-components';

type CircleProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  pass?: boolean;
  theme: EnovaTheme;
};

// eslint-disable-next-line
const PassFailIndicator = styled(({ pass, ...props }: CircleProps) => (
  <div {...props}>
    <Icon color="inherit" name={pass ? 'check' : 'close'} fontSize="large" />
  </div>
))`
  align-items: center;
  background: ${({ theme, pass }) =>
    pass ? theme.palette.success.dark : theme.palette.error.dark};
  color: ${({ theme, pass }) =>
    theme.palette.getContrastText(
      pass ? theme.palette.success.dark : theme.palette.error.main
    )};
  border-radius: 50%;
  padding: ${({ theme }) => theme.spacing()};
  display: flex;
  justify-content: center;
`;

export default PassFailIndicator;
