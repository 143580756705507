import { useMutation, useQueryClient } from 'react-query';

import { deleteRegistration } from '../../services/api';
import { DetailedBuildingDetails } from '../../types/building';
import { queryKeys } from '../../utils/react-query';

import { RegistrationType } from './models';

export type OpenRegistrationFilterFunction = (
  building: DetailedBuildingDetails,
  registrationId: string
) => DetailedBuildingDetails;

const filterOpenRegistrations: OpenRegistrationFilterFunction = (
  building,
  registrationId
) => {
  return {
    ...building,
    apenRegistreringList: building.apenRegistreringList.filter(
      (registration) => registration !== registrationId
    )
  };
};

const filterOpenExpertRegistrations: OpenRegistrationFilterFunction = (
  building,
  registrationId
) => {
  return {
    ...building,
    apenEkspertRegistreringList: building.apenEkspertRegistreringList.filter(
      (registration) => registration !== registrationId
    )
  };
};

export const useDeleteOpenRegistration = (
  buildingId: string,
  registrationType: RegistrationType
) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (registrationId: string) => deleteRegistration(registrationId),
    {
      onSuccess: (_, registrationId) => {
        const queryKey = [queryKeys.building, buildingId];

        // Cancel 'api/registrerteiendom/oppsummering' query if one is pending. These are usual very slow
        // so if a user deletes several registrations in succession, the client will  display stale server
        // data every time an old request returns.
        queryClient.cancelQueries(queryKey);

        // Delete the open registration from the cache so the user gets a repsonse
        // immediately after the delete request is successfully finished.
        queryClient.setQueryData<DetailedBuildingDetails | undefined>(
          queryKey,
          (building) => {
            if (!building) {
              return building;
            }

            // Need to differentiate if we are deleting expert registrations or normal registrations
            // since they exists in two different properties on the building details object
            return registrationType === 'expert'
              ? filterOpenExpertRegistrations(building, registrationId)
              : filterOpenRegistrations(building, registrationId);
          }
        );

        queryClient.invalidateQueries([
          queryKeys.energyAnalysisList,
          buildingId
        ]);
        queryClient.invalidateQueries([queryKeys.building, buildingId]);
      }
    }
  );

  return { deleteRegistration: mutate, isLoading };
};
