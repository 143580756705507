import React, { Fragment, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Link, Typography } from 'enova-frontend-components';

import { XMLParams } from '../../types';

export const ReturnToXmlOverviewButton = () => {
  const { step } = useParams<XMLParams>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();
  const { push } = useHistory();

  const navigateToOverview = () => push('/xml-oversikt');

  const onBackToOverview = () => {
    if (step === 'last-opp-fil') {
      setDialogOpen(true);
      return;
    }
    navigateToOverview();
  };

  return (
    <Fragment>
      <Link button transparentBackground onClick={onBackToOverview}>
        {t('expertToolsXML.back')}
      </Link>

      <Dialog open={dialogOpen}>
        <Dialog.Title>
          <Typography variant="h3">
            {t('expertToolsXML.resetDataDialog.title')}
          </Typography>
        </Dialog.Title>
        <Dialog.Content>
          {t('expertToolsXML.resetDataDialog.content')}
        </Dialog.Content>
        <Dialog.Actions>
          <Button aria-label={t('cancel')} onClick={() => setDialogOpen(false)}>
            {t('cancel')}
          </Button>
          <Button
            aria-label={t('expertToolsXML.resetDataDialog.confirm')}
            onClick={navigateToOverview}
          >
            {t('expertToolsXML.resetDataDialog.confirm')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Fragment>
  );
};
