import React, { FC, Fragment } from 'react';
import {
  EnergyRatingIndicator,
  FactBox,
  Grid,
  Stack
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { camelCasify } from '../../utils/navigation';
import { SoknadStegInfo } from '../../types/soknad';
import ReadonlyField from '../readonlyField';
import useBuildingDetails from '../../hooks/useBuildingDetails';
import useFormatDate from '../../hooks/useFormatDate';
import { SoknadSteg } from '../registering/utils/registerEnums';

import {
  ArchiveAndCloneRegistration,
  ContinueRegistrationButton,
  DownloadCertificateButton,
  DownloadTiltakslisteButton
} from './components';

type EnovaApplicationListItemProps = SoknadStegInfo & {
  soknadId: string;
  displayActionButtons?: boolean;
  applicationIsCompleted: boolean;
  isPreview: boolean;
  isSteg1Done: boolean;
};

const EnovaApplicationListItem: FC<EnovaApplicationListItemProps> = ({
  analysis,
  done,
  displayActionButtons,
  soknadId,
  soknadSteg,
  id,
  applicationIsCompleted,
  isPreview,
  isSteg1Done
}) => {
  const { t } = useTranslation();
  const { formatDateTime } = useFormatDate();

  const {
    building: { energiObjektId }
  } = useBuildingDetails();

  const endretDato = analysis?.registrering.endretDato;
  const attestUri = analysis?.energiplan?.attestUri;
  const energiKarakter = analysis?.energiplan?.energimerke?.energiKarakter;
  const oppvarmingKarakter = analysis?.energiplan?.energimerke?.properties?.oppvarmingKarakter;

  const showArchiveButton =
    !applicationIsCompleted && done && soknadSteg !== SoknadSteg.Steg3;
  const showDownloadTiltakliste =
    done &&
    (soknadSteg === SoknadSteg.Steg2 || soknadSteg === SoknadSteg.Steg3);

  return (
    <Fragment>
      <Grid item xs={12}>
        <FactBox
          title={t('documentsForEnovaApplication.report.title', {
            context: camelCasify(soknadSteg)
          })}
        >
          <Grid container spacing={4}>
            <Grid container item spacing={4} xs={12}>
              <Grid container item spacing={4} xs={12} sm>
                {endretDato && (
                  <Grid item xs={12}>
                    <ReadonlyField
                      label={t('modifiedDate')}
                      value={formatDateTime(endretDato)}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <ReadonlyField
                    label={t('status')}
                    value={done ? t('status.done') : t('status.ongoing')}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm="auto">
                <EnergyRatingIndicator
                  heatingGrade={done ? oppvarmingKarakter : undefined}
                  energyGrade={done ? energiKarakter : undefined}
                />
              </Grid>
            </Grid>

            {displayActionButtons && (
              <Grid item xs={12} sm={9}>
                <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
                  {!done && (
                    <ContinueRegistrationButton
                      soknadSteg={soknadSteg}
                      soknadId={soknadId}
                      energiObjektId={energiObjektId}
                    />
                  )}

                  {attestUri && (
                    <DownloadCertificateButton
                      isPreview={isPreview}
                      uri={attestUri}
                    />
                  )}

                  {showDownloadTiltakliste && (
                    <DownloadTiltakslisteButton
                      soknadId={soknadId}
                      soknadSteg={soknadSteg}
                      isSteg1Done={isSteg1Done}
                    />
                  )}

                  {showArchiveButton && (
                    <ArchiveAndCloneRegistration
                      registrationId={id}
                      energiplanId={analysis?.energiplan?.energiplanId}
                      energiobjektId={energiObjektId}
                      soknadSteg={soknadSteg}
                    />
                  )}
                </Stack>
              </Grid>
            )}
          </Grid>
        </FactBox>
      </Grid>
    </Fragment>
  );
};

export default EnovaApplicationListItem;
