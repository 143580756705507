import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps
} from 'enova-frontend-components';

export type ReadonlyParagraphFieldProps = BoxProps & {
  label?: string;
  LabelProps?: Partial<TypographyProps>;
  value?: string;
  ValueProps?: Partial<TypographyProps>;
};

const Wrapper = styled((props) => <Box {...props} />)`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

const ReadonlyParagraphField: FC<ReadonlyParagraphFieldProps> = ({
  label,
  LabelProps,
  value,
  ValueProps,
  ...boxProps
}) => {
  const valueArray = value ? value.split('\n') : [];
  return (
    <Wrapper {...boxProps}>
      {label && (
        <Typography variant="h4" component="p" {...LabelProps}>
          {label}
        </Typography>
      )}

      {valueArray.map((item) => {
        if (item === '')
          return <Typography key={item} {...ValueProps} pb={4} />;
        else
          return (
            <Typography key={item} {...ValueProps}>
              {String(item)}
            </Typography>
          );
      })}
    </Wrapper>
  );
};

export default ReadonlyParagraphField;
