import { Box, Divider, Link, Typography } from 'enova-frontend-components';
import { Trans, useTranslation } from 'react-i18next';
import React, { FC, useState } from 'react';

import { AddBuilding } from '../simpleSearch/addBuilding';
import { BuildingSearch } from '../../../../../../types/building';

interface Props {
  queryParams?: Partial<BuildingSearch>;
}

export const MissingUnitBox: FC<Props> = ({ queryParams }) => {
  const [isOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Box p={3} width={1}>
        <Box paddingY={3}>
          <Typography>
            <i>{t('buildingList.search.group.emptyState')}</i>
          </Typography>
        </Box>
        <Divider />
        <Box paddingY={3}>
          <ul>
            <li>{t('searchScreen.addBuilding.tryAdvancedSearch')}</li>
            <li>
              <Trans
                i18nKey="searchScreen.addBuilding.addTempBuilding"
                components={[
                  <Link
                    key={0}
                    button
                    variant="body2"
                    onClick={() => setDialogOpen(true)}
                  />
                ]}
              />
            </li>
          </ul>
        </Box>
      </Box>
      <AddBuilding
        isOpen={isOpen}
        onCancel={() => setDialogOpen(false)}
        queryParams={queryParams}
      />
    </React.Fragment>
  );
};
