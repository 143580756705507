import { Control, Controller } from 'react-hook-form';
import { Box, NumberField, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FloorFormData } from '../../types';

interface Props {
  control: Control<FloorFormData>;
}

export const FloorHeight: FC<Props> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Typography variant="h3">
        {t('register.buildingShape.averageHeight.title')}
      </Typography>
      <Typography>
        {t('register.buildingShape.averageHeight.description')}
      </Typography>

      <Controller
        key="floorHeight"
        control={control}
        rules={{
          validate: (value) =>
            (value && 1.7 <= value && value <= 50) ||
            t('register.buildingShape.averageHeight.error')
        }}
        name="floor.gjennomsnittligEtasjehoyde"
        render={({
          field: { value, onChange, ...rest },
          fieldState: { error }
        }) => (
          <NumberField
            {...rest}
            autoComplete
            error={!!error}
            helperText={error?.message}
            label={t('register.buildingShape.averageHeight.title')}
            name="hoyde-i-meter"
            suffix={t('suffix.m')}
            value={value ?? null}
            onValueChange={({ floatValue }) => onChange(floatValue || null)}
          />
        )}
      />
    </Box>
  );
};
