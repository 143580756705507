import React, { FC, Fragment, useState } from 'react';
import { Button, Dialog } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

interface Props {
  onDelete: () => void;
  isLoading: boolean;
}

export const DeleteRegistrationButton: FC<Props> = ({
  onDelete,
  isLoading
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <Fragment>
      <Button
        disabled={isLoading || isOpen}
        variant="secondary"
        size="medium"
        onClick={() => setOpen(true)}
      >
        {t('energyrating.continue.existing.delete')}
      </Button>

      <Dialog open={isOpen}>
        <Dialog.Title>
          {t('energyrating.continue.existing.delete.dialog.title')}
        </Dialog.Title>

        <Dialog.Content>
          {t('energyrating.continue.existing.delete.dialog.content')}
        </Dialog.Content>

        <Dialog.Actions>
          <Button
            disabled={isLoading}
            onClick={() => setOpen(false)}
            variant="secondary"
          >
            {t('cancel')}
          </Button>

          <Button loading={isLoading} onClick={onDelete}>
            {t('confirm')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Fragment>
  );
};
