import { AreaOfExpertise } from './types/user';

export const externalLinks = {
  footer: {
    shortcuts: {
      about: 'https://www.enova.no/energimerking/om-energimerkeordningen/',
      guide: 'https://www.enova.no/energimerking/bolig/',
      subsidies: 'https://www.enova.no/privat/alle-energitiltak/'
    },
    help: {
      privacyAndCookies: 'https://www.enova.no/om-enova/cookies-og-kildevern/',
      siteMap: 'mailto:nettredaktor@enova.no'
    },
    contact: {
      kontaktoss: 'https://www.enova.no/om-enova/kontakt-oss/',
    }
  },

  profile: {
    [AreaOfExpertise.ENERGIRADGIVER]:
      'https://www.enova.no/privat/alle-energitiltak/energiradgivning/'
  }
};
