import { FC } from 'react';

import { ReactComponent as UShapeNorth } from '../../../../../../../assets/icons/shapes/u-shape/u-shape-1.svg';
import { ReactComponent as UShapeEast } from '../../../../../../../assets/icons/shapes/u-shape/u-shape-2.svg';
import { ReactComponent as UShapeSouth } from '../../../../../../../assets/icons/shapes/u-shape/u-shape-3.svg';
import { ReactComponent as UShapeWest } from '../../../../../../../assets/icons/shapes/u-shape/u-shape-4.svg';
import { Himmelretning } from '../../../../../utils/registerEnums';

import { ShapeProps } from './types';

export const UShape: FC<ShapeProps> = ({ direction }) => {
  switch (direction) {
    case Himmelretning.Nord:
      return <UShapeNorth />;
    case Himmelretning.Ost:
      return <UShapeEast />;
    case Himmelretning.Sor:
      return <UShapeSouth />;
    case Himmelretning.Vest:
      return <UShapeWest />;

    default:
      return <UShapeNorth />;
  }
};
