import { useMutation, useQueryClient } from 'react-query';

import { createTiltakGrupperegistrering } from '../../../services/api';
import { Tiltak } from '../../../types/registration/tiltak';
import { queryKeys } from '../../../utils/react-query';

export const useAddGrupperegistreringTiltak = (
  grupperegistreringId: string | undefined
) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (tiltak: Tiltak) =>
      createTiltakGrupperegistrering({
        ...tiltak,
        grupperegistreringId
      }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          queryKeys.grupperegistreringMeasures,
          grupperegistreringId
        ])
    }
  );

  return { mutate, isLoading };
};
