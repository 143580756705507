import { Grid, Select } from 'enova-frontend-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { GulvType } from '../../../../types/misc';
import { useFloorInsulationOptions } from '../../utils/insulation';

interface FloorConstructionProps {
  gulvType: GulvType | undefined;
  isolasjonstykkelseIMillimeter: number | undefined;
  onChangeGulvType: (value: string) => void;
  onChangeGulvIsolasjon: (value: number) => void;
}

export const FloorConstruction: FC<FloorConstructionProps> = ({
  gulvType,
  isolasjonstykkelseIMillimeter,
  onChangeGulvIsolasjon,
  onChangeGulvType
}) => {
  const { t } = useTranslation();
  const { insulationOptions, floorOptions } =
    useFloorInsulationOptions(gulvType);

  return (
    <Grid container spacing={4}>
      <Grid item maxWidth={1}>
        <Select
          label={t('register.floors.constructionType.label')}
          helperText={t('register.floorConstruction.helperText')}
          value={gulvType ?? -1}
          onChange={({ currentTarget: { value } }) => onChangeGulvType(value)}
          options={floorOptions}
        />
      </Grid>

      <Grid item maxWidth={1}>
        <Select
          disabled={!gulvType}
          label={t('register.isolation.label')}
          helperText={t('register.floorIsolation.helperText')}
          value={isolasjonstykkelseIMillimeter ?? -1}
          onChange={({ currentTarget: { value } }) =>
            onChangeGulvIsolasjon(+value)
          }
          options={insulationOptions}
        />
      </Grid>
    </Grid>
  );
};
