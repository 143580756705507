import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import {
  BuildingScreenParams,
  BuildingScreenTab,
  RegistrationCommonStep
} from '../../../utils/navigation';
import { Tiltak } from '../../../types/registration/tiltak';
import TiltakSection from '../tiltakSection';

import { useUpdateEnergiplanTiltak } from './useUpdateEnergiplanTiltak';
import { useAddEnergiplanTiltak } from './useAddEnergiplanTiltak';
import { useDeleteEnergiplanTiltak } from './useDeleteEnergiplanTiltak';

type TiltakSectionProps = {
  energiplanId: string;
  readOnly?: boolean;
  tiltakList?: Tiltak[];
  allowEnergiobjektTypeOverride?: boolean;
};

type ExistingTiltakParams = {
  step: RegistrationCommonStep;
} & BuildingScreenParams;

const EnergiplanTiltakSection: FC<TiltakSectionProps> = ({
  energiplanId,
  readOnly,
  tiltakList,
  allowEnergiobjektTypeOverride
}) => {
  const { step, tab } = useParams<ExistingTiltakParams>();

  const addTiltakMutation = useAddEnergiplanTiltak(energiplanId);
  const deleteTiltakMutation = useDeleteEnergiplanTiltak(energiplanId);
  const updateTiltakMutation = useUpdateEnergiplanTiltak(energiplanId);

  return (
    <TiltakSection
      addTiltakMutation={addTiltakMutation}
      deleteTiltakMutation={deleteTiltakMutation}
      updateTiltakMutation={updateTiltakMutation}
      tiltakList={tiltakList}
      allowEnergiobjektTypeOverride={allowEnergiobjektTypeOverride}
      readOnly={
        readOnly ||
        step === RegistrationCommonStep.ENERGY_CERTIFICATE ||
        tab === BuildingScreenTab.ENERGY_SAVING_MEASURES
      }
    />
  );
};

export default EnergiplanTiltakSection;
