import React, { FC } from 'react';
import { EnergyGrade as Grade, HeatingGrade } from 'enova-frontend-components';

import TranslatedEnergyRating2D from '../../../../../../../components/translatedEnergyRating2D';

interface Props {
  energyGrade: Grade;
  heatingGrade: HeatingGrade;
}

export const EnergyGrade: FC<Props> = ({ energyGrade, heatingGrade }) => (
  <TranslatedEnergyRating2D
    energyGrade={energyGrade}
    heatingGrade={heatingGrade}
  />
);
