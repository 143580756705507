import { Box, Button, Collapse, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FloorFormData } from '../types';
import { useFloorFormContext } from '../utils';

import { AreaPerFloor } from './components/areaPerFloor';
import { BuildingShapeSelector } from './components/buildingShapeRadioGroup/buildingShapeSelector';
import { ShapeDirection } from './components/shapeDirection';
import { WallLengths } from './components/wallLengths';
import { FloorHeight } from './components/floorHeight';

interface Props {
  onSubmit: (data: FloorFormData) => void;
  onCancel: () => void;
  floorId: string;
  floorTitle: string;
}

export const Form: FC<Props> = ({
  onSubmit,
  floorId,
  onCancel,
  floorTitle
}) => {
  const { t } = useTranslation();
  const { control, watch, handleSubmit } = useFloorFormContext();

  const selectedShape = watch('floor.bygningsForm');

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Typography variant="h3" mb={3}>
        {t('register.buildingShape.floor.title')} - {floorTitle}
      </Typography>
      <Typography>{t('register.buildingShape.description')}</Typography>
      <BuildingShapeSelector control={control} />

      <Collapse in={!!selectedShape}>
        <ShapeDirection />
        <WallLengths />
        <AreaPerFloor floorId={floorId} control={control} />
        <FloorHeight control={control} />
      </Collapse>
      <Box my={3} display="flex" flexDirection="row" gap={2}>
        <Button variant="secondary" type="submit">
          {t('save')}
        </Button>
        <Button variant="secondary" onClick={onCancel}>
          {t('cancel')}
        </Button>
      </Box>
    </form>
  );
};
