import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberField, Radio } from 'enova-frontend-components';

import { camelCasify, kebabCasify } from '../../../../../utils/navigation';
import { WindowSpecType } from '../../../utils/window';
import CollapsibleRegField from '../../../collapsibleRegField/collapsibleRegField';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';
import { WindowErrors, WindowType as TypeOfWindow } from '../../utils';

interface WindowManufactureYearProps {
  errors: WindowErrors;
  onChange: (value: number | undefined) => void;
  fabrikasjonsAr: number | undefined;
  type: TypeOfWindow;
  windowSpecType: WindowSpecType | null;
}

export const WindowManufactureYear: FC<WindowManufactureYearProps> = ({
  errors,
  onChange,
  type,
  fabrikasjonsAr,
  windowSpecType
}) => {
  const { xsScreen } = useMediaQuery();
  const { t } = useTranslation();

  const hasError = !!errors.fabrikasjonsar;

  return (
    <CollapsibleRegField
      open={windowSpecType === WindowSpecType.YEAR_OF_CHANGE}
      transparent={type === 'roof'}
    >
      <Radio
        width={xsScreen ? 'fullWidth' : 'auto'}
        helperText={t('register.windows.knownYear.helperText')}
        className="mb-2"
        label={t(
          `register.window.${camelCasify(WindowSpecType.YEAR_OF_CHANGE)}`
        )}
        value={WindowSpecType.YEAR_OF_CHANGE}
      />

      <CollapsibleRegField.Collapse>
        <NumberField
          autoComplete
          required
          label={t(
            `register.window.${camelCasify(WindowSpecType.YEAR_OF_CHANGE)}`
          )}
          name={kebabCasify(WindowSpecType.YEAR_OF_CHANGE)}
          format="####"
          onValueChange={({ floatValue }) => onChange(floatValue)}
          value={fabrikasjonsAr}
          error={hasError}
          helperText={
            hasError
              ? t(`register.parameters.error.year`, {
                  context: errors?.fabrikasjonsar
                })
              : ''
          }
        />
      </CollapsibleRegField.Collapse>
    </CollapsibleRegField>
  );
};
