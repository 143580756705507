import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from 'enova-frontend-components';
import { shallowEqual } from 'react-redux';

import useSelector from '../../../hooks/useSelector';
import TranslatedEnergyRating2D from '../../../components/translatedEnergyRating2D';
import useBuildingDetails from '../../../hooks/useBuildingDetails';
import useRegistrationForm from '../useRegistrationForm';
import RouterButtonLink from '../../../components/routerButtonLink';
import {
  BuildingScreenTab,
  getBuildingScreenPath,
  getSoknadScreenPath
} from '../../../utils/navigation';
import TiltakListReadOnly from '../../../components/tiltakSection/tiltakListReadOnly';

const EnergyCertificate: FC = () => {
  const { t } = useTranslation();
  const {
    building: { energiObjektId, gyldigSoknad },
    getNextSoknadSteg
  } = useBuildingDetails();
  const { soknadSteg, registrationIsPublished, attestUrl } =
    useRegistrationForm();

  const tiltakList = useSelector(
    (state) => state.register?.energiplan?.gjenstaendeTiltak,
    shallowEqual
  );

  const energiplan = useSelector(
    (state) => state.register.energiplan,
    shallowEqual
  );

  const {
    energimerke: {
      energiKarakter = undefined,
      properties: { oppvarmingKarakter = undefined } = {}
    } = {}
  } = energiplan || {};

  const nextSoknadStage = soknadSteg ? getNextSoknadSteg() : undefined;

  return (
    <Fragment>
      <Typography variant="h2" gutterBottom>
        {t('register.energyCertificate.heading', { context: soknadSteg })}
      </Typography>

      <Typography variant="h3" gutterBottom>
        {t('register.energyCertificate.newRating.heading', {
          context: soknadSteg
        })}
      </Typography>

      <Typography>
        {t('register.energyCertificate.newRating.description', {
          context: soknadSteg
        })}
      </Typography>

      {energiKarakter && oppvarmingKarakter && (
        <Box pt={4}>
          <TranslatedEnergyRating2D
            energyGrade={energiKarakter}
            heatingGrade={oppvarmingKarakter}
          />
        </Box>
      )}

      {registrationIsPublished && attestUrl && (
        <Box mt={4}>
          <Button
            leftIcon="download"
            variant="secondary"
            href={attestUrl}
            disabled={!attestUrl}
          >
            {t('downloadEnergyCertificate')}
          </Button>
        </Box>
      )}

      <Grid mt={4} container spacing={4} direction="column">
        <TiltakListReadOnly tiltakList={tiltakList || []} />

        <Grid item xs={12} sm="auto" mr="auto">
          <RouterButtonLink
            fullWidth
            variant="secondary"
            to={getBuildingScreenPath({
              buildingId: energiObjektId,
              tab: soknadSteg
                ? BuildingScreenTab.DOCUMENTS
                : BuildingScreenTab.ENERGY_RATING
            })}
          >
            {t('navigation.registerScreen.back')}
          </RouterButtonLink>
        </Grid>

        {nextSoknadStage && gyldigSoknad?.soknadId && (
          <Grid item xs={12} sm="auto" mr="auto">
            <RouterButtonLink
              fullWidth
              to={getSoknadScreenPath({
                buildingId: energiObjektId,
                soknadId: gyldigSoknad.soknadId,
                soknadSteg: nextSoknadStage
              })}
            >
              {t('documentsForEnovaApplication.create', {
                context: nextSoknadStage
              })}
            </RouterButtonLink>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default EnergyCertificate;
