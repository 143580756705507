import { useDispatch } from 'react-redux';

import {
  editTakVindu,
  editVindu,
  removeTakVindu,
  removeVindu
} from '../../../../store/registration/actions';
import { Vindu } from '../../../../types/registration/userInput';
import { createWindows, WindowType } from '../utils';

export const useUpdateWindow = (type: WindowType) => {
  const dispatch = useDispatch();

  const removeWindow = (vinduIds: string[]) =>
    dispatch(
      type === 'roof'
        ? removeTakVindu({ vinduIds })
        : removeVindu({
            etasjeId: type.etasjeId,
            veggId: type.veggId,
            veggsoneId: type.veggsoneId,
            vinduIds
          })
    );

  const editWindow = (
    vindu: Vindu,
    numberOfEqualWindows: number,
    vinduIds: string[]
  ) => {
    dispatch(
      type === 'roof'
        ? editTakVindu({ vindu })
        : editVindu({
            etasjeId: type.etasjeId,
            veggId: type.veggId,
            veggsoneId: type.veggsoneId,
            windows: createWindows(vindu, numberOfEqualWindows),
            vinduIds
          })
    );
  };

  return { editWindow, removeWindow };
};
