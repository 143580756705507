/* eslint-disable camelcase */
import React, { FC } from 'react';
import {
  FactBox,
  FormMessage,
  Grid,
  styled,
  Table,
  TTableData,
  TTableHeadCell
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'underscore';
import classNames from 'classnames';

import { isXMLRegistration } from '../../../../../../../utils/expertToolsXML/typeGuard';
import { XMLReport } from '../../../../../../../types/expertToolsXML';
import { camelCasify } from '../../../../../../../utils/navigation';
import { SimpleEnergyAnalysis } from '../../../../../../../types/building';

type Value = string | number | boolean;

type TableData = TTableData & {
  field: string;
  value: Value;
};

type TableFieldDef = {
  /**
   * Table rows with bold text bold
   */
  bold?: string[];
  /**
   * Fields to skip
   */
  skip?: string[];
};

const TableCategoryDefs: Record<string, TableFieldDef> = {
  adresseInfo: { skip: ['created'] },
  maltEnergi: { bold: ['totalt'] },
  beregningsResultat: { bold: ['totaltNettoEnergibehov', 'totalt'] },
  maltEnergiPerAr: { bold: ['aar', 'totalt'] }
};

type Category = {
  id: string;
  name?: string;
  fieldset: Record<string, Value>;
};

const StyledTable = styled(Table)`
  .bold * {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }
` as typeof Table;

const withPrefix = (obj: Record<string, Value>, prefix: string) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({ ...acc, [`${prefix}${key}`]: value }),
    {}
  );

interface Props {
  energyAnalysis: SimpleEnergyAnalysis;
}

const FileContent: FC<Props> = ({ energyAnalysis }) => {
  const { t } = useTranslation();

  const ekspertInndata =
    energyAnalysis?.energiplan?.energimerke?.ekspertInndata;

  let parsedData;
  let isValid = false;

  try {
    if (ekspertInndata) {
      parsedData = JSON.parse(ekspertInndata);
      isValid = isXMLRegistration(parsedData);
    }
  } catch (err: unknown) {
    console.error(err);

    return (
      <Grid item mb={8} xs={12}>
        <FormMessage fullWidth title={t('somethingWentWrong')}>
          {t('tiltak.parseError')}
        </FormMessage>
      </Grid>
    );
  }

  const headCells: readonly TTableHeadCell<TableData>[] = [
    { disableSort: true, id: 'field', isMainProperty: true, label: t('field') },
    { align: 'right', disableSort: true, id: 'value', label: t('value') }
  ];

  const {
    Enheter: {
      Enhet: {
        Beregningsresultat: {
          ForventetLevertEnergi: { LevertEnergi = {} } = {},
          LevertEnergiLokaltKlima = '',
          LevertEnergiNormalisertKlima = '',
          LevertEnergiOppvarmingVarmtvannNormalisertKlima = '',
          LevertEnergiPrKvmLokaltKlima = '',
          LevertEnergiSpesifiktNormalisertKlima = '',
          MaltEnergi = {},
          MaltEnergiPrAar = {},
          NettoEnergibudsjettPrKvm = {}
        } = {},
        Inngangsverdi: {
          ArealAndelVinduDorGlass = '',
          BRA = '',
          BRAOppvarmet = '',
          Byggear = '',
          ByggTypeTEK: { NyEksisterendeBygg = '', TekStandard = '' } = {},
          Bygningskategori = '',
          BygningskategoriId = '',
          Bygningstype = '',
          DatoBeregning = '',
          Driftstider = {},
          EffektRomkjolingVentilasjonskjoling = '',
          EffektRomoppvarmingVentilasjonsvarme = '',
          Energiradgiver = {},
          Energivurdering = {},
          FriBeskrivelse = '',
          KarmfaktorGjSn = '',
          KjolefaktorKjolesystemetGjSn = '',
          KlimastasjonKilde = '',
          Lekkasjetall = '',
          LekkasjetallDatoMaling = '',
          M2Gulv = '',
          M2Tak = '',
          M2VinduDorGlass = '',
          M2Yttervegger = '',
          M3OppvarmetLuft = '',
          NormalisertKuldebro = '',
          NormalisertVarmekapasitet = '',
          Oppvarmingssystem = '',
          Programvare = {},
          SettpunkttemperaturKjoling = '',
          SettpunkttemperaturOppvarming = '',
          SolfaktorVinduSolskjerming = '',
          Solskjerming = '',
          Solskjermingsfaktor = '',
          SpesifikkPumpeeffekt = '',
          SpesifiktEffektbehovBelysningIDriftstiden = '',
          SpesifiktEffektbehovUtstyrIDriftstiden = '',
          SpesifiktEffektbehovVarmtvannIDriftstiden = '',
          SpesifiktVarmetilskuddBelysningIDriftstiden = '',
          SpesifiktVarmetilskuddUtstyrIDriftstiden = '',
          SystemvirkningsgradOppvarmingssystemet = '',
          TempVirkningsgradVarmegjenvinner = '',
          TempVirkningsgradVarmegjenvinnerFrostsikring = '',
          UGulv = '',
          UTak = '',
          UVinduer = '',
          UYttervegger = '',
          Varmefordelingssystem = '',
          VarmekildeVarmepumpeOgFordeling = '',
          VarmetilskuddPersonerIDriftstiden = '',
          VarmetilskuddVarmtvannIDriftstiden = '',
          VentilasjonsluftmengdeIDriftstidenGjSn = '',
          VifteeffektLuftmengderIDriftstiden = '',
          VifteeffektLuftmengderUtenforDriftstiden = ''
        } = {}
      } = {}
    } = {}
  } = isValid ? parsedData : ({} as XMLReport);

  const maltEnergiPerArDefined =
    MaltEnergiPrAar &&
    MaltEnergiPrAar.Aar1 &&
    MaltEnergiPrAar.Aar2 &&
    MaltEnergiPrAar.Aar3 &&
    (Object.values(MaltEnergiPrAar.Aar1).some(
      (x) => typeof x === 'number' && x > 0
    ) ||
      Object.values(MaltEnergiPrAar.Aar2).some(
        (x) => typeof x === 'number' && x > 0
      ) ||
      Object.values(MaltEnergiPrAar.Aar3).some(
        (x) => typeof x === 'number' && x > 0
      ));

  const categories: Record<string, Category[]> = {
    beregningsResultat: [
      {
        id: uniqueId(),
        name: 'nettoEnergibudsjettPrKvm',
        fieldset: NettoEnergibudsjettPrKvm
      },
      {
        id: uniqueId(),
        name: 'levertEnergi',
        fieldset: {
          levertEnergiNormalisertKlima: LevertEnergiNormalisertKlima,
          levertEnergiSpesifiktNormalisertKlima:
            LevertEnergiSpesifiktNormalisertKlima,
          levertEnergiOppvarmingVarmtvannNormalisertKlima:
            LevertEnergiOppvarmingVarmtvannNormalisertKlima,
          levertEnergiPrKvmLokaltKlima: LevertEnergiPrKvmLokaltKlima,
          levertEnergiLokaltKlima: LevertEnergiLokaltKlima
        }
      },
      {
        id: uniqueId(),
        name: 'levertEnergi',
        fieldset: {
          ...LevertEnergi
        }
      }
    ],
    ...(!maltEnergiPerArDefined && {
      maltEnergi: [{ id: uniqueId(), fieldset: MaltEnergi }]
    }),
    ...(maltEnergiPerArDefined && {
      maltEnergiPerAr: [
        { id: uniqueId(), fieldset: MaltEnergiPrAar.Aar1 },
        { id: uniqueId(), fieldset: MaltEnergiPrAar.Aar2 },
        { id: uniqueId(), fieldset: MaltEnergiPrAar.Aar3 }
      ]
    }),
    inngangsverdier: [
      {
        id: uniqueId(),
        fieldset: {
          Bygningskategori,
          BygningskategoriId,
          Bygningstype,
          Byggear,
          NyEksisterendeBygg,
          TekStandard,
          ...withPrefix(Energivurdering, 'energivurdering'),
          M2Yttervegger,
          M2Tak,
          M2Gulv,
          M2VinduDorGlass,
          BRAOppvarmet,
          BRA,
          M3OppvarmetLuft,
          UYttervegger,
          UTak,
          UGulv,
          UVinduer,
          ArealAndelVinduDorGlass,
          NormalisertKuldebro,
          NormalisertVarmekapasitet,
          Lekkasjetall,
          LekkasjetallDatoMaling,
          TempVirkningsgradVarmegjenvinner,
          TempVirkningsgradVarmegjenvinnerFrostsikring,
          VifteeffektLuftmengderIDriftstiden,
          VifteeffektLuftmengderUtenforDriftstiden,
          VentilasjonsluftmengdeIDriftstidenGjSn,
          SystemvirkningsgradOppvarmingssystemet,
          EffektRomoppvarmingVentilasjonsvarme,
          SettpunkttemperaturOppvarming,
          KjolefaktorKjolesystemetGjSn,
          SettpunkttemperaturKjoling,
          EffektRomkjolingVentilasjonskjoling,
          SpesifikkPumpeeffekt,
          ...withPrefix(Driftstider, 'driftstider'),
          SpesifiktEffektbehovBelysningIDriftstiden,
          SpesifiktVarmetilskuddBelysningIDriftstiden,
          SpesifiktEffektbehovUtstyrIDriftstiden,
          SpesifiktVarmetilskuddUtstyrIDriftstiden,
          SpesifiktEffektbehovVarmtvannIDriftstiden,
          VarmetilskuddVarmtvannIDriftstiden,
          VarmetilskuddPersonerIDriftstiden,
          SolfaktorVinduSolskjerming,
          KarmfaktorGjSn,
          Solskjermingsfaktor,
          Oppvarmingssystem,
          Varmefordelingssystem,
          VarmekildeVarmepumpeOgFordeling,
          Solskjerming,
          KlimastasjonKilde,
          DatoBeregning,
          FriBeskrivelse
        }
      }
    ],
    programvare: [{ id: uniqueId(), fieldset: Programvare }],
    energiradgiver: [{ id: uniqueId(), fieldset: Energiradgiver }]
  };

  if (!isValid) {
    return null;
  }

  return (
    <Grid item xs={12} mb={8} container spacing={4} direction="column">
      {Object.entries(categories).map(([category, fieldsets]) => {
        const tKeyBase = `expertToolsXML.review.${category}`;

        return (
          <Grid item key={category}>
            <FactBox dense title={t(`${tKeyBase}.title`)}>
              <Grid container spacing={6} direction="column">
                {fieldsets.map(({ fieldset, id, name }) => {
                  const tKeyFieldsetBase = [
                    tKeyBase,
                    ...(name ? [name] : [])
                  ].join('.');

                  return (
                    <Grid item key={id}>
                      <StyledTable<TableData>
                        alternating
                        dense
                        disablePagination
                        headCells={headCells}
                        hideHeader
                        hideToolbar
                        rows={Object.entries(fieldset).reduce(
                          (acc: TableData[], [field, value]) => {
                            const f = camelCasify(field.split('_')[0]);

                            if (TableCategoryDefs[category]?.skip?.includes(f))
                              return acc;

                            const fieldId = `${f}_${uniqueId()}`;

                            const displayValue =
                              typeof value === 'boolean'
                                ? t(value ? 'yes' : 'no')
                                : value;

                            return [
                              ...acc,
                              {
                                field: t(`${tKeyFieldsetBase}.${f}`),
                                value: displayValue,
                                id: field,
                                TableCellPropMap: {
                                  field: {
                                    id: fieldId,
                                    scope: 'row',
                                    component: 'th'
                                  },
                                  value: { headers: `${fieldId} value` }
                                },
                                TableRowProps: {
                                  className: classNames({
                                    bold: TableCategoryDefs[
                                      category
                                    ]?.bold?.includes(f)
                                  })
                                }
                              }
                            ];
                          },
                          []
                        )}
                        shadow="none"
                        square
                        tableTitle={t(`${tKeyFieldsetBase}.title`)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </FactBox>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FileContent;
