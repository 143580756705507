import React, { FC, Fragment } from 'react';
import {
  Accordion,
  Box,
  CollapsibleFactBox,
  Grid,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import useSelector from '../../../../../../../hooks/useSelector';
import { getEtasjer } from '../../../../../../../store/registration/selectors';
import { InnerAccordion } from '../../../../../../../components/innerAccordion';

import WindowsSummaryContent from './windowsSummaryContent';

const WindowsSummarySection: FC = () => {
  const { t } = useTranslation();
  const etasjer = useSelector(getEtasjer, shallowEqual);

  const multipleFloors = etasjer.length > 1;

  return (
    <Fragment>
      {etasjer.map(({ id: etasjeId, erKjeller, etasjeNummer, vegger }) => {
        const tOptions = {
          context: multipleFloors
            ? erKjeller
              ? 'basement'
              : 'floorNo'
            : 'singleFloor',
          count: etasjeNummer
        };

        return (
          <Grid item xs={12} key={etasjeId}>
            <CollapsibleFactBox
              title={t('register.windows.sectionTitle', tOptions)}
            >
              <Box mx={-4} my={-5}>
                <Accordion>
                  {vegger?.map(({ veggId, veggSoner }, index) => (
                    <Accordion.Item
                      key={veggId}
                      initExpanded={index === 0}
                      title={
                        <Typography bold component="h4" mx={1}>
                          {t('wall', {
                            veggId: veggId.toUpperCase()
                          })}
                        </Typography>
                      }
                    >
                      <Box mx={1}>
                        {veggSoner.length > 1 ? (
                          <InnerAccordion>
                            <Box>
                              {veggSoner?.map(
                                (
                                  { id: veggsoneId, vinduer },
                                  veggsoneIndex
                                ) => (
                                  <Accordion.Item
                                    title={
                                      <Typography bold px={1} component="h5">
                                        {t('register.wallZone.wallZoneId', {
                                          wallZoneId: `${veggId.toUpperCase()}${
                                            veggsoneIndex + 1
                                          }`
                                        })}
                                      </Typography>
                                    }
                                    initExpanded={veggsoneIndex === 0}
                                    key={veggsoneId}
                                  >
                                    <WindowsSummaryContent
                                      veggId={veggId}
                                      veggsoneIndex={veggsoneIndex}
                                      vinduer={vinduer}
                                    />
                                  </Accordion.Item>
                                )
                              )}
                            </Box>
                          </InnerAccordion>
                        ) : (
                          veggSoner?.[0] && (
                            <WindowsSummaryContent
                              vinduer={veggSoner?.[0]?.vinduer}
                              veggId={veggId}
                            />
                          )
                        )}
                      </Box>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </Box>
            </CollapsibleFactBox>
          </Grid>
        );
      })}
    </Fragment>
  );
};

export default WindowsSummarySection;
