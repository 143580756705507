import { FullScreenLoader, Grid } from 'enova-frontend-components';
import { FormProvider, useForm } from 'react-hook-form';
import { FC } from 'react';

import { BuildingDetails } from '../../../../../../../../types/building';

import { Form } from './form';
import { SearchResults } from './searchResults';
import { BuildingSearchFormData } from './types';
import { useBuildingSearchDefaultValues } from './form/useBuildingSearchDefaultValues';
import { useSearchBuilding } from './useSearchBuilding';
import { searchFormResolver } from './searchFormResolver';

interface Props {
  onResultItemSelect: (building: BuildingDetails) => void;
  isMultiSelect: boolean;
}

const BuildingSearchView: FC<Props> = ({
  onResultItemSelect,
  isMultiSelect
}) => {
  const { defaultValues } = useBuildingSearchDefaultValues();

  // Require kommunenummer + gårdsnummer and bruksnummer or gatenavn or bygningsnummer to search
  const isSearchEnabled =
    !!defaultValues.kommunenummer &&
    (!!defaultValues.gatenavn ||
      !!defaultValues.bygningsnummer ||
      !!defaultValues.gardsnummer);

  const methods = useForm<BuildingSearchFormData>({
    defaultValues,
    resolver: searchFormResolver
  });

  const { error, searchResults, isLoading, refetch } = useSearchBuilding(
    defaultValues,
    isSearchEnabled
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mb={6}>
        <Grid container spacing={4}>
          <Grid item>
            <FormProvider {...methods}>
              <Form />
            </FormProvider>
          </Grid>
        </Grid>
      </Grid>

      <SearchResults
        error={error}
        searchResult={searchResults}
        refetch={refetch}
        onResultItemSelect={onResultItemSelect}
        isMultiSelect={isMultiSelect}
      />
      <FullScreenLoader open={isLoading} />
    </Grid>
  );
};

export default BuildingSearchView;
