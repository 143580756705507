import React, { FC, Fragment } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { styled } from 'enova-frontend-components';

import useSelector from '../hooks/useSelector';
import useFormatDate from '../hooks/useFormatDate';
import {
  CheckboxType,
  CheckButtonType,
  CheckFieldTypes,
  Field,
  FieldType,
  NumberType,
  RadioButtonType,
  RadioFieldTypes,
  RadioType,
  SelectType
} from '../types/registration/fieldTypes';

import ReadonlyField, { ReadonlyFieldProps } from './readonlyField';

type RecursiveReadonlyFieldProps = FieldType &
  Pick<ReadonlyFieldProps, 'LabelProps' | 'ValueProps'> & {
    groupFields?: boolean;
    hideLabel?: boolean;
    innerField?: boolean;
  };

const GroupWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
    padding-bottom: ${({ theme }) => theme.spacing(4)};

    &:after {
      background: ${({ theme }) => theme.palette.divider};
      content: '';
      height: 1px;
      left: 0;
      margin-top: ${({ theme }) => theme.spacing(4)};
      position: absolute;
      width: 100%;
    }
  }
`;

const RecursiveReadonlyField: FC<RecursiveReadonlyFieldProps> = ({
  condition,
  groupFields,
  hideLabel,
  innerField,
  LabelProps,
  path,
  selector,
  tLabelKey,
  type,
  ValueProps,
  ...rest
}) => {
  const { t } = useTranslation();

  const { formatDate } = useFormatDate();

  const displayField = useSelector((state) =>
    condition ? condition(state) : true
  );

  const selectorValue = selector ? useSelector(selector, _.isEqual) : undefined;

  if (
    !displayField ||
    tLabelKey == null ||
    selectorValue == null ||
    type === Field.TYPOGRAPHY
  )
    return null;

  if (RadioFieldTypes.includes(type)) {
    const {
      commonFields,
      options,
      valueType = 'string'
    } = rest as RadioButtonType | RadioType;

    if (selectorValue == null) return null;

    let value: unknown;

    if (valueType === 'object' || valueType === 'boolean')
      value = t(selectorValue ? 'yes' : 'no');

    const option = options.find((o) => o.value === selectorValue);
    value = option != null ? t(option.tLabelKey) : null;

    const Content = () => (
      <Fragment>
        <ReadonlyField
          label={
            hideLabel
              ? undefined
              : t(tLabelKey || (path ? `register.${path.join('.')}.label` : ''))
          }
          LabelProps={LabelProps}
          value={value}
          ValueProps={ValueProps}
        />

        {[
          ...(commonFields?.[selectorValue as string] || []),
          ...(option?.fields || [])
        ]?.map((defs) => (
          <RecursiveReadonlyField
            innerField
            key={defs.key ?? defs.attrKey}
            {...defs}
          />
        ))}
      </Fragment>
    );

    return groupFields ? (
      <GroupWrapper>
        <Content />
      </GroupWrapper>
    ) : (
      <Content />
    );
  }

  if (CheckFieldTypes.includes(type)) {
    const { options } = rest as CheckButtonType | CheckboxType;

    return (
      <Fragment>
        {options.map(
          ({ fields, tLabelKey: optionTLabelKey, ...optionProps }) => {
            const isChecked = !!((selectorValue || []) as string[])?.includes(
              optionProps.value as string
            );

            if (!isChecked) return null;

            const Content = () => (
              <Fragment>
                <ReadonlyField
                  label={hideLabel ? undefined : t(optionTLabelKey)}
                  LabelProps={LabelProps}
                  value={
                    innerField
                      ? t('yes')
                      : t(
                          `register.${path!.join('.')}.${
                            optionProps.value
                          }.heading`
                        )
                  }
                  ValueProps={ValueProps}
                />

                {fields?.map((defs) => (
                  <RecursiveReadonlyField
                    key={defs.key ?? defs.attrKey}
                    innerField
                    {...defs}
                  />
                ))}
              </Fragment>
            );

            return groupFields ? (
              <GroupWrapper key={optionProps.value}>
                <Content />
              </GroupWrapper>
            ) : (
              <Content key={optionProps.value} />
            );
          }
        )}
      </Fragment>
    );
  }

  const getValue = () => {
    switch (type) {
      case Field.SELECT: {
        const { options } = rest as SelectType;
        const option = options.find((o) => o.value === selectorValue);
        return option != null ? t(option.label as string) : null;
      }
      case Field.INTEGER:
      case Field.DECIMAL:
      case Field.YEAR: {
        const { tSuffixKey } = rest as NumberType;
        return tSuffixKey ? `${selectorValue} ${t(tSuffixKey)}` : selectorValue;
      }
      case Field.DATE:
        return formatDate(selectorValue as string);
      default:
        return null;
    }
  };

  const value = getValue();

  if (value == null) return null;

  return (
    <ReadonlyField
      label={hideLabel ? undefined : t(tLabelKey)}
      LabelProps={LabelProps}
      value={value}
      ValueProps={ValueProps}
    />
  );
};

export default RecursiveReadonlyField;
