import { EnergyGrade, HeatingGrade } from 'enova-frontend-components';

export type AggregatedStatistics = {
  aggregertStatistikkId: string;
  publisertAr: bigint;
  publisertManed: bigint;
  utlopsAr: bigint;
  energiObjektType: string;
  bygningsKategori: string;
  byggType: string;
  typemerke: string;
  kommuneNummer: string;
  byggAr: bigint;
  energiKarakter: EnergyGrade;
  oppvarmingKarakter: HeatingGrade;
  total: number;
};

export type AggregatedStatisticsMap = {
  [key: string]: AggregatedStatistics;
};

export type AggregatedStatisticsByMonth = {
  id: string;
  publishedYear: bigint;
  january: number;
  february: number;
  march: number;
  april: number;
  may: number;
  june: number;
  july: number;
  august: number;
  september: number;
  october: number;
  november: number;
  december: number;
  total: number;
  accumulated: number;
};

export type AggregatedStatisticsByMonthMap = {
  [key: string]: AggregatedStatisticsByMonth;
};

export const Years = [
  '2040',
  '2039',
  '2038',
  '2037',
  '2036',
  '2035',
  '2034',
  '2033',
  '2032',
  '2031',
  '2030',
  '2029',
  '2028',
  '2027',
  '2026',
  '2025',
  '2024',
  '2023',
  '2022',
  '2021',
  '2020',
  '2019',
  '2018',
  '2017',
  '2016',
  '2015',
  '2014',
  '2013',
  '2012',
  '2011',
  '2010',
  '2009'
] as const;

export type Year = typeof Years[number];

export type AggregatedStatisticsByYear = Record<Year, number> & {
  id: string;
  buildingCategory: string;
  buildingType: string;
  total: number;
  accumulated: number;
};

export type AggregatedStatisticsByYearMap = {
  [key: string]: AggregatedStatisticsByYear;
};

export type AggregatedStatisticsByEnergikarakter = Record<EnergyGrade, number> &
  Record<HeatingGrade, number> & {
    id: string;
    publishedYear: string;
    energyGrade: string;
    total: number;
    accumulated: number;
  };

export type AggregatedStatisticsByEnergikarakterMap = {
  [key: string]: AggregatedStatisticsByEnergikarakter;
};

export type AggregatedStatisticsByEnergikarakterTable = Record<
  EnergyGrade,
  string
> &
  Record<HeatingGrade, string> & {
    id: string;
    publishedYear: string;
    energyGrade: string;
    total: string;
    accumulated: string;
  };

export type AggregatedStatisticsByEnergikarakterTableMap = {
  [key: string]: AggregatedStatisticsByEnergikarakterTable;
};

export type StatistikkRequest = {
  kommune?: Set<string>;
  buildingCategory?: Set<string>;
  aggregation: StatistikkRequestType;
};

export enum StatistikkRequestType {
  OVERTID = 'Overtid',
  BYGGTYPE = 'ByggType',
  ENERGIKARAKTER = 'Energikarakter'
}
