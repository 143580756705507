import React from 'react';

import { BuildingSelect } from '../../common/steps/buildingSelect';
import { useBuildingSelectEnhet } from '../hooks/useBuildingSelectEnhet';

export const EnhetBuildingSelect = () => {
  const { onSelect, toggleMultiSelect } = useBuildingSelectEnhet();

  return (
    <BuildingSelect
      onSelect={onSelect}
      toggleMultiSelect={toggleMultiSelect}
      registrationType="enhet"
    />
  );
};
