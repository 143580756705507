import { useQuery } from 'react-query';

import { getPortefoljeTiltak } from '../../../../services/api';
import { queryKeys } from '../../../../utils/react-query';

export const useTiltakPortefolje = (portefoljeId: string | undefined) => {
  const { data: tiltakList } = useQuery(
    [queryKeys.portfolioMeasures, portefoljeId],
    () => getPortefoljeTiltak(portefoljeId)
  );

  return {
    tiltakList
  };
};
