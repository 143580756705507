import { Radio, RadioGroup } from 'enova-frontend-components';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { CertificateVisibility } from '../../types/building';

interface Props {
  onChange: (visibility: CertificateVisibility) => void;
  value: CertificateVisibility;
  isLoading: boolean;
}

export const CertificateVisibilityRadio: FC<Props> = ({
  onChange,
  value,
  isLoading
}) => {
  const { t } = useTranslation();
  return (
    <RadioGroup
      value={value}
      disabled={isLoading}
      onChange={(_, value) => onChange(value as CertificateVisibility)}
    >
      <Radio
        value="offentlig"
        width="fullWidth"
        aria-label={t('energyCertificateVisibilityConsent.dialog.radio.grant')}
        label={t('energyCertificateVisibilityConsent.dialog.radio.grant')}
      />
      <Radio
        value="reservert"
        width="fullWidth"
        aria-label={t('energyCertificateVisibilityConsent.dialog.radio.revoke')}
        label={
          <Fragment>
            {t('energyCertificateVisibilityConsent.dialog.radio.revoke')}
            <sup>2</sup>
          </Fragment>
        }
      />
    </RadioGroup>
  );
};
