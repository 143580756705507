import { Box, RadioGroup, styled, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setWallHasNoWindows } from '../../../../../store/registration/actions';
import { VeggId } from '../../../utils/registerEnums';
import { IngenVindu, Vindu } from '../../../../../assets/icons/window';
import { SvgRadioButton } from '../../../../svgRadioButton';

const RadioButtonsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
`;

interface Props {
  value: boolean;
  etasjeId: string;
  veggId: VeggId;
}

export const HasWindowsRadio: FC<Props> = ({ value, etasjeId, veggId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onHasWindowsChange = (value: boolean) =>
    dispatch(
      setWallHasNoWindows({
        value,
        etasjeId: etasjeId,
        veggId: veggId
      })
    );

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <RadioGroup
        marginBottom
        row
        value={value}
        onChange={(_, value) => onHasWindowsChange(value === 'true')}
      >
        <RadioButtonsWrapper>
          <SvgRadioButton
            value={true}
            label={
              <Box display="flex" flexDirection="row" gap={1}>
                <Vindu />
                <Typography>{t('register.window.addNew')}</Typography>
              </Box>
            }
          />
          <SvgRadioButton
            value={false}
            label={
              <Box gap={1} display="flex" flexDirection="row">
                <IngenVindu />
                <Typography>{t('register.window.noWindows.label')}</Typography>
              </Box>
            }
          />
        </RadioButtonsWrapper>
      </RadioGroup>
    </Box>
  );
};
