import React from 'react';

import { Upload } from '../../common/steps/upload';
import { useHandleCreateFlerboligRegistration } from '../hooks/useHandleCreateFlerboligRegistration';

export const FlerboligUpload = () => {
  const handleRegistrationHook = useHandleCreateFlerboligRegistration();

  return (
    <Upload
      registrationType="flerbolig"
      handleCreateRegistrationHook={handleRegistrationHook}
    />
  );
};
