import { Fragment, useState } from 'react';
import { Box, Icon, Link, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

export const ReadMoreSection = () => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <Fragment>
      <Box display="flex" flexDirection="row" alignContent="center" my={3}>
        <Link
          button
          transparentBackground
          onClick={() => setExpanded(!expanded)}
        >
          {expanded
            ? t('energyCertificateVisibilityConsent.dialog.more.hide')
            : t('energyCertificateVisibilityConsent.dialog.more.show')}
          <Icon name={expanded ? 'chevron_up' : 'chevron_down'} />
        </Link>
      </Box>
      {expanded && (
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="p">
            <sup style={{ marginRight: '10px' }}>1</sup>
            {t('energyCertificateVisibilityConsent.dialog.info_extended')}
          </Typography>
          <Typography variant="p">
            <sup style={{ marginRight: '10px' }}>2</sup>
            {t(
              'energyCertificateVisibilityConsent.dialog.radio.revoke_extended'
            )}
          </Typography>
        </Box>
      )}
    </Fragment>
  );
};
