import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  XMLParams,
  XmlRegistrationStep,
  XmlRegistrationType
} from '../../types';
import { FlerboligRegistrationXMLParams } from '../../flerbolig/types';

type CombinedXMLParams = XMLParams & FlerboligRegistrationXMLParams;

export const useXmlStepNavigation = () => {
  const { registrationId, flerboligId } = useParams<CombinedXMLParams>();
  const history = useHistory();
  const location = useLocation();

  const navigateToStep = (
    step: XmlRegistrationStep | null,
    type: XmlRegistrationType
  ) => {
    const id = type === 'flerbolig' ? flerboligId : registrationId;

    history.push({
      pathname: `/xml-registrering/${type}/${step}/${id || ''}`,
      search: location.search,
      state: location.state
    });
  };

  return { navigateToStep };
};
