import { useQuery } from 'react-query';

import { queryKeys } from '../../../utils/react-query';
import { getExpertReports } from '../../../services/api';
import {
  ExpertToolsKuldeArea,
  ExpertToolsVarmeArea
} from '../../../utils/navigation';
import { ExpertReport } from '../../../types/building';

const filterAndSortExpertReports = (
  items: ExpertReport[],
  predicate: ExpertToolsKuldeArea | ExpertToolsVarmeArea
) => {
  return items
    .filter((item) => item.ekspertRapportType === predicate)
    .sort((a, b) => {
      const dateA = a.opprettetDato ? new Date(a.opprettetDato).getTime() : 0;
      const dateB = b.opprettetDato ? new Date(b.opprettetDato).getTime() : 0;

      if (!dateA || !dateB) {
        return 0;
      }

      return dateB - dateA;
    });
};

export const useBuildingExpertReports = (buildingId: string) => {
  const { data } = useQuery(
    [queryKeys.expertReports, buildingId],
    () => getExpertReports(buildingId),
    {
      staleTime: 1000 * 60 * 2
    }
  );

  const expertReports = data ?? [];

  const kjoleanlegg = filterAndSortExpertReports(
    expertReports,
    ExpertToolsKuldeArea.KJOLEANLEGG
  );
  const ventilasjon = filterAndSortExpertReports(
    expertReports,
    ExpertToolsKuldeArea.VENTILASJON
  );
  const varmeanlegg = filterAndSortExpertReports(
    expertReports,
    ExpertToolsVarmeArea.VARMEANLEGG
  );
  const elkjel = filterAndSortExpertReports(
    expertReports,
    ExpertToolsVarmeArea.ELKJEL
  );

  // We only need the earliest rapport of every category
  return {
    expertReports: {
      kjoleanlegg: kjoleanlegg?.[0],
      ventilasjon: ventilasjon?.[0],
      varmeanlegg: varmeanlegg?.[0],
      elkjel: elkjel?.[0]
    }
  };
};
