import React, { FC } from 'react';
import { Select, SelectOption } from 'enova-frontend-components';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useWallZones } from '../../utils';
import { WindowFormFieldProps } from '../utils';

export const WallZoneSelect: FC<WindowFormFieldProps> = ({ control }) => {
  const { t } = useTranslation();
  const windowData = useWatch({ control, name: 'windowData' });

  const { wallZones } = useWallZones(windowData.etasjeId, windowData.wallId);

  const detailingOptions: SelectOption[] = [
    {
      label: t('select'),
      value: -1,
      disabled: true
    }
  ];

  wallZones?.map((zone, index) =>
    detailingOptions.push({
      label: t('register.wallZone.wallZoneId', { wallZoneId: index + 1 }),
      value: zone.id
    })
  );

  return (
    <Controller
      control={control}
      name="windowData.wallZoneId"
      rules={{
        validate: (value) => value !== -1 || t('required')
      }}
      render={({ field: { value, onChange }, fieldState }) => (
        <Select
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          label={t('register.window.selectWallZone')}
          options={detailingOptions}
          value={value ?? -1}
          onChange={(e) => {
            onChange(e.currentTarget.value);
          }}
        />
      )}
    />
  );
};
