import { useQuery } from 'react-query';

import { getUnpublishedExpertRegistrations } from '../../../../../services/api';
import { queryKeys } from '../../../../../utils/react-query';

export const useGetUserRegistrations = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    [queryKeys.userExpertRegistrations],
    getUnpublishedExpertRegistrations
  );

  return { isLoading, data, isError, refetch };
};
