import React, { FC, Fragment, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  FullScreenLoader,
  Grid,
  Typography,
  useSnack
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { XMLParser } from 'fast-xml-parser';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
  camelCasify,
  ExpertToolsXMLPortfolioWorklistParams
} from '../../../../../utils/navigation';
import { FileType } from '../../../../../components/fileUpload/utils';
import FileUploadMultiple from '../../../../../components/fileUpload/fileUploadMultiple';
import { XMLParserOptions } from '../../../../../utils/expertToolsXML/helpers';
import {
  isXMLPortfolio,
  XMLReportError
} from '../../../../../utils/expertToolsXML/typeGuard';
import { ExpertToolsXMLPortfolioStep } from '../../../../../utils/expertToolsXML/enums';
import { XMLReport } from '../../../../../types/expertToolsXML';
import { addReportsToPortefolje } from '../../../../../services/api';
import useExpertToolsXMLPortfolio from '../../useExpertToolsXMLPortfolio';
import { createErrorMessage } from '../../../../../utils/utils';
import { XMLValidationErrorList } from '../../../../main/xmlRegistrationView/xmlRegistration/common/components/XMLValidationErrorList';

import RegistrationList from './registrationList';

enum UploadError {
  INVALID_FILE_TYPE = 'invalidFileType',
  INVALID_FORMAT = 'invalidFormat',
  PARSING = 'parse'
}

type APIError = { BruksenhetsIdentId?: number; error?: string; id: string };

const XMLReportErrorMessage: FC<{ xmlErrors: XMLReportError[] }> = ({
  xmlErrors
}) => {
  const { t } = useTranslation();

  return (
    <li>
      <Typography>
        {t('expertToolsXMLPortfolio.upload.error.fileFormat', {
          count: xmlErrors.length
        })}
      </Typography>
      <ul>
        {xmlErrors.map((reportError, index) => (
          // Intentionally using index, as we're not manipulating the list
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>
            <Typography>{reportError.fileName!}</Typography>
            <XMLValidationErrorList xmlError={reportError} />
          </li>
        ))}
      </ul>
    </li>
  );
};

const Upload: FC = () => {
  const { addSnack } = useSnack();
  const { portefoljeId } = useParams<ExpertToolsXMLPortfolioWorklistParams>();
  const { t } = useTranslation();
  const { tiltakList, refreshPortefoljeData } = useExpertToolsXMLPortfolio();

  const fileUploadRef = useRef<HTMLInputElement | null>(null);

  const [parsing, setParsing] = useState(false);
  const [apiErrors, setApiErrors] = useState<APIError[]>();
  const [xmlReportErrors, setXmlReportErrors] = useState<XMLReportError[]>();

  const handleError = (uploadError: UploadError, file: File, err?: unknown) => {
    if (err) console.error(err);
    if (fileUploadRef.current) fileUploadRef.current.value = '';

    addSnack(
      createErrorMessage(
        t('expertToolsXMLPortfolio.worklist.uploadError', {
          context: uploadError,
          filename: file.name
        })
      ),
      { variant: 'error' }
    );
  };

  const parseFile = async (
    file: File
  ): Promise<XMLReport | XMLReportError | undefined> => {
    return new Promise((resolve) => {
      if (file.type !== 'text/xml') {
        resolve(undefined);
        handleError(UploadError.INVALID_FILE_TYPE, file);
        return;
      }

      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        try {
          const obj = new XMLParser(XMLParserOptions).parse(
            fileReader.result as string
          );

          if (!isXMLPortfolio(obj, file, false)) {
            resolve(undefined);
            handleError(UploadError.INVALID_FORMAT, file);
            return;
          }

          resolve(obj);
        } catch (err) {
          if (err instanceof XMLReportError) {
            resolve(err);
            return;
          }
          resolve(undefined);
          handleError(UploadError.PARSING, file, err);
        }
      };
      fileReader.onerror = () => resolve(undefined);
      fileReader.readAsText(file);
    });
  };

  const readAllFiles = async (files: File[]) => {
    const results = await Promise.all(
      files.map(async (file) => await parseFile(file))
    );

    const errors = results.filter(
      (item) => item instanceof XMLReportError
    ) as XMLReportError[];
    if (errors.length > 0) {
      setXmlReportErrors(errors);
    }

    return results.filter(
      (item) => !!item && !(item instanceof XMLReportError)
    ) as XMLReport[];
  };
  const MaximumNumberOfFilesPrUpload = 10;
  const handleAddFiles = async (fileList?: FileList) => {
    if (fileList) {
      if (fileList.length > MaximumNumberOfFilesPrUpload) {
        addSnack(
          t('expertToolsXMLPortfolio.worklist.step.lastOpp.maxfiles', {
            numberOfFiles: MaximumNumberOfFilesPrUpload
          }),
          {
            variant: 'error'
          }
        );
        return;
      }
      setParsing(true);
      const xmlReports = await readAllFiles(Array.from(fileList));

      if (xmlReports.length > 0)
        addReportsToPortefolje(portefoljeId, xmlReports)
          .then(({ ok, data }) => {
            if (!ok || !data) throw new Error();

            // TODO: Ensure all errors have a representative translation.
            const errors: APIError[] = data.reduce(
              (acc: APIError[], { error, jsonInput }) => {
                if (!error || !jsonInput) return acc;

                const input = JSON.parse(jsonInput);

                try {
                  const { BruksenhetsIdentId } =
                    input.Enheter.Enhet.Adresser.BygningsAdresse.MatrikkelInfo;

                  return [...acc, { BruksenhetsIdentId, error, id: uuidv4() }];
                } catch (err: unknown) {
                  console.error(err);
                  return acc;
                }
              },
              []
            );

            if (errors.length > 0) setApiErrors(errors);

            refreshPortefoljeData();
            if (fileUploadRef.current) fileUploadRef.current.value = '';
          })
          .catch(() =>
            addSnack(
              createErrorMessage(
                t('expertToolsXMLPortfolio.worklist.uploadError')
              ),
              { variant: 'error' }
            )
          )
          .finally(() => setParsing(false));
      else setParsing(false);
    }
  };

  const camelCasifiedStep = camelCasify(ExpertToolsXMLPortfolioStep.UPLOAD);

  return (
    <Fragment>
      {tiltakList && tiltakList.length > 0 ? (
        <Grid item xs={12}>
          <RegistrationList />
        </Grid>
      ) : (
        <Fragment>
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              {t(
                `expertToolsXMLPortfolio.worklist.step.${camelCasifiedStep}.heading`
              )}
            </Typography>

            <Typography paragraph>
              {t(
                `expertToolsXMLPortfolio.worklist.step.${camelCasifiedStep}.description1`,
                {
                  numberOfFiles: MaximumNumberOfFilesPrUpload
                }
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} container spacing={4}>
            <Grid item xs={12} sm="auto" mb={4}>
              <FileUploadMultiple
                acceptedFileTypes={[FileType.XML]}
                ButtonProps={{
                  fullWidth: true,
                  loading: parsing,
                  onKeyDown: (e) =>
                    (e.key === ' ' || e.key === 'Enter') &&
                    fileUploadRef.current?.click(),
                  variant: 'secondary'
                }}
                handleAddFiles={handleAddFiles}
                ref={fileUploadRef}
                required
              >
                {t('expertToolsXMLPortfolio.worklist.selectFiles')}
              </FileUploadMultiple>
            </Grid>
            <RegistrationList />
          </Grid>
        </Fragment>
      )}

      <FullScreenLoader open={parsing} />

      <Dialog
        open={!parsing && (!!apiErrors || !!xmlReportErrors)}
        onClose={() => {
          setApiErrors(undefined);
          setXmlReportErrors(undefined);
        }}
      >
        <Dialog.Title>
          {t('expertToolsXMLPortfolio.upload.error.title')}
        </Dialog.Title>

        <Dialog.Content>
          <Typography>
            {t('expertToolsXMLPortfolio.upload.error.description')}
          </Typography>

          <ul>
            {xmlReportErrors && (
              <XMLReportErrorMessage xmlErrors={xmlReportErrors} />
            )}
            {apiErrors?.map(({ id, error, BruksenhetsIdentId }) => (
              <li key={id}>
                <Typography>
                  {t('keyValue', {
                    key: BruksenhetsIdentId
                      ? BruksenhetsIdentId
                      : t('expertToolsXML.error.bruksenhetsIdentIdNotFound'),
                    value: t('expertToolsXML.error', { context: error })
                  })}
                </Typography>
              </li>
            ))}
          </ul>
        </Dialog.Content>

        <Dialog.Actions>
          <Button
            onClick={() => {
              setApiErrors(undefined);
              setXmlReportErrors(undefined);
            }}
          >
            {t('ok')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Fragment>
  );
};

export default Upload;
