import { Control, useWatch } from 'react-hook-form';
import React, { FC } from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from 'enova-frontend-components';

import { FloorFormData } from '../../../types';
import useSelector from '../../../../../../../hooks/useSelector';
import { getEtasjer } from '../../../../../../../store/registration/selectors';
import { BygningsForm } from '../../../../../utils/registerEnums';

import { FloorArea } from './floorArea';
import { TotalArea } from './totalArea';

interface Props {
  floorId: string;
  control: Control<FloorFormData>;
}

export const AreaPerFloor: FC<Props> = ({ floorId, control }) => {
  const floors = useSelector(getEtasjer, shallowEqual);
  const { t } = useTranslation();

  const formWalls = useWatch({ control, name: 'floor.vegger' }) ?? [];
  const sameShapeAllFloors = useWatch({ control, name: 'sameShapeAllFloors' });

  const formShape =
    useWatch({
      control,
      name: 'floor.bygningsForm'
    }) ?? BygningsForm.Unknown;

  return (
    <Box my={5}>
      <Typography variant="h6">
        {t('register.buildingShape.areaPerFloor')}
      </Typography>
      <Paper shadow="none">
        <Box display="flex" flexDirection="row">
          {floors.map((floor, floorIndex) => {
            const isCurrentFloor = sameShapeAllFloors || floorId === floor.id;

            // Need the form shape and walls values to calculate current floor area on wall length input change
            const shape = isCurrentFloor ? formShape : floor.bygningsForm;
            const walls = isCurrentFloor ? formWalls : floor.vegger ?? [];

            return (
              <FloorArea
                key={floorIndex}
                isCurrentFloor={isCurrentFloor}
                isFirstElement={floorIndex === 0}
                floor={{
                  ...floor,
                  vegger: walls,
                  bygningsForm: shape
                }}
              />
            );
          })}
          <TotalArea
            walls={formWalls}
            currentFloorId={floorId}
            formShape={formShape}
          />
        </Box>
      </Paper>
    </Box>
  );
};
