import { ShapeOption } from 'enova-frontend-components';

import {
  BygningsForm,
  Himmelretning,
  VeggId
} from '../../../../../../../components/registering/utils/registerEnums';

type BuildingShapeDef = {
  label: string;
  shape: ShapeOption;
  walls: Partial<Record<VeggId, Himmelretning>>;
};

export const BuildingShapeDefs: Partial<
  Record<BygningsForm, BuildingShapeDef>
> = {
  [BygningsForm.Rektangulaert]: {
    label: BygningsForm.Rektangulaert,
    shape: 'rectangle',
    walls: {
      [VeggId.A]: Himmelretning.Nord,
      [VeggId.B]: Himmelretning.Ost,
      [VeggId.C]: Himmelretning.Sor,
      [VeggId.D]: Himmelretning.Vest
    }
  },
  [BygningsForm.LForm]: {
    label: BygningsForm.LForm,
    shape: 'l_shape',
    walls: {
      [VeggId.A]: Himmelretning.Nord,
      [VeggId.B]: Himmelretning.Ost,
      [VeggId.C]: Himmelretning.Sor,
      [VeggId.D]: Himmelretning.Vest,
      [VeggId.E]: Himmelretning.Sor,
      [VeggId.F]: Himmelretning.Vest
    }
  },
  [BygningsForm.LFormSpeilvendt]: {
    label: BygningsForm.LFormSpeilvendt,
    shape: 'l_shape_mirrored',
    walls: {
      [VeggId.A]: Himmelretning.Nord,
      [VeggId.B]: Himmelretning.Ost,
      [VeggId.C]: Himmelretning.Sor,
      [VeggId.D]: Himmelretning.Ost,
      [VeggId.E]: Himmelretning.Sor,
      [VeggId.F]: Himmelretning.Vest
    }
  },
  [BygningsForm.TForm]: {
    label: BygningsForm.TForm,
    shape: 't_shape',
    walls: {
      [VeggId.A]: Himmelretning.Nord,
      [VeggId.B]: Himmelretning.Ost,
      [VeggId.C]: Himmelretning.Sor,
      [VeggId.D]: Himmelretning.Ost,
      [VeggId.E]: Himmelretning.Sor,
      [VeggId.F]: Himmelretning.Vest,
      [VeggId.G]: Himmelretning.Sor,
      [VeggId.H]: Himmelretning.Vest
    }
  },
  [BygningsForm.UForm]: {
    label: BygningsForm.UForm,
    shape: 'u_shape',
    walls: {
      [VeggId.A]: Himmelretning.Nord,
      [VeggId.B]: Himmelretning.Ost,
      [VeggId.C]: Himmelretning.Nord,
      [VeggId.D]: Himmelretning.Vest,
      [VeggId.E]: Himmelretning.Nord,
      [VeggId.F]: Himmelretning.Ost,
      [VeggId.G]: Himmelretning.Sor,
      [VeggId.H]: Himmelretning.Vest
    }
  },
  [BygningsForm.Atriumshus]: {
    label: BygningsForm.Atriumshus,
    shape: 'atrium',
    walls: {
      [VeggId.A]: Himmelretning.Nord,
      [VeggId.B]: Himmelretning.Ost,
      [VeggId.C]: Himmelretning.Sor,
      [VeggId.D]: Himmelretning.Vest,
      [VeggId.E]: Himmelretning.Sor,
      [VeggId.F]: Himmelretning.Vest,
      [VeggId.G]: Himmelretning.Nord,
      [VeggId.H]: Himmelretning.Ost
    }
  },
  [BygningsForm.YForm]: {
    label: BygningsForm.YForm,
    shape: 'y_shape',
    walls: {
      [VeggId.A]: Himmelretning.Nord,
      [VeggId.B]: Himmelretning.Ost,
      [VeggId.C]: Himmelretning.Nord,
      [VeggId.D]: Himmelretning.Vest,
      [VeggId.E]: Himmelretning.Nord,
      [VeggId.F]: Himmelretning.Ost,
      [VeggId.G]: Himmelretning.Sor,
      [VeggId.H]: Himmelretning.Ost,
      [VeggId.I]: Himmelretning.Sor,
      [VeggId.J]: Himmelretning.Vest,
      [VeggId.K]: Himmelretning.Sor,
      [VeggId.L]: Himmelretning.Vest
    }
  },
  [BygningsForm.XForm]: {
    label: BygningsForm.XForm,
    shape: 'x_shape',
    walls: {
      [VeggId.A]: Himmelretning.Nord,
      [VeggId.B]: Himmelretning.Ost,
      [VeggId.C]: Himmelretning.Nord,
      [VeggId.D]: Himmelretning.Ost,
      [VeggId.E]: Himmelretning.Sor,
      [VeggId.F]: Himmelretning.Ost,
      [VeggId.G]: Himmelretning.Sor,
      [VeggId.H]: Himmelretning.Vest,
      [VeggId.I]: Himmelretning.Sor,
      [VeggId.J]: Himmelretning.Vest,
      [VeggId.K]: Himmelretning.Nord,
      [VeggId.L]: Himmelretning.Vest
    }
  },
  [BygningsForm.ZForm]: {
    label: BygningsForm.ZForm,
    shape: 'z_shape',
    walls: {
      [VeggId.A]: Himmelretning.Nord,
      [VeggId.B]: Himmelretning.Ost,
      [VeggId.C]: Himmelretning.Sor,
      [VeggId.D]: Himmelretning.Ost,
      [VeggId.E]: Himmelretning.Sor,
      [VeggId.F]: Himmelretning.Vest,
      [VeggId.G]: Himmelretning.Nord,
      [VeggId.H]: Himmelretning.Vest
    }
  },
  [BygningsForm.ZFormSpeilvendt]: {
    label: BygningsForm.ZFormSpeilvendt,
    shape: 'z_shape_mirrored',
    walls: {
      [VeggId.A]: Himmelretning.Nord,
      [VeggId.B]: Himmelretning.Ost,
      [VeggId.C]: Himmelretning.Nord,
      [VeggId.D]: Himmelretning.Ost,
      [VeggId.E]: Himmelretning.Sor,
      [VeggId.F]: Himmelretning.Vest,
      [VeggId.G]: Himmelretning.Sor,
      [VeggId.H]: Himmelretning.Vest
    }
  }
};
