import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  EnergyRatingIndicator,
  Typography
} from 'enova-frontend-components';

import { StyledTable } from '../styledTable';
import { LevelCriteria } from '../../../../../../../types/soknad';

import { getNumberFromLevel } from './utils';

interface SupportCriteriaTableProps {
  criteriaLevels?: LevelCriteria[];
}

const i18nPath = 'register.summary.energyCalculation.table.heading';

export const SupportCriteriaTable: FC<SupportCriteriaTableProps> = ({
  criteriaLevels
}) => {
  const { t } = useTranslation();

  return (
    <StyledTable>
      <thead>
        <tr>
          <td>{t(`${i18nPath}.criteria`)}</td>
          <td>{t(`${i18nPath}.heatLoss`)}</td>
          <td>{t(`${i18nPath}.netEnergReq`)}</td>
          <td>{t(`${i18nPath}.minimumHeatingGrade`)}</td>
        </tr>
      </thead>

      <tbody>
        {criteriaLevels?.map(
          ({
            enovaStotteNiva,
            maksimaltArligNettoEnergibehov,
            maksimaltVarmetapstall,
            lavesteAkseptertOppvarmingKarakter
          }) => (
            <tr key={enovaStotteNiva}>
              <td>
                <Typography bold variant="body2">
                  {t('register.summary.energyCalculation.table.content.level', {
                    count: getNumberFromLevel(enovaStotteNiva)
                  })}
                </Typography>
              </td>
              <td>{maksimaltVarmetapstall}</td>
              <td>
                {maksimaltArligNettoEnergibehov && (
                  <span title={String(maksimaltArligNettoEnergibehov)}>
                    {maksimaltArligNettoEnergibehov?.toFixed(2)}
                  </span>
                )}
              </td>
              <td>
                <Box
                  display="flex"
                  justifyContent="center"
                  title={t('energyLabel.heatingGrade', {
                    context: lavesteAkseptertOppvarmingKarakter
                  })}
                >
                  <EnergyRatingIndicator
                    heatingGrade={lavesteAkseptertOppvarmingKarakter}
                  />
                </Box>
              </td>
            </tr>
          )
        )}
      </tbody>
    </StyledTable>
  );
};
