import { Box, FormMessage, Grid, styled } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { XmlRegistrationView } from '../xmlRegistrationView';

const StyledFormMessage = styled(FormMessage)`
  border-color: #009bcc;
  background-color: #e5f4f9;
`;

export const XmlTab = () => {
  const { t } = useTranslation();

  return (
    <Grid item lg={10} my={5}>
      <Box mb={10}>
        <StyledFormMessage title={t('expertToolsXML.info.header')} fullWidth>
          {t('expertToolsXML.info.content')}
        </StyledFormMessage>
      </Box>
      <XmlRegistrationView />
    </Grid>
  );
};
