import { FunctionComponent, SVGProps } from 'react';
import {
  CheckboxProps,
  CheckButtonProps,
  CheckGroupProps,
  DatePickerProps,
  NumberFieldProps,
  NumberPickerProps,
  RadioGroupProps,
  RadioProps,
  SelectOption,
  SelectProps,
  TypographyProps
} from 'enova-frontend-components';

import {
  SelectorFunc,
  ValidatorFunc
} from '../../components/registering/utils/validation';
import { RootState } from '../../store/rootReducer';

type Translation = {
  tDescriptionKey?: string;
  tErrorKey?: string;
  tHelperTextKey?: string;
  tInformationKey?: string;
  tLabelKey: string;
};

type OmitKeys =
  | 'description'
  | 'helperText'
  | 'information'
  | 'label'
  | 'suffix'
  | 'type';

export type ConditionFunc = (state: RootState) => boolean;

type Base = Translation & {
  /**
   * Field name
   */
  attrKey: string;
  /**
   * If the field should render conditionally of other register values
   */
  condition?: ConditionFunc;
  /**
   * Inward key path of attribute in register object
   */
  path: readonly string[];
  selector: SelectorFunc;
  validator?: ValidatorFunc;
};

export enum Field {
  CHECK,
  CHECKBUTTON,
  DATE,
  DECIMAL,
  INTEGER,
  NUMBER_PICKER,
  RADIO,
  RADIOBUTTON,
  SELECT,
  TYPOGRAPHY,
  YEAR
}

export const CheckFieldTypes = [Field.CHECK, Field.CHECKBUTTON];
export const NumberFieldTypes = [Field.INTEGER, Field.DECIMAL, Field.YEAR];
export const RadioFieldTypes = [Field.RADIO, Field.RADIOBUTTON];

export type TypographyType = Partial<Base> &
  Omit<TypographyProps, 'children'> & {
    type: Field.TYPOGRAPHY;
    tContentKey: string;
  };

export type NumberType = Base &
  Omit<NumberFieldProps, OmitKeys> & {
    tSuffixKey?: string;
  };

export type IntType = NumberType & { type: Field.INTEGER };

export type YearType = NumberType & { type: Field.YEAR };

export type DecimalType = NumberType & { type: Field.DECIMAL };

export type NumberPickerType = Base & {
  type: Field.NUMBER_PICKER;
} & Omit<NumberPickerProps, OmitKeys>;

export type DateType = Partial<Omit<DatePickerProps, 'type'>> &
  Base & { type: Field.DATE };

export type SelectType = Base & {
  type: Field.SELECT;
  options: SelectOption[];
} & Omit<SelectProps, OmitKeys>;

type RadioOptionType = Omit<SelectOption, 'value'> &
  Omit<RadioProps, OmitKeys> & {
    fields?: FieldType[];
    value: string | number | boolean | unknown;
  };

type RadioButtonOptionType = RadioOptionType & {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
};

type RadioBase = Base &
  Omit<RadioGroupProps, 'children' | OmitKeys> & {
    commonFields?: { [value: string]: FieldType[] };
    valueType?: 'string' | 'boolean' | 'object'; // Default is 'string'
  };

export type RadioType = RadioBase & {
  options: RadioOptionType[];
  type: Field.RADIO;
};

export type RadioButtonType = RadioBase & {
  options: RadioButtonOptionType[];
  type: Field.RADIOBUTTON;
};

type CheckOptionType = Pick<SelectOption, 'value'> &
  Pick<Translation, 'tHelperTextKey' | 'tLabelKey'> &
  Omit<CheckboxProps, OmitKeys> & {
    fields?: FieldType[];
    defaultValueOnCheck?: unknown;
  } & { [key: string]: unknown };

type CheckButtonOptionType = CheckOptionType &
  Pick<CheckButtonProps, 'icon' | 'invertedIcon'>;

export type CheckboxType = Base &
  Omit<CheckGroupProps, 'children' | OmitKeys> & {
    type: Field.CHECK;
    options: CheckOptionType[];
  };

export type CheckButtonType = Base &
  Omit<CheckGroupProps, 'children' | OmitKeys> & {
    type: Field.CHECKBUTTON;
    options: CheckButtonOptionType[];
  };

export type InputFieldType =
  | CheckboxType
  | CheckButtonType
  | DateType
  | DecimalType
  | IntType
  | RadioType
  | RadioButtonType
  | SelectType
  | YearType;

export type FieldType = InputFieldType | TypographyType;
