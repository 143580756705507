import type { User } from 'oidc-client-ts';

import type { AuthenticationState } from './types';

export enum ActionType {
  INITIALISED = 'INITIALISED',
  USER_UNLOADED = 'USER_UNLOADED',
  USER_LOADED = 'USER_LOADED',
  ERROR = 'ERROR',
  SESSION_EXPIRES = 'SESSION_EXPIRES',
  SESSION_EXPIRES_CONFIRMED = 'SESSION_EXPIRES_CONFIRMED'
}

type Action =
  | {
      type: ActionType.INITIALISED | ActionType.USER_LOADED;
      user: User | null;
    }
  | { type: ActionType.USER_UNLOADED }
  | { type: ActionType.ERROR; error: unknown }
  | { type: ActionType.SESSION_EXPIRES }
  | { type: ActionType.SESSION_EXPIRES_CONFIRMED };

export const reducer = (
  state: AuthenticationState,
  action: Action
): AuthenticationState => {
  switch (action.type) {
    case ActionType.INITIALISED:
    case ActionType.USER_LOADED:
      return {
        ...state,
        user: action.user,
        isLoading: false,
        isAuthenticated: action.user ? !action.user.expired : false,
        error: undefined
      };
    case ActionType.USER_UNLOADED:
      return {
        ...state,
        user: undefined,
        isAuthenticated: false
      };
    case ActionType.SESSION_EXPIRES:
      return {
        ...state,
        sessionExpiry: {
          isDialogOpen: true,
          isNotificationDismissed: true
        }
      };
    case ActionType.SESSION_EXPIRES_CONFIRMED:
      return {
        ...state,
        sessionExpiry: {
          ...state.sessionExpiry,
          isDialogOpen: false
        }
      };
    case ActionType.ERROR:
      return {
        ...state,
        isLoading: false,
        error:
          action.error instanceof Error
            ? action.error
            : new Error('Login failed')
      };
    default:
      return {
        ...state,
        isLoading: false,
        error: new Error(`unknown type ${action['type'] as string}`)
      };
  }
};
