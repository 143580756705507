import React from 'react';
import { Box, Grid, Stepper, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { ActionBarHeight } from '../../../utils/layout';
import { ExpertToolsXMLPortfolioStep } from '../../../utils/expertToolsXML/enums';
import { camelCasify } from '../../../utils/navigation';

import Upload from './steps/upload';
import Measures from './steps/measures';
import Confirmation from './steps/confirmation';
import ActionButtons from './actionButtons';
import useExpertToolsXMLPortfolio from './useExpertToolsXMLPortfolio';

export const EditWorklist = () => {
  const { t } = useTranslation();
  const { step, steps } = useExpertToolsXMLPortfolio();

  const getStepName = (s: string) =>
    s ? t(`expertToolsXMLPortfolio.worklist.step.${camelCasify(s)}`) : '';

  const getStepperTOptions = () => {
    const stepIndex = Object.values(ExpertToolsXMLPortfolioStep).indexOf(step);

    return {
      stepCount: steps.length,
      stepName: getStepName(steps[stepIndex]),
      stepNo: stepIndex + 1
    };
  };

  return (
    <Grid container spacing={8}>
      <Grid item xs={12} md={4} lg={3} position="relative">
        <Box position="sticky" top={85 + ActionBarHeight}>
          <Box id="stepper">
            <Typography variant="srOnly">
              {t('stepper', getStepperTOptions())}
            </Typography>

            <Stepper aria-hidden>
              {steps.map((s, index) => (
                <Stepper.Step
                  active={s === step}
                  done={
                    index < steps.indexOf(step) ||
                    step === ExpertToolsXMLPortfolioStep.CONFIRMATION
                  }
                  key={s}
                  label={`${index + 1}. ${getStepName(s)}`}
                />
              ))}
            </Stepper>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={8} lg={9}>
        <Grid container spacing={4}>
          {step === ExpertToolsXMLPortfolioStep.UPLOAD && <Upload />}
          {step === ExpertToolsXMLPortfolioStep.MEASURES && <Measures />}
          {step === ExpertToolsXMLPortfolioStep.CONFIRMATION ? (
            <Confirmation />
          ) : (
            <ActionButtons />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
