import { ReactComponent as Rectangle } from '../../../../../../assets/icons/shapes/icons/square.svg';
import { ReactComponent as TShape } from '../../../../../../assets/icons/shapes/icons/t-shape.svg';
import { ReactComponent as LShape } from '../../../../../../assets/icons/shapes/icons/l-shape.svg';
import { ReactComponent as LShapeMirrored } from '../../../../../../assets/icons/shapes/icons/l-shape-mirrored.svg';
import { ReactComponent as XShape } from '../../../../../../assets/icons/shapes/icons/x-shape.svg';
import { ReactComponent as UShape } from '../../../../../../assets/icons/shapes/icons/u-shape.svg';
import { ReactComponent as Atrium } from '../../../../../../assets/icons/shapes/icons/atrium.svg';
import { ReactComponent as YShape } from '../../../../../../assets/icons/shapes/icons/y-shape.svg';
import { ReactComponent as ZShape } from '../../../../../../assets/icons/shapes/icons/z-shape.svg';
import { ReactComponent as ZShapeMirrored } from '../../../../../../assets/icons/shapes/icons/z-shape-mirrored.svg';
import { BygningsForm } from '../../../../utils/registerEnums';

export const ShapeIcon = ({ shape }: { shape: BygningsForm }) => {
  switch (shape) {
    case BygningsForm.Rektangulaert:
      return <Rectangle />;
    case BygningsForm.TForm:
      return <TShape />;
    case BygningsForm.Atriumshus:
      return <Atrium />;
    case BygningsForm.YForm:
      return <YShape />;
    case BygningsForm.LForm:
      return <LShape />;
    case BygningsForm.UForm:
      return <UShape />;
    case BygningsForm.XForm:
      return <XShape />;
    case BygningsForm.ZForm:
      return <ZShape />;
    case BygningsForm.ZFormSpeilvendt:
      return <ZShapeMirrored />;
    case BygningsForm.LFormSpeilvendt:
      return <LShapeMirrored />;
  }
};
