import React, { FC, Fragment, useEffect, useState } from 'react';
import { Box, Collapse, List } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import useStatisticsFilter from '../../hooks/useStatisticsFilter';
import {
  FilterCategory,
  FilterSubcategory
} from '../../hooks/useStatisticsFilter/context';
import { fylkerWithoutHistorical, Kommune } from '../../utils/kommuner';
import { toCamelCase } from '../../utils/utils';

type CollapsibleFilterListProps = {
  category: FilterCategory;
  subcategory?: FilterSubcategory;
  option: string;
};

const CollapsibleFilterList: FC<CollapsibleFilterListProps> = ({
  category,
  subcategory,
  option
}) => {
  const { t } = useTranslation();
  const [fylkeChecked, setFylkeChecked] = useState(false);
  const [subcategoryValues, setSubcategoryValues] = useState<Kommune[]>([]);

  const {
    mode,
    filterValues,
    subcategoryFilterValues,
    updateFilterValues,
    updateSubcategoryFilterValues
  } = useStatisticsFilter();

  useEffect(() => {
    const subcategoryValues =
      fylkerWithoutHistorical.find((fylke) => fylke.fylkesnavn === option)
        ?.kommuner ?? [];
    setSubcategoryValues(subcategoryValues);
  }, [option]);

  useEffect(() => {
    setFylkeChecked(filterValues[category].includes(option));
  }, [filterValues, option]);

  return (
    <Fragment key={option}>
      {mode && (
        <Fragment>
          <List.Item
            button
            divider
            onClick={() =>
              updateFilterValues(
                mode,
                option,
                !filterValues[mode].includes(option),
                subcategory
              )
            }
            rightIcon={
              filterValues[mode].includes(option) ? 'check' : undefined
            }
            role="checkbox"
          >
            {t(`${category}.${toCamelCase(option)}`)}
          </List.Item>
          <Collapse in={fylkeChecked}>
            <Box pl={5}>
              {subcategory === FilterSubcategory.KOMMUNER &&
                subcategoryValues.map((sub) => (
                  <List.Item
                    button
                    divider
                    key={sub.kommunenummer}
                    onClick={() =>
                      updateSubcategoryFilterValues(
                        subcategory,
                        sub.kommunenummer,
                        !subcategoryFilterValues[subcategory].includes(
                          sub.kommunenummer
                        )
                      )
                    }
                    rightIcon={
                      subcategoryFilterValues[subcategory].includes(
                        sub.kommunenummer
                      )
                        ? 'check'
                        : undefined
                    }
                    role="checkbox"
                  >
                    {sub.kommunenavn}
                  </List.Item>
                ))}
            </Box>
          </Collapse>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CollapsibleFilterList;
