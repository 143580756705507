import { AreaOfExpertise, User } from '../../../types/user';
import {
  DetailingStep,
  SoknadSteg
} from '../../../components/registering/utils/registerEnums';
import {
  AppRoute,
  hasPermission,
  RegistrationCommonStep,
  RegistrationStep
} from '../../../utils/navigation';
import { detailingStepList } from '../../../utils/register';
import { Detaljeringssteg } from '../../../types/registration/userInput';
import { UserInputErrorType } from '../../../services/types';

// Function returns a list of steps for the registration wizard. Order of items matter.
// This should probably come from the backend in the future.
export const getSteps = (
  user?: User,
  detaljeringssteg?: Detaljeringssteg,
  soknadSteg?: SoknadSteg
) => {
  let result: (DetailingStep | RegistrationCommonStep)[] = [
    RegistrationCommonStep.ABOUT
  ];

  // Reduce object of detaljeringsteg back into an array of steps for all the steps
  // the user has checked.
  if (detaljeringssteg) {
    const reducedList = detailingStepList.reduce<DetailingStep[]>(
      (acc, step) => (detaljeringssteg[step] ? [...acc, step] : acc),
      []
    );

    result = [...result, ...reducedList];
  }

  // PARAMETERS only available for experts
  if (user?.fagomrader.includes(AreaOfExpertise.BYGNINGSEKSPERT)) {
    result = [...result, RegistrationCommonStep.PARAMETERS];
  }

  // MEASURES only available in a søknad if not first step
  if (
    hasPermission(AppRoute.SOKNAD, user) &&
    !!soknadSteg &&
    soknadSteg != SoknadSteg.Steg1
  ) {
    result = [...result, RegistrationCommonStep.MEASURES];
  }

  // Add SUMMARY and ENERGY_CERTIFICATE
  return [
    ...result,
    RegistrationCommonStep.SUMMARY,
    RegistrationCommonStep.ENERGY_CERTIFICATE
  ];
};

export const UserInputErrorTypeStepMap: Partial<
  Record<RegistrationStep, UserInputErrorType[]>
> = {
  [DetailingStep.Bygningsform]: [
    UserInputErrorType.BygningsFormIkkeSatt,
    UserInputErrorType.GjennomsnittligEtasjehoydeIkkeSatt,
    UserInputErrorType.TotalEtasjeArealStorreEnnOppvarmetAreal,
    UserInputErrorType.TotalEtasjeArealMindreEnnOppvarmetAreal,
    UserInputErrorType.LengdeIkkeSattForVegg
  ],
  [DetailingStep.Vegger]: [
    UserInputErrorType.IngenVerdierValgtForVeggkonstruksjon,
    UserInputErrorType.VeggKonstruksjonOgIsolasjonstykkelseBeggeEllerIngen,
    UserInputErrorType.LengdeIkkeSattForVegg,
    UserInputErrorType.LengdeIkkeSattForVeggSone,
    UserInputErrorType.TilliggendeRomIkkeSattForVeggSone,
    UserInputErrorType.LengdeForVeggSonerStemmerIkke,
    UserInputErrorType.IngenVeggsoneSattForVegg
  ],
  [DetailingStep.Vinduer]: [
    UserInputErrorType.VinduArealStorreEnnVegg,
    UserInputErrorType.GlasstypeOgKarmtypeBeggeEllerIngen,
    UserInputErrorType.VinduArealStorreEnnVeggSone,
    UserInputErrorType.IngenVerdierValgtForVindu,
    UserInputErrorType.IngenVinduerDetaljert
  ],
  [DetailingStep.Gulv]: [
    UserInputErrorType.ArealIkkeSattForGulvsone,
    UserInputErrorType.GulvKonstruksjonOgIsolasjonstykkelseBeggeEllerIngen,
    UserInputErrorType.IngenVerdierValgtForGulvkonstruksjon,
    UserInputErrorType.TilliggendeRomIkkeSattForGulvSone,
    UserInputErrorType.EtasjeOgGulvArealStemmerIkke,
    UserInputErrorType.TotalEtasjeArealStorreEnnOppvarmetAreal,
    UserInputErrorType.TotalEtasjeArealMindreEnnOppvarmetAreal
  ]
};
