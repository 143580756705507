import { Grid, Typography } from 'enova-frontend-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const BuildingSearchEmptyState = () => {
  const { t } = useTranslation();
  return (
    <Grid xs={12} item minWidth={800}>
      <Typography>{t('search.no_result')}</Typography>
    </Grid>
  );
};
