import { FormProvider, useForm } from 'react-hook-form';
import { FC } from 'react';

import { Form } from '../form';
import { WindowFormData } from '../types';
import { useWindowOperations } from '../hooks/useWindowOperations';
import { VeggId } from '../../../utils/registerEnums';

interface Props {
  onSave?: () => void;
  onCancel?: () => void;
  wallId: VeggId;
  etasjeId: string;
}

export const AddWindowForm: FC<Props> = ({
  onSave,
  onCancel,
  etasjeId,
  wallId
}) => {
  const methods = useForm<WindowFormData>({
    defaultValues: {
      windowData: {
        wallZoneId: -1,
        wallId,
        etasjeId
      },
      detailing: -1
    }
  });

  const { addWindow } = useWindowOperations(etasjeId, wallId);

  const onSubmit = (data: WindowFormData) => {
    if (data.windowData.wallZoneId === -1 || !data.windowData.window) {
      return;
    }

    addWindow(
      data.windowData.window,
      data.windowData.wallZoneId,
      data.windowData.amount
    );
    onSave && onSave();
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={onSubmit}
        onRemove={() => {
          onCancel && onCancel();
        }}
      />
    </FormProvider>
  );
};
