import { useMutation } from 'react-query';

import * as api from '../../../../../../services/api';
import { queryClient, queryKeys } from '../../../../../../utils/react-query';

export const usePublishFlerboligRegistration = (flerboligbyggId: string) => {
  const { mutate, isLoading } = useMutation(
    () => api.publishFlerboligRegistration(flerboligbyggId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          queryKeys.flerboligRegistration,
          flerboligbyggId
        ])
    }
  );

  return { publishRegistration: mutate, isLoading };
};
