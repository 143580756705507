import { createContext } from 'react';
import { UseMutateFunction } from 'react-query';

import { User, UserBase } from '../../types/user';

export type UserContextValues = {
  user?: User;
  isLoadingUserProfile: boolean;
  isFetchedAfterMount: boolean;
  updateUser: UseMutateFunction<
    UserBase | undefined,
    unknown,
    UserBase,
    unknown
  >;
  isUpdateUserLoading: boolean;
  hasUserProfile: boolean;
};

export const UserContext = createContext<UserContextValues | null>(null);
