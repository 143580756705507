import { useParams } from 'react-router-dom';

import { BuildingDetails } from '../../../../../../types/building';
import { XMLParams } from '../../types';
import { useXmlRegistrationSearchParams } from '../../common/hooks/useXmlRegistrationSearchParams';
import { useXmlStepNavigation } from '../../common/hooks/useXmlStepNavigation';

export const useBuildingSelectEnhet = () => {
  const { step } = useParams<XMLParams>();
  const { setBuildingSelection, selectedBuildingIds } =
    useXmlRegistrationSearchParams();

  const { navigateToStep } = useXmlStepNavigation();

  const toggleMultiSelect = () => {
    navigateToStep(step, 'flerbolig');
  };

  const onSelect = (building: BuildingDetails) => {
    const { bruksenhetsIdentId, energiObjektId } = building;
    const buildingIsSelected = selectedBuildingIds.includes(bruksenhetsIdentId);

    if (buildingIsSelected) {
      // Remove building from selection
      const filteredList = selectedBuildingIds.filter(
        (id) => id !== bruksenhetsIdentId
      );

      setBuildingSelection(filteredList, {
        energiobjektId: energiObjektId
      });
      return;
    }

    // Replace selected building
    const updatedSelection = [bruksenhetsIdentId];

    setBuildingSelection(updatedSelection, {
      energiobjektId: energiObjektId
    });
  };

  return {
    onSelect,
    toggleMultiSelect
  };
};
