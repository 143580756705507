import React from 'react';
import { Box, Typography } from 'enova-frontend-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  toggleAllFloorsHaveSameWallConstr,
  toggleAllWallsAgainstTheOpen
} from '../../../../store/registration/actions';

import { useBuildingConstructionData } from './wallZone/form/useBuildingConstructionData';
import {
  AllWallsAgainstTheOpenRadio,
  Etasje,
  SameConstructionAllFloorsRadio
} from './components';

export const Walls = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    allFloorsSameWallConstruction,
    allWallsAgainstTheOpen,
    allFloorsSameShape,
    floors: etasjer
  } = useBuildingConstructionData();

  // If all floors have same construction we use first floor.
  const floors = !allFloorsSameWallConstruction ? etasjer : [etasjer[0]];

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="h2">
        {t('register.wallsAndIsolation.title')}
      </Typography>

      <Typography>{t('register.wallsAndIsolation.description')}</Typography>

      {/*Toggle same construction all floors, only available if all floors has same buildingShape */}
      {allFloorsSameShape && (
        <SameConstructionAllFloorsRadio
          value={allFloorsSameWallConstruction}
          onChange={(value) =>
            dispatch(toggleAllFloorsHaveSameWallConstr({ value }))
          }
        />
      )}

      {/*Toggle all walls against the open */}
      <AllWallsAgainstTheOpenRadio
        value={allWallsAgainstTheOpen}
        onChange={(value) => dispatch(toggleAllWallsAgainstTheOpen({ value }))}
      />

      {floors.map((etasje, index) => (
        <Etasje
          key={index}
          etasje={etasje}
          sameConstructionAllFloors={allFloorsSameWallConstruction}
        />
      ))}
    </Box>
  );
};
