import { VeggId } from '../../../../utils/registerEnums';
import useSelector from '../../../../../../hooks/useSelector';
import { Bygningsdetaljer } from '../../../../../../types/registration/userInput';

export const highlightWall = (wallId: VeggId) => {
  const box = document.querySelector(`#box-${wallId}`);
  const wallLine = document.querySelector(`#line-${wallId}`);

  box && box.classList.add('selected');
  wallLine && wallLine.classList.add('selected');
};

export const removeHighlight = (wallId: VeggId) => {
  const box = document.querySelector(`#box-${wallId}`);
  const wallLine = document.querySelector(`#line-${wallId}`);

  box && box.classList.remove('selected');
  wallLine && wallLine.classList.remove('selected');
};

export const useBygningsdetaljer = (): Bygningsdetaljer =>
  useSelector((state) => state.register.userInput?.bygningsdetaljer);

export const useCalculatedArea = () =>
  useSelector((state) => state.register?.calculatedArea);
