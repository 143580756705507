import React, { FC } from 'react';
import {
  CollapsibleFactBox,
  Grid,
  Shape,
  styled,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import useSelector from '../../../../../../../hooks/useSelector';
import {
  getEtasje,
  getEtasjeDirection,
  getEtasjer,
  getMinifiedWalls
} from '../../../../../../../store/registration/selectors';
import { Etasje } from '../../../../../../../types/registration/userInput';
import ReadonlyField from '../../../../../../../components/readonlyField';
import { getEtasjerTOptions } from '../../../../../../../utils/register';
import { BuildingShapeWithCompass } from '../../../../../../../components/buildingShapeWithCompass';

import { BuildingShapeDefs } from './types';

type ShapeAndDirectionSummaryContentProps = {
  etasjeId: string;
};

const StyledShapeWrapper = styled.div`
  align-items: center;
  border: thin solid ${({ theme }) => theme.palette.divider};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  display: inline-flex;
  flex-direction: column;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacing(4)};
  width: 220px;
`;

const ShapeAndDirectionSummaryContent: FC<
  ShapeAndDirectionSummaryContentProps
> = ({ etasjeId }) => {
  const { t } = useTranslation();
  const allFloorsSameShape = useSelector(
    (state) => state.register.allFloorsSameShape
  );

  const etasjer = useSelector(getEtasjer, shallowEqual);

  const etasjeDirection = useSelector((state) =>
    getEtasjeDirection(state, etasjeId)
  );

  const minifiedWalls = useSelector(
    (state) => getMinifiedWalls(state, etasjeId),
    shallowEqual
  );

  const minifiedEtasje: Pick<
    Etasje,
    | 'bygningsForm'
    | 'erKjeller'
    | 'etasjeNummer'
    | 'gjennomsnittligEtasjehoyde'
    | 'id'
  > | null = useSelector((state) => {
    const tmp = getEtasje(state, etasjeId);

    return tmp
      ? {
          bygningsForm: tmp.bygningsForm,
          erKjeller: tmp.erKjeller,
          etasjeNummer: tmp.etasjeNummer,
          gjennomsnittligEtasjehoyde: tmp.gjennomsnittligEtasjehoyde,
          id: tmp.id
        }
      : null;
  }, shallowEqual);

  if (!minifiedEtasje) return null;

  const { bygningsForm, erKjeller, etasjeNummer } = minifiedEtasje;

  const tOptions = getEtasjerTOptions(
    etasjer.length,
    allFloorsSameShape,
    erKjeller,
    etasjeNummer
  );

  const buildingShapeDef = bygningsForm
    ? BuildingShapeDefs[bygningsForm]
    : null;

  const multipleFloors = allFloorsSameShape && etasjer.length > 1;

  const anyDefinedWallLengths = minifiedWalls?.some(
    ({ lengdeIMeter }) => lengdeIMeter != null
  );

  const heightsToDisplay = multipleFloors
    ? etasjer?.some(
        ({ gjennomsnittligEtasjehoyde }) => gjennomsnittligEtasjehoyde != null
      )
    : minifiedEtasje.gjennomsnittligEtasjehoyde != null;

  const noValues =
    !buildingShapeDef &&
    !(bygningsForm && etasjeDirection) &&
    !anyDefinedWallLengths &&
    !heightsToDisplay;

  return (
    <CollapsibleFactBox
      title={t('register.shapeAndDirection.shape.sectionTitle', tOptions)}
    >
      {noValues ? (
        <Typography>{t('register.shapeAndDirection.shape.empty')}</Typography>
      ) : (
        <Grid container spacing={4} direction="column">
          {buildingShapeDef && (
            <Grid item>
              <Typography variant="h4" component="h5" gutterBottom>
                {t('buildingShape')}
              </Typography>

              <StyledShapeWrapper>
                <Shape name={buildingShapeDef.shape} />

                <Typography mt={2}>
                  {t(`shape.${buildingShapeDef.label}`)}
                </Typography>
              </StyledShapeWrapper>
            </Grid>
          )}

          {bygningsForm && etasjeDirection && (
            <Grid item>
              <Typography variant="h4" component="h5" gutterBottom>
                {t('register.shapeAndDirection.direction.title')}
              </Typography>

              <BuildingShapeWithCompass
                shape={bygningsForm}
                direction={etasjeDirection}
              />
            </Grid>
          )}

          {anyDefinedWallLengths && (
            <Grid item>
              <Typography variant="h4" component="h5" gutterBottom>
                {t('register.shapeAndDirection.wallLengths.title', tOptions)}
              </Typography>

              <Grid container spacing={10}>
                {minifiedWalls?.map((vegg) => (
                  <Grid item key={vegg.veggId}>
                    <ReadonlyField
                      label={t('wall', { veggId: vegg.veggId.toUpperCase() })}
                      value={
                        vegg.lengdeIMeter != null
                          ? `${vegg.lengdeIMeter} ${t('suffix.m')}`
                          : t('missingValue')
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}

          {heightsToDisplay && (
            <Grid item>
              <Typography
                variant="h4"
                component="h5"
                gutterBottom={allFloorsSameShape && etasjer.length > 1}
              >
                {t('register.shapeAndDirection.wallHeights.title', tOptions)}
              </Typography>

              <Grid container spacing={10}>
                {allFloorsSameShape && etasjer.length > 1 ? (
                  etasjer?.map((etasje) =>
                    etasje.gjennomsnittligEtasjehoyde == null ? null : (
                      <Grid item key={etasje.id}>
                        <ReadonlyField
                          label={t(
                            etasje.erKjeller ? 'basement' : 'floorNumber',
                            {
                              count: etasje.etasjeNummer
                            }
                          )}
                          value={`${etasje.gjennomsnittligEtasjehoyde} ${t(
                            'suffix.m'
                          )}`}
                        />
                      </Grid>
                    )
                  )
                ) : minifiedEtasje.gjennomsnittligEtasjehoyde == null ? null : (
                  <Grid item>
                    <Typography>
                      {`${minifiedEtasje.gjennomsnittligEtasjehoyde} ${t(
                        'suffix.m'
                      )}`}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </CollapsibleFactBox>
  );
};

export default ShapeAndDirectionSummaryContent;
