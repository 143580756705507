/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation } from 'react-query';

import useSelector from '../../hooks/useSelector';
import { updateInputParameters, updateRegistration } from '../../services/api';
import {
  RegistrationCommonStep,
  RegistrationStep,
} from '../../utils/navigation';

export interface AutoSaveParams {
  registrationId: string | undefined;
  step: RegistrationStep;
}

// As of now the only two places we support saving before logout are for
// register an energy label or register a soknad. This hook returns an async function
// to trigger a save before logout based on the parameters provided. To be able to save
// anything before logout we need to know the registration id and the registration step.
const useSaveMutation = (params: AutoSaveParams | undefined) => {
  const register = useSelector((state) => state.register);
  const userInput = register.userInput;
  const beregningsKjerneInput =
    register.energiplan?.energimerke?.inndata?.beregningskjerneInput;

  if (!params) {
    return () => Promise.resolve();
  }

  const registrationId = params.registrationId;

  if (!registrationId) {
    return () => Promise.resolve();
  }

  if (params.step === RegistrationCommonStep.PARAMETERS) {
    return () => updateInputParameters(registrationId, beregningsKjerneInput);
  }

  return () => updateRegistration(registrationId, userInput);
};

export const useSaveBeforeLogout = (params: AutoSaveParams | undefined) => {
  const save = useSaveMutation(params);
  const { mutate, isLoading } = useMutation(async () => save());

  return { saveData: mutate, isLoading };
};
