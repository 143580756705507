import { XMLParser } from 'fast-xml-parser';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { XMLParserOptions } from '../../../../../../../utils/expertToolsXML/helpers';
import { createErrorMessage } from '../../../../../../../utils/utils';
import { XMLReport } from '../../../../../../../types/expertToolsXML';

export const useFileUpload = () => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const parseAndUpload = ({
    file,
    onUpload
  }: {
    file: File;
    buildingIds?: string[];
    onUpload: (report: XMLReport) => void;
  }) => {
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      try {
        const parsedReport = new XMLParser(XMLParserOptions).parse(
          fileReader.result as string
        );

        onUpload(parsedReport);
      } catch (err) {
        const errorMessage = createErrorMessage(
          t('expertToolsXML.error_couldNotCreateRegistration')
        );

        addSnack(errorMessage, { variant: 'error' });
      }
    };

    fileReader.readAsText(file);
  };

  return {
    parseAndUpload
  };
};
