import React, { FC, Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  Grid,
  Link,
  NumberField,
  Radio,
  RadioGroup,
  Select
} from 'enova-frontend-components';
import { useDebounce } from 'rooks';
import { shallowEqual, useDispatch } from 'react-redux';
import { getYear } from 'date-fns';

import { Grunnforhold, TilliggendeRom } from '../../utils/registerEnums';
import CollapsibleRegField from '../../collapsibleRegField/collapsibleRegField';
import { camelCasify, kebabCasify } from '../../../../utils/navigation';
import { getGulvsone } from '../../../../store/registration/selectors';
import useSelector from '../../../../hooks/useSelector';
import { Gulvsone } from '../../../../types/registration/userInput';
import {
  updateBygningsdetaljer,
  updateGulvsone
} from '../../../../store/registration/actions';
import { useUser } from '../../../../hooks/useUser';
import { ConstructionSpecType } from '../../utils/constructionSpecType';
import {
  getRadioOptionsFromEnum,
  getSelectOptionsFromEnum
} from '../../../../utils/register';
import useRegistrationForm from '../../../../screens/registration/useRegistrationForm';
import {
  FieldError,
  MAX_NUMBER_OF_YEARS_IN_THE_FUTURE
} from '../../utils/validation';
import { UserRole } from '../../../../types/user';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';

import { FloorConstruction } from './floorConstruction';

type FloorZoneProps = {
  etasjeId: string;
  gulvsoneId: string;
  gulvsoneIndex?: number;
};

const FloorZone: FC<FloorZoneProps> = ({
  etasjeId,
  gulvsoneId,
  gulvsoneIndex
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useUser();
  const { xsScreen } = useMediaQuery();
  const { displayErrors } = useRegistrationForm();

  const [confirmDeleteDataDialogOpen, setConfirmDeleteDataDialogOpen] =
    useState(false);
  const [tmpTilliggendeRom, setTmpTilliggendeRom] = useState<TilliggendeRom>();

  const gulvsone = useSelector(
    (state) => getGulvsone(state, etasjeId, gulvsoneId),
    shallowEqual
  );

  const grunnforhold = useSelector(
    (state) => state.register.userInput?.bygningsdetaljer?.grunnforhold,
    shallowEqual
  );

  const byggAr = useSelector(
    (state) => state.register.userInput?.bygningsdetaljer?.byggAr
  );

  const getInitConstructionSpecTypeValue = () => {
    if (gulvsone?.gulvKonstruksjon?.uendretSidenOppforing)
      return ConstructionSpecType.UNCHANGED;

    if (gulvsone?.gulvKonstruksjon?.rehabiliteringEllerPabyggingsAr)
      return ConstructionSpecType.YEAR_OF_REHABILITATION;

    if (
      gulvsone?.gulvKonstruksjon?.isolasjonstykkelseIMillimeter ||
      gulvsone?.gulvKonstruksjon?.gulvType
    )
      return ConstructionSpecType.CONSTRUCTION;

    if (gulvsone?.gulvKonstruksjon?.brukStandardVerdier)
      return ConstructionSpecType.UNKNOWN;

    return null;
  };

  const [constructionSpecType, setConstructionSpecType] =
    useState<ConstructionSpecType | null>(getInitConstructionSpecTypeValue());

  const handleEditGulvsone = (field: keyof Gulvsone, value: unknown) =>
    dispatch(updateGulvsone({ field, value, gulvsoneId, etasjeId }));

  const updateConstructionSpecTypeValue = (
    newConstructionSpecTypeValue: ConstructionSpecType
  ) => {
    switch (newConstructionSpecTypeValue) {
      case ConstructionSpecType.UNCHANGED:
        handleEditGulvsone('gulvKonstruksjon', { uendretSidenOppforing: true });
        break;
      case ConstructionSpecType.UNKNOWN:
        handleEditGulvsone('gulvKonstruksjon', {
          brukStandardVerdier: true
        });
        break;
      case ConstructionSpecType.CONSTRUCTION:
      case ConstructionSpecType.YEAR_OF_REHABILITATION:
      default:
        handleEditGulvsone('gulvKonstruksjon', {});
        break;
    }
    setConstructionSpecType(newConstructionSpecTypeValue);
  };

  const handleEditGulvsoneDebounced = useDebounce<typeof handleEditGulvsone>(
    handleEditGulvsone,
    500,
    {
      leading: true,
      trailing: true
    }
  );

  const adjoiningRoomsOptions = useMemo(
    () => [
      {
        disabled: true,
        label: t('register.adjoiningRooms.option.default', {
          context: 'floor'
        }),
        value: -1
      },
      ...getSelectOptionsFromEnum(
        Object.entries(TilliggendeRom).reduce(
          (acc, [key, value]) =>
            value === TilliggendeRom.MotGrunnOgDetFri
              ? acc
              : { ...acc, [key]: value },
          {}
        ),
        (label) =>
          t(`register.adjoiningRooms.option.${label}`, { context: 'floor' })
      )
    ],
    []
  );

  if (!gulvsone) return null;

  const {
    arealIKvm,
    gulvKonstruksjon: {
      isolasjonstykkelseIMillimeter,
      gulvType,
      rehabiliteringEllerPabyggingsAr
    } = {}
  } = gulvsone;

  const displayExpandableGroupDivider =
    gulvsone?.tilliggendeRom === TilliggendeRom.MotGrunn &&
    constructionSpecType === ConstructionSpecType.CONSTRUCTION;

  const cancelChangeAdjoiningRoom = () => {
    setTmpTilliggendeRom(undefined);
    setConfirmDeleteDataDialogOpen(false);
  };

  const confirmChangeAdjoiningRoom = () => {
    tmpTilliggendeRom &&
      handleEditGulvsone('tilliggendeRom', tmpTilliggendeRom);
    setTmpTilliggendeRom(undefined);
    setConstructionSpecType(null);
  };

  const handleChangeAdjoiningRoom = (value: TilliggendeRom) => {
    if (
      value === TilliggendeRom.OppvarmetRom &&
      gulvsone?.gulvKonstruksjon &&
      Object.values(gulvsone?.gulvKonstruksjon).filter((item) => item != null)
        .length > 0
    ) {
      setTmpTilliggendeRom(value);
      setConfirmDeleteDataDialogOpen(true);
    } else handleEditGulvsone('tilliggendeRom', value);
  };

  const getYearError = () => {
    const year = rehabiliteringEllerPabyggingsAr;
    const isProfessional = user?.rolle === UserRole.PROFESJONELL;

    if (!year || !displayErrors) return FieldError.None;
    if (!year) return FieldError.Missing;
    if (typeof year !== 'number' || String(year).length !== 4)
      return FieldError.Invalid;

    const currentYear = getYear(Date.now());

    if (!isProfessional && year > currentYear) {
      return FieldError.Max;
    }

    if (year > currentYear + MAX_NUMBER_OF_YEARS_IN_THE_FUTURE)
      return FieldError.MaxProfessional;
    if (byggAr && year < byggAr) return FieldError.Min;
    return FieldError.None;
  };

  const hasError = getYearError() !== FieldError.None;

  return (
    <Fragment>
      <Box display="flex" flexDirection="column">
        <NumberField
          autoComplete
          label={t('register.floors.gulvsone.area', {
            context: gulvsoneIndex != null && 'zone',
            count: (gulvsoneIndex || 0) + 1
          })}
          marginBottom
          name="areal-i-kvm"
          onValueChange={({ floatValue }) =>
            handleEditGulvsoneDebounced('arealIKvm', floatValue)
          }
          suffix={t('suffix.m2')}
          value={arealIKvm}
        />

        <Box gap={2} display="flex" flexDirection="column">
          <CollapsibleRegField
            open={gulvsone?.tilliggendeRom === TilliggendeRom.MotGrunn}
          >
            <Grid container>
              <Grid item xs={12} sm="auto" maxWidth={1}>
                <Select
                  fullWidth
                  HelperBoxProps={{
                    'aria-label': t('readFieldInformation'),
                    children: t('register.floors.adjoiningRooms.information')
                  }}
                  label={t('register.floors.adjoiningRooms.label', {
                    context: gulvsoneIndex != null && 'zone',
                    count: (gulvsoneIndex || 0) + 1
                  })}
                  marginBottom
                  onChange={({ currentTarget: { value } }) =>
                    handleChangeAdjoiningRoom(value as TilliggendeRom)
                  }
                  options={adjoiningRoomsOptions}
                  value={gulvsone?.tilliggendeRom ?? -1}
                />
              </Grid>
            </Grid>

            <CollapsibleRegField.Collapse>
              <RadioGroup
                value={grunnforhold ?? Grunnforhold.Ukjent}
                label={t('register.floors.gulvsone.grunnforhold.label')}
                row
                onChange={(_, val) =>
                  dispatch(
                    updateBygningsdetaljer({
                      field: 'grunnforhold',
                      value: val ?? undefined
                    })
                  )
                }
              >
                <Fragment>
                  {getRadioOptionsFromEnum(Grunnforhold)
                    ?.filter(({ value }) => value !== Grunnforhold.FuktigJord)
                    ?.map((option) => (
                      <Radio
                        value={option.value}
                        key={option.value}
                        width="auto"
                        label={t(
                          `register.grunnforhold.options.${camelCasify(
                            option.label as string
                          )}`
                        )}
                      />
                    ))}
                </Fragment>
              </RadioGroup>
            </CollapsibleRegField.Collapse>
          </CollapsibleRegField>

          {gulvsone?.tilliggendeRom !== TilliggendeRom.OppvarmetRom && (
            <RadioGroup
              aria-label={t('register.floors.selectConstructionSpecType')}
              onChange={(_, val) =>
                updateConstructionSpecTypeValue(val as ConstructionSpecType)
              }
              value={constructionSpecType}
            >
              <CollapsibleRegField
                divider={displayExpandableGroupDivider}
                open={
                  constructionSpecType === ConstructionSpecType.CONSTRUCTION
                }
              >
                <Radio
                  width={xsScreen ? 'fullWidth' : 'auto'}
                  label={t(
                    `register.floors.constructionSpecType.${camelCasify(
                      ConstructionSpecType.CONSTRUCTION
                    )}`
                  )}
                  helperText={t('register.floors.knownTypeHelperText')}
                  className="mb-2"
                  value={ConstructionSpecType.CONSTRUCTION}
                />

                <CollapsibleRegField.Collapse>
                  <FloorConstruction
                    gulvType={gulvType}
                    isolasjonstykkelseIMillimeter={
                      isolasjonstykkelseIMillimeter
                    }
                    onChangeGulvType={(value) =>
                      handleEditGulvsone('gulvKonstruksjon', {
                        ...gulvsone.gulvKonstruksjon,
                        gulvType: value
                      })
                    }
                    onChangeGulvIsolasjon={(insulationValue) =>
                      handleEditGulvsone('gulvKonstruksjon', {
                        ...gulvsone.gulvKonstruksjon,
                        isolasjonstykkelseIMillimeter: insulationValue
                      })
                    }
                  />
                </CollapsibleRegField.Collapse>
              </CollapsibleRegField>

              <CollapsibleRegField
                open={
                  constructionSpecType ===
                  ConstructionSpecType.YEAR_OF_REHABILITATION
                }
              >
                <Radio
                  width={xsScreen ? 'fullWidth' : 'auto'}
                  label={t(
                    `register.floors.constructionSpecType.${camelCasify(
                      ConstructionSpecType.YEAR_OF_REHABILITATION
                    )}`
                  )}
                  helperText={t(
                    'register.floors.yearOfRehabilitation.helperText'
                  )}
                  className="mb-2"
                  value={ConstructionSpecType.YEAR_OF_REHABILITATION}
                />

                <CollapsibleRegField.Collapse>
                  <NumberField
                    autoComplete
                    label={t(
                      `register.floors.constructionSpecType.${camelCasify(
                        ConstructionSpecType.YEAR_OF_REHABILITATION
                      )}.label`
                    )}
                    name={kebabCasify(
                      ConstructionSpecType.YEAR_OF_REHABILITATION
                    )}
                    format="####"
                    onValueChange={({ floatValue }) =>
                      handleEditGulvsoneDebounced('gulvKonstruksjon', {
                        rehabiliteringEllerPabyggingsAr: floatValue
                      })
                    }
                    value={rehabiliteringEllerPabyggingsAr}
                    error={hasError}
                    helperText={
                      hasError
                        ? t(`register.parameters.error.year`, {
                            context: getYearError()
                          })
                        : ''
                    }
                  />
                </CollapsibleRegField.Collapse>
              </CollapsibleRegField>

              <Radio
                width={xsScreen ? 'fullWidth' : 'auto'}
                label={t(
                  `register.floors.constructionSpecType.${camelCasify(
                    ConstructionSpecType.UNCHANGED
                  )}`
                )}
                helperText={t(
                  'register.floors.unchangedConstruction.helperText'
                )}
                className="mb-2"
                value={ConstructionSpecType.UNCHANGED}
              />

              <Radio
                width={xsScreen ? 'fullWidth' : 'auto'}
                label={t(
                  `register.floors.constructionSpecType.${camelCasify(
                    ConstructionSpecType.UNKNOWN
                  )}`
                )}
                helperText={t('register.floors.unknownConstruction.helperText')}
                value={ConstructionSpecType.UNKNOWN}
              />
            </RadioGroup>
          )}
        </Box>
      </Box>
      <Dialog
        open={!!(tmpTilliggendeRom && confirmDeleteDataDialogOpen)}
        onClose={cancelChangeAdjoiningRoom}
      >
        <Dialog.Title>
          {t('register.floors.adjoiningRooms.deleteConstructionDialog.title')}
        </Dialog.Title>

        <Dialog.Content>
          {t(
            'register.floors.adjoiningRooms.deleteConstructionDialog.description'
          )}
        </Dialog.Content>

        <Dialog.Actions>
          <Link button onClick={cancelChangeAdjoiningRoom}>
            {t('cancel')}
          </Link>

          <Button onClick={confirmChangeAdjoiningRoom}>{t('confirm')}</Button>
        </Dialog.Actions>
      </Dialog>
    </Fragment>
  );
};

export default FloorZone;
