/* eslint-disable camelcase */
import Joi from 'joi';

import { BeregningskjerneInput, State } from '../utils';

export const SUM_OPPVARMING_ERROR = 'sumAndelOppvarming';
export const SUM_TAPPEVANN_ERROR = 'sumAndelTappevann';

const getExpertValueOrDefault = (
  expertValue: number | null,
  baseValue: number | null
) => {
  if (expertValue != null) {
    return expertValue;
  }
  if (baseValue != null) {
    return baseValue;
  }
  return 0;
};

const sumAndelOppvarming = (value: unknown, helpers: Joi.CustomHelpers) => {
  const state = helpers.state.ancestors?.[1] as State | undefined;

  if (!state) {
    return value;
  }

  const andelElektrisitet = getExpertValueOrDefault(
    state.el_er_andel_energi_oppv_ventilasjon?.expertValue,
    state.el_er_andel_energi_oppv_ventilasjon?.calculatedValue
  );

  const andelVarmepumpe = getExpertValueOrDefault(
    state.el_hp_andel_energi_oppv_ventilasjon?.expertValue,
    state.el_hp_andel_energi_oppv_ventilasjon?.calculatedValue
  );

  const andelSolfanger = getExpertValueOrDefault(
    state.el_Tsol_andel_energi_oppv_ventilasjon?.expertValue,
    state.el_Tsol_andel_energi_oppv_ventilasjon?.calculatedValue
  );

  const andelOlje = getExpertValueOrDefault(
    state.olje_andel_energi_oppv_ventilasjon?.expertValue,
    state.olje_andel_energi_oppv_ventilasjon?.calculatedValue
  );

  const andelGass = getExpertValueOrDefault(
    state.gass_andel_energi_oppv_ventilasjon?.expertValue,
    state.gass_andel_energi_oppv_ventilasjon?.calculatedValue
  );

  const andelFjernvarme = getExpertValueOrDefault(
    state.fjernvarme_andel_energi_oppv_ventilasjon?.expertValue,
    state.fjernvarme_andel_energi_oppv_ventilasjon?.calculatedValue
  );

  const andelBiobrensel = getExpertValueOrDefault(
    state.bio_andel_energi_oppv_ventilasjon?.expertValue,
    state.bio_andel_energi_oppv_ventilasjon?.calculatedValue
  );

  const andelAndreEnergivaner = getExpertValueOrDefault(
    state.annet_andel_energi_oppv_ventilasjon?.expertValue,
    state.annet_andel_energi_oppv_ventilasjon?.calculatedValue
  );

  const andelOppvarming =
    andelElektrisitet +
    andelVarmepumpe +
    andelSolfanger +
    andelOlje +
    andelGass +
    andelFjernvarme +
    andelBiobrensel +
    andelAndreEnergivaner;

  if (andelOppvarming !== 1) {
    // Add andelOppvarming to the error message to be able to display the correct limit value in the error message.
    throw new Error(`${SUM_OPPVARMING_ERROR}:${andelOppvarming}`);
  }

  return value;
};

const sumAndelTappevann = (value: unknown, helpers: Joi.CustomHelpers) => {
  const state = helpers.state.ancestors?.[1] as State | undefined;

  if (!state) {
    return value;
  }

  const andelElektrisitet = getExpertValueOrDefault(
    state.el_er_andel_energi_tappevann_varme?.expertValue,
    state.el_er_andel_energi_tappevann_varme?.calculatedValue
  );
  const andelVarmepumpe = getExpertValueOrDefault(
    state.el_hp_andel_energi_tappevann_varme?.expertValue,
    state.el_hp_andel_energi_tappevann_varme?.calculatedValue
  );
  const andelSolfanger = getExpertValueOrDefault(
    state.el_Tsol_andel_energi_tappevann_varme?.expertValue,
    state.el_Tsol_andel_energi_tappevann_varme?.calculatedValue
  );
  const andelOlje = getExpertValueOrDefault(
    state.olje_andel_energi_tappevann_varme?.expertValue,
    state.olje_andel_energi_tappevann_varme?.calculatedValue
  );
  const andelGass = getExpertValueOrDefault(
    state.gass_andel_energi_tappevann_varme?.expertValue,
    state.gass_andel_energi_tappevann_varme?.calculatedValue
  );
  const andelFjernvarme = getExpertValueOrDefault(
    state.fjernvarme_andel_energi_tappevann_varme?.expertValue,
    state.fjernvarme_andel_energi_tappevann_varme?.calculatedValue
  );
  const andelBiobrensel = getExpertValueOrDefault(
    state.bio_andel_energi_tappevann_varme?.expertValue,
    state.bio_andel_energi_tappevann_varme?.calculatedValue
  );
  const andelAndreEnergivaner = getExpertValueOrDefault(
    state.annet_andel_energi_tappevann_varme?.expertValue,
    state.annet_andel_energi_tappevann_varme?.calculatedValue
  );

  const andelTappevannOppvarming =
    andelElektrisitet +
    andelVarmepumpe +
    andelSolfanger +
    andelOlje +
    andelGass +
    andelFjernvarme +
    andelBiobrensel +
    andelAndreEnergivaner;

  if (andelTappevannOppvarming !== 1) {
    // Add andelOppvarming to the error message to be able to display the correct limit value in the error message.
    throw new Error(`${SUM_TAPPEVANN_ERROR}:${andelTappevannOppvarming}`);
  }

  return value;
};

const createMaxNumberSchema = (maxValue: number) => {
  return Joi.object<BeregningskjerneInput>({
    expertValue: Joi.number().allow(null).max(maxValue)
  });
};

const createSumAndelOppvarmingSchema = () => {
  return Joi.object({
    expertValue: Joi.number()
      .allow(null)
      .max(1)
      .custom(sumAndelOppvarming, SUM_OPPVARMING_ERROR)
  });
};

const createSumAndelTappevannSchema = () => {
  return Joi.object({
    expertValue: Joi.number()
      .allow(null)
      .max(1)
      .custom(sumAndelTappevann, SUM_TAPPEVANN_ERROR)
  });
};

export const schema = Joi.object<State>({
  varmetapsfaktor_uoppv: createMaxNumberSchema(1),
  tempvirkningsgrad_varmegjenvinner: createMaxNumberSchema(1),
  el_solcelle_andel_el_spesifikt_forbruk: createMaxNumberSchema(1),
  systemvirkningsgrad_varmepumpeanlegg_tappevann_varme:
    createMaxNumberSchema(10),
  systemvirkningsgrad_varmepumpeanlegg_oppv_ventilasjon:
    createMaxNumberSchema(10),
  systemvirkningsgrad_solfanger_termisk_tappevann_varme:
    createMaxNumberSchema(60),
  systemvirkningsgrad_solfanger_termisk_oppv_ventilasjon:
    createMaxNumberSchema(60),

  annet_andel_energi_oppv_ventilasjon: createSumAndelOppvarmingSchema(),
  annet_andel_energi_tappevann_varme: createSumAndelTappevannSchema(),
  bio_andel_energi_oppv_ventilasjon: createSumAndelOppvarmingSchema(),
  bio_andel_energi_tappevann_varme: createSumAndelTappevannSchema(),
  el_er_andel_energi_oppv_ventilasjon: createSumAndelOppvarmingSchema(),
  el_er_andel_energi_tappevann_varme: createSumAndelTappevannSchema(),
  el_hp_andel_energi_oppv_ventilasjon: createSumAndelOppvarmingSchema(),
  el_hp_andel_energi_tappevann_varme: createSumAndelTappevannSchema(),
  el_Tsol_andel_energi_oppv_ventilasjon: createSumAndelOppvarmingSchema(),
  el_Tsol_andel_energi_tappevann_varme: createSumAndelTappevannSchema(),
  fjernvarme_andel_energi_oppv_ventilasjon: createSumAndelOppvarmingSchema(),
  fjernvarme_andel_energi_tappevann_varme: createSumAndelTappevannSchema(),
  gass_andel_energi_oppv_ventilasjon: createSumAndelOppvarmingSchema(),
  gass_andel_energi_tappevann_varme: createSumAndelTappevannSchema(),
  olje_andel_energi_oppv_ventilasjon: createSumAndelOppvarmingSchema(),
  olje_andel_energi_tappevann_varme: createSumAndelTappevannSchema()
})
  // Field names that match these regular expressions will be validated alongside their corresponding schemas.
  // If you add the specific fieldname to the Joi.object above, it will have precedence over the pattern.
  .pattern(/solskjermingsfaktor/i, createMaxNumberSchema(1))
  .pattern(/arealfraksjon_karm/i, createMaxNumberSchema(1))
  .pattern(/solfaktor_vindu/i, createMaxNumberSchema(1))
  .pattern(/solfaktor_total/i, createMaxNumberSchema(1))
  .pattern(/andel_energi_oppv/i, createMaxNumberSchema(1))
  .pattern(/andel_energi_tappevann/i, createMaxNumberSchema(1))
  .pattern(/systemvirkningsgrad/i, createMaxNumberSchema(1));
