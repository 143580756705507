import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'enova-frontend-components';

import { TiltakKategori, TiltakType } from '../../../types/registration/tiltak';

import { useTiltakFormContext } from './utils';
import { useTiltakOptions } from './useTiltakOptions';

interface TiltakTypeSelectProps {
  isLoading: boolean;
  options?: TiltakType[];
}

export const TiltakTypeSelect: FC<TiltakTypeSelectProps> = ({
  isLoading,
  options
}) => {
  const { t } = useTranslation();

  const {
    register,
    watch,
    formState: { errors }
  } = useTiltakFormContext();

  const tiltaksKategori = watch('category');
  const tiltakOptions = useTiltakOptions(options);

  // Needs a category to get type select values
  if (tiltaksKategori === -1) {
    return null;
  }

  const isDisabled =
    isLoading || tiltaksKategori === TiltakKategori.Egendefinert;

  return (
    <Select
      {...register('name', {
        validate: (value) => value !== -1 || (t('required') as string)
      })}
      fullWidth
      error={!!errors.name}
      helperText={errors?.name?.message}
      label={t('tiltak.create.type.label')}
      options={tiltakOptions}
      required
      disabled={isDisabled}
    />
  );
};
