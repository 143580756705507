import { useTranslation } from 'react-i18next';

import { VeggId } from '../../../components/registering/utils/registerEnums';
import useStore from '../../../hooks/useStore';
import {
  UserInputErrorInformation,
  UserInputErrorType
} from '../../../services/types';
import { RegistrationStep } from '../../../utils/navigation';

import { UserInputErrorTypeStepMap } from './utils';

type GetStepErrorInformationFunc = (
  step: RegistrationStep,
  userInputErrorInformation?: UserInputErrorInformation[]
) => string[] | undefined;

type idMap = {
  etasje?: string;
  gulvsone?: string;
  vegg?: VeggId;
  veggSone?: string;
  vindu?: string;
};

const useStepErrorInformation = (): GetStepErrorInformationFunc => {
  const { t } = useTranslation();
  const store = useStore();

  const getStepErrorInformation: GetStepErrorInformationFunc = (
    step,
    userInputErrorInformation
  ) => {
    const userInputErrorTypeForStep = UserInputErrorTypeStepMap[step];

    if (
      userInputErrorInformation &&
      userInputErrorTypeForStep &&
      userInputErrorTypeForStep.length > 0
    ) {
      const filteredErrorInformation = userInputErrorInformation.filter(
        (element, index, array) =>
          array.findIndex(
            (subelement) =>
              subelement.errorType ===
                UserInputErrorType.TotalEtasjeArealStorreEnnOppvarmetAreal ||
              subelement.errorType ===
                UserInputErrorType.TotalEtasjeArealMindreEnnOppvarmetAreal
          ) === index ||
          (element.errorType !==
            UserInputErrorType.TotalEtasjeArealStorreEnnOppvarmetAreal &&
            element.errorType !==
              UserInputErrorType.TotalEtasjeArealMindreEnnOppvarmetAreal)
      );
      return filteredErrorInformation?.reduce(
        (stepErrorInformation: string[], { ids, errorType }) => {
          if (userInputErrorTypeForStep.includes(errorType)) {
            const {
              register: {
                userInput: {
                  bygningsdetaljer: { etasjer }
                }
              }
            } = store.getState();

            const formattedIds: idMap = ids?.reduce(
              (acc, { id, type }) => ({
                ...acc,
                [type]: id
              }),
              {}
            );

            const etasjeCount = etasjer.length;

            const etasje = etasjer?.find(
              ({ id }) => id === formattedIds.etasje
            );

            if (!etasje) return stepErrorInformation;

            const vegg = etasje?.vegger?.find(
              ({ veggId }) => veggId === formattedIds.vegg?.toLowerCase()
            );

            const veggsoneCount = vegg?.veggSoner.length;

            const veggsone = formattedIds.veggSone
              ? vegg?.veggSoner.find(({ id }) => id === formattedIds.veggSone)
              : undefined;

            const veggsoneIndex = veggsone
              ? vegg?.veggSoner.indexOf(veggsone)
              : undefined;

            const vinduIndex = formattedIds.vindu
              ? veggsone?.vinduer?.findIndex(
                  ({ id }) => id === formattedIds.vindu
                )
              : undefined;

            const gulvsoneCount = etasje?.gulvSoner?.length || 0;

            const gulvsoneIndex =
              etasje?.gulvSoner?.findIndex(
                ({ id }) => id === formattedIds.gulvsone
              ) ?? -1;

            const identifier = [];

            if (vinduIndex != null && vinduIndex !== -1)
              identifier.push(
                t('register.backendValidation.window', {
                  count: vinduIndex + 1
                })
              );

            if (vegg)
              identifier.push(
                t('register.backendValidation.wall', {
                  context:
                    veggsoneCount != null &&
                    veggsoneCount > 1 &&
                    veggsoneIndex != null
                      ? 'wallZone'
                      : undefined,
                  veggId: vegg.veggId?.toUpperCase(),
                  wallZoneId: `${vegg.veggId?.toUpperCase()}${
                    veggsoneIndex != null ? veggsoneIndex + 1 : ''
                  }`
                })
              );

            if (gulvsoneCount > 1 && gulvsoneIndex !== -1)
              identifier.push(
                t('register.backendValidation.floorZone', {
                  count: gulvsoneIndex + 1
                })
              );

            if (etasjeCount > 1)
              identifier.push(
                t('register.backendValidation.inFloorNo', {
                  context: etasje.erKjeller ? 'basement' : undefined,
                  count: etasje.etasjeNummer
                })
              );

            const label =
              errorType ===
                UserInputErrorType.TotalEtasjeArealStorreEnnOppvarmetAreal ||
              errorType ===
                UserInputErrorType.TotalEtasjeArealMindreEnnOppvarmetAreal
                ? t('register.backendValidation.baseTotalEtasjeArea')
                : t('register.backendValidation.base', {
                    content: identifier.join(' ')
                  });

            const formattedError = t('register.backendValidation.errorType', {
              context: errorType
            });

            return [
              ...stepErrorInformation,
              identifier.length > 0
                ? `${label}: ${formattedError}`
                : formattedError
            ];
          }

          return stepErrorInformation;
        },
        []
      );
    }

    return undefined;
  };

  return getStepErrorInformation;
};

export default useStepErrorInformation;
