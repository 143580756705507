import { FC } from 'react';
import { Button, Grid } from 'enova-frontend-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BuildingSearchFormData, useBuildingSearchFormContext } from '../types';
import { useMediaQuery } from '../../../../../../../../../hooks/useMediaQuery';

import { KommuneField } from './components/kommuneField';
import { FormErrors } from './components/formErrors';
import { SearchFormNumberField } from './components/searchFormNumberField';
import { SearchFormTextField } from './components/searchFormTextField';
import { createSearchParams } from './utils';

export const Form: FC = () => {
  const { search, pathname } = useLocation();
  const { xsScreen } = useMediaQuery();
  const { push } = useHistory();
  const { t } = useTranslation();

  const { handleSubmit } = useBuildingSearchFormContext();

  const handleSearch = (searchValues: BuildingSearchFormData) => {
    push({
      pathname,
      search: createSearchParams(searchValues, search).toString()
    });
  };

  const onSubmit = handleSubmit((formData) => handleSearch(formData));

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid container spacing={3} item xs={7}>
          <Grid item xs={12} sm={12}>
            <KommuneField />
          </Grid>
        </Grid>

        <Grid container spacing={3} item xs={7}>
          <Grid item xs={12} sm={6}>
            <SearchFormNumberField
              field="gardsnummer"
              fieldDependencies={['gatenavn', 'bruksnummer', 'bygningsnummer']}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SearchFormNumberField field="bruksnummer" />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={7}>
          <SearchFormNumberField field="seksjonsnummer" />
        </Grid>

        <Grid item xs={12} sm={7}>
          <SearchFormTextField
            field="gatenavn"
            fieldDependencies={['bygningsnummer', 'gardsnummer', 'bruksnummer']}
          />
        </Grid>

        <Grid container spacing={3} xs={12} item sm={7}>
          <Grid item xs={12} sm={6}>
            <SearchFormNumberField field="nummer" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SearchFormTextField field="bokstav" />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={7}>
          <SearchFormNumberField
            field="bygningsnummer"
            fieldDependencies={['gatenavn', 'gardsnummer', 'bruksnummer']}
          />
        </Grid>

        <Grid item xs={12} sm={7}>
          <SearchFormTextField field="bruksenhetsnummer" />
        </Grid>

        <Grid item xs={12} sm={7}>
          <SearchFormNumberField field="festenummer" />
        </Grid>

        <Grid item xs={12} sm={7}>
          <FormErrors />
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth={xsScreen}
            style={{ minWidth: '90px' }}
            type="submit"
          >
            {t('search.button')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
