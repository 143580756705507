import React, { FC } from 'react';
import { Box } from 'enova-frontend-components';

import { Vegg } from '../../../../../types/registration/userInput';
import { WallZone } from '../wallZone';
import { WallZoneData } from '../types';

interface Props {
  isUniformWall: boolean;
  wall: Vegg;
  etasjeId: string;
}

export const WallZones: FC<Props> = ({ isUniformWall, wall, etasjeId }) => {
  if (wall.veggSoner.length === 0) {
    return null;
  }

  if (isUniformWall) {
    // Uniform wall have only one wall zone
    const wallZone = wall.veggSoner?.[0];

    const wallZoneData: WallZoneData = {
      wallZone,
      floorId: etasjeId,
      wallId: wall.veggId,
      wallZoneIndex: 0
    };

    return (
      <Box m={4}>
        <WallZone wallZoneData={wallZoneData} />
      </Box>
    );
  }

  return (
    <Box m={4}>
      {wall.veggSoner?.map((zone, index) => {
        const wallZoneData: WallZoneData = {
          wallZone: zone,
          floorId: etasjeId,
          wallId: wall.veggId,
          wallZoneIndex: index
        };

        return (
          <Box my={3} key={index}>
            <WallZone wallZoneData={wallZoneData} />
          </Box>
        );
      })}
    </Box>
  );
};
