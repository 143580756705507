import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { WallZoneData, WallZoneFormData } from '../types';
import { TilliggendeRom } from '../../../utils/registerEnums';
import { Veggsone } from '../../../../../types/registration/userInput';

import { Form } from './form';
import { useWallZoneDefaultValues } from './useWallZoneDefaultValues';
import { useWallZoneOperations } from './form/useWallZoneOperations';
import { useBuildingConstructionData } from './form/useBuildingConstructionData';

interface Props {
  data: WallZoneData;
  onSave: () => void;
}

export const EditWallZoneForm: FC<Props> = ({ data, onSave }) => {
  const defaultValues = useWallZoneDefaultValues(data.wallZone);
  const { allWallsAgainstTheOpen } = useBuildingConstructionData();
  const methods = useForm<WallZoneFormData>({
    defaultValues
  });

  const { updateVeggsone, removeVeggsone } = useWallZoneOperations(
    data.floorId,
    data.wallId
  );

  const onSubmit = ({ wallZoneData }: WallZoneFormData) => {
    if (!wallZoneData.wallZone.id) {
      return;
    }

    const tilliggendeRom = allWallsAgainstTheOpen
      ? TilliggendeRom.MotDetFri
      : wallZoneData.wallZone.tilliggendeRom;

    const veggsone: Veggsone = {
      ...wallZoneData.wallZone,
      tilliggendeRom
    };

    updateVeggsone(veggsone, data.wallZoneIndex);
    onSave();
  };

  return (
    <FormProvider {...methods}>
      <Form
        allWallsAgainstTheOpen={allWallsAgainstTheOpen}
        onSubmit={onSubmit}
        onDelete={() => removeVeggsone(data.wallZone.id)}
      />
    </FormProvider>
  );
};
