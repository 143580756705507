import { RootState } from '../../../../store/rootReducer';
import { camelCasify } from '../../../../utils/navigation';
import {
  getKeysWithValue,
  getRadioOptionsWithTLabelKey
} from '../../../../utils/register';
import {
  KjelKaminVannbarenVarme,
  TTermostatstyring,
  TTermostatstyringGulv,
  VarmepumpeVentilasjon
} from '../../../../types/registration/userInput';
import { Field, FieldType } from '../../../../types/registration/fieldTypes';
import {
  JaNeiVetIkke,
  OppvarmingBruk,
  Termostatstyring,
  TermostatstyringGulv,
  Varmesentral
} from '../registerEnums';
import { SelectorFunc } from '../validation';

type KjelKaminBaseSelector = (
  state: RootState
) => KjelKaminVannbarenVarme | undefined;

type VarmepumpeVentilasjonBaseSelector = (
  state: RootState
) => VarmepumpeVentilasjon | undefined;

const serviceGjennomfortSiste2ArDefaultValue = {
  serviceGjennomfortSiste2Ar: JaNeiVetIkke.VetIkke
};

const varmepumpeDefaultValueOnCheck = {
  bruk: OppvarmingBruk.Ukjent,
  ...serviceGjennomfortSiste2ArDefaultValue
};

const varmesentralDefaultValueOnCheck = { varmesentral: Varmesentral.Ukjent };

export const kjelOrKaminDefaultValueOnCheck = {
  ...varmepumpeDefaultValueOnCheck,
  ...varmesentralDefaultValueOnCheck
};

export type CommonFieldGroupBaseSelector = (
  state: RootState
) => KjelKaminVannbarenVarme | VarmepumpeVentilasjon | undefined;

export const getInstallasjonsArField = (
  selector: SelectorFunc,
  basePath: string[]
): FieldType => ({
  attrKey: 'installasjonsAr',
  path: [...basePath, 'installasjonsAr'],
  selector,
  tLabelKey: 'register.tekniskUtstyr.installasjonsAr.label',
  tInformationKey: `register.tekniskUtstyr.${camelCasify(
    basePath[basePath.length - 1]
  )}.installasjonsAr.information`,
  type: Field.YEAR
});

export const getServiceGjennomfortSiste2ArField = (
  selector: SelectorFunc,
  type: 'kjel' | 'kamin' | 'punktoppvarming' | 'vannbarenVarme' | 'ventilasjon',
  basePath: string[]
): FieldType => ({
  selector,
  attrKey: 'serviceGjennomfortSiste2Ar',
  path: [...basePath, 'serviceGjennomfortSiste2Ar'],
  tLabelKey: `register.tekniskUtstyr.${type}.serviceGjennomfortSiste2Ar.label`,
  tInformationKey: `register.tekniskUtstyr.${camelCasify(
    basePath[basePath.length - 1]
  )}.serviceGjennomfortSiste2Ar.information`,
  options: getRadioOptionsWithTLabelKey(JaNeiVetIkke, 'auto'),
  row: true,
  type: Field.RADIO
});

const getCommonFieldGroup = (
  baseSelector: CommonFieldGroupBaseSelector,
  type: 'kjel' | 'kamin' | 'vannbarenVarme' | 'ventilasjon',
  basePath: string[]
): FieldType[] => [
  {
    attrKey: 'bruk',
    path: [...basePath, 'bruk'],
    options: getRadioOptionsWithTLabelKey(OppvarmingBruk, 'auto'),
    row: true,
    selector: (state) => baseSelector(state)?.bruk,
    tLabelKey: `register.tekniskUtstyr.${type}.bruk.label`,
    type: Field.RADIO
  },
  {
    attrKey: 'elektrokjelens-standard',
    component: 'p',
    gutterBottom: true,
    tContentKey: `register.tekniskUtstyr.${type}.standard`,
    type: Field.TYPOGRAPHY,
    variant: 'h4'
  },
  getInstallasjonsArField(
    (state) => baseSelector(state)?.installasjonsAr,
    basePath
  ),
  getServiceGjennomfortSiste2ArField(
    (state) => baseSelector(state)?.serviceGjennomfortSiste2Ar,
    type,
    basePath
  )
];

export const getVarmepumpeVentilationFields = (
  baseSelector: VarmepumpeVentilasjonBaseSelector,
  type: 'kjel' | 'kamin' | 'vannbarenVarme' | 'ventilasjon',
  basePath: string[]
): FieldType[] => [
  {
    attrKey: 'varmesentral',
    path: [...basePath, 'varmesentral'],
    options: getRadioOptionsWithTLabelKey(Termostatstyring, 'auto'),
    row: true,
    selector: (state) => baseSelector(state)?.varmesentral,
    tLabelKey: `register.tekniskUtstyr.${type}.varmesentral.label`,
    tInformationKey: `register.varmesentral.${camelCasify(
      basePath[basePath.length - 1]
    )}.information`,
    type: Field.RADIO
  },
  ...getCommonFieldGroup(baseSelector, type, basePath)
];

export const getNestedTermostatstyringField = (
  baseSelector: (state: RootState) => TTermostatstyring | undefined,
  basePath: string[]
): FieldType => ({
  attrKey: 'termostatstyring',
  options: getRadioOptionsWithTLabelKey(Termostatstyring, 'auto'),
  path: [...basePath, 'termostatstyring'],
  row: true,
  selector: (state) => baseSelector(state)?.termostatstyring,
  tLabelKey: `register.termostatstyring.${camelCasify(
    basePath[basePath.length - 1]
  )}.label`,
  tInformationKey: `register.termostatstyring.${camelCasify(
    basePath[basePath.length - 1]
  )}.information`,
  type: Field.RADIO
});

export const getTermostatstyringField = (
  selector: (state: RootState) => Termostatstyring | undefined,
  path: string[]
): FieldType => ({
  attrKey: 'termostatstyring',
  options: getRadioOptionsWithTLabelKey(Termostatstyring, 'auto'),
  path,
  row: true,
  selector,
  tLabelKey: `register.termostatstyring.${camelCasify(
    path[path.length - 1]
  )}.label`,
  tInformationKey: `register.termostatstyring.${camelCasify(
    path[path.length - 1]
  )}.information`,
  type: Field.RADIO
});

export const getTermostatstyringGulvField = (
  baseSelector: (state: RootState) => TTermostatstyringGulv | undefined,
  basePath: string[]
): FieldType => ({
  attrKey: 'termostatstyring',
  options: getRadioOptionsWithTLabelKey(TermostatstyringGulv, 'auto'),
  path: [...basePath, 'termostatstyring'],
  row: true,
  selector: (state) => baseSelector(state)?.termostatstyring,
  tLabelKey: `register.termostatstyring.${camelCasify(
    basePath[basePath.length - 1]
  )}.label`,
  tInformationKey: `register.termostatstyringGulv.${camelCasify(
    basePath[basePath.length - 1]
  )}.information`,
  type: Field.RADIO
});

export const getKjelOrKaminFields = (
  baseSelector: KjelKaminBaseSelector,
  type: 'kjel' | 'kamin' | 'vannbarenVarme' | 'ventilasjon',
  basePath: string[]
): FieldType[] => [
  {
    attrKey: 'varmesentral',
    path: [...basePath, 'varmesentral'],
    options: getRadioOptionsWithTLabelKey(Varmesentral, 'auto'),
    row: true,
    selector: (state) => baseSelector(state)?.varmesentral,
    tLabelKey: `register.tekniskUtstyr.${type}.varmesentral.label`,
    tInformationKey: `register.varmesentral.${camelCasify(
      basePath[basePath.length - 1]
    )}.information`,
    type: Field.RADIO
  },
  ...getCommonFieldGroup(baseSelector, type, basePath),
  {
    attrKey: 'tekniskUtstyr.fordeling',
    tDescriptionKey: 'register.tekniskUtstyr.fordeling.description',
    path: basePath,
    tLabelKey: `register.tekniskUtstyr.${type}.fordeling.label`,
    tInformationKey: `register.tekniskUtstyr.${camelCasify(
      basePath[basePath.length - 1]
    )}.fordeling.information`,
    selector: (state) => getKeysWithValue(baseSelector(state)),
    type: Field.CHECK,
    options: [
      {
        defaultValueOnCheck: Termostatstyring.Ukjent,
        fields: [
          getTermostatstyringField(
            (state) => baseSelector(state)?.gulvvarme,
            [...basePath, 'gulvvarme']
          )
        ],
        tLabelKey: `register.tekniskUtstyr.${camelCasify(
          basePath[basePath.length - 1]
        )}.gulvvarme.label`,
        value: 'gulvvarme'
      },
      {
        defaultValueOnCheck: Termostatstyring.Ukjent,
        fields: [
          getTermostatstyringField(
            (state) => baseSelector(state)?.radiator,
            [...basePath, 'radiator']
          )
        ],
        tLabelKey: 'register.tekniskUtstyr.radiator.label',
        value: 'radiator'
      }
    ]
  }
];
