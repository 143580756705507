import { Button, Grid, TextField, useSnack } from 'enova-frontend-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../hooks/useUser';

export const UserIdButton: FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { addSnack } = useSnack();
  const copyToClipboard = () => {
    navigator.clipboard.writeText(user?.userId ?? '');
    addSnack(t('profileScreen.copieduserid'), {
      variant: 'success'
    });
  };

  return (
    <Grid container item columnSpacing={3}>
      <Grid item xs={12} sm={5}>
        <TextField
          inputProps={{ readOnly: true }}
          label={t('profileScreen.copyuseriddescription')}
          value={user?.userId ?? ''}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={5} pt={7}>
        <Button variant="secondary" size="large" onClick={copyToClipboard}>
          {t('profileScreen.copyuserid')}
        </Button>
      </Grid>
    </Grid>
  );
};
