import React, { FC, ReactNode } from 'react';
import { Box, Collapse } from 'enova-frontend-components';

import useCollapsible from './useCollapsible';

const RegFieldCollapse: FC<{ children: ReactNode }> = ({ children }) => {
  const { open } = useCollapsible();

  return (
    <Collapse in={open}>
      <Box pl={4} pt={2} display="flex" flexDirection="column">
        {children}
      </Box>
    </Collapse>
  );
};

export default RegFieldCollapse;
