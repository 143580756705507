// Removes all null values
export const sanitize = <T>(obj: T): T =>
  JSON.parse(
    JSON.stringify(obj, (_, value) => (value == null ? undefined : value))
  );

export const isFalsy = (value: unknown): boolean =>
  value == null || value === '' || value === 0 || value === '0';

// Removes all falsy values
export const deepSanitize = <T>(obj: T): Partial<T> =>
  JSON.parse(
    JSON.stringify(obj, (_, value) => (isFalsy(value) ? undefined : value))
  );
