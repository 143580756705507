import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import { fylkerDictionary } from '../../utils/kommuner';
import { GetEnovaKategoriFromMatrikkel } from '../../utils/matrikkelBygningsTyper';
import { useGetBuildingAddress } from '../useGetBuildingAddress';

import BuildingFilterContext, {
  BuildingFilterContextValues,
  BuildingListValues,
  CategoryOptions,
  FilterCategory,
  FilterValues,
  Mode,
  Order,
  SortAttribute,
  SortOrder
} from './context';

type FilterProviderProps = Pick<
  BuildingFilterContextValues,
  'allowFilter' | 'allowSearch' | 'buildingList' | 'categories'
> & { children: ReactNode };

type SearchValues = Partial<FilterValues> & {
  dir?: SortOrder;
  sort?: SortAttribute;
  q?: string;
};

const BuildingFilterProvider: FC<FilterProviderProps> = ({
  allowFilter,
  allowSearch,
  buildingList,
  buildingList: { buildings, ...buildingListValues } = {},
  categories,
  children
}) => {
  const { getBuildingAddress } = useGetBuildingAddress();
  const [body, setBody] = useState('');

  const searchValues = useMemo<SearchValues>(() => {
    let sv: SearchValues = JSON.parse(body || '{}');

    if (!allowSearch && !!sv.q) delete sv.q;

    if (!allowFilter)
      Object.values(FilterCategory).forEach((fc) => {
        if (sv[fc]) delete sv[fc];
      });
    else {
      Object.values(FilterCategory).forEach((fc) => {
        const val = sv[fc];

        if (val)
          if (Array.isArray(val))
            sv = {
              ...sv,
              [fc]: val?.filter((fv) => CategoryOptions[fc].includes(fv))
            };
          else if (typeof val === 'string' && CategoryOptions[fc].includes(val))
            sv = {
              ...sv,
              [fc]: [val]
            };
          else delete sv[fc];
      });
    }

    return sv;
  }, [body]);

  const [filterValues, setFilterValues] = useState<FilterValues>({
    [FilterCategory.BUILDING_CATEGORY]:
      searchValues[FilterCategory.BUILDING_CATEGORY] || [],
    [FilterCategory.ENERGY_CERTIFICATE]:
      searchValues[FilterCategory.ENERGY_CERTIFICATE] || [],
    [FilterCategory.GEOGRAPHY]: searchValues[FilterCategory.GEOGRAPHY] || [],
    [FilterCategory.STATUS]: searchValues[FilterCategory.STATUS] || []
  });

  const updateFilterValues = (
    category: FilterCategory,
    option: string,
    checked: boolean
  ) =>
    setFilterValues((prev) => ({
      ...prev,
      [category]: checked
        ? [...prev[category], option]
        : prev[category].filter((val) => val !== option)
    }));

  const [mode, setMode] = useState<Mode>();
  const [mounted, setMounted] = useState(false);

  const [searchString, setSearchString] = useState(searchValues.q);

  const defaultOrderAttr = SortAttribute.ADDRESS;
  const defaultOrderDir = SortOrder.DESC;

  const [order, setOrderBy] = useState<Order>([
    searchValues.sort || defaultOrderAttr,
    searchValues.dir || defaultOrderDir
  ]);

  const [orderAttr, orderDir] = order;

  const updateOrder = (newAttr: SortAttribute) =>
    setOrderBy(([prevAttr, prevOrder]) =>
      prevAttr === newAttr
        ? [
            prevAttr,
            prevOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC
          ]
        : [newAttr, defaultOrderDir]
    );

  const generateRequestBody = () => {
    const queryObj: SearchValues = {
      q: searchString,
      ...Object.entries(filterValues).reduce(
        (acc, [key, value]) =>
          value.length === 0 ? acc : { ...acc, [key]: value },
        {}
      )
    };

    if (orderAttr != null && orderAttr !== defaultOrderAttr)
      queryObj.sort = orderAttr;

    if (orderDir != null && orderDir !== defaultOrderDir)
      queryObj.dir = orderDir;

    return JSON.stringify(queryObj);
  };

  const handleSearch = () => {
    setBody(generateRequestBody());
  };

  useEffect(() => {
    if (mounted) {
      handleSearch();
    } else {
      setMounted(true);
    }
  }, [filterValues, order]);

  const clearSortOrder = () => setOrderBy([defaultOrderAttr, defaultOrderDir]);

  const clearAll = () => {
    setFilterValues({
      [FilterCategory.BUILDING_CATEGORY]: [],
      [FilterCategory.ENERGY_CERTIFICATE]: [],
      [FilterCategory.GEOGRAPHY]: [],
      [FilterCategory.STATUS]: []
    });
    clearSortOrder();
  };

  const clearFilterCategory = (category: FilterCategory) =>
    setFilterValues((prev) => ({
      ...prev,
      [category]: []
    }));

  let sortedBuildingList = buildings;
  let filteredSortedBuildingList = buildings;

  if (buildingList) {
    sortedBuildingList = sortedBuildingList?.sort((a, b) => {
      let aVal;
      let bVal;
      const aDate = a?.gyldigeEnergiattester?.[0]?.publisertDato;
      const bDate = b?.gyldigeEnergiattester?.[0]?.publisertDato;
      switch (orderAttr) {
        default:
        case SortAttribute.ADDRESS:
          aVal = getBuildingAddress(a.adresse);
          bVal = getBuildingAddress(b.adresse);
          break;
        case SortAttribute.REGISTERED:
          aVal = aDate ? new Date(aDate).getTime() : 0;
          bVal = bDate ? new Date(bDate).getTime() : 0;
          break;
      }

      if (!aVal) return 1;
      if (!bVal) return -1;

      if (orderDir === SortOrder.ASC)
        return aVal < bVal ? 1 : aVal > bVal ? -1 : 0;
      return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
    });

    filteredSortedBuildingList = sortedBuildingList?.filter((item) => {
      return (
        (filterValues.geography.length > 0
          ? filterValues.geography
              .flatMap((fylke) => fylkerDictionary[fylke])
              .includes(item.adresse.kommuneNummer)
          : true) &&
        (filterValues.buildingCategory.length > 0
          ? filterValues.buildingCategory.includes(
              GetEnovaKategoriFromMatrikkel(item.bygningsTypeNummer)
            )
          : true)
      );
    });
  }

  const values: BuildingFilterContextValues = {
    allowFilter,
    allowSearch,
    buildingList: buildingList
      ? ({
          buildings: filteredSortedBuildingList,
          ...buildingListValues
        } as BuildingListValues)
      : undefined,
    categories,
    clearAll,
    clearFilterCategory,
    clearSortOrder,
    filterValues,
    handleSearch,
    mode,
    order,
    searchString: searchString ?? '',
    setMode,
    setSearchString,
    updateFilterValues,
    updateOrder,
    body
  };

  return (
    <BuildingFilterContext.Provider value={values}>
      {children}
    </BuildingFilterContext.Provider>
  );
};

export default BuildingFilterProvider;
