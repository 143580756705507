import { useMutation } from 'react-query';

import { Tiltak } from '../../../types/registration/tiltak';
import { updateTiltak } from '../../../services/api';
import { queryClient, queryKeys } from '../../../utils/react-query';

export const useUpdateEnergiplanTiltak = (energiplanId: string) => {
  const { mutate, isLoading } = useMutation(
    (tiltak: Tiltak) => updateTiltak(tiltak),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          queryKeys.energyplanMeasures,
          energiplanId
        ])
    }
  );

  return { mutate, isLoading };
};
