import { FC } from 'react';

import { ReactComponent as LShapeMirroredNorth } from '../../../../../../../assets/icons/shapes/l-shape-mirrored/l-shape-mirrored-1.svg';
import { ReactComponent as LShapeMirroredEast } from '../../../../../../../assets/icons/shapes/l-shape-mirrored/l-shape-mirrored-2.svg';
import { ReactComponent as LShapeMirroredSouth } from '../../../../../../../assets/icons/shapes/l-shape-mirrored/l-shape-mirrored-3.svg';
import { ReactComponent as LShapeMirroredWest } from '../../../../../../../assets/icons/shapes/l-shape-mirrored/l-shape-mirrored-4.svg';
import { Himmelretning } from '../../../../../utils/registerEnums';

import { ShapeProps } from './types';

export const LShapeMirrored: FC<ShapeProps> = ({ direction }) => {
  switch (direction) {
    case Himmelretning.Nord:
      return <LShapeMirroredNorth />;
    case Himmelretning.Ost:
      return <LShapeMirroredEast />;
    case Himmelretning.Sor:
      return <LShapeMirroredSouth />;
    case Himmelretning.Vest:
      return <LShapeMirroredWest />;

    default:
      return <LShapeMirroredNorth />;
  }
};
