/* eslint-disable consistent-return */
import React, { FC, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import useBuildingDetails from '../../hooks/useBuildingDetails';
import {
  AllRegistrationScreenParams,
  getRegistrationScreenPath,
  getRegistrationSummaryScreenPath
} from '../../utils/navigation';
import { initRegistration } from '../../store/registration/actions';

import RegistrationFormProvider from './useRegistrationForm/provider';
import RegistrationScreenContent from './content';

const RegistrationScreen: FC = () => {
  const { address, soknadStegMap } = useBuildingDetails();
  const { t } = useTranslation();
  const { registrationId, originalRegistrationId } =
    useParams<AllRegistrationScreenParams>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initRegistration());
  }, []);

  const helmetTitle = [t('registerScreen.pageTitle'), address].join(' | ');

  return (
    <Fragment>
      <Helmet title={helmetTitle} />

      <RegistrationFormProvider
        generatePath={getRegistrationScreenPath}
        generateSummaryPath={getRegistrationSummaryScreenPath}
        regId={registrationId ?? originalRegistrationId}
        registrationId={registrationId}
      >
        <RegistrationScreenContent soknadStegMap={soknadStegMap} />
      </RegistrationFormProvider>
    </Fragment>
  );
};

export default RegistrationScreen;
