import { MutationCache, QueryCache, QueryClient } from 'react-query';
import * as Sentry from '@sentry/react';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  },
  queryCache: new QueryCache({
    onError: (error) => {
      Sentry.captureException(error);
    }
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      Sentry.captureException(error);
    }
  })
});

export const queryKeys = {
  area: 'area',
  user: 'user',
  brreg: 'brreg',
  building: 'building',
  buildingList: 'buildingList',
  energyAnalysis: 'energyAnalysis',
  energyAnalysisList: 'energyAnalysisList',
  energyplanMeasures: 'energyplanMeasures',
  expertReports: 'exportReports',
  kommune: 'kommune',
  userSelectedBuildingList: 'userSelectedBuildingList',
  search: 'search',
  searchPoststed: 'searchPoststed',
  searchBuilding: 'searchBuilding',
  advancedSearch: 'advancedSearch',
  portfolio: 'portfolio',
  portfolioList: 'portfolioList',
  portfolioMeasures: 'portfolioMeasures',
  insulation: 'insulation',
  statistic: 'statistic',
  unofficialEnergyCertificate: 'unofficialEnergyCertificate',
  userInput: 'userInput',
  applicationMeasures: 'applicationMeasures',
  supportLevels: 'supportLevels',
  measureCategories: 'measureCategories',
  flerboligRegistration: 'flerboligRegistration',
  userExpertRegistrations: 'userExpertRegistrations',
  grupperegistreringMeasures: 'grupperegistreringMeasures',
  // Is only used to check if submitRegistration request is pending inside adjustParameters.
  // Used to disable ActionButtons in registration wizard. Will be removed.
  // eslint-disable-next-line camelcase
  UNSAFE_SumitRegistrationAsQuery: 'hack-query-key'
};
