import React, { FC } from 'react';

import CollapsibleContext, { CollapsibleValues } from './context';

const CollapsibleProvider: FC<CollapsibleValues> = ({ children, open }) => (
  <CollapsibleContext.Provider value={{ open }}>
    {children}
  </CollapsibleContext.Provider>
);

export default CollapsibleProvider;
