import React, { FC, Fragment } from 'react';
import { Grid } from 'enova-frontend-components';
import { shallowEqual } from 'react-redux';

import useSelector from '../../../../../../../hooks/useSelector';
import { getMinifiedEtasjer } from '../../../../../../../store/registration/selectors';

import ShapeAndDirectionSummaryContent from './shapeAndDirectionSummaryContent';

const ShapeAndDirectionSummarySection: FC = () => {
  const allFloorsSameShape = useSelector(
    (state) => state.register.allFloorsSameShape
  );

  const etasjer = useSelector(getMinifiedEtasjer, shallowEqual);

  return (
    <Fragment>
      {allFloorsSameShape && etasjer?.[0] ? (
        <Grid item xs={12}>
          <ShapeAndDirectionSummaryContent etasjeId={etasjer[0].id} />
        </Grid>
      ) : (
        etasjer.map(({ id }) => (
          <Grid item xs={12} key={id}>
            <ShapeAndDirectionSummaryContent etasjeId={id} />
          </Grid>
        ))
      )}
    </Fragment>
  );
};

export default ShapeAndDirectionSummarySection;
