import { Box, Button } from 'enova-frontend-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFloorFormContext } from '../../utils';
import { rotateWalls } from '../../hooks/utils';
import { BuildingShapeWithCompass } from '../../../../../buildingShapeWithCompass';
import { BygningsForm, Himmelretning } from '../../../../utils/registerEnums';

export const ShapeDirection = () => {
  const { t } = useTranslation();
  const { setValue, watch } = useFloorFormContext();
  const shape = watch('floor.bygningsForm');
  const walls = watch('floor.vegger');

  const onRotate = () => {
    if (!walls) {
      return;
    }

    const rotatedWalls = rotateWalls(walls);
    setValue(`floor.vegger`, rotatedWalls);
  };

  // Use wall A as direction guide
  const direction = walls?.[0]?.himmelretning;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <BuildingShapeWithCompass
        shape={shape ?? BygningsForm.Unknown}
        direction={direction ?? Himmelretning.Nord}
      />

      <Box ml="auto">
        <Button variant="secondary" onClick={onRotate}>
          {t('register.buildingShape.rotateBuilding')}
        </Button>
      </Box>
    </Box>
  );
};
