import {
  Box,
  Chip,
  EnovaTheme,
  FormMessage,
  Icon,
  IconProps,
  Loader,
  styled
} from 'enova-frontend-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  row-gap: ${({ theme }) => theme.spacing(2)};
`;

const BuildingListLoader = () => (
  <Box
    className="w-100"
    display="flex"
    m={4}
    justifyContent="center"
    alignItems="center"
  >
    <Loader size="large" />
  </Box>
);

const BuildingListError = () => {
  const { t } = useTranslation();

  return (
    <FormMessage
      fullWidth
      title={t('buildingList.loadBuildingListError.title')}
    >
      {t('buildingList.loadBuildingListError.description')}
    </FormMessage>
  );
};

const StyledChip = styled(Chip)`
  span {
    align-items: center;
    display: flex;
  }
` as typeof Chip;

type RotatingIconProps = IconProps & { turn: boolean; theme: EnovaTheme };

// eslint-disable-next-line
const RotatingIcon = styled(({ turn, ...rest }: RotatingIconProps) => (
  <Icon {...rest} />
))`
  margin-left: ${({ theme }) => theme.spacing()};
  transform: ${({ turn }: RotatingIconProps) =>
    turn ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: ${({ theme }) =>
    `${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`};
  font-size: inherit;
`;

export {
  GridWrapper,
  BuildingListLoader,
  BuildingListError,
  StyledChip,
  RotatingIcon
};
