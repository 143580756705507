import { FormProvider, useForm } from 'react-hook-form';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import useSelector from '../../../../hooks/useSelector';
import { Etasje } from '../../../../types/registration/userInput';
import {
  updateEtasje,
  updateEtasjer
} from '../../../../store/registration/actions';
import { useFloorTitle } from '../../utils/useFloorTitle';

import { FloorFormData } from './types';
import { Form } from './form';

interface Props {
  floor: Etasje;
  onSave: () => void;
  onCancel: () => void;
}

export const ShapeAndAreaForm: FC<Props> = ({ floor, onSave, onCancel }) => {
  const allFloorsSameShape = useSelector(
    (state) => state.register.allFloorsSameShape
  );
  const { getFloorTitle } = useFloorTitle();
  const dispatch = useDispatch();

  const methods = useForm<FloorFormData>({
    defaultValues: {
      floor,
      sameShapeAllFloors: allFloorsSameShape
    }
  });

  const onFormSubmit = ({ floor, sameShapeAllFloors }: FloorFormData) => {
    if (sameShapeAllFloors) {
      dispatch(updateEtasjer({ etasje: floor }));
    } else {
      dispatch(updateEtasje(floor));
    }

    onSave();
  };

  const floorTitle = getFloorTitle(
    floor.etasjeNummer,
    floor.erKjeller,
    allFloorsSameShape
  );

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={onFormSubmit}
        onCancel={onCancel}
        floorId={floor.id}
        floorTitle={floorTitle}
      />
    </FormProvider>
  );
};
