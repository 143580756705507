import { Radio, RadioGroup } from 'enova-frontend-components';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useProfileFormContext } from '../types';
import { UserRole } from '../../../types/user';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

interface UseRoleProps {
  loading: boolean;
}

export const UserRoleRadioGroup: FC<UseRoleProps> = ({ loading }) => {
  const { control, reset } = useProfileFormContext();
  const { t } = useTranslation();
  const { xsScreen } = useMediaQuery();
  return (
    <Controller
      control={control}
      name="rolle"
      rules={{
        required: t('required') as string
      }}
      render={({ field: { value, onChange, ...rest } }) => (
        <RadioGroup
          {...rest}
          aria-describedby="professional-desc"
          aria-labelledby="professional-label"
          value={value ?? null}
          disabled={loading}
          marginTop
          onChange={(event, value) => {
            // Set all fagområde-checkboxes to false for non-professionals
            if (value === UserRole.PERSON) {
              reset({
                ventilasjonsOgKjoleanlegg: false,
                bygningsekspert: false,
                energiradgiver: false,
                kjelOgVarmeanlegg: false,
                rolle: value
              });
              return;
            }
            onChange(event);
          }}
        >
          <Radio
            label={t('profileScreen.notProfessional')}
            value={UserRole.PERSON}
            width={xsScreen ? 'fullWidth' : 'default'}
          />
          <Radio
            label={t('profileScreen.professional')}
            value={UserRole.PROFESJONELL}
            width={xsScreen ? 'fullWidth' : 'default'}
          />
        </RadioGroup>
      )}
    />
  );
};
