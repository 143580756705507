import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './assets/locales/en.json';
import nb from './assets/locales/nb-NO.json';
import nn from './assets/locales/nn-NO.json';

export enum Language {
  NB_NO = 'nb-NO',
  NN_NO = 'nn-NO',
  EN = 'en'
}
const resources = {
  [Language.NB_NO]: { translation: nb },
  [Language.NN_NO]: { translation: nn },
  [Language.EN]: { translation: en }
};

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  keySeparator: '.',
  lng: window.config.VITE_LANG,
  resources
});

export default i18n;
