import { ReactComponent as BetongIcon } from '../../../../assets/icons/materials/concrete.svg';
import { ReactComponent as BetongInvertedIcon } from '../../../../assets/icons/materials/concrete-inverted.svg';
import { ReactComponent as MurIcon } from '../../../../assets/icons/materials/bricks.svg';
import { ReactComponent as MurInvertedIcon } from '../../../../assets/icons/materials/bricks-inverted.svg';
import { ReactComponent as StalIcon } from '../../../../assets/icons/materials/steel.svg';
import { ReactComponent as StalInvertedIcon } from '../../../../assets/icons/materials/steel-inverted.svg';
import { ReactComponent as TreIcon } from '../../../../assets/icons/materials/wood.svg';
import { ReactComponent as TreInvertedIcon } from '../../../../assets/icons/materials/wood-inverted.svg';
import { RootState } from '../../../../store/rootReducer';
import { getSelectOptionsFromEnum } from '../../../../utils/register';
import { Field, FieldType } from '../../../../types/registration/fieldTypes';
import {
  ByggType,
  Konstruksjonsmateriale,
  PlasseringAvEnhetIByggHorisontalt,
  PlasseringAvEnhetIByggVertikalt,
  TilliggendeRom
} from '../registerEnums';
import { validators } from '../validation';

export const BasicInformation: FieldType[] = [
  {
    attrKey: 'byggType',
    options: [
      {
        disabled: true,
        label: 'register.byggType.option.default',
        value: -1
      },
      ...[
        ByggType.Enebolig,
        ByggType.EneboligMedUtleiedel,
        ByggType.TomannsboligHorisontalDelt,
        ByggType.TomannsboligVertikalDelt,
        ByggType.Firemannsbolig,
        ByggType.KjedetEnebolig,
        ByggType.Rekkehus,
        ByggType.Fritidsbolig,
        ByggType.AnnetSmahus,
        ByggType.Leilighet,
        ByggType.AnnenBolig
      ].map((byggType) => ({
        label: `buildingCategory.${byggType}`,
        value: byggType
      }))
    ],
    path: ['bygningsdetaljer', 'byggType'],
    required: true,
    selector: (state) => state.register.userInput?.bygningsdetaljer?.byggType,
    tErrorKey: 'register.bygningsdetaljer.boligtype.error',
    tLabelKey: 'register.bygningsdetaljer.boligtype.label',
    type: Field.SELECT
  },
  // Display only if ByggType is Leilighet
  {
    attrKey: 'plasseringAvEnhetIByggHorisontalt',
    condition: (state) =>
      state.register?.userInput?.bygningsdetaljer?.byggType ===
      ByggType.Leilighet,
    row: true,
    path: ['bygningsdetaljer', 'plasseringAvEnhetIByggHorisontalt'],
    options: [
      PlasseringAvEnhetIByggHorisontalt.Endeleilighet,
      PlasseringAvEnhetIByggHorisontalt.Hjorneleilighet,
      PlasseringAvEnhetIByggHorisontalt.Midtleilighet,
      PlasseringAvEnhetIByggHorisontalt.FrittstaendeLeilighet
    ].map((plassering) => ({
      tLabelKey: `register.bygningsdetaljer.leilighet.plasseringAvEnhetIByggHorisontalt.option.${plassering}`,
      value: plassering,
      width: 'auto'
    })),
    selector: (state) =>
      state.register.userInput?.bygningsdetaljer
        ?.plasseringAvEnhetIByggHorisontalt,
    tLabelKey:
      'register.bygningsdetaljer.leilighet.plasseringAvEnhetIByggHorisontalt.label',
    type: Field.RADIO
  },
  // Display only if ByggType is Leilighet
  {
    attrKey: 'plasseringAvEnhetIByggVertikalt',
    condition: (state) =>
      state.register?.userInput?.bygningsdetaljer?.byggType ===
      ByggType.Leilighet,
    row: true,
    path: ['bygningsdetaljer', 'plasseringAvEnhetIByggVertikalt'],
    options: [
      PlasseringAvEnhetIByggVertikalt.MellomliggendeEtasje,
      PlasseringAvEnhetIByggVertikalt.NedersteEtasje,
      PlasseringAvEnhetIByggVertikalt.OversteEtasje
    ].map((plassering) => ({
      tLabelKey: `register.bygningsdetaljer.leilighet.plasseringAvEnhetIByggVertikalt.option.${plassering}`,
      value: plassering,
      width: 'auto'
    })),
    selector: (state) =>
      state.register.userInput?.bygningsdetaljer
        ?.plasseringAvEnhetIByggVertikalt,
    tLabelKey:
      'register.bygningsdetaljer.leilighet.plasseringAvEnhetIByggVertikalt.label',
    type: Field.RADIO
  },
  // Display only if ByggType is Firemannsbolig or Horisontaldelt
  {
    attrKey: 'plasseringAvEnhetIByggVertikalt',
    key: 'HorisontaldeltBoligPlasseringVertikalt',
    condition: (state: RootState): boolean => {
      const byggType = state.register?.userInput?.bygningsdetaljer?.byggType;

      return byggType
        ? [
            ByggType.TomannsboligHorisontalDelt,
            ByggType.Firemannsbolig
          ].includes(byggType)
        : false;
    },
    row: true,
    path: ['bygningsdetaljer', 'plasseringAvEnhetIByggVertikalt'],
    options: [
      PlasseringAvEnhetIByggVertikalt.OversteEtasje,
      PlasseringAvEnhetIByggVertikalt.NedersteEtasje
    ].map((plassering) => ({
      tLabelKey: `register.bygningsdetaljer.horisontaldeltBolig.plasseringAvEnhetIByggVertikalt.option.${plassering}`,
      value: plassering,
      width: 'auto'
    })),
    selector: (state) =>
      state.register.userInput?.bygningsdetaljer
        ?.plasseringAvEnhetIByggVertikalt,
    tLabelKey:
      'register.bygningsdetaljer.horisontaldeltBolig.plasseringAvEnhetIByggVertikalt.label',
    type: Field.RADIO
  },
  // Display only if ByggType is Rekkehus
  {
    attrKey: 'plasseringAvEnhetIByggHorisontalt',
    key: 'RekkehusPlasseringHorisontalt',
    condition: (state) =>
      state.register?.userInput?.bygningsdetaljer?.byggType ===
      ByggType.Rekkehus,
    row: true,
    path: ['bygningsdetaljer', 'plasseringAvEnhetIByggHorisontalt'],
    options: [
      PlasseringAvEnhetIByggHorisontalt.Midtrekkehus,
      PlasseringAvEnhetIByggHorisontalt.Enderekkehus
    ].map((plassering) => ({
      tLabelKey: `register.bygningsdetaljer.rekkehus.plasseringAvEnhetIByggHorisontalt.option.${plassering}`,
      value: plassering,
      width: 'auto'
    })),
    selector: (state) =>
      state.register.userInput?.bygningsdetaljer
        ?.plasseringAvEnhetIByggHorisontalt,
    tLabelKey:
      'register.bygningsdetaljer.rekkehus.plasseringAvEnhetIByggHorisontalt.label',
    type: Field.RADIO
  },
  {
    attrKey: 'byggAr',
    path: ['bygningsdetaljer', 'byggAr'],
    required: true,
    selector: (state) => state.register.userInput?.bygningsdetaljer?.byggAr,
    tErrorKey: 'register.bygningsdetaljer.byggear.error',
    tInformationKey: 'register.bygningsdetaljer.byggear.information',
    tLabelKey: 'register.bygningsdetaljer.byggear.label',
    type: Field.YEAR,
    validator: validators.byggAr
  },
  {
    attrKey: 'antallEtasjerUnntattKjeller',
    format: '###',
    integer: true,
    path: ['bygningsdetaljer', 'antallEtasjerUnntattKjeller'],
    required: true,
    selector: (state) =>
      state.register.userInput?.bygningsdetaljer?.antallEtasjerUnntattKjeller,
    tErrorKey: 'register.bygningsdetaljer.antallEtasjerUnntattKjeller.error',
    tInformationKey:
      'register.bygningsdetaljer.antallEtasjerUnntattKjeller.information',
    tLabelKey: 'register.bygningsdetaljer.antallEtasjerUnntattKjeller.label',
    type: Field.INTEGER,
    validator: validators.antallEtasjerUnntattKjeller
  },
  {
    attrKey: 'totalBRA',
    path: ['bygningsdetaljer', 'totalBRA'],
    required: true,
    selector: (state) => state.register.userInput?.bygningsdetaljer?.totalBRA,
    tErrorKey: 'register.bygningsdetaljer.totalBRA.error',
    tInformationKey: 'register.bygningsdetaljer.totalBRA.information',
    tLabelKey: 'register.bygningsdetaljer.totalBRA.label',
    tSuffixKey: 'suffix.m2',
    type: Field.DECIMAL,
    validator: validators.totalBRA
  },
  {
    attrKey: 'totalOppvarmetBRA',
    path: ['bygningsdetaljer', 'totalOppvarmetBRA'],
    required: true,
    selector: (state) =>
      state.register.userInput?.bygningsdetaljer?.totalOppvarmetBRA,
    tErrorKey: 'register.bygningsdetaljer.totalOppvarmetBRA.error',
    tInformationKey: 'register.bygningsdetaljer.totalOppvarmetBRA.information',
    tLabelKey: 'register.bygningsdetaljer.totalOppvarmetBRA.label',
    tSuffixKey: 'suffix.m2',
    type: Field.DECIMAL,
    validator: validators.totalOppvarmetBRA
  },
  // Hide only if the user is to register an apartment that is not located on the first floor
  {
    attrKey: 'harBoligenKjeller',
    condition: (state) => {
      const byggType = state.register?.userInput?.bygningsdetaljer?.byggType;
      const plasseringAvEnhetIByggVertikalt =
        state.register?.userInput?.bygningsdetaljer
          ?.plasseringAvEnhetIByggVertikalt;

      if (
        byggType === ByggType.Leilighet &&
        plasseringAvEnhetIByggVertikalt != null &&
        plasseringAvEnhetIByggVertikalt !==
          PlasseringAvEnhetIByggVertikalt.NedersteEtasje
      ) {
        return false;
      }

      return true;
    },
    row: true,
    path: ['bygningsdetaljer', 'harBoligenKjeller'],
    required: true,
    selector: (state) =>
      state.register.userInput?.bygningsdetaljer?.harBoligenKjeller,
    tErrorKey: 'register.bygningsdetaljer.harBoligenKjeller.error',
    tInformationKey: 'register.bygningsdetaljer.harBoligenKjeller.information',
    tLabelKey: 'register.bygningsdetaljer.harBoligenKjeller.label',
    type: Field.RADIO,
    valueType: 'boolean',
    options: [
      {
        tLabelKey: 'yes',
        value: true,
        width: 'auto'
      },
      {
        tLabelKey: 'no',
        value: false,
        width: 'auto'
      }
    ],
    commonFields: {
      true: [
        {
          attrKey: 'basement',
          valueType: 'boolean',
          path: ['basement'],
          options: [
            {
              tLabelKey: 'yes',
              value: true,
              width: 'auto'
            },
            {
              tLabelKey: 'no',
              value: false,
              width: 'auto'
            }
          ],
          selector: (state) =>
            state.register?.userInput?.bygningsdetaljer?.etasjer?.some(
              (etasje) => etasje.erKjeller
            ),
          tInformationKey:
            'register.bygningsdetaljer.harBoligenKjeller.yes.information',
          tLabelKey: 'register.bygningsdetaljer.harBoligenKjeller.yes.label',
          type: Field.RADIO,
          row: true
        }
      ],
      false: [
        {
          attrKey: 'tilliggendeRomGulvIForsteEtasje',
          path: ['bygningsdetaljer', 'tilliggendeRomGulvIForsteEtasje'],
          options: [
            {
              label: 'register.adjoiningRooms.option.defaultFirstStoryFloor',
              value: -1,
              disabled: true
            },
            ...getSelectOptionsFromEnum(
              Object.entries(TilliggendeRom).reduce(
                (acc, [key, value]) =>
                  value === TilliggendeRom.MotGrunnOgDetFri
                    ? acc
                    : { ...acc, [key]: value },
                {}
              ),
              (label) => `register.adjoiningRooms.option.${label}`
            )
          ],
          selector: (state) =>
            state.register.userInput?.bygningsdetaljer
              ?.tilliggendeRomGulvIForsteEtasje,
          tInformationKey:
            'register.bygningsdetaljer.tilliggendeRomGulvIForsteEtasje.information',
          tLabelKey:
            'register.bygningsdetaljer.tilliggendeRomGulvIForsteEtasje.label',
          type: Field.SELECT
        }
      ]
    }
  },
  {
    attrKey: 'konstruksjonsmateriale',
    options: [
      {
        // eslint-disable-next-line max-len
        tLabelKey: `register.bygningsdetaljer.konstruksjonsmateriale.option.${Konstruksjonsmateriale.Betong}`,
        value: Konstruksjonsmateriale.Betong,
        icon: BetongIcon,
        invertedIcon: BetongInvertedIcon
      },
      {
        tLabelKey: `register.bygningsdetaljer.konstruksjonsmateriale.option.${Konstruksjonsmateriale.Tre}`,
        value: Konstruksjonsmateriale.Tre,
        icon: TreIcon,
        invertedIcon: TreInvertedIcon
      },
      {
        // eslint-disable-next-line max-len
        tLabelKey: `register.bygningsdetaljer.konstruksjonsmateriale.option.${Konstruksjonsmateriale.MurTeglstein}`,
        value: Konstruksjonsmateriale.MurTeglstein,
        icon: MurIcon,
        invertedIcon: MurInvertedIcon
      },
      {
        tLabelKey: `register.bygningsdetaljer.konstruksjonsmateriale.option.${Konstruksjonsmateriale.Stal}`,
        value: Konstruksjonsmateriale.Stal,
        icon: StalIcon,
        invertedIcon: StalInvertedIcon
      }
    ],
    required: true,
    row: true,
    path: ['bygningsdetaljer', 'konstruksjonsmateriale'],
    selector: (state) =>
      state.register.userInput?.bygningsdetaljer?.konstruksjonsmateriale,
    tErrorKey: 'register.bygningsdetaljer.konstruksjonsmateriale.error',
    tDescriptionKey:
      'register.bygningsdetaljer.konstruksjonsmateriale.information',
    tLabelKey: 'register.bygningsdetaljer.konstruksjonsmateriale.label',
    type: Field.RADIOBUTTON
  }
];
