import { Button, Grid, Stack, TextArea } from 'enova-frontend-components';
import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tiltak } from '../../../types/registration/tiltak';
import { EnergiObjektType } from '../../../utils/matrikkelBygningsTyper';
import { LoadingIndicator } from '../../loadingIndicator';

import { EnergiObjektTypeSelect } from './energiObjektTypeSelect';
import { useTiltakFormContext } from './utils';
import { TiltakFormData } from './types';
import { CategoryRadioGroup } from './categoryRadioGroup';
import { TiltakTypeSelect } from './tiltakTypeSelect';
import { useGetTiltakskategorier } from './useGetTiltakskategorier';

interface TiltakFormProps {
  tiltak: Partial<Tiltak>;
  onSubmit: (data: TiltakFormData) => void;
  onCancel?: () => void;
  isLoading: boolean;
  hasTiltak?: boolean;
  energiobjektType: EnergiObjektType;
  allowEnergiobjektTypeOverride?: boolean;
}

export const TiltakForm: FC<TiltakFormProps> = ({
  onSubmit,
  onCancel,
  isLoading,
  hasTiltak,
  energiobjektType,
  allowEnergiobjektTypeOverride
}) => {
  const { t } = useTranslation();
  const [energiobjektTypeOverride, setEnergiobjektTypeOverride] =
    useState<EnergiObjektType>(energiobjektType);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    reset
  } = useTiltakFormContext();

  const { tiltakskategorier, isLoading: isTiltakskategorierLoading } =
    useGetTiltakskategorier(energiobjektTypeOverride);

  const tiltakCategory = watch('category');

  const radioGroupCategories = tiltakskategorier?.map(
    ({ kategori }) => kategori
  );
  const currentTiltakCategory = tiltakskategorier?.find(
    ({ kategori }) => kategori === tiltakCategory
  );

  const onEnergiobjektTypeChange = (energiobjektType: EnergiObjektType) => {
    setEnergiobjektTypeOverride(energiobjektType);
    // Reset entire form on change
    reset();
  };

  if (isTiltakskategorierLoading) {
    return <LoadingIndicator text={t('tiltak.category.loading')} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={4}>
        {allowEnergiobjektTypeOverride && (
          <Grid item>
            <EnergiObjektTypeSelect
              value={energiobjektTypeOverride}
              onChange={onEnergiobjektTypeChange}
              disabled={hasTiltak}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CategoryRadioGroup
            isLoading={isLoading || isTiltakskategorierLoading}
            categories={radioGroupCategories || []}
          />
        </Grid>

        {tiltakCategory && tiltakCategory !== -1 && (
          <Fragment>
            <Grid
              item
              maxWidth={1}
              sm="auto"
              sx={{ maxWidth: '100% !important' }}
              xs={12}
            >
              <TiltakTypeSelect
                isLoading={isLoading || isTiltakskategorierLoading}
                options={currentTiltakCategory?.tiltaksliste || []}
              />
            </Grid>

            <Grid item xs={12}>
              <TextArea
                error={!!errors?.description}
                helperText={errors?.description?.message}
                {...register('description', {
                  validate: (value) =>
                    (value && value != '') || (t('required') as string)
                })}
                fullWidth
                label={t('tiltak.create.description.label')}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <Stack direction="row" spacing={2}>
                <Button
                  disabled={isLoading || isTiltakskategorierLoading}
                  type="submit"
                  loading={isLoading || isTiltakskategorierLoading}
                >
                  {t('tiltak.save')}
                </Button>
                <Button
                  disabled={isLoading || isTiltakskategorierLoading}
                  onClick={onCancel}
                >
                  {t('cancel')}
                </Button>
              </Stack>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </form>
  );
};
