import React, { FC } from 'react';
import {
  Box,
  Button,
  Form,
  TextField,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

interface SmallScreenSearchProps {
  disabled: boolean;
  onSearch: () => void;
  searchString: string | undefined;
  setSearchString: (searchString: string) => void;
}

export const SmallScreenSearch: FC<SmallScreenSearchProps> = ({
  disabled,
  onSearch,
  searchString,
  setSearchString
}) => {
  const { t } = useTranslation();

  return (
    <Box alignSelf="stretch">
      <Typography gutterBottom variant="h3" component="span">
        {t('search.title')}
      </Typography>

      <Typography
        {...{
          htmlFor: 'searchFieldId'
        }}
        component="label"
        display="block"
        gutterBottom
      >
        {t('search.barContent')}
      </Typography>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onSearch();
        }}
      >
        <TextField
          id="searchFieldId"
          value={searchString}
          onChange={({ currentTarget: { value } }) => setSearchString(value)}
          fullWidth
        />

        <Button
          className="mt-2"
          type="submit"
          fullWidth
          rightIcon="search"
          disabled={disabled}
        >
          {t('search.button')}
        </Button>
      </Form>
    </Box>
  );
};
