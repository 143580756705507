import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, useSnack } from 'enova-frontend-components';

import { createErrorMessage, downloadFile } from '../../../utils/utils';

interface Props {
  uri: string;
  isPreview: boolean;
}

export const DownloadCertificateButton: FC<Props> = ({ uri, isPreview }) => {
  const { t } = useTranslation();
  const { addSnack } = useSnack();

  return (
    <Button
      variant="secondary"
      onClick={() =>
        downloadFile({ uri }, () =>
          addSnack(createErrorMessage(t('downloadError')), { variant: 'error' })
        )
      }
    >
      {isPreview
        ? t('documentsForEnovaApplication.download.certificate_preview')
        : t('documentsForEnovaApplication.download.certificate')}
    </Button>
  );
};
