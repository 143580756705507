import { Box, MainContentBox, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { CreateBuildingForm } from './form';
import { CreateBuildingData } from './types';
import { useCreateBuildingDefaultValues } from './utils';

const CreateBuilding: FC = () => {
  const { t } = useTranslation();
  const createBuildingDefaultValues = useCreateBuildingDefaultValues();

  const methods = useForm<CreateBuildingData>({
    defaultValues: {
      ...createBuildingDefaultValues
    }
  });

  return (
    <FormProvider {...methods}>
      <Helmet title={t('building.create.title')} />
      <MainContentBox maxWidth={740}>
        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <Typography variant="h1" mb={3}>
            {t('building.create.title')}
          </Typography>
          <Typography variant="ingress" mb={4}>
            {t('building.create.ingress')}
          </Typography>
          <Typography paragraph mb={4}>
            {t('building.create.paragraph')}
          </Typography>
        </Box>

        <CreateBuildingForm />
      </MainContentBox>
    </FormProvider>
  );
};

export default CreateBuilding;
