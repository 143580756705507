import { useQuery } from 'react-query';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { queryKeys } from '../../../../../../../utils/react-query';
import { getSearchPoststed } from '../../../../../../../services/api';
import { KommuneSearchOptions, useAdvancedSearchFormContext } from '../types';

export const useAddressSearch = (postnummer: string | null) => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();
  const { setValue } = useAdvancedSearchFormContext();

  useQuery(
    [queryKeys.searchPoststed, postnummer],
    () => getSearchPoststed(postnummer),
    {
      enabled: !!postnummer && postnummer?.length === 4,
      onSuccess(data) {
        if (!data) {
          return;
        }

        if (data.adresser.length === 0) {
          // Reset values if the search don't return any result
          setValue('poststed', '');
          setValue('kommuneOptions', []);
          setValue('kommunenummer', '');

          addSnack(
            t('search.advanced.error.postnummerSearchNoResult', {
              postnummer
            }),
            { variant: 'error' }
          );

          return;
        }

        const result = data.adresser?.[0];
        const poststed = result?.poststed;

        const options: KommuneSearchOptions = [
          {
            label: `${result?.kommunenavn} (${result?.kommunenummer})` ?? '',
            value: `${result?.kommunenummer}` ?? ''
          }
        ];

        setValue('kommuneOptions', options);
        setValue('poststed', poststed);
        setValue('kommunenummer', String(result?.kommunenummer));
      },
      onError: () => {
        addSnack(t('search.advanced.error.postnummerSearchError')),
          { variant: 'error' };
      }
    }
  );
};
