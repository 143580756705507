import React, { FC, Fragment } from 'react';
import { Box, FactBox, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import DynamicField from '../../dynamicField';
import useRegistrationForm from '../../../../screens/registration/useRegistrationForm';
import { BasicInformation } from '../../utils/registerConfig';
import { SoknadSteg } from '../../utils/registerEnums';

const General: FC = () => {
  const { t } = useTranslation();
  const { registrationId, soknadSteg } = useRegistrationForm();

  const fieldIsDisabled = (fieldKey?: string) => {
    if (
      soknadSteg &&
      soknadSteg !== SoknadSteg.Steg1 &&
      fieldKey === 'byggAr'
    ) {
      return true;
    }

    if (!!registrationId && fieldKey === 'byggType') {
      return true;
    }

    return false;
  };

  return (
    <Fragment>
      <Box>
        <Typography variant="h2" gutterBottom>
          {t('register.general.aboutBuilding.heading')}
        </Typography>

        <Typography paragraph className="text-preline">
          {t('register.general.aboutBuilding.description')}
        </Typography>
      </Box>

      <FactBox title={t('register.general.basicInformation.title')}>
        <Box display="flex" flexDirection="column">
          {BasicInformation.map((defs, index) => (
            <DynamicField
              {...defs}
              disabled={fieldIsDisabled(defs.attrKey)}
              end={index === BasicInformation.length - 1}
              key={defs.key ?? defs.attrKey}
              marginBottom={index !== BasicInformation.length - 1}
            />
          ))}
        </Box>
      </FactBox>
    </Fragment>
  );
};

export default General;
