import React, { FC, Fragment } from 'react';
import {
  Accordion,
  Box,
  CollapsibleFactBox,
  Grid,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import useSelector from '../../../../../../../hooks/useSelector';
import { getEtasjer } from '../../../../../../../store/registration/selectors';

import FloorsSummaryContent from './content';

const FloorsSummarySection: FC = () => {
  const { t } = useTranslation();

  const etasjer = useSelector(getEtasjer, shallowEqual);

  return etasjer && etasjer.length > 0 ? (
    <Fragment>
      {etasjer.map(({ gulvSoner, erKjeller, etasjeNummer, id }) =>
        gulvSoner && gulvSoner.length > 0 ? (
          <Grid item key={id} xs={12}>
            <CollapsibleFactBox
              title={t('register.floors.sectionTitle', {
                context:
                  etasjer.length === 1
                    ? undefined
                    : erKjeller
                    ? 'basement'
                    : 'floorNo',
                count: etasjeNummer
              })}
            >
              {gulvSoner.length === 1 ? (
                <FloorsSummaryContent
                  etasjeId={id}
                  gulvsoneId={gulvSoner[0].id}
                />
              ) : (
                <Box mx={-4} my={-5}>
                  <Accordion>
                    {gulvSoner.map((gulvsone, gulvsoneIndex) => (
                      <Accordion.Item
                        title={
                          <Typography bold px={1} component="h5">
                            {t('register.floors.gulvsoneId', {
                              count: gulvsoneIndex + 1
                            })}
                          </Typography>
                        }
                        key={gulvsone.id}
                        unmountOnExit={false}
                      >
                        <FloorsSummaryContent
                          etasjeId={id}
                          gulvsoneId={gulvsone.id}
                        />
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Box>
              )}
            </CollapsibleFactBox>
          </Grid>
        ) : null
      )}
    </Fragment>
  ) : null;
};

export default FloorsSummarySection;
