import { FC, Fragment } from 'react';
import { FormMessage, Grid, Link, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { BuildingDetails } from '../../../../../../types/building';
import { SearchMatchLink } from '../searchMatchListItems/searchMatchLink';

interface AdvancedSearchResultsProps {
  error: unknown;
  searchResults: BuildingDetails[] | undefined;
  refetch: () => Promise<unknown>;
}

export const SearchResults: FC<AdvancedSearchResultsProps> = ({
  error,
  refetch,
  searchResults
}) => {
  const { t } = useTranslation();

  if (error) {
    return (
      <Grid xs={12} item>
        <FormMessage fullWidth title={t('search.error.title')}>
          <Typography>{t('search.error.description')}</Typography>

          <Link button color="inherit" onClick={async () => await refetch()}>
            {t('search.error.retry')}
          </Link>
        </FormMessage>
      </Grid>
    );
  }

  if (!searchResults) {
    return null;
  }

  if (searchResults.length === 0) {
    return (
      <Grid xs={12} item display="flex">
        <Typography>{t('search.no_result')}</Typography>
      </Grid>
    );
  }

  return (
    <Fragment>
      <Grid item xs={12} display="flex" flexDirection="column">
        <Typography variant="h4" component="h2" gutterBottom>
          {t('buildingSections')}
        </Typography>
      </Grid>

      {searchResults?.map((item) => (
        <Grid
          container
          display="flex"
          item
          key={JSON.stringify(item)}
          spacing={2}
          xs={12}
        >
          <SearchMatchLink building={item} />
        </Grid>
      ))}
    </Fragment>
  );
};
