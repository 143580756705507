import { Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SoknadStegInfo } from '../../../../../../../../types/soknad';

import { EnergyGradeCell } from './energyGradeCell';

interface SoknadTableRowProps {
  stegInfo?: SoknadStegInfo;
  steg: string;
}

export const SoknadTableRow: FC<SoknadTableRowProps> = ({ stegInfo, steg }) => {
  const { t } = useTranslation();

  const analysis = stegInfo?.analysis;
  const energimerke = analysis?.energiplan?.energimerke;
  const energiforbruk = energimerke?.utdata?.energiforbruk;
  const oppvarmetBruksAreal = analysis?.registrering.oppvarmetBruksAreal;

  const varmetapstall = energiforbruk?.varmetransportkoeff_wk;
  const totaltNettoEnergibehov = energiforbruk?.totalt_netto_energibehov;

  const calculatedNettobehov =
    totaltNettoEnergibehov &&
    oppvarmetBruksAreal &&
    totaltNettoEnergibehov / oppvarmetBruksAreal;

  const calculatedVarmetapstall =
    varmetapstall && oppvarmetBruksAreal && varmetapstall / oppvarmetBruksAreal;

  return (
    <tr key={steg}>
      <td>
        <Typography bold variant="body2">
          {t('documentsForEnovaApplication.report.title', {
            context: steg
          })}
        </Typography>
      </td>
      <td>
        {calculatedVarmetapstall && (
          <span title={String(calculatedVarmetapstall)}>
            {calculatedVarmetapstall.toFixed(2)}
          </span>
        )}
      </td>
      <td>
        {calculatedNettobehov && (
          <span title={String(calculatedNettobehov)}>
            {calculatedNettobehov.toFixed(2)}
          </span>
        )}
      </td>
      <td>
        <EnergyGradeCell energimerke={energimerke} />
      </td>
    </tr>
  );
};
