import { useFormContext } from 'react-hook-form';

import { SearchValues } from '../../../../../../../../types/building';
import { KommuneSearchOptions } from '../../../../../../buildings/components/searchView/advancedSearch/types';

export type BuildingSearchFormData = Pick<
  SearchValues,
  | 'kommunenummer'
  | 'kommunenavn'
  | 'gardsnummer'
  | 'bruksnummer'
  | 'seksjonsnummer'
  | 'bruksenhetsnummer'
  | 'festenummer'
  | 'gatenavn'
  | 'bokstav'
  | 'nummer'
  | 'bygningsnummer'
> & {
  kommuneOptions: KommuneSearchOptions;
};

export const useBuildingSearchFormContext = () => {
  return useFormContext<BuildingSearchFormData>();
};
