import { FC, useEffect } from 'react';

import { useAuthentication } from '../../hooks/useAuthentication';

const DirectLogin: FC = () => {
  const { signIn, isAuthenticated } = useAuthentication();

  useEffect(() => {
    if (!isAuthenticated) {
      signIn();
    }
  }, [isAuthenticated]);

  return null;
};

export default DirectLogin;
