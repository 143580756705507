import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Divider, Typography } from 'enova-frontend-components';

import { useBuildingData } from '../../../../hooks/useBuildingDetails/buildingDetailsHooks';
import { BuildingId } from '../../../../utils/navigation';
import { LoadingIndicator } from '../../../../components/loadingIndicator';
import { GetEnergiObjektTypeFromMatrikkel } from '../../../../utils/matrikkelBygningsTyper';

import { NoValidCertificate } from './components/noValidCertificate';
import { EnergyCertificateWrapper } from './energyCertificateWrapper';
import { PreviousEnergyPlanList } from './components/previousEnergyPlanList';
import { EnergyCertificateInfoBox } from './components/energyCertificate/components';

export const EnergyCertificateTabContent: FC = () => {
  const { t } = useTranslation();
  const { buildingId } = useParams<BuildingId>();
  const { building, buildingLoading } = useBuildingData(buildingId);

  if (buildingLoading) {
    return <LoadingIndicator text={t('loadingContent')} />;
  }

  if (!building) {
    return null;
  }

  const attester = building.gyldigeEnergiattester || [];

  if (attester.length === 0) {
    return <NoValidCertificate building={building} />;
  }

  const energiObjektType = GetEnergiObjektTypeFromMatrikkel(
    building.kanEnergimerkesAvPrivatPerson
  );

  return (
    <Box mt={4}>
      <EnergyCertificateInfoBox />
      <Typography gutterBottom variant="h2">
        {t('buildingScreen.tab.energiattest.buildingCertificate', {
          context: energiObjektType
        })}
      </Typography>

      {attester.map((attest, index) => (
        <Box
          key={attest.registreringId}
          display="flex"
          flexDirection="column"
          gap={5}
        >
          {index !== 0 && <Divider />}
          <Box my={5}>
            <EnergyCertificateWrapper
              attest={attest}
              address={building.adresse}
              isOwner={building.gyldigSoknad?.isOwner}
            />
          </Box>
        </Box>
      ))}
      <Box mt={10}>
        <PreviousEnergyPlanList building={building} />
      </Box>
    </Box>
  );
};
