import { useMutation } from 'react-query';

import { queryClient, queryKeys } from '../../../utils/react-query';
import { updateTiltak } from '../../../services/api';
import { Tiltak } from '../../../types/registration/tiltak';

export const useUpdatePortefoljeTiltak = (portefoljeId: string) => {
  const { mutate, isLoading } = useMutation(
    (tiltak: Tiltak) => updateTiltak(tiltak),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          queryKeys.portfolioMeasures,
          portefoljeId
        ])
    }
  );

  return { mutate, isLoading };
};
