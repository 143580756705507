import { Button, Dialog, useSnack } from 'enova-frontend-components';
import React, { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { deletePortefolje } from '../../../services/api';
import { Portefolje } from '../types';
import { queryKeys } from '../../../utils/react-query';
import { createErrorMessage } from '../../../utils/utils';

interface Props {
  portefolje: Portefolje;
}

export const DeleteWorklistButton: FC<Props> = ({ portefolje }) => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [showDialog, setShowDialog] = useState(false);

  const { mutate, isLoading } = useMutation(
    (portefoljeId: string) => deletePortefolje(portefoljeId),
    {
      onSuccess: () => queryClient.invalidateQueries([queryKeys.portfolioList])
    }
  );

  const deleteWorklistMutation = () =>
    mutate(portefolje.portefoljeId, {
      onError: () => {
        addSnack(
          createErrorMessage(
            t('expertToolsXMLPortfolio.worklists.removeWorklist.error')
          ),
          { variant: 'error' }
        );
      },
      onSettled: () => {
        setShowDialog(false);
      }
    });

  return (
    <React.Fragment>
      <Button onClick={() => setShowDialog(true)} variant="secondary">
        {t('expertToolsXMLPortfolio.worklists.table.removeWorklist')}
      </Button>

      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <Dialog.Title>
          {t('expertToolsXMLPortfolio.worklists.removeWorklist.title')}
        </Dialog.Title>

        <Dialog.Content>
          {t('expertToolsXMLPortfolio.worklists.removeWorklist.description', {
            name: portefolje.navn
          })}
        </Dialog.Content>

        <Dialog.Actions>
          <Button
            variant="secondary"
            disabled={isLoading}
            onClick={() => setShowDialog(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            disabled={isLoading}
            loading={isLoading}
            onClick={deleteWorklistMutation}
          >
            {t('confirm')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </React.Fragment>
  );
};
