import { shallowEqual } from 'react-redux';

import { DetailingStep } from '../../components/registering/utils/registerEnums';
import { getInvalidFields } from '../../components/registering/utils/validation';
import useSelector from '../../hooks/useSelector';
import {
  RegistrationCommonStep,
  RegistrationStep,
  withAnchor
} from '../../utils/navigation';

export const RegistrationSteps = {
  ...Object.entries(RegistrationCommonStep).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value }),
    {}
  ),
  ...Object.entries(DetailingStep).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value }),
    {}
  )
};

export const StepperId = 'stepper';

export const withStepperId = (path: string): string =>
  withAnchor(path, StepperId);

export const useErrors = (step: RegistrationStep) => {
  const errors = useSelector(
    (state) => getInvalidFields(state, step),
    shallowEqual
  );

  return { errors };
};
