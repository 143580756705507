import React, { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import BuildingHeaderInfo from '../../../../../components/buildingHeaderInfo';
import { XMLParams } from '../types';
import { XmlRegistrationHeader } from '../common/components/xmlRegistrationHeader';
import { SimpleEnergyAnalysis } from '../../../../../types/building';

interface SearchParams {
  energiobjektId: string;
}

interface Props {
  energianalyse?: SimpleEnergyAnalysis;
}

export const EnhetRegistrationHeader: FC<Props> = ({ energianalyse }) => {
  const location = useLocation<SearchParams>();
  const { step } = useParams<XMLParams>();

  const isSelectBuildingStep = step === 'velg-bygg';

  const energiobjektId =
    location.state?.energiobjektId ||
    energianalyse?.registrering.energiObjektId;

  const displayAddress = !isSelectBuildingStep && !!energiobjektId;

  return (
    <XmlRegistrationHeader
      headerInfo={
        displayAddress && (
          <BuildingHeaderInfo
            energiobjektId={energiobjektId}
            BruksEnhetsNummerProps={{ variant: 'h4' }}
            AddressProps={{ variant: 'h3' }}
          />
        )
      }
    />
  );
};
