import {
  VeggKonstruksjon,
  Veggsone
} from '../../../../../types/registration/userInput';
import { WallDetailingOption } from '../types';
import { TilliggendeRom } from '../../../utils/registerEnums';
import useSelector from '../../../../../hooks/useSelector';

//TODO: Check if this should be moved to utils
export const getDetailingValue = (
  veggKonstruksjon: VeggKonstruksjon | undefined
): WallDetailingOption | -1 => {
  if (veggKonstruksjon?.uendretSidenOppforing) {
    return 'unchanged';
  }
  if (veggKonstruksjon?.rehabiliteringEllerPabyggingsAr) {
    return 'yearOfRehabilitation';
  }

  if (veggKonstruksjon?.veggType) {
    return 'construction';
  }

  if (veggKonstruksjon?.brukStandardVerdier) {
    return 'unknown';
  }

  return -1;
};

export const useWallZoneDefaultValues = (wallZone?: Partial<Veggsone>) => {
  const allWallsAgainstTheOpen = useSelector(
    (state) => state.register.allWallsAgainstTheOpen
  );

  const adjoiningRoom = allWallsAgainstTheOpen
    ? TilliggendeRom.MotDetFri
    : wallZone?.tilliggendeRom;

  return {
    wallZoneData: {
      wallZone: {
        id: wallZone?.id,
        lengdeIMeter: wallZone?.lengdeIMeter ?? 0,
        tilliggendeRom: adjoiningRoom,
        veggKonstruksjon: wallZone?.veggKonstruksjon
      },
      detailing: getDetailingValue(wallZone?.veggKonstruksjon)
    }
  };
};
