import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { SmallTypography } from '../../../../../utils';
import { GetTypeFromMatrikkel } from '../../../../../../../../utils/matrikkelBygningsTyper';
import { BuildingDetails } from '../../../../../../../../types/building';
import { useGetBuildingAddress } from '../../../../../../../../hooks/useGetBuildingAddress';
import useBuildingIdentifier from '../../../../../../../../hooks/useBuildingIdentifier';

interface Props {
  building: BuildingDetails;
}

export const BuildingInfo: FC<Props> = ({ building }) => {
  const { getBuildingAddress } = useGetBuildingAddress();
  const getBuildingIdentifier = useBuildingIdentifier();
  const { t } = useTranslation();

  const {
    adresse,
    bygningsNummer,
    bygningsTypeNummer,
    gardsNummer,
    festeNummer,
    seksjonsNummer,
    bruksNummer,
    bruksEnhetsNummer,
    isHovedbygg
  } = building;

  return (
    <Fragment>
      <SmallTypography variant="h4" component="p">
        {getBuildingAddress(adresse)}
      </SmallTypography>

      <SmallTypography>
        {`${GetTypeFromMatrikkel(bygningsTypeNummer)}`}
        {!isHovedbygg && ` - ${t('building.tilbygg')}`}
      </SmallTypography>

      {bruksEnhetsNummer && (
        <SmallTypography>
          {t('building.bruksenhetsnummer', { bruksEnhetsNummer })}
        </SmallTypography>
      )}

      <SmallTypography>
        {getBuildingIdentifier({
          bnr: bruksNummer,
          fnr: festeNummer,
          gnr: gardsNummer,
          knr: adresse.kommuneNummer,
          snr: seksjonsNummer
        })}
      </SmallTypography>

      <SmallTypography>
        {t('building.identifier', {
          context: 'byggnr',
          value: bygningsNummer
        })}
      </SmallTypography>
    </Fragment>
  );
};
