import Ajv, { DefinedError, ValidateFunction } from 'ajv';

import { XMLReport } from '../../types/expertToolsXML';

import registrationSchema from './registrationSchema.json';
import portfolioSchema from './portfolioSchema.json';

// Validation is performed by [Ajv JSON schema validator](https://ajv.js.org/).
const ajv = new Ajv({ allowUnionTypes: true, allErrors: true });
const validatePortfolio = ajv.compile(portfolioSchema);
const validateRegistration = ajv.compile(registrationSchema);

export declare type XMLValidationError = DefinedError;

/**
 * Schema validation error
 *
 * See [AJV api reference](https://ajv.js.org/api.html#validation-errors) for an overview
 * of which errors exist. For expertToolsXML the most relevant error types are "required",
 * "additionalProperties", and "type".
 */
export class XMLReportError extends Error {
  readonly errors: XMLValidationError[];
  readonly fileName: string | null;

  constructor(errors: XMLValidationError[], fileName: string | null = null) {
    super(
      `Validation errors during XMLReport validation${
        fileName ? ` of ${fileName}` : ''
      }: ${errors.map((e) => `${e.instancePath}: ${e.message}`).join(', ')}`
    );
    this.errors = errors;
    this.fileName = fileName;
  }
}

const isXMLReport = (
  obj: unknown,
  silentErrors: boolean,
  validate: ValidateFunction,
  file?: File
): obj is XMLReport => {
  if (!obj) return false;
  if (validate(obj)) return true;

  if (silentErrors) {
    file
      ? console.error(`${file.name}: Type guard error`, validate.errors)
      : console.error('Type guard error', validate.errors);
    return false;
  }
  throw new XMLReportError(validate.errors as XMLValidationError[], file?.name);
};

/**
 * Verify that `obj` is a valid registration {@link XMLReport}.
 *
 * If validation fails unexpectedly, check the console to find out why, and update JSON schema accordingly to allow more
 * types on the attribute in question, make attributes nullable, etc.
 *
 * @param obj The object to be validated
 * @param silentErrors If false, validation errors are thrown as a {@link XMLReportError} rather than logged to console.
 * @returns A {@link Boolean} value indicating whether the object is of type {@link XMLReport}
 * @throws {XMLReportError} if `silentErrors` is false, and a validation error occurs
 */
export const isXMLRegistration = (
  obj: unknown,
  silentErrors = true
): obj is XMLReport => {
  return isXMLReport(obj, silentErrors, validateRegistration);
};

/**
 * Verify that `obj` is a valid portfolio {@link XMLReport}.
 *
 * If validation fails unexpectedly, check the console to find out why, and update JSON schema accordingly to allow more
 * types on the attribute in question, make attributes nullable, etc.
 *
 * @param obj The object to be validated
 * @param file The file containing the object
 * @param silentErrors If false, validation errors are thrown as a {@link XMLReportError} rather than logged to console.
 * @returns A {@link Boolean} value indicating whether the object is of type {@link XMLReport}
 * @throws {XMLReportError} if `silentErrors` is false, and a validation error occurs
 */
export const isXMLPortfolio = (
  obj: unknown,
  file?: File,
  silentErrors = true
): obj is XMLReport => {
  return isXMLReport(obj, silentErrors, validatePortfolio, file);
};
