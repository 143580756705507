import { FC } from 'react';

import { LoadingIndicator } from '../../loadingIndicator';
import TiltakSection from '../tiltakSection';

import { useGetTiltakGrupperegistrering } from './useGetGrupperegistreringTiltak';
import { useAddGrupperegistreringTiltak } from './useAddGrupperegistreringTiltak';
import { useUpdateGrupperegistreringTiltak } from './useUpdateGrupperegistreringTiltak';
import { useDeleteGrupperegistreringTiltak } from './useDeleteGrupperegistreringTiltak';

interface Props {
  grupperegistreringId: string;
}

export const GrupperegistreringTiltakSection: FC<Props> = ({
  grupperegistreringId
}) => {
  const { isLoading: isGetMeasuresLoading, tiltakList } =
    useGetTiltakGrupperegistrering(grupperegistreringId);

  const addTiltakMutation =
    useAddGrupperegistreringTiltak(grupperegistreringId);
  const updateTiltakMutation =
    useUpdateGrupperegistreringTiltak(grupperegistreringId);
  const deleteTiltakMutation =
    useDeleteGrupperegistreringTiltak(grupperegistreringId);

  if (isGetMeasuresLoading) {
    return <LoadingIndicator />;
  }

  return (
    <TiltakSection
      tiltakList={tiltakList}
      deleteTiltakMutation={deleteTiltakMutation}
      addTiltakMutation={addTiltakMutation}
      updateTiltakMutation={updateTiltakMutation}
      allowEnergiobjektTypeOverride
    />
  );
};
