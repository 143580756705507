import React, { FC } from 'react';
import { Button, Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { useXmlStepNavigation } from '../hooks/useXmlStepNavigation';
import { RegistrationTypeProps } from '../../types';
import { NavigationWrapper } from '../components/styledComponents';

interface Props {
  children: React.ReactNode;
}

const Measures: FC<RegistrationTypeProps & Props> = ({
  children,
  registrationType
}) => {
  const { t } = useTranslation();
  const { navigateToStep } = useXmlStepNavigation();

  return (
    <Grid item xs={12} container spacing={4} direction="column">
      <Grid item>
        <Typography variant="h2">
          {t('expertToolsXML.measures.heading')}
        </Typography>
      </Grid>

      {children}

      <NavigationWrapper>
        <Button
          variant="secondary"
          onClick={() =>
            navigateToStep('oppsummering-av-fil', registrationType)
          }
        >
          {t('expertToolsXML.actions.previous')}
        </Button>
        <Button
          onClick={() => navigateToStep('oppsummering', registrationType)}
        >
          {t('expertToolsXML.actions.next_oppsummering')}
        </Button>
      </NavigationWrapper>
    </Grid>
  );
};

export default Measures;
