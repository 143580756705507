import { useMutation, useQueryClient } from 'react-query';

import { Malform } from '../components/registering/utils/registerEnums';
import * as api from '../services/api';
import { queryKeys } from '../utils/react-query';

export const useUpdateRegistrationLanguage = (
  registrationId: string | undefined,
  buildingId: string
) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    (language: Malform) =>
      api.updateRegistreringLanguage(registrationId, language),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          queryKeys.energyAnalysisList,
          buildingId
        ])
    }
  );

  return { updateRegistreringLanguage: mutate };
};
