import React, { FC, useMemo } from 'react';
import { Select } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { TilliggendeRom } from '../../../../../utils/registerEnums';
import { getSelectOptionsFromEnum } from '../../../../../../../utils/register';
import { WallZoneFormFieldProps } from '../utils';
import { useWallFormContext } from '../../../utils';

export const AdjoiningRoomSelect: FC<WallZoneFormFieldProps> = ({
  control
}) => {
  const { t } = useTranslation();
  const { setValue } = useWallFormContext();

  const adjoiningRoomOptions = useMemo(
    () => [
      {
        label: t('register.adjoiningRooms.option.default_wall'),
        value: -1,
        disabled: true
      },
      ...getSelectOptionsFromEnum(
        Object.entries(TilliggendeRom).reduce((acc, [key, enumValue]) => {
          return { ...acc, [key]: enumValue };
        }, {}),
        (label) =>
          t(`register.adjoiningRooms.option.${label}`, { context: 'wall' })
      )
    ],
    []
  );

  return (
    <Controller
      control={control}
      rules={{ validate: (value) => !!value || t('required') }}
      name="wallZoneData.wallZone.tilliggendeRom"
      render={({ field: { value, onChange, ...rest }, fieldState }) => {
        return (
          <Select
            {...rest}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            required
            value={value ?? -1}
            onChange={(e) => {
              const newValue = e.currentTarget.value as TilliggendeRom;
              onChange(newValue);

              if (newValue === TilliggendeRom.OppvarmetRom) {
                setValue('wallZoneData.wallZone.veggKonstruksjon', undefined);
              }
            }}
            label={t('register.wallZone.adjoiningRooms.label')}
            fullWidth
            options={adjoiningRoomOptions}
          />
        );
      }}
    />
  );
};
