import React, { FC } from 'react';
import { Button, Stack, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getBuildingIdentifier } from '../../../../../utils/expertToolsXML/helpers';
import SimpleTable from '../../../components/simpleTable';
import { getBuildingScreenPath } from '../../../../../utils/navigation';
import { Tiltak } from '../../../../../types/registration/tiltak';

import { ParsedContent } from './models';
import { FileListTableHeader } from './fileListTableHeader';

interface FileListProps {
  data: ParsedContent[];
  tableCaptionId: string;
  setRegistrationToDelete: (registration: {
    registreringId: string;
    BruksenhetsIdentId: string | number | undefined;
  }) => void;
  tiltakList: Tiltak[] | undefined;
}

export const FileList: FC<FileListProps> = ({
  data,
  tableCaptionId,
  setRegistrationToDelete,
  tiltakList
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  if (data.length === 0) {
    return (
      <Typography>{t('expertToolsXMLPortfolio.worklist.empty')}</Typography>
    );
  }

  return (
    <React.Fragment>
      <SimpleTable aria-labelledby={tableCaptionId}>
        <FileListTableHeader />

        <tbody>
          {data.map((parsedRegistration) => {
            const { registreringId, energiObjektId, inputJson } =
              parsedRegistration;
            const address = inputJson.Enheter.Enhet.Adresser.BygningsAdresse;
            const BruksenhetsIdentId = address.MatrikkelInfo.BruksenhetsIdentId;
            const Gateadresse = address.AdresseInfo?.Gateadresse;

            return (
              <tr key={registreringId}>
                <td>
                  <Typography>{BruksenhetsIdentId}</Typography>
                </td>

                <td>
                  {inputJson && (
                    <Typography>{getBuildingIdentifier(inputJson)}</Typography>
                  )}
                </td>

                <td>
                  <Typography>{Gateadresse}</Typography>
                </td>

                {(!tiltakList || tiltakList.length === 0) && (
                  <td className="actions">
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="secondary"
                        onClick={() =>
                          push(
                            getBuildingScreenPath({
                              buildingId: energiObjektId
                            })
                          )
                        }
                      >
                        {t('expertToolsXMLPortfolio.worklist.show')}
                      </Button>

                      <Button
                        variant="secondary"
                        onClick={() =>
                          setRegistrationToDelete({
                            registreringId,
                            BruksenhetsIdentId
                          })
                        }
                      >
                        {t('expertToolsXMLPortfolio.worklist.removeFile')}
                      </Button>
                    </Stack>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </SimpleTable>
    </React.Fragment>
  );
};
