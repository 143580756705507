import { Box, NumberField, Typography } from 'enova-frontend-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { useFloorFormContext } from '../../utils';
import {
  getAffectedWalls,
  getCalculatedLength,
  lengthDisabled
} from '../../shapeDirectionUtils';
import { VeggId } from '../../../../utils/registerEnums';

import { highlightWall, removeHighlight } from './utils';

export const WallLengths = () => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFloorFormContext();

  const selectedShape = watch('floor.bygningsForm');
  const walls = watch('floor.vegger');

  // Update disabled wall length fields that can be derived from other walls
  const updateAffectedWalls = (wallId: VeggId) => {
    if (!walls || !selectedShape) {
      return;
    }
    const affectedWalls = getAffectedWalls(selectedShape, wallId);

    affectedWalls.forEach((affectedWall) => {
      const indexToUpdate = walls.findIndex((d) => d.veggId === affectedWall);

      setValue(
        `floor.vegger.${indexToUpdate}.lengdeIMeter`,
        getCalculatedLength(selectedShape, affectedWall, walls)
      );
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={4} mb={5}>
      <Typography variant="h3">
        {t('register.buildingShape.wallLengths.title')}
      </Typography>
      <Typography>
        {t('register.buildingShape.wallLengths.description')}
      </Typography>

      <Box display="flex" flexDirection="row" gap={2} flexWrap="wrap">
        {walls?.map(({ veggId }, index) => {
          const disabledWall = lengthDisabled(selectedShape, veggId);

          return (
            <Controller
              key={index}
              control={control}
              rules={{
                validate: (value) => (value && value > 0) || t('required')
              }}
              name={`floor.vegger.${index}.lengdeIMeter`}
              render={({ field: { value, onChange, ...rest }, fieldState }) => (
                <NumberField
                  {...rest}
                  key={veggId}
                  error={!!fieldState.error}
                  disabled={disabledWall}
                  helperText={!disabledWall && fieldState.error?.message}
                  allowNegative
                  autoComplete
                  label={t('wall', { veggId: veggId.toUpperCase() })}
                  aria-label={t('wall', { veggId: veggId.toUpperCase() })}
                  name="lengde-i-meter"
                  suffix={t('suffix.m')}
                  value={value ?? null}
                  onFocus={() => highlightWall(veggId)}
                  onBlur={() => removeHighlight(veggId)}
                  onValueChange={({ floatValue }) => {
                    onChange(floatValue || null);
                    updateAffectedWalls(veggId);
                  }}
                />
              )}
            />
          );
        })}
      </Box>
    </Box>
  );
};
