import React from 'react';
import { Box, FactBox, styled, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import useSelector from '../../../../hooks/useSelector';
import { getEtasjer } from '../../../../store/registration/selectors';

import { SameShapeAndAreaRadio } from './components/sameShapeAndAreaRadio';
import { TerrainShielding } from './components/terrainShielding';
import { FloorDetailing } from './components/floorDetailing';

const Wrapper = styled(Box)`
  background-color: #eef9f5;
  padding: 20px;
`;

export const ShapeAndArea = () => {
  const etasjer = useSelector(getEtasjer, shallowEqual);

  const { t } = useTranslation();
  const allFloorsSameShape = useSelector(
    (state) => state.register.allFloorsSameShape
  );

  const floors = allFloorsSameShape && etasjer[0] ? [etasjer[0]] : etasjer;

  return (
    <Box>
      <Box>
        <Box my={5} display="flex" flexDirection="column" gap={3}>
          <Typography variant="h3">
            {t('register.buildingShape.title')}
          </Typography>
          <Typography> {t('register.buildingShape.description')}</Typography>
          <SameShapeAndAreaRadio isSameShape={allFloorsSameShape} />
        </Box>

        <FactBox dense title={t('register.buildingShape.floor.title')}>
          <Wrapper>
            {floors.map((floor) => (
              <FloorDetailing
                key={floor.id}
                floor={floor}
                allFloorsSameShape={allFloorsSameShape}
              />
            ))}
          </Wrapper>
        </FactBox>
      </Box>

      <Box my={5}>
        <FactBox title={t('register.terrainShielding.title')}>
          <TerrainShielding />
        </FactBox>
      </Box>
    </Box>
  );
};
