import { Box, Checkbox, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateBuildingFormContext } from '../utils';

interface TermsProps {
  disabled: boolean;
}

export const Terms: FC<TermsProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useCreateBuildingFormContext();

  return (
    <Box display="flex" alignItems="flex-start" flexDirection="column" mt={4}>
      <Typography variant="h4" bold>
        {t('building.create.terms.title')}
      </Typography>
      <Typography variant="body" mb={2}>
        {t('building.create.terms')}
      </Typography>
      <Controller
        control={control}
        name="vilkar"
        rules={{
          required: true
        }}
        render={({ field }) => (
          <Checkbox
            {...field}
            checked={!!field.value}
            disabled={disabled}
            width="fullWidth"
            label={t('building.create.terms.agree')}
            error={!!errors.vilkar}
            helperText={errors.vilkar?.message}
          />
        )}
      />
    </Box>
  );
};
