import React, { FC, Fragment, useState } from 'react';
import { Button, Grid, Stack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { Tiltak } from '../../types/registration/tiltak';
import useBuildingDetails from '../../hooks/useBuildingDetails';
import { GetEnergiObjektTypeFromMatrikkel } from '../../utils/matrikkelBygningsTyper';

import { DeleteTiltakDialog } from './deleteTiltakDialog';
import { UpdateTiltakForm } from './updateTiltakForm';
import { TiltakView } from './tiltakView';
import { TiltakMutation } from './types';

type ExistingTiltakProps = {
  tiltak: Tiltak;
  tiltakIndex: number;
  deleteTiltakMutation: TiltakMutation;
  updateTiltakMutation: TiltakMutation;
};

const ExistingTiltak: FC<ExistingTiltakProps> = ({
  tiltak,
  tiltakIndex,
  updateTiltakMutation,
  deleteTiltakMutation
}) => {
  const { t } = useTranslation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editTiltak, setEditTiltak] = useState<boolean>();

  const { isLoading: isUpdateLoading, mutate: updateTiltak } =
    updateTiltakMutation;

  const onUpdateTiltak = (updatedTiltak: Tiltak) =>
    updateTiltak(updatedTiltak, { onSuccess: () => setEditTiltak(false) });

  const { building } = useBuildingDetails();
  const energiobjektType = GetEnergiObjektTypeFromMatrikkel(
    building.kanEnergimerkesAvPrivatPerson
  );

  return (
    <Fragment>
      <Grid item>
        {editTiltak && (
          <UpdateTiltakForm
            tiltak={tiltak}
            onUpdateTiltak={onUpdateTiltak}
            isLoading={isUpdateLoading}
            onCancel={() => setEditTiltak(false)}
            energiobjektType={energiobjektType}
          />
        )}

        {!editTiltak && (
          <Fragment>
            <TiltakView tiltak={tiltak} tiltakIndex={tiltakIndex} />
            <Stack spacing={2} direction="row" mt={2}>
              <Button
                variant="secondary"
                onClick={() => setDeleteDialogOpen(true)}
              >
                {t('tiltak.delete')}
              </Button>
              <Button onClick={() => setEditTiltak(true)}>
                {t('tiltak.edit')}
              </Button>
            </Stack>
          </Fragment>
        )}
      </Grid>

      <DeleteTiltakDialog
        isOpen={deleteDialogOpen}
        deleteTiltakMutation={deleteTiltakMutation}
        onClose={() => setDeleteDialogOpen(false)}
        tiltak={tiltak}
      />
    </Fragment>
  );
};

export default ExistingTiltak;
