import { Box, Grid, Menu, styled, Typography } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useBuildingIdentifier from '../../../../../../../../hooks/useBuildingIdentifier';
import { BuildingDetails } from '../../../../../../../../types/building';
import {
  GetEnergiObjektTypeFromMatrikkel,
  GetTypeFromMatrikkel
} from '../../../../../../../../utils/matrikkelBygningsTyper';
import { useGetBuildingAddress } from '../../../../../../../../hooks/useGetBuildingAddress';
import { StyledMenuButton } from '../../../../../../buildings/utils';
import { getBuildingScreenPath } from '../../../../../../../../utils/navigation';
import { useXmlRegistrationSearchParams } from '../../../hooks/useXmlRegistrationSearchParams';

import { SearchResultItemWrapper } from './components/searchResultItemWrapper';

const StyledCheckbox = styled(Box)`
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid darkgrey;
`;

interface Props {
  building: BuildingDetails;
  onSelect: (building: BuildingDetails) => void;
}

export const SearchResultItem: FC<Props> = ({ building, onSelect }) => {
  const getBuildingIdentifier = useBuildingIdentifier();
  const { t } = useTranslation();
  const { selectedBuildingIds } = useXmlRegistrationSearchParams();

  const { formatAddressText } = useGetBuildingAddress();

  const isSelected = selectedBuildingIds?.includes(building.bruksenhetsIdentId);

  const getAddressText = (building: BuildingDetails) => {
    const addressText = building.adresse.gateNavn
      ? formatAddressText(building.adresse)
      : t('buildingAddress.missing');

    if (building.bruksEnhetsNummer) {
      return `${addressText}-${building.bruksEnhetsNummer}`;
    }

    return addressText;
  };

  const energiObjektType = GetEnergiObjektTypeFromMatrikkel(
    building.kanEnergimerkesAvPrivatPerson
  );

  const buildingUrl = getBuildingScreenPath({
    buildingId: building.energiObjektId
  });

  // Manually created buildings' bruksenhetsIdentId is a negative number
  const isManuallyCreated = parseInt(building.bruksenhetsIdentId) < 0;

  return (
    <Grid item xs={12} display="flex" flexDirection="row" gap={2}>
      <SearchResultItemWrapper
        flexGrow={1}
        isSelected={isSelected}
        onClick={() => onSelect(building)}
        isManuallyCreated={isManuallyCreated}
      >
        <StyledCheckbox>
          <input
            readOnly
            type="checkbox"
            checked={isSelected}
            style={{ accentColor: 'black' }}
          />
        </StyledCheckbox>

        <Box p={3} display="flex" flexDirection="column" gap={1} width="100%">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4">{getAddressText(building)}</Typography>
            {isManuallyCreated && (
              <Typography variant="body2">
                {t('building.manuallyCreated')}
              </Typography>
            )}
          </Box>
          <Typography>
            {GetTypeFromMatrikkel(building.bygningsTypeNummer)}
          </Typography>
          <Typography bold>
            {getBuildingIdentifier({
              knr: building.adresse.kommuneNummer,
              gnr: building.gardsNummer,
              snr: building.seksjonsNummer
            })}
          </Typography>
          {!isManuallyCreated && (
            <Typography>
              {`${t('building.bruksenhetsIdentId')}:
              ${building.bruksenhetsIdentId}`}
            </Typography>
          )}
          <Typography>
            {`${t('expertToolsXML.review.adresseInfo.bygningsNr')}: ${
              building.bygningsNummer
            }`}
          </Typography>
        </Box>
      </SearchResultItemWrapper>
      <SearchResultItemWrapper>
        <Menu>
          <StyledMenuButton aria-label={`${t('options')}`}>
            {t('options')}
          </StyledMenuButton>
          <Menu.Content>
            <Menu.Item onClick={() => window.open(buildingUrl, '_blank')}>
              {t('showBuilding.newTab', { context: energiObjektType })}
            </Menu.Item>
          </Menu.Content>
        </Menu>
      </SearchResultItemWrapper>
    </Grid>
  );
};
