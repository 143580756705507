import { Box, Button, FullScreenLoader } from 'enova-frontend-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormWatch } from 'react-hook-form';

import { PreviousStepButton } from '../../../../screens/registration/components/registrationActionButtons/buttons';
import useRegistrationForm from '../../../../screens/registration/useRegistrationForm';

import { useUpdateReduxStore } from './useUpdateReduxStore';
import { State } from './types';

interface SubmitButtonsProps {
  watch: UseFormWatch<State>;
}

export const SubmitButtons: FC<SubmitButtonsProps> = ({ watch }) => {
  const { t } = useTranslation();
  const { nextStep, prevStep, loadingStep, goToPrevStep } =
    useRegistrationForm();

  useUpdateReduxStore(watch);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      gap={4}
      mt={20}
      flexDirection={{ xs: 'column', sm: 'row' }}
    >
      {loadingStep && <FullScreenLoader open />}

      {prevStep && (
        <Box>
          <PreviousStepButton disabled={loadingStep} onClick={goToPrevStep} />
        </Box>
      )}

      {nextStep && (
        <Box>
          <Button disabled={loadingStep} fullWidth type="submit">
            {t('register.actions.next')}
          </Button>
        </Box>
      )}
    </Box>
  );
};
