import { FC } from 'react';
import { Box, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import {
  AppRoute,
  BuildingScreenTab,
  RegistrationCommonStep,
  RegistrationStep
} from '../../utils/navigation';
import { SoknadSteg } from '../../components/registering/utils/registerEnums';
import { SoknadStegMap } from '../../types/soknad';

import RegistrationForm from './components/registrationForm';
import { PublishedErrorLink } from './components/publishedErrorLink';

interface RegistrationFormWrapperProps {
  isPublished: boolean;
  step: RegistrationStep;
  soknadStegMap: SoknadStegMap | undefined;
}

export const RegistrationFormWrapper: FC<RegistrationFormWrapperProps> = ({
  isPublished,
  step,
  soknadStegMap
}) => {
  const { buildingId } = useParams<{
    buildingId: string;
  }>();
  const location = useLocation();
  const { t } = useTranslation();

  const isSoknad = location.pathname.includes(AppRoute.SOKNAD);
  const isSoknadSteg2 = location.pathname.includes(SoknadSteg.Steg2);
  const soknadStep2Done = soknadStegMap?.steg2?.done;

  // If the registration is published or it's the second step of the application process with
  // the second step completed, and the user isn't on the energy certificate step, we display
  // a message and a link back to the building. This is necessary because the second application
  // step is never published, but we need to verify that the step is done.
  if (
    step !== RegistrationCommonStep.ENERGY_CERTIFICATE &&
    ((isSoknadSteg2 && soknadStep2Done) || isPublished)
  ) {
    // Different message depending on if the user is trying to navigate to an application or not
    return (
      <Box>
        <Typography variant="h2">
          {isSoknad
            ? t('registration.isPublished.heading.soknad')
            : t('registration.isPublished.heading')}
        </Typography>
        {isSoknad ? (
          <PublishedErrorLink
            i18nKey="registration.isPublished.description.soknad"
            tab={BuildingScreenTab.DOCUMENTS}
            buildingId={buildingId}
          />
        ) : (
          <PublishedErrorLink
            i18nKey="registration.isPublished.description"
            buildingId={buildingId}
            tab={BuildingScreenTab.ENERGY_CERTIFICATE}
          />
        )}
      </Box>
    );
  }

  return <RegistrationForm isPublished={isPublished} />;
};
