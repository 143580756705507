import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnack } from 'enova-frontend-components';
import { useHistory } from 'react-router-dom';

import { XMLFlerboligResponse } from '../../../../../../services/types';
import { useFileUpload } from '../../common/steps/upload/useFileUpload';
import { createErrorMessage } from '../../../../../../utils/utils';
import { XMLReport } from '../../../../../../types/expertToolsXML';

import { useCreateFlerboligRegistration } from './useCreateFlerboligRegistration';

export const useHandleCreateFlerboligRegistration = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { t } = useTranslation();
  const { addSnack } = useSnack();
  const { parseAndUpload } = useFileUpload();
  const history = useHistory();

  const { createFlerboligRegistration, isLoading } =
    useCreateFlerboligRegistration('multi-unit-cache-key');

  const handleXmlResponse = (response?: XMLFlerboligResponse) => {
    const flerboligId =
      response?.energiAnalyseFlerboligBygg.flerboligbygg.flerboligbyggId;

    if (response?.error) {
      setErrorMessage(`expertToolsXML.error.${response.error}`);
      return;
    }

    if (!flerboligId) {
      const errorMessage = createErrorMessage(
        t('expertToolsXML.error_couldNotCreateRegistration')
      );

      addSnack(errorMessage, { variant: 'error' });
      return;
    }

    history.push(
      `/xml-registrering/flerbolig/oppsummering-av-fil/${flerboligId}`
    );
  };

  const handleCreateRegistration = (
    file?: File,
    buildingIds?: string[],
    groupName?: string
  ) => {
    if (!file || !buildingIds || buildingIds.length === 0) {
      setErrorMessage(t('expertToolsXML.error.noSelectedFile.content'));
      return;
    }

    if (!groupName) {
      setErrorMessage(t('expertToolsXML.error.noGroupName'));
      return;
    }

    const onFlerboligUpload = (report: XMLReport) =>
      createFlerboligRegistration(
        { report, bruksenhetsIds: buildingIds, groupName },
        {
          onSuccess: (data) => handleXmlResponse(data),
          onError: () => {
            setErrorMessage(
              t('expertToolsXML.error_couldNotCreateRegistration')
            );
          }
        }
      );

    parseAndUpload({ file, onUpload: onFlerboligUpload });
  };

  return { handleCreateRegistration, isLoading, errorMessage };
};
