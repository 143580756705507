import React, { FC } from 'react';
import SimpleTable from './components/table';
import { TracelogTableHeader } from './TracelogTableHeader';
import { TraceLogRow } from './traceLogRow';
import { TraceLogItem } from '../../services/tracelog';

interface Props {
  rows: TraceLogItem[];
}

export const DisplayWorklist: FC<Props> = ({ rows }) => {
  return (
    <React.Fragment>
      <SimpleTable aria-labelledby="tableCaptionId">
        <TracelogTableHeader />
        <tbody>
          {rows.reverse().map((tracelogEntry, index) => (
            <TraceLogRow key={index} logentry={tracelogEntry} />
          ))}
        </tbody>
      </SimpleTable>
    </React.Fragment>
  );
};
