import Joi from 'joi';

import { State } from '../utils';

import { schema } from './schema';

// Record where the keys are the input names from the state.
// E.g. { norm_varme: { ...errorProperties}, olje_andel_energi_tappevann_varme: { ...errorProperties}}
type ReducedError = Record<string, Joi.ValidationErrorItem>;

export const resolver = async (data: State) => {
  const { error, value: values } = schema.validate(data, {
    abortEarly: false,
    allowUnknown: true
  });

  return {
    values: error ? {} : (values as State),
    errors: error
      ? error.details.reduce<ReducedError>((previous, currentError) => {
          return {
            ...previous,
            [currentError.path[0]]: currentError
          };
        }, {})
      : {}
  };
};
