import React, { FC, Fragment } from 'react';
import { Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import ReadonlyField from '../../../../../../../components/readonlyField';
import { TilliggendeRom } from '../../../../../../../components/registering/utils/registerEnums';
import useSelector from '../../../../../../../hooks/useSelector';
import { getGulvsone } from '../../../../../../../store/registration/selectors';

type FloorsSummaryContentProps = {
  etasjeId: string;
  gulvsoneId: string;
  gulvsoneIndex?: number;
};

const FloorsSummaryContent: FC<FloorsSummaryContentProps> = ({
  etasjeId,
  gulvsoneId,
  gulvsoneIndex
}) => {
  const { t } = useTranslation();

  const gulvsone = useSelector(
    (state) => getGulvsone(state, etasjeId, gulvsoneId),
    shallowEqual
  );

  const grunnforhold = useSelector(
    (state) => state.register.userInput?.bygningsdetaljer?.grunnforhold,
    shallowEqual
  );

  if (!gulvsone) return null;

  const {
    tilliggendeRom,
    arealIKvm,
    gulvKonstruksjon: {
      brukStandardVerdier,
      gulvType,
      isolasjonstykkelseIMillimeter,
      rehabiliteringEllerPabyggingsAr,
      uendretSidenOppforing
    } = {}
  } = gulvsone;

  const noValues =
    !gulvType &&
    !rehabiliteringEllerPabyggingsAr &&
    !tilliggendeRom &&
    !uendretSidenOppforing &&
    arealIKvm == null &&
    isolasjonstykkelseIMillimeter == null;

  return (
    <Fragment>
      {arealIKvm != null && (
        <ReadonlyField
          label={t('register.floors.gulvsone.area', {
            context: gulvsoneIndex != null && 'zone',
            count: (gulvsoneIndex || 0) + 1
          })}
          value={`${arealIKvm} ${t('suffix.m2')}`}
        />
      )}

      {tilliggendeRom && (
        <ReadonlyField
          label={t('register.floors.adjoiningRooms.label', {
            context: gulvsoneIndex != null && 'zone',
            count: (gulvsoneIndex || 0) + 1
          })}
          value={t(`register.adjoiningRooms.option.${tilliggendeRom}`, {
            context: 'floor'
          })}
        />
      )}

      {tilliggendeRom === TilliggendeRom.MotGrunn && grunnforhold && (
        <ReadonlyField
          label={t('register.floors.gulvsone.grunnforhold.label')}
          value={t(`register.grunnforhold.options.${grunnforhold}`)}
        />
      )}

      {gulvType && (
        <ReadonlyField
          label={t('register.floors.constructionType.label')}
          value={t(`register.floors.constructionType.option.${gulvType}`)}
        />
      )}

      {isolasjonstykkelseIMillimeter != null && (
        <ReadonlyField
          label={t('register.isolation.label')}
          value={t('register.isolation', {
            count: isolasjonstykkelseIMillimeter,
            context: 'count'
          })}
        />
      )}

      {rehabiliteringEllerPabyggingsAr != null && (
        <ReadonlyField
          label={t(
            'register.floors.constructionSpecType.yearOfRehabilitation.label'
          )}
          value={rehabiliteringEllerPabyggingsAr}
        />
      )}

      {uendretSidenOppforing && (
        <ReadonlyField
          label={t('register.floors.constructionSpecType.unchanged')}
          value={t('yes')}
        />
      )}

      {(brukStandardVerdier || noValues) && (
        <Typography>{t('register.floors.constructionMissing')}</Typography>
      )}
    </Fragment>
  );
};

export default FloorsSummaryContent;
