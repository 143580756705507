import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FileContent from '../../../fileReviewContent/fileContent';
import { SimpleEnergyAnalysis } from '../../../../../../../../../types/building';

interface Props {
  energyAnalysis: SimpleEnergyAnalysis;
}

export const FileSummaryTab: FC<Props> = ({ energyAnalysis }) => {
  const { t } = useTranslation();

  return <FileContent energyAnalysis={energyAnalysis} />;
};
