import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Hidden,
  Icon,
  Loader,
  styled,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useQuery } from 'react-query';

import { BuildingSearch } from '../../../../../../types/building';
import { getSearchBuilding } from '../../../../../../services/api';
import { sanitize } from '../../../../../../utils/sanitize';
import { SearchMatchBaseCSS, SearchMatchCSS } from '../utils';
import { SmallTypography } from '../../../utils';
import useBuildingIdentifier from '../../../../../../hooks/useBuildingIdentifier';
import { queryKeys } from '../../../../../../utils/react-query';

import { SearchMatchGroupContent } from './searchMatchGroupContent';

const StyledButton = styled(Button)`
  ${SearchMatchCSS}
  &.expanded {
    border-bottom-color: transparent !important;
  }
`;

const StyledBox = styled((props) => <Box {...props} />)`
  ${SearchMatchBaseCSS};
  border-top: none;
`;

const StyledIcon = styled((props) => <Icon {...props} />)`
  transition: ${({ theme }) =>
    `${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut}`};

  &.rotate {
    transform: rotate(-180deg);
  }
`;

const SearchMatchGroup: FC<BuildingSearch> = ({
  adressenavn,
  adressetekst,
  bokstav,
  bruksnummer,
  festenummer,
  gardsnummer,
  kommunenummer,
  nummer,
  postnummer,
  poststed
}) => {
  const { t } = useTranslation();
  const getBuildingIdentifier = useBuildingIdentifier();

  const [expanded, setExpanded] = useState(false);
  const queryParams = sanitize({
    bokstav,
    bruksNummer: bruksnummer,
    festeNummer: festenummer,
    gardsNummer: gardsnummer,
    gatenavn: adressenavn,
    kommuneNummer: kommunenummer,
    nummer,
    postNummer: postnummer,
    poststed
  });

  // Stringified querykey to identify the search result in the query cache if we in the future
  // may want to cache requests.
  const queryKey = Object.values(queryParams).toString().split(',').join('');

  const {
    error,
    refetch: fetch,
    data: content,
    isLoading: loading
  } = useQuery(
    [queryKeys.searchBuilding, queryKey],
    () => getSearchBuilding(queryParams),
    { enabled: false, onSettled: () => setExpanded(true) }
  );

  const handleExpandChange = () => {
    if (expanded) {
      setExpanded(false);
    } else if (!content) {
      fetch();
    } else {
      setExpanded(true);
    }
  };

  return (
    <Box display="flex" flexDirection="column" width={1}>
      <StyledButton
        aria-expanded={expanded}
        className={classNames('f-fill fvibs', { expanded })}
        fullWidth
        onClick={handleExpandChange}
      >
        <Box display="flex" width={1}>
          <Box p={3} className="f-fill">
            <SmallTypography variant="h4" component="p">
              {t('buildingAddress', {
                adressetekst,
                postSted: poststed,
                postNummer: postnummer
              })}
            </SmallTypography>

            <SmallTypography>
              {getBuildingIdentifier({
                bnr: bruksnummer,
                fnr: festenummer,
                gnr: gardsnummer,
                knr: kommunenummer
              })}
            </SmallTypography>
          </Box>

          <Box p={3} display="flex" alignItems="center" alignSelf="center">
            <Box width={20} mr={1}>
              {loading && <Loader />}
            </Box>

            <Hidden smDown>
              <Typography mr={2} bold>
                {expanded ? t('search.hide') : t('search.show')}
              </Typography>
            </Hidden>

            <StyledIcon
              className={classNames({ rotate: expanded })}
              name="chevron_down"
            />
          </Box>
        </Box>
      </StyledButton>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <StyledBox className={classNames({ expanded })}>
          <SearchMatchGroupContent
            content={content}
            error={error}
            fetch={fetch}
            loading={loading}
            queryParams={queryParams}
          />
        </StyledBox>
      </Collapse>
    </Box>
  );
};

export default SearchMatchGroup;
