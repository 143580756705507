import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Typography } from 'enova-frontend-components';

import { UserType } from '../../../types/user';
import {
  camelCasify,
  DefaultMainScreenTab,
  MainScreenParams,
  MainScreenTab
} from '../../../utils/navigation';

interface TitleProps {
  tabIdentifier: MainScreenTab;
  userType: UserType;
}

const TabTitle = ({ tabIdentifier, userType }: TitleProps) => {
  const { t } = useTranslation();
  const { tab = DefaultMainScreenTab } = useParams<MainScreenParams>();

  return (
    <Typography variant="h2" component={tabIdentifier === tab ? 'h2' : 'span'}>
      {t(`mainScreen.tab.${camelCasify(tabIdentifier)}`, {
        context: userType
      })}
    </Typography>
  );
};

export default TabTitle;
