import React, { FC, Fragment } from 'react';
import {
  Box,
  Button,
  Form,
  FullScreenLoader,
  Radio,
  RadioGroup,
  Typography
} from 'enova-frontend-components';
import { Trans, useTranslation } from 'react-i18next';

import { Malform } from '../../../../../../../../../../components/registering/utils/registerEnums';
import TranslatedEnergyRating2D from '../../../../../../../../../../components/translatedEnergyRating2D';
import { SimpleEnergyAnalysis } from '../../../../../../../../../../types/building';

import { useChangeLanguage } from './useChangeLanguage';

interface CalculatedEnergyRatingProps {
  energyAnalysis: SimpleEnergyAnalysis;
  onDownloadCertificate: () => void;
  isLoading: boolean;
}

const CalculatedEnergyRating: FC<CalculatedEnergyRatingProps> = ({
  energyAnalysis,
  onDownloadCertificate,
  isLoading
}) => {
  const { t } = useTranslation();
  const { changeLanguage, malform } = useChangeLanguage(energyAnalysis);

  const energiKarakter =
    energyAnalysis?.energiplan?.energimerke?.energiKarakter;
  const oppvarmingKarakter =
    energyAnalysis?.energiplan?.energimerke?.properties?.oppvarmingKarakter;

  return (
    <Fragment>
      <FullScreenLoader open={isLoading} />

      <Box mb={8} mt={4}>
        {energiKarakter && oppvarmingKarakter && (
          <TranslatedEnergyRating2D
            energyGrade={energiKarakter}
            heatingGrade={oppvarmingKarakter}
          />
        )}
      </Box>

      <Typography paragraph>
        <Trans i18nKey="expertToolsXML.summary.calculatedRating.description1" />
      </Typography>

      <Typography paragraph>
        {t('expertToolsXML.summary.calculatedRating.description2')}
      </Typography>

      <Form>
        <RadioGroup
          label={t(
            'expertToolsXML.summary.downloadEnergyCertificatePreview.selectMalform.label'
          )}
          marginBottom
          onChange={(_, val) => changeLanguage(val as Malform)}
          row
          value={malform}
        >
          <Radio
            label={t('malform.bokmal')}
            value={Malform.Bokmal}
            width="auto"
          />

          <Radio
            label={t('malform.nynorsk')}
            value={Malform.Nynorsk}
            width="auto"
          />
        </RadioGroup>

        <Button
          className="mb-5"
          leftIcon="download"
          type="submit"
          variant="secondary"
          onClick={onDownloadCertificate}
        >
          {t('expertToolsXML.summary.downloadEnergyCertificatePreview')}
        </Button>
      </Form>
    </Fragment>
  );
};

export default CalculatedEnergyRating;
