import { SelectOption } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

export const useTiltakOptions = (
  tiltaksliste?: string[] | -1
): SelectOption[] => {
  const { t } = useTranslation();

  const options = [
    {
      disabled: true,
      label: t('tiltak.category.option.default'),
      value: -1
    },
    ...Object.values(tiltaksliste || []).map((tiltakType) => ({
      label: t(`tiltak.${tiltakType}`),
      value: tiltakType
    }))
  ];

  return options;
};
