import React, { FC } from 'react';
import { Grid } from 'enova-frontend-components';

import useSelector from '../../../../../hooks/useSelector';
import { displayRoofConstructionCheck } from '../../../../../store/registration/selectors';
import { DetailingStep } from '../../../utils/registerEnums';

import {
  BygningsformCheckBox,
  StepCheckBox,
  VeggerCheckBox,
  VinduerCheckBox
} from './components';

export const DetailingCheckBoxes: FC = () => {
  const displayRoofCheck = useSelector(displayRoofConstructionCheck);

  return (
    <Grid container>
      <BygningsformCheckBox />

      <VeggerCheckBox />

      <VinduerCheckBox />

      {displayRoofCheck && (
        <StepCheckBox step={DetailingStep.Takkonstruksjon} />
      )}

      <StepCheckBox step={DetailingStep.Gulv} />

      <StepCheckBox step={DetailingStep.Ytterdorer} />

      <StepCheckBox step={DetailingStep.Energibruk} />

      <StepCheckBox step={DetailingStep.Lekkasjetall} />
    </Grid>
  );
};
