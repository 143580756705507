import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps
} from 'enova-frontend-components';

export type ReadonlyListFieldProps = BoxProps & {
  label?: string;
  LabelProps?: Partial<TypographyProps>;
  value?: string;
  ValueProps?: Partial<TypographyProps>;
};

const Wrapper = styled((props) => <Box {...props} />)`
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

const ReadonlyListField: FC<ReadonlyListFieldProps> = ({
  label,
  LabelProps,
  value,
  ValueProps,
  ...boxProps
}) => (
  <Wrapper {...boxProps}>
    {label && (
      <Typography bold component="p" {...LabelProps}>
        {label}
      </Typography>
    )}

    <Typography component="p" {...ValueProps}>
      {value}
    </Typography>
  </Wrapper>
);

export default ReadonlyListField;
