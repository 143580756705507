import React, { FC, useCallback, useEffect } from 'react';
import { Box, Grid, Stepper, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { ActionBarHeight } from '../../../utils/layout';
import { camelCasify, RegistrationCommonStep } from '../../../utils/navigation';
import { DetailingStep } from '../../../components/registering/utils/registerEnums';
import { StepperId } from '../utils';
import { AdjustParameters } from '../../../components/registering/advisor/adjustParameters/index';
import Base from '../../../components/registering/steps/base';
import Doors from '../../../components/registering/steps/doors';
import EnergyUsage from '../../../components/registering/steps/energyUsage';
import Floors from '../../../components/registering/steps/floors';
import { Roof } from '../../../components/registering/steps/roof';
import TiltakStep from '../../../components/registering/advisor/tiltak';
import useRegistrationForm from '../useRegistrationForm';
import { useAutoSave } from '../useAutoSave';
import { ConstructionDensity } from '../../../components/registering/steps/constructionDensity';
import { Windows } from '../../../components/registering/steps/windows';
import { Walls } from '../../../components/registering/steps/walls';
import { ShapeAndArea } from '../../../components/registering/steps/shapeAndArea';

import EnergyCertificate from './energyCertificate';
import Summary from './summary';
import { RegistrationActionButtons } from './registrationActionButtons';

const RegistrationForm: FC<{ isPublished: boolean }> = ({ isPublished }) => {
  const { t } = useTranslation();
  const { step, steps, displayErrors, goToStep, registrationId } =
    useRegistrationForm();
  const { mutateAutoSave } = useAutoSave();

  const saveRegistration = useCallback((id: string) => mutateAutoSave(id), []);

  useEffect(() => {
    if (isPublished) {
      return;
    }

    // Dont autosave if step is not included in the detailing step enum or is the about step
    if (
      !Object.values(DetailingStep).includes(step as DetailingStep) &&
      step !== RegistrationCommonStep.ABOUT
    ) {
      return;
    }

    const autoSaveInterval = setInterval(() => {
      if (!registrationId) {
        return;
      }

      saveRegistration(registrationId);
    }, 30 * 1000);

    return () => clearInterval(autoSaveInterval);
  }, [step, registrationId, isPublished]);

  if (!steps.includes(step)) {
    return null;
  }

  const getStepperTOptions = () => {
    const stepIndex = steps.indexOf(step);

    return {
      stepCount: steps.length,
      stepName: steps[stepIndex]
        ? t(`register.step.${camelCasify(steps[stepIndex])}`)
        : '',
      stepNo: stepIndex + 1
    };
  };

  const showSubmitButtons =
    step !== RegistrationCommonStep.ENERGY_CERTIFICATE &&
    step !== RegistrationCommonStep.PARAMETERS &&
    step !== DetailingStep.Lekkasjetall;

  return (
    <Grid container spacing={8}>
      <Grid item xs={12} md={4} lg={3} position="relative">
        <Box position="sticky" top={85 + ActionBarHeight}>
          <Box id={StepperId}>
            <Typography variant="srOnly">
              {t('stepper', getStepperTOptions())}
            </Typography>

            <Stepper aria-hidden>
              {steps.map((registrationStep, index) => {
                //   Disable step navigation:
                //   - If no registration has been created
                //   - To energy certificate step to force submission of registration from summary step
                const isNavigationAvailable =
                  !!registrationId &&
                  registrationStep !==
                    RegistrationCommonStep.ENERGY_CERTIFICATE;

                const navigateToStep = isNavigationAvailable
                  ? () => goToStep(registrationStep)
                  : undefined;

                return (
                  <Stepper.Step
                    active={registrationStep === step}
                    done={
                      index < steps.indexOf(step) ||
                      step === RegistrationCommonStep.ENERGY_CERTIFICATE
                    }
                    onClick={navigateToStep}
                    key={registrationStep}
                    label={`${index + 1}. ${t(
                      `register.step.${camelCasify(registrationStep)}`
                    )}`}
                  />
                );
              })}
            </Stepper>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={8} lg={9}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {step === RegistrationCommonStep.ABOUT && <Base />}
            {step === DetailingStep.Bygningsform && <ShapeAndArea />}
            {step === DetailingStep.Vegger && <Walls />}
            {step === DetailingStep.Vinduer && <Windows />}
            {step === DetailingStep.Takkonstruksjon && (
              <Roof displayErrors={displayErrors} />
            )}
            {step === DetailingStep.Ytterdorer && <Doors />}
            {step === DetailingStep.Gulv && <Floors />}
            {step === DetailingStep.Energibruk && <EnergyUsage />}
            {step === DetailingStep.Lekkasjetall && <ConstructionDensity />}
            {step === RegistrationCommonStep.PARAMETERS && (
              <AdjustParameters registrationId={registrationId} />
            )}
            {step === RegistrationCommonStep.MEASURES && <TiltakStep />}
            {step === RegistrationCommonStep.SUMMARY && <Summary />}
            {step === RegistrationCommonStep.ENERGY_CERTIFICATE && (
              <EnergyCertificate />
            )}
          </Grid>

          {showSubmitButtons && <RegistrationActionButtons />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RegistrationForm;
