import React, { FC, useEffect } from 'react';
import { Box } from 'enova-frontend-components';
import { useParams } from 'react-router-dom';

import useBuildingFilter from '../../../../../hooks/useBuildingFilter';
import {
  DefaultMainScreenTab,
  MainScreenParams,
  MainScreenTab
} from '../../../../../utils/navigation';

import BuildingListItem from './buildingListItem';
import { OrderBuildingListMenu } from './orderBuildingListMenu';
import {
  BuildingListError,
  BuildingListLoader,
  GridWrapper
} from './components';
import { BuildingListEmptyState } from './buildingListEmptyState';

const BuildingList: FC = () => {
  const { tab = DefaultMainScreenTab } = useParams<MainScreenParams>();

  const {
    buildingList: {
      buildings,
      buildingsError,
      buildingsLoading,
      refreshBuildings
    } = {}
  } = useBuildingFilter();

  useEffect(() => {
    refreshBuildings?.();
  }, [tab]);

  const {
    order: [sort, dir],
    updateOrder
  } = useBuildingFilter();

  if (buildingsLoading) {
    return <BuildingListLoader />;
  }

  if (buildingsError) {
    return <BuildingListError />;
  }

  if (!buildings || buildings.length === 0) {
    return <BuildingListEmptyState />;
  }

  return (
    <Box display="flex" flexDirection="column">
      <GridWrapper>
        {buildings.length > 1 && (
          <OrderBuildingListMenu
            dir={dir}
            sort={sort}
            updateOrder={updateOrder}
          />
        )}

        {buildings?.map((building) => (
          <BuildingListItem
            key={building.energiObjektId}
            displayOptions={tab !== MainScreenTab.BUILDINGS}
            building={building}
          />
        ))}
      </GridWrapper>
    </Box>
  );
};

export default BuildingList;
