export enum UserType {
  PERSON = 'person',
  ADVISOR = 'advisor',
  EXPERT = 'expert'
}

export enum UserRole {
  PERSON = 'person',
  PROFESJONELL = 'profesjonell'
}

export enum AreaOfExpertise {
  ENERGIRADGIVER = 'energiradgiver',
  BYGNINGSEKSPERT = 'bygningsekspert',
  KJELOGVARMEANLEGG = 'kjelOgVarmeanlegg',
  VENTILASJONOGKJOLEANLEGG = 'ventilasjonsOgKjoleanlegg'
}

export type UserBase = {
  userId: string;
  rolle: UserRole;
  fagomrader: AreaOfExpertise[];
  endretDato?: Date;
};

export type User = UserBase & {
  navn: string;
  etternavn?: string;
  type: UserType;
};

export const getUserType = (user: UserBase): UserType => {
  if (
    user.fagomrader.includes(AreaOfExpertise.BYGNINGSEKSPERT) ||
    user.fagomrader.includes(AreaOfExpertise.KJELOGVARMEANLEGG) ||
    user.fagomrader.includes(AreaOfExpertise.VENTILASJONOGKJOLEANLEGG)
  )
    return UserType.EXPERT;
  if (user.fagomrader.includes(AreaOfExpertise.ENERGIRADGIVER))
    return UserType.ADVISOR;
  return UserType.PERSON;
};

export const getName = (user: User): string =>
  user.etternavn ? `${user.navn} ${user.etternavn}` : user.navn;
