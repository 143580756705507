import React, { FC } from 'react';
import { CollapsibleFactBox, Grid } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import useSelector from '../../../../../../hooks/useSelector';
import { getDoors } from '../../../../../../store/registration/selectors';
import ExistingDoor from '../../../../../../components/registering/steps/doors/existingDoor';

const DoorsSummarySection: FC = () => {
  const { t } = useTranslation();

  const doors = useSelector(getDoors, shallowEqual);

  return doors && doors.length > 0 ? (
    <Grid item xs={12}>
      <CollapsibleFactBox title={t('register.doors.title')}>
        <Grid container spacing={4}>
          {doors.map((door, doorIndex) => (
            <Grid item xs={12} key={door.id}>
              <ExistingDoor
                door={door}
                HeadingProps={{ component: 'h4' }}
                index={doorIndex}
              />
            </Grid>
          ))}
        </Grid>
      </CollapsibleFactBox>
    </Grid>
  ) : null;
};

export default DoorsSummarySection;
