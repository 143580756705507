import React, { FC, Fragment } from 'react';
import {
  AppBar,
  Box,
  Divider,
  Hidden,
  List,
  styled,
  useEnovaContext
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import {
  AppRoute,
  AppRoutes,
  camelCasify,
  ExpertToolsXMLPortfolioWorklistPath,
  getExpertToolsXMLPortfolioBasePath,
  getMainScreenPath,
  getStatisticsScreenPath,
  hasPermission,
  MainScreenTab,
  StatisticsPath
} from '../../../utils/navigation';
import { useUser } from '../../../hooks/useUser';
import RouterLink from '../../routerLink';
import { ActionBarHeight } from '../../../utils/layout';

import UserMenuCollapsed from './userMenuCollapsed';

const MenuContent = styled.div`
  .user-select--button {
    border: thin solid ${({ theme }) => theme.palette.divider};
    height: auto;
    padding: ${({ theme }) => theme.spacing(3)};
  }
`;

const StyledMenuContent = styled(MenuContent)`
  margin-top: ${ActionBarHeight}px;
`;

const buildingsPath = getMainScreenPath({
  tab: MainScreenTab.BUILDINGS
});

const otherBuildingsPath = getMainScreenPath({
  tab: MainScreenTab.OTHERS_BUILDINGS
});

const findBuildingPath = getMainScreenPath({
  tab: MainScreenTab.SEARCH
});

const xmlRegistrationPath = getMainScreenPath({
  tab: MainScreenTab.XML_OVERVIEW
});

const MainMenu: FC = () => {
  const { t } = useTranslation();
  const { hasUserProfile, user } = useUser();
  const { setMainMenuOpen, mainMenuOpen } = useEnovaContext();

  const buildingsMatch = useRouteMatch({
    exact: true,
    path: ['/', getMainScreenPath({ tab: MainScreenTab.BUILDINGS })]
  });

  const otherBuildingsMatch = useRouteMatch(otherBuildingsPath);
  const findBuildingMatch = useRouteMatch(findBuildingPath);
  const xmlRegistrationMatch = useRouteMatch(xmlRegistrationPath);
  const statisticsMatch = useRouteMatch(StatisticsPath);
  const settingsMatch = useRouteMatch(AppRoutes[AppRoute.PROFILE][0]);
  const xmlPortfolioMatch = useRouteMatch(ExpertToolsXMLPortfolioWorklistPath);

  return (
    <AppBar.Menu
      ToggleButtonProps={{
        label: t('mainMenu.label'),
        'aria-label': mainMenuOpen ? t('mainMenu.close') : t('mainMenu.open')
      }}
    >
      <StyledMenuContent className="d-flex f-column f-fill">
        <Hidden mdUp>
          <Box padding={2}>
            <UserMenuCollapsed />
          </Box>

          <Divider />
        </Hidden>

        <nav>
          <List>
            {hasUserProfile && user && (
              <Fragment>
                <List.Item
                  {...{ to: buildingsPath }}
                  button
                  className="fvibs"
                  component={RouterLink}
                  divider
                  onClick={(e) => !e.ctrlKey && setMainMenuOpen(false)}
                  selected={!!buildingsMatch}
                >
                  {t(`mainScreen.tab.${camelCasify(MainScreenTab.BUILDINGS)}`, {
                    context: user.type
                  })}
                </List.Item>

                <List.Item
                  {...{ to: otherBuildingsPath }}
                  button
                  className="fvibs"
                  component={RouterLink}
                  divider
                  onClick={(e) => !e.ctrlKey && setMainMenuOpen(false)}
                  selected={!!otherBuildingsMatch}
                >
                  {t(
                    `mainScreen.tab.${camelCasify(
                      MainScreenTab.OTHERS_BUILDINGS
                    )}`,
                    { context: user.type }
                  )}
                </List.Item>

                <List.Item
                  {...{ to: findBuildingPath }}
                  button
                  className="fvibs"
                  component={RouterLink}
                  divider
                  onClick={(e) => !e.ctrlKey && setMainMenuOpen(false)}
                  selected={!!findBuildingMatch}
                >
                  {t(`mainScreen.tab.${camelCasify(MainScreenTab.SEARCH)}`, {
                    context: user.type
                  })}
                </List.Item>
                {hasPermission(AppRoute.EXPERT_TOOLS_XML_PORTFOLIO, user) && (
                  <List.Item
                    {...{ to: xmlRegistrationPath }}
                    button
                    className="fvibs"
                    component={RouterLink}
                    divider
                    onClick={(e) => !e.ctrlKey && setMainMenuOpen(false)}
                    selected={!!xmlRegistrationMatch}
                  >
                    {t(
                      `mainScreen.tab.${camelCasify(
                        MainScreenTab.XML_OVERVIEW
                      )}`,
                      {
                        context: user.type
                      }
                    )}
                  </List.Item>
                )}

                <Box bgcolor="divider" height={20} width={1} />

                <List.Item
                  {...{ to: AppRoutes[AppRoute.PROFILE][0] }}
                  button
                  className="fvibs"
                  component={RouterLink}
                  onClick={(e) => !e.ctrlKey && setMainMenuOpen(false)}
                  selected={!!settingsMatch}
                >
                  {t('yourSettings')}
                </List.Item>

                <List.Item
                  {...{ to: '/tracelog' }}
                  button
                  className="fvibs"
                  component={RouterLink}
                  onClick={(e) => !e.ctrlKey && setMainMenuOpen(false)}
                  selected={!!settingsMatch}
                >
                  {t('tracelog')}
                </List.Item>

                <Box bgcolor="divider" height={20} width={1} />
              </Fragment>
            )}

            <List.Item
              {...{ to: getStatisticsScreenPath() }}
              button
              className="fvibs"
              component={RouterLink}
              divider
              onClick={(e) => !e.ctrlKey && setMainMenuOpen(false)}
              selected={!!statisticsMatch}
            >
              {t('statistics')}
            </List.Item>

            {hasPermission(AppRoute.EXPERT_TOOLS_XML_PORTFOLIO, user) && (
              <List.Item
                {...{ to: getExpertToolsXMLPortfolioBasePath() }}
                button
                className="fvibs"
                component={RouterLink}
                divider
                onClick={(e) => !e.ctrlKey && setMainMenuOpen(false)}
                selected={!!xmlPortfolioMatch}
              >
                {t('mainMenu.item', { context: 'expertToolsPortfolio' })}
              </List.Item>
            )}
          </List>
        </nav>
      </StyledMenuContent>
    </AppBar.Menu>
  );
};

export default MainMenu;
