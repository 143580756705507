import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'enova-frontend-components';
import React from 'react';

import { Vegg } from '../../../../../types/registration/userInput';

export const WallTitle = ({ wall }: { wall: Vegg }) => {
  const { t } = useTranslation();

  const wallZonesCombinedLength = wall.veggSoner.reduce(
    (prev, currentValue) => prev + (currentValue.lengdeIMeter ?? 0),
    0
  );

  return (
    <Box display="flex" flexDirection="row" gap={4} alignItems="center">
      <Typography variant="h4">
        {t('wall', { veggId: wall.veggId.toUpperCase() })}
      </Typography>
      <Typography>
        {t('register.wallZone.subHeader', {
          length: wallZonesCombinedLength,
          totalLength: wall.lengdeIMeter
        })}
      </Typography>
    </Box>
  );
};
