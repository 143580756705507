import { isValid, isAfter } from 'date-fns';

import { AreaOfExpertise } from '../../../../types/user';

import { State } from './types';

export const lekkasjeTallIsRequired = (
  lekkasjetall: State['lekkasjetall'],
  values: State
) => {
  if (!lekkasjetall && values.lekkasjetallDato) {
    return 'register.errorMessage.field.lekkasjetall';
  }

  return true;
};

export const datoIsRequired = (
  dato: State['lekkasjetallDato'],
  values: State
) => {
  if (!dato && !values.lekkasjetall) {
    return true;
  }

  if (values.lekkasjetall && !dato) {
    const fagomrader = values.user?.fagomrader;
    const isBygningsEkspertOrEnergiradgiver =
      fagomrader?.includes(AreaOfExpertise.BYGNINGSEKSPERT) ||
      fagomrader?.includes(AreaOfExpertise.ENERGIRADGIVER);

    // If user has fagområde bygningsekspert or energirådgiver, dato is not required
    if (isBygningsEkspertOrEnergiradgiver) {
      return true;
    }

    return 'register.errorMessage.field.lekkasjetallDato';
  }

  return true;
};

export const datoIsInvalid = (
  dato: State['lekkasjetallDato'],
  values: State
) => {
  if (dato instanceof Date) {
    if (!isValid(dato)) {
      return 'register.errorMessage.field.lekkasjetallDato';
    }

    const isBygningsEkspertOrEnergiradgiver =
      values.user?.fagomrader.includes(AreaOfExpertise.BYGNINGSEKSPERT) ||
      values.user?.fagomrader.includes(AreaOfExpertise.ENERGIRADGIVER);
    const byggAr = values.byggAr;

    if (
      !isBygningsEkspertOrEnergiradgiver &&
      byggAr &&
      dato.getFullYear() < byggAr - 1
    ) {
      return 'register.errorMessage.field.lekkasjetallDato_min';
    }

    const today = new Date();

    if (isAfter(dato, today)) {
      return 'register.errorMessage.field.lekkasjetallDato_max';
    }
  }

  return true;
};
