import { useHistory, useLocation } from 'react-router-dom';

export const useCategoryFilter = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const history = useHistory();
  const selectedCategory = searchParams.get('kategori');

  const setCategoryFilter = (categoryId: string) => {
    searchParams.set('kategori', categoryId);

    // Reset building selection on category change
    searchParams.delete('valgte-bygg');

    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  return { selectedCategory, setCategoryFilter };
};
