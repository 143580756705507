import { Redirect, useParams } from 'react-router-dom';
import React, { FC, Fragment } from 'react';
import { FullScreenLoader } from 'enova-frontend-components';

import { XmlRegistration } from '../common/xmlRegistration';
import { XmlRegistrationStepper } from '../common/components/xmlRegistrationStepper';
import Confirmation from '../common/steps/confirmation';
import FileReviewContent from '../common/steps/fileReviewContent';

import { FlerboligUpload } from './steps/flerboligUpload';
import { FlerboligMeasures } from './steps/flerboligMeasures';
import { FlerboligBuildingSelect } from './steps/flerboligBuildingSelect';
import { FlerboligHeader } from './flerboligHeader';
import { FlerboligRegistrationXMLParams } from './types';
import { checkFlerboligStepCompletion } from './utils';
import { FlerboligSummary } from './steps/flerboligSummary';
import { useGetFlerboligRegistration } from './hooks/useGetFlerboligRegistration';

export const FlerboligXmlRegistration: FC = () => {
  const { step: currentStep } = useParams<FlerboligRegistrationXMLParams>();
  const { flerboligId } = useParams<FlerboligRegistrationXMLParams>();

  const { flerboligRegistrationData, isLoading } =
    useGetFlerboligRegistration(flerboligId);

  if (!currentStep) {
    // Use 'velg-bygg' as default step
    return <Redirect to="/xml-registrering/flerbolig/velg-bygg" />;
  }

  if (isLoading) {
    return <FullScreenLoader open />;
  }

  const energianalyser = flerboligRegistrationData?.energianalyser;

  return (
    <XmlRegistration
      header={
        <FlerboligHeader
          flerboligRegistrationData={flerboligRegistrationData}
        />
      }
      stepper={
        <XmlRegistrationStepper
          checkStepCompletion={checkFlerboligStepCompletion}
        />
      }
      registrationStep={
        <Fragment>
          {currentStep === 'velg-bygg' && <FlerboligBuildingSelect />}
          {currentStep === 'last-opp-fil' && <FlerboligUpload />}
          {currentStep === 'tiltak' && <FlerboligMeasures />}
          {currentStep === 'oppsummering-av-fil' && (
            <FileReviewContent
              registrationType="flerbolig"
              energianalyse={energianalyser?.[0]}
            />
          )}
          {currentStep === 'kvittering' && (
            <Confirmation energianalyse={energianalyser?.[0]} />
          )}
          {currentStep === 'oppsummering' && (
            <FlerboligSummary energianalyser={energianalyser} />
          )}
        </Fragment>
      }
    />
  );
};
