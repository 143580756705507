import { Box } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';

import { WindowDetailingSelect } from './components/windowDetailingSelect';
import { YearOfProduction } from './components/yearOfProduction';
import { WindowUValue } from './components/windiowUValue';
import { WindowArea } from './components/windowArea';
import { WindowAmount } from './components/windowAmount';
import { WindowFrameSelect } from './components/windowFrameSelect';
import { WindowGlassSelect } from './components/windowGlassSelect';
import { WallZoneSelect } from './components/wallZoneSelect';
import { WindowFormFieldProps } from './utils';

export const FormFields: FC<WindowFormFieldProps> = ({ control }) => {
  const selectedDetailing = useWatch({
    control,
    name: `detailing`
  });

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <WallZoneSelect control={control} />
      <WindowDetailingSelect control={control} />

      {selectedDetailing === 'yearOfProduction' && (
        <YearOfProduction control={control} />
      )}

      {selectedDetailing === 'uValue' && <WindowUValue control={control} />}

      {selectedDetailing === 'windowType' && (
        <Box display="flex" flexDirection="row" gap={3}>
          <WindowFrameSelect control={control} />
          <WindowGlassSelect control={control} />
        </Box>
      )}

      <WindowArea control={control} />
      <WindowAmount control={control} />
    </Box>
  );
};
