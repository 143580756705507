import React, { FC, Fragment } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';
import {
  AppRoute,
  AppRoutes,
  BuildingBasePath,
  camelCasify,
  hasPermission,
  XmlEnhetPath,
  XmlFlerboligPath
} from '../utils/navigation';
import i18n from '../i18n';
import { useUser } from '../hooks/useUser';
import BuildingDetailsProvider from '../hooks/useBuildingDetails/provider';

import CreateBuilding from './createBuilding';
import HomeScreen from './home';
import PageNotFound from './pageNotFound';
import DirectLogin from './directLogin';
import LoginScreen from './login';
import LogoutScreen from './logout';
import ProfileScreen from './profile';
import MainScreen from './main';
import StatisticsScreen from './statistics';
import BuildingScreen from './building';
import RegistrationScreen from './registration';
import ExpertToolsScreen from './expertTools';
import ExpertToolsXMLPortfolioScreen from './expertToolsXMLPortfolio';
import SoknadScreen from './soknad';
import { ProtectedRoute } from './protectedRoute';
import TracelogScreen from './tracelog';
import { EnhetXmlRegistration } from './main/xmlRegistrationView/xmlRegistration/enhet';
import { FlerboligXmlRegistration } from './main/xmlRegistrationView/xmlRegistration/flerbolig';

export const Routes: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { user } = useUser();

  const defaultTitle = [t('companyName'), t('appName')].join(' ');
  const documentTitle = pathname
    .split('/')
    .reverse()
    .reduce(
      (acc: string[], cur) => (cur ? [...acc, t(camelCasify(cur))] : acc),
      []
    )
    .join(' | ');

  return (
    <Fragment>
      <Helmet
        defaultTitle={defaultTitle}
        htmlAttributes={{ lang: i18n.language }}
        title={documentTitle}
        titleTemplate={`%s | ${defaultTitle}`}
      />

      <Layout
        fullWidth
        MainProps={{
          ContainerProps: { className: 'd-flex' },
          className: 'd-flex f-fill f-column'
        }}
      >
        <Switch>
          <ProtectedRoute path={BuildingBasePath}>
            <BuildingDetailsProvider>
              <Switch>
                <Route
                  component={RegistrationScreen}
                  exact
                  path={[
                    ...AppRoutes[AppRoute.REGISTRATION],
                    ...AppRoutes[AppRoute.NEW_REGISTRATION]
                  ]}
                />

                <Route
                  component={BuildingScreen}
                  exact
                  path={AppRoutes[AppRoute.BUILDING]}
                />

                {hasPermission(AppRoute.SOKNAD, user) && (
                  <Route
                    component={SoknadScreen}
                    exact
                    path={AppRoutes[AppRoute.SOKNAD]}
                  />
                )}

                {hasPermission(AppRoute.EXPERT_TOOLS_VARME, user) && (
                  <Route
                    component={ExpertToolsScreen}
                    exact
                    path={AppRoutes[AppRoute.EXPERT_TOOLS_VARME]}
                  />
                )}

                {hasPermission(AppRoute.EXPERT_TOOLS_KULDE, user) && (
                  <Route
                    component={ExpertToolsScreen}
                    exact
                    path={AppRoutes[AppRoute.EXPERT_TOOLS_KULDE]}
                  />
                )}

                <Route component={PageNotFound} />
              </Switch>
            </BuildingDetailsProvider>
          </ProtectedRoute>
          <ProtectedRoute
            component={CreateBuilding}
            path={AppRoutes[AppRoute.CREATE_BUILDING]}
          />

          <ProtectedRoute
            component={ExpertToolsXMLPortfolioScreen}
            exact
            path={AppRoutes[AppRoute.EXPERT_TOOLS_XML_PORTFOLIO]}
          />

          {hasPermission(AppRoute.XML_REGISTRATION, user) && (
            <ProtectedRoute
              component={EnhetXmlRegistration}
              path={XmlEnhetPath}
            />
          )}
          {hasPermission(AppRoute.XML_REGISTRATION, user) && (
            <ProtectedRoute
              component={FlerboligXmlRegistration}
              path={XmlFlerboligPath}
            />
          )}

          <Route
            component={LoginScreen}
            exact
            path={AppRoutes[AppRoute.LOGIN]}
          />

          <Route
            component={LogoutScreen}
            exact
            path={AppRoutes[AppRoute.LOGOUT]}
          />

          <ProtectedRoute
            component={ProfileScreen}
            exact
            path={AppRoutes[AppRoute.PROFILE]}
          />

          <Route
            component={StatisticsScreen}
            exact
            path={AppRoutes[AppRoute.STATISTICS]}
          />

          <ProtectedRoute
            component={MainScreen}
            exact
            path={AppRoutes[AppRoute.MAIN]}
          />

          <Route
            component={DirectLogin}
            exact
            path={AppRoutes[AppRoute.DIRECT_LOGIN]}
          />

          <ProtectedRoute
            component={TracelogScreen}
            exact
            path={AppRoutes[AppRoute.TRACELOG]}
          />
          <Route component={HomeScreen} exact path={AppRoutes[AppRoute.HOME]} />

          <Route component={PageNotFound} />
        </Switch>
      </Layout>
    </Fragment>
  );
};
