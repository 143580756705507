import { Radio, RadioGroup } from 'enova-frontend-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { toggleAllFloorsHaveSameShape } from '../../../../../store/registration/actions';
import { ConfirmDialog } from '../../../../confirmDialog';
import useSelector from '../../../../../hooks/useSelector';
import { getEtasjer } from '../../../../../store/registration/selectors';
import { BygningsForm } from '../../../utils/registerEnums';

interface Props {
  isSameShape: boolean;
}

export const SameShapeAndAreaRadio: FC<Props> = ({ isSameShape }) => {
  const dispatch = useDispatch();
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const { t } = useTranslation();

  const etasjer = useSelector(getEtasjer);

  const hasEtasjeData = etasjer.some(
    (etasje) =>
      etasje.vegger &&
      etasje.vegger.length > 0 &&
      etasje.bygningsForm &&
      etasje.bygningsForm !== BygningsForm.Unknown
  );

  const handleChange = (value: boolean) => {
    dispatch(toggleAllFloorsHaveSameShape({ value }));

    isConfirmDialogOpen && setConfirmDialogOpen(false);
  };

  return (
    <>
      <RadioGroup
        row
        marginBottom
        value={isSameShape ?? null}
        label={t('register.buildingShape.sameShapeAndArea')}
        onChange={(_e, val) => {
          const sameShapeAllFloors = val === 'true';

          if (sameShapeAllFloors && hasEtasjeData) {
            setConfirmDialogOpen(true);
            return;
          }

          handleChange(sameShapeAllFloors);
        }}
      >
        <Radio label={t('yes')} value={true} width="auto" />
        <Radio label={t('no')} value={false} width="auto" />
      </RadioGroup>

      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onConfirm={() => handleChange(true)}
        onCancel={() => setConfirmDialogOpen(false)}
        title={t('register.buildingShape.sameShapeAndArea.confirmDialog.title')}
        content={t(
          'register.buildingShape.sameShapeAndArea.confirmDialog.content'
        )}
      />
    </>
  );
};
