// Key for storing the user's pre-login location for post-authentication redirection.
export const REFERRER_KEY = 'referrer';

export const hasAuthParams = (location = window.location): boolean => {
  // response_mode: query
  let searchParams = new URLSearchParams(location.search);
  if (
    (searchParams.get('code') || searchParams.get('error')) &&
    searchParams.get('state')
  ) {
    return true;
  }

  // response_mode: fragment
  searchParams = new URLSearchParams(location.hash.replace('#', '?'));
  if (
    (searchParams.get('code') || searchParams.get('error')) &&
    searchParams.get('state')
  ) {
    return true;
  }

  return false;
};

// Check if user state has a referrer property
export function hasReferrer(state: unknown): state is { referrer: string } {
  return Boolean(
    state &&
      typeof state === 'object' &&
      REFERRER_KEY in state &&
      typeof state[REFERRER_KEY] === 'string'
  );
}
