import React, { FC, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import { useUser } from '../../hooks/useUser';
import { SessionExpiresDialog } from '../../components/sessionExpiresDialog';
import {
  AppRoute,
  ExpertToolsXMLPortfolioBasePath,
  ExpertToolsXMLPortfolioWorklistPath,
  hasPermission
} from '../../utils/navigation';

import Worklist from './worklist';
import ExpertToolsXMLPortfolioProvider from './worklist/useExpertToolsXMLPortfolio/provider';
import { WorklistOverview } from './worklistOverview';

const ExpertToolsXMLPortfolioScreen: FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  if (!hasPermission(AppRoute.EXPERT_TOOLS_XML_PORTFOLIO, user)) {
    return null;
  }

  return (
    <Fragment>
      <Helmet title={t('expertToolsXMLPortfolio.title')} />

      <Switch>
        <Route
          component={WorklistOverview}
          exact
          path={ExpertToolsXMLPortfolioBasePath}
        />

        <Route exact path={ExpertToolsXMLPortfolioWorklistPath}>
          <ExpertToolsXMLPortfolioProvider>
            <Worklist />
          </ExpertToolsXMLPortfolioProvider>
        </Route>
      </Switch>
      <SessionExpiresDialog />
    </Fragment>
  );
};

export default ExpertToolsXMLPortfolioScreen;
