import React, { FC, useMemo } from 'react';
import { Select } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { getSelectOptionsFromEnum } from '../../../../../../../utils/register';
import i18n from '../../../../../../../i18n';
import { VeggType } from '../../../../../utils/registerEnums';
import { WallZoneFormFieldProps } from '../utils';
import { useWallFormContext } from '../../../utils';

export const WallTypeSelect: FC<WallZoneFormFieldProps> = ({ control }) => {
  const { t } = useTranslation();
  const { setValue } = useWallFormContext();

  const wallTypeOptions = useMemo(
    () => [
      {
        label: String(t('register.wallType.option.default')),
        value: -1,
        disabled: true
      },
      ...getSelectOptionsFromEnum(VeggType, (label) =>
        t(`register.wallType.option.${label}`)
      ).sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))
    ],
    [i18n.language]
  );

  return (
    <Controller
      control={control}
      name="wallZoneData.wallZone.veggKonstruksjon.veggType"
      rules={{
        validate: {
          required: (value, { wallZoneData }) => {
            // Only required if detailing is set to 'construction'
            if (wallZoneData.detailing === 'construction' && !value) {
              return t('required');
            }

            return true;
          }
        }
      }}
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error }
      }) => {
        return (
          <Select
            {...rest}
            error={!!error}
            helperText={error?.message}
            label={t('register.wallType.label')}
            onChange={(e) => {
              onChange(e.currentTarget.value as VeggType);

              // Reset insulation select value
              setValue(
                'wallZoneData.wallZone.veggKonstruksjon.isolasjonstykkelseIMillimeter',
                -1
              );
            }}
            options={wallTypeOptions}
            value={value ?? -1}
            HelperBoxProps={{
              'aria-label': t('readFieldInformation'),
              children: t('register.wallType.information')
            }}
          />
        );
      }}
    />
  );
};
