import { FC } from 'react';
import { Button } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { SoknadSteg } from '../../../../components/registering/utils/registerEnums';
import useRegistrationForm from '../../useRegistrationForm';

interface Props {
  disabled: boolean;
  onClick: () => void;
}
export const NextStepButton: FC<Props> = ({ onClick, disabled }) => {
  const { t } = useTranslation();

  const { nextStep, soknadSteg, registrationId } = useRegistrationForm();

  const nextStepContext =
    soknadSteg === SoknadSteg.Steg2 && nextStep === 'energiattest'
      ? `${nextStep}.steg2`
      : nextStep;

  const nextStepButtonCaption = !registrationId
    ? t('register.actions.startDetailing')
    : t('register.actions.next', {
        context: nextStepContext
      });

  return (
    <Button disabled={disabled} fullWidth onClick={onClick}>
      {nextStepButtonCaption}
    </Button>
  );
};

export const PreviousStepButton: FC<Props> = ({ disabled, onClick }) => {
  const { t } = useTranslation();

  return (
    <Button disabled={disabled} fullWidth onClick={onClick} variant="secondary">
      {t('register.actions.previous')}
    </Button>
  );
};
