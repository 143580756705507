import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from 'enova-frontend-components';

interface FormButtonsProps {
  onCancel: () => void;
  disableSubmit: boolean;
}

export const FormButtons: FC<FormButtonsProps> = ({
  onCancel,
  disableSubmit
}) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12} sm="auto">
        <Button fullWidth variant="secondary" onClick={onCancel}>
          {t('cancel')}
        </Button>
      </Grid>

      <Grid item xs={12} sm="auto">
        <Button fullWidth type="submit" disabled={disableSubmit}>
          {t('register.window.confirm')}
        </Button>
      </Grid>
    </Grid>
  );
};
