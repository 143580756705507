import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useSnack } from 'enova-frontend-components';
import { useHistory, useParams } from 'react-router-dom';

import { publishPortefolje } from '../../../../services/api';
import { ExpertToolsXMLPortfolioStep } from '../../../../utils/expertToolsXML/enums';
import {
  ExpertToolsXMLPortfolioWorklistParams,
  getExpertToolsXMLPortfolioWorklistPath
} from '../../../../utils/navigation';
import { createErrorMessage } from '../../../../utils/utils';

import { useTiltakPortefolje } from './useTiltakPortefolje';
import ExpertToolsXMLPortfolioContext, {
  ExpertToolsXMLPortfolioContextValues
} from './context';
import { usePortefoljeData } from './usePortefoljeData';

const steps = Object.values(
  ExpertToolsXMLPortfolioStep
) as ExpertToolsXMLPortfolioStep[];

const ExpertToolsXMLPortfolioProvider: FC<{ children: ReactNode }> = ({
  children
}) => {
  const { push } = useHistory();
  const { addSnack } = useSnack();
  const params = useParams<ExpertToolsXMLPortfolioWorklistParams>();

  const { step = ExpertToolsXMLPortfolioStep.UPLOAD, portefoljeId } = params;

  const [loadingStep, setLoadingStep] = useState(false);

  const { refreshPortefoljeData, simpleEnergyAnalyses } =
    usePortefoljeData(portefoljeId);
  const { tiltakList } = useTiltakPortefolje(portefoljeId);

  useEffect(() => window.scrollTo(0, 0), [step]);

  const nextStep = steps[
    steps.indexOf(step) + 1
  ] as ExpertToolsXMLPortfolioStep;

  const prevStep = steps[
    steps.indexOf(step) - 1
  ] as ExpertToolsXMLPortfolioStep;

  const goToNextStep = () => {
    switch (step) {
      case ExpertToolsXMLPortfolioStep.MEASURES: {
        if (portefoljeId) {
          setLoadingStep(true);
          publishPortefolje(portefoljeId)
            .then(({ ok }) => {
              if (!ok) throw new Error();

              if (nextStep) {
                push(
                  getExpertToolsXMLPortfolioWorklistPath({
                    ...params,
                    step: nextStep
                  })
                );
              }
              setLoadingStep(false);
            })
            .catch(() => {
              refreshPortefoljeData();
              addSnack(createErrorMessage(), { variant: 'error' });
              setLoadingStep(false);
            });
        }
        break;
      }
      default:
        nextStep &&
          push(
            getExpertToolsXMLPortfolioWorklistPath({
              ...params,
              step: nextStep
            })
          );
    }
  };

  const goToPrevStep = () =>
    prevStep &&
    push(getExpertToolsXMLPortfolioWorklistPath({ ...params, step: prevStep }));

  const values: ExpertToolsXMLPortfolioContextValues = {
    goToNextStep,
    goToPrevStep,
    nextStep,
    portefoljeId,
    prevStep,
    refreshPortefoljeData,
    simpleEnergyAnalyses,
    step,
    steps,
    tiltakList,
    loadingStep
  };

  return (
    <ExpertToolsXMLPortfolioContext.Provider value={values}>
      {children}
    </ExpertToolsXMLPortfolioContext.Provider>
  );
};

export default ExpertToolsXMLPortfolioProvider;
