import { generatePath } from 'react-router-dom';

import { StatisticsScreenViews } from '../screens/statistics/utils';
import {
  DetailingStep,
  SoknadSteg
} from '../components/registering/utils/registerEnums';
import { AreaOfExpertise, User, UserType } from '../types/user';

import {
  ExpertToolsXMLPortfolioStep,
  ExpertToolsXMLStep
} from './expertToolsXML/enums';

type TabMap<T> = Record<UserType, T[]>;

// MAIN SCREEN TABS
export enum MainScreenTab {
  BUILDINGS = 'boliger',
  OTHERS_BUILDINGS = 'andres-boliger',
  SEARCH = 'sok',
  XML_OVERVIEW = 'xml-oversikt'
}

export const DefaultMainScreenTab = MainScreenTab.BUILDINGS;

// BUILDING SCREEN TABS
export enum BuildingScreenTab {
  ABOUT = 'om-boligen',
  DOCUMENTS = 'dokumenter-for-enova-soknad',
  ENERGY_ASSESSMENT = 'energivurdering',
  ENERGY_CERTIFICATE = 'energiattest',
  ENERGY_RATING = 'energimerking',
  ENERGY_SAVING_MEASURES = 'energisparende-tiltak'
}

export const BuildingScreenTabMap: TabMap<BuildingScreenTab> = {
  [UserType.PERSON]: [
    BuildingScreenTab.ENERGY_CERTIFICATE,
    BuildingScreenTab.ABOUT,
    BuildingScreenTab.ENERGY_RATING,
    BuildingScreenTab.ENERGY_SAVING_MEASURES,
    BuildingScreenTab.DOCUMENTS
  ],
  [UserType.ADVISOR]: [
    BuildingScreenTab.ENERGY_CERTIFICATE,
    BuildingScreenTab.ABOUT,
    BuildingScreenTab.ENERGY_RATING,
    BuildingScreenTab.ENERGY_SAVING_MEASURES,
    BuildingScreenTab.DOCUMENTS
  ],
  [UserType.EXPERT]: [
    BuildingScreenTab.ENERGY_CERTIFICATE,
    BuildingScreenTab.ABOUT,
    BuildingScreenTab.ENERGY_RATING,
    BuildingScreenTab.ENERGY_ASSESSMENT,
    BuildingScreenTab.ENERGY_SAVING_MEASURES,
    BuildingScreenTab.DOCUMENTS
  ]
};

// EXPERT TOOLS
export enum ExpertToolsKuldeArea {
  KJOLEANLEGG = 'kjoleanlegg',
  VENTILASJON = 'ventilasjon'
}

export enum ExpertToolsVarmeArea {
  VARMEANLEGG = 'varmeanlegg',
  ELKJEL = 'elkjel'
}

export enum ExpertToolsStep {
  UPLOAD = 'last-opp',
  CONFIRMATION = 'bekreftelse'
}

// REGISTER STEPS
export enum RegistrationCommonStep {
  ABOUT = 'om-boligen',
  PARAMETERS = 'parameter',
  MEASURES = 'tiltak',
  SUMMARY = 'oppsummering',
  ENERGY_CERTIFICATE = 'energiattest'
}

export type RegistrationStep = RegistrationCommonStep | DetailingStep;

export enum RegistrationSummaryTab {
  CALCULATED_ENERGY_RATING = 'beregnet-energimerke',
  RESULT_CALCULATION = 'beregning-av-resultat',
  REVIEW = 'se-over',
  MEASURES = 'tiltak',
  DOCUMENTS = 'dokumenter'
}

// APP ROUTES
export enum AppRoute {
  HOME,
  BUILDING = 'bygg',
  CREATE_BUILDING = 'opprett-bygg',
  DIRECT_LOGIN = 'direct-login',
  EXPERT_TOOLS_KULDE = 'ekspert-kulde',
  EXPERT_TOOLS_VARME = 'ekspert-varme',
  EXPERT_TOOLS_XML = 'ekspertregistrering',
  EXPERT_TOOLS_XML_PORTFOLIO = 'ekspertregistrering-portefolje',
  LOGIN = 'login',
  LOGOUT = 'logout',
  MAIN = 'main',
  NEW_REGISTRATION = 'ny-registrering',
  PROFILE = 'profil',
  REGISTRATION = 'registrer-energimerke',
  SOKNAD = 'soknad',
  STATISTICS = 'statistikk',
  TRACELOG = 'tracelog',
  XML_REGISTRATION = 'xml-registrering'
}

const getValidTabsRegEx = (tabEnum: Record<string, string>) =>
  Object.values(tabEnum).join('|');

// PATH PARAMS
export type BuildingId = { buildingId: string };

export type RegistrationId = { registrationId: string };

export type BuildingScreenParams = BuildingId & {
  tab?: BuildingScreenTab;
};

type ExpertToolsVarmeParams = BuildingId & {
  area: ExpertToolsVarmeArea;
};

type ExpertToolsKuldeParams = BuildingId & {
  area: ExpertToolsKuldeArea;
};

export type ExpertToolsParams = BuildingId & {
  area: ExpertToolsVarmeArea | ExpertToolsKuldeArea;
};

export type ExpertToolsXMLCreateParams = BuildingId;

export type ExpertToolsXMLParams = ExpertToolsXMLCreateParams &
  RegistrationId & {
    step: ExpertToolsXMLStep;
  };

type NewRegistrationScreenParams = BuildingId & {
  originalRegistrationId?: string;
};

export type RegistrationScreenParams = BuildingId &
  RegistrationId & {
    step?: RegistrationStep;
  };

export type RegistrationSummaryScreenParams = BuildingId &
  RegistrationId & {
    step: RegistrationCommonStep.SUMMARY;
    tab?: RegistrationSummaryTab;
  };

type SoknadScreenBaseParams = {
  soknadId: string;
  soknadSteg: SoknadSteg;
};

export type SoknadScreenParams = Omit<
  RegistrationScreenParams,
  'registrationId'
> &
  SoknadScreenBaseParams;

type SoknadSummaryScreenParams = Omit<
  RegistrationSummaryScreenParams,
  'registrationId'
> &
  SoknadScreenBaseParams;

export type ExpertToolsXMLPortfolioWorklistParams = {
  portefoljeId: string;
  step?: ExpertToolsXMLPortfolioStep;
};

export type AllRegistrationScreenParams = NewRegistrationScreenParams &
  RegistrationScreenParams &
  SoknadScreenParams;

export type MainScreenParams = { tab?: MainScreenTab };

export type StatisticsScreenParams = { view?: StatisticsScreenViews };

// PATHS
export const BuildingBasePath = ['', AppRoute.BUILDING, ':buildingId'].join(
  '/'
);

const BuildingScreenPath = [
  BuildingBasePath,
  `:tab(${getValidTabsRegEx(BuildingScreenTab)})?`
].join('/');

const ExpertToolsVarmePath = [
  BuildingBasePath,
  AppRoute.EXPERT_TOOLS_VARME,
  `:area(${getValidTabsRegEx(ExpertToolsVarmeArea)})`
].join('/');

const ExpertToolsKuldePath = [
  BuildingBasePath,
  AppRoute.EXPERT_TOOLS_KULDE,
  `:area(${getValidTabsRegEx(ExpertToolsKuldeArea)})`
].join('/');

const ExpertToolsXMLPath = [
  BuildingBasePath,
  AppRoute.EXPERT_TOOLS_XML,
  ':registrationId?',
  `:step(${getValidTabsRegEx(ExpertToolsXMLStep)})?`
].join('/');

export const ExpertToolsXMLPortfolioBasePath = [
  '',
  AppRoute.EXPERT_TOOLS_XML_PORTFOLIO
].join('/');

export const ExpertToolsXMLPortfolioWorklistPath = [
  '',
  AppRoute.EXPERT_TOOLS_XML_PORTFOLIO,
  'arbeidsliste',
  `:portefoljeId`,
  `:step(${getValidTabsRegEx(ExpertToolsXMLPortfolioStep)})?`
].join('/');

const NewRegistrationScreenPath = [
  BuildingBasePath,
  AppRoute.NEW_REGISTRATION,
  `:originalRegistrationId?`
].join('/');

const RegistrationBasePath = [
  BuildingBasePath,
  AppRoute.REGISTRATION,
  ':registrationId'
].join('/');

const RegistrationPathGeneral = [
  RegistrationBasePath,
  `:step(${getValidTabsRegEx({
    ...RegistrationCommonStep,
    ...DetailingStep
  })})?`
].join('/');

const RegistrationPathSummary = [
  RegistrationBasePath,
  `:step(${RegistrationCommonStep.SUMMARY})`,
  `:tab(${getValidTabsRegEx(RegistrationSummaryTab)})?`
].join('/');

const NewSoknadPath = [BuildingBasePath, 'ny-soknad'].join('/');

const SoknadBasePath = [
  BuildingBasePath,
  AppRoute.SOKNAD,
  ':soknadId',
  `:soknadSteg(${getValidTabsRegEx(SoknadSteg)})`
].join('/');

const SoknadPathGeneral = [
  SoknadBasePath,
  `:step(${getValidTabsRegEx({
    ...RegistrationCommonStep,
    ...DetailingStep
  })})?`
].join('/');

const SoknadPathSummary = [
  SoknadBasePath,
  `:step(${RegistrationCommonStep.SUMMARY})`,
  `:tab(${getValidTabsRegEx(RegistrationSummaryTab)})?`
].join('/');

export const MainScreenPath = `/:tab(${getValidTabsRegEx(MainScreenTab)})`;

export const AdvancedSearchScreenPath = `/:tab(${MainScreenTab.SEARCH})/advanced`;

export const XmlTabPath = `/:tab(${AppRoute.XML_REGISTRATION})`;
export const XmlEnhetPath = `/:tab(${AppRoute.XML_REGISTRATION})/enhet/:step/:registrationId?`;
export const XmlFlerboligPath = `/:tab(${AppRoute.XML_REGISTRATION})/flerbolig/:step/:flerboligId?`;

export const StatisticsPath = `/${
  AppRoute.STATISTICS
}/:view(${getValidTabsRegEx(StatisticsScreenViews)})?`;

export const AppRoutes: Record<AppRoute, string[]> = {
  [AppRoute.HOME]: ['/'],
  [AppRoute.BUILDING]: [BuildingScreenPath],
  [AppRoute.CREATE_BUILDING]: [`/${AppRoute.CREATE_BUILDING}`],
  [AppRoute.DIRECT_LOGIN]: [`/${AppRoute.DIRECT_LOGIN}`],
  [AppRoute.EXPERT_TOOLS_XML_PORTFOLIO]: [
    ExpertToolsXMLPortfolioBasePath,
    ExpertToolsXMLPortfolioWorklistPath
  ],
  [AppRoute.EXPERT_TOOLS_XML]: [ExpertToolsXMLPath],
  [AppRoute.EXPERT_TOOLS_VARME]: [ExpertToolsVarmePath],
  [AppRoute.EXPERT_TOOLS_KULDE]: [ExpertToolsKuldePath],
  [AppRoute.LOGIN]: [`/${AppRoute.LOGIN}`],
  [AppRoute.LOGOUT]: [`/${AppRoute.LOGOUT}`],
  [AppRoute.MAIN]: [MainScreenPath, AdvancedSearchScreenPath, XmlTabPath],
  [AppRoute.NEW_REGISTRATION]: [NewRegistrationScreenPath],
  [AppRoute.PROFILE]: [`/${AppRoute.PROFILE}`],
  [AppRoute.REGISTRATION]: [RegistrationPathGeneral, RegistrationPathSummary],
  [AppRoute.SOKNAD]: [NewSoknadPath, SoknadPathGeneral, SoknadPathSummary],
  [AppRoute.STATISTICS]: [StatisticsPath],
  [AppRoute.TRACELOG]: [`/${AppRoute.TRACELOG}`],
  [AppRoute.XML_REGISTRATION]: [XmlEnhetPath, XmlFlerboligPath]
};

export const camelCasify = (str: string): string =>
  str
    .replaceAll('-', ' ')
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      +match === 0
        ? ''
        : index === 0
        ? match.toLowerCase()
        : match.toUpperCase()
    );

export const kebabCasify = (str: string): string =>
  str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()
    .split(' ')
    .join('-');

// PATH GENERATORS
export const getBuildingScreenPath = (params: BuildingScreenParams): string =>
  generatePath(BuildingScreenPath, params);

export const getExpertToolsVarmePath = (
  params: ExpertToolsVarmeParams
): string => generatePath(ExpertToolsVarmePath, params);

export const getExpertToolsKuldePath = (
  params: ExpertToolsKuldeParams
): string => generatePath(ExpertToolsKuldePath, params);

export const getExpertToolsXMLCreatePath = (
  params: ExpertToolsXMLCreateParams
): string => generatePath(ExpertToolsXMLPath, params);

export const getExpertToolsXMLPath = (params: ExpertToolsXMLParams): string =>
  generatePath(ExpertToolsXMLPath, params);

export const getExpertToolsXMLPortfolioBasePath = (): string =>
  generatePath(ExpertToolsXMLPortfolioBasePath);

export const getExpertToolsXMLPortfolioWorklistPath = (
  params: ExpertToolsXMLPortfolioWorklistParams
): string => generatePath(ExpertToolsXMLPortfolioWorklistPath, params);

export const getMainScreenPath = (params?: MainScreenParams): string =>
  generatePath(MainScreenPath, params);

export const getSimpleSearchScreenPath = (): string =>
  getMainScreenPath({
    tab: MainScreenTab.SEARCH
  });

export const getAdvancedSearchScreenPath = (): string =>
  generatePath(AdvancedSearchScreenPath, { tab: MainScreenTab.SEARCH });

export const getNewRegistrationScreenPath = (
  params: NewRegistrationScreenParams
): string => generatePath(NewRegistrationScreenPath, params);

export const getRegistrationScreenPath = (
  params: RegistrationScreenParams
): string => generatePath(RegistrationPathGeneral, params);

export const getRegistrationSummaryScreenPath = (
  params: Omit<RegistrationSummaryScreenParams, 'step'>
): string =>
  generatePath(RegistrationPathSummary, {
    ...params,
    step: RegistrationCommonStep.SUMMARY
  });

export const getNewSoknadScreenPath = (buildingId: string): string =>
  generatePath(NewSoknadPath, { buildingId });

export const getSoknadScreenPath = (params: SoknadScreenParams): string =>
  generatePath(SoknadPathGeneral, params);

export const getSoknadSummaryScreenPath = (
  params: Omit<SoknadSummaryScreenParams, 'step'>
): string =>
  generatePath(SoknadPathSummary, {
    ...params,
    step: RegistrationCommonStep.SUMMARY
  });

export const getStatisticsScreenPath = (
  params?: StatisticsScreenParams
): string => generatePath(StatisticsPath, params);

export const withAnchor = (path: string, anchor: string): string =>
  [path, anchor].join('#');

export const hasPermission = (appRoute: AppRoute, user?: User): boolean => {
  if (appRoute === AppRoute.STATISTICS) return true;
  if (!user) return false;

  switch (appRoute) {
    case AppRoute.EXPERT_TOOLS_VARME:
      return !!(
        user.type === UserType.EXPERT &&
        user.fagomrader?.includes(AreaOfExpertise.KJELOGVARMEANLEGG)
      );
    case AppRoute.EXPERT_TOOLS_KULDE:
      return !!(
        user.type === UserType.EXPERT &&
        user.fagomrader?.includes(AreaOfExpertise.VENTILASJONOGKJOLEANLEGG)
      );
    case AppRoute.EXPERT_TOOLS_XML_PORTFOLIO:
    case AppRoute.EXPERT_TOOLS_XML:
    case AppRoute.XML_REGISTRATION:
      return (
        user.type !== UserType.PERSON &&
        (user.fagomrader?.includes(AreaOfExpertise.BYGNINGSEKSPERT) ||
          user.fagomrader.includes(AreaOfExpertise.ENERGIRADGIVER))
      );
    case AppRoute.SOKNAD:
      return (
        user.type === UserType.ADVISOR ||
        user.fagomrader.includes(AreaOfExpertise.ENERGIRADGIVER)
      );
    default:
      return true;
  }
};
