import {
  CalculationParameterMap,
  UpdateCalculationParameterMap
} from '../../types/calculationParameters';

export const updateBeregningskjerneinput = (
  currentBeregningskjerneInput: CalculationParameterMap | undefined,
  updatedBeregningskjerneInput: UpdateCalculationParameterMap
) => {
  if (!currentBeregningskjerneInput) {
    throw new Error('No beregningskjerneinput provided');
  }

  let updatedBeregningskjerneinput: CalculationParameterMap = {};

  for (const [categoryKey, categoryItem] of Object.entries(
    currentBeregningskjerneInput ?? {}
  )) {
    for (const [key, itemData] of Object.entries(categoryItem)) {
      // This is a hack to avoid updating the forretningsbygg value in the faste verdier category.
      // This key is not modifiable by the user and is also the only one with a different type.
      if (categoryKey === 'fasteVerdier' && key === 'forretningsBygg') {
        continue;
      }

      const updatedExpertValue =
        updatedBeregningskjerneInput?.[key]?.expertValue;

      updatedBeregningskjerneinput = {
        ...updatedBeregningskjerneinput,
        [categoryKey]: {
          ...categoryItem,
          ...updatedBeregningskjerneinput[categoryKey],
          [key]: {
            ...itemData,
            ...updatedBeregningskjerneinput[categoryKey]?.[key],
            expertValue: updatedExpertValue
          }
        }
      };
    }
  }

  return updatedBeregningskjerneinput;
};
