/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  AppBar,
  ButtonProps,
  Menu,
  styled,
  Typography,
} from 'enova-frontend-components';

export const disabledFontColor = '#606060';

export const SmallTypography = styled(Typography)`
  line-height: normal;
` as typeof Typography;

export const StyledMenuButton = styled(
  ({ isHovedBygg, ...props }: ButtonProps & { isHovedBygg?: boolean }) => {
    return (
      <Menu.Button
        component={AppBar.Button}
        leftIcon="horizontal_dots"
        {...props}
      />
    );
  }
)`
  border: ${({ theme }) => `thin solid ${theme.palette.divider}`} !important;
  height: 100% !important;
  padding-bottom: ${({ theme }) => theme.spacing(2)} !important;

  &:not(:hover):not(:focus-visible) {
    color: ${({ theme, isHovedBygg = true }) =>
      isHovedBygg ? theme.palette.primary.main : disabledFontColor};
    background-color: ${({ theme, isHovedBygg = true }) =>
      isHovedBygg
        ? theme.palette.background.paper
        : theme.palette.action.disabledBackground};
  }

  & > span {
    margin: 10;

    svg {
      font-size: ${({ theme }) => theme.typography.h1.fontSize};
      margin-bottom: -10px;
    }
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.sm}px) {
    min-width: 0;
    padding: ${({ theme }) => theme.spacing()} !important;

    & > p {
      display: none;
    }

    & > span {
      margin: 0;
    }
  }
`;

export const BuildingListItemEnergyIndicatorWidth = 60;

export const BuildingListItemRegistrationDateWidth = 175;
