import { FormProvider, useForm } from 'react-hook-form';
import { FC } from 'react';

import { Vindu } from '../../../../../types/registration/userInput';
import { Form } from '../form';
import { useWindowDefaultValues } from '../hooks/useWindowDefaultValues';
import { WindowData, WindowFormData } from '../types';
import { useWindowOperations } from '../hooks/useWindowOperations';

interface Props {
  windowGroup: Vindu[];
  onSave?: () => void;
  onCancel?: () => void;
  windowData: WindowData;
}

export const EditWindowForm: FC<Props> = ({
  windowGroup,
  onSave,
  windowData
}) => {
  const defaultValues = useWindowDefaultValues(windowData);
  const methods = useForm<WindowFormData>({
    defaultValues
  });

  const { removeWindow, editWindow } = useWindowOperations(
    windowData.etasjeId,
    windowData.wallId
  );

  const vinduIds = windowGroup.map((window) => window.id);

  const onSubmit = ({ windowData }: WindowFormData) => {
    if (!windowData.window || windowData?.wallZoneId === -1) {
      return;
    }

    editWindow(
      windowData.window,
      windowData.amount,
      vinduIds,
      windowData.wallZoneId
    );
    onSave && onSave();
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={onSubmit}
        onRemove={() => {
          if (windowData.wallZoneId === -1) {
            return;
          }

          removeWindow(vinduIds, windowData.wallZoneId);
        }}
      />
    </FormProvider>
  );
};
