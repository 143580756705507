import { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';

import useSelector from '../../../hooks/useSelector';
import { addTakVindu, addVindu } from '../../../store/registration/actions';
import { getVeggsone } from '../../../store/registration/selectors';
import { Vindu } from '../../../types/registration/userInput';
import { TilliggendeRom } from '../utils/registerEnums';

import { createWindows, WindowType } from './utils';
import { AddWindowDialog } from './add-window-dialog';
import { WindowList } from './windowList';
import { WindowNotAllowed } from './windowNotAllowed';

const useAdjoiningRoomState = (type: WindowType) => {
  const adjoiningRoom = useSelector((state) =>
    type === 'roof'
      ? undefined
      : getVeggsone(state, type.etasjeId, type.veggId, type.veggsoneId)
          ?.tilliggendeRom
  );

  const windowsNotAllowed =
    adjoiningRoom === TilliggendeRom.OppvarmetRom ||
    adjoiningRoom === TilliggendeRom.UoppvarmetRom;

  return { adjoiningRoom, windowsNotAllowed };
};

interface WindowSectionProps {
  mulitpleWallZones?: boolean;
  type: WindowType;
}

export const WindowSection: FC<WindowSectionProps> = ({
  mulitpleWallZones,
  type
}) => {
  const dispatch = useDispatch();

  const { adjoiningRoom, windowsNotAllowed } = useAdjoiningRoomState(type);

  if (windowsNotAllowed && type !== 'roof') {
    return <WindowNotAllowed type={type} adjoiningRoom={adjoiningRoom} />;
  }

  const handleAddWindow = (vindu: Vindu, amount: number) => {
    if (type === 'roof') {
      return dispatch(addTakVindu({ vindu }));
    }

    return dispatch(
      addVindu({
        etasjeId: type.etasjeId,
        veggId: type.veggId,
        veggsoneId: type.veggsoneId,
        windows: createWindows(vindu, amount)
      })
    );
  };

  return (
    <Fragment>
      <WindowList mulitpleWallZones={mulitpleWallZones} type={type} />
      <AddWindowDialog type={type} handleAddWindow={handleAddWindow} />
    </Fragment>
  );
};
