import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { getUser } from '../../services/api';
import { updateUser } from '../../store/registration/actions';
import { getUserType, User, UserBase } from '../../types/user';
import { queryKeys } from '../../utils/react-query';

export const mapUser = (userBase: UserBase | undefined) => {
  if (!userBase) {
    return undefined;
  }

  const userType = getUserType(userBase);
  const userDetails: User = {
    ...userBase,
    // TODO: change when getting user name from altinn
    navn: userType.charAt(0).toUpperCase() + userType.slice(1),
    etternavn: '',
    type: userType
  };

  return userDetails;
};

export const useUserProfile = (isAuthenticated: boolean) => {
  const dispatch = useDispatch();

  const {
    isLoading,
    isFetchedAfterMount,
    refetch: refetchUser,
    data: user
  } = useQuery([queryKeys.user], () => getUser(), {
    enabled: isAuthenticated,
    onSuccess: (data) => {
      if (!data) {
        return;
      }

      dispatch(updateUser({ value: mapUser(data) }));
    }
  });

  return {
    isLoading,
    // Since the query wont be enabled until we are authenticated with ID-porten,
    // we need to know if this query has been run after it was mounted.
    isFetchedAfterMount,
    refetchUser,
    user: mapUser(user)
  };
};
