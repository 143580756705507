import { Trans } from 'react-i18next';
import { Box, Link } from 'enova-frontend-components';
import React from 'react';

import { DetailingStep } from '../../../utils/registerEnums';
import useRegistrationForm from '../../../../../screens/registration/useRegistrationForm';

export const WindowsEmptyState = () => {
  const { goToStep } = useRegistrationForm();

  return (
    <Box m={5}>
      <Trans
        i18nKey={'register.window.emptyState.description'}
        components={[
          <Link
            key="window-empty-state"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              goToStep(DetailingStep.Vegger);
            }}
          />
        ]}
      />
    </Box>
  );
};
