import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as Sentry from '@sentry/react';

import rootReducer from './rootReducer';

const composedEnhancer = composeWithDevTools(
  applyMiddleware(),
  Sentry.createReduxEnhancer()
);

const store = createStore(rootReducer, composedEnhancer);
export default store;
