import {
  Box,
  Button,
  Checkbox,
  FormMessage,
  styled,
  TextField,
  Typography
} from 'enova-frontend-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MultiSelectWrapper,
  NavigationWrapper
} from '../../components/styledComponents';
import { useXmlStepNavigation } from '../../hooks/useXmlStepNavigation';
import { BuildingDetails } from '../../../../../../../types/building';
import { useXmlRegistrationSearchParams } from '../../hooks/useXmlRegistrationSearchParams';
import { XmlRegistrationType } from '../../../types';

import BuildingSearchView from './buildingSearchForm';

const StyledFormMessage = styled(FormMessage)`
  border-color: #009bcc;
  background-color: #e5f4f9;
`;

interface Props {
  registrationType: XmlRegistrationType;
  toggleMultiSelect: () => void;
  onSelect: ({ bruksenhetsIdentId, energiObjektId }: BuildingDetails) => void;
}

export const BuildingSelect: FC<Props> = ({
  toggleMultiSelect,
  onSelect,
  registrationType
}) => {
  const { t } = useTranslation();
  const { navigateToStep } = useXmlStepNavigation();
  const [errorKeys, setErrorKeys] = useState<string[]>([]);
  const { selectedBuildingIds, groupName, setGroupName } =
    useXmlRegistrationSearchParams();

  const isMultiSelect = registrationType === 'flerbolig';

  const navigateToFileUpload = () => {
    const errors = [];

    // Multi unit registration requires a group name
    if (isMultiSelect && !groupName) {
      errors.push('expertToolsXML.error.noGroupName');
    }

    if (isMultiSelect && groupName.length > 100) {
      errors.push('expertToolsXML.error.groupNameTooLong');
    }

    // To proceed to next step at least one building has to be selected
    if (selectedBuildingIds.length === 0) {
      errors.push('expertToolsXML.error.noSelectedBuilding.content');
    }

    if (errors.length > 0) {
      setErrorKeys(errors);
      return;
    }

    navigateToStep('last-opp-fil', registrationType);
  };

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography variant="h4">
          {t('expertToolsXML.buildingSelect.header')}
        </Typography>
        <StyledFormMessage
          title={t('expertToolsXML.info.header')}
          fullWidth={true}
        >
          {t('expertToolsXML.selectMultiple.content')}
          <MultiSelectWrapper mt={5} isMultipleSelected={isMultiSelect}>
            <Box
              gap={3}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Checkbox
                value={isMultiSelect}
                width="auto"
                checked={isMultiSelect}
                onChange={() => toggleMultiSelect && toggleMultiSelect()}
                aria-label={t('expertToolsXML.selectMultiple.label')}
                label={t('expertToolsXML.selectMultiple.label')}
              />
              {isMultiSelect && (
                <TextField
                  fullWidth
                  helperText={t(
                    'expertToolsXML.selectMultiple.groupName.helperText'
                  )}
                  defaultValue={groupName}
                  label={t('expertToolsXML.selectMultiple.groupName')}
                  aria-label={t('expertToolsXML.selectMultiple.groupName')}
                  onBlur={(event) => setGroupName(event.currentTarget.value)}
                />
              )}
            </Box>
          </MultiSelectWrapper>
        </StyledFormMessage>

        <Box>
          <BuildingSearchView
            onResultItemSelect={onSelect}
            isMultiSelect={isMultiSelect}
          />
        </Box>

        {errorKeys.length > 0 && (
          <FormMessage
            fullWidth
            title={t('expertToolsXML.error.noSelectedBuilding.title')}
          >
            <ul>
              {errorKeys.map((errorKey, index) => (
                <li key={index}>
                  <Typography>{t(errorKey)}</Typography>
                </li>
              ))}
            </ul>
          </FormMessage>
        )}

        <NavigationWrapper>
          <Button onClick={navigateToFileUpload} style={{ marginLeft: 'auto' }}>
            {t('expertToolsXML.actions.next_fileUpload')}
          </Button>
        </NavigationWrapper>
      </Box>
    </Box>
  );
};
