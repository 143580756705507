import React, { FC, Fragment } from 'react';
import { Box, Button, Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { SimpleEnergyAnalysis } from '../../../../../../types/building';

interface Props {
  energianalyse?: SimpleEnergyAnalysis;
}

const Confirmation: FC<Props> = ({ energianalyse }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  if (!energianalyse) {
    return null;
  }

  const energiobjektId = energianalyse?.registrering.energiObjektId;
  const onNewXmlRegistration = () => push('/xml-oversikt');
  const onNavigateToBuilding = () =>
    push(`/bygg/${energiobjektId}/energiattest`);

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          {t(`expertToolsXML.step.bekreftelse.heading`)}
        </Typography>

        <Typography paragraph>
          {t(`expertToolsXML.step.bekreftelse.description`)}
        </Typography>

        <Box display="flex" gap={3} mt={10} flexWrap="wrap">
          <Button
            disabled={!energianalyse?.energiplan?.attestUri}
            href={energianalyse?.energiplan?.attestUri}
            leftIcon="download"
            variant="secondary"
          >
            {t('downloadEnergyCertificate')}
          </Button>
          <Button variant="secondary" onClick={onNavigateToBuilding}>
            {t('expertToolsXML.step.bekreftelse.navigateToBuilding')}
          </Button>
          <Button variant="secondary" onClick={onNewXmlRegistration}>
            {t('expertToolsXML.step.bekreftelse.startNewXmlRegistration')}
          </Button>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default Confirmation;
