import { FormMessage, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { useAdvancedSearchFormContext } from '../../types';

export const FormErrors = () => {
  const { formState } = useAdvancedSearchFormContext();
  const { t } = useTranslation();

  let errorList = Object.entries(formState.errors);
  if (errorList.length === 0) {
    return null;
  }

  const hasRequiredFieldCombinationError = errorList.find(([key, error]) => {
    return (
      key === 'gardsnummer' || key === 'bruksnummer' || key === 'bygningsnummer'
    );
  });

  //if any of the  gardsnummer and bruksnummer and bygningsnummer is in error list remove all
  //and add custom error for this case
  if (hasRequiredFieldCombinationError) {
    errorList = errorList.filter(([key]) => {
      return (
        key !== 'gardsnummer' &&
        key !== 'bruksnummer' &&
        key !== 'bygningsnummer'
      );
    });
    errorList.push([
      'requiredFieldCombination',
      { message: t('search.advanced.error.requiredFieldCombination') }
    ]);
  }

  return (
    <FormMessage title={t('search.advanced.error.title')} fullWidth>
      <Typography>{t('search.advanced.error.description')} </Typography>
      <ul>
        {errorList.map(([key, error]) => (
          <li key={key} aria-label={error.message}>
            {error.message}
          </li>
        ))}
      </ul>
    </FormMessage>
  );
};
