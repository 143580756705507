import React, { FC, Fragment, useState } from 'react';
import {
  Box,
  Button,
  FormMessage,
  Grid,
  styled,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import {
  AppRoute,
  BuildingScreenParams,
  ExpertToolsXMLParams,
  getExpertToolsXMLPath,
  getNewRegistrationScreenPath,
  getRegistrationScreenPath,
  hasPermission
} from '../../../utils/navigation';
import RouterButtonLink from '../../../components/routerButtonLink';
import useBuildingDetails from '../../../hooks/useBuildingDetails';
import OpenRegistrationList from '../../../components/openRegistrationList';
import { Typemerke } from '../../../types/building';
import { useUser } from '../../../hooks/useUser';
import { ExpertToolsXMLStep } from '../../../utils/expertToolsXML/enums';
import { isBuildingGarageOrAnnex } from '../../../utils/matrikkelBygningsTyper';
import { StartRegistrationDialog } from '../../../components/startRegistrationDialog';

const StyledFormMessage = styled(FormMessage)`
  border-color: #009bcc;
  border-width: 2px;
  background-color: #e5f4f9;
`;

const EnergyRating: FC = () => {
  const { user } = useUser();
  const { buildingId } = useParams<BuildingScreenParams>();
  const { push } = useHistory();
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const {
    building: {
      apenEkspertRegistreringList,
      apenRegistreringList,
      bygningsTypeNummer,
      forrigePublisertEnkeltRegistreringId,
      isHovedbygg,
      kanEnergimerkesAvPrivatPerson
    },
    currentEnergyAnalysis
  } = useBuildingDetails();

  const getPath = (
    params: Pick<ExpertToolsXMLParams, 'buildingId' | 'registrationId'>
  ) => getExpertToolsXMLPath({ ...params, step: ExpertToolsXMLStep.REVIEW });

  const navigateToRegistration = () =>
    push(getNewRegistrationScreenPath({ buildingId }));

  const startNewRegistration = () => {
    if (!isHovedbygg || isBuildingGarageOrAnnex(bygningsTypeNummer)) {
      return setIsDialogOpen(true);
    }

    navigateToRegistration();
  };

  return (
    <Fragment>
      <Box mt={4}>
        <Grid container spacing={4}>
          {kanEnergimerkesAvPrivatPerson && (
            <Grid item xs={12}>
              <Typography gutterBottom variant="h2">
                {t('energyrating.start.new')}
              </Typography>

              <Typography>{t('energyrating.start.new.description')}</Typography>
            </Grid>
          )}

          {kanEnergimerkesAvPrivatPerson && (
            <Grid item xs={12} sm="auto">
              <Button fullWidth onClick={startNewRegistration}>
                {t('energyrating.start.new')}
              </Button>
            </Grid>
          )}

          {kanEnergimerkesAvPrivatPerson &&
            forrigePublisertEnkeltRegistreringId &&
            currentEnergyAnalysis?.registrering?.typemerke !==
              Typemerke.Advanced && (
              <Fragment>
                <Grid item xs={12}>
                  <Typography gutterBottom mt={5} variant="h2">
                    {t('energyrating.start.reuse')}
                  </Typography>

                  <Typography>
                    {t('energyrating.start.reuse.description')}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm="auto">
                  <RouterButtonLink
                    fullWidth
                    to={getNewRegistrationScreenPath({
                      buildingId,
                      originalRegistrationId:
                        forrigePublisertEnkeltRegistreringId
                    })}
                  >
                    {t('energyrating.start.reuse.button')}
                  </RouterButtonLink>
                </Grid>
              </Fragment>
            )}

          {kanEnergimerkesAvPrivatPerson && (
            <OpenRegistrationList
              getPath={getRegistrationScreenPath}
              registrationIds={apenRegistreringList}
              registrationType="user"
            />
          )}

          {hasPermission(AppRoute.EXPERT_TOOLS_XML, user) && (
            <Fragment>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  mt={kanEnergimerkesAvPrivatPerson ? 5 : 0}
                  variant="h2"
                >
                  {t('energyAssessment.title', { context: 'xml' })}
                </Typography>
                <StyledFormMessage
                  title={t('energyAssessment.xml.info.title')}
                  fullWidth
                >
                  {t('energyAssessment.xml.info.content')}
                </StyledFormMessage>
              </Grid>

              <Grid item xs={12} sm="auto">
                <RouterButtonLink fullWidth to="/xml-oversikt">
                  {t('energyAssessment.xml.info.navigateToXml')}
                </RouterButtonLink>
              </Grid>

              <OpenRegistrationList
                getPath={getPath}
                registrationIds={apenEkspertRegistreringList}
                translationContext="expert"
                registrationType="expert"
              />
            </Fragment>
          )}
        </Grid>

        <StartRegistrationDialog
          bygningsTypeNummer={bygningsTypeNummer}
          isHovedBygg={isHovedbygg}
          isOpen={isDialogOpen}
          onConfirm={navigateToRegistration}
          onClose={() => setIsDialogOpen(false)}
        />
      </Box>
    </Fragment>
  );
};

export default EnergyRating;
