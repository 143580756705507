import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import RouterButtonLink from '../../routerButtonLink';
import { camelCasify, getSoknadScreenPath } from '../../../utils/navigation';
import { SoknadSteg } from '../../registering/utils/registerEnums';

interface ContinueRegistrationProps {
  energiObjektId: string;
  soknadId: string;
  soknadSteg: SoknadSteg;
}

export const ContinueRegistrationButton: FC<ContinueRegistrationProps> = ({
  energiObjektId,
  soknadId,
  soknadSteg
}) => {
  const { t } = useTranslation();

  return (
    <RouterButtonLink
      to={getSoknadScreenPath({
        buildingId: energiObjektId,
        soknadId,
        soknadSteg
      })}
      variant="secondary"
    >
      {t('documentsForEnovaApplication.continue', {
        context: camelCasify(soknadSteg)
      })}
    </RouterButtonLink>
  );
};
