import React, { FC, Fragment } from 'react';
import { Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import useSelector from '../../../../hooks/useSelector';
import { getTak } from '../../../../store/registration/selectors';
import { useErrors } from '../../../../screens/registration/utils';
import { DetailingStep } from '../../utils/registerEnums';
import { WindowSection } from '../../window-section';

import { RoofConstruction } from './roofConstruction';
import { RoofInsulation } from './roofInsulation';

interface RoofProps {
  displayErrors: boolean;
}

export const Roof: FC<RoofProps> = ({ displayErrors }) => {
  const { t } = useTranslation();

  const tak = useSelector(getTak, shallowEqual);
  const { errors } = useErrors(DetailingStep.Takkonstruksjon);

  const roofConstructionError =
    displayErrors && !!errors?.takKonstruksjonsType?.error;

  const roofisolasjonsTykkelseError =
    displayErrors && !!errors?.isolasjonstykkelseIMillimeter?.error;

  return (
    <Fragment>
      <Typography variant="h2" gutterBottom>
        {t('register.roof.title')}
      </Typography>

      <Fragment>
        <Typography paragraph>{t('register.roof.description')}</Typography>

        <Grid container spacing={4}>
          <RoofConstruction
            roofType={tak?.takKonstruksjonsType}
            error={roofConstructionError}
          />

          <RoofInsulation
            insulationThickness={tak?.isolasjonstykkelseIMillimeter}
            roofType={tak?.takKonstruksjonsType}
            error={roofisolasjonsTykkelseError}
          />

          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom>
              {t('register.roof.windows.title')}
            </Typography>

            <WindowSection type="roof" />
          </Grid>
        </Grid>
      </Fragment>
    </Fragment>
  );
};
