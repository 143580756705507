import React, { FC } from 'react';
import { Grid, Select } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { updateTak } from '../../../../store/registration/actions';
import { TakKonstruksjonsType } from '../../utils/registerEnums';
import { useRoofInsulationOptions } from '../../utils/insulation';

interface RoofInsulationProps {
  insulationThickness: number | undefined;
  roofType: TakKonstruksjonsType | undefined;
  error: boolean;
}

export const RoofInsulation: FC<RoofInsulationProps> = ({
  insulationThickness,
  roofType,
  error
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const insulationOptions = useRoofInsulationOptions(roofType);

  return (
    <Grid item>
      <Select
        error={error}
        label={t('register.isolation.label')}
        value={insulationThickness ?? -1}
        onChange={({ currentTarget: { value } }) =>
          dispatch(
            updateTak({
              field: 'isolasjonstykkelseIMillimeter',
              value: Number(value)
            })
          )
        }
        options={insulationOptions}
      />
    </Grid>
  );
};
