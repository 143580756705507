import React, { FC } from 'react';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import {
  Tiltak,
  TiltakStatus,
  TiltakType
} from '../../types/registration/tiltak';
import { TiltakNameMap } from '../../types/registration/tiltakNameMap';
import { Malform } from '../registering/utils/registerEnums';
import { EnergiObjektType } from '../../utils/matrikkelBygningsTyper';
import { createErrorMessage } from '../../utils/utils';

import { TiltakForm } from './tiltakForm';
import { getTiltakTypeFromStringValue } from './tiltakForm/utils';
import { TiltakFormData } from './tiltakForm/types';
import { TiltakMutation } from './types';

const emptyTiltak: Partial<Tiltak> = {
  rehabilitering: false,
  status: TiltakStatus.Gjenstaende,
  beskrivelseBokmal: undefined,
  beskrivelseNynorsk: undefined,
  navnBokmal: undefined,
  navnNynorsk: undefined
};

type AddTiltakProps = {
  energiobjektType: EnergiObjektType;
  addTiltakMutation: TiltakMutation;
  hasTiltak?: boolean;
  allowEnergiobjektTypeOverride?: boolean;
};

export const AddTiltak: FC<AddTiltakProps> = ({
  energiobjektType,
  addTiltakMutation,
  hasTiltak,
  allowEnergiobjektTypeOverride
}) => {
  const { t } = useTranslation();
  const { addSnack } = useSnack();

  const { isLoading, mutate: addTiltak } = addTiltakMutation;

  const formMethods = useForm<TiltakFormData>({
    defaultValues: {
      category: emptyTiltak.tiltaksKategori ?? -1,
      name: getTiltakTypeFromStringValue(emptyTiltak.navnBokmal) ?? -1,
      description: emptyTiltak.beskrivelseBokmal ?? ''
    }
  });

  const handleAddTiltak = (data: TiltakFormData) => {
    if (data.category === -1) {
      return;
    }

    const tiltak = {
      rehabilitering: false,
      status: TiltakStatus.Gjenstaende,
      beskrivelseBokmal: data.description,
      beskrivelseNynorsk: data.description,
      navnBokmal: TiltakNameMap?.[data.name as TiltakType]?.[Malform.Bokmal],
      navnNynorsk: TiltakNameMap?.[data.name as TiltakType]?.[Malform.Nynorsk],
      tiltaksKategori: data.category
    };

    const addTiltakOptions = {
      onSuccess: () => formMethods.reset(),
      onError: () => {
        addSnack(createErrorMessage(t('tiltak.create.error')), {
          variant: 'error'
        });
      }
    };

    addTiltak(tiltak, addTiltakOptions);
  };

  return (
    <FormProvider {...formMethods}>
      <TiltakForm
        tiltak={emptyTiltak}
        onSubmit={handleAddTiltak}
        isLoading={isLoading}
        onCancel={formMethods.reset}
        energiobjektType={energiobjektType}
        allowEnergiobjektTypeOverride={allowEnergiobjektTypeOverride}
        hasTiltak={hasTiltak}
      />
    </FormProvider>
  );
};
