import { FC } from 'react';

import { ReactComponent as TShapeNorth } from '../../../../../../../assets/icons/shapes/t-shape/t-shape-1.svg';
import { ReactComponent as TShapeEast } from '../../../../../../../assets/icons/shapes/t-shape/t-shape-2.svg';
import { ReactComponent as TShapeSouth } from '../../../../../../../assets/icons/shapes/t-shape/t-shape-3.svg';
import { ReactComponent as TShapeWest } from '../../../../../../../assets/icons/shapes/t-shape/t-shape-4.svg';
import { Himmelretning } from '../../../../../utils/registerEnums';

import { ShapeProps } from './types';

export const TShape: FC<ShapeProps> = ({ direction }) => {
  switch (direction) {
    case Himmelretning.Nord:
      return <TShapeNorth />;
    case Himmelretning.Ost:
      return <TShapeEast />;
    case Himmelretning.Sor:
      return <TShapeSouth />;
    case Himmelretning.Vest:
      return <TShapeWest />;

    default:
      return <TShapeNorth />;
  }
};
