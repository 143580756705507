import { Box } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import RouterLink from '../../../../../../../../components/routerLink';
import { getSimpleSearchScreenPath } from '../../../../../../../../utils/navigation';

export const SimpleSearchLink = () => {
  const { t } = useTranslation();

  return (
    <Box mt={2}>
      <RouterLink to={getSimpleSearchScreenPath()}>
        {t('search.simple')}
      </RouterLink>
    </Box>
  );
};
