import { DetailingStep } from '../../../utils/registerEnums';
import { UserInput } from '../../../../../types/registration/userInput';

export const hasToDeleteExistingData = (
  userInput: UserInput,
  category: DetailingStep
) => {
  switch (category) {
    case DetailingStep.Bygningsform:
      return (
        userInput?.bygningsdetaljer?.etasjer?.some(
          (etasje) => etasje.bygningsForm
        ) || !!userInput?.bygningsdetaljer?.terrengskjerming
      );

    case DetailingStep.Ytterdorer:
      return !!userInput?.bygningsdetaljer?.ytterDorer?.length;

    case DetailingStep.Takkonstruksjon:
      return Object.keys(userInput.bygningsdetaljer.tak ?? {}).length > 0;

    case DetailingStep.Gulv:
      return userInput?.bygningsdetaljer?.etasjer?.some((etasje) =>
        etasje.gulvSoner?.some((sone) => sone.id)
      );

    case DetailingStep.Vegger:
      return userInput?.bygningsdetaljer?.etasjer?.some((etasje) =>
        etasje.vegger?.some((sone) => sone.veggId)
      );

    case DetailingStep.Vinduer:
      return userInput?.bygningsdetaljer?.etasjer?.some((etasje) =>
        etasje.vegger?.some((vegg) =>
          vegg.veggSoner.some((veggsone) =>
            veggsone?.vinduer?.some((vindu) => vindu.id)
          )
        )
      );

    case DetailingStep.Energibruk:
      return !!userInput?.energibruk?.length;

    case DetailingStep.Lekkasjetall:
      return !!userInput?.bygningsdetaljer?.konstruksjonstetthet;

    default:
      return false;
  }
};
