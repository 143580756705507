/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, styled, Typography } from 'enova-frontend-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Vindu } from '../../../../types/registration/userInput';
import { getTranslationOptions, useWindows, WindowType } from '../utils';
import { Window } from '../window';

import { useUpdateWindow } from './useUpdateWindow';

// Some conditional styles are applied because the WindowSection component is used both on BuildingShape
// and Roof step. And both of those pages act and look different.
const Wrapper = styled(Box)<{
  mulitpleWallZones: boolean;
  type: WindowType | undefined;
}>`
  border-top: ${({ theme, type }) =>
    type === 'roof' ? 'none' : `thin solid ${theme.palette.divider}`};

  :last-child {
    border-bottom: ${({ theme, type }) =>
      type === 'roof' ? 'none' : `thin solid ${theme.palette.divider}`};
  }

  :nth-child(even) {
    background-color: ${({ mulitpleWallZones, type }) =>
      type === 'roof' ? 'none' : `${mulitpleWallZones ? 'white' : '#f7f9f6'}`};
  }

  padding: 15px;
`;

interface WindowListProps {
  mulitpleWallZones?: boolean;
  type: WindowType;
}

export const WindowList: FC<WindowListProps> = ({
  mulitpleWallZones,
  type
}) => {
  const { t } = useTranslation();

  const { windowRecord } = useWindows(type);
  const { editWindow, removeWindow } = useUpdateWindow(type);

  if (Object.entries(windowRecord).length === 0) {
    return type === 'roof' ? (
      <Typography>
        {t('register.window.empty', getTranslationOptions(type))}
      </Typography>
    ) : null;
  }

  const handleRemoveWindow = (windowGroup: Vindu[]) => {
    const vinduIds = windowGroup.map((window) => window.id);

    removeWindow(vinduIds);
  };

  const handleEditWindow = (
    window: Vindu,
    numberOfEqualWindows: number,
    windowGroup: Vindu[]
  ) => {
    const vinduIds = windowGroup.map(({ id }) => id);

    editWindow(window, numberOfEqualWindows, vinduIds);
  };
  return (
    <Box marginX={-3}>
      <Box marginX={3} marginBottom={type === 'roof' ? 0 : 5}>
        <Typography bold component={mulitpleWallZones ? 'h6' : 'h5'}>
          {t('register.window.registeredWindows', getTranslationOptions(type))}
        </Typography>
      </Box>

      {Object.entries(windowRecord).map(([_, windowgroup]) => {
        const window = windowgroup?.[0];

        return (
          <Wrapper
            key={window.id}
            type={type}
            mulitpleWallZones={mulitpleWallZones ?? false}
          >
            <Window
              editWindow={(...args) => handleEditWindow(...args, windowgroup)}
              numberOfEqualWindows={windowgroup.length}
              removeWindow={() => handleRemoveWindow(windowgroup)}
              type={type}
              window={window}
            />
          </Wrapper>
        );
      })}
    </Box>
  );
};
