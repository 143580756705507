import { FC } from 'react';

import { ReactComponent as AtriumNorth } from '../../../../../../../assets/icons/shapes/atrium/atrium-1.svg';
import { ReactComponent as AtriumEast } from '../../../../../../../assets/icons/shapes/atrium/atrium-2.svg';
import { ReactComponent as AtriumSouth } from '../../../../../../../assets/icons/shapes/atrium/atrium-3.svg';
import { ReactComponent as AtriumWest } from '../../../../../../../assets/icons/shapes/atrium/atrium-4.svg';
import { Himmelretning } from '../../../../../utils/registerEnums';

import { ShapeProps } from './types';

export const Atrium: FC<ShapeProps> = ({ direction }) => {
  switch (direction) {
    case Himmelretning.Nord:
      return <AtriumNorth />;
    case Himmelretning.Ost:
      return <AtriumEast />;
    case Himmelretning.Sor:
      return <AtriumSouth />;
    case Himmelretning.Vest:
      return <AtriumWest />;

    default:
      return <AtriumNorth />;
  }
};
