import React, { FC, forwardRef, HTMLProps, RefAttributes } from 'react';
import {
  Button,
  ButtonProps as EFCButtonProps
} from 'enova-frontend-components';

import { FileType } from './utils';

type FileUploadProps = RefAttributes<HTMLInputElement> &
  Omit<
    HTMLProps<HTMLInputElement>,
    'accept' | 'hidden' | 'onChange' | 'type'
  > & {
    acceptedFileTypes?: FileType[];
    ButtonProps?: Omit<EFCButtonProps, 'children'>;
    setFile: (file?: File) => void;
  };

const FileUpload: FC<FileUploadProps> = forwardRef<
  HTMLInputElement,
  FileUploadProps
>(({ acceptedFileTypes, ButtonProps, children, setFile }, ref) => (
  <Button {...{ component: 'label' }} {...ButtonProps}>
    {children}

    <input
      accept={acceptedFileTypes?.join(',')}
      hidden
      onChange={({ target: { files } }) => setFile(files?.[0] ?? undefined)}
      ref={ref}
      required
      type="file"
    />
  </Button>
));

export default FileUpload;
