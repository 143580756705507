import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'enova-frontend-components';

interface TitleProps {
  parameterKey: string;
}

export const Title: FC<TitleProps> = ({ parameterKey }) => {
  const { t } = useTranslation();

  return (
    <Typography>{t(`register.parameters.type.${parameterKey}`)}</Typography>
  );
};
