import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import DismissableInfoBox from '../../../components/dismissableInfoBox';
import BuildingFilterProvider from '../../../hooks/useBuildingFilter/provider';
import { useUser } from '../../../hooks/useUser';
import Buildings from '../buildings';
import { queryKeys } from '../../../utils/react-query';
import { getBuildingList } from '../../../services/api';

export const BuildingsTabContent = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  const {
    error: buildingsError,
    isLoading: buildingsLoading,
    data: buildings,
    refetch: refreshBuildings
  } = useQuery([queryKeys.buildingList], () => getBuildingList());

  return (
    <React.Fragment>
      <DismissableInfoBox
        inline={false}
        localStorageKey={user?.userId}
        title={t('mainScreen.buildings.info.title')}
      >
        {t('mainScreen.buildings.info.content')}
      </DismissableInfoBox>

      <BuildingFilterProvider
        buildingList={{
          buildings,
          buildingsError: !!buildingsError,
          buildingsLoading,
          refreshBuildings
        }}
      >
        <Buildings />
      </BuildingFilterProvider>
    </React.Fragment>
  );
};
