import React, { FC, Fragment } from 'react';
import { Box, Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { camelCasify, getStatisticsScreenPath } from '../../utils/navigation';
import StylizedRouterButtonLink from '../../components/stylizedRouterButtonLink';

import { StatisticsScreenViews } from './utils';

const maxWidthInfoDiv = 800;

const StatisticsOverview: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h1" component="span" align="center" gutterBottom>
          {t('statisticsScreen.pageTitle')}
        </Typography>

        <Box display="flex" maxWidth={maxWidthInfoDiv}>
          <Typography paddingBottom={5} align="center">
            {t('statisticsScreen.pageDescription')}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center">
        <Grid container spacing={3} justifyContent="center" width={1100}>
          {Object.values(StatisticsScreenViews).map((view) => (
            <Grid item xs={12} md={8} key={view}>
              <StylizedRouterButtonLink
                description={t(
                  `statisticsScreen.${camelCasify(view)}.description`
                )}
                fullWidth
                id={`statistikk-${view}-link`}
                size="large"
                sx={{ height: 1 }}
                title={t(`statisticsScreen.${camelCasify(view)}.title`)}
                to={getStatisticsScreenPath({ view })}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        paddingY={5}
        alignItems="center"
        mt={8}
      >
        <Typography variant="h2" component="span" align="center" gutterBottom>
          {t('statisticsScreen.detailsTitle')}
        </Typography>

        <Box display="flex" flexDirection="column" maxWidth={maxWidthInfoDiv}>
          <Typography paragraph align="center">
            {t('statisticsScreen.detailsDescription1')}
          </Typography>

          <Typography paragraph align="center">
            {t('statisticsScreen.detailsDescription2')}
          </Typography>

          <Typography paragraph align="center">
            {t('statisticsScreen.detailsDescription3') }
            <a href='https://www.enova.no/om-enova/drift/deling-av-data-fra-enova/' target='_blank'>her</a>.
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default StatisticsOverview;
