import { Control, useFormContext } from 'react-hook-form';

import { WindowFormData } from '../types';

export const useWindowFormContext = () => {
  return useFormContext<WindowFormData>();
};

export interface WindowFormFieldProps {
  control: Control<WindowFormData>;
}
