import React, { FC } from 'react';
import { Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { CalculationParameterCategory } from '../../../../../types/calculationParameters';
import { unitWidth, useAdjustParametersFormContext } from '../utils';

import { CalculatedValue, ExpertValue, Title } from './components';

const ValueWrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Grid
      alignItems="flex-end"
      display="flex"
      item
      maxWidth={160}
      minWidth="min-content"
      width={`calc(calc(100% - ${unitWidth}px) / 2)`}
    >
      {children}
    </Grid>
  );
};

interface SmallScreenItemProps {
  category: CalculationParameterCategory;
}

export const SmallScreenItem: FC<SmallScreenItemProps> = ({ category }) => {
  const { t } = useTranslation();
  const { control, trigger } = useAdjustParametersFormContext();
  const valueCanBeNegative = (parameterKey: string) => {
    return parameterKey === 'frostsikringstemperatur';
  };

  return (
    <Grid container spacing={4} direction="column">
      {Object.entries(category).map(
        ([parameterKey, { calculatedValue, unit }]) => {
          return (
            <Grid
              item
              key={parameterKey}
              display="flex"
              flexWrap="wrap"
              container
            >
              <Grid item xs={12}>
                <Title parameterKey={parameterKey} />
              </Grid>

              <Grid container spacing={2} item xs={12} flexWrap="nowrap">
                <ValueWrapper>
                  <CalculatedValue
                    parameterKey={parameterKey}
                    label={t('register.parameters.calculated')}
                    calculatedValue={calculatedValue}
                    allowNegative={valueCanBeNegative(parameterKey)}
                  />
                </ValueWrapper>

                <ValueWrapper>
                  <ExpertValue
                    control={control}
                    trigger={trigger}
                    label={t('register.parameters.new')}
                    parameterKey={parameterKey}
                    allowNegative={valueCanBeNegative(parameterKey)}
                  />
                </ValueWrapper>

                <Grid
                  alignItems="flex-end"
                  display="flex"
                  item
                  mb={2.4}
                  width={unitWidth}
                >
                  <Typography>{unit}</Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        }
      )}
    </Grid>
  );
};
