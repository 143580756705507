import React, { FC, forwardRef, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Typography } from 'enova-frontend-components';

import { useDownloadTiltakslisteReport } from '../useGetTiltakslisteReport';
import { SoknadSteg } from '../../registering/utils/registerEnums';

interface Props {
  soknadId: string;
  soknadSteg: SoknadSteg;
  isSteg1Done: boolean;
}

export const DownloadTiltakslisteButton: FC<Props> = ({
  soknadId,
  soknadSteg,
  isSteg1Done
}) => {
  const { t } = useTranslation();
  const { cancelDownload, downloadTiltakslisteReport, isLoading, reportUrl } =
    useDownloadTiltakslisteReport(soknadId, soknadSteg);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    setIsOpen(true);
  }, [isLoading]);

  const onClose = () => {
    cancelDownload();
    setIsOpen(false);
  };

  const DownloadLink = forwardRef<HTMLAnchorElement>((props, ref) => (
    <a
      {...props}
      rel="noopener noreferrer"
      target="blank"
      download="Tiltaksliste"
      ref={ref}
      onClick={() => setIsOpen(false)}
    />
  ));

  return (
    <Fragment>
      <Button
        onClick={
          isSteg1Done
            ? downloadTiltakslisteReport
            : () => {
                setIsOpen(true);
              }
        }
        variant="secondary"
      >
        {t('documentsForEnovaApplication.download.tiltak')}
      </Button>

      <Dialog open={isOpen} onClose={onClose}>
        <Dialog.Title>
          {t('documentsForEnovaApplication.download.dialog.title')}
        </Dialog.Title>

        <Dialog.Content>
          <Typography>
            {isSteg1Done
              ? t('documentsForEnovaApplication.download.dialog.description')
              : t('documentsForEnovaApplication.download.dialog.tiltak.error')}
          </Typography>
        </Dialog.Content>

        <Dialog.Actions>
          <Button variant="secondary" onClick={onClose}>
            {t('cancel')}
          </Button>
          {isSteg1Done && (
            <Button
              LinkComponent={DownloadLink}
              href={reportUrl}
              loading={isLoading}
              disabled={!reportUrl || isLoading}
            >
              {t('download')}
            </Button>
          )}
        </Dialog.Actions>
      </Dialog>
    </Fragment>
  );
};
