import { Box, styled } from 'enova-frontend-components';
import React from 'react';

export const SearchResultItemWrapper = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ isSelected, isManuallyCreated, ...props }) => <Box {...props} />
)<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: ${({ isManuallyCreated }) =>
    !isManuallyCreated ? 'white' : '#FCFEE7'};
  border: ${({ isSelected }) => `2px solid ${isSelected ? 'black' : 'grey'}`};
  min-height: 128px;
  cursor: pointer;
`;
