import React, { FC, Fragment, ReactNode } from 'react';
import {
  AppBar,
  Menu,
  reservedKeys,
  styled,
  useLocalStorage
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import i18n, { Language } from '../../i18n';
import { Malform } from '../registering/utils/registerEnums';
import { ReactComponent as NorwegianFlag } from '../../assets/icons/flags/norge.svg';

const LanguageSelectorButton = styled(AppBar.Button)`
  height: auto !important;
  padding: ${({ theme }) => theme.spacing(0.5, 2)} !important;

  p {
    font-size: ${({ theme }) => theme.typography.body1.fontSize} !important;
    text-transform: none;
  }
`;

const FlagWrapper = styled.span`
  display: flex;
  height: ${({ theme }) => theme.spacing(3)};
  margin-right: ${({ theme }) => theme.spacing(2)};
  width: ${({ theme }) => theme.spacing(3)};

  svg {
    height: 100%;
    width: 100%;
  }
`;

const LanguageMalformMap: Partial<
  Record<Language, { flag: ReactNode; malform: Malform }>
> = {
  [Language.NB_NO]: {
    flag: <NorwegianFlag />,
    malform: Malform.Bokmal
  },
  [Language.NN_NO]: {
    flag: <NorwegianFlag />,
    malform: Malform.Nynorsk
  }
};

const LanguageMenu: FC = () => {
  const { t } = useTranslation();
  const [, setLng] = useLocalStorage(reservedKeys.lng.app);

  const curLng = i18n.language as Language;

  const changeLanguage = (newLng: Language) => {
    i18n.changeLanguage(newLng);
    setLng(newLng);
  };

  return (
    <Menu>
      <Menu.Button component={LanguageSelectorButton} dropdownIcon>
        {LanguageMalformMap.hasOwnProperty(curLng) ? (
          <Fragment>
            <FlagWrapper>{LanguageMalformMap[curLng]!.flag}</FlagWrapper>
            {t(`malform.${LanguageMalformMap[curLng]!.malform}`)}
          </Fragment>
        ) : (
          t('selectLanguage')
        )}
      </Menu.Button>

      <Menu.Content>
        {Object.entries(LanguageMalformMap).map(
          ([language, { malform, flag }]) => (
            <Menu.Item
              className="d-flex ai-center"
              key={language}
              onClick={() => changeLanguage(language as Language)}
              selected={curLng === language}
            >
              <FlagWrapper>{flag}</FlagWrapper>
              {t(`malform.${malform}`)}
            </Menu.Item>
          )
        )}
      </Menu.Content>
    </Menu>
  );
};

export default LanguageMenu;
