import React, { FC } from 'react';
import { Button, Dialog, useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { createErrorMessage } from '../../utils/utils';
import { Tiltak } from '../../types/registration/tiltak';
import i18n, { Language } from '../../i18n';

import { TiltakMutation } from './types';

interface DeleteTiltakDialogProps {
  isOpen: boolean;
  deleteTiltakMutation: TiltakMutation;
  onClose: () => void;
  tiltak: Tiltak;
}

export const DeleteTiltakDialog: FC<DeleteTiltakDialogProps> = ({
  isOpen,
  onClose,
  tiltak,
  deleteTiltakMutation
}) => {
  const { t } = useTranslation();
  const { addSnack } = useSnack();
  const { isLoading, mutate: deleteTiltak } = deleteTiltakMutation;

  const onDeleteTiltak = () =>
    deleteTiltak(tiltak, {
      onSuccess: () => {
        addSnack(t('tiltak.delete.success'));
      },
      onError: () => {
        addSnack(createErrorMessage(t('tiltak.delete.error')), {
          variant: 'error'
        });
      }
    });

  const curLng = i18n.language as Language;
  const tiltakName =
    curLng === Language.NN_NO ? tiltak.navnNynorsk : tiltak.navnBokmal;

  return (
    <Dialog open={isOpen} onClose={isLoading ? undefined : onClose}>
      <Dialog.Title>{t('tiltak.delete.title')}</Dialog.Title>

      <Dialog.Content>
        {t('tiltak.delete.description', { tiltakName })}
      </Dialog.Content>

      <Dialog.Actions>
        <Button disabled={isLoading} onClick={onClose} variant="secondary">
          {t('cancel')}
        </Button>

        <Button loading={isLoading} onClick={onDeleteTiltak}>
          {t('confirm')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
