import { FormMessage, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { useBuildingSearchFormContext } from '../../types';

export const FormErrors = () => {
  const { t } = useTranslation();
  const {
    formState: { errors, isSubmitted }
  } = useBuildingSearchFormContext();

  const missingRequiredField =
    errors.gatenavn || errors.bygningsnummer || errors.gardsnummer;

  if (!isSubmitted || (!errors.kommunenummer && !missingRequiredField)) {
    return null;
  }

  return (
    <FormMessage title={t('search.advanced.error.title')} fullWidth>
      <Typography>{t('search.advanced.error.description')} </Typography>
      <ul>
        {errors.kommunenummer && (
          <li> {t('search.advanced.error.kommune')} </li>
        )}
        {missingRequiredField && (
          <li> {t('search.advanced.error.missingRequiredField')} </li>
        )}
      </ul>
    </FormMessage>
  );
};
