import { Radio, RadioGroup } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { useCreateBuildingFormContext } from '../utils';
import { Bygningstype } from '../types';

interface BuildingTypeProps {
  disabled?: boolean;
}

export const BuildingType: FC<BuildingTypeProps> = ({ disabled }) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors }
  } = useCreateBuildingFormContext();

  return (
    <Controller
      control={control}
      name="bygningstype"
      rules={{
        required: t('required') as string
      }}
      render={({ field: { value, ...rest } }) => (
        <RadioGroup
          {...rest}
          value={value ?? null}
          error={!!errors.bygningstype}
          label={t('building.create.bygningstype')}
          row
          disabled={disabled}
          helperText={errors.bygningstype?.message}
        >
          <Radio
            error={!!errors.bygningstype}
            width="auto"
            value={Bygningstype.BoligBygg}
            label={t('building.create.bygningstype.boligBygg')}
          />
          <Radio
            error={!!errors.bygningstype}
            width="auto"
            value={Bygningstype.YrkesBygg}
            label={t('building.create.bygningstype.yrkesBygg')}
          />
        </RadioGroup>
      )}
    />
  );
};
