import { ApisauceInstance } from 'apisauce';

import { CompanySearchResponse, Enhet } from './types';

/**
 *  Example of Content-disposition header:
 *
 * 'attachment;
 *  filename=Energiattest-20230206-_vre-16-3403-7-310-0-16744352-a3505447-a30e-47b0-b34f-88c1d96d9506.pdf;
 *  filename*=UTF-8''Energiattest-20230206-%C3%98vre-16-3403-7-310-0-16744352-a3505447-a30e-47b0-b34f-88c1d96d9506.pdf'
 */

export const getFilename = (contentDispositionHeader: string | undefined) => {
  if (!contentDispositionHeader) {
    return undefined;
  }

  try {
    const headerParts = contentDispositionHeader?.split(' ') ?? [];

    let filename = headerParts?.[1];

    if (headerParts?.some((p) => p.includes('UTF-8'))) {
      filename = headerParts?.[2].split("''")?.[1];
    }

    return decodeURIComponent(filename);
  } catch (error) {
    console.error(
      'Error while parsing content disposition header for filename'
    );

    return undefined;
  }
};

// Need to do two parallell requests to check both enheter and underenheter
// for a given organization number
export const searchOrganizationNumber = async (
  orgNr: string,
  brregApi: ApisauceInstance
) => {
  const enhetResponse = brregApi.get<Enhet>(
    `/enhetsregisteret/api/enheter/${orgNr}`
  );
  const underEnhetResponse = brregApi.get<Enhet>(
    `/enhetsregisteret/api/underenheter/${orgNr}`
  );
  const responses = await Promise.allSettled([
    enhetResponse,
    underEnhetResponse
  ]);
  let results: Enhet[] = [];

  for (const response of responses) {
    if (response.status === 'fulfilled' && response?.value?.data) {
      results = [...results, response.value.data];
    }
  }
  return results;
};

// Need to do two parallell requests to check both enheter and underenheter
// for a given company name
export const searchCompanyName = async (
  companyName: string,
  brregApi: ApisauceInstance
) => {
  const enhetResponse = brregApi.get<CompanySearchResponse>(
    `/enhetsregisteret/api/enheter?navn=${companyName}`
  );
  const underEnhetResponse = brregApi.get<CompanySearchResponse>(
    `/enhetsregisteret/api/underenheter?navn=${companyName}`
  );

  const responses = await Promise.allSettled([
    enhetResponse,
    underEnhetResponse
  ]);

  let results: Enhet[] = [];

  for (const response of responses) {
    if (response.status === 'fulfilled' && response.value.data) {
      const res = response.value.data?._embedded?.enheter ?? [];

      results = [...results, ...res];
    }
  }

  return results;
};
