import React, { FC, Fragment } from 'react';
import { AppBar, styled, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../hooks/useUser';
import { getName } from '../../../types/user';
import { useAuthentication } from '../../../hooks/useAuthentication';

export const UserRole = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.h4.fontSize};
  color: ${({ theme }) => theme.palette.greys.grey1};
`;

const UserMenu: FC = () => {
  const { user } = useUser();
  const { signIn, signOut, isAuthenticated } = useAuthentication();

  const { t } = useTranslation();

  if (!isAuthenticated) {
    return <AppBar.Button onClick={() => signIn()}>{t('logIn')}</AppBar.Button>;
  }

  return (
    <Fragment>
      {user && <UserRole mr={3}>{getName(user)}</UserRole>}

      <AppBar.Button onClick={signOut}>{t('logOut')}</AppBar.Button>
    </Fragment>
  );
};

export default UserMenu;
