import querystring from 'querystring';

import { ApiResponse, ApisauceConfig, create } from 'apisauce';

import {
  AggregatedStatisticsMap,
  StatistikkRequest,
  StatistikkRequestType
} from '../types/statistics';
import { UserBase } from '../types/user';
import { UserInput } from '../types/registration/userInput';
import {
  BuildingDetails,
  BuildingSearchResponse,
  DetailedBuildingDetails,
  ExpertReport,
  Property,
  Registration,
  RegistrationRequest,
  SearchValues,
  SimpleEnergyAnalysis
} from '../types/building';
import { CalculationParameterMap } from '../types/calculationParameters';
import { XMLReport } from '../types/expertToolsXML';
import { EnovaStotteCriteria } from '../types/soknad';
import { Tiltak, Tiltakskategori } from '../types/registration/tiltak';
import {
  CreatePortefoljeDTO,
  Portefolje,
  PortefoljeEnergiAnalyseResponse
} from '../screens/expertToolsXMLPortfolio/types';
import {
  BygningsForm,
  Malform,
  SoknadSteg
} from '../components/registering/utils/registerEnums';
import { InsulationData } from '../types/misc';
import { EnergiObjektType } from '../utils/matrikkelBygningsTyper';
import { authManager } from '../auth';

import {
  CreateBuildingPayload,
  FlerboligRegistrationResponse,
  NavnSokResponse,
  NummerSokResponse,
  PublishRegistrationDTO,
  SearchOptions,
  SubmitRegistrationDTO,
  UpdateRegistrationDTO,
  UserEnergianalyseResponse,
  XMLFlerboligResponse,
  XMLResponse
} from './types';
import {
  getFilename,
  searchCompanyName,
  searchOrganizationNumber
} from './utils';
import { logError, logNetworkError } from './tracelog';

const apiBaseURL = window.config.VITE_API_URL;
const commonConfig: Partial<ApisauceConfig> = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8'
  },
  timeout: 30000
};

export const api = create({
  ...commonConfig,
  baseURL: apiBaseURL
});

// Add authorization header to every request made to the api
api.addAsyncRequestTransform(async (request) => {
  const user = await authManager.getUser();
  request.headers['Authorization'] = `Bearer ${user?.access_token}`;
});

api.axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response) {
      //No response from server.
      logNetworkError('error interceptor network', error);
    } else {
      logError('error interceptor application', error.response);
    }
    return Promise.reject(error);
  }
);

const wsGeoNorgeApi = create({
  ...commonConfig,
  baseURL: 'https://ws.geonorge.no'
});

const brregApi = create({
  ...commonConfig,
  baseURL: 'https://data.brreg.no'
});

// GET
export const getInsulationValues = async () => {
  const response = await api.get<InsulationData>('registrering/load');

  if (!response.ok) {
    throw new Error(response.problem);
  }

  const data = response.data;

  if (!data) {
    logError(response.config?.url?.toString() ?? '', response);
    throw new Error('Missing insulation data');
  }

  return data;
};

export const getUser = async () => {
  const response = await api.get<UserBase>('bruker');

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const getBuilding = async (
  energiobjektId: string,
  cancelToken?: ApisauceConfig['cancelToken']
) => {
  const response = await api.get<DetailedBuildingDetails>(
    `registrerteiendom/oppsummering/${energiobjektId}`,
    undefined,
    {
      cancelToken
    }
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const getBuildingList = async () => {
  const response = await api.get<BuildingDetails[]>(
    'registrerteiendom/oppsummering'
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const getUserSelectedBuildingList = async (
  cancelToken?: ApisauceConfig['cancelToken']
) => {
  const response = await api.get<BuildingDetails[]>(
    'bruker/valgtebygninger',
    undefined,
    {
      cancelToken
    }
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const getSimpleEnergyAnalyses = async (buildingId: string) => {
  const response = await api.get<SimpleEnergyAnalysis[]>(
    `energianalyse/bygg/${buildingId}`,
    undefined
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const getExpertReports = async (buildingId: string) => {
  const response = await api.get<ExpertReport[]>(
    `ekspertrapport/bygg/${buildingId}`
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const getEnergyAnalysis = async (registreringId: string | undefined) => {
  if (!registreringId) {
    throw new Error(
      'Cant retrieve energy analysis when missing registrationId'
    );
  }

  const response = await api.get<SimpleEnergyAnalysis[]>(
    `energianalyse/${registreringId}`
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const getUserInput = async (id: string | undefined) => {
  if (!id) {
    throw new Error('Missing id when retrieving userinput');
  }

  const response = await api.get<UserInput>(`userinput/${id}`);

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const getSearchBuilding = async (
  address: SearchValues,
  cancelToken?: ApisauceConfig['cancelToken']
) => {
  const response = await api.get<BuildingDetails[]>(
    `registrerteiendom/oppsummering/adresse?${querystring.stringify(
      address
    )}&inkluderManueltRegistrerte=true`,
    undefined,
    { cancelToken }
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const getUnofficialEnergyCertificate = async (
  registrationId: string | undefined
) => {
  if (!registrationId) {
    throw new Error(
      'Missing registrationId to fetch unofficial energy vertificate'
    );
  }

  const response = await api.get<Blob>(
    `energiattest`,
    { registreringId: registrationId },
    {
      responseType: 'blob',
      headers: { Accept: 'application/pdf' }
    }
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  if (!response.data) {
    logError(response.config?.url?.toString() ?? '', response);
    throw new Error(
      `Cant find energy certificate for registration id "${registrationId}"`
    );
  }

  const contentDisposition = response.headers?.['content-disposition'];
  const filename = getFilename(contentDisposition);

  return { data: response.data, filename };
};

export const getUnofficialEnergyCertificateFlerboligbygg = async (
  flerboligbyggId?: string
) => {
  if (!flerboligbyggId) {
    throw new Error(
      'Missing flerboligbyggId to fetch unofficial energy certificate'
    );
  }
  const response = await api.get<Blob>(
    `energiattest`,
    { flerboligbyggId: flerboligbyggId },
    {
      responseType: 'blob',
      headers: { Accept: 'application/pdf' }
    }
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  if (!response.data) {
    logError(response.config?.url?.toString() ?? '', response);
    throw new Error(
      `Cant find energy certificate for registration id "${flerboligbyggId}"`
    );
  }

  const contentDisposition = response.headers?.['content-disposition'];
  const filename = getFilename(contentDisposition);

  return { data: response.data, filename };
};

export const getSoknadTiltakslisteReport = async (
  soknadId: string,
  soknadSteg: SoknadSteg,
  cancelToken: ApisauceConfig['cancelToken']
) => {
  const response = await api.get<string>(
    `energiattest/${soknadId}/${soknadSteg}`,
    undefined,
    {
      cancelToken
    }
  );

  if (!response.ok) {
    throw Error(response.problem);
  }

  if (!response.data) {
    logError(response.config?.url?.toString() ?? '', response);
    throw new Error(
      `Cant find report for application id "${soknadId}" and application step "${soknadSteg}"`
    );
  }

  return response.data;
};

export const getUnpublishedExpertRegistrations = async () => {
  const response = await api.get<UserEnergianalyseResponse>(
    `energianalyse/ekspert`
  );

  if (!response.ok) {
    throw Error(response.problem);
  }

  return response.data;
};

export const grantCertificateConsent = async (energiplanId: string) => {
  const response = await api.post<string>(`energiattest/consent/grant/`, {
    energiplanId
  });

  if (!response.ok) {
    throw Error(response.problem);
  }

  if (!response.data) {
    logError(response.config?.url?.toString() ?? '', response);
    throw new Error(
      `Failed to grant certificate consent for id ${energiplanId}`
    );
  }

  return response.data;
};

export const revokeCertificateConsent = async (energiplanId: string) => {
  const response = await api.post<string>(`energiattest/consent/revoke/`, {
    energiplanId
  });

  if (!response.ok) {
    throw Error(response.problem);
  }

  if (!response.data) {
    logError(response.config?.url?.toString() ?? '', response);
    throw new Error(
      `Failed to revoke certificate consent for id ${energiplanId}`
    );
  }

  return response.data;
};

export const getPortefoljer = async (isPublished?: boolean) => {
  const response = await api.get<Portefolje[]>('portefolje/bruker', {
    isPublished
  });

  if (!response.ok) {
    throw Error(response.problem);
  }

  return response.data || [];
};

export const getPortefoljeData = async (portefoljeId: string) => {
  const response = await api.get<PortefoljeEnergiAnalyseResponse>(
    `energianalyse/portefolje/${portefoljeId}`
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const getPortefoljeTiltak = async (portefoljeId: string | undefined) => {
  if (!portefoljeId) {
    throw new Error('Cant retrieve measures without a portefoljeId');
  }

  const response = await api.get<Tiltak[]>(`tiltak/portefolje/${portefoljeId}`);

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const getEnergiplanTiltak = async (energiplanId: string | undefined) => {
  if (!energiplanId) {
    throw new Error(
      'Cant retrieve energyplan measures when missing energiplanId'
    );
  }

  const response = await api.get<Tiltak[]>(`tiltak/energiplan/${energiplanId}`);

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const getEnovaSupportLevels = async (
  soknadId: string,
  soknadSteg?: SoknadSteg
) => {
  const response = await api.get<EnovaStotteCriteria>(
    `enovastotte/${soknadId}${soknadSteg ? `/${soknadSteg}` : ''}`
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

// POST to statistikk without authorization
export const loadStatistics = async (
  aggregation: StatistikkRequestType,
  body?: string
) => {
  if (!aggregation) {
    throw new Error(`Unknown StatistikkRequestType provided ${aggregation}`);
  }

  const { kommune, buildingCategory } = JSON.parse(body || '{}');

  const request: StatistikkRequest = {
    kommune,
    buildingCategory,
    aggregation
  };

  const response = await api.post<AggregatedStatisticsMap>(
    'statistikk/aggregert/load',
    request
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

// External
export const getSearchAddress = async (
  searchString?: string,
  options?: SearchOptions
) => {
  if (!searchString) {
    throw new Error('Missing search address');
  }

  const searchParams = new URLSearchParams();

  searchParams.set('sok', searchString);
  searchParams.set('side', options?.page || '0');
  searchParams.set('treffPerSide', options?.hitsPerPage || '15');

  if (options?.fuzzySearch) {
    searchParams.set('fuzzy', 'true');
  }

  const response = await wsGeoNorgeApi.get<BuildingSearchResponse>(
    `/adresser/v1/sok?${searchParams.toString()}`
  );

  // Return empty result if address is not found
  if (response.status === 404) {
    return { adresser: [] };
  }

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const getSearchPoststed = async (postnummer: string | null) => {
  if (!postnummer) {
    throw new Error('Missing postnummer');
  }

  const response = await wsGeoNorgeApi.get<BuildingSearchResponse>(
    `adresser/v1/sok?fuzzy=false&postnummer=${postnummer}&filtrer=adresser&treffPerSide=1&side=0`
  );

  // Return empty result if address is not found
  if (response.status === 404) {
    return { adresser: [] };
  }

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const fetchKommuneByNavn = async (kommunenavn: string) => {
  const params = new URLSearchParams();
  params.append('filtrer', 'kommuner.kommunenummer,kommuner.kommunenavnNorsk');
  params.append('knavn', `*${kommunenavn}*`);

  const response = await wsGeoNorgeApi.get<NavnSokResponse>(
    `kommuneinfo/v1/sok?${params.toString()}`
  );

  // Return empty result if kommune is not found
  if (response.status === 404) {
    return { kommuner: [] };
  }

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const fetcKommuneByNummer = async (kommunenummer: string) => {
  const response = await wsGeoNorgeApi.get<NummerSokResponse>(
    `kommuneinfo/v1/kommuner/${kommunenummer}`
  );

  // Return empty result if kommune is not found
  if (response.status === 404) {
    return { kommuner: [] };
  }

  if (!response.ok) {
    throw new Error(response.problem);
  }

  const data = response.data;

  return {
    kommuner: [
      {
        kommunenummer: data?.kommunenummer,
        kommunenavnNorsk: data?.kommunenavnNorsk
      }
    ]
  };
};

export const searchCompany = async (companyNameOrOrgNumber: string) => {
  // Regex to determine if a string only contains numbers
  const numberRegex = /^\d+$/;

  if (
    numberRegex.test(companyNameOrOrgNumber) &&
    companyNameOrOrgNumber.length === 9
  ) {
    return await searchOrganizationNumber(companyNameOrOrgNumber, brregApi);
  }

  return await searchCompanyName(companyNameOrOrgNumber, brregApi);
};

// PUT
/**
 * Updates the UserInput object of a given registration
 * @param {string} registrationId The ID of the registration to be updated
 * @param {UserInput} userInput Updated UserInput object
 * @returns {Promise} Promise object specifies whether the update was successful
 */
export const updateRegistration = async (
  registrationId: string | undefined,
  userInput: UserInput
) => {
  if (!registrationId) {
    throw new Error('Missing registrationId when updating registration');
  }

  const response = await api.put<UpdateRegistrationDTO>(
    `userinput/${registrationId}`,
    userInput
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  const data = response.data;

  if (!data?.userInput) {
    throw new Error('Missing data when updating registration');
  }

  return data;
};

export const updateBaseUser = async (user: UserBase) => {
  const response = await api.put<UserBase>('bruker', user);

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const updateInputParameters = async (
  registrationId: string | undefined,
  parameters?: CalculationParameterMap
) => {
  if (!registrationId) {
    throw new Error('Missing registrationId when updating parameters');
  }

  const response = await api.put<CalculationParameterMap>(
    `energimerke/parameter/${registrationId}`,
    parameters
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const updateRegistreringLanguage = async (
  registrationId: string | undefined,
  language: Malform
) => {
  if (!registrationId) {
    throw new Error('Cant update language when no registrationId is provided');
  }

  const response = await api.put(
    `registrering/${registrationId}/sprak/${language}`
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const updateTiltak = async (tiltak: Partial<Tiltak>) => {
  const response = await api.put<Tiltak>(`tiltak/${tiltak.tiltakId}`, tiltak);

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

// POST
export const calculateArea = async (
  bygningsform: BygningsForm,
  vegglengder: Array<{ lengde: number; id: string }>
) => {
  const response = await api.post<{ areal: number }>(
    'registrering/calculate/area',
    {
      bygningsform,
      vegglengder
    }
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const createBuilding = async (payload: CreateBuildingPayload) => {
  const response = await api.post<string>('eiendom', payload);

  if (!response.ok) {
    throw new Error(response.problem);
  }

  const data = response.data;

  if (!data) {
    logError(response.config?.url?.toString() ?? '', response);
    throw new Error('Missing id from response when creating building');
  }

  return { data, status: response.status };
};

export const createRegistration = async (
  registrationRequestDTO: RegistrationRequest
) => {
  const response = await api.post<Registration>(
    'registrering',
    registrationRequestDTO
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  const data = response.data;
  const userInput = data?.userInput;

  if (!userInput) {
    throw new Error('Missing reponse data when creating registration');
  }

  return { ...data, userInput };
};

export const archiveAndCloneRegistration = async (
  registrationId: string,
  energiplanId: string
) => {
  const response = await api.post<Registration>(`energiplan/cloneAndArchive`, {
    registrationId,
    energiplanId
  });

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const createExpertRegistration = async (
  inputJson: XMLReport,
  bruksenhetsId: string
) => {
  const formData = new FormData();

  formData.append('ekspertRegistrering', JSON.stringify(inputJson));
  formData.append('ekspertRegistreringRequest.BruksenhetsId', bruksenhetsId);

  const response = await api.post<XMLResponse>(
    'registrering/ekspertbygg',
    formData
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const getFlerboligRegistration = async (flerboligId: string) => {
  const response = await api.get<FlerboligRegistrationResponse>(
    `energianalyse/flerboligbygg/${flerboligId}`
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const createFlerboligRegistrations = async (
  inputJson: XMLReport,
  bruksenhetsIds: string[],
  groupName: string
) => {
  const formData = new FormData();

  formData.append('ekspertRegistrering', JSON.stringify(inputJson));

  bruksenhetsIds.forEach((id) =>
    formData.append(
      'ekspertRegistreringFlerboligbyggRequest.BruksenhetsIder',
      id
    )
  );

  formData.append(
    'ekspertRegistreringFlerboligbyggRequest.RegistreringsNavn',
    groupName
  );

  const response = await api.post<XMLFlerboligResponse>(
    'registrering/ekspertbygg/flerboligbygg',
    formData
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const createPortefolje = async (portefolje: CreatePortefoljeDTO) => {
  const response = await api.post<Portefolje>('portefolje', portefolje);

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const createTiltakEnergiplan = async (tiltak: Tiltak) => {
  const response = await api.post<Tiltak>('tiltak', tiltak);

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const createTiltakPortefolje = async (
  portefoljeId: string | undefined,
  tiltak: Tiltak
) => {
  if (!portefoljeId) {
    throw new Error('Cant add tiltak without a portefolje id');
  }

  const response = await api.post<Tiltak>(
    `tiltak/portefolje/${portefoljeId}`,
    tiltak
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const createTiltakGrupperegistrering = async (tiltak: Tiltak) => {
  const response = await api.post<Tiltak>('tiltak/grupperegistrering', tiltak);

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const updateTiltakGrupperegistrering = async (
  tiltak: Partial<Tiltak>
) => {
  if (!tiltak.tiltakId) {
    throw new Error('Can not update tiltak without tiltakId');
  }
  const response = await api.put<Tiltak>(
    `tiltak/${tiltak.tiltakId}/grupperegistrering/`,
    tiltak
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const getTiltakGrupperegistrering = async (
  grupperegistreringId?: string
) => {
  if (!grupperegistreringId) {
    throw new Error('Can not get tiltak without grupperegistreringId');
  }

  const response = await api.get<Tiltak[]>(
    `tiltak/grupperegistrering/${grupperegistreringId}`
  );
  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const createExpertReport = async (report: ExpertReport) => {
  const response = await api.post<ExpertReport>('ekspertrapport', report);

  if (!response.ok) {
    throw new Error(response.problem);
  }

  const data = response.data;

  if (!data) {
    logError(response.config?.url?.toString() ?? '', response);
    throw new Error('Missing response data when creating expert report');
  }

  return data;
};

export const addReportsToPortefolje = (
  portefoljeId: string,
  content: XMLReport[]
): Promise<ApiResponse<XMLResponse[]>> =>
  // Timeout set to 0 to avoid timeout error when uploading multiple files
  api.post(`registrering/ekspertbygg/portefolje/${portefoljeId}`, content, {
    timeout: 0
  });

/**
 * Submits the UserInput object of a given registration, and calculates the new energy rating based on UserInput values
 * @param {string} registrationId The ID of the registration to be submitted
 * @param {UserInput} userInput UserInput object to be submitted
 * @returns {Promise<ApiResponse<SubmitRegistrationDTO>>} Promise object with EnergyPlan, UserInput and
 * UserInputErrorInformation[]
 */
export const submitRegistration = async (
  registrationId: string | undefined,
  userInput: UserInput
) => {
  if (!registrationId) {
    throw new Error(
      'Need to provide a registrationId when submitting a registration'
    );
  }

  const response = await api.post<SubmitRegistrationDTO>(
    `energiplan/beregn/energimerke/${registrationId}`,
    userInput
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const publishRegistration = async (
  registrationId: string | undefined
) => {
  if (!registrationId) {
    throw new Error('Missing registrationId when publishing registration');
  }

  const response = await api.post<PublishRegistrationDTO>(
    `energiplan/publish/${registrationId}`
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const publishFlerboligRegistration = async (flerboligbyggId: string) => {
  if (!flerboligbyggId) {
    throw new Error('Missing flerboligId when publishing registration');
  }

  const response = await api.post(
    `energiplan/publish/flerboligbygg/${flerboligbyggId}`
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const publishPortefolje = (
  portefoljeId: string
): Promise<ApiResponse<PublishRegistrationDTO>> =>
  api.post(`portefolje/publish/${portefoljeId}`);

export const addBuildingToUserSelection = (
  energiObjektId: string
): Promise<ApiResponse<Property>> =>
  api.post(`bruker/valgtebygninger/${energiObjektId}`);

export const getTiltaksKategorier = async (type: EnergiObjektType) => {
  const response = await api.get<Tiltakskategori[]>('tiltak/kategorier/load', {
    type
  });

  if (!response.ok) {
    throw new Error(response.problem);
  }

  const data = response.data;

  if (!data) {
    throw new Error('Missing measures category data');
  }

  return data;
};

// DELETE
export const deleteGrupperegistrering = async (
  grupperegistreringId: string
) => {
  const response = await api.delete(
    `registrering/grupperegistrering/${grupperegistreringId}`
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const deleteRegistration = async (registrationId: string) => {
  const response = await api.delete<Registration>(
    `registrering/${registrationId}`
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const deleteBuildingFromUserSelection = async (
  energiObjektId: string
) => {
  const response = await api.delete<Property>(
    `bruker/valgtebygninger/${energiObjektId}`
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const deleteSoknad = async (soknadId: string | undefined) => {
  if (!soknadId) {
    throw new Error('Cant delete soknad when no soknadId is provided');
  }

  const response = await api.delete<Registration>(
    `registrering/soknad/${soknadId}`
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const deleteTiltakEnergiplan = async (tiltakId?: string) => {
  if (!tiltakId) {
    throw new Error('Cant delete tiltak when no tiltakId is provided');
  }
  const response = await api.delete(`tiltak/${tiltakId}`);

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const deleteTiltakPortefolje = async (
  portefoljeTiltakId?: string,
  portefoljeId?: string
) => {
  if (!portefoljeTiltakId) {
    throw new Error('Cant delete tiltak when missing tiltakId');
  }

  if (!portefoljeId) {
    throw new Error('Cant delete tiltak when missing portefoljeId');
  }

  const response = await api.delete(
    `tiltak/${portefoljeTiltakId}/portefolje/${portefoljeId}`
  );

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};

export const deletePortefolje = async (portefoljeId: string) => {
  const response = await api.delete(`portefolje/${portefoljeId}`);

  if (!response.ok) {
    throw new Error(response.problem);
  }

  return response.data;
};
