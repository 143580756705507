import { useQuery, useQueryClient } from 'react-query';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { CancelToken } from 'apisauce';

import { getSoknadTiltakslisteReport } from '../../services/api';
import { queryKeys } from '../../utils/react-query';
import { base64toBlob, createErrorMessage } from '../../utils/utils';
import { SoknadSteg } from '../registering/utils/registerEnums';

const createURL = (fileData: string) => {
  const blob = base64toBlob(fileData);
  const file = new File([blob], 'Tiltaksliste', {
    type: 'application/pdf'
  });
  const blobUrl = URL.createObjectURL(file);

  return blobUrl;
};

export const useDownloadTiltakslisteReport = (
  soknadId: string,
  soknadSteg: SoknadSteg
) => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const queryKey = [queryKeys.applicationMeasures, soknadId, soknadSteg];

  const cancelDownload = () => queryClient.cancelQueries(queryKey);

  const { data, isFetching, refetch } = useQuery(
    queryKey,
    ({ signal }) => {
      const source = CancelToken.source();

      const request = getSoknadTiltakslisteReport(
        soknadId,
        soknadSteg,
        source.token
      );

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('The download of the report was canceled by React Query');
      });

      return request;
    },
    {
      onError: () =>
        addSnack(createErrorMessage(t('downloadError')), { variant: 'error' }),
      enabled: false,
      staleTime: 1000 * 60 * 1
    }
  );

  const reportUrl = data ? createURL(data) : undefined;

  return {
    cancelDownload,
    downloadTiltakslisteReport: () => refetch(),
    isLoading: isFetching,
    reportUrl
  };
};
