import React, { FC } from 'react';
import {
  Accordion,
  Box,
  CollapsibleFactBox,
  Typography
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import useSelector from '../../../../../../../hooks/useSelector';
import {
  getEtasje,
  getEtasjer,
  getWalls
} from '../../../../../../../store/registration/selectors';
import { Etasje } from '../../../../../../../types/registration/userInput';
import { InnerAccordion } from '../../../../../../../components/innerAccordion';
import { getEtasjerTOptions } from '../../../../../../../utils/register';

import WallZoneSummaryContent from './wallZoneSummaryContent';

type WallsSummarySectionContentProps = {
  etasjeId: string;
};

const WallsSummaryContent: FC<WallsSummarySectionContentProps> = ({
  etasjeId
}) => {
  const { t } = useTranslation();

  const allFloorsSameWallConstruction = useSelector(
    (state) => state.register.allFloorsSameWallConstruction
  );

  const etasjer = useSelector(getEtasjer, shallowEqual);

  const walls = useSelector((state) => getWalls(state, etasjeId), shallowEqual);

  const minifiedEtasje: Pick<
    Etasje,
    | 'bygningsForm'
    | 'erKjeller'
    | 'etasjeNummer'
    | 'gjennomsnittligEtasjehoyde'
    | 'id'
  > | null = useSelector((state) => {
    const tmp = getEtasje(state, etasjeId);

    return tmp
      ? {
          bygningsForm: tmp.bygningsForm,
          erKjeller: tmp.erKjeller,
          etasjeNummer: tmp.etasjeNummer,
          gjennomsnittligEtasjehoyde: tmp.gjennomsnittligEtasjehoyde,
          id: tmp.id
        }
      : null;
  }, shallowEqual);

  if (!minifiedEtasje) return null;

  const { erKjeller, etasjeNummer } = minifiedEtasje;

  const tOptions = getEtasjerTOptions(
    etasjer.length,
    allFloorsSameWallConstruction,
    erKjeller,
    etasjeNummer
  );

  return (
    <CollapsibleFactBox
      title={t('register.wallsAndIsolation.walls.sectionTitle', tOptions)}
    >
      <Box mx={-4} my={-5}>
        <Accordion>
          {walls?.map(({ veggId, veggSoner }, index) => (
            <Accordion.Item
              key={veggId}
              initExpanded={index === 0}
              title={
                <Typography bold component="h4" mx={1}>
                  {t('wall', {
                    veggId: veggId.toUpperCase()
                  })}
                </Typography>
              }
            >
              <Box mx={1}>
                {veggSoner.length > 1 ? (
                  <InnerAccordion>
                    <Box mb={-2}>
                      {veggSoner?.map((veggsone, veggsoneIndex) => (
                        <Accordion.Item
                          title={
                            <Typography bold px={1} component="h5">
                              {t('register.wallZone.wallZoneId', {
                                wallZoneId: `${veggId.toUpperCase()}${
                                  veggsoneIndex + 1
                                }`
                              })}
                            </Typography>
                          }
                          initExpanded={veggsoneIndex === 0}
                          key={veggsone.id}
                        >
                          <WallZoneSummaryContent
                            etasjeId={etasjeId}
                            veggId={veggId}
                            veggsoneId={veggsone.id}
                            veggsoneIndex={index}
                          />
                        </Accordion.Item>
                      ))}
                    </Box>
                  </InnerAccordion>
                ) : (
                  veggSoner?.[0] && (
                    <Box mb={-2}>
                      <WallZoneSummaryContent
                        etasjeId={etasjeId}
                        veggId={veggId}
                        veggsoneId={veggSoner[0].id}
                        veggsoneIndex={0}
                      />
                    </Box>
                  )
                )}
              </Box>
            </Accordion.Item>
          ))}
        </Accordion>
      </Box>
    </CollapsibleFactBox>
  );
};

export default WallsSummaryContent;
