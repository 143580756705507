import React, { FC, Fragment, useState } from 'react';
import { Box, Hidden, Menu } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { StyledMenuButton } from '../../../utils';
import {
  getBuildingScreenPath,
  getNewRegistrationScreenPath
} from '../../../../../../utils/navigation';
import {
  BuildingDetails,
  BuildingListProps
} from '../../../../../../types/building';
import {
  GetEnergiObjektTypeFromMatrikkel,
  isBuildingGarageOrAnnex
} from '../../../../../../utils/matrikkelBygningsTyper';
import { useGetBuildingAddress } from '../../../../../../hooks/useGetBuildingAddress';
import { StartRegistrationDialog } from '../../../../../../components/startRegistrationDialog';

import { BuildingLink } from './components/buildingLink';
import { useDeleteBuilding } from './useDeleteBuilding';
import { CertificateDownloadButton } from './components/certificateDownloadButton';

type BuildingProps = BuildingListProps & {
  building: BuildingDetails;
};

const BuildingListItem: FC<BuildingProps> = ({ building, displayOptions }) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const {
    adresse,
    energiObjektId,
    bygningsTypeNummer,
    isHovedbygg,
    kanEnergimerkesAvPrivatPerson,
    gyldigeEnergiattester
  } = building;

  const { getBuildingAddress } = useGetBuildingAddress();
  const { deleteBuildingFromUserSelection } = useDeleteBuilding(energiObjektId);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const removeFromUserSelectedBuildings = () =>
    deleteBuildingFromUserSelection();

  const energiObjektType = GetEnergiObjektTypeFromMatrikkel(
    kanEnergimerkesAvPrivatPerson
  );
  const address = getBuildingAddress(adresse);

  const navigateToRegistration = () =>
    push(getNewRegistrationScreenPath({ buildingId: energiObjektId }));

  const startRegistration = () => {
    if (!isHovedbygg || isBuildingGarageOrAnnex(bygningsTypeNummer)) {
      return setIsDialogOpen(true);
    }

    navigateToRegistration();
  };

  const hasEnergiattest =
    !!gyldigeEnergiattester && gyldigeEnergiattester?.length > 0;

  return (
    <Fragment>
      <BuildingLink building={building} hasMenu={displayOptions} />

      {displayOptions && (
        <Box display="flex" alignItems="stretch" ml={2}>
          <Menu>
            <StyledMenuButton aria-label={`${t('options')} ${address}`}>
              {t('options')}
            </StyledMenuButton>

            <Menu.Content>
              <Menu.Item
                aria-label={`${t('deleteBuilding')} ${address}`}
                divider
                onClick={removeFromUserSelectedBuildings}
              >
                {t('deleteBuilding')}
              </Menu.Item>

              <Menu.Item
                aria-label={`${t('showBuilding')} ${address}`}
                divider
                onClick={() =>
                  push(getBuildingScreenPath({ buildingId: energiObjektId }))
                }
              >
                {t('showBuilding', { context: energiObjektType })}
              </Menu.Item>

              {kanEnergimerkesAvPrivatPerson && (
                <Menu.Item divider onClick={startRegistration}>
                  {t('startNewRegistration')}
                </Menu.Item>
              )}

              {hasEnergiattest && (
                <a
                  href={gyldigeEnergiattester[0].attestUri}
                  style={{ all: 'unset' }}
                >
                  <Menu.Item>{t('downloadEnergyCertificate')}</Menu.Item>
                </a>
              )}
            </Menu.Content>
          </Menu>
        </Box>
      )}
      {!displayOptions && hasEnergiattest && (
        <Hidden smDown>
          <CertificateDownloadButton attest={gyldigeEnergiattester[0]} />
        </Hidden>
      )}

      <StartRegistrationDialog
        bygningsTypeNummer={building.bygningsTypeNummer}
        isHovedBygg={isHovedbygg}
        isOpen={isDialogOpen}
        onConfirm={navigateToRegistration}
        onClose={() => setIsDialogOpen(false)}
      />
    </Fragment>
  );
};

export default BuildingListItem;
