import React, { FC, useState } from 'react';
import { Box, Button, Paper } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { Vegg } from '../../../../../types/registration/userInput';
import { AddWallZoneForm } from '../wallZone/addWallZoneForm';

interface Props {
  etasjeId: string;
  wall: Vegg;
  isUniformWall: boolean;
  isFormVisible?: boolean;
}

export const AddWallZone: FC<Props> = ({
  wall,
  etasjeId,
  isUniformWall,
  isFormVisible
}) => {
  const { t } = useTranslation();
  const [addFormVisible, setAddFormVisible] = useState(!!isFormVisible);

  if (!addFormVisible) {
    return (
      <Box m={5}>
        <Button
          variant="secondary"
          onClick={() => {
            setAddFormVisible(true);
          }}
        >
          {t('register.wallZone.addNew')}
        </Button>
      </Box>
    );
  }

  return (
    <Box m={4}>
      <Paper shadow="none" style={{ border: 'solid 1px #d0d2d2' }}>
        <AddWallZoneForm
          onSave={() => setAddFormVisible(false)}
          onCancel={() => setAddFormVisible(false)}
          isUniformWall={isUniformWall}
          wall={wall}
          etasjeId={etasjeId}
        />
      </Paper>
    </Box>
  );
};
