import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FullScreenLoader,
  Typography,
  useSnack
} from 'enova-frontend-components';

import { useUser } from '../../hooks/useUser';
import { MainScreenTab } from '../../utils/navigation';
import { useAuthentication } from '../../hooks/useAuthentication';
import { createErrorMessage } from '../../utils/utils';

const LoginScreen: FC = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { addSnack } = useSnack();

  const { isLoadingUserProfile, isFetchedAfterMount, hasUserProfile } =
    useUser();
  const { signOut } = useAuthentication();

  useEffect(() => {
    if (!isFetchedAfterMount || isLoadingUserProfile) {
      return;
    }

    const logOut = async () => {
      await signOut();
      addSnack(createErrorMessage(t('failedLogin')), { variant: 'error' });
      push(`/`);
    };

    if (!hasUserProfile) {
      logOut();
    } else {
      push(`/${MainScreenTab.BUILDINGS}`);
    }
  }, [isLoadingUserProfile, hasUserProfile, isFetchedAfterMount]);

  return (
    <FullScreenLoader open>
      <Typography>{t('loggingIn')}</Typography>
    </FullScreenLoader>
  );
};

export default LoginScreen;
