import { FC } from 'react';

import { ReactComponent as ZShapeMirroredNorth } from '../../../../../../../assets/icons/shapes/z-shape-mirrored/z-shape-mirrored-1.svg';
import { ReactComponent as ZShapeMirroredEast } from '../../../../../../../assets/icons/shapes/z-shape-mirrored/z-shape-mirrored-2.svg';
import { ReactComponent as ZShapeMirroredSouth } from '../../../../../../../assets/icons/shapes/z-shape-mirrored/z-shape-mirrored-3.svg';
import { ReactComponent as ZShapeMirroredWest } from '../../../../../../../assets/icons/shapes/z-shape-mirrored/z-shape-mirrored-4.svg';
import { Himmelretning } from '../../../../../utils/registerEnums';

import { ShapeProps } from './types';

export const ZShapeMirrored: FC<ShapeProps> = ({ direction }) => {
  switch (direction) {
    case Himmelretning.Nord:
      return <ZShapeMirroredNorth />;
    case Himmelretning.Ost:
      return <ZShapeMirroredEast />;
    case Himmelretning.Sor:
      return <ZShapeMirroredSouth />;
    case Himmelretning.Vest:
      return <ZShapeMirroredWest />;

    default:
      return <ZShapeMirroredNorth />;
  }
};
