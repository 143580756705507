import { Tabs, TabsProps } from 'enova-frontend-components';
import React, { FC, useState } from 'react';

import { SummaryTab } from './tabs/types';

export const SummaryTabs: FC<TabsProps> = ({ children }) => {
  const [tab, setTab] = useState(SummaryTab.RATING);

  return (
    <Tabs
      contained
      onChange={(_, newTab) => setTab(newTab as SummaryTab)}
      selectedTab={tab}
    >
      {children}
    </Tabs>
  );
};
