import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog } from 'enova-frontend-components';

import { useMediaQuery } from '../../../../hooks/useMediaQuery';

interface RemoveWindowDialogProps {
  removeWindow: () => void;
}

export const RemoveWindowDialog: FC<RemoveWindowDialogProps> = ({
  removeWindow
}) => {
  const { t } = useTranslation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { xsScreen } = useMediaQuery();

  const onClose = () => setDeleteDialogOpen(false);

  return (
    <Fragment>
      <Button
        variant="secondary"
        size="medium"
        fullWidth={xsScreen}
        onClick={() => setDeleteDialogOpen(true)}
      >
        {t('register.window.remove.button')}
      </Button>

      <Dialog open={deleteDialogOpen} onClose={onClose}>
        <Dialog.Title>{t('register.window.remove.title')}</Dialog.Title>

        <Dialog.Content>
          {t('register.window.remove.description')}
        </Dialog.Content>

        <Dialog.Actions>
          <Button variant="secondary" onClick={onClose}>
            {t('cancel')}
          </Button>

          <Button onClick={removeWindow}>{t('confirm')}</Button>
        </Dialog.Actions>
      </Dialog>
    </Fragment>
  );
};
