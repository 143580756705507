import { useMutation } from 'react-query';

import * as api from '../../../../services/api';
import { CalculationParameterMap } from '../../../../types/calculationParameters';
import { updateBeregningskjerneinput } from '../../../../store/registration/utils';

import { State } from './utils';

interface Payload {
  state: State;
  inputData: CalculationParameterMap | undefined;
}
// Used in update parameters page
export const useUpdateParameters = (registrationId: string | undefined) => {
  const { mutate, isLoading } = useMutation((payload: Payload) => {
    const currentBeregningskjerneInput = payload?.inputData;
    const updatedBeregningskjerneInput = payload?.state;

    if (!currentBeregningskjerneInput) {
      throw new Error('No input data');
    }

    // The backend requires to send back the entire beregningskjerneinput object with updated values.
    const updatedBeregningskjerneinput: CalculationParameterMap =
      updateBeregningskjerneinput(
        currentBeregningskjerneInput,
        updatedBeregningskjerneInput
      );

    return api.updateInputParameters(
      registrationId,
      updatedBeregningskjerneinput
    );
  });

  return {
    updateParameters: mutate,
    updateParametersIsLoading: isLoading
  };
};
