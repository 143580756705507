import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import {
  InfoBox,
  InfoBoxProps,
  useEnovaContext,
  useLocalStorage,
  useMediaQuery
} from 'enova-frontend-components';

type DismissableInfoBoxProps = InfoBoxProps & {
  localStorageKey?: string | number;
};

const DismissableInfoBox: FC<DismissableInfoBoxProps> = ({
  className,
  inline,
  localStorageKey,
  ...rest
}) => {
  const { pathname } = useLocation();
  const [dismissed, setDismissed] = useLocalStorage<string[]>('dismissed', []);
  const { theme } = useEnovaContext();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const display = useMemo(
    () =>
      !dismissed.includes(
        localStorageKey ? `${pathname}_${localStorageKey}` : pathname
      ),
    []
  );

  return display ? (
    <InfoBox
      {...rest}
      control={{
        onClose: () => setDismissed((prev) => [...prev, pathname]),
        open: !dismissed.includes(pathname)
      }}
      className={classNames('mb-3', className)}
      inline={inline ?? !xsDown}
    />
  ) : null;
};

DismissableInfoBox.displayName = 'DismissableInfoBox';

export default DismissableInfoBox;
