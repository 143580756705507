import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { NumberField } from 'enova-frontend-components';
import { FC } from 'react';

import {
  BuildingSearchFormData,
  useBuildingSearchFormContext
} from '../../types';

type FormField = keyof Omit<BuildingSearchFormData, 'kommuneOptions'>;

export interface BuildingSearchFieldProps {
  field: FormField;
  fieldDependencies?: FormField[];
}

export const SearchFormNumberField: FC<BuildingSearchFieldProps> = ({
  field,
  fieldDependencies
}) => {
  const { t } = useTranslation();
  const { control } = useBuildingSearchFormContext();

  const label = t(`search.advanced.field_${field}`);

  return (
    <Controller
      control={control}
      name={field}
      rules={{
        deps: fieldDependencies
      }}
      render={({ field: { value, ...rest }, fieldState }) => (
        <NumberField
          error={!!fieldState?.error}
          helperText={fieldState.error?.message}
          fullWidth
          label={label}
          inputProps={{ 'aria-label': label }}
          autoComplete
          useThousandSeparator={false}
          isAllowed={({ floatValue }) => !floatValue || floatValue < 1000000000}
          allowLeadingZeros
          title={label}
          value={value ?? null}
          integer
          {...rest}
        />
      )}
    />
  );
};
