import React, { FC } from 'react';
import { Form, Grid } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { GlassType, KarmType } from '../../utils/registerEnums';
import { Vindu } from '../../../../types/registration/userInput';
import {
  getTranslationOptions,
  isWindowValid,
  useWindowErrors,
  WindowType as TypeOfWindow
} from '../utils';

import { Heading } from './components/heading';
import { WindowType } from './components/windowType';
import { WindowManufactureYear } from './components/windowManufactureYear';
import { UValue } from './components/uValue';
import { UnknownWindowType } from './components/unknownWindowType';
import { WindowTypeRadioGroup } from './components/windowTypeRadioGroup';
import { WindowArea } from './components/windowArea';
import { NumberOfWindows } from './components/numberOfWindows';
import { ActionType, useWindowReducer } from './windowReducer';
import { FormButtons } from './components/formButtons';

interface AddOrEditWindowProps {
  onSubmit: (window: Vindu, amount: number) => void;
  handleCancel: () => void;
  numberOfEqualWindows?: number;
  type: TypeOfWindow;
  windowToEdit?: Vindu;
}

export const AddorEditWindow: FC<AddOrEditWindowProps> = ({
  onSubmit,
  handleCancel,
  numberOfEqualWindows,
  type,
  windowToEdit
}) => {
  const { t } = useTranslation();

  const {
    state: { window, windowSpecType, equalNumberOfWindows },
    dispatch
  } = useWindowReducer(windowToEdit, numberOfEqualWindows);

  const { errors } = useWindowErrors(window.fabrikasjonsAr);

  const { fabrikasjonsAr, uverdi, arealIKvm } = window;

  const disableSubmit =
    !isWindowValid(window, windowSpecType) || Object.keys(errors).length > 0;

  const headingText = windowToEdit
    ? 'register.window.edit.label'
    : 'register.window.add.label';

  return (
    <Form onSubmit={() => onSubmit(window, equalNumberOfWindows ?? 0)}>
      <Grid container spacing={4}>
        <Grid item xs={12} display="flex" flexDirection="column">
          <Heading heading={t(headingText, getTranslationOptions(type))} />

          <WindowTypeRadioGroup
            onChange={(val) =>
              dispatch({
                type: ActionType.UpdateType,
                payload: { value: val }
              })
            }
            value={windowSpecType}
          >
            <UnknownWindowType />

            <WindowType
              onChangeGlassType={(val) =>
                dispatch({
                  type: ActionType.UpdateGlassType,
                  payload: { value: val as GlassType }
                })
              }
              onChangeKarmType={(val) =>
                dispatch({
                  type: ActionType.UpdateKarmType,
                  payload: { value: val as KarmType }
                })
              }
              type={type}
              window={window}
              windowSpecType={windowSpecType}
            />

            <WindowManufactureYear
              errors={errors}
              onChange={(val) =>
                dispatch({
                  type: ActionType.UpdateValue,
                  payload: { key: 'fabrikasjonsAr', value: val }
                })
              }
              type={type}
              fabrikasjonsAr={fabrikasjonsAr}
              windowSpecType={windowSpecType}
            />

            <UValue
              onChange={(val) =>
                dispatch({
                  type: ActionType.UpdateValue,
                  payload: { key: 'uverdi', value: val }
                })
              }
              type={type}
              uverdi={uverdi}
              windowSpecType={windowSpecType}
            />
          </WindowTypeRadioGroup>
        </Grid>

        <Grid item xs={12}>
          <WindowArea
            onChange={(val) =>
              dispatch({
                type: ActionType.UpdateValue,
                payload: { key: 'arealIKvm', value: val }
              })
            }
            value={arealIKvm}
          />
        </Grid>

        {type !== 'roof' && (
          <Grid item xs={12}>
            <NumberOfWindows
              onChange={(floatValue) =>
                dispatch({
                  type: ActionType.UpdateEqualNumberOfWindows,
                  payload: { value: floatValue }
                })
              }
              value={equalNumberOfWindows}
            />
          </Grid>
        )}

        <FormButtons onCancel={handleCancel} disableSubmit={disableSubmit} />
      </Grid>
    </Form>
  );
};
