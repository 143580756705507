import { Box, FormMessage, Typography } from 'enova-frontend-components';
import { FC } from 'react';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreateBuildingData } from '../types';

interface ErrorMessageProps {
  errors: FieldErrors<CreateBuildingData>;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ errors }) => {
  const { t } = useTranslation();

  if (Object.keys(errors).length === 0) {
    return null;
  }

  return (
    <Box mt={10}>
      <FormMessage fullWidth title={t('building.create.errors.title')}>
        <Typography>{t('building.create.errors.description')}</Typography>
        <ul aria-label={t('building.create.errors.list')}>
          {errors &&
            Object.entries(errors).map(([field, error]) => {
              const errorMessage = t(`building.create.error.${field}`, {
                context: error.type
              });

              return (
                <li key={field} aria-label={errorMessage}>
                  {errorMessage}
                </li>
              );
            })}
        </ul>
      </FormMessage>
    </Box>
  );
};
