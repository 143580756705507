import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Form,
  Grid,
  Select,
  Typography
} from 'enova-frontend-components';

import { DorType } from '../../utils/registerEnums';
import { YtterDor } from '../../../../types/registration/userInput';
import i18n from '../../../../i18n';
import { getSelectOptionsFromEnum } from '../../../../utils/register';

type NewDoorProps = {
  handleCancel: () => void;
  saveDoor: (door: YtterDor) => void;
};

const NewDoor: FC<NewDoorProps> = ({ handleCancel, saveDoor }) => {
  const { t } = useTranslation();

  const [door, setDoor] = useState<YtterDor>({
    id: uuidv4(),
    dorType: DorType.Default
  });

  const { dorType } = door;

  const dorTypeOptions = useMemo(
    () => [
      {
        label: String(t('register.door.dorType.option.default')),
        value: DorType.Default,
        disabled: true
      },
      ...getSelectOptionsFromEnum(DorType, (label) =>
        t(`register.door.dorType.option.${label}`)
      )
        .filter((item) => item.value !== DorType.Default)
        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))
    ],
    [i18n.language]
  );

  return (
    <Form onSubmit={() => saveDoor(door)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3">{t('register.door.newDoor')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Select
            fullWidth
            label={t('register.door.dorType.label')}
            onChange={({ currentTarget: { value } }) =>
              setDoor((prev) => ({ ...prev, dorType: value as DorType }))
            }
            options={dorTypeOptions}
            required
            title={
              dorType != null
                ? (dorTypeOptions.find((option) => option.value === dorType)
                    ?.label as string)
                : undefined
            }
            value={dorType ?? -1}
          />
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} sm="auto">
            <Button fullWidth variant="secondary" onClick={handleCancel}>
              {t('cancel')}
            </Button>
          </Grid>

          <Grid item xs={12} sm="auto">
            <Button
              disabled={!dorType || dorType === DorType.Default}
              fullWidth
              type="submit"
              variant="primary"
            >
              {t('register.door.create')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default NewDoor;
