import React, { FC, Fragment } from 'react';
import { Grid, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import useBuildingDetails from '../../hooks/useBuildingDetails';
import { GetEnergiObjektTypeFromMatrikkel } from '../../utils/matrikkelBygningsTyper';
import { Tiltak } from '../../types/registration/tiltak';

import { AddTiltak } from './addTiltak';
import TiltakList from './tiltakList';
import { TiltakMutation } from './types';

type TiltakSectionProps = {
  addTiltakMutation: TiltakMutation;
  updateTiltakMutation: TiltakMutation;
  deleteTiltakMutation: TiltakMutation;
  tiltakList?: Tiltak[];
  allowEnergiobjektTypeOverride?: boolean;
  readOnly?: boolean;
};

const TiltakSection: FC<TiltakSectionProps> = ({
  tiltakList,
  allowEnergiobjektTypeOverride,
  addTiltakMutation,
  updateTiltakMutation,
  deleteTiltakMutation,
  readOnly
}) => {
  const { t } = useTranslation();
  const { building } = useBuildingDetails();
  const hasTiltak = tiltakList && tiltakList.length > 0;
  const energiobjektType = GetEnergiObjektTypeFromMatrikkel(
    building.kanEnergimerkesAvPrivatPerson
  );

  return (
    <Fragment>
      <Grid item>
        {!readOnly && (
          <AddTiltak
            energiobjektType={energiobjektType}
            addTiltakMutation={addTiltakMutation}
            hasTiltak={hasTiltak}
            allowEnergiobjektTypeOverride={allowEnergiobjektTypeOverride}
          />
        )}
      </Grid>

      {hasTiltak && (
        <Fragment>
          <Grid item mt={4}>
            <Typography variant="h3">
              {t('tiltak.existingMeasures.heading', {
                context: energiobjektType
              })}
            </Typography>
          </Grid>

          <TiltakList
            tiltakList={tiltakList}
            deleteTiltakMutation={deleteTiltakMutation}
            updateTiltakMutation={updateTiltakMutation}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default TiltakSection;
