import { Stepper } from 'enova-frontend-components';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { camelCasify } from '../../../../../../utils/navigation';
import { XMLParams, XmlRegistrationStep } from '../../types';

interface XmlStepProps {
  step: XmlRegistrationStep;
  done: boolean;
}

export const XmlStep: FC<XmlStepProps> = ({ step, done }) => {
  const { step: currentStep } = useParams<XMLParams>();
  const { t } = useTranslation();

  return (
    <Stepper.Step
      active={currentStep === step}
      done={done}
      label={t(`expertToolsXML.step.${camelCasify(step)}`)}
    />
  );
};
